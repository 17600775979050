import { GetFinancesStatsForDiagrams } from "./statistics.stats.enum";

export enum DiagramStatsEnum {
    Finances,
    OrderCount,
    OrderPrice,
    FinishedProducts
}

export const DiagramStatsEnumName = (status: DiagramStatsEnum) => {
    switch (status) {
        case DiagramStatsEnum.Finances:
            return 'Финансы';
        case DiagramStatsEnum.OrderCount:
            return 'Количество созданных заказов';
        case DiagramStatsEnum.OrderPrice:
            return 'Стоимость товаров в заказах';
        case DiagramStatsEnum.FinishedProducts:
            return 'Товары выполненых заказов ';
        default:
            return '';
    }
}

export enum OrderDiagramStatsEnum {
    All,
    NotInStockNotWaiting,
    NotInStockWaiting,
    Waiting,
    Confirmed,
    Canceled,
    Sanded,
    OnTheWay,
    Delivered,
    Received,
    Overdue,
    Refused,
    Payed
}

export const OrderCountStatsEnumName = (status: OrderDiagramStatsEnum) => {
    switch (status) {
        case OrderDiagramStatsEnum.All:
            return 'Количество созданных заказов (все статусы)';
        case OrderDiagramStatsEnum.NotInStockNotWaiting:
            return 'Количество созданных заказов со статусом "Нет в наличии, не согласен ждать"';
        case OrderDiagramStatsEnum.NotInStockWaiting:
            return 'Количество созданных заказов со статусом "Нет в наличии, согласен ждать"';
        case OrderDiagramStatsEnum.Waiting:
            return 'Количество созданных заказов со статусом "В ожидании"';
        case OrderDiagramStatsEnum.Confirmed:
            return 'Количество созданных заказов со статусом "Оформлен"';
        case OrderDiagramStatsEnum.Canceled:
            return 'Количество созданных заказов со статусом "Отменен"';
        case OrderDiagramStatsEnum.Sanded:
            return 'Количество созданных заказов со статусом "Отправлен"';
        case OrderDiagramStatsEnum.OnTheWay:
            return 'Количество созданных заказов со статусом "В пути"';
        case OrderDiagramStatsEnum.Delivered:
            return 'Количество созданных заказов со статусом "Доставлен"';
        case OrderDiagramStatsEnum.Received:
            return 'Количество созданных заказов со статусом "Получен"';
        case OrderDiagramStatsEnum.Overdue:
            return 'Количество созданных заказов со статусом "Не забранный"';
        case OrderDiagramStatsEnum.Refused:
            return 'Количество созданных заказов со статусом "Отказ"';
        case OrderDiagramStatsEnum.Payed:
            return 'Количество созданных заказов, по которым были списаны средства с баланса и не возвращены';
        default:
            return '';
    }
}

export const OrderPriceStatsEnumName = (status: OrderDiagramStatsEnum) => {
    switch (status) {
        case OrderDiagramStatsEnum.All:
            return 'Стоимость товаров в заказах по ценовому уровню партнера (все статусы)';
        case OrderDiagramStatsEnum.NotInStockNotWaiting:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Нет в наличии, не согласен ждать"';
        case OrderDiagramStatsEnum.NotInStockWaiting:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Нет в наличии, согласен ждать"';
        case OrderDiagramStatsEnum.Waiting:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "В ожидании"';
        case OrderDiagramStatsEnum.Confirmed:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Оформлен"';
        case OrderDiagramStatsEnum.Canceled:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отменен"';
        case OrderDiagramStatsEnum.Sanded:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отправлен"';
        case OrderDiagramStatsEnum.OnTheWay:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "В пути"';
        case OrderDiagramStatsEnum.Delivered:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Доставлен"';
        case OrderDiagramStatsEnum.Received:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Получен"';
        case OrderDiagramStatsEnum.Overdue:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Не забранный"';
        case OrderDiagramStatsEnum.Refused:
            return 'Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отказ"';
        case OrderDiagramStatsEnum.Payed:
            return 'Стоимость товаров созданных заказов по ценовому уровню партнера, по которым были списаны средства с баланса и не возвращены';
        default:
            return '';
    }
}

export const GetOrderCountStatsForDiagrams = () => [
    { value: OrderDiagramStatsEnum.All, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.All) },
    { value: OrderDiagramStatsEnum.NotInStockNotWaiting, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.NotInStockNotWaiting) },
    { value: OrderDiagramStatsEnum.NotInStockWaiting, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.NotInStockWaiting) },
    { value: OrderDiagramStatsEnum.Waiting, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Waiting) },
    { value: OrderDiagramStatsEnum.Confirmed, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Confirmed) },
    { value: OrderDiagramStatsEnum.Canceled, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Canceled) },
    { value: OrderDiagramStatsEnum.Sanded, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Sanded) },
    { value: OrderDiagramStatsEnum.OnTheWay, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.OnTheWay) },
    { value: OrderDiagramStatsEnum.Delivered, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Delivered) },
    { value: OrderDiagramStatsEnum.Received, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Received) },
    { value: OrderDiagramStatsEnum.Overdue, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Overdue) },
    { value: OrderDiagramStatsEnum.Refused, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Refused) },
    { value: OrderDiagramStatsEnum.Payed, label: OrderCountStatsEnumName(OrderDiagramStatsEnum.Payed) },
];

export const GetOrderPriceStatsForDiagrams = () => [
    { value: OrderDiagramStatsEnum.All, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.All) },
    { value: OrderDiagramStatsEnum.NotInStockNotWaiting, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.NotInStockNotWaiting) },
    { value: OrderDiagramStatsEnum.NotInStockWaiting, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.NotInStockWaiting) },
    { value: OrderDiagramStatsEnum.Waiting, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Waiting) },
    { value: OrderDiagramStatsEnum.Confirmed, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Confirmed) },
    { value: OrderDiagramStatsEnum.Canceled, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Canceled) },
    { value: OrderDiagramStatsEnum.Sanded, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Sanded) },
    { value: OrderDiagramStatsEnum.OnTheWay, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.OnTheWay) },
    { value: OrderDiagramStatsEnum.Delivered, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Delivered) },
    { value: OrderDiagramStatsEnum.Received, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Received) },
    { value: OrderDiagramStatsEnum.Overdue, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Overdue) },
    { value: OrderDiagramStatsEnum.Refused, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Refused) },
    { value: OrderDiagramStatsEnum.Payed, label: OrderPriceStatsEnumName(OrderDiagramStatsEnum.Payed) },
];

export const GetStatisticsTypesForDiagrams = () => [
    { value: DiagramStatsEnum.Finances, label: DiagramStatsEnumName(DiagramStatsEnum.Finances), subOptions: GetFinancesStatsForDiagrams() },
    { value: DiagramStatsEnum.OrderCount, label: DiagramStatsEnumName(DiagramStatsEnum.OrderCount), subOptions: GetOrderCountStatsForDiagrams() },
    { value: DiagramStatsEnum.OrderPrice, label: DiagramStatsEnumName(DiagramStatsEnum.OrderPrice), subOptions: GetOrderPriceStatsForDiagrams() },
    { value: DiagramStatsEnum.FinishedProducts, label: DiagramStatsEnumName(DiagramStatsEnum.FinishedProducts), subOptions: [] },
];

export const GetPartnerStatisticsTypesForDiagrams = () => [
    { value: DiagramStatsEnum.OrderCount, label: DiagramStatsEnumName(DiagramStatsEnum.OrderCount), subOptions: GetOrderCountStatsForDiagrams() },
    { value: DiagramStatsEnum.OrderPrice, label: DiagramStatsEnumName(DiagramStatsEnum.OrderPrice), subOptions: GetOrderPriceStatsForDiagrams() },
    // { value: DiagramStatsEnum.FinishedProducts, label: DiagramStatsEnumName(DiagramStatsEnum.FinishedProducts), subOptions: [] },
];