export class ProductPriceListItem {
    public ProductId: number;

    public LexiconId: number;

    public ProductCode: string;

    public CategoryName: string;

    public SubCategoryName: string;

    public LexiconOfficialName: string;
    
    public PartnerPrice: number;
    
    public PartnerPriceD1: number;

    public PartnerPriceD2: number;

    public PartnerPriceD3: number;

    public PartnerWholesalePrice: number;

    public RecomendedPartnerPrice: number;

    public SiteUrl: string;
}