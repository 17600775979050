import { SelectModel } from "../../helpers/select.model";
import { Category } from "../../business.entities/category";

export class AviabilityState {
  optionModelList: SelectModel[];
  optionArticulusList: SelectModel[];
  optionLexiconList: SelectModel[];
  optionFilterCategoryList: SelectModel[];
  optionFilterSubCategoryList: SelectModel[];

  selectedModelValue: SelectModel;
  selectedArticulusValue: SelectModel;
  selectedLexiconValue: SelectModel;
  selectedFilterCategoryValue: SelectModel;
  selectedFilterSubCategoryValue: SelectModel;

  productValue: SelectModel;

  totalPages: number;

  fetchInProccess: boolean;
  filterChanged: boolean;

  categories: Category[];
}

export const defaultAvailabilityState = {
  optionModelList: [],
  optionArticulusList: [],
  optionLexiconList: [],
  optionFilterCategoryList: [],
  optionFilterSubCategoryList: [],

  selectedModelValue: null,
  selectedArticulusValue: null,
  selectedLexiconValue: null,
  selectedFilterCategoryValue: null,
  selectedFilterSubCategoryValue: null,

  productValue: null,

  totalPages: 0,

  fetchInProccess: false,
  filterChanged: false,

  categories: null,
};