import { BaseCaseshopEntity } from "./base.caseshop.entity";
import { Color } from "./color";
import { Product } from "./product";

export class ProductColor extends BaseCaseshopEntity {
    public ColorId: number;
    public ProductId: number;
    public Quantity: number;
    public ShipmentDate: Date;
    public isRemoved: boolean;
    public Color: Color;
    public Product: Product;

    public PictureUrl1: string = '';
    public PictureUrl2: string = '';
    public PictureUrl3: string = '';
    public PictureUrl4: string = '';
    public PictureUrl5: string = '';
}

export default ProductColor;
