import * as API from '../../constants/api.constants';
import * as AviabilityActionsCreators from '../../actions/stock/aviability.actions';
import * as AviabilityActionsTypes from '../../actions/stock/aviability.actions';
import { actions } from 'react-redux-form';
import { Category } from '../../business.entities/category';
import { error, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import storeProvider from '../../helpers/store.provider';
import Lexicon from '../../business.entities/lexicon';

export const GetAutocompleteLexiconResult = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_AUTOCOMPLETE_LEXICON_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_AUTOCOMPLETE_LEXICON_RESULT}?value=${encodeURIComponent(action.payload)}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Лексиконы получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          let lexiconSelectOptionData = response.Body.map((option:Lexicon) =>
            ({ value: option.Id.toString(), label: option.OfficialPartnerName })
          );

          return AviabilityActionsCreators.GetAutocompliteLexiconsSuccess(lexiconSelectOptionData);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );


export const GetAutocompleteModelResult = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_AUTOCOMPLETE_MODEL_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_AUTOCOMPLETE_MODEL_RESULT}?value=${encodeURIComponent(action.payload)}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Модели получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          let modelSelectOptionData = response.Body.map(option =>
            ({ value: option.Id.toString(), label: `${option.Parent.Name} ${option.Name}` })
          );

          return AviabilityActionsCreators.GetAutocompliteModelSuccess(modelSelectOptionData);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetAutocompleteArticulusResult = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_AUTOCOMPLETE_ARTICULUS_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_AUTOCOMPLETE_ARTICULUS_RESULT}?value=${encodeURIComponent(action.payload)}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Артикулы получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          let articulusSelectOptionData = response.Body.map(option =>
            ({ value: option.Id.toString(), label: option.PartnerProductCode })
          );

          return AviabilityActionsCreators.GetAutocompliteArticulusSuccess(articulusSelectOptionData);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetFilterCategoryResult = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_FILTER_CATEGORY_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Марки получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          let filterCategoryData = response.Body.map(option =>
            ({ value: option.Id.toString(), label: option.Name }));

          return AviabilityActionsCreators.GetFilterCategoryListSuccess(filterCategoryData);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );


export const GetAutocompleteFilterSubCategoryResult = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_AUTOCOMPLETE_FILTER_SUB_CATEGORY_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_CATEGORY_FOR_AUTOCOMPLETE}?id=${action.payload.parent.value}&value=${action.payload.categoryName}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Модели получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          let filterSubCategoryData = response.Body.map(option =>
            ({ value: option.Id.toString(), label: option.Name }));

          return AviabilityActionsCreators.GetFilterSubCategoryListSuccess(filterSubCategoryData);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetCategoriesForSelectedProduct = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_CATEGORIES_FOR_SELECTED_PRODUCT)
    .switchMap(action =>
      Observable.ajax.getJSON(
        `${API.GET_CATEGORIES_FOR_SELECTED_PRODUCT}?filter=${action.payload}`
        ,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` }
      ).map((response: any) => {
        storeProvider.getStore().dispatch(success({
          title: 'Категории получены',
          message: '',
          position: 'br',
          autoDismiss: 3,
        }));

        return AviabilityActionsCreators.GetCategoriesForSelectedProductSuccess(
          response.Body.Collection,
          response.Body.PagesCount
        );
      }).catch((errorData: any) => {
        return Observable.of(ErrorHandler(errorData));
      })
    );


export const GetPartnerPriceList = (action$, store: Store<any>) =>
  action$.ofType(AviabilityActionsTypes.GET_PARTNER_PRICELIST)
    .switchMap(action =>
      Observable.ajax.getJSON(
        `${API.GET_PARTNER_PRICE_LIST}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` }
      ).map((response: any) => {
        window.open(API.SERVER_URL + response.Body, '_blank');

        return success({
          title: 'Прайс получен',
          message: '',
          position: 'br',
          autoDismiss: 3,
        });
      }).catch((errorData: any) => {
        return Observable.of(ErrorHandler(errorData));
      })
    );
