import * as actionCreators from '../../actions/stock/product.actions';
import * as actionStockCreators from '../../actions/stock/stock.actions';
import * as rightViewAction from '../../actions/right.view.actions';
import * as API from '../../constants/api.constants';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { HelperFunctions } from '../../helpers/helper.functions';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { List } from 'linqts';
import { Observable } from 'rxjs/Observable';
import { Product } from '../../business.entities/product';
import { ProductColor } from '../../business.entities/product.color';
import { Store } from 'redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { debounce } from 'rxjs/operator/debounce';
import storeProvider from '../../helpers/store.provider';
import { ofType } from 'redux-observable';
import { map, debounceTime } from 'rxjs/operators';
import { ProductState } from '../../states/stock/product.state';

export const UpdateProduct = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PRODUCT)
        .switchMap(action =>
            Observable.ajax.post(`${API.UPDATE_PRODUCT}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Информация о продукте успешно изменена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    const helper = new HelperFunctions();
                    storeProvider.getStore().dispatch(rightViewAction.RemoveRVData());

                    const productToUpdate = new List(storeProvider.getStore().getState().stock.product.products)
                        .Where((c: Product) => c.Id === response.response.Body.Id)
                        .FirstOrDefault();

                    const index = storeProvider.getStore().getState().stock.product.products.indexOf(productToUpdate);

                    response.response.Body, storeProvider.getStore().getState().stock.product.products = helper.createProductArray(index, response.response.Body, storeProvider.getStore().getState().stock.product.products);

                    return actions.reset('productForm');
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const UpdateProductImage = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PRODUCT_IMAGE)
        .switchMap(action =>
            Observable.ajax.post(`${API.ADD_PRODUCT_IMAGE}?productId=${action.payload.productId}`,
                action.payload.formData,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Фото продукта успешно загружено`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    const helper = new HelperFunctions();

                    const productToUpdate = new List(storeProvider.getStore().getState().stock.product.products)
                        .Where((c: Product) => c.Id === response.response.Body.Id)
                        .FirstOrDefault();

                    const index = storeProvider.getStore().getState().stock.product.products.indexOf(productToUpdate);

                    response.response.Body, storeProvider.getStore().getState().stock.product.products = helper.createProductArray(index, response.response.Body, storeProvider.getStore().getState().stock.product.products);

                    return actions.change('productForm.ProductFoto', response.response.Body.ProductFoto);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const UpdateProductColor = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PRODUCT_COLOR)
        .switchMap(action =>
            Observable.ajax.post(`${API.UPDATE_PRODUCT_COLOR}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    let productToUpdate = new List(storeProvider.getStore().getState().stock.product.products)
                        .Where((c: Product) => c.Id === (action.payload as ProductColor).ProductId)
                        .FirstOrDefault();

                    let productColorToUpdate = new List((productToUpdate as Product).ProductColors)
                        .Where((c: ProductColor) => c.Id === action.payload.Id)
                        .FirstOrDefault();

                    let productIndex = storeProvider.getStore().getState().stock.product.products.indexOf(productToUpdate);
                    let colorIndex = (productToUpdate as Product).ProductColors.indexOf(productColorToUpdate);
                    let productColors: ProductColor[] = (productToUpdate as any).ProductColors;

                    const helper = new HelperFunctions();
                    storeProvider.getStore().getState().stock.product.products[productIndex].ProductColors = helper.createNewProductColorArray(colorIndex, action.payload, storeProvider.getStore().getState().stock.product.products[productIndex].ProductColors);

                    storeProvider.getStore().dispatch(actions.change("productForm", storeProvider.getStore().getState().stock.product.products[productIndex]));

                    return success({
                        title: `Информация успешно обновлена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    })
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const UpdateProductColorImage = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PRODUCT_COLOR_IMAGE)
        .switchMap(action =>
            Observable.ajax.post(`${API.ADD_PRODUCT_COLOR_IMAGE}?colorId=${action.payload.productColorId}&imageNumber=${action.payload.number}`,
                action.payload.formData,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Фото успешно загружено`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    let productToUpdate = new List(storeProvider.getStore().getState().stock.product.products)
                        .Where((c: Product) => c.Id === response.response.Body.ProductId)
                        .FirstOrDefault();

                    let productColorToUpdate = new List((productToUpdate as Product).ProductColors)
                        .Where((c: ProductColor) => c.Id === response.response.Body.Id)
                        .FirstOrDefault();

                    let productIndex = storeProvider.getStore().getState().stock.product.products.indexOf(productToUpdate);
                    let colorIndex = (productToUpdate as Product).ProductColors.indexOf(productColorToUpdate);

                    const helper = new HelperFunctions();
                    storeProvider.getStore().getState().stock.product.products[productIndex].ProductColors
                        = helper.createNewProductColorArray(colorIndex, response.response.Body,
                            storeProvider.getStore().getState().stock.product.products[productIndex].ProductColors);

                    if (action.payload.number === 1)
                        return actions.change('productColorForm.PictureUrl1', response.response.Body.PictureUrl1);

                    if (action.payload.number === 2)
                        return actions.change('productColorForm.PictureUrl2', response.response.Body.PictureUrl2);

                    if (action.payload.number === 3)
                        return actions.change('productColorForm.PictureUrl3', response.response.Body.PictureUrl3);

                    if (action.payload.number === 4)
                        return actions.change('productColorForm.PictureUrl4', response.response.Body.PictureUrl4);

                    if (action.payload.number === 5)
                        return actions.change('productColorForm.PictureUrl5', response.response.Body.PictureUrl5);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetProductFilteredPaginated = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PRODUCT_FILTERED_PAGINATED)
        .switchMap(action => {
            return Observable.ajax.getJSON(`${API.GET_PRODUCT_FILTERED_PAGINATED}?count=${action.payload.count}&page=${action.payload.page}&lexiconId=${action.payload.lexiconId}&categoryId=${action.payload.categoryId}&subCategoryId=${action.payload.subCategoryId}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    storeProvider.getStore().dispatch(success({
                        title: `Товары успешно получены`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return actionCreators.GetProductsSuccess(response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        });

export const BulkUpdatePrice = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.BULK_PRICE_CHANGE)
        .switchMap(action => {
            return Observable.ajax.getJSON(`${API.UPDATE_PRODUCTS_PRICE}?categoryId=${action.payload.categoryId}&lexiconId=${action.payload.lexiconId}&price=${action.payload.price}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {

                    return success({
                        title: `Цены успешно обновлены`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    });
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        });

export const UpdateRecomendedPrices = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_RECOMENDED_PRICE)
        .switchMap(action => {
            return Observable.ajax.getJSON(`${API.UPDATE_RECOMENDED_PRICES}?categoryId=${action.payload.categoryId}&lexiconId=${action.payload.lexiconId}&price=${action.payload.price}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {

                    return success({
                        title: `Цены успешно обновлены`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    });
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        });

export const RefreshProductPage = (action$, state$) =>
    action$.pipe(
        ofType(actionCreators.BULK_PRICE_CHANGE),
        debounceTime(2000),
        map((action: any) => {

            return actionCreators.GetProductFilteredPaginated(
                (state$.value.stock.product as ProductState).take,
                1,
                state$.value.stock.product.selectedLexiconValue && state$.value.stock.product.selectedLexiconValue.value,
                state$.value.stock.product.selectedCategoryValue && state$.value.stock.product.selectedCategoryValue.value,
                state$.value.stock.product.selectedSubcategoryValue && state$.value.stock.product.selectedSubcategoryValue.value);
        })
    )

export const RefreshProductPageOnRecomended = (action$, state$) =>
    action$.pipe(
        ofType(actionCreators.UPDATE_RECOMENDED_PRICE),
        debounceTime(2000),
        map((action: any) => {

            return actionCreators.GetProductFilteredPaginated(
                (state$.value.stock.product as ProductState).take,
                1,
                state$.value.stock.product.selectedLexiconValue && state$.value.stock.product.selectedLexiconValue.value,
                state$.value.stock.product.selectedCategoryValue && state$.value.stock.product.selectedCategoryValue.value,
                state$.value.stock.product.selectedSubcategoryValue && state$.value.stock.product.selectedSubcategoryValue.value);
        })
    )    