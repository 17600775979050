import { EntityBase } from "./entity.base";
import { Partner } from "./partner";
import { OrderProduct } from "./order.product";
import { LaserStatusEnum } from "../helpers/enums/laser.status.enum";

export class ProductLaserStatus extends EntityBase {
    public Status: LaserStatusEnum;
    public OrderProductId: number;
    public UserId: number;
    public OrderProduct: OrderProduct;
    public Partner: Partner;
}