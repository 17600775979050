import * as actionCreators from '../../../../../actions/partner/partner.info.actions';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { UkrRegionModel } from "../../../../../business.entities/UkrPoshtaRegion";
import { UkrAreaModel } from "../../../../../business.entities/UkrAreaModel";
import { UkrCityModel } from "../../../../../business.entities/UkrCityModel";
import { UkrPostOfficeModel } from "../../../../../business.entities/UkrPostOfficeModel";
import * as rightViewActions from '../../../../../actions/right.view.actions';
import { actions, Errors, Form } from 'react-redux-form';
import { bindActionCreators } from 'redux';
import { OrderCardState } from "../../../../../states/sales/order.card.state";
import { connect } from 'react-redux';
import { default as UserPaswordChangeForm } from './user.password.change';
import { default as ChangeUserNameForm } from './user.name.change';
import { Loader } from '../../../../shared/loader';
import { MaxLength, Validator } from '../../../../../helpers/validator.helper';
import { Partner } from '../../../../../business.entities/partner';
import { RegistrationFormCheckbox } from '../../../../authentication/common/registration.form.checkbox';
import { RegistrationUser } from '../../../../../business.entities/registration.user';
import { RightView } from '../../../../shared/right.view/right.view';
import { RightViewState } from '../../../../../states/right.view.state';
import { TextBox } from '../../../../shared/form.controls/textbox';
import UserRegistrationForm from './user.registration';
import { default as ChangeRole } from "./change.role.component";
import Select from 'react-select';
import * as orderCardAction from "../../../../../actions/sales/order.card.actions";
import PermissionChecker from '../../../../shared/permission.checker.component';
import { AllIdentityRolesExept, IdentityRoleEnum } from '../../../../../helpers/identity.roles';
import { PartnerPaymentMethodEnum } from '../../../../../helpers/enums/partner.payment.method.enum';

namespace PartnerInfo {
    export interface IProps {
        //orderCard: OrderCardState;
        RegionUa: Array<any>;
        partner: Partner;
        UpdatePartner: any;
        ResetForm: any;
        GetPartner: any;

        match: any;
        AddUserPartner: any;
        isRvOpen: boolean;
        component: any;
        OpenRv: any;
        CloseRv: any;
        ChangeForm: any;
        title: string;
        ChangePriceLevel(value);
        ChangePartnerPaymentMethod(value);
        ChangePostOffice(value);
        ChangeCity(value);
        partnerInfo: any;
        partnerForm: any;
        SetValidity: any;
        SetError: any;
        RightViewData: RightViewState;
        OrderCard: OrderCardState;
    }

    export interface IState {
        CititesUkrPoshta: Array<any>;
        AreaUkrPoshta: Array<any>;
        CityUkrPoshta: Array<any>;
        selectedCity: any;
    }
}

const mapStateToProps = (state) => {
    return {
        partner: state.partnerForm,
        partnerForm: state.partnerFormReducer.$form,
        isRvOpen: state.partner.partnerInfo.isRvOpen,
        component: state.partner.partnerInfo.formComponent,
        title: state.partner.partnerInfo.title,
        OrderCard: state.sales.orderCard,
        partnerInfo: state.partner.partnerInfo,
        RegionUa: state.sales.orderCard.UkrRegionModel.RegionUa,
        RightViewData: state.partner.partner.PartnerRightView,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        UpdatePartner: actionCreators.UpdatePartner,
        ResetForm: actions.reset,
        GetPartner: actionCreators.GetPartner,
        AddUserPartner: actionCreators.AddUserPartner,

        ChangeForm: actions.change,
        SetValidity: actions.setValidity,
        SetError: actions.setErrors,
        ChangePriceLevel: actionCreators.ChangeMinimumPriceLevel,
        ChangePostOffice: actionCreators.ChangePostOffice,
        ChangeCity: actionCreators.ChangeCity,
        ChangePartnerPaymentMethod: actionCreators.ChangePartnerPaymentMethod,
        OpenRv: rightViewActions.SelectRVData,
        CloseRv: rightViewActions.RemoveRVData
    }, dispatch);
};

class PartnerInfo extends React.Component<PartnerInfo.IProps, PartnerInfo.IState>{

    static contextTypes = {
        store: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.props.GetPartner(this.props.match.params.netid);

        this.state = {
            CititesUkrPoshta: [],
            AreaUkrPoshta: [],
            CityUkrPoshta: [],
            selectedCity: undefined
        }
    }

    componentWillUnmount() {
        this.props.ResetForm("partnerUserForm");
        this.props.ResetForm("partnerForm");
    }

    componentWillReceiveProps(nextProps: PartnerInfo.IProps) {
        if (nextProps.OrderCard.UkrPostOfficeModel !== this.props.OrderCard.UkrPostOfficeModel) {
            this.setState({
                CititesUkrPoshta: nextProps.OrderCard.UkrPostOfficeModel.map((item: UkrPostOfficeModel) => {
                    return { ...item, label: item.PostCode + " " + item.StreetUaVpz, value: item.CityId }

                })

            })
        }

        if (nextProps.OrderCard.UkrAreaModel !== this.props.OrderCard.UkrAreaModel) {
            this.setState({
                AreaUkrPoshta: nextProps.OrderCard.UkrAreaModel.map((item: UkrAreaModel) => {
                    return { ...item, label: item.DistrictUa, value: item.RegionId }

                })

            })
        }
        if (nextProps.OrderCard.UkrCityModel !== this.props.OrderCard.UkrCityModel) {
            this.setState({
                CityUkrPoshta: nextProps.OrderCard.UkrCityModel.map((item: UkrCityModel) => {
                    return { ...item, label: "м. " + item.CityUa + " р-н " + item.DistrictUa + " " + item.RegionUa + " обл.", value: item.RegionId }

                })
            })
            debugger
        }
    }

    public onCreateUserForPartner(user: RegistrationUser) {
        this.props.AddUserPartner(this.props.match.params.netid, user);
        this.props.CloseRv();
    }

    public OnUpdatePartnerInfoCommand(partner: Partner) {
        debugger
        if (this.props.partnerInfo.selectedLevel) {
            partner = Object.assign({}, partner, {
                MinimumPriceLevel: this.props.partnerInfo.selectedLevel.value
            })
        } else {
            partner = Object.assign({}, partner, {
                MinimumPriceLevel: null
            })
        }

        this.props.UpdatePartner(partner);
    }

    public OnOpenUserRegistrationViewCommand() {
        let rvState = new RightViewState();
        rvState.Title = "Создание";
        rvState.Component = UserRegistrationForm;
        rvState.CloseFunction = () => {
            this.OnCloseViewCommand()
        }

        this.props.OpenRv(rvState);
    }

    public OnOpenPasswordChangeViewCommad() {
        let rvState = new RightViewState();
        rvState.Title = "Изменение пароля";
        rvState.ViewSize = "defaultForm__changePassword";
        rvState.Component = UserPaswordChangeForm;
        rvState.CloseFunction = () => {
            this.OnCloseViewCommand()
        }

        this.props.OpenRv(rvState);
    }

    public OnOpenUserNameChangeViewCommand() {
        this.props.ChangeForm("partnerUserNameForm", { OldUserName: this.props.partnerInfo.user.UserName, NewUserName: "" });

        let rvState = new RightViewState();
        rvState.Title = "Изменение имени";
        rvState.Component = ChangeUserNameForm;
        rvState.CloseFunction = () => {
            this.OnCloseViewCommand()
        }

        this.props.OpenRv(rvState);
    }

    public OnOpenRoleChangeViewCommand() {
        let rvState = new RightViewState();
        rvState.Title = "Изменение роли";
        rvState.Component = ChangeRole;
        rvState.CloseFunction = () => {
            this.OnCloseViewCommand()
        }

        this.props.OpenRv(rvState);
    }

    public OnCloseViewCommand() {
        this.props.ResetForm("partnerUserForm");
        this.props.ResetForm("partnerUserNameForm");
        this.props.partnerInfo.user && this.props.ChangeForm("partnerUserForm", { UserName: this.props.partnerInfo.user.UserName, Password: "" });
        this.props.partnerInfo.user && this.props.ChangeForm("partnerUserNameForm", { OldUserName: this.props.partnerInfo.user.UserName, NewUserName: "" });

        this.props.CloseRv();
    }

    private SellTypeValidation(): boolean {
        if (this.props.partner.Vkontakte ||
            this.props.partner.Instagram ||
            this.props.partner.LocalShop ||
            this.props.partner.OnlineShop ||
            this.props.partner.Other ||
            this.props.partner.OfflineShop) {

            return true;
        } else {

            return false;
        }
    }

    private InterestProduct(): boolean {
        if (this.props.partner.SiliconeCases ||
            this.props.partner.LeatherCase ||
            this.props.partner.PrintSiliconeCases ||
            this.props.partner.GlassAndFilm) {

            return true;
        } else {

            return false;
        }
    }

    render() {
        return (
            <div className="parthnerInWrapperContainer">
                <div className="new_user_form_component shadow_controls">
                    {this.props.partner.NetUid ?
                        <Form
                            validateOn='change'
                            model="partnerForm"
                            validators={{
                                '': {
                                    isAnyProdSelected: (vals) => this.InterestProduct(),
                                    isAnySaleTypeSelected: (vals) => this.SellTypeValidation()
                                }
                            }}
                            onSubmit={(partnerForm: any) => this.OnUpdatePartnerInfoCommand(partnerForm)}>
                            <div className="form_title_component">Партнер</div>
                            <div className="user_picture_component"></div>

                            <div className="form_row_component">
                                <div className="defaultFormControl col_3">
                                    <div className="defaultFormControl__label">Название организации</div>
                                    <TextBox
                                        Validators={{
                                            IsMaxLength: MaxLength(50)
                                        }}

                                        ErrorMessages={{
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.OrganizationName'
                                    />
                                </div>

                                <div className="defaultFormControl col_3">
                                    <div className="defaultFormControl__label">Город</div>
                                    <TextBox
                                        Validators={{
                                            isCityAddress: (val: string) => Validator.IsCityAddressMatchToPattern(val)
                                        }}

                                        ErrorMessages={{
                                            isCityAddress: 'Не валидный адрес города',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.OrganizationCity'
                                    />
                                </div>

                                <div className="defaultFormControl col_3">
                                    <div className="defaultFormControl__label">Ссылка на сайт или соцсеть</div>
                                    <TextBox
                                        Validators={{
                                            isUrl: (val: string) => Validator.IsUrl(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isUrl: 'Не валидний адрес',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.PageUrl'
                                    />
                                </div>

                                <PermissionChecker
                                    Classes='defaultFormControl col_3 last '
                                    ViewRoles={[IdentityRoleEnum.Boss]}>
                                    <div className="defaultFormControl__label">Минимальный ценовой уровень</div>
                                    <div className="selectWrapper__container notPadd">
                                        <Select
                                            name="level"
                                            isClearable
                                            clearValue={() => { this.props.ChangePriceLevel(null) }}
                                            options={this.props.partnerInfo.pricelevels}
                                            onChange={(value) => this.props.ChangePriceLevel(value)}
                                            value={this.props.partnerInfo.selectedLevel}
                                        />
                                    </div>
                                </PermissionChecker>
                            </div>

                            <div className="form_row_component">
                                <div className="form_col_component validations__component">
                                    {
                                        this.props.partnerForm.errors.isAnySaleTypeSelected ?
                                            <Errors
                                                model="partnerForm"
                                                show={true}
                                                messages={{
                                                    isAnySaleTypeSelected: "Нужно выбрать хоть бы один пункт"
                                                }}
                                            /> : null
                                    }

                                    <div>
                                        <div className="form_row_component">
                                            <div className="line_control">
                                                <div className="row_title">Путь сбыта</div>
                                            </div>
                                        </div>

                                        <div className="form_row_component">
                                            <RegistrationFormCheckbox
                                                model='.OnlineShop'
                                                text="Интернет-магазин"
                                                checked={this.props.partner.OnlineShop}
                                            />

                                            <RegistrationFormCheckbox
                                                model='.LocalShop'
                                                text="Локальный магазин"
                                                checked={this.props.partner.LocalShop}
                                            />

                                            <RegistrationFormCheckbox
                                                model='.Instagram'
                                                text="Инстаграм"
                                                checked={this.props.partner.Instagram}
                                            />

                                            <RegistrationFormCheckbox
                                                model='.Vkontakte'
                                                text="Вконтакте"
                                                checked={this.props.partner.Vkontakte}
                                            />

                                            <RegistrationFormCheckbox
                                                model='.Other'
                                                text="Другое"
                                                checked={this.props.partner.Other}
                                            />

                                            <RegistrationFormCheckbox
                                                model='.OfflineShop'
                                                text='Офлайн-магазин со стендом'
                                                checked={this.props.partner.OfflineShop}
                                            />

                                            {this.props.partner.OfflineShop &&
                                                <RegistrationFormCheckbox
                                                    model='.StandardImageLoading'
                                                    text='Стандартная загрузка картинок'
                                                    checked={this.props.partner.StandardImageLoading}
                                                />
                                            }

                                        </div>
                                    </div>
                                    {
                                        this.props.partner.Other ?
                                            <div className="defaultFormControl">
                                                <TextBox
                                                    Validators={{
                                                        isRequired: (val: string) => !Validator.IsEmpty(val),
                                                        IsMaxLength: MaxLength(75)
                                                    }}

                                                    ErrorMessages={{
                                                        isRequired: '*',
                                                        IsMaxLength: 'TooManyCharacters',
                                                    }}

                                                    ShowErrorOn='touched'
                                                    Type='text'
                                                    ValidatedOn='change'
                                                    IsRequired={true}
                                                    Model='.OtherDescription'
                                                    Placeholder={'Путь сбыта'} />
                                            </ div>
                                            : null
                                    }
                                </div>

                                <div className="form_col_component last validations__component">
                                    {
                                        this.props.partnerForm.errors.isAnyProdSelected ?
                                            <Errors
                                                model="partnerForm"
                                                show={true}
                                                messages={{
                                                    isAnyProdSelected: "Нужно выбрать хоть бы один пункт"
                                                }}
                                            /> : null
                                    }

                                    <div>
                                        <div className="form_row_component">
                                            <div className="line_control">
                                                <div className="row_title">Интересующие товары</div>
                                            </div>
                                        </div>

                                        <RegistrationFormCheckbox
                                            model='.PrintSiliconeCases'
                                            text="Силиконовые чехлы с печатью"
                                            checked={this.props.partner.PrintSiliconeCases}
                                        />

                                        <RegistrationFormCheckbox
                                            model='.LeatherCase'
                                            text="Кожанные чехлы"
                                            checked={this.props.partner.LeatherCase}
                                        />

                                        <RegistrationFormCheckbox
                                            model='.SiliconeCases'
                                            text="Силиконовые чехлы"
                                            checked={this.props.partner.SiliconeCases}
                                        />

                                        <RegistrationFormCheckbox
                                            model='.GlassAndFilm'
                                            text="Стекло и пленки"
                                            checked={this.props.partner.GlassAndFilm}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">"Нова пошта" АПИ</div>
                                </div>
                            </div>

                            <div className="form_row_component">
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Ключ АПИ</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.NovaPoshtaApiKey'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Логин</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.NovaPoshtaLogin'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Пароль</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.NovaPoshtaPass'
                                    />
                                </div>
                            </div>

                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">"Укр Пошта" АПИ</div>
                                </div>
                            </div>

                            <div className="form_row_component">
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">UkrPoshta Api ConterPartyToken</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.UkrPoshtaApiConterPartyToken'
                                    />
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">UkrPoshta Api Bearer</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.UkrPoshtaApiBearer'
                                    />
                                </div>

                            </div>
                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">"Укр Пошта" Банковські дані</div>
                                </div>
                            </div>

                            <div className="form_row_component">
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">IBAN</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.BankAccount'
                                    />
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Tin</div>
                                    <TextBox
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        Model='.Tin'
                                    />
                                </div>

                            </div>
                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">Контактное лицо</div>
                                </div>
                            </div>

                            <div className="form_row_component contRow">
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Имя и фамилия</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isCyrylic: (val: string) => Validator.IsCyrylicLetters(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isCyrylic: 'Поле должно содержать только кириллицу',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactName'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Контактный телефон</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isPhone: (val: string) => Validator.IsPhone(val),
                                            IsMaxLength: MaxLength(10)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isPhone: "Не валидный номер телефона",
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        MaxLength={10}
                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactPhone'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Email</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isEmail: (val: string) => Validator.IsEmail(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactEmail'
                                    />
                                </div>

                            </div>

                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">Руководитель</div>
                                </div>
                            </div>

                            <div className="form_row_component contRow">

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Имя руководителя</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isCyrylic: (val: string) => Validator.IsCyrylicLetters(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.DirectorsName'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Телефон руководителя</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isPhone: (val: string) => Validator.IsPhone(val),
                                            IsMaxLength: MaxLength(10)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.DirectorsPhone'
                                    />
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Email руководителя</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isEmail: (val: string) => Validator.IsEmail(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.DirectorsEmail'
                                    />
                                </div>


                            </div>

                            <div className="form_row_component">
                                <div className="line_control mTitleFix">
                                    <div className="row_title">Метод рассчета</div>
                                </div>
                            </div>

                            <div className="form_row_component">
                                <div className="selectWrapper__container notPadd">
                                    <Select
                                        name="partnerPaymentMethod"
                                        options={this.props.partnerInfo.partnerPaymentMethods}
                                        onChange={(value) => this.props.ChangePartnerPaymentMethod(value)}
                                        value={this.props.partnerInfo.selectedPartnerPaymentMethod}
                                    />
                                </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="rightPullControls">
                                    <button type="submit" className={"defaultFormButton mWidth nMar"}>Изменить</button>
                                </div>
                            </div>
                        </ Form>
                        :
                        <Loader />
                    }
                    <div className="form_row_component">
                        <div className="line_control">
                            <div className="row_title">Действия с пользователем</div>
                        </div>
                        {
                            this.props.partner.NetUid ?
                                this.props.partner.IsRegistered ?

                                    <div className="controls">
                                        <div className="button_control default" onClick={this.OnOpenUserNameChangeViewCommand.bind(this)}>
                                            Обновить имя пользователя
                                        </div>
                                        <div className="button_control default" onClick={this.OnOpenPasswordChangeViewCommad.bind(this)}>
                                            Обновить пароль
                                        </div>
                                        <div className="button_control default" onClick={this.OnOpenRoleChangeViewCommand.bind(this)}>
                                            Изменить роль
                                        </div>
                                    </div> :
                                    <div className="controls">
                                        <div className="button_control default" onClick={this.OnOpenUserRegistrationViewCommand.bind(this)}>
                                            Создание пользователя
                                        </div>
                                    </div> :
                                null
                        }
                    </ div>
                </div>

                {
                    this.props.RightViewData ?
                        <RightView Title={this.props.RightViewData.Title}
                            CloseFunction={this.props.RightViewData.CloseFunction.bind(this)}
                            Component={this.props.RightViewData.Component}
                        />
                        : null
                }
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerInfo);
