import { StatisticsTypeEnum, GetStatisticsTypesForSelectForBoss, GetStatisticsTypesForSelectForCoordinator } from "../../helpers/enums/statistics.type.enum";
import { GetTimePartArray } from "../../helpers/enums/time.part.enum";
import {GetStatisticsTypesForDiagrams} from "../../helpers/enums/diagram.stats.enum";
export class StatisticsState {
    Fetching: boolean;
    StatisticsEntity: any;
    SelectedType: any;
    TypesList: any[];
    SelectedPartner: any; 
    PartnerList: any[];
    StartDate: any;
    EndDate: any;
    DiagramTypes: any;
    SelectedDiagramType: any;
    SelectedDiagrammStat: any;
    DiagrammMode: boolean;
    SelectedPartnerList: any[];
    SelectedStatsList: any[];
    StatsList: any[];
    PartnerForList: any;
    TimeParts: any[];
    SelectedTimePart: any;
    DiagramData: any[];
    DiagramStartDate: any;
    DiagramEndDate: any;
}

export const DefaultStatisticsState: StatisticsState = {
    Fetching: false,
    StatisticsEntity: null,
    SelectedType: GetStatisticsTypesForSelectForCoordinator()[0],
    TypesList: GetStatisticsTypesForSelectForCoordinator(),
    SelectedPartner: null,
    PartnerList: [],
    StartDate: null,
    EndDate: null,
    DiagrammMode: false,
    DiagramTypes: GetStatisticsTypesForDiagrams(),
    SelectedDiagramType: GetStatisticsTypesForDiagrams()[0],
    SelectedDiagrammStat: GetStatisticsTypesForDiagrams()[0].subOptions[0],
    StatsList: GetStatisticsTypesForDiagrams()[0].subOptions,
    SelectedPartnerList: [],
    SelectedStatsList: [],
    PartnerForList: null,
    TimeParts: GetTimePartArray,
    SelectedTimePart: GetTimePartArray[2],
    DiagramData: [],
    DiagramStartDate: null,
    DiagramEndDate: null
}
