import * as API from '../../constants/api.constants';
import * as priceActions from '../../actions/stock/price.actions';
import * as rightViewActions from '../../actions/right.view.actions';
import { actions } from 'react-redux-form';
import { ApplicationState } from '../../reducers/index';
import { Category } from '../../business.entities/category';
import { CreateWholesaleSuccess, SetWholesaleList, UpdateWholesaleSuccess } from '../../actions/stock/price.actions';
import { debounce } from 'rxjs/operator/debounce';
import { error, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { List } from 'linqts';
import { Observable } from 'rxjs/Observable';
import { Partner } from '../../business.entities/partner';
import { PartnerPrice } from '../../business.entities/partner.price';
import { PartnerWholesale } from '../../business.entities/partnerWholesale';
import { Product } from '../../business.entities/product';
import storeProvider from '../../helpers/store.provider';

export const GetPartnerAutocomplete = (action$, store) =>
	action$.ofType(priceActions.GET_PARTNERS_AUTOCOMPLETE)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_PARTNER_AUTOCOMPLETE}?value=${action.payload ? action.payload : ''}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					let arrayToReturn: any[] = [];
					response.Body.forEach((element: Partner) => {
						arrayToReturn.push({ value: element.NetUid.toString(), label: element.ContactName, partnerId: element.Id });
					});

					return priceActions.SetPartnersAutocomplete(arrayToReturn);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetDefaultPriceSegments = (action$, store) =>
	action$.ofType(priceActions.GET_ALL_DEFAULT_PRICES)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_ALL_DEFAULT_PRICE_SEGMENTS}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					storeProvider.getStore().dispatch(success({
						title: 'Цены и ставки успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return priceActions.SetAllPrices(response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetPriceSegmentsForPartner = (action$, store) =>
	action$.ofType(priceActions.GET_PRICE_SEGMENTS_FOR_PARTNER)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_PARTNER_PRICE_SEGMENT}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					storeProvider.getStore().dispatch(success({
						title: 'Цены и ставки успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));
					let pricesSegments: PartnerPrice[] = response.Body;
					if ((storeProvider.getStore().getState() as ApplicationState).stock.price.products.length > 0) {
						let products: Product[] = (storeProvider.getStore().getState() as ApplicationState).stock.price.products;

						products.forEach((element: Product) => {
							let priceSegment: PartnerPrice = new List(pricesSegments)
								.Where(c => c.LexiconId === element.LexiconId)
								.FirstOrDefault();

							let priceSegmentIndex: number = pricesSegments.indexOf(priceSegment);
							if (priceSegmentIndex !== -1)
								pricesSegments[priceSegmentIndex].Price = element.PartnerPrice;
						});
					}
					
					return priceActions.SetAllPrices(pricesSegments);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetPriceCategorySelectList = (action$, store) =>
	action$.ofType(priceActions.GET_PRICE_CATEGORY_SELECT_LIST)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					let arrayToReturn: any[] = [];
					response.Body.forEach((element: Category) => {
						arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
					});

					return priceActions.SetPriceCategorySelectList(arrayToReturn);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetPriceSubcategorySelectList = (action$, store) =>
	action$.ofType(priceActions.GET_PRICE_SUBCATEGORY_SELECT_LIST)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_CATEGORY_FOR_AUTOCOMPLETE}?id=${action.payload.parent.value}&value=${action.payload.categoryName}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					let arrayToReturn: any[] = [];
					response.Body.forEach((element: Category) => {
						arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
					});

					return priceActions.SetPriceSubategorySelectList(arrayToReturn);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const UpdatePartnerPriceSegment = (action$, store) =>
	action$.ofType(priceActions.UPDATE_PARTNER_PRICE_SEGMENT)
		.switchMap(action =>
			Observable.ajax.post(`${API.UPDATE_PARTNER_PRICE_SEGMENT}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {
					let partnerPriceItem = new List<PartnerPrice>((storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments)
						.Where(c => c.Id === response.response.Body.Id && c.LexiconId === response.response.Body.LexiconId)
						.FirstOrDefault();
					let index = (storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments.indexOf(partnerPriceItem);

					const priceSegments = (storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments;

					priceSegments[index] = response.response.Body;

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					storeProvider.getStore().dispatch(actions.reset('partnerPriceForm'));
					storeProvider.getStore().dispatch(success({
						title: 'Ценовой уровень успешно обновлён',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return priceActions.UpdatePartnerPriceSegmentSuccess(priceSegments);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const CreatePartnerPriceSegment = (action$, store) =>
	action$.ofType(priceActions.CREATE_PARTNER_PRICE_SEGMENT)
		.switchMap(action =>
			Observable.ajax.post(`${API.CREATE_PARTNER_PRICE_SEGMENT}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {

					let partnerPriceItem = new List<PartnerPrice>((storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments)
						.Where(c => c.LexiconId === response.response.Body.LexiconId)
						.FirstOrDefault();
					let index = (storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments.indexOf(partnerPriceItem);

					const priceSegments = (storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments;

					priceSegments[index] = response.response.Body;

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					storeProvider.getStore().dispatch(actions.reset('partnerPriceForm'));
					storeProvider.getStore().dispatch(success({
						title: 'Ценовой уровень успешно добавлен партнёру',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return priceActions.CreatePartnerPriceSegmentSuccess(priceSegments);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetPropductsForPrices = (action$, store) =>
	action$.ofType(priceActions.GET_PRODUCTS_FOR_PRICES)
		.switchMap(action => {
			return Observable.ajax.getJSON(`${API.GET_PRODUCT_FILTERED_PAGINATED}?count=50&page=1&lexiconId=null&categoryId=${action.payload.categoryId}&subCategoryId=${action.payload.subcategoryId}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					storeProvider.getStore().dispatch(success({
						title: 'Цены успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					if (response.Body.Collection.length > 0) {
						let pricesSegments = (storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments;
						pricesSegments.forEach((price) => {
							price.Price = 0;
							price.D1Price = 0;
						});

						response.Body.Collection.forEach((element: Product) => {
							let priceSegment: PartnerPrice = new List(pricesSegments)
								.Where(c => c.LexiconId === element.LexiconId)
								.FirstOrDefault();
							let priceSegmentIndex: number = pricesSegments.indexOf(priceSegment);
							if (priceSegmentIndex !== -1)
								pricesSegments[priceSegmentIndex].Price = element.PartnerPrice !== 0 ? element.PartnerPrice : element.Price;
						});
						(storeProvider.getStore().getState() as ApplicationState).stock.price.partnerPriceSegments = pricesSegments;
					}

					return priceActions.SetProductsForPrices(response.Body.Collection);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		});

export const GetTerm = (action$, store) =>
	action$.ofType(priceActions.GET_PRICE_TERMS)
		.switchMap(action => {
			return Observable.ajax.getJSON(`${API.GET_PRICE_TERMS}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					storeProvider.getStore().dispatch(success({
						title: 'Условия успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(actions.reset("priceLevelTerms"));

					return actions.change("priceLevelTerms", response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		});

export const CreateTerm = (action$, store) =>
	action$.ofType(priceActions.CREATE_PARTNER_PRICE_TERMS)
		.switchMap(action => {
			return Observable.ajax.post(`${API.CREATE_PRICE_TERMS}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {

					storeProvider.getStore().dispatch(success({
						title: 'Условия успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(actions.reset("priceLevelTerms"));

					return actions.change("priceLevelTerms", response.response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				});
		});

export const UpdateTerm = (action$, store) =>
	action$.ofType(priceActions.UPDATE_PARTNER_PRICE_TERMS)
		.switchMap(action =>
			Observable.ajax.post(`${API.UPDATE_PRICE_TERMS}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {

					storeProvider.getStore().dispatch(success({
						title: 'Условия успешно получены',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(actions.reset("priceLevelTerms"));

					return actions.change("priceLevelTerms", response.response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const RemovePriceSegment = (action$, store) =>
	action$.ofType(priceActions.REMOVE_PRICE_SEGMENT)
		.switchMap(action =>
			Observable.ajax.delete(`${API.DELETE_PARTNER_PRICE_SEGMENT}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					storeProvider.getStore().dispatch(actions.reset('partnerPriceForm'));
					storeProvider.getStore().dispatch(success({
						title: 'Ценовой уровень успешно обновлён',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return priceActions.GetPriceSergmentsForPartner((storeProvider.getStore().getState() as ApplicationState).stock.price.selectedPartner.value);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const RemovePriceTerm = (action$, store) =>
	action$.ofType(priceActions.REMOVE_PRICE_TERM)
		.switchMap(action =>
			Observable.ajax.delete(`${API.DELETE_PRICE_TERMS}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					storeProvider.getStore().dispatch(actions.reset('partnerPriceTrems'));
					storeProvider.getStore().dispatch(success({
						title: 'Ценовой уровень успешно обновлён',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return actions.change('priceLevelTerms', response.response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetWholesaleList = (action$, store) =>
	action$.ofType(priceActions.GET_WHOLESALE_LIST)
		.switchMap(action =>
			Observable.ajax.getJSON(`${API.GET_WHOLESALE}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					return SetWholesaleList(response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const CreateWholesale = (action$, store) =>
	action$.ofType(priceActions.CREATE_WHOLESALE)
		.switchMap(action =>
			Observable.ajax.post(`${API.CREATE_WHOLESALE}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {
					let wholesaleList: PartnerWholesale[] = (storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList;

					let wholesaleToChange: PartnerWholesale = new List((storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList)
						.Where(c => c.LexiconId === response.response.Body.LexiconId)
						.FirstOrDefault()

					let index: number = (storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList.indexOf(wholesaleToChange);

					wholesaleList[index] = response.response.Body;

					storeProvider.getStore().dispatch(success({
						title: "",
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					return CreateWholesaleSuccess(wholesaleList)
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const UpdateWholesale = (action$, store) =>
	action$.ofType(priceActions.UPDATE_WHOLESALE)
		.switchMap(action =>
			Observable.ajax.post(`${API.UPDATE_WHOLESALE}`,
				JSON.stringify(action.payload),
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
				.map((response: any) => {
					let wholesaleList: PartnerWholesale[] = (storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList;

					let wholesaleToChange: PartnerWholesale = new List((storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList)
						.Where(c => c.LexiconId === response.response.Body.LexiconId)
						.FirstOrDefault()

					let index: number = (storeProvider.getStore().getState() as ApplicationState).stock.price.wholesaleList.indexOf(wholesaleToChange);

					wholesaleList[index] = response.response.Body;

					storeProvider.getStore().dispatch(success({
						title: "",
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					return UpdateWholesaleSuccess(wholesaleList)
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const RemoveWholesale = (action$, store) =>
	action$.ofType(priceActions.REMOVE_WHOLESALE)
		.switchMap(action =>
			Observable.ajax.delete(`${API.DELETE_WHOLESALE}?netId=${action.payload}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					storeProvider.getStore().dispatch(actions.reset('wholesaleForm'));
					storeProvider.getStore().dispatch(success({
						title: 'Ценовой уровень успешно обновлён',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

					return priceActions.GetWholesaleList((storeProvider.getStore().getState() as ApplicationState).stock.price.selectedPartner.value);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		);

export const GetHistory = (action$, store) =>
	action$.ofType(priceActions.GET_PARTNER_PRICE_HISTORY)
		.switchMap(action => {
			return Observable.ajax.get(`${API.GET_PARTNER_HISTORY}?netId=${action.payload.partnerNetId ? action.payload.partnerNetId : ""}&entityName=${action.payload.entityName}&dateFrom=${action.payload.dateFrom}&dateTo=${action.payload.dateTo}`,
				{ "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
				.map((response: any) => {
					storeProvider.getStore().dispatch(success({
						title: 'История успешно получена',
						message: '',
						position: 'br',
						autoDismiss: 3,
					}));

					return priceActions.SetPartnerHistory(response.response.Body);
				}).catch((errorData: any) => {
					return Observable.of(ErrorHandler(errorData));
				})
		});