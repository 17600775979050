import { ofType } from 'redux-observable';
import * as orderCardActions from '../../actions/sales/order.card.actions';
import { mergeMap, map, catchError, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ErrorHandler } from '../../helpers/error.manager';
import { Observable } from 'rxjs';
import * as API from '../../constants/api.constants';
import { Client } from '../../business.entities/client';
import storeProvider from '../../helpers/store.provider';
import { Order } from '../../business.entities/order';
import { GetPrintStatusName, PrintStatusEnum } from '../../helpers/enums/print.status.enum';
import { PrintVerificationStatusEnum } from '../../helpers/enums/print.verification.status.enum';
import { GetProductionStatusName } from '../../helpers/enums/production.status';
import { OrderProduct } from '../../business.entities/order.product';
import { List } from 'linqts';
import Category from '../../business.entities/category';
import { OrderCardState } from '../../states/sales/order.card.state';
import { success } from 'react-notification-system-redux';
import { RemoveRVData, SelectRVData } from '../../actions/right.view.actions';
import { error as errorNotification, success as successNotification } from 'react-notification-system-redux';
import { GetLaserStatusName } from '../../helpers/enums/laser.status.enum';


export const GetOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_ORDER_FOR_ORDER_CARD),
        mergeMap((action: any) => {
            return ajax.getJSON(`${API.GET_ORDER_BY_UID}?netUid=${action.payload}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        //go to order base and show notification
                        let order: Order = (response as any).Body;

                        order.OrderProducts.forEach(product => {
                            if (product.ProductPrintStatus.length > 0)
                                product.SelectedPrintStatus = {
                                    value: product.ProductPrintStatus[0].Status,
                                    label: GetPrintStatusName(product.ProductPrintStatus[0].Status)
                                };

                            if (product.PrintVerificationStatus)
                                product.SelectedVerificationStatus = {
                                    value: product.PrintVerificationStatus,
                                    label: product.PrintVerificationStatus == null ?
                                        'Выберите статус' :
                                        (product.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                                }

                            product.SelectedReuseOption = {
                                value: product.CanBeReused === null ? undefined : product.CanBeReused,
                                label: product.CanBeReused === null ?
                                    'Выберите опцию' :
                                    (product.CanBeReused === false ? 'Не использовать' : 'Использовать')
                            }

                            if (product.ProductProductionStatus.length > 0)
                                product.SelectedProductionStatus = {
                                    value: product.ProductProductionStatus[0].Status,
                                    label: GetProductionStatusName(product.ProductProductionStatus[0].Status)
                                };

                            if (product.ProductLaserStatus.length > 0)
                                product.SelectedLaserStatus = {
                                    value: product.ProductLaserStatus[0].Status,
                                    label: GetLaserStatusName(product.ProductLaserStatus[0].Status)
                                };
                        });

                        return orderCardActions.SetOrderForOrderCard(order);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )


export const UpdateOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPDATE_ORDER),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(RemoveRVData());

                        return orderCardActions.SetOrderForOrderCard((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateOrderSilent = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPDATE_ORDER_SILENT),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        return orderCardActions.SetOrderForOrderCard((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetClientsForPartner = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_CLIENTS_FOR_PARTNER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CLIENTS_BY_PARTNTER_AND_PHONE}?search=${action.payload.value ? action.payload.value : ''}&uid=${action.payload.uid ? action.payload.uid : ''}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Client) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.PhoneNumber, object: element });
                        });

                        if (arrayToReturn.length === 0) {
                            if ((action.payload.startsWith('0', 0) && action.payload.length === 10)
                                || (action.payload.startsWith('38', 0) && action.payload.length === 12)) {

                                let promise = new Promise((resolve, reject) => {
                                    resolve(storeProvider.getStore().dispatch(orderCardActions.SetClientPhone(action.payload, true)));
                                });

                                promise.then(() => {
                                    storeProvider.getStore().getState().sales.orderCard.clientPhoneInputRef.current.focus();
                                });
                            }
                        }

                        return orderCardActions.SetClientsForPartner(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetRegionFromUP = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE),
        mergeMap((action: any) =>
            ajax.get(`${API.GET_REGION_UKR_POSHTA}?regionUkrPoshta=${action.payload ? action.payload : ''}&UkrPoshtaApiBearer=${state$.value.authentication.userInfo.UkrPoshtaApiBearer ? state$.value.authentication.userInfo.UkrPoshtaApiBearer : ''}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        return orderCardActions.SetRegionUkrPoshtaForAutocomplete(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )
export const GetAreaFromUP = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE),
        mergeMap((action: any) =>
            ajax.get(`${API.GET_AREA_UKR_POSHTA}?idAreaUkrPoshta=${state$.value.sales.orderCard.selectedUkrPoshtaRegionId ? state$.value.sales.orderCard.selectedUkrPoshtaRegionId : ''}&changeAreaUkrPoshta=${action.payload ? action.payload : ''}&UkrPoshtaApiBearer=${state$.value.authentication.userInfo.UkrPoshtaApiBearer ? state$.value.authentication.userInfo.UkrPoshtaApiBearer : ''}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        return orderCardActions.SetAreaUkrPoshtaForAutocomplete(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetCityFromUP = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE),
        mergeMap((action: any) =>
            ajax.get(`${API.GET_CITY_UKR_POSHTA}?changeCityUkrPoshta=${action.payload ? action.payload : ''}&UkrPoshtaApiBearer=${state$.value.authentication.userInfo.UkrPoshtaApiBearer ? state$.value.authentication.userInfo.UkrPoshtaApiBearer : ''}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        return orderCardActions.SetCityUkrPoshtaForAutocomplete(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetCitiesFromNP = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_CITIES_FOR_AUTOCOMPLETE),
        mergeMap((action: any) =>
            ajax.post(`${API.NOVA_POSHTA_GET_CITIES}`,
                JSON.stringify({
                    apiKey: state$.value.authentication.userInfo.NovaPoshtaApiKey,
                    modelName: 'Address',
                    calledMethod: 'searchSettlements',
                    methodProperties: {
                        CityName: action.payload,
                        Limit: 25
                    }
                }),
                { 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        if (response.response.errors.length > 0 && response.response.errors.indexOf('API key expired') !== -1) {
                            storeProvider.getStore().dispatch(
                                errorNotification({
                                    title: 'Вышел срок действия ключа АПИ Новой Почты',
                                    message: '',
                                    position: 'br',
                                    autoDismiss: 3,
                                })
                            )

                            return orderCardActions.SetCitiesForAutocomplete(arrayToReturn);
                        }

                        if (response.response.data[0])
                            response.response.data[0].Addresses.forEach((element: any) => {
                                arrayToReturn.push({ value: element.DeliveryCity, label: element.Present, entity: element });
                            });

                        return orderCardActions.SetCitiesForAutocomplete(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetWarehousesFromNP = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_WEREHOUSES_FOR_AUTOCOMPLETE),
        mergeMap((action: any) => {
            return ajax.post(`${API.NOVA_POSHTA_GET_WEREHOUSES}`,
                JSON.stringify({
                    cityRef: action.payload,
                    apiKey: state$.value.authentication.userInfo.NovaPoshtaApiKey
                }),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];

                        if (response.response.errors.length > 0 && response.response.errors.indexOf('API key expired') !== -1) {
                            storeProvider.getStore().dispatch(
                                errorNotification({
                                    title: 'Вышел срок действия ключа АПИ Новой Почты',
                                    message: '',
                                    position: 'br',
                                    autoDismiss: 3,
                                })
                            )

                            return orderCardActions.SetCitiesForAutocomplete(arrayToReturn);
                        }

                        if (response.response.data)
                            response.response.data.forEach((element: any) => {
                                arrayToReturn.push({ value: element.Ref, label: element.DescriptionRu, entity: element });
                            });

                        return orderCardActions.SetWerehousesForAutocomplete(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

export const UploadImage = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPLOAD_FILES_FOR_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.UPLOAD_ORDER_PRODUCT_IMAGE_ORDER}?netId=${action.payload.orderUid}&orderProductUid=${action.payload.orderProductUid}&fileType=${action.payload.fileType}`,
                action.payload.formData,
                {
                    'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        let updatedOrderProduct: OrderProduct = (response as any).response.Body;
                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderCard.order.OrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderCard.order.OrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return orderCardActions.SetUpdatedOrderProduct(updatedOrderProduct, index);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RemoveFile = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.REMOVE_FILE_FROM_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.REMOVE_FILE_FROM_ORDER_PRODUCT}?orderProductUid=${action.payload.orderProductUid}&fileType=${action.payload.fileType}`,
                action.payload.formData,
                {
                    'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        let updatedOrderProduct: OrderProduct = (response as any).response.Body;
                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderCard.order.OrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderCard.order.OrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return orderCardActions.SetUpdatedOrderProduct(updatedOrderProduct, index);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPDATE_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER_PRODUCT}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        let updatedOrderProduct: OrderProduct = (response as any).response.Body.orderProductToReturn;

                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderCard.order.OrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderCard.order.OrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return orderCardActions.SetUpdatedOrderProduct(updatedOrderProduct, index);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ProcessFailedOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.PROCESS_FAIL_MULTI),
        mergeMap((action: any) =>
            ajax.post(`${API.PROCESS_FAIL_ORDER_PRODUCT_MULTI}?failCount=${action.payload.failCount}`,
                action.payload.orderProduct,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        //go to order base and show notification
                        let order: Order = (response as any).response.Body;

                        order.OrderProducts.forEach(product => {
                            if (product.ProductPrintStatus.length > 0)
                                product.SelectedPrintStatus = {
                                    value: product.ProductPrintStatus[0].Status,
                                    label: GetPrintStatusName(product.ProductPrintStatus[0].Status)
                                };

                            if (product.PrintVerificationStatus)
                                product.SelectedVerificationStatus = {
                                    value: product.PrintVerificationStatus,
                                    label: product.PrintVerificationStatus == null ?
                                        'Выберите статус' :
                                        (product.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                                }

                            product.SelectedReuseOption = {
                                value: product.CanBeReused === null ? undefined : product.CanBeReused,
                                label: product.CanBeReused === null ?
                                    'Выберите опцию' :
                                    (product.CanBeReused === false ? 'Не использовать' : 'Использовать')
                            }

                            if (product.ProductProductionStatus.length > 0)
                                product.SelectedProductionStatus = {
                                    value: product.ProductProductionStatus[0].Status,
                                    label: GetProductionStatusName(product.ProductProductionStatus[0].Status)
                                };

                            if (product.ProductLaserStatus.length > 0)
                                product.SelectedLaserStatus = {
                                    value: product.ProductLaserStatus[0].Status,
                                    label: GetLaserStatusName(product.ProductLaserStatus[0].Status)
                                };
                        });

                        return orderCardActions.SetOrderForOrderCard(order);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateUserImageComment = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CHANGE_USER_IMAGE_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const orderProducts = storeProvider.getStore().getState().sales.orderCard.order.OrderProducts;

            let orderProduct: OrderProduct = new List<OrderProduct>(orderProducts).FirstOrDefault(c => c.Id === action.payload.id);

            return orderCardActions.UpdateOrderProduct(orderProduct);
        })
    )

export const UpdatePrintComment = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CHANGE_PRINT_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const orderProducts = storeProvider.getStore().getState().sales.orderCard.order.OrderProducts;

            let orderProduct: OrderProduct = new List<OrderProduct>(orderProducts).FirstOrDefault(c => c.Id === action.payload.id);

            return orderCardActions.UpdateOrderProduct(orderProduct);
        })
    )


export const UpdateProductionOperatorComment = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CHANGE_PRODUCTION_OPERATOR_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            return orderCardActions.UpdateOrderSilent(storeProvider.getStore().getState().sales.orderCard.order);
        })
    )

export const UpdateProductionComment = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CHANGE_PRODUCTION_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            return orderCardActions.UpdateOrderSilent(storeProvider.getStore().getState().sales.orderCard.order);
        })
    )

//orderproduct update logic
export const GetCategoriesAutocomplete = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_CATEGORIES),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Category) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                        });

                        return arrayToReturn;
                    }),
                    map(data => orderCardActions.SetCategories(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetSubCategoryList = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_SUBCATEGORIES),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CATEGORY_FOR_AUTOCOMPLETE}?id=${action.payload.id}&value=${action.payload.value}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Category) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                        });

                        return arrayToReturn;
                    }),
                    map(data => orderCardActions.SetSubCategories(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RemoveOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.REMOVE_ORDER_PRODUCT_SERVER),
        mergeMap((action: any) =>
            ajax.delete(`${API.REMOVE_ORDER_PRODUCT}?uid=${action.payload.NetUid}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        //debugger
                        let index = storeProvider.getStore().getState().sales.orderCard.productUpdateSubState.currentOrderProducts.indexOf(action.payload);
                        return orderCardActions.RemoveOrderProductClient(
                            index,
                            (response as any).response.Body.orderTotalsTuple.Item1,
                            (response as any).response.Body.orderTotalsTuple.Item2,
                            (response as any).response.Body.orderProductTuple.Item1,
                            (response as any).response.Body.orderStatusToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetBasesForOrderProducts = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_BASES_FOR_ORDERPRODUCT),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_BASES}?categoryId=${action.payload.categoryId}&subcategoryId=${action.payload.subCategoryId}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => orderCardActions.SetBasesForProduct((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetProducts = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_PRODUCTS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_FOR_WIZARD}?categoryId=${action.payload.categoryId}
                &subCategoryId=${action.payload.subCategoryId}&orderNetId=${action.payload.orderNetId}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => orderCardActions.SetProducts((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetProductTotalPrice = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CALCULATE_PRODUCT_TOTAL),
        debounceTime(1000),
        mergeMap((action: any) =>
            ajax.post(`${API.GET_PRICE_FOR_PRODUCT}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        return orderCardActions.SetTotalForCurrentOrderProduct(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SaveOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.SAVE_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_ORDER_PRODUCT}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        const orderCard: OrderCardState = state$.value.sales.orderCard;

                        let existing = new List<OrderProduct>(orderCard.productUpdateSubState.currentOrderProducts)
                            .Where(c => c.Id === (response as any).response.Body.orderProductTuple.Item2.Id)
                            .FirstOrDefault();
                        let index = -1;

                        if (existing)
                            index = orderCard.productUpdateSubState.currentOrderProducts.indexOf(existing);

                        if (index !== -1) {
                            return orderCardActions.SetOrderProductByIndex(
                                (response as any).response.Body.orderProductTuple.Item2,
                                index,
                                (response as any).response.Body.orderTotalsTuple.Item1,
                                (response as any).response.Body.orderTotalsTuple.Item2,
                                (response as any).response.Body.orderProductTuple.Item1,
                                (response as any).response.Body.orderStatusToReturn);
                        } else {
                            return orderCardActions.SetOrderProduct(
                                (response as any).response.Body.orderProductTuple.Item2,
                                (response as any).response.Body.orderTotalsTuple.Item1,
                                (response as any).response.Body.orderTotalsTuple.Item2,
                                (response as any).response.Body.orderProductTuple.Item1,
                                (response as any).response.Body.orderStatusToReturn);
                        }
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateOrderProductWizard = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPDATE_ORDER_PRODUCT_SERVER),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER_PRODUCT}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        const orderCard: OrderCardState = state$.value.sales.orderCard;

                        let currentOrderProduct = new List<OrderProduct>(orderCard.productUpdateSubState.currentOrderProducts)
                            .Where(c => c.Id === (response as any).response.Body.orderProductToReturn.Id)
                            .FirstOrDefault();

                        let index = orderCard.productUpdateSubState.currentOrderProducts.indexOf(currentOrderProduct);

                        if (action.payload.Id !== (response as any).response.Body.orderProductToReturn.Id) {
                            let orderProductToRemove = new List<OrderProduct>(orderCard.productUpdateSubState.currentOrderProducts)
                                .Where(c => c.Id === action.payload.Id)
                                .FirstOrDefault();
                            let indexToRemove = orderCard.productUpdateSubState.currentOrderProducts.indexOf(orderProductToRemove);

                            if (indexToRemove !== -1) {
                                storeProvider.getStore().dispatch(orderCardActions.RemoveOrderProductClient(
                                    indexToRemove,
                                    (response as any).response.Body.orderTotalsTuple.Item1,
                                    (response as any).response.Body.orderTotalsTuple.Item2,
                                    orderCard.order.OrderType,
                                    orderCard.order.OrderStatus[0]));
                            }
                        }

                        return orderCardActions.UpdateOrderProductClient(
                            (response as any).response.Body.orderProductToReturn,
                            index,
                            (response as any).response.Body.orderTotalsTuple.Item1,
                            (response as any).response.Body.orderTotalsTuple.Item2,
                            (response as any).response.Body.orderStatusToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SetForceWholesale = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.SET_FORCE_WHOLESALE),
        mergeMap((action: any) =>
            ajax.post(`${API.SET_FORCE_WHOLESALE}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        const orderCard: OrderCardState = storeProvider.getStore().getState().sales.orderCard;

                        let currentOrderProduct = new List<OrderProduct>(orderCard.productUpdateSubState.currentOrderProducts)
                            .Where(c => c.Id === action.payload.Id)
                            .FirstOrDefault();

                        let index = orderCard.productUpdateSubState.currentOrderProducts.indexOf(currentOrderProduct);

                        return orderCardActions.UpdateOrderProductClient(
                            (response as any).response.Body.orderProductToReturn,
                            index,
                            (response as any).response.Body.orderTotalsTuple.Item1,
                            (response as any).response.Body.orderTotalsTuple.Item2,
                            (response as any).response.Body.orderStatusToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UploadImageWizard = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.UPLOAD_USER_IMAGE),
        mergeMap((action: any) =>
            ajax.post(`${API.UPLOAD_ORDER_PRODUCT_IMAGE_WIZARD}?netId=${action.payload.tempOrdernetId}`,
                action.payload.formData,
                {
                    'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        return orderCardActions.SetUserImage((response as any).response.Body, action.payload.fileType);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ValidateForStatus = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.VALIDATE_FOR_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.CHECK_STATUS}`,
                JSON.stringify({
                    tempOrderId: action.payload.orderId,
                    status: action.payload.statusId
                }),
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        const selctedFinishStatus = new List(state$.value.sales.orderCard.finishStatus)
                            .Where(c => (c as any).id === action.payload.statusId)
                            .FirstOrDefault();

                        const index = state$.value.sales.orderCard.finishStatus.indexOf(selctedFinishStatus);

                        return orderCardActions.SelectFinishStatus(index, response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateGlobalComment = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.CHANGE_GLOBAL_COMMENT),
        debounceTime(2000),
        map((action: any) => {

            return orderCardActions.UpdateOrder(state$.value.sales.orderCard.order);
        })
    )

export const AddOrderStatus = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.ADD_ORDER_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_ORDER_STATUS}`,
                action.payload,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(success({
                            title: (response as any).response.Message,
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));

                        storeProvider.getStore().dispatch(RemoveRVData());

                        return orderCardActions.SetOrderStatus((response as any).response.Body);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )


export const GetOrderAudit = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_ORDER_AUDIT),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDER_AUDIT}?netId=${action.payload}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        return orderCardActions.SetOrderAudit((response as any).Body)
                    }),
                    catchError(error => Observable.of(ErrorHandler(error)))
                )
        )
    )

export const RefreshOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.REFRESH_ORDERPRODUCT),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDER_PRODUCT_BY_UID}?uid=${action.payload.netid}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        return orderCardActions.SetRefreshedOrderProduct((response as any).Body, action.payload.index);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetProductBasesForOrderCard = (action$, state$) =>
    action$.pipe(
        ofType(orderCardActions.GET_PRODUCT_BASES_FOR_ORDER_CARD),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_BASE_PRODUCTS}?lexiconId=${action.payload.lexiconId}&categoryId=${action.payload.categoryId}&subcategoryId=${action.payload.subcategoryId}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => orderCardActions.GetProductBasesForOrderCardSuccess((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAdditionalServiceProducts = (action$, state$) =>
action$.pipe(
    ofType(orderCardActions.GET_ADDITIONAL_SERVICE_PRODUCTS),
    mergeMap((action: any) =>
        ajax.getJSON(`${API.GET_ADDITIONAL_SERVICE_PRODUCTS}`,
            { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
            .pipe(
                map(response => orderCardActions.SetAdditionalServiceProducts((response as any).Body)),
                catchError(error => {
                    return Observable.of(ErrorHandler(error));
                })
            )
    )
)
