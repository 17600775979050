
export enum StatisticsTypeEnum {
    Finances = 1,
    Partners,
    Production,
    Laser,
    Print,
    Payment,
    Delivery,
    Client,
    Order,
    FinishedProducts
}

export const GetStatisticsTypeName = (status: StatisticsTypeEnum) => {
    switch (status) {
        case StatisticsTypeEnum.Finances:
            return 'Финансы';
        case StatisticsTypeEnum.Partners:
            return 'Партнёры';
        case StatisticsTypeEnum.Production:
            return 'Производство';
        case StatisticsTypeEnum.Laser:
            return 'Текстиль';
        case StatisticsTypeEnum.Print:
            return 'Печать';
        case StatisticsTypeEnum.Payment:
            return 'Оплата';
        case StatisticsTypeEnum.Delivery:
            return 'Доставка';
        case StatisticsTypeEnum.Client:
            return 'Клиенты';
        case StatisticsTypeEnum.Order:
            return 'Заказы';
        case StatisticsTypeEnum.FinishedProducts:
            return 'Товары выполненых заказов';
        default:
            return '';
    }
}

export const GetStatisticsTypesForSelectForBoss = () => [
    { value: StatisticsTypeEnum.Finances, label: GetStatisticsTypeName(StatisticsTypeEnum.Finances) },
    { value: StatisticsTypeEnum.Partners, label: GetStatisticsTypeName(StatisticsTypeEnum.Partners) },
    { value: StatisticsTypeEnum.Production, label: GetStatisticsTypeName(StatisticsTypeEnum.Production) },
    { value: StatisticsTypeEnum.Laser, label: GetStatisticsTypeName(StatisticsTypeEnum.Laser) },
    { value: StatisticsTypeEnum.Print, label: GetStatisticsTypeName(StatisticsTypeEnum.Print) },
    { value: StatisticsTypeEnum.Payment, label: GetStatisticsTypeName(StatisticsTypeEnum.Payment) },
    { value: StatisticsTypeEnum.Delivery, label: GetStatisticsTypeName(StatisticsTypeEnum.Delivery) },
    { value: StatisticsTypeEnum.Client, label: GetStatisticsTypeName(StatisticsTypeEnum.Client) },
    { value: StatisticsTypeEnum.Order, label: GetStatisticsTypeName(StatisticsTypeEnum.Order) },
    { value: StatisticsTypeEnum.FinishedProducts, label: GetStatisticsTypeName(StatisticsTypeEnum.FinishedProducts) },
];

export const GetStatisticsTypesForSelectForCoordinator = () => [
    { value: StatisticsTypeEnum.Finances, label: GetStatisticsTypeName(StatisticsTypeEnum.Finances) },
    { value: StatisticsTypeEnum.Partners, label: GetStatisticsTypeName(StatisticsTypeEnum.Partners) },
    { value: StatisticsTypeEnum.Production, label: GetStatisticsTypeName(StatisticsTypeEnum.Production) },
    { value: StatisticsTypeEnum.Laser, label: GetStatisticsTypeName(StatisticsTypeEnum.Laser) },
    { value: StatisticsTypeEnum.Print, label: GetStatisticsTypeName(StatisticsTypeEnum.Print) },
    { value: StatisticsTypeEnum.Payment, label: GetStatisticsTypeName(StatisticsTypeEnum.Payment) },
    { value: StatisticsTypeEnum.Delivery, label: GetStatisticsTypeName(StatisticsTypeEnum.Delivery) },
    { value: StatisticsTypeEnum.Client, label: GetStatisticsTypeName(StatisticsTypeEnum.Client) },
    { value: StatisticsTypeEnum.Order, label: GetStatisticsTypeName(StatisticsTypeEnum.Order) },
    { value: StatisticsTypeEnum.FinishedProducts, label: GetStatisticsTypeName(StatisticsTypeEnum.FinishedProducts) },
];

export const GetStatisticsTypesForSelectForPartner = () => [
    { value: StatisticsTypeEnum.Finances, label: GetStatisticsTypeName(StatisticsTypeEnum.Finances) },
    { value: StatisticsTypeEnum.Order, label: GetStatisticsTypeName(StatisticsTypeEnum.Order) },
    { value: StatisticsTypeEnum.Payment, label: GetStatisticsTypeName(StatisticsTypeEnum.Payment) },
    { value: StatisticsTypeEnum.Delivery, label: GetStatisticsTypeName(StatisticsTypeEnum.Delivery) },
    { value: StatisticsTypeEnum.Client, label: GetStatisticsTypeName(StatisticsTypeEnum.Client) },
];

export const GetStatisticsTypesForSelectForPrint = () => [
    { value: StatisticsTypeEnum.Print, label: GetStatisticsTypeName(StatisticsTypeEnum.Print) },
];

export const GetStatisticsTypesForSelectForManufacture = () => [
    { value: StatisticsTypeEnum.Production, label: GetStatisticsTypeName(StatisticsTypeEnum.Production) },
];

export const GetStatisticsTypesForSelectForLasercut = () => [
    { value: StatisticsTypeEnum.Laser, label: GetStatisticsTypeName(StatisticsTypeEnum.Laser) },
];