import { List } from "linqts";
import { MenuModel } from "./menu.model";

export class MenuProvider {

    private _menuContainer: Array<MenuModel> = [
        new MenuModel("stock", 'Cклад', '', true, false, ['Administrator', 'Boss', 'User', 'Partner', 'Admin', 'Coordinator', 'Sklad', 'Maysternya'], [
            new MenuModel("stock/products", 'Товары', '', false, true, ['Administrator', 'Boss', 'Admin']),
            new MenuModel("stock/lexicons", 'Лексиконы', '', false, true, ['Administrator', 'Admin', 'Boss']),
            new MenuModel("stock/prices", 'Ценообразование', '', false, true, ['Administrator', 'Boss',]),
            new MenuModel("stock/availability", 'Наличие и цены', '', false, true, ['Administrator', 'User', 'Partner', 'Boss', 'Coordinator', 'Sklad', 'Maysternya']),
            new MenuModel("stock/price-list", 'Выгрузка/прайс', '', false, true, ['Administrator', 'Boss', 'Coordinator', 'Maysternya']),
        ]),
        new MenuModel("sales", 'Продажи', '', true, false, ['Administrator', 'Boss', 'User', 'Partner', 'Booker', 'Coordinator', 'Manufacture', 'Sklad', 'Print', 'Lasercut', 'Maysternya'], [
            new MenuModel("sales/create-order", 'Создать заказ', '', false, true, ['Administrator', 'Boss', 'User', 'Partner', 'Coordinator']),
            new MenuModel("sales/order-base", 'Заказы', '', false, true, ['Administrator', 'Boss', 'User', 'Partner', 'Booker', 'Coordinator', 'Manufacture', 'Sklad', 'Print', 'Lasercut', 'Maysternya']),
            new MenuModel("sales/printers-base", 'Друкари', '', false, true, ['Administrator', 'Boss']),
            new MenuModel("sales/clients-base", 'Клиенты', '', false, true, ['Administrator', 'Boss', 'User', 'Partner', 'Coordinator']),
        ]),
        new MenuModel("partners", 'Партнеры', '', true, false, ['Administrator', 'Boss', 'Coordinator'], [
            new MenuModel("partners/accounts", 'Аккаунты', '', false, true, ['Administrator', 'Boss', 'Coordinator']),
            new MenuModel("partners/promocodes", 'Промокоды', '', false, true, ['Administrator', 'Boss']),
        ]),
        new MenuModel("cabinet", 'Личный кабинет', '', true, false, ['Administrator', 'User', 'Boss', 'Booker', 'Partner', 'Coordinator', 'Maysternya'], [
            new MenuModel("cabinet/client-info", 'Персональная информация', '', false, true, ['Boss', 'Administrator', 'Partner']),
            new MenuModel("cabinet/constructor-info", 'Конструктор', '', false, true, ['Boss', 'Administrator', 'Coordinator', 'Partner', 'Maysternya']),
            // new MenuModel("cabinet/statistics", 'Статистика', '', false, true, ['Administrator', 'User', 'Boss' ]),
            // new MenuModel("cabinet/chat", 'Чат', '', false, true, ['Administrator', 'Boss' ]),
            new MenuModel("cabinet/mutual-settlements", 'Взаимо-расчеты', '', false, true, ['Administrator', 'Booker', 'User', 'Boss', 'Partner', 'Coordinator']),
            new MenuModel("cabinet/tutorial", 'Видео инструкции', '', false, true, ['Administrator', 'Booker', 'User', 'Boss', 'Partner', 'Coordinator']),
            new MenuModel("cabinet/statistics", 'Статистика', '', false, true, ['Administrator', 'Boss', 'Coordinator', 'Partner', 'Print', 'Manufacture', 'Lasercut']),
            new MenuModel("cabinet/printing-stats", 'Статистика печати', '', false, true, ['Administrator', 'Boss'])
        ])
    ];

    public GetRootMenuItems(): Array<MenuModel> {
        return new List(this._menuContainer).Where((c: MenuModel) => c.IsRoot).Select((c: MenuModel) => c).ToArray();
    }

    public GetRoutes(role: string): MenuModel[] {
        let routesToReturn: MenuModel[] = [];

        new List(this._menuContainer)
            .Where(c => c.Roles.indexOf(role) !== -1).ForEach(c => {
                routesToReturn.push(c);
                const childs = this.GetRouteChilds(c, role);
                routesToReturn = routesToReturn.concat(childs);
            });

        new List(routesToReturn).ForEach(c => {
            c.ChildMenuItems = [];
        })

        return routesToReturn;
    }

    private GetRouteChilds(route: MenuModel, role: string): MenuModel[] {
        let childsToReturn = [];
        new List(route.ChildMenuItems).Where(x => x.Roles.indexOf(role) !== -1).ForEach(c => {
            childsToReturn.push(c);
            let childs = this.GetRouteChilds(c, role);
            if (childs.length > 0)
                childsToReturn = childsToReturn.concat(childs);

        });

        return childsToReturn;
    }

    public GetFirstAllowedRoutesForRole(role: string, parentRoute: string) {
        let parntMenuItem = new List(this._menuContainer)
            .Where((c: MenuModel) => c.Route === parentRoute)
            .Select((c: MenuModel) => c)
            .FirstOrDefault();

        return new List(parntMenuItem.ChildMenuItems)
            .Where((c: MenuModel) => c.Roles.indexOf(role) != -1)
            .Select((c: MenuModel) => c.Route)
            .FirstOrDefault();
    }
}