import { EntityBase } from "./entity.base";
import { PartnerPrice } from "./partner.price";
import { Client } from "./client";
import { PriceLevel } from "../helpers/enums/price.level.enum";
import * as moment from 'moment';
import { WatermarkPositionEnum } from "../helpers/enums/watermark.position.enum";
import { PartnerPaymentMethodEnum } from "../helpers/enums/partner.payment.method.enum";

export class Partner extends EntityBase {
    public OrganizationName: string = '';
    public OrganizationCity: string = '';
    public PageUrl: string = '';

    public ContactName: string = '';
    public ContactPhone: string = '';
    public ContactEmail: string = '';

    public DirectorsName: string = '';
    public DirectorsPhone: string = '';
    public DirectorsEmail: string = '';

    public OtherDescription: string = '';

    public OnlineShop: boolean = false;
    public LocalShop: boolean = false;
    public Instagram: boolean = false;
    public Vkontakte: boolean = false;
    public Other: boolean = false;
    public OfflineShop: boolean = false;

    public StandardImageLoading: boolean = false;

    public PrintSiliconeCases: boolean = false;
    public PrintLeatherCase: boolean = false;
    public LeatherCase: boolean = false;
    public SiliconeCases: boolean = false;
    public GlassAndFilm: boolean = false;

    public IsRegistered: boolean = false;

    public PartnerPrices: PartnerPrice[] = [];
    public PriceTerms: any[] = [];

    public Clients: Client[] = [];

    public PriceLevel: PriceLevel;

    public NovaPoshtaApiKey: string = '';
    public NovaPoshtaLogin: string = '';
    public NovaPoshtaPass: string = '';


    public UkrPoshtaApiKey: string = '';
    public UkrPoshtaLogin: string = '';
    public UkrPoshtaPass: string = '';

    public CityPostOffice: string = '';
    public PostCodePostOffice: string = '';
    public StreetCodePostOffice: string = '';

    public Balance: number = 0;

    public MinimumPriceLevel: PriceLevel = null;

    public EnabledConstructorWatermark: boolean = false;
    public WatermarkUrl: string = '';
    public WatermarkPosition: WatermarkPositionEnum = WatermarkPositionEnum.BottomRight;

    public PartnerPaymentMethod: PartnerPaymentMethodEnum = PartnerPaymentMethodEnum.EarlyPayment;
}

export default Partner;