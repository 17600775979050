import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { WizardState } from '../../../../states/sales/wizard.state';
import { default as OrderProductStep } from "./order.product.step";
import { default as InfoStep } from "./info.step";
import {
    SelectWizardStep,
    GetClientsForPartner,
    OpenFinishMenu,
    GetCitiesForAutocomplete,
    SelectFinishStatus,
    AddNewOrderWithStatus,
    ProcessTempOrder,
    ValidateForStatus,
    ChangeGlobalComment,
    UnmountComponent,
    CheckForTempOrder,
    CalculateSendDate,
    SetProcessingOrderState,
    GetDeliveryPrice,
    GetCategories,
    GetTempOrder,
    ClearWizard
} from '../../../../actions/sales/wizard.actions';
import { Route, withRouter } from 'react-router';
import { default as PopupWrapper } from "./addOrderProductSteps/wrapper";
import { List } from 'linqts';
import { Order } from '../../../../business.entities/order';
import { OrderStatusEnum } from '../../../../helpers/enums/order.status.enum';
import { Loader } from '../../../shared/loader';

export interface IWizardProps {
    Wizard: WizardState;
    SelectWizardStep(step: React.ComponentClass);
    GetClientsForPartner(serach: string);
    GetCitiesForAutocomplete(name: string);
    ProcessTempOrder(uid: any);
    ValidateForStatus(guid, statusId);
    ChangeGlobalComment(comment);
    UnmountComponent();
    CheckForTempOrder();
    CalculateSendDate(orderType);
    SetProcessingOrderState();
    ClearWizard();
}

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectWizardStep,
    GetClientsForPartner,
    OpenFinishMenu,
    GetCitiesForAutocomplete,
    SelectFinishStatus,
    AddNewOrderWithStatus,
    ProcessTempOrder,
    ValidateForStatus,
    ChangeGlobalComment,
    UnmountComponent,
    CheckForTempOrder,
    CalculateSendDate,
    SetProcessingOrderState,
    GetDeliveryPrice,
    GetCategories,
    GetTempOrder,
    ClearWizard
}, dispatch);

class Wizard extends React.Component<IWizardProps | any, any>  {

    constructor(props) {
        super(props);

        if (props.match.params.netId != null && !props.Wizard.order.Id)
            props.GetTempOrder(props.match.params.netId);

        props.GetDeliveryPrice(props.Wizard.order.DeliveryType, props.Wizard.order.PaymentMethod);
        props.GetCategories("");

        document.onkeydown = (e) => {
            if (props.Wizard.isFinishMenuOpen) {
                if ((event as any).ctrlKey && (event as any).keyCode == 70) {
                    event.preventDefault();
                }

                if ((event as any).keyCode == 38) {
                    event.preventDefault();
                }

                if ((event as any).keyCode == 40) {
                    event.preventDefault();
                }

                if ((event as any).keyCode === 37) {
                    event.preventDefault();
                }

                if ((event as any).keyCode === 39) {
                    event.preventDefault();
                }

                if ((event as any).keyCode == 13 && props.Wizard.currentProduct) {
                    event.preventDefault();
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    public OnOpenProductStep() {
        this.props.SelectWizardStep(OrderProductStep, 1);
    }

    public OnOpenInfotStep() {
        this.props.SelectWizardStep(InfoStep, 2);
        this.props.GetCitiesForAutocomplete('а');

        this.props.CalculateSendDate((this.props.Wizard.order as Order).OrderType ? (this.props.Wizard.order as Order).OrderType : 1);

        if (this.props.Wizard.clients.length === 0)
            this.props.GetClientsForPartner();
    }

    public OnOpenFinishMenuCommand() {
        this.props.OpenFinishMenu(!this.props.Wizard.isFinishMenuOpen);
    }

    public OnChangeFinishStatusCommand(index: number) {
        this.props.ValidateForStatus(this.props.Wizard.order.NetUid, this.props.Wizard.finishStatus[index].id);
    }

    public OnFinishOrderCommand() {
        if (this.props.Wizard.proccessingOrderState === false) {
            this.props.SetProcessingOrderState();

            const selctedFinishStatus = new List(this.props.Wizard.finishStatus)
                .Where(c => (c as any).isSelected)
                .FirstOrDefault();

            if (selctedFinishStatus) {
                if ((selctedFinishStatus as any).id == OrderStatusEnum.Confirmed) {
                    this.props.AddNewOrderWithStatus(this.props.Wizard.order.NetUid, OrderStatusEnum.Confirmed);
                }

                if ((selctedFinishStatus as any).id == OrderStatusEnum.Waiting) {
                    this.props.AddNewOrderWithStatus(this.props.Wizard.order.NetUid, OrderStatusEnum.Waiting);
                }

                if ((selctedFinishStatus as any).id == OrderStatusEnum.Cancel) {
                    this.props.AddNewOrderWithStatus(this.props.Wizard.order.NetUid, OrderStatusEnum.Cancel);
                }
                
                if ((selctedFinishStatus as any).id == OrderStatusEnum.NotAvailableNotWaiting) {
                    this.props.AddNewOrderWithStatus(this.props.Wizard.order.NetUid, OrderStatusEnum.NotAvailableNotWaiting);
                }

                if ((selctedFinishStatus as any).id == OrderStatusEnum.NotAvailableWaiting) {
                    this.props.AddNewOrderWithStatus(this.props.Wizard.order.NetUid, OrderStatusEnum.NotAvailableWaiting);
                }

                if ((selctedFinishStatus as any).id == 0) {
                    this.props.ProcessTempOrder(this.props.Wizard.order.NetUid);
                }
            }
        }
    }

    render() {
        return (
            <div className={`createOrced__container ${this.props.Wizard.orderProductSubStep ? 'orderProductSubStep_hide' : ''}`}>

                {this.props.Wizard.order.Id ?
                    <div className="wizardWrapper__container">
                        {/* WIZARD WRAP */}
                        {/* wizard header */}
                        <div className="wizardHeader__container">
                            <div className="page_title">
                                <span className="title_default">Создание заказа</span>
                                <span className="title_description">Оформление</span>
                            </div>
                        </div>

                        <div className="wizardFilter__container">
                            <div className="wizardFilterButtons__container">
                                <div className={`onOpenProductStep floatLeft ${this.props.Wizard.stepNumber === 1 ? 'isSelected' : ''}`}
                                    onClick={this.OnOpenProductStep.bind(this)}>Товары</div>
                                <div className={`onOpenProductStep floatLeft ${this.props.Wizard.stepNumber === 2 ? 'isSelected' : ''}`}
                                    onClick={this.OnOpenInfotStep.bind(this)}>Клиент и доставка</div>
                            </div>
                            <div className="wizardFilterContent__container">
                                <div className="floatLeft priceWrapper">
                                    <span>{this.props.Wizard.order ? this.props.Wizard.order.PartnerTotalAmount : 0}</span>
                                    <span> {this.props.Wizard.order ? this.props.Wizard.order.TotalAmount : 0}</span>
                                </div>
                                <div className="onOpenFinishMenuCommand" onClick={this.OnOpenFinishMenuCommand.bind(this)}>Завершить</div>
                            </div>
                        </div>
                        <div className="wizardContent__container">
                            <Route path={this.props.history.location.pathName} component={this.props.Wizard.step} />
                            {this.props.Wizard.orderProductSubStep &&
                                <Route path={this.props.history.location.pathName} component={PopupWrapper} />
                            }
                        </div>

                        {this.props.Wizard.isFinishMenuOpen && <div className="completionContainer__content">
                            <div className="completionContainer">
                                <div className="closeModalIcon__control" />
                                <div className="completionHeader__container">
                                    <div className="page_title">
                                        <span className="title_default">Последний шаг</span>
                                    </div>
                                </div>

                                <div className="containerGlobalComment">
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Комментарий</div>
                                        <div className="defaultFormControlTextatea__value">
                                            <textarea
                                                value={(this.props.Wizard.order as Order).GlobalComment}
                                                onChange={(event) => this.props.ChangeGlobalComment(event.target.value)} />

                                            {
                                                (this.props.Wizard as WizardState).fetching ?
                                                    <Loader /> : null
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="completionContent__container">
                                    <ul>
                                        {
                                            this.props.Wizard.finishStatus.map((status, index) => {
                                                return (
                                                    <li key={index}
                                                        className={`${status.isSelected ? 'isSelected' : ''} `}
                                                        onClick={this.OnChangeFinishStatusCommand.bind(this, index)}>
                                                        {status.name}
                                                    </li>
                                                );
                                            })
                                        }
                                    </ul>
                                </div>

                                {
                                    this.props.Wizard.isValidated ?
                                        <div className="completionButton__container" onClick={this.OnFinishOrderCommand.bind(this)}>Сохранить</div> :
                                        null
                                }
                                <div className="closeModalIcon__control" onClick={this.OnOpenFinishMenuCommand.bind(this)} />
                            </div>
                        </div>}
                    </div>
                    : <Loader />}
            </div>
        );
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Wizard);
