import * as LexiconActions from "../../../../actions/stock/lexicon.actions";
import * as React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import Select from "react-select";
import { DebounceInput } from 'react-debounce-input';
import { actions } from 'react-redux-form';
import { NavLink } from 'react-router-dom';
import {
     GetName,
     GetChoiceOfChangeType,
     GetSelectionOfProductType,
     GetChoiceOfUrgency,
     GetLexiconSelection,
     GetSelectionOfAdditionalServices,
     AddNewPrinter,
     GetPrinters,
     RemovePrinter,
} from '../../../../actions/sales/printers.actions';
import { default as BulkPriceUpdateForm } from "./right.view.lexicon";
import { PrinterState } from '../../../../states/sales/printer.state';
import Lexicon from "../../../../business.entities/lexicon";
import { RightViewState } from "../../../../states/right.view.state";
import { SelectRVData, RemoveRVData } from "../../../../actions/right.view.actions";
import { Print } from "../../../../business.entities/printer";
import * as rightViewActionCreators from '../../../../actions/right.view.actions';
import PrintList from "../../../../business.entities/PrintList";
import { Loader } from "../../../shared/loader";
import { Hidden } from "@material-ui/core";
import { MultiSelect } from "./select";

export interface IPrinterProps {
     printers: PrinterState;
     SelectRVData(state: RightViewState): void;
}

export interface row {
     Printer: Print;
     selectedPrinters: any;
     selectedLexicons: Array<Lexicon>;
     SelectionOfAdditional: any;
     ChoiceOfChangeType: any;
     ChoiceOfUrgency: any;
     SelectionOfAdditionalServices: Array<any>;

}

export interface IState {
     CloseRv: () => any;
     OpenRv: () => any;
     rows: Array<any>;
     Printer: Print;
     selectedLexicons: Array<Lexicon>;
     selectedPrinters: any;
     SelectionOfAdditional: any;
     ChoiceOfUrgency: any;
     ChoiceOfChangeType: any;
     SelectionOfAdditionalServices: Array<any>;
     ResetForm: any;
     allLexicons: Array<Lexicon>;
     DefaultLexicon: boolean;
     isAllLexicon: boolean;
     isSeeAllLexicon: boolean;
     defaultAdditionalServices: boolean;
     isAllAdditionalServices: boolean;
     isSeeAllAdditionalServices: boolean;
}

const mapStateToProps = (state) => {
     return {
          Authorization: state.authentication,
          Printer: state.sales.printers,
     };
};

const selectAllOptionLexicon = {
     value: "<SELECT_ALL>",
     label: "Всі лексикони"
};
const selectAllOptionAdditionalService = {
     value: "<SELECT_ALL>",
     label: "Всі додаткові послуги"
};

// const onChange = (newValue, actionMeta) => {
//      const { action, option, removedValue } = actionMeta;
//      if (action === "select-option" && option.value === selectAllOptionLexicon.value) {
//           // props.onChange(props.Printer.LexiconSelectionList, actionMeta);
//      } else if (
//           (action === "deselect-option" &&
//                option.value === selectAllOptionLexicon.value) ||
//           (action === "remove-value" &&
//                removedValue.value === selectAllOptionLexicon.value)
//      ) {
//           //props.onChange([], actionMeta);
//      }
//      // else if (
//      //      actionMeta.action === "deselect-option" &&
//      //      isSelectAllSelected()
//      // ) {
//      //      // props.onChange(
//      //      //      props.options.filter(({ value }) => value !== option.value),
//      //      //      actionMeta
//      //      // );
//      // } 
//      else {
//           //props.onChange(newValue || [], actionMeta);
//      }
// }

const mapDispatchToProps = (dispatch) => bindActionCreators({
     GetName,
     GetChoiceOfChangeType,
     GetSelectionOfProductType,
     GetChoiceOfUrgency,
     GetLexiconSelection,
     GetSelectionOfAdditionalServices,
     GetLexicons: LexiconActions.GetLexiconsFilteredPaginated,
     RemoveRVData,
     SelectRVData,
     ResetForm: actions.reset,
     GetPrinters,
     AddNewPrinter,
     RemovePrinter
}, dispatch);

class PrintersBase extends React.Component<IPrinterProps | any, IState> {

     constructor(props) {
          super(props);

          this.state = {
               //selectedPrinter: {},
               Printer: undefined,
               rows: [],
               selectedLexicons: [],
               selectedPrinters: undefined,
               SelectionOfAdditional: undefined,
               ChoiceOfChangeType: undefined,
               SelectionOfAdditionalServices: [],
               ChoiceOfUrgency: undefined,
               CloseRv: () => undefined,
               OpenRv: () => undefined,
               ResetForm: () => undefined,
               allLexicons: [],
               DefaultLexicon: true,
               isAllLexicon: false,
               isSeeAllLexicon: false,
               defaultAdditionalServices: true,
               isAllAdditionalServices: false,
               isSeeAllAdditionalServices: false
          };

          this.Add = this.Add.bind(this);
     }



     componentWillMount() {
          this.props.GetName("");
          this.props.GetChoiceOfChangeType("");
          this.props.GetSelectionOfProductType("");
          this.props.GetChoiceOfUrgency("");
          this.props.GetLexiconSelection("");
          this.props.GetSelectionOfAdditionalServices("");
          this.props.GetPrinters("");
          this.setState({
               allLexicons: [selectAllOptionLexicon, ...this.props.Printer.LexiconSelectionList]
          })
     }

     componentWillUpdate(nextProps: Readonly<any>, nextState: Readonly<IState>, nextContext: any): void {

     }

     componentWillReceiveProps(nextProps: any) {

     }

     public Add() {
          debugger
          const newPrinter = {
               LexiconSelection: this.state.selectedLexicons.map(n => n.OfficialPartnerName),
               Name: this.state.selectedPrinters.label,
               SelectionOfProductType: this.state.SelectionOfAdditional.label,//+
               ChoiceOfChangeType: this.state.ChoiceOfChangeType.label,//+
               SelectionOfAdditionalServices: this.state.SelectionOfAdditionalServices.map(n => n.label),
               ChoiceOfUrgency: this.state.ChoiceOfUrgency.label,//+
          }
          this.props.AddNewPrinter(newPrinter);
     }
     public OnOpenRegistersRvCommand(index: any) {
          let rvState = new RightViewState();
          rvState.Title = "Лексикони";
          rvState.Component = BulkPriceUpdateForm;
          rvState.ViewSize = "rightViewGroupActions";
          rvState.Props = index;
          rvState.CloseFunction = () => { this.props.RemoveRVData() };
          this.props.SelectRVData(rvState);
     }
     public onChangeLexicon = (newValue, actionMeta) => {
          const { action, option, removedValue } = actionMeta;
          if (action === "select-option" && option.value === selectAllOptionLexicon.value) {
               debugger
               this.setState({
                    selectedLexicons: this.props.Printer.LexiconSelectionList,
               }, () => {
                    this.setState({
                         isAllLexicon: true,
                         isSeeAllLexicon: false,
                         DefaultLexicon: false,
                    });
               })

          } else if (action === "clear") {
               this.setState({
                    selectedLexicons: [],
               }, () => {
                    this.setState({
                         isAllLexicon: false,
                         isSeeAllLexicon: false,
                         DefaultLexicon: true,
                    });
               })

          }
          else if (removedValue !== undefined) {
               if (removedValue.label == selectAllOptionLexicon.label) {
                    this.setState({
                         selectedLexicons: newValue,
                    }, () => {
                         this.setState({
                              isAllLexicon: false,
                              isSeeAllLexicon: false,
                              DefaultLexicon: true,
                         });
                    })
               }
               else {
                    this.setState({
                         selectedLexicons: newValue,
                    }, () => {
                         this.setState({
                              isAllLexicon: false,
                              isSeeAllLexicon: false,
                              DefaultLexicon: true,
                         });
                    })
               }

          }
          else {
               this.setState({
                    selectedLexicons: newValue,
               }, () => {
                    this.setState({
                         isAllLexicon: false,
                         isSeeAllLexicon: true,
                         DefaultLexicon: false,
                    });
               })

          }
     }

     public onChangeAdditionalServices = (newValue, actionMeta) => {
          const { action, option, removedValue } = actionMeta;
          if (action === "select-option" && option.value === selectAllOptionAdditionalService.value) {
               this.setState({
                    SelectionOfAdditionalServices: this.props.Printer.SelectionOfAdditionalServicesList,
               }, () => {
                    this.setState({
                         isAllAdditionalServices: true,
                         isSeeAllAdditionalServices: false,
                         defaultAdditionalServices: false,
                    });
               })

          } else if (action === "clear") {
               this.setState({
                    SelectionOfAdditionalServices: [],
               }, () => {
                    this.setState({
                         isAllAdditionalServices: false,
                         isSeeAllAdditionalServices: false,
                         defaultAdditionalServices: true,
                    });
               })

          } else if (removedValue !== undefined) {
               if (removedValue.label == selectAllOptionAdditionalService.label) {
                    this.setState({
                         SelectionOfAdditionalServices: newValue,
                    }, () => {
                         this.setState({
                              isAllAdditionalServices: false,
                              isSeeAllAdditionalServices: false,
                              defaultAdditionalServices: true,
                         });
                    })
               }
               else {
                    this.setState({
                         SelectionOfAdditionalServices: newValue,
                    }, () => {
                         this.setState({
                              isAllAdditionalServices: false,
                              isSeeAllAdditionalServices: false,
                              defaultAdditionalServices: true,
                         });
                    })
               }

          }
          else {
               this.setState({
                    SelectionOfAdditionalServices: newValue,
               }, () => {
                    this.setState({
                         isAllAdditionalServices: false,
                         isSeeAllAdditionalServices: true,
                         defaultAdditionalServices: false,
                    });
               })

          }
     }



     public remove(id: any) {
          this.props.RemovePrinter(id);
     }
     public getLexiconOption() {
          if (this.state.isAllLexicon) {
               return [selectAllOptionLexicon];
          }
          else if (this.state.isSeeAllLexicon) {
               return [...this.props.Printer.LexiconSelectionList];
          }
          else if (this.state.DefaultLexicon) {
               return [selectAllOptionLexicon, ...this.props.Printer.LexiconSelectionList];
          }
     };
     public getAdditionalServiceOption() {
          if (this.state.isAllAdditionalServices) {
               return [selectAllOptionAdditionalService];
          }
          else if (this.state.isSeeAllAdditionalServices) {
               return [...this.props.Printer.SelectionOfAdditionalServicesList];
          }
          else if (this.state.defaultAdditionalServices) {
               return [selectAllOptionAdditionalService, ...this.props.Printer.SelectionOfAdditionalServicesList];
          }
     };




     render() {
          // const optionsName = this.props.Printer.PrintersList.Printers.map(printer => ({
          //      value: printer.Name,
          //      label: printer.Name
          // }));

          // const optionsChoiceOfChangeType = this.props.Printer.PrintersList.Printers.map(printer => ({
          //      value: printer.ChoiceOfChangeType,
          //      label: printer.ChoiceOfChangeType
          // }));

          return <div className="parthnerInWrapperContainer">
               <div className="grid_component" style={{
                    zIndex: 2,
                    bottom: 'auto',
                    minHeight: '44px',
                    position: 'relative',
                    overflow: 'unset'
               }}>
                    <div className="grid_container">
                         <div className="grid_printer">
                              <div className="grid_printer_wrapper" style={{ paddingRight: 0 }}>
                                   <div className="grid_row row_header">
                                        <div style={{ width: "40px" }} className='grid_cell obCell__code'>
                                             {/* <div className="cell_printer">Принтери</div> */}

                                             {/* "defaultCenterPrint v1" */}
                                             <div className="cell_printer obCell__codeC oBase__CELL" style={{ paddingTop: 15 }} >
                                                  Принтер : №
                                             </div>
                                        </div>
                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір друкаря</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }} >
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            placeholder="Вибір друкаря"
                                                            options={this.props.Printer.NameList}
                                                            onChange={(items) => {
                                                                 this.setState({
                                                                      selectedPrinters: items
                                                                 })
                                                            }}
                                                       />
                                                  </div>
                                             </div>

                                        </div>
                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір типу Зміни</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }} >
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            placeholder="Вибір типу Зміни"
                                                            options={this.props.Printer.ChoiceOfChangeTypeList}
                                                            onChange={(item) => {
                                                                 this.setState({
                                                                      ChoiceOfChangeType: item
                                                                 })
                                                            }}
                                                       />
                                                  </div>
                                             </div>

                                        </div>
                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір типу товару</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }} >
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            placeholder="Вибір типу товару"
                                                            options={this.props.Printer.SelectionOfProductTypeList}
                                                            onChange={(items) => {
                                                                 this.setState({
                                                                      SelectionOfAdditional: items
                                                                 })
                                                            }}
                                                       />
                                                  </div>
                                             </div>

                                        </div>

                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір Терміновості</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }} >
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            placeholder="Вибір Терміновості"
                                                            clearValue={() => {

                                                            }}
                                                            options={this.props.Printer.ChoiceOfUrgencyList}
                                                            onChange={(items) => {
                                                                 this.setState({
                                                                      ChoiceOfUrgency: items
                                                                 })
                                                            }}
                                                       />
                                                  </div>
                                             </div>

                                        </div>
                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір Лексикону</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }} >
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            options={this.getLexiconOption()}
                                                            placeholder="Вибір Лексикону"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            onChange={this.onChangeLexicon}
                                                       />
                                                  </div>

                                             </div>


                                        </div>
                                        <div className='grid_cell obCell__code oBase__CELL'>
                                             {/* <div className="cell_printer">Вибір додаткової послуги</div> */}
                                             <div className="defaultFormControl" style={{ marginTop: 0 }}>
                                                  <div className="selectWrapper__container notPadd">
                                                       <Select
                                                            options={this.getAdditionalServiceOption()}
                                                            placeholder="Вибір додаткової послуги"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            onChange={this.onChangeAdditionalServices}
                                                       />
                                                  </div>
                                             </div>
                                        </div>
                                        <div className="grid_cell obCell__code oBase__CELL" style={{ verticalAlign: "top", paddingTop: 2 }}>
                                             {/* <div className="cell_printer">Добавити принтер</div> */}
                                             <button className="defaultButtonPrint" style={{ minHeight: 38 }} onClick={this.Add} >Добавити</button>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="grid_component" style={{ top: 44 }}>
                    <div className="grid_container">
                         <div className="grid_printer">
                              <div className="grid_printer_wrapper">
                                   <div className="grid_row row_header">
                                        <div style={{ width: "40px" }} className="grid_cell obCell__code">
                                             <div className="cell_content">Принтери</div>
                                        </div>
                                        <div style={{ width: "40px" }} className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір друкаря</div>
                                        </div>
                                        <div style={{ width: "40px" }} className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір типу Зміни</div>
                                        </div>
                                        <div style={{ width: "40px" }} className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір типу товару</div>
                                        </div>
                                        <div style={{ width: "40px" }} className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір Терміновості</div>
                                        </div>
                                        <div className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір Лексикону</div>
                                        </div>
                                        <div className="grid_cell obCell__code">
                                             <div className="cell_content">Вибір додаткової послуги</div>
                                        </div>
                                        <div className="grid_cell obCell__code">
                                             <div className="cell_content">Добавити принтер</div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                         {this.props.Printer.fetchInProgress ?

                              <div className="grid_scroll_content_component">
                                   <div className=" grid_wrapper_component ">

                                        {this.props.Printer.PrintersList && this.props.Printer.PrintersList.map((printer, index) => {
                                             return <div className="mMEDIA___orderBase_ROW ordersBASE_GRID_ROW grid_row" key={index}>

                                                  <div style={{ width: "40px" }} className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            Принтер: {++index}
                                                       </div>
                                                  </div>
                                                  <div style={{ width: "40px" }} className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            <span >{printer.Name}</span>
                                                       </div>
                                                  </div>
                                                  <div style={{ width: "40px" }} className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            <span >{printer.ChoiceOfChangeType}</span>
                                                       </div>

                                                  </div>
                                                  <div style={{ width: "40px" }} className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            <span >{printer.SelectionOfProductType}</span>
                                                       </div>

                                                  </div>
                                                  <div style={{ width: "40px" }} className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            <span >{printer.ChoiceOfUrgency}</span>
                                                       </div>

                                                  </div>
                                                  <div className="grid_cell obCell__code oBase__CELL">
                                                       <div className="cell_content obCell__codeC">
                                                            <button className="defaultButtonPrint" onClick={() => this.OnOpenRegistersRvCommand(index - 1)}>Вибраний лексикон</button>
                                                       </div>
                                                  </div>
                                                  <div className='grid_cell obCell__code oBase__CELL'>
                                                       <div className="cell_content obCell__codeC">
                                                            {printer.SelectionOfAdditionalServices.map((t, index: number) => {
                                                                 return <span key={index}>{t.AdditionalServices + " . "}</span>
                                                            })}
                                                       </div>
                                                  </div>
                                                  <div className="grid_cell obCell__code oBase__CELL">
                                                       <div className="cell_content obCell__codeC">
                                                            <button className="defaultButtonPrint" onClick={() => this.remove(printer.NetUid)}>Видалити</button>
                                                       </div>
                                                  </div>
                                             </div>
                                        })}
                                   </div>
                              </div> : <Loader />}
                    </div>
               </div>
               <div className='form_row_component'>
               </div>
          </div>

     }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintersBase);