import * as React from "react";
import { ApplicationState } from "../../../../reducers/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ProductPriceListItem } from "../../../../business.entities/product.price.list.item";
import { GetPricelist, GetPricelistUrl } from "../../../../actions/stock/price.list.actions";
import { SelectRVData, RemoveRVData } from "../../../../actions/right.view.actions";
import { RightViewState } from "../../../../states/right.view.state";
import { default as RecomendedPriceRV } from "./price.list.recomended.price.form";
import { actions } from "react-redux-form";
import { PriceListLexiconPriceItem } from "../../../../business.entities/price.list.lexicon.price.item";
import { Lexicon } from "../../../../business.entities/lexicon";
import { PriceListProductPriceItem } from "../../../../business.entities/price.list.product.price.item";
import { List } from "linqts";
import { PriceListState } from "../../../../states/stock/price.list.state";
import { LoginState } from "../../../../states/login.state";
import { Loader } from "../../../shared/loader";
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import Select from 'rc-select';

export interface IPriceListGrid {
    GetPricelist(page: number, limit: number);
    GetPricelistUrl();
    SelectRVData(rvData: RightViewState);
    RemoveRVData();
    ChangeForm(formName: string, value: any);
    ResetForm(formName: string);

    PriceListState: PriceListState;
    AuthenticationState: LoginState;
}

const PriceListGrid = (props: IPriceListGrid) => {


    const OnChangePageCommand = (current, pageSize) => {
        if (!props.PriceListState.IsFetchInProccess) {
            props.GetPricelist(current, props.PriceListState.Limit);
        }
    }

    const onNextPageCommand = () => {
        if (!props.PriceListState.IsFetchInProccess) {
            if (props.PriceListState.TotalPages > props.PriceListState.Page) {
                let nextPage = props.PriceListState.Page;
                nextPage++;
                props.GetPricelist(nextPage, props.PriceListState.Limit);
            }
        }
    }

    const onPrevPageCommand = () => {
        if (!props.PriceListState.IsFetchInProccess) {
            if (props.PriceListState.Page > 1) {
                let nextPage = props.PriceListState.Page;
                nextPage--;
                props.GetPricelist(nextPage, props.PriceListState.Limit);
            }
        }
    }

    const generateLexiconPricesForm = () => {
        let lexionPrices: PriceListLexiconPriceItem[] = [];

        props.PriceListState.Lexicons.forEach(item => {
            lexionPrices.push({ LexiconId: item.Id, Price: 0 });
            if (props.PriceListState.LexiconPriceItems) {
                let lexionPriceItem = new List(props.PriceListState.LexiconPriceItems).FirstOrDefault(c => c.LexiconId === item.Id)
                lexionPrices[lexionPrices.length - 1].Price = lexionPriceItem ? lexionPriceItem.Price : 0;
            }
        });

        return lexionPrices;
    }

    const getProductPrice = (pricelistItem: ProductPriceListItem) => {
        let productPrice = null;
        if (props.PriceListState.ProductPriceItems)
            productPrice = new List(props.PriceListState.ProductPriceItems).FirstOrDefault(c => c.ProductId === pricelistItem.ProductId);

        return Object.assign({}, pricelistItem, { RecomendedPartnerPrice: productPrice ? productPrice.Price : pricelistItem.RecomendedPartnerPrice } as ProductPriceListItem)
    }

    const onOpenRv = (pricelistItem: ProductPriceListItem) => {
        props.ChangeForm('pricelistLexiconPriceForm', generateLexiconPricesForm());
        props.ChangeForm('pricelistItemForm', getProductPrice(pricelistItem));

        let rvData = new RightViewState();
        rvData.Title = "Редагування РРЦ";

        rvData.Component = RecomendedPriceRV;
        rvData.CloseFunction = () => {
            props.RemoveRVData();
            props.ResetForm('pricelistLexiconPriceForm');
            props.ResetForm('pricelistItemForm');
            props.GetPricelist(props.PriceListState.Page, props.PriceListState.Limit);
        }

        props.SelectRVData(rvData);
    }

    const goToSite = (url: string) => {
        window.open(url);
    }

    const getPriceListUrl = () => {
        props.GetPricelistUrl();
    }

    return (
        <div className="content_component prices__page__component">
            <div className="grid_component availability_grid__component">
                <div className="grid_container">
                    <div className="grid_header">
                        <div className="grid_header_wrapper">
                            <div className="grid_row row_header">
                                <div className="grid_cell prcPLgCode__cell">
                                    <span className="cell_content">Код</span>
                                </div>
                                <div className="grid_cell prcPLgLex__cell">
                                    <span className="cell_content ">Категория / Подкатегория</span>
                                </div>
                               
                                <div className="grid_cell prcPLgCate__cell">
                                    <span className="cell_content">Лексикон</span>
                                </div>
                                <div className="grid_cell prcPLg__cell" >
                                    <span className="cell_content">Цена</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д1</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д2</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д3</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д4</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д5</span>
                                </div>
                                <div className="grid_cell prcPLg__cell">
                                    <span className="cell_content">Цена Д6</span>
                                </div>
                                <div className="grid_cell prcPLg__cell optC">
                                    <span className="cell_content">Оптовая цена</span>
                                </div>
                                <div className="grid_cell prcPLgCate__cell">
                                    <span className="cell_content">РРЦ</span>
                                </div>
                                <div className="grid_cell cell__sites">
                                    <span className="cell_content">Ссылка</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {props.PriceListState.PricelistItems.length > 0 ?
                        <div className="grid_scroll_content_component">
                            <div className="grid_wrapper_component ">
                                {props.PriceListState.PricelistItems.length > 0 && props.PriceListState.PricelistItems
                                    .map((item: ProductPriceListItem, key: number) => {
                                        return <div className="grid_row mMEDIA___priceList_ROW" key={key}>
                                            <div className="grid_cell prcPLgCode__cell pLG__CELL" data-tooltip="Код">
                                                <span className="cell_content">{item.ProductCode}</span>
                                            </div>
                                            <div className="grid_cell prcPLgLex__cell pLG__CELL" data-tooltip="Категория / Подкатегория">
                                                <span className="cell_content ">
                                                    {item.CategoryName}
                                                    <br />
                                                    <b>{item.SubCategoryName}</b>
                                                </span>
                                            </div>
                                          
                                            <div className="grid_cell prcPLgCate__cell pLG__CELL" data-tooltip="Лексикон">
                                                <span className="cell_content">{item.LexiconOfficialName}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell priceC" data-tooltip="Цена">
                                                <span className="cell_content">{item.PartnerPrice}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д1">
                                                <span className="cell_content">{item.PartnerPriceD1}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д2">
                                                <span className="cell_content">{item.PartnerPriceD2}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д3">
                                                <span className="cell_content">{item.PartnerPriceD3}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д4">
                                                <span className="cell_content">{item.PartnerPriceD4}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д5">
                                                <span className="cell_content">{item.PartnerPriceD5}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell mD__CELL" data-tooltip="Цена Д6">
                                                <span className="cell_content">{item.PartnerPriceD6}</span>
                                            </div>
                                            <div className="grid_cell prcPLg__cell optC" data-tooltip="Оптовая цена">
                                                <span className="cell_content">{item.PartnerWholesalePrice}</span>
                                            </div>
                                            <div className="grid_cell hover_one__cell prcPLgCate__cell mD__CELL" data-tooltip="РРЦ" onClick={() => onOpenRv(item)}>
                                                <span className="cell_content">{item.RecomendedPartnerPrice}</span>
                                            </div>
                                            <div className="grid_cell cell__sites prcPLg___CELL" data-tooltip="Сайт">
                                                {
                                                    item.SiteUrl &&
                                                    <div className="sites_icon" onClick={() => goToSite(item.SiteUrl)}>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    )}
                            </div>
                        </div>
                        :
                        <Loader />
                    }
                </div>
            </div>

                <Pagination
                    locale={Locale}
                    // selectComponentClass={Select}
                    // showSizeChanger
                    // pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                    defaultPageSize={props.PriceListState.Limit}
                    defaultCurrent={props.PriceListState.Page}
                    // onShowSizeChange={OnChangePageSizeCommand}
                    onChange={OnChangePageCommand}
                    total={props.PriceListState.TotalPages}
                />
        </div>
    )
}

export default connect((state: ApplicationState) => ({
    PriceListState: state.stock.priceList, AuthenticationState: state.authentication
}), (dispatch) => bindActionCreators({
    GetPricelist, SelectRVData, RemoveRVData, ChangeForm: actions.change,
    ResetForm: actions.reset,
}, dispatch))(PriceListGrid)
