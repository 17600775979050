import * as React from "react";
import { connect } from "react-redux";

export const Partners = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров (общее)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество зарегистрированных партнеров всех ценовых уровней, регистрация которых на момент проверки была завершена и подтверждена
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров начального ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество зарегистрированных партнеров начального ценового уровня, регистрация которых на момент проверки была завершена и подтверждена
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S0PartnerQuantity}
                                </span>
                            </div>

                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров первого ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров первого ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S1PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров второго ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров второго ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S2PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров третьего ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров третьего ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше  </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S3PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров четвертого ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров четвертого ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше               </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S4PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров пятого ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров пятого ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S5PartnerQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество партнеров шестого ценового уровня
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров шестого ценового уровня, которые на момент проверки находятся в данном ценовом уровне, а не имели его раньше  </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.S6PartnerQuantity}
                                </span>
                            </div>
                        </div>



                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество активных партнеров (которые оформили минимум один заказ)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров, которые на момент проверки со дня своей регистрации оформили минимум один заказ
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ActivePartnersQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество не активных партнеров (не оформили ни одного заказа)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество партнеров, которые на момент проверки со дня своей регистрации не оформили ни одного заказа
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PassivePartnersQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Соотношение активных (которые оформили минимум один заказ) партнеров к неактивным, %
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Соотношение общего количества партнеров, которые на момент проверки со дня своей регистрации оформили минимум один заказ к общему количеству партнеров, которые на момент проверки со дня своей регистрации не оформили ни одного заказа
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ActiveToPassive}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name"></div>
                            <div className="grid_cell"></div>
                            <div className="grid_cell cell__GRID_CONT"></div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданых товаров с конструктора с вотермаком
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество созданых товаров с конструктора с вотермаком
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConstructorProductQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество оплоченых товаров с конструктора с вотермаком
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество оплоченых товаров с конструктора с вотермаком
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConstructorProductPayedQuantity}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(Partners);
