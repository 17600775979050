import * as API from '../../../../constants/api.constants';
import * as PlatformActions from '../../../../actions/stock/platform.actions';
import * as priceListExportActions from '../../../../actions/stock/price.list.export.actions';
import * as React from 'react';
import * as RightViewActions from '../../../../actions/right.view.actions';
import { ApplicationState } from '../../../../reducers/index';
import { bindActionCreators } from 'redux';
import { ClipboardHelper } from '../../../../helpers/clipboardHelper';
import { connect } from 'react-redux';
import { default as PriceListExportConfig } from './price.list.export.config';
import { LoginState } from '../../../../states/login.state';
import { RightViewState } from '../../../../states/right.view.state';
import { actions } from 'react-redux-form';
import { PriceListState } from '../../../../states/stock/price.list.state';
import { Loader } from '../../../shared/loader';
import { Route } from 'react-router';
import PriceListOptions from './price.list.options';

export interface IPriceListExport {
    Authentication: LoginState;
    PriceListState: PriceListState;
    RenewMultiple();
    RenewSingle();

    OpenRv(rvData: RightViewState);
    CloseRv();
    GetAllPlatforms();
    Change(name: string, value: any);
}

const PriceListExport = (props: IPriceListExport) => {

    const onGetFile = (single: boolean) => {
        window.open(`${API.SERVER_URL}${API.EXPORT_ASSORTMENT_FOLDER}/${props.Authentication.UserNetUid}/${single ? API.OPENCART_SINGLE_COLOR_EXPORT_FILE : API.OPENCART_MULTIPLE_COLOR_EXPORT_FILE}`, '_blank');
    }

    const OpenConfigure = () => {
        props.GetAllPlatforms();
        let rvState = new RightViewState();
        rvState.Title = "Настройки експорта";
        rvState.Component = PriceListExportConfig;
        rvState.ViewSize = "audit__modal__component";
        rvState.CloseFunction = props.CloseRv;
        props.OpenRv(rvState);
        props.PriceListState.ExportPlatformConfig
            && props.PriceListState.ExportPlatformConfig.length > 0
            && props.Change('platformConfigForm', props.PriceListState.ExportPlatformConfig);
    }

    return (
        <div className="content_component prices__page__component">
            <div className="price_page__row_list__wrapper">
                <div className="price_page__row_list__item">
                    <div className="item__name"> OpenCart</div>

                    <div className="controls__wrapper">

                        {
                            !props.PriceListState.IsFetchInProccess ?
                                <div className="item__control" onClick={() => { onGetFile(true) }}>
                                    Скачать "продукт-цвет"
                                </div> :
                                <div className="item__control bzReload">
                                    <Loader />
                                    Скачать "продукт-цвет"
                                </div>
                        }

                        <div className="item__control" onClick={() => { props.RenewSingle() }}>
                            Обновить файл "продукт-цвет"
                        </div>

                        <div className="item__control" onClick={() => {
                            ClipboardHelper.CopyTextWithMessage(
                                `${API.SERVER_URL}${API.EXPORT_ASSORTMENT_FOLDER}/${props.Authentication.UserNetUid}/${API.OPENCART_SINGLE_COLOR_EXPORT_FILE}`,
                                "Ссылка на експорт опенкарт (1 цвет) скопирована")
                        }}>
                            Ссылка "продукт-цвет"
                        </div>

                        <div className="item__HR"></div>

                        {
                            !props.PriceListState.IsFetchInProccess ?
                                <div className="item__control" onClick={() => { onGetFile(false) }}>
                                    Скачать "продукт с опциями"
                            </div> :
                                <div className="item__control bzReload">
                                    <Loader />
                                    Скачать "продукт с опциями"
                                </div>
                        }

                        <div className="item__control" onClick={() => { props.RenewMultiple() }}>
                            Обновить "продукт с опциями"
                        </div>

                        <div className="item__control" onClick={() => {
                            ClipboardHelper.CopyTextWithMessage(
                                `${API.SERVER_URL}${API.EXPORT_ASSORTMENT_FOLDER}/${props.Authentication.UserNetUid}/${API.OPENCART_MULTIPLE_COLOR_EXPORT_FILE}`,
                                "Ссылка на експорт опенкарт (много цветов) скопирована")
                        }}>
                            Ссылка "продукт с опциями"
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="price_list__top__controls">
                <div className="button__control" onClick={() => { OpenConfigure() }}>Опции</div>
            </div> */}

            <Route path={(props as any).history.location.pathName} component={PriceListOptions} />

        </div>
    );
}
export default connect((state: ApplicationState) => ({
    Authentication: state.authentication,
    PriceListState: state.stock.priceList
}),
    (dispatch) => bindActionCreators({
        RenewMultiple: priceListExportActions.RenewOpencartMultipleFile,
        RenewSingle: priceListExportActions.RenewOpencartSingleFile,
        OpenRv: RightViewActions.SelectRVData,
        CloseRv: RightViewActions.RemoveRVData,
        GetAllPlatforms: PlatformActions.GetAll,
        Change: actions.change
    }, dispatch))(PriceListExport)