import { Reducer } from "redux";
import * as PromocodeActions from "../../actions/partner/promocode.actions";
import { defaultPromocodeState, PromocodeState } from "../../states/partner/promocode.state";

export const reducer: Reducer<any> = (state: PromocodeState, action: PromocodeActions.PromocodeKnownActions) => {
    switch (action.type) {
        //Grid
        case PromocodeActions.SET_PROMOCODES:
            return { ...state, promocodes: action.payload.Item1, totalCount: action.payload.Item2 };
            
        case PromocodeActions.SELECT_PROMOCODE:
            return Object.assign({}, state, {
                promocodes: Object.assign([], state.promocodes, {
                    [action.payload.index]: Object.assign({},
                        state.promocodes[action.payload.index],
                        {
                            IsSelected: action.payload.value
                        })
                })
            } as PromocodeState);
        //Filter
        case PromocodeActions.SELECT_PROMOCODE_NAME_FILTER:
            return { ...state, promocodeNameFilter: action.payload }

        case PromocodeActions.SELECT_PROMOCODE_USED_DATE_FILTER:
            return { ...state, promocodeUsedDateFilter: action.payload }

        case PromocodeActions.SELECT_PROMOCODE_SHOP_ADDRESS_FILTER:
            return { ...state, promocodeShopAddressFilter: action.payload }

        case PromocodeActions.SELECT_PROMOCODE_STATUS_FILTER:
            return { ...state, promocodeStatusFilter: action.payload }

        case PromocodeActions.SELECT_PROMOCODE_TYPE_FILTER:
            return { ...state, promocodeTypeFilter: action.payload }

        case PromocodeActions.SELECT_PROMOCODE_PRICE_FILTER:
            return { ...state, promocodePriceFilter: action.payload } 
        
        case PromocodeActions.UNSELECT_PROMOCODE_USED_DATE_FILTER:
            return { ...state, promocodeUsedDateFilter: null}

        //AutocompleteOptions
        case PromocodeActions.GET_PROMOCODE_NAMES_AUTOCOMPLETE_SUCCESS:
            return { ...state, promocodeNameAutocompleteOptions: action.payload } as PromocodeState

        case PromocodeActions.GET_PROMOCODE_STATUSES_AUTOCOMPLETE_SUCCESS:
            return { ...state, promocodeStatusAutocompleteOptions: action.payload }

        case PromocodeActions.GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE_SUCCESS:
            return { ...state, promocodeAddressAutocompleteOptions: action.payload }
            
        case PromocodeActions.GET_PROMOCODE_TYPE_AUTOCOMPLETE_SUCCESS:
            return { ...state, promocodeTypeAutocompleteOptions: action.payload }


        // Files
        case PromocodeActions.SELECT_FILE_WITH_PROMOCODES:
            return { ...state, fileWithPromocodes: action.payload }

        case PromocodeActions.ADD_FILE_WITH_PROMOCODES:
            return { ...state, fileWithPromocodesToSend: action.payload }

        case PromocodeActions.SELECT_FILE_WITH_PROMOCODES_NAME:
            return { ...state, fileToSendName: action.payload }

        case PromocodeActions.EXPORT_PROMOCODES_SUCCESS:
            return { ...state, fileToExportName: action.payload }
        
        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultPromocodeState;
}



// SelectPromocodeNameFilter,
//     SelectPromocodeUsedDateFilter,
//     SelectPromocodeShopAddressFilter,
//     SelectPromocodeStatusFilter,
//     SelectPromocodeTypeFilter,
//     SelectPromocodePriceFilter