import * as React from 'react';
import Product from '../../../../../../business.entities/product';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    ConfirmBaseProductSelect,
    SelectCurrentBaseProduct,
    SetNextSubStep
    } from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { List } from 'linqts';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseProduct,
    ConfirmBaseProductSelect
}, dispatch);

const BaseProduct = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    document.onkeydown = (e) => {
        if ((event as any).keyCode == 38) {
            event.preventDefault();
            OnSelectTopBaseCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();
            OnSelectBottomBaseCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();
            OnProductSelectConfirm()
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            orderProductUpdate.currentOrderProduct.BaseProduct &&
                OnGoToNextOrderCardStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopBaseCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentBaseProduct) {
            const base = new List(orderProductUpdate.baseProductList)
                .FirstOrDefault(c => (c as Product).Id === orderProductUpdate.currentBaseProduct.Id)

            startIndex = orderProductUpdate.baseProductList.indexOf(base);
            startIndex != 0 ? startIndex-- : startIndex = orderProductUpdate.baseProductList.length - 1;
        }

        props.SelectCurrentBaseProduct(orderProductUpdate.baseProductList[startIndex]);
    }

    const OnSelectBottomBaseCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentBaseProduct) {
            const base = new List(orderProductUpdate.baseProductList)
                .FirstOrDefault(c => (c as Product).Id === orderProductUpdate.currentBaseProduct.Id)

            startIndex = orderProductUpdate.baseProductList.indexOf(base);
            startIndex != orderProductUpdate.baseProductList.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentBaseProduct(orderProductUpdate.baseProductList[startIndex]);
    }

    const OnProductSelectConfirm = () => {
        if (orderProductUpdate.currentBaseProduct) {
            props.ConfirmBaseProductSelect();
            OnGoToNextOrderCardStepCommand(props);
        }
    }

    const OnSelectBaseProductByMouse = (index) => {
        props.SelectCurrentBaseProduct(orderProductUpdate.baseProductList[index]);

        if (orderProductUpdate.baseProductList[index]) {
            props.ConfirmBaseProductSelect();
        }

        OnGoToNextOrderCardStepCommand(props);
    }


    return (
        <div className="baseBroductView__container">

            <div>
                {orderProductUpdate.baseProductList.length > 0 && orderProductUpdate.baseProductList.map((baseProduct, index) =>
                    <div key={index}
                        className={`baseProductItem__container  ${orderProductUpdate.currentBaseProduct.Id === baseProduct.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectBaseProductByMouse(index)}>
                        <div className="baseProduct__nameContainer">
                            <div className="casualName">
                                {(baseProduct as Product).Lexicon.OfficialPartnerName}
                            </div>
                            {/* <div className="officialPartnerName">
                                {(baseProduct as Product).Lexicon.CasualName}
                            </div> */}
                        </div>
                        <div className="productColors__container">
                            {(baseProduct as Product).ProductColors.map((productColor, colorIndex) =>
                                <div className="colorItem" key={`${index}-${colorIndex}`}>
                                    <div className={`dotStyle ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`} style={{ backgroundColor: productColor.Color.Code }} >
                                        <div className="hide">
                                            {productColor.Color.Name}
                                        </div>
                                        <div className="qty">
                                            {productColor.Quantity}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseProduct)