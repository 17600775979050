import * as React from "react";
import { Form } from "react-redux-form";
import { TextBox } from "../../../shared/form.controls/textbox";
import { DatePicker, TimePicker } from 'material-ui';
import * as moment from "moment";
import { PrintingStatisticsState } from "../../../../states/cabinet/print.stats.state";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/cabinet/print.statistics.actions";
import { connect } from "react-redux";
import { GetOptionsForLexicon } from "../../../../actions/sales/wizard.actions";

interface IPrintingStatisticsProps {
    PrintingStatistics: PrintingStatisticsState,
    SetStartDate(date);
    SetEndDate(date);
    SetStartTime(time);
    SetEndTime(time);
    GetPrintStatistics(startDate, endDate);
    SetFetching(isFetching);
}

function mapStateToProps(state) {
    return {
        Authorization: state.authentication,
        PrintingStatistics: state.cabinet.printStatistics
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        SetStartDate: actions.SetStartDate,
        SetEndDate: actions.SetEndDate,
        SetStartTime: actions.SetStartTime,
        SetEndTime: actions.SetEndTime,
        GetPrintStatistics: actions.GetPrintStatistics,
        SetFetching: actions.SetFetching
    }, dispatch)
}

class DateTimeFilter extends React.Component<any, any> {

    constructor(props) {
        super(props);
    }
    public onSetStartDate(date: any) {
        this.props.SetStartDate(date);
    }

    public onSetEndDate(date: any) {
        this.props.SetEndDate(date);
    }

    public onSetStartTime(time: any) {
        this.props.SetStartTime(time);
    }

    public onSetEndTime(time: any) {
        this.props.SetEndTime(time);
    }

    public onClearFilterCommand() {
        let startDate = moment(new Date('0001-01-01T00:00:00')).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
        let endDate = moment(new Date('0001-01-01T00:00:00')).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
        this.props.SetStartDate(null);
        this.props.SetStartTime(null);
        this.props.SetEndDate(null);
        this.props.SetEndTime(null);
        this.props.SetFetching(true);
        this.props.GetPrintStatistics(startDate, endDate);
    }

    public onApplyFilterCommand() {
        let startDate = undefined;
        let endDate = undefined;
        if (this.props.PrintingStatistics.StartDate) {
            let hours = this.props.PrintingStatistics.StartDate.getUTCHours();
            let minutes = this.props.PrintingStatistics.StartDate.getUTCMinutes();
            let seconds = this.props.PrintingStatistics.StartDate.getUTCSeconds();
            let year = this.props.PrintingStatistics.StartDate.getUTCFullYear();
            let month = this.props.PrintingStatistics.StartDate.getUTCMonth();
            let day = this.props.PrintingStatistics.StartDate.getUTCDate();

            if (this.props.PrintingStatistics.StartTime) {
                hours = this.props.PrintingStatistics.StartTime.getUTCHours();
                minutes = this.props.PrintingStatistics.StartTime.getUTCMinutes();
                seconds = this.props.PrintingStatistics.StartTime.getUTCSeconds();
            }

            startDate = moment(new Date(year, month, day, hours, minutes, seconds)).format('YYYY-MM-DDTHH:mm:ss');
        } else {
            startDate = moment('0001-01-01T00:00:00').hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
        }

        if (this.props.PrintingStatistics.EndDate) {
            let hours = this.props.PrintingStatistics.EndDate.getHours();
            let minutes = this.props.PrintingStatistics.EndDate.getMinutes();
            let seconds = this.props.PrintingStatistics.EndDate.getSeconds();
            let year = this.props.PrintingStatistics.EndDate.getFullYear();
            let month = this.props.PrintingStatistics.EndDate.getMonth();
            let day = this.props.PrintingStatistics.EndDate.getDate();

            if (this.props.PrintingStatistics.EndTime) {
                hours = this.props.PrintingStatistics.EndTime.getUTCHours();
                minutes = this.props.PrintingStatistics.EndTime.getUTCMinutes();
                seconds = this.props.PrintingStatistics.EndTime.getUTCSeconds();
            }
            endDate = moment(new Date(year, month, day, hours, minutes, seconds)).format('YYYY-MM-DDTHH:mm:ss');
        } else {
            endDate = moment('0001-01-01T00:00:00').hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
        }
        this.props.SetFetching(true);
        this.props.GetPrintStatistics(startDate, endDate);
    }

    public render() {
        return (
            <div className="left_menu_component">
            <div className="left_menu_SCROLL">
                <div>
                    <div >
                        <div className="defaultFormControl">
                                <span>Дата начала</span>
                            <div className="dateTimeWrapper__container">
                                <DatePicker 
                                    floatingLabelText="Дата начала"
                                    onChange={(e, date)=> {this.onSetStartDate(date)}} 
                                    value={this.props.PrintingStatistics.StartDate} />

                                <div className="clControl" onClick={() => this.onSetStartDate(null)} />
                            </div>
                        </div>
                        <div className="defaultFormControl">
                            <span>Время начала</span>
                            <div className="dateTimeWrapper__container">
                            <TimePicker
                                floatingLabelText="Время начала"
                                format="24hr"
                                value={this.props.PrintingStatistics.StartTime ? this.props.PrintingStatistics.StartTime : this.props.PrintingStatistics.StartTime}
                                onChange={(event, time) => {
                                    this.onSetStartTime(time);
                                }}
                            />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="defaultFormControl">
                            <span>Дата конца</span>
                            <div className="dateTimeWrapper__container">
                                <DatePicker 
                                    floatingLabelText="Время конца"
                                    onChange={(e, date)=> {this.onSetEndDate(date)}} 
                                    value={this.props.PrintingStatistics.EndDate}/>
                                <div className="clControl" onClick={() => this.onSetEndDate(null)} />
                            </div>
                        </div>
                        <div className="defaultFormControl">
                            <span>Время конца</span>
                            <div className="dateTimeWrapper__container">
                            <TimePicker
                                floatingLabelText="Время конца"
                                format="24hr"
                                value={this.props.PrintingStatistics.EndTime}
                                onChange={(event, time) => {
                                    this.onSetEndTime(time);
                                }}
                            />
                            </div>
                        </div>

                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormButton" onClick={() => {this.onClearFilterCommand()}}>Сбросить фильтр</div>
                    </div>
                    <div className="defaultFormControl ">
                        <div className="defaultFormButton" onClick={() => {this.onApplyFilterCommand()}}>Применить фильтр</div>
                    </div>
                    
                </div>
            </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeFilter);