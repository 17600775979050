import * as API from '../../constants/api.constants';
import * as rightViewActions from '../../actions/right.view.actions';
import * as tutorialActionsTypes from '../../actions/cabinet/tutorial.actions';
import * as personalInfoActionsCreators from '../../actions/cabinet/personal.info.actions';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import storeProvider from '../../helpers/store.provider';
import { RemoveRVData } from '../../actions/right.view.actions';

export const GetAll = (action$, store: Store<any>) =>
  action$.ofType(tutorialActionsTypes.GET_ALL)
    .switchMap(action => {
      return Observable.ajax.getJSON(`${API.GET_ALL_TUTORIALS}?page=${action.payload.page}&count=${action.payload.count}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Инструкции получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          return tutorialActionsTypes.SetAllTutorials(response.Body.Item1, response.Body.Item2);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    });

export const NewTutorial = (action$, store: Store<any>) =>
    action$.ofType(tutorialActionsTypes.NEW_TUTORIAL)
      .switchMap(action => {

        let formData = action.payload.form ? action.payload.form : new FormData();
        formData.append('data', JSON.stringify({Name: action.payload.tutorial.name, Description: action.payload.tutorial.descr, Order: action.payload.tutorial.order}));

        return Observable.ajax.post(`${API.NEW_TUTORIAL}`,
          action.payload.form,
          { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
          .map((response: any) => {
            storeProvider.getStore().dispatch(success({
              title: `Инструкция добавлена`,
              message: '',
              position: 'br',
              autoDismiss: 3,
            }));
  
            storeProvider.getStore().dispatch(RemoveRVData());
            storeProvider.getStore().dispatch(tutorialActionsTypes.DeselectTutorial());
            storeProvider.getStore().dispatch(tutorialActionsTypes.ChangeVideo(null));

            return tutorialActionsTypes.GetAllTutorials(1, 20);
          }).catch((errorData: any) => {
            return Observable.of(ErrorHandler(errorData));
          })
      });

// export const UpdateTutorial = (action$, store: Store<any>) =>
//     action$.ofType(tutorialActionsTypes.UPDATE_TUTORIAL)
//       .switchMap(action => {
//         return Observable.ajax.post(`${API.UPDATE_TUTORIAL}?netUid=${action.payload.tutorial.uid}&name=${action.payload.tutorial.name}&descr=${action.payload.tutorial.descr}
//           &order=${action.payload.tutorial.order}&deleted=${action.payload.tutorial.isDeleted ? action.payload.tutorial.isDeleted : false}`,
//           action.payload.form,
//           { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
//           .map((response: any) => {
//             storeProvider.getStore().dispatch(success({
//               title: `Инструкция изменена`,
//               message: '',
//               position: 'br',
//               autoDismiss: 3,
//             }));

//             storeProvider.getStore().dispatch(RemoveRVData());
//             storeProvider.getStore().dispatch(tutorialActionsTypes.DeselectTutorial());
//             storeProvider.getStore().dispatch(tutorialActionsTypes.ChangeVideo(null));

//             return tutorialActionsTypes.GetAllTutorials(1, 20);
//           }).catch((errorData: any) => {
//             return Observable.of(ErrorHandler(errorData));
//           })
//       });

      
export const UpdateTutorial = (action$, store: Store<any>) =>
action$.ofType(tutorialActionsTypes.UPDATE_TUTORIAL)
  .switchMap(action => {

    let formData = action.payload.form ? action.payload.form : new FormData();
    formData.append('data', JSON.stringify(storeProvider.getStore().getState().cabinet.tutorial.selectedTutorial));

    return Observable.ajax.post(`${API.UPDATE_TUTORIAL}`,
      formData,
      { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
      .map((response: any) => {
        storeProvider.getStore().dispatch(success({
          title: `Инструкция изменена`,
          message: '',
          position: 'br',
          autoDismiss: 3,
        }));

        storeProvider.getStore().dispatch(RemoveRVData());
        storeProvider.getStore().dispatch(tutorialActionsTypes.DeselectTutorial());
        storeProvider.getStore().dispatch(tutorialActionsTypes.ChangeVideo(null));

        return tutorialActionsTypes.GetAllTutorials(1, 20);
      }).catch((errorData: any) => {
        return Observable.of(ErrorHandler(errorData));
      })
  });

