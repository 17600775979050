import * as actionCreators from '../../../../actions/stock/price.actions';
import * as React from 'react';
import { ApplicationState } from '../../../../Reducers/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { PartnerPrice } from '../../../../business.entities/partner.price';
import { TextBoxWithLabel } from '../../../shared/form.controls/textbox.with.label';
import { Validator } from '../../../../helpers/validator.helper';
import { Partner } from '../../../../business.entities/partner';
import { PartnerWholesale } from '../../../../business.entities/partnerWholesale';
import * as priceActions from "../../../../actions/stock/price.actions";
import { TextAreaWithLabel } from '../../../shared/form.controls/textarea.with.label';

export interface WholesaleForm {
    SelectedPartner: Partner;
    WholesaleForm: PartnerWholesale;
    RemoveWholesale: (netId: string) => any;
    UpdateWholesale: (wholesale: PartnerWholesale) => any;
    CreateWholesale: (wholesale: PartnerWholesale) => any;
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        WholesaleForm: (state as any).wholesaleForm,
        SelectedPartner: state.stock.price.selectedPartner
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        RemoveWholesale: priceActions.RemoveWholesale,
        UpdateWholesale: priceActions.UpdateWholesale,
        CreateWholesale: priceActions.CreateWholesale
    }, dispatch);
};

const PartnerWholesaleForm = (props: WholesaleForm | any) => {

    const prepareWholesale = (wholesale: PartnerWholesale) => {
        if (props.SelectedPartner) {
            if (!wholesale.PartnerId) {
                let preparedWholesale = Object.assign({}, wholesale, { IsDefault: false, PartnerId: (props.SelectedPartner as any).partnerId });
                console.log(preparedWholesale)
                props.CreateWholesale(preparedWholesale);
            } else {
                props.UpdateWholesale(wholesale);
            }
        } else {
            props.UpdateWholesale(wholesale);
        }
    }

    const removeWholesale = () => {
        !props.WholesaleForm.IsDefault && props.RemoveWholesale(props.WholesaleForm.NetUid);
    }

    return (
        <Form
            validateOn='change'
            model="wholesaleForm"
            onSubmit={prepareWholesale}>
            <div className="default_form_component shadow_controls">

                {
                    props.WholesaleForm.Lexicon.CasualName &&
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Категория</div>
                        <div className="defaultFormControl__value notPadd">
                            <input type="text" defaultValue={props.WholesaleForm.Lexicon.CasualName} readOnly />
                        </div>
                    </div>
                }

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='number'
                    ValidatedOn='change'
                    LabelMessage='Количество'
                    IsRequired={true}
                    Model='.Count'
                />

                <TextAreaWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    ValidatedOn='change'
                    LabelMessage='Коментарии'
                    IsRequired
                    Model='.Comment'
                />

                <div className="defaultFormControl ">
                    <button type="submit" className="defaultFormButton">Сохранить</button>

                    {
                        props.WholesaleForm.IsDefault ?
                            null :
                            <div className="defaultFormButton" onClick={removeWholesale}>Удалить</div>
                    }
                </div>
            </div>
        </Form>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerWholesaleForm);