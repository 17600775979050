import { EntityBase } from "./entity.base";
import { Partner } from "./partner";
import { Lexicon } from "./lexicon";

export class PartnerWholesale extends EntityBase {
    public Count: number = 0;
    public IsDefault: boolean = true;
    public PartnerId?: number = 0;
    public Partner: Partner = null;
    public LexiconId: number = 0;
    public Lexicon: Lexicon = null;
    public Comment: string = '';
}

export default PartnerWholesale;