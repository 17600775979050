import { Reducer } from "redux";
import * as partnerInfoActions from '../../actions/partner/partner.info.actions';
import { PartnerInfoState, dafaultPartnerInfoState } from '../../states/partner/partner.info.state';

export const reducer: Reducer<any> = (state: PartnerInfoState, action: partnerInfoActions.PartnerKnownActions) => {
    switch (action.type) {
        case partnerInfoActions.ADD_USER_PARTNER:
            return { ...state };
        case partnerInfoActions.SUCCESS_ADD_USER_PARTNER:
            return {
                ...state,
                user: action.payload,
                isRvOpen: false,
                formComponent: null
            };
        case partnerInfoActions.FAILED_ADD_USER_PARTNER:
            return { ...state };
        case partnerInfoActions.UPDATE_PARTNER:
            return { ...state };
        case partnerInfoActions.PARTNER_UPDATE_SUCCESS:
            return { ...state, partner: action.payload };
        case partnerInfoActions.PARTNER_UPDATE_FAILED:
            return { ...state, partner: action.payload };
        case partnerInfoActions.GET_PARTNER:
            return { ...dafaultPartnerInfoState };
        case partnerInfoActions.GET_PARTNER_SUCCESS:
            return {
                ...state,
                partner: action.payload.partner,
                selectedPartnerPaymentMethod: state.partnerPaymentMethods.find(el => el.value === action.payload.partner.PartnerPaymentMethod),
                user: action.payload.user,
                selectedRole: action.payload.role
            } as PartnerInfoState;
        case partnerInfoActions.SELECT_ROLE:
            return {
                ...state,
                selectedRole: action.payload
            } as PartnerInfoState;
        case partnerInfoActions.GET_PARTNER_FAILED:
            return { ...state };
        case partnerInfoActions.CHANGE_MINIMUL_PRICE_LEVEL:
            return Object.assign({}, state, {
                selectedLevel: action.payload
            } as PartnerInfoState)
        case partnerInfoActions.CHANGE_POST_OFFICE:
            return Object.assign({}, state, {
                selectedPostOffice: action.payload
            } as PartnerInfoState)
        case partnerInfoActions.CHANGE_CITY:
            return Object.assign({}, state, {
                selectedCity: action.payload
            } as PartnerInfoState)
        case partnerInfoActions.CHANGE_PARTNER_PAYMENT_METHOD:
            return Object.assign({}, state, {
                selectedPartnerPaymentMethod: action.payload
            } as PartnerInfoState)
        case partnerInfoActions.UNMOUNT_PARTNER_INFO:
            return { ...dafaultPartnerInfoState };
        default:
            const exhaustiveCheck: any = action;
    }

    return state || dafaultPartnerInfoState;
}