import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface ILandState {
  
}

export class LandingThree extends React.Component<any, ILandState>{
    public defaultItemPrice = 110;
    public defaultItemSellPrice = 200;
    public slidesToShowItem;
    constructor(props) {
        super(props);
        
    }

    public OnOpenRegistrationPage(): void {
        window.open('https://b2b.caseshop.com.ua/auth/register', '_self');
    }

    render() {


        return (
            <div className="landPageContainer landPage3Container">
                <div className="landGLOBAL__header">
                    <div className="landGLOBAL__headerContainer">
                    <ul className="header_menu_component">
                            <li className="menu_list_item">
                                <NavLink to={"/lending3"} activeClassName='active'>Главная</NavLink>
                            </li>
                            <li className="menu_list_item ">
                                <NavLink to={"/lending1"} activeClassName='active'>Чехлы с печатью</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/lending2"} activeClassName='active'>Другие аксессуары</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/auth/login"} activeClassName='active'>Вход</NavLink>
                            </li>
                        </ul>
                        <div className="header_logo"></div>
                    </div>
                </div>

                <div className="landWrapper__container">
                    <div className="topPicture__container top3Picture__container">
                        <div className="topPicture__containerTextPhone">
                            <span className="phoneContainer">069 669 96 96</span>
                            <span className="emailContainer">b2b.caseshop@gmail.com</span>
                        </div>
                        <div className="topPicture__containerText">
                            <span>Добро пожаловать на портал сотрудничества</span>
                            <span className="logo" />
                            <span>
                                К Вашему вниманию представляем висококачественую УФ печать на чехлах. Ми предлагаем Вам один из самых лёгких видов заробатка с нами - дропшиппинг. Вы прилогаете минимальные усилия получяя грандиозный результат. Никаких стартовых вложений, большого персонала или склада!
                            </span>

                            <div className="regButton" onClick={() => this.OnOpenRegistrationPage()}>Регистрация</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}