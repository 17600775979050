import { RightViewState } from "../../states/right.view.state";

export const SELECT_PARNTER_RV_DATA: string = 'SELECT_PARNTER_RV_DATA';
export interface ISelectPartnerRVData { type: 'SELECT_PARNTER_RV_DATA'; payload: any; }
export const SelectPartnerRVData = (rvData: RightViewState) => <ISelectPartnerRVData>{ type: SELECT_PARNTER_RV_DATA, payload: rvData };

export const REMOVE_PARTNER_RV_DATA: string = 'REMOVE_PARTNER_RV_DATA';
export interface IRemovePartnerRVData { type: 'REMOVE_PARTNER_RV_DATA'; payload: any; }
export const RemovePartnerRVData = () => <IRemovePartnerRVData>{ type: REMOVE_PARTNER_RV_DATA };

export type PartnerKnownActions =
    ISelectPartnerRVData |
    IRemovePartnerRVData;
