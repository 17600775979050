import * as React from "react";
import { AuditEntity } from "../../../business.entities/audit.entity";
import { default as PartnerPriceAuditItem } from "./partner.price.audit.item";
import { default as PartnerPriceTermsAuditItem } from "./partner.price.terms.audit.item";
import { ApplicationState } from "../../../reducers/index";
import { connect } from "react-redux";
import * as moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { bindActionCreators } from "redux";
import * as priceActions from "../../../actions/stock/price.actions";
import { DatePicker } from "material-ui";

export interface IAuditComponent {
    AuditEntities: AuditEntity[];
}
const AuditComponent = (props: any) => {
    const IntlPolyfill = require('intl');
    const DateTimeFormat = IntlPolyfill.DateTimeFormat;
    require('intl/locale-data/jsonp/ru-UA');

    const format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    return (
        <div className="">
            <div className="audit__com__header">
                <div className="filterDate__containere">
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Min Date</div>
                        <div className="dateTimeWrapper__container">
                            <DatePicker
                                DateTimeFormat={DateTimeFormat}
                                formatDate={format}
                                floatingLabelText="Min Date"
                                value={props.priceState.auditStartDate ? props.priceState.auditStartDate.toDate() : props.priceState.auditStartDate}
                                onChange={(event, date: any) => {
                                    props.SetStartDate(moment(date).hour(0).minutes(0).seconds(0));
                                    props.GetHistory(
                                        props.priceState.selectedPartner ? props.priceState.selectedPartner.value : "",
                                        props.rightViewState.Props.SelectedMenuItem.AuditEntityName,
                                        props.priceState.auditStartDate ? props.priceState.auditStartDate.format('DD MMMM YYYY') : props.priceState.auditStartDate,
                                        props.priceState.auditEndDate ? props.priceState.auditEndDate.format('DD MMMM YYYY') : props.priceState.auditEndDate
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Max Date</div>
                        <div className="dateTimeWrapper__container">
                            <DatePicker
                                DateTimeFormat={DateTimeFormat}
                                formatDate={format}
                                floatingLabelText="Max Date"
                                value={props.priceState.auditEndDate ? props.priceState.auditEndDate.toDate() : props.priceState.auditEndDate}
                                onChange={(event, date: any) => {
                                    props.SetEndDate(moment(date).hour(0).minutes(0).seconds(0));
                                    props.GetHistory(
                                        props.priceState.selectedPartner ? props.priceState.selectedPartner.value : "",
                                        props.rightViewState.Props.SelectedMenuItem.AuditEntityName,
                                        props.priceState.auditStartDate ? props.priceState.auditStartDate.format('DD MMMM YYYY') : props.priceState.auditStartDate,
                                        props.priceState.auditEndDate ? props.priceState.auditEndDate.format('DD MMMM YYYY') : props.priceState.auditEndDate
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="audit_component">


                {
                    props.rightViewState.Props.AuditEntities && props.rightViewState.Props.AuditEntities.map((auditEntity: AuditEntity, index: number) => {
                        if (auditEntity.EntityName === "PartnerPrice")
                            return (<PartnerPriceAuditItem AuditEntity={auditEntity} key={index} />);
                        if (auditEntity.EntityName === "PriceTerm")
                            return (<PartnerPriceTermsAuditItem AuditEntity={auditEntity} key={index} />);
                        if (auditEntity.EntityName === "Wholesale")
                            return (<PartnerPriceAuditItem AuditEntity={auditEntity} key={index} />);
                    })
                }
            </div>
        </div>
    );
}
export default connect(
    (state: ApplicationState) => ({ rightViewState: state.rightView, priceState: state.stock.price }),
    (dispatch) => bindActionCreators({
        SetStartDate: priceActions.SetStartDate,
        SetEndDate: priceActions.SetEndDate,
        GetHistory: priceActions.GetPartnerHistory
    }, dispatch))(AuditComponent)