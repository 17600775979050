export class TutorialState {
    tutorials: any[];
    total: number;
    fetching: boolean;
    selectedTutorial: any;
    uploadedVideo: any;
}

export const DefaultTutorialState = {
    tutorials: [],
    total: 0,
    fetching: false,
    selectedTutorial: {},
    uploadedVideo: null
}
