import * as React from "react";
import Chart from "chart.js"

export class ChartComponent extends React.Component<any, any>{
    constructor(props){
        super(props);

        (this as any).canvas = React.createRef();
    }

    componentDidMount(){
        new Chart((this as any).canvas.current, {
            type: 'line',
            data: this.props.data,
            options: {
                legend: {
                    display: false,
                },
                maintainAspectRatio: false,
                elements: {
                    line: {
                        fill: false,
                        tension: 0,
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                        }
                    }]
                }
            }
        })
    }

    render(){
        return(
            <div>
                <canvas ref={(this as any).canvas} />
            </div>
        );
    }
}