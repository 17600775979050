import Product from './product';
import ProductColor from './product.color';
import { Color } from './color';
import { EntityBase } from './entity.base';
import { Order } from './order';
import { TempOrder } from './temp.order';
import { ProductTypeEnum } from '../helpers/enums/product.type.enum';
import { PrintVerificationStatusEnum } from '../helpers/enums/print.verification.status.enum';
import { ProductPrintStatus } from './product.print.status';
import { ProductProductionStatus } from './product.production.status';
import { ProductLaserStatus } from './product.laser.status';

export class OrderProduct extends EntityBase {
    public OrderId: number;
    public TempOrderId: number;
    public ColorId: number = null;
    public ProductId: number = null;
    public BaseProductId: number = null;
    public Quantity: number;
    public PartnerPrice: number;
    public Price: number;
    public BaseColorId: number = null;
    public PartnerTotal: number;
    public Total: number;
    public ProductType: ProductTypeEnum;
    public Product: Product;
    public BaseProduct: Product;
    public Order: Order;
    public TempOrder: TempOrder;
    public Color: Color;
    public BaseColor: Color;
    public ProductColor: ProductColor;
    public UserImage: string;
    public UserPrewiev: string;
    public FailImage: string;
    public UserImageComment: string;
    public PrintComment: string;
    public ProductionComment: string;
    public IsAvailable: boolean;
    public PrintVerificationStatus: PrintVerificationStatusEnum = null;
    public CanBeReused: boolean;
    public ExpressPrintOrderProductID: number;
    public ExtraPrintOrderProductID: number;
    public ExpressPrintOrderProduct: OrderProduct;
    public ExtraPrintOrderProduct: OrderProduct;
    public ParentOrderProductID: number;
    public ParentOrderProduct: OrderProduct;
    public ChildrenOrderProducts: OrderProduct[] = [];

    public ForceWholesale: boolean = false;

    public ProductPrintStatus: ProductPrintStatus[] = [];
    public ProductProductionStatus: ProductProductionStatus[] = [];
    public ProductLaserStatus: ProductLaserStatus[] = [];

    public FinishPrintStatus: ProductPrintStatus;
    public FinishProductionStatus: ProductProductionStatus;
    public FinishLaserStatus: ProductLaserStatus;

    public OrderProductAdditionalOption: any[] = [];

    //UI 
    public SelectedPrintStatus: any;
    public SelectedLaserStatus: any;
    public SelectedVerificationStatus: any;
    public SelectedReuseOption: any;
    public SelectedProductionStatus: any;
    public CantReuseQuantity: number = 1;
}