import { Partner } from "./partner";
import { Lexicon } from "./lexicon";
import { EntityBase } from "./entity.base";

export class PartnerPrice extends EntityBase {
    public Price: number = 0;
    public D1Price: number = 0;
    public D2Price: number = 0;
    public D3Price: number = 0;
    public D4Price: number = 0;
    public D5Price: number = 0;
    public D6Price: number = 0;
    public WholesalePrice: number = 0;
    public S1Percents: number = 0;
    public S2Percents: number = 0;
    public S3Percents: number = 0;
    public S4Percents: number = 0;
    public S5Percents: number = 0;
    public S6Percents: number = 0;
    public S7Percents: number = 0;
    public Condition: number = 0;
    public IsDefault: boolean = false;
    public LexiconId: number = 0;
    public PartnerId?: number = 0;
    public Partner: Partner;
    public Lexicon: Lexicon;
    public Comment: string = '';
}