import * as React from 'react';
import Moment from 'react-moment';
import { AuditEntity } from '../../../business.entities/audit.entity';
import { AuditEntityProperty } from '../../../business.entities/audit.entity.property';
import 'moment-timezone';
import { ApplicationState } from '../../../Reducers/index';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { GetAuditEventTypeName } from '../../../helpers/enums/audit.event.type.enum';

const mapStateToProps = (state: any) => {
    return {
        Translate: getTranslate(state.locale),
        CurrentLanguage: getActiveLanguage(state.locale).code
    };
}

export const PartnerPriceTermsAuditItem = (props: any) => {
    return (
        <div className="audit_item">
            <div className="audit_header_component">
                <div className="created_log">
                    <span className="date"><Moment format={'DD'} date={props.AuditEntity.Created}></Moment></span>
                    <div className="weekday_month">
                        <span className="year"><Moment format={'YYYY'} date={props.AuditEntity.Created}></Moment></span>
                        <span className="month"><Moment format={'MMMM'} date={props.AuditEntity.Created} /></span>

                    </div>
                    <span className="weekday"><Moment format={'HH:mm'} date={props.AuditEntity.Created} />
                    </span>
                </div>

                <div className="created_log_user">
                    <div className="status">{GetAuditEventTypeName(props.AuditEntity.Type)}</div>
                    <div className="user">
                        {props.AuditEntity.UpdatedBy}</div>
                </div>
            </div>

            <div className="item_content">
                <div className="item">
                    <div className="item_content_title">
                        Новое значение
                    </div>
                    <div className="value_item">
                        {
                            props.AuditEntity.NewValues.map((newValue: AuditEntityProperty, index: number) =>
                                <div className="value_name" key={index}>
                                    {props.Translate(newValue.Name)}:
                                    {newValue.Value}
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="item">
                    <div className="item_content_title">
                        Старое значение
                    </div>
                    <div className="value_item">
                        {
                            props.AuditEntity.OldValues && props.AuditEntity.OldValues.map((oldValue: AuditEntityProperty, index: number) =>
                                <div className="value_name" key={index}>
                                    {props.Translate(oldValue.Name)}:
                                    {oldValue.Value ? (isNaN(oldValue.Value as any) ? oldValue.Value : parseFloat((Math.round((oldValue.Value as any ) * 100) / 100) as any).toFixed(2)) : null}
                                </div>)
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps)(PartnerPriceTermsAuditItem);