import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as React from "react";
import * as tutorialActions from "../../../../actions/cabinet/tutorial.actions";
import { Loader } from "../../../shared/loader";

const EditTutorialComponent = (props: any) => {

    const OnFileUpload = (event) => {
        const files = Array.from(event.target.files)
        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);
        props.ChangeVideo(formData);
    }

    return (
        <div className="editTutorialContainer">

            <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Имя
                </div>
                <div className="defaultFormControl__value">
                    <input type="text" onChange={(event) => props.ChangeName(event.target.value)} value={props.Tutorial.selectedTutorial.Name} />
                </div>
            </div>

            {/* <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Описание
                </div>
                <div className="defaultFormControl__value">
                    <input type="text" onChange={(event) => props.ChangeDescription(event.target.value)} value={props.Tutorial.selectedTutorial.Description} />
                </div>
            </div> */}

            <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Порядок
                </div>
                <div className="defaultFormControl__value">
                    <input type="number" min={0} onChange={(event) => props.ChangeOrder(event.target.value)} value={props.Tutorial.selectedTutorial.Order} />
                </div>
            </div>

            {/* file */}
            <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Видео
                </div>
                {props.Tutorial.selectedTutorial.Link || props.Tutorial.uploadedVideo ?
                    <div className="defaultFormControl__value">
                        <div className="imitation_inputText wordWrap">
                            {props.Tutorial.uploadedVideo ? "Uploaded video" : props.Tutorial.selectedTutorial.Link}

                            <span className="rControl" onClick={() => props.ChangeVideo(null)}>Удалить</span>
                        </div>
                    </div> :
                    <label className="onFileUploadControl">
                        <div className="label">
                            Видео
                            <input type="file" accept="video/*" onChange={(event) => OnFileUpload(event)} />
                        </div>
                    </label>
                }
            </div>

            {props.Tutorial.selectedTutorial.NetUid ?
                <div className="defaultFormControl ">
                    <div className="defaultFormControl__label">
                        Удалён
                </div>
                    <div className="defaultCheckFormControl__value chk__fix">
                        <label>
                            <input type="checkbox" onChange={(event) => props.ChangeDeleted(event.target.checked)} checked={props.Tutorial.selectedTutorial.Deleted} />
                            <div className="chActiviti"></div>
                        </label>
                    </div>
                </div>
                : null
            }

            <div className="defaultFormControl">
                    {props.Tutorial.selectedTutorial.Id ?
                        <div className={`defaultFormButtonControl ${props.Tutorial.fetching ? 'isDisabled' : '' }`} onClick={() => !props.Tutorial.fetching && props.UpdateTutorial({
                            uid: props.Tutorial.selectedTutorial.NetUid,
                            name: props.Tutorial.selectedTutorial.Name,
                            descr: props.Tutorial.selectedTutorial.Description,
                            order: props.Tutorial.selectedTutorial.Order,
                            isDeleted: props.Tutorial.selectedTutorial.Deleted
                        },
                            props.Tutorial.uploadedVideo)} >
                            Сохранить
                    </div>
                        : <div className={`defaultFormButtonControl ${props.Tutorial.fetching ? 'isDisabled' : '' }`} onClick={() => !props.Tutorial.fetching && props.AddTutorial({
                            name: props.Tutorial.selectedTutorial.Name,
                            descr: props.Tutorial.selectedTutorial.Description,
                            order: props.Tutorial.selectedTutorial.Order
                        },
                            props.Tutorial.uploadedVideo)} >
                            Сохранить
                </div>}
            </div>
        </div>
    );
}
export default connect((state: any) => ({
    Tutorial: state.cabinet.tutorial
}),
    dispatch => bindActionCreators({
        ChangeName: tutorialActions.ChangeName,
        ChangeDescription: tutorialActions.ChangeDescription,
        ChangeOrder: tutorialActions.ChangeOrder,
        ChangeVideo: tutorialActions.ChangeVideo,
        ChangeDeleted: tutorialActions.ChangeDeleted,
        AddTutorial: tutorialActions.NewTutorial,
        UpdateTutorial: tutorialActions.UpdateTutorial,
    }, dispatch))(EditTutorialComponent);