import * as API from '../../constants/api.constants';
import * as moment from 'moment';
import * as wizardActions from '../../actions/sales/wizard.actions';
import Category from '../../business.entities/category';
import storeProvider from '../../helpers/store.provider';
import { ajax } from 'rxjs/ajax';
import { CalculateDeliveryDateBody } from '../../helpers/novaposhta.body.builder';
import {
    catchError,
    debounceTime,
    map,
    mergeMap,
    concatMap
} from 'rxjs/operators';
import { Client } from '../../business.entities/client';
import { error as errorNotification, success as successNotification } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { List } from 'linqts';
import { Observable, of, pipe } from 'rxjs';
import { ofType } from 'redux-observable';
import { Order } from '../../business.entities/order';
import { OrderProduct } from '../../business.entities/order.product';
import { TempOrder } from '../../business.entities/temp.order';
import { push } from 'react-router-redux';

export const GetCategoriesAutocomplete = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_CATEGORIES),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Category) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                        });

                        return arrayToReturn;
                    }),
                    map(data => wizardActions.SetCategories(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetSubCategoryList = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_SUBCATEGORIES),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CATEGORY_FOR_AUTOCOMPLETE}?id=${action.payload.id}&value=${action.payload.value}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Category) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                        });

                        return arrayToReturn;
                    }),
                    map(data => wizardActions.SetSubCategories(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetProducts = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_PRODUCTS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_FOR_WIZARD}?categoryId=${action.payload.categoryId}&subCategoryId=${action.payload.subCategoryId}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => wizardActions.SetProducts((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAdditionalServiceProducts = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_ADDITIONAL_SERVICE_PRODUCTS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ADDITIONAL_SERVICE_PRODUCTS}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => wizardActions.SetAdditionalServiceProducts((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const CreateTempOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CREATE_TEMP_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.ADD_TEMP_ORDER}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    concatMap(response => of(
                        wizardActions.SetTempOrder((response as any).Body),
                        push(`wizard/${(response as any).Body.NetUid}`))
                    ),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetTempOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_TEMP_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_TEMP_ORDER_BY_UID}?uid=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        const order: TempOrder = (response as any).Body;

                        if (order.Client) {
                            let client = { value: order.Client.Id.toString(), label: order.Client.PhoneNumber, object: order.Client };
                            storeProvider.getStore().dispatch(wizardActions.SelectClientPartner(client));
                        }

                        if (order.NovaPoshtaCityRef && order.NovaPoshtaCityName) {
                            storeProvider.getStore().dispatch(wizardActions.SelectCitiesForAutocomplete({ value: order.NovaPoshtaCityRef, label: order.NovaPoshtaCityName }))
                        }

                        if (order.NovaPoshtaWarehouseRef && order.NovaPoshtaWarehouseName) {
                            storeProvider.getStore().dispatch(wizardActions.SelectWerehousesForAutocomplete({ value: order.NovaPoshtaWarehouseRef, label: order.NovaPoshtaWarehouseName }))
                        }

                        storeProvider.getStore().dispatch(wizardActions.SetCheckedTempOrder(order));

                        return wizardActions.ApproveOrderCreation();
                    }
                    ),
                    catchError(error => {
                        storeProvider.getStore().dispatch(push(`/sales/create-order`));
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    );

export const GetClientsForPartner = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_CLIENTS_FOR_PARTNER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CLIENTS_BY_PARTNTER_AND_PHONE}?search=${action.payload ? action.payload : ''}&uid=`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        let arrayToReturn: any[] = [];

                        if ((response as any).Body.length === 0) {
                            if ((action.payload.startsWith('0', 0) && action.payload.length === 10)
                                || (action.payload.startsWith('38', 0) && action.payload.length === 12)) {

                                let promise = new Promise((resolve, reject) => {
                                    resolve(storeProvider.getStore().dispatch(wizardActions.SetClientPhone(action.payload, true)));
                                });

                                promise.then(() => {
                                    storeProvider.getStore().getState().sales.wizard.clientPhoneInputRef.current.focus();
                                });
                            }

                            return wizardActions.SetClientsForPartner([]);
                        }

                        (response as any).Body.forEach((element: Client) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.PhoneNumber, object: element });
                        });

                        return wizardActions.SetClientsForPartner(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SaveOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.SAVE_ORDER_PRODUCT),
        mergeMap((action: any) => {
            return ajax.post(`${API.ADD_ORDER_PRODUCT}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        let existing = new List<OrderProduct>(state$.value.sales.wizard.order.OrderProducts)
                            .Where(c => c.Id === (response as any).response.Body.orderProductTuple.Item2.Id)
                            .FirstOrDefault();
                        let index = -1;

                        if (existing)
                            index = state$.value.sales.wizard.order.OrderProducts.indexOf(existing);

                        if (index !== -1) {
                            return wizardActions.SetOrderProductByIndex(
                                (response as any).response.Body.orderProductTuple.Item2,
                                index,
                                (response as any).response.Body.orderTotalsTuple.Item1,
                                (response as any).response.Body.orderTotalsTuple.Item2,
                                (response as any).response.Body.orderProductTuple.Item1);
                        } else {
                            return wizardActions.SetOrderProduct(
                                (response as any).response.Body.orderProductTuple.Item2,
                                (response as any).response.Body.orderTotalsTuple.Item1,
                                (response as any).response.Body.orderTotalsTuple.Item2,
                                (response as any).response.Body.orderProductTuple.Item1);
                        }
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

export const UpdateOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.UPDATE_ORDER_PRODUCT_SERVER),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER_PRODUCT}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let currentOrderProduct = new List<OrderProduct>(state$.value.sales.wizard.order.OrderProducts)
                            .Where(c => c.Id === (response as any).response.Body.orderProductToReturn.Id)
                            .FirstOrDefault();

                        let index = state$.value.sales.wizard.order.OrderProducts.indexOf(currentOrderProduct);

                        if (action.payload.Id !== (response as any).response.Body.orderProductToReturn.Id) {
                            let orderProductToRemove = new List<OrderProduct>(state$.value.sales.wizard.order.OrderProducts)
                                .Where(c => c.Id === action.payload.Id)
                                .FirstOrDefault();
                            let indexToRemove = state$.value.sales.wizard.order.OrderProducts.indexOf(orderProductToRemove);

                            if (indexToRemove !== -1) {
                                storeProvider.getStore().dispatch(wizardActions.RemoveOrderProductClient(
                                    indexToRemove,
                                    (response as any).response.Body.orderTotalsTuple.Item1,
                                    (response as any).response.Body.orderTotalsTuple.Item2,
                                    state$.value.sales.wizard.order.OrderType));
                            }
                        }

                        return wizardActions.UpdateOrderProductClient(
                            (response as any).response.Body.orderProductToReturn,
                            index,
                            (response as any).response.Body.orderTotalsTuple.Item1,
                            (response as any).response.Body.orderTotalsTuple.Item2);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RemoveOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.REMOVE_ORDER_PRODUCT_SERVER),
        mergeMap((action: any) =>
            ajax.delete(`${API.REMOVE_ORDER_PRODUCT}?uid=${action.payload.NetUid}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let index = storeProvider.getStore().getState().sales.wizard.order.OrderProducts.indexOf(action.payload);
                        return wizardActions.RemoveOrderProductClient(
                            index,
                            (response as any).response.Body.orderTotalsTuple.Item1,
                            (response as any).response.Body.orderTotalsTuple.Item2,
                            (response as any).response.Body.orderProductTuple.Item1);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const AddNewClient = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.ADD_NEW_CLIENT),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_CLIENT}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return wizardActions.SetNewClient(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateTempOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.UPDATE_TEMP_ORDER),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_TEMP_ORDER}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        return wizardActions.SetTempOrder(response.response.Body);
                    }),
                    catchError(error => {
                        storeProvider.getStore().dispatch(ErrorHandler(error));
                        return Observable.of(wizardActions.SetTempOrder(action.payload));
                    })
                )
        )
    )

export const GetProductTotalPrice = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CALCULATE_PRODUCT_TOTAL),
        debounceTime(1000),
        mergeMap((action: any) => {
            return ajax.post(`${API.GET_PRICE_FOR_PRODUCT}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return wizardActions.SetTotalForCurrentOrderProduct(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        })
    )

export const GetCitiesFromNP = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_CITIES_FOR_AUTOCOMPLETE),
        mergeMap((action: any) =>
            ajax.post(`${API.NOVA_POSHTA_GET_CITIES}`,
                JSON.stringify({
                    apiKey: state$.value.authentication.userInfo.NovaPoshtaApiKey,
                    modelName: "Address",
                    calledMethod: "searchSettlements",
                    methodProperties: {
                        CityName: action.payload,
                        Limit: 25
                    }
                }),
                { "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];

                        state$.value.authentication.userInfo.ContactName
                        if (response.response.errors.length > 0 && response.response.errors.indexOf("API key expired") !== -1) {
                            storeProvider.getStore().dispatch(
                                errorNotification({
                                    title: 'Вышел срок действия ключа АПИ Новой Почты',
                                    message: '',
                                    position: 'br',
                                    autoDismiss: 3,
                                })
                            )

                            return wizardActions.SetCitiesForAutocomplete(arrayToReturn);
                        }


                        if (response.response.data[0])
                            response.response.data[0].Addresses.forEach((element: any) => {
                                if (element.Warehouses > 0) {
                                    arrayToReturn.push({ value: element.DeliveryCity, label: element.Present, entity: element });
                                }
                            });

                        return wizardActions.SetCitiesForAutocomplete(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetWarehousesFromNP = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_WEREHOUSES_FOR_AUTOCOMPLETE),
        mergeMap((action: any) => {
            return ajax.post(`${API.NOVA_POSHTA_GET_WEREHOUSES}`,
                JSON.stringify({
                    cityRef: action.payload,
                    apiKey: state$.value.authentication.userInfo.NovaPoshtaApiKey
                }),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let arrayToReturn: any[] = [];
                        if (response.response.errors.length > 0 && response.response.errors.indexOf("API key expired") !== -1) {
                            storeProvider.getStore().dispatch(
                                errorNotification({
                                    title: 'Вышел срок действия ключа АПИ Новой Почты',
                                    message: '',
                                    position: 'br',
                                    autoDismiss: 3,
                                })
                            );

                            return wizardActions.SetCitiesForAutocomplete(arrayToReturn);
                        }

                        if (response.response.data)
                            response.response.data.forEach((element: any) => {
                                arrayToReturn.push({ value: element.Ref, label: element.DescriptionRu, entity: element });
                            });

                        return wizardActions.SetWerehousesForAutocomplete(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

export const CheckForTempOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CHECK_FOR_TEMP_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.CHECK_FOR_UNFINISHED_TEMP}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        const order: TempOrder = (response as any).Body;
                        if (order) {
                            if (order.Client) {
                                let client = { value: order.Client.Id.toString(), label: order.Client.PhoneNumber, object: order.Client };
                                storeProvider.getStore().dispatch(wizardActions.SelectClientPartner(client));
                            }

                            if (order.NovaPoshtaCityRef && order.NovaPoshtaCityName) {
                                storeProvider.getStore().dispatch(wizardActions.SelectCitiesForAutocomplete({ value: order.NovaPoshtaCityRef, label: order.NovaPoshtaCityName }))
                            }

                            if (order.NovaPoshtaWarehouseRef && order.NovaPoshtaWarehouseName) {
                                storeProvider.getStore().dispatch(wizardActions.SelectWerehousesForAutocomplete({ value: order.NovaPoshtaWarehouseRef, label: order.NovaPoshtaWarehouseName }))
                            }

                            return wizardActions.SetCheckedTempOrder(order);
                        }

                        return wizardActions.SetCheckedTempOrder(new Order());
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )



export const CreateOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.ADD_NEW_ORDER_WITH_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_ORDER}`,
                JSON.stringify({
                    tempOrderId: action.payload.tempOrderId,
                    status: action.payload.statusId
                }),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(wizardActions.ClearWizard());
                        return push(`/sales/create-order`);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ProcessTempOrder = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.PROCESS_TEMP_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.PROCESS_TEMP_ORDER}?uid=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        // return successNotification({
                        //     title: (response as any).Body.message,
                        //     message: '',
                        //     position: 'br',
                        //     autoDismiss: 3,
                        // });
                        storeProvider.getStore().dispatch(wizardActions.ClearWizard());
                        return push(`/sales/create-order`);
                        // return wizardActions.ClearWizard();
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UploadUserImage = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.UPLOAD_USER_IMAGE),
        mergeMap((action: any) =>
            ajax.post(`${API.UPLOAD_ORDER_PRODUCT_IMAGE}?netId=${action.payload.tempOrdernetId}`,
                action.payload.formData,
                {
                    "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        return wizardActions.SetUserImage((response as any).response.Body, action.payload.fileType);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UploadUserPreview = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.UPLOAD_USER_PREVIEW),
        mergeMap((action: any) =>
            ajax.post(`${API.UPLOAD_ORDER_PRODUCT_PREVIEW}?netId=${action.payload.tempOrdernetId}`,
                action.payload.formData,
                {
                    "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        return wizardActions.SetUserImage((response as any).response.Body, action.payload.fileType);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetBasesForOrderProducts = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_BASES_FOR_ORDERPRODUCT),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_BASES}?categoryId=${action.payload.categoryId}&subcategoryId=${action.payload.subCategoryId}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => wizardActions.SetBasesForProduct((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetProductBasesViaLexicon = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_PRODUCT_BASES),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_BASE_PRODUCTS}?categoryId=${action.payload.categoryId}&subcategoryId=${action.payload.subcategoryId}&lexiconId=${action.payload.lexiconId}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => wizardActions.SetBasesForProduct((response as any).Body)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ValidateForStatus = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.VALIDATE_FOR_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.CHECK_STATUS_TEMP}`,
                JSON.stringify({
                    tempOrderId: action.payload.tempOrderId,
                    status: action.payload.statusId
                }),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        const selctedFinishStatus = new List(state$.value.sales.wizard.finishStatus)
                            .Where(c => (c as any).id === action.payload.statusId)
                            .FirstOrDefault();

                        const index = state$.value.sales.wizard.finishStatus.indexOf(selctedFinishStatus);

                        return wizardActions.SelectFinishStatus(index, response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ClearAndCheck = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CLEAR_WIZARD),
        debounceTime(2000),
        map((action: any) => {

            return wizardActions.CheckForTempOrder();
        })
    )

export const UpdateGlobalComment = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CHANGE_GLOBAL_COMMENT),
        debounceTime(2000),
        map((action: any) => {

            return wizardActions.UpdateTempOrder(state$.value.sales.wizard.order);
        })
    )

export const GetDeliveryPrice = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_DELIVERY_PRICE),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_DELIVERY}?deliveryTypeId=${action.payload.deliveryType}&paymentMethodId=${action.payload.paymentMethod}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return wizardActions.SetDeliveryPrice((response as any).Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const CalculateSendDate = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CALCULATE_SEND_DATE),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.CALCULATE_SEND_DATE}?orderType=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return wizardActions.SetSendDate((response as any).Body);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const CalculateDeliveryDate = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.CALCULATER_DELIVERY_DATE),
        mergeMap((action: any) =>
            ajax.post(`${API.NOVA_POSHTA_CALCULATE_DELIVERY_DATE}`,
                action.payload,
                { "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let data = (response as any).response.data[0].DeliveryDate.date;

                        return wizardActions.SetDeliveryDate(moment(data).toDate());
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RefreshOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.REFRESH_ORDERPRODUCT),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDER_PRODUCT_BY_UID}?uid=${action.payload.netid}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        return wizardActions.SetRefreshedOrderProduct((response as any).Body, action.payload.index);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetOptionsTree = (action$, state$) =>
    action$.pipe(
        ofType(wizardActions.GET_OPTIONS_FOR_LEXICON),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ADDITIONAL_OPTIONS_TREE}?lexiconUid=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return wizardActions.SetOptionsForLexicon((response as any).Body);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

