import * as React from "react";
import { Control, Errors } from "react-redux-form";

interface ITextBoxWithValidatorProps {
    LabelMessage?: string;
    ErrorMessages?: any;
    Model: string;
    IsRequired?: boolean;
    Validators?: any,
    ValidatedOn?: any,
    ShowErrorOn?: any,
    Placeholder?: any,
    Readonly?: boolean,
    Type?: string
    AsyncValidators?: any;
    AsyncValidateOn?: any;
    MaxLength?: number;
    IsLink?: boolean;
}

export const CustomErrors = (props: any) => {
    return (
        <div className="errrors">
            {
                props.children.map((obj: any) =>
                    <span className={obj.key} key={obj.key}>
                        {obj.props.children}
                    </span>)
            }
        </div>
    )
}

export const TextBox = (props: ITextBoxWithValidatorProps) => {
    let currentValue: any;
    const setValue = (event: any) => {
        if (props.IsLink && event) {
            currentValue = event.value ? event.value : '';
        }
    }

    const onOpenLinkCommand = (event: any) => {
        if (currentValue)
            window.open(currentValue);
    }

    return (
        <div className={'defaultFormControl__value notPadd ' + (props.ShowErrorOn ? 'showErrorOn' : 'showErrorOn')}>
            <Control
                maxLength={props.MaxLength}
                readOnly={props.Readonly}
                placeholder={props.Placeholder}
                autoComplete='off'
                type={props.Type}
                required={props.IsRequired ? true : false}
                validateOn={props.ValidatedOn}
                validators={props.Validators}
                autoCorrect={"off"}
                spellCheck={false}
                model={props.Model}
                asyncValidators={props.AsyncValidators}
                asyncValidateOn={props.AsyncValidateOn}
                getRef={(event) => setValue(event)}
            />
            <Errors
                show={props.ShowErrorOn}
                model={props.Model}
                wrapper={CustomErrors}
                messages={props.ErrorMessages}
            />
            {
                props.IsLink ?
                    <div className="go_to_url" onClick={(event) => onOpenLinkCommand(event)}></div>
                    : null
            }
        </div>
    );
}