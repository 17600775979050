import * as actionCreators from '../../../../actions/stock/price.actions';
import * as React from 'react';
import { ApplicationState } from '../../../../Reducers/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { PartnerPrice } from '../../../../business.entities/partner.price';
import { TextBoxWithLabel } from '../../../shared/form.controls/textbox.with.label';
import { Validator } from '../../../../helpers/validator.helper';
import { Partner } from '../../../../business.entities/partner';
import { TextAreaWithLabel } from '../../../shared/form.controls/textarea.with.label';

export interface IPartnerPriceLevelForm {
    UpdatePriceSegment: (priceSegment: PartnerPrice) => any;
    CreatePriceSegmentForPartner: (priceSegment: PartnerPrice) => any;
    RemovePriceSegment: (netid: string) => any;
    SelectedPartner: Partner;
    PartnerPriceForm: PartnerPrice;
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        PartnerPriceForm: (state as any).partnerPriceForm,
        SelectedPartner: state.stock.price.selectedPartner
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        UpdatePriceSegment: actionCreators.UpdatePartnerPriceSegment,
        CreatePriceSegmentForPartner: actionCreators.CreatePartnerPriceSegment,
        RemovePriceSegment: actionCreators.RemovePriceSegment
    }, dispatch);
};

const PartnerPriceLevelForm = (props:IPartnerPriceLevelForm | any) => {
    const preparePrice = (partnerPrice: PartnerPrice) => {
        //debugger
        if (props.SelectedPartner) {
            if (!partnerPrice.PartnerId) {
                props.CreatePriceSegmentForPartner(Object.assign({}, partnerPrice, { partnerId: (props.SelectedPartner as any).partnerId, IsDefault: false }));
            } else {
                props.UpdatePriceSegment(partnerPrice);
            }
        } else {
            props.UpdatePriceSegment(partnerPrice);
        }
    }

    const removePrice = () => {
        !props.PartnerPriceForm.IsDefault && props.RemovePriceSegment(props.PartnerPriceForm.NetUid);
    }

    return (
        <Form
            validateOn='change'
            model="partnerPriceForm"
            onSubmit={preparePrice}
        >
            <div className="default_form_component shadow_controls">

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S1'
                    IsRequired={true}
                    Model='.S1Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S2'
                    IsRequired={true}
                    Model='.S2Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S3'
                    IsRequired={true}
                    Model='.S3Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S4'
                    IsRequired={true}
                    Model='.S4Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S5'
                    IsRequired={true}
                    Model='.S5Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S6'
                    IsRequired={true}
                    Model='.S6Percents' />

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Уровень S7'
                    IsRequired={true}
                    Model='.S7Percents' />

                <TextAreaWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                    }}
                    ShowErrorOn='touched'
                    ValidatedOn='change'
                    LabelMessage='Коментарии'
                    IsRequired
                    Model='.Comment'
                />

                <div className="defaultFormControl ">
                    {
                        props.PartnerPriceForm.IsDefault ?
                            null :
                            <div className="defaultFormButton" onClick={removePrice}>Удалить</div>
                    }
                    <button type="submit" className="defaultFormButton">Сохранить</button>
                </div>
            </div>
        </Form>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)
(PartnerPriceLevelForm);