import { OrderProductStep } from "./enums/order.product.steps";

//quantity -> price -> finish
export const SaleType = [
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

export const SaleTypeWithOptions = [
    OrderProductStep.AdditionalOption,
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];
//color -> quantity -> price -> finish
export const ColorSaleType = [
    OrderProductStep.ProductColor, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

export const ColorSaleTypeWithOptions = [
    OrderProductStep.ProductColor,
    OrderProductStep.AdditionalOption,
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

//color -> base -> base color -> picture -> price -> finish
export const PrintType = [
    OrderProductStep.BaseProduct, 
    OrderProductStep.BaseProductColor, 
    OrderProductStep.Picture, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

export const PrintTypeWithOptions = [
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct, 
    OrderProductStep.BaseProductColor, 
    OrderProductStep.Picture, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];
//color -> base -> base color -> design -> price -> finish
export const DesignPrintType = [ 
    OrderProductStep.BaseProduct, 
    OrderProductStep.BaseProductColor, 
    OrderProductStep.Design, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

export const DesignPrintTypeWithOptions = [ 
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct, 
    OrderProductStep.BaseProductColor, 
    OrderProductStep.Design, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];
//color -> base -> base color -> quantity -> price -> finish
export const ProductionType = [
    OrderProductStep.ProductColor,
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];


export const ProductionTypeWithOptions = [
    OrderProductStep.ProductColor,
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];

export const LaserType = [
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];

export const LaserTypeWithOptions = [
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];

export const LaserTypeWithImage = [
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct, 
    OrderProductStep.BaseProductColor, 
    OrderProductStep.Picture, 
    OrderProductStep.Price, 
    OrderProductStep.Quantity
];

//color -> base -> base color -> quantity -> price -> finish
export const PrintProductionType = [
    OrderProductStep.ProductColor,
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Picture,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];

export const PrintProductionTypeWithOptions = [
    OrderProductStep.ProductColor,
    OrderProductStep.AdditionalOption,
    OrderProductStep.BaseProduct,
    OrderProductStep.BaseProductColor,
    OrderProductStep.Picture,
    OrderProductStep.Price,
    OrderProductStep.Quantity
];
