import * as React from "react";
import { Product } from "../../../../business.entities/product";
import { ProductColor } from "../../../../business.entities/product.color";
import { AvailabilityProductColor } from "./availability.product.color";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../reducers/index";
import { LoginState } from "../../../../states/login.state";
import { List } from "linqts";
import { PriceLevel } from "../../../../helpers/enums/price.level.enum";
import { PartnerPrice } from "../../../../business.entities/partner.price";
import * as API from '../../../../constants/api.constants'
import { bindActionCreators } from "redux";
import { ShowHoverBlock } from "../../../../actions/right.view.actions";

export interface IAvailabilityProduct {
    Product: Product;
    Key: string;
}

type AvailabilityProductProps = IAvailabilityProduct & LoginState;

const AvailabilityProduct = (props: any) => {

    const goToSite = () => {
        (props as AvailabilityProductProps).Product.ProductSite
            && (props as AvailabilityProductProps).Product.ProductSite.Url
            && window.open((props as AvailabilityProductProps).Product.ProductSite.Url);
    }

    const getPartnerPriceLevelPercents = (lexiconId: number, wholesale: boolean = false) => {
        let partnerPriceForLexicon = new List((props as AvailabilityProductProps).userInfo.PartnerPrices).Where(c => c.LexiconId === lexiconId).FirstOrDefault();

        if (wholesale)
            return partnerPriceForLexicon.S4Percents;

        switch (props.userInfo.PriceLevel) {
            case PriceLevel.S1:
                return partnerPriceForLexicon.S1Percents;

            case PriceLevel.S2:
                return partnerPriceForLexicon.S2Percents;

            case PriceLevel.S3:
                return partnerPriceForLexicon.S3Percents;
        }
    }

    const getPartnerPrice = () => {
        return props.Product.PartnerPrice - (props.Product.PartnerPrice * (getPartnerPriceLevelPercents(props.Product.LexiconId) / 100));
    }

    const getWholesalePrice = () => {
        return props.Product.PartnerPrice - (props.Product.PartnerPrice * (getPartnerPriceLevelPercents(props.Product.LexiconId, true) / 100));
    }

    return (
        <div className="grid_row mMEDIA___availability_ROW">
            <div className="grid_cell cell__lexicon availability__CELL" data-tooltip="Наименование">
                <span className="cell_content">{props.Product.Lexicon.OfficialPartnerName}</span>
            </div>
            <div className="grid_cell cell__price availability__CELL" data-tooltip="Цена дропшиппинг">
                <span className="cell_content">{props.Product.PartnerPrice}</span>
            </div>
            <div className="grid_cell cell__ppc availability__CELL" data-tooltip="РРЦ">
                <span className="cell_content">{props.Product.RecomendedPartnerPrice}</span>
            </div>
            <div className="grid_cell cell__opt availability__CELL" data-tooltip="Оптовая цена">
                <span className="cell_content">{props.Product.WholesalePrice}</span>
            </div>
            <div className="grid_cell cell__sites availability__CELL" data-tooltip="Пример">
                {
                    props.Product.ProductSite && props.Product.ProductSite.Url ?
                        <div className="sites_icon" onClick={goToSite}>
                        </div> :
                        null
                }
            </div>
            <div className="grid_cell cell__code availability__CELL" data-tooltip="Код товара">
                <span className="cell_content">{props.Product.PartnerProductCode}</span>
            </div>
            <div className="grid_cell cell__colors availability__CELL " data-tooltip="Цвета">
                <div className="colors_list">
                    {
                        !props.Product.Lexicon.HasOptions && !props.Product.Lexicon.HasColors && props.Product.Lexicon.HasQuantity ?
                            <div className="item_color">
                                <div className="item_quantity">
                                    {props.Product.Quantity}
                                </div>
                            </div>
                            : null}

                    {props.Product.Lexicon.HasOptions && props.Product.Lexicon.HasQuantity ?
                        props.Product.ProductColors && props.Product.ProductColors.map((productColor: ProductColor, colorIndex: number) => {
                            return (
                                <>
                                    <div className="item_color imgThumb__WRAPPER" style={{ backgroundImage: `url(${API.CRM_URL + productColor.Color.Thumb})` }} 
                                        onMouseEnter={(e) => {e.stopPropagation(); props.ShowHoverBlock(productColor.Color)}}
                                        onMouseLeave={(e) => {e.stopPropagation(); props.ShowHoverBlock(null)}}
                                    >
                                        <div className="item_color_name">
                                            {productColor.Color.Name}
                                        </div>
                                        <div className="item_quantity">
                                            {productColor.Quantity}
                                        </div>
                                    </div>
                                    {/* <div className="imgThumb__WRAPPER__IMG" >
                                        <div className="item_color_name">
                                            {productColor.Color.Name}
                                        </div>
                                        <img src={`${API.CRM_URL + productColor.Color.Image}`} />
                                    </div> */}
                                </>
                            )
                        }
                        ) : null}

                    {props.Product.Lexicon.HasColors && props.Product.Lexicon.HasQuantity ?
                        props.Product.ProductColors && props.Product.ProductColors.map((productColor: ProductColor, colorIndex: number) => {
                            return <AvailabilityProductColor
                                key={colorIndex}
                                ProductColor={productColor}
                            />
                        }
                        ) : null}

                    {
                        props.Product.Lexicon.HasColors && !props.Product.Lexicon.HasQuantity ?
                            props.Product.ProductColors && props.Product.ProductColors.map((productColor: ProductColor, colorIndex: number) =>
                                <div className="item_color" style={{ backgroundColor: productColor.Color.Code === 'clear' ? '' : productColor.Color.Code }}>
                                    <div className="item_color_name">
                                        {productColor.Color.Name}
                                    </div>
                                </div>)
                            : null}
                </div>
            </div>
        </div>
    );
}
export default connect((state: ApplicationState) => state.authentication, 
(dispatch) => bindActionCreators({
    ShowHoverBlock
}, dispatch))(AvailabilityProduct)