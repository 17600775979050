import { connect } from 'react-redux';
import * as React from 'react';
import * as orderCardActions from '../../../../../actions/sales/order.card.actions';
import { bindActionCreators } from 'redux';
import { RemoveRVData, SelectRVData } from '../../../../../actions/right.view.actions';
import { RightViewState } from '../../../../../states/right.view.state';
import { default as OrderStatusesRv } from '././order.card.statuses';
import { default as PaymentRv } from './payment.rv';
import { default as DeliveryRv } from './delivery.rv';
import { default as ClientRv } from './client.rv';
import { default as PrintInfoRv } from './print.info.rv';
import { default as ProductionInfoRv } from './production.info.rv';
import { OrderCardClient as ClientBlock } from './order.card.client.component';
import { DeliveryType, GetDeliveryTypeName } from '../../../../../helpers/enums/delivery.type';
import { GetNameForOrderStatus, OrderStatusEnum } from '../../../../../helpers/enums/order.status.enum';
import { GetPaymentMethodName } from '../../../../../helpers/enums/payment.method';
import { OrderTypeEnum, GetTypeName } from '../../../../../helpers/enums/order.type.enum';
import { ProductTypeEnum } from '../../../../../helpers/enums/product.type.enum';
import { GetProductionStatusName, ProductionStatusEnum } from '../../../../../helpers/enums/production.status';
import * as API from '../../../../../constants/api.constants'
import { PrintStatusEnum, GetPrintStatusName } from '../../../../../helpers/enums/print.status.enum';
import { GetPrintVerificationStatusName } from '../../../../../helpers/enums/print.verification.status.enum';
import { Order } from '../../../../../business.entities/order';
import Moment from 'react-moment';
import { List } from 'linqts';
import PermissionChecker from '../../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept, GetIdentityRoleName } from '../../../../../helpers/identity.roles';
import * as moment from 'moment';
import { default as OrderAuditComponent } from './order.audit.component';
import { removeAll } from 'react-notification-system-redux';
import { IsPartner } from '../../../../../helpers/role.validator';
import { ShowHoverBlock } from '../../../../../actions/right.view.actions';
import { GetLaserStatusName, LaserStatusEnum } from '../../../../../helpers/enums/laser.status.enum';
import LaserInfoRv from './laser.info.rv';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetOrder: orderCardActions.GetOrderForOrderCard,
    RemoveRVData,
    SelectRVData,
    GetClients: orderCardActions.GetClientsForPartner,
    ResetClientRv: orderCardActions.ResetClientRv,
    SelectClient: orderCardActions.SelectClient,
    SetCurrentRecipient: orderCardActions.SetCurrentRecipient,
    SetCurrentDelivery: orderCardActions.SetCurrentDeliveryInfo,
    GetCitiesForAutocomplete: orderCardActions.GetCitiesForAutocomplete,
    GetWerehousesForAutocomplete: orderCardActions.GetWerehousesForAutocomplete,
    SelectOrderProductId: orderCardActions.SelectOrderProductForRv,
    SetProductUpdateMode: orderCardActions.SetProductUpdateMode,
    GetCategories: orderCardActions.GetCategories,
    SetCurrentOrderStatus: orderCardActions.SetCurrentOrderStatus,
    GetOrderAudit: orderCardActions.GetOrderAudit,
    SetOrderAudit: orderCardActions.SetOrderAudit,
    ShowHoverBlock,
    removeAll
}, dispatch);

const OrderCardMainComponent = (props: any) => {
    const order: Order = props.OrderCard.order;

    const OnOpenAuditRvCommand = () => {

        props.GetOrderAudit(order.NetUid);

        let rvState = new RightViewState();
        rvState.Title = 'Иcтория заказа';
        rvState.Component = OrderAuditComponent;
        rvState.ViewSize = 'audit__modal__component';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
            props.SetOrderAudit([])
        };

        props.SelectRVData(rvState);
    }

    const OnOperStatusesRvCommand = () => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0) {
            const status = new List<any>(props.OrderCard.finishStatus).FirstOrDefault(c => c.id === order.OrderStatus[0].Status);
            const index = props.OrderCard.finishStatus.indexOf(status);

            props.SetCurrentOrderStatus(index);
        }

        let rvState = new RightViewState();
        rvState.Title = 'Статусы'
        rvState.Component = OrderStatusesRv;
        rvState.ViewSize = 'rightViewGroupActions clientStatusRv';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
        };

        props.SelectRVData(rvState);
    }

    const OnOperPaymentRvCommand = () => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        let rvState = new RightViewState();
        rvState.Title = 'Оплата'
        rvState.Component = PaymentRv;
        rvState.ViewSize = 'rightViewGroupActions clientPaymRv';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
        };

        props.SelectRVData(rvState);
    }

    const OnOperDelivetryRvCommand = () => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.GetCitiesForAutocomplete('а');

        if (props.OrderCard.order.NovaPoshtaCityRef)
            props.GetWerehousesForAutocomplete(props.OrderCard.order.NovaPoshtaCityRef);

        props.SetCurrentDelivery({
            deliveryType: props.OrderCard.order.DeliveryType,
            novaPoshtaCity: props.OrderCard.order.DeliveryType === DeliveryType.Nova ? {
                value: props.OrderCard.order.NovaPoshtaCityRef,
                label: props.OrderCard.order.NovaPoshtaCityName
            } : null,
            novaPoshtaWarehouse: props.OrderCard.order.DeliveryType === DeliveryType.Nova ? {
                value: props.OrderCard.order.NovaPoshtaWarehouseRef,
                label: props.OrderCard.order.NovaPoshtaWarehouseName
            } : null,
            ukrPoshtaCityName: props.OrderCard.order.UkrPoshtaCityName,
            ukrPoshtaStreetName: props.OrderCard.order.UkrPoshtaStreetName,
            ukrPoshtaBuildingNumber: props.OrderCard.order.UkrPoshtaBuildingNumber,
            ukrPoshtaFlatNumber: props.OrderCard.order.UkrPoshtaFlatNumber,
            ukrPoshtaZipNumber: props.OrderCard.order.UkrPoshtaZipNumber
        });

        let rvState = new RightViewState();
        rvState.Title = 'Доставка'
        rvState.Component = DeliveryRv;
        rvState.ViewSize = 'rightViewGroupActions clientDeliveryRv';
        rvState.CloseFunction = () => {
            props.removeAll();
            props.RemoveRVData();
        };

        props.SelectRVData(rvState);
    }

    const OnOpenClientRvCommand = () => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.GetClients('', props.OrderCard.order.Partner.NetUid);

        if (props.OrderCard.order.Client) {
            props.SelectClient({
                value: props.OrderCard.order.Client.Id,
                label: props.OrderCard.order.Client.PhoneNumber,
                object: props.OrderCard.order.Client
            });
        }

        if (props.OrderCard.order.Client.FullName != props.OrderCard.order.RecipientName ||
            props.OrderCard.order.Client.PhoneNumber != props.OrderCard.order.RecipientPhoneNumber) {
            props.SetCurrentRecipient(props.OrderCard.order.RecipientName, props.OrderCard.order.RecipientPhoneNumber);
        }

        let rvState = new RightViewState();
        rvState.Title = 'Клиент'
        rvState.Component = ClientRv;
        rvState.ViewSize = 'rightViewGroupActions clientRvInfoConts';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
            props.ResetClientRv();
        };

        props.SelectRVData(rvState);
    }

    const OnOpenPrintInfoRvCommand = (orderProductId) => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.SelectOrderProductId(orderProductId);

        let rvState = new RightViewState();
        rvState.Title = 'Печать'
        rvState.Component = PrintInfoRv;
        rvState.ViewSize = 'rightViewGroupActions orderProductsInfo';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
            props.SelectOrderProductId(null);
        };

        props.SelectRVData(rvState);
    }

    const OnOpenProductionInfoRvCommand = (orderProductId) => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.SelectOrderProductId(orderProductId);

        let rvState = new RightViewState();
        rvState.Title = 'Произвозство'
        rvState.Component = ProductionInfoRv;
        rvState.ViewSize = 'rightViewGroupActions orderProductsInfo';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
            props.SelectOrderProductId(null);
        };

        props.SelectRVData(rvState);
    }

    const OnStartProductUpdate = () => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.Authorization.Role == IdentityRoleEnum.Partner && (props.OrderCard.order as Order).StockStatus.length > 0)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.GetCategories('');
        props.SetProductUpdateMode(true, order.OrderProducts);
    }

    const GetPrintStatusesForPartner = (status) => {
        if (status === PrintStatusEnum.Finished)
            return GetPrintStatusName(PrintStatusEnum.Finished);
        else
            // return GetPrintStatusName(PrintStatusEnum.ReadyForPrinting);
            return '';
    }

    const GetProductionStatusesForPartner = (status) => {
        if (status === ProductionStatusEnum.Producted)
            return GetProductionStatusName(ProductionStatusEnum.Producted);
        else
            // return GetProductionStatusName(ProductionStatusEnum.WaitingForProduction);
            return '';
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    const HasLaserItems = () => {
        if (order.OrderType === OrderTypeEnum.Laser)
            return true;

        if (order.OrderType === OrderTypeEnum.ProductionAndPrint) {
            if (order.OrderProducts.length > 0)
                return order.OrderProducts
                    .some(orderProduct => orderProduct.ProductType === ProductTypeEnum.Laser)
            else
                return false;
        }

        return false;
    }

    const HasPrintItems = () => {
        if (order.OrderType === OrderTypeEnum.Print)
            return true;

        if (order.OrderType === OrderTypeEnum.ProductionAndPrint) {
            if (order.OrderProducts.length > 0)
                return order.OrderProducts
                    .some(orderProduct => orderProduct.ProductType === ProductTypeEnum.PrintProduction || orderProduct.ProductType === ProductTypeEnum.Print)
            else
                return false;
        }

        return false;
    }

    const HasProductionItems = () => {
        if (order.OrderType === OrderTypeEnum.Production)
            return true;

        if (order.OrderType === OrderTypeEnum.ProductionAndPrint) {
            if (order.OrderProducts.length > 0)
                return order.OrderProducts
                    .some(orderProduct => orderProduct.ProductType === ProductTypeEnum.PrintProduction || orderProduct.ProductType === ProductTypeEnum.Production)
            else
                return false;
        }

        return false;
    }

    const OnOpenLaserInfoRvCommand = (id: number) => {
        if (props.OrderCard.order.TTN)
            return;

        if (props.OrderCard.order.OrderStatus.length > 0 && props.OrderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        props.SelectOrderProductId(id);

        let rvState = new RightViewState();
        rvState.Title = 'Лазер'
        rvState.Component = LaserInfoRv;
        rvState.ViewSize = 'rightViewGroupActions orderProductsInfo';
        rvState.CloseFunction = () => {
            props.RemoveRVData();
            props.SelectOrderProductId(null);
        };

        props.SelectRVData(rvState);
    }

    const GetLaserStatusesForPartner = (status: LaserStatusEnum) => {
        if (status === LaserStatusEnum.Producted)
            return GetLaserStatusName(LaserStatusEnum.Producted);
        else
            return '';
    }

    return (
        <div className="orderCardMainComponent___PAGE">
            <div className="content_header_component">
                <div className="page_title">
                    <span className="title_default">{order.OrderCode}</span>
                    <span className="title_description">
                        Статус:{' '}
                        {order.OrderStatus.length > 0 ? GetNameForOrderStatus(order.OrderStatus[0].Status, IsPartner(props.Authorization)) : null}
                    </span>
                </div>

                <PermissionChecker
                    Classes='header__button__controls'
                    ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>
                    <details className="dStyle" open>
                        <summary>
                            <div className="summaryFilter" />
                        </summary>
                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>
                            <div onClick={OnOpenAuditRvCommand}>Audit</div>
                        </PermissionChecker>
                        <div className="button__control" onClick={OnOperStatusesRvCommand}>Статус</div>
                    </details>

                    <details className="mStyle">
                        <summary>
                            <div className="summaryFilter" />
                        </summary>
                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>
                            <div onClick={OnOpenAuditRvCommand}>Audit</div>
                        </PermissionChecker>
                        <div className="button__control" onClick={OnOperStatusesRvCommand}>Статус</div>
                    </details>
                </PermissionChecker>
            </div>

            <div className="leftContentContainer ocmComponent">
                {/* left content */}
                <div className="ocmComponent__DIV">
                    <PermissionChecker
                        ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>
                        {
                            order.Client ?
                                <div className="orderCardClientContainer borderStxx">
                                    <div className="headerFormDefaultContainer">
                                        <div className="headerLabelWrapper">
                                            <div className="label">Клиент {' '} {order.Client.FullName}</div>
                                            <PermissionChecker
                                                ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                                <div className="editButtonControl" onClick={OnOpenClientRvCommand} />
                                            </PermissionChecker>
                                        </div>
                                    </div>

                                    <div className="wrapperFormDefaultContainer">
                                        <div className="formWrapperControl col4">
                                            <div className="formWrapperLabel">
                                                Телефон
                                            </div>
                                            <div className="formWrapperValue">
                                                {order.Client.PhoneNumber}
                                            </div>
                                        </div>

                                        <div className="formWrapperControl col4">
                                            <div className="formWrapperLabel">
                                                Имя
                                            </div>
                                            <div className="formWrapperValue">
                                                {order.Client.FullName}
                                            </div>
                                        </div>

                                        <div className="formWrapperControl col4">
                                            <div className="formWrapperLabel">
                                                Емейл
                                            </div>
                                            <div className="formWrapperValue">
                                                {order.Client.Email}
                                            </div>
                                        </div>
                                    </div>
                                </div> : null
                        }
                    </PermissionChecker>

                    <PermissionChecker
                        Classes='paymentCardClientContainer borderStxx'
                        ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>

                        <div className="headerFormDefaultContainer">
                            <div className="headerLabelWrapper">
                                <div className="label">Оплата</div>
                                <PermissionChecker
                                    ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                    <div className="editButtonControl" onClick={OnOperPaymentRvCommand} />
                                </PermissionChecker>
                            </div>
                        </div>

                        <div className="wrapperFormDefaultContainer">
                            <div className="formWrapperControl col6">
                                <div className="formWrapperLabel">Метод оплаты</div>
                                <div className="formWrapperValue">
                                    {GetPaymentMethodName(order.PaymentMethod)}
                                </div>
                            </div>
                            <div className="formWrapperControl col6">
                                <div className="formWrapperLabel">Сума</div>
                                <div className="formWrapperValue">
                                    {order.TotalAmount}
                                </div>
                            </div>
                        </div>
                    </PermissionChecker>

                    <PermissionChecker
                        Classes='deliveryCardClientContainer borderStxx'
                        ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker, IdentityRoleEnum.Sklad, IdentityRoleEnum.Maysternya]}>

                        <div className="headerFormDefaultContainer">
                            <div className="headerLabelWrapper">
                                <div className="label">Доставка - {' '}{GetDeliveryTypeName(order.DeliveryType)}</div>

                                <PermissionChecker
                                    ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                    <div className="editButtonControl" onClick={OnOperDelivetryRvCommand} />
                                </PermissionChecker>

                            </div>
                        </div>

                        {order.DeliveryType === DeliveryType.Nova ?
                            <div className="wrapperFormDefaultContainer">
                                <div className="formWrapperControl col6">
                                    <div className="formWrapperLabel">Город</div>
                                    <div className="formWrapperValue">
                                        {order.NovaPoshtaCityName}
                                    </div>
                                </div>

                                <div className="formWrapperControl col6">
                                    <div className="formWrapperLabel">Склад</div>
                                    <div className="formWrapperValue">
                                        {order.NovaPoshtaWarehouseName}
                                    </div>
                                </div>
                            </div> :
                            null
                        }

                        {order.DeliveryType === DeliveryType.Ukr ?
                            <div className="wrapperFormDefaultContainer">
                                <div className="formWrapperControl col6">
                                    <div className="formWrapperLabel">Город</div>
                                    <div className="formWrapperValue">
                                        {order.UkrPoshtaCityName}
                                    </div>
                                </div>

                                <div className="formWrapperControl col6">
                                    <div className="formWrapperLabel">Улица</div>
                                    <div className="formWrapperValue">
                                        {order.UkrPoshtaStreetName}
                                    </div>
                                </div>

                                <div className="formWrapperControl col3">
                                    <div className="formWrapperLabel">Дом</div>
                                    <div className="formWrapperValue">
                                        {order.UkrPoshtaBuildingNumber}
                                    </div>
                                </div>

                                <div className="formWrapperControl col3">
                                    <div className="formWrapperLabel">Квартира</div>
                                    <div className="formWrapperValue">
                                        {order.UkrPoshtaFlatNumber}
                                    </div>
                                </div>
                                <div className="formWrapperControl col3">
                                    <div className="formWrapperLabel">Индекс</div>
                                    <div className="formWrapperValue">
                                        {order.UkrPoshtaZipNumber}
                                    </div>
                                </div>
                            </div> :
                            null
                        }

                        <div className="wrapperFormDefaultContainer">
                            <div className="formWrapperControl col6">
                                <div className="formWrapperLabel">Получатель</div>
                                <div className="formWrapperValue">
                                    {order.RecipientName}
                                    {' '}
                                    {order.RecipientPhoneNumber}
                                </div>
                            </div>
                            {
                                order.TTN ?
                                    <div className="formWrapperControl col3">
                                        <div className="formWrapperLabel">ТТН</div>
                                        <div className="formWrapperValue">
                                            {order.TTN}
                                        </div>
                                    </div> :
                                    null
                            }
                            {order.SendedStatus ?
                                <div className="formWrapperControl col3">
                                    <div className="formWrapperLabel">Дата отправки</div>
                                    <div className="formWrapperValue">
                                        <Moment date={order.SendedStatus.Created} format='DD/MM/YYYY HH:mm' />
                                    </div>
                                </div>
                                : null}
                        </div>
                    </PermissionChecker>

                    <div className="leftSubCont borderStxx">
                        <div className="leftSubContContainer">

                            {HasProductionItems() ?
                                <div className="orderTypeContainerRow">
                                    <div className="headerFormDefaultContainer">
                                        <div className="headerLabelWrapper">
                                            <div className="label">Производство</div>
                                        </div>
                                    </div>
                                    <div className="containerOrderProducts">
                                        {
                                            order.OrderProducts.length > 0 ?
                                                order.OrderProducts
                                                    .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Production && ProductTypeEnum.PrintProduction)
                                                    .map((orderProduct, index) =>
                                                        <div className="productList__item productListQTY__item" key={index}>

                                                            <PermissionChecker
                                                                ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Manufacture, IdentityRoleEnum.Maysternya]}>
                                                                <div className="onOpenProductionInfoRvCommand" onClick={() => OnOpenProductionInfoRvCommand(orderProduct.Id)} />
                                                            </PermissionChecker>

                                                            <div className="orderProduct__modalHeader">
                                                                <div className="brandContainer">
                                                                    <div className="qtyWContainer ">
                                                                        <div className="value">{orderProduct.Quantity}</div>
                                                                        <div className="label">Кол-во</div>
                                                                    </div>
                                                                    <div className="brand">{orderProduct.Product.Category.Name}</div>
                                                                    <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                                                    <div className="lexiconContainer">
                                                                        <div className="lexiconWrapper">
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Наименование:</div>

                                                                                <div className="lexValue">
                                                                                    <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                                                </div>

                                                                                {
                                                                                    orderProduct.Product.Lexicon.HasOptions && orderProduct.Color &&
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg imgThumb__WRAPPER"
                                                                                            style={{ backgroundImage: orderProduct.Color.Thumb }}
                                                                                            onMouseEnter={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(orderProduct.Color)
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(null)
                                                                                            }}
                                                                                        />

                                                                                        <div className="colorBgLabel">({orderProduct.Color.Name})</div>
                                                                                    </div>
                                                                                }

                                                                                {
                                                                                    orderProduct.Product.Lexicon.HasColors && orderProduct.Color &&
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                                                        <div className="colorBgLabel">({orderProduct.Color.Name})</div>
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                            {orderProduct.BaseProduct &&
                                                                                <div className="lexiconItem">
                                                                                    <div className="lexLabel">Основа:</div>

                                                                                    <div className="lexValue">
                                                                                        <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                                                    </div>

                                                                                    {orderProduct.BaseColor &&
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }} />
                                                                                            <div className="colorBgLabel">({orderProduct.BaseColor.Name})</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                                                    <div className="brandContainer ops__brandContainer">
                                                                        <div className="lexiconContainer">
                                                                            <div className="lexiconWrapper">
                                                                                <div className="lexiconItem">
                                                                                    <details open className="lexiconItem__DETAILS">
                                                                                        <summary>Доп опции</summary>
                                                                                        {
                                                                                            orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                                                <div className="lexiconItem"
                                                                                                    onMouseEnter={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(null)
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                                                    <div className="lexValue">
                                                                                                        <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                    </details>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>

                                                            <div className="ocmCompProdRow">
                                                                <div className="defaultFormControl">
                                                                    <div className="defaultFormControl__label">Статус производства</div>
                                                                    <div className="defaultFormControl__value">
                                                                        <div className="imitation_input">
                                                                            {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                                <>
                                                                                    {
                                                                                        orderProduct.ProductProductionStatus.length > 0 ?
                                                                                            GetProductionStatusesForPartner(orderProduct.ProductProductionStatus[0].Status) :
                                                                                            ''
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {orderProduct.ProductProductionStatus.length > 0 ?
                                                                                        GetProductionStatusName(orderProduct.ProductProductionStatus[0].Status) :
                                                                                        ''}
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {orderProduct.FinishProductionStatus ?
                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        <div className="defaultFormControl__label">Дата пошива</div>
                                                                        <div className="defaultFormControl__value">
                                                                            <div className="imitation_input">
                                                                                {moment(orderProduct.FinishProductionStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                                            </div>
                                                                        </div>
                                                                    </PermissionChecker> : null}

                                                                {orderProduct.FinishProductionStatus ?
                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        <div className="defaultFormControl__label">Пошил</div>
                                                                        <div className="defaultFormControl__value">
                                                                            <div className="imitation_input">
                                                                                {orderProduct.FinishProductionStatus.Partner.ContactName}
                                                                            </div>
                                                                        </div>
                                                                    </PermissionChecker>
                                                                    : null}

                                                                <div className="defaultFormControl">
                                                                    <div className="defaultFormControl__label">Комментарий</div>
                                                                    <div className="defaultFormControl__value">
                                                                        <div className="imitation_input">
                                                                            {orderProduct.ProductionComment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                null
                                        }
                                        <div className="commentsProductionContainer">
                                            <div className="formWrapperControl col6">
                                                <div className="formWrapperLabel">Комментарий заказчика</div>
                                                <div className="formWrapperValue">
                                                    {order.ProductionOperatorComment ? order.ProductionOperatorComment : ''}
                                                </div>
                                            </div>

                                            <div className="formWrapperControl col6">
                                                <div className="formWrapperLabel">Комментарий швачки</div>
                                                <div className="formWrapperValue">
                                                    {order.ProductionComment ? order.ProductionComment : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {HasPrintItems() ?
                                <div className="orderTypeContainerRow">
                                    <div className="headerFormDefaultContainer">
                                        <div className="headerLabelWrapper">
                                            <div className="label">Печать</div>
                                        </div>
                                    </div>
                                    <div className="containerOrderProducts">
                                        {
                                            order.OrderProducts.length > 0 ?
                                                order.OrderProducts
                                                    .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Print && ProductTypeEnum.PrintProduction)
                                                    .map((orderProduct, index) =>
                                                        <div className="productList__item productListQTY__item" key={index}>
                                                            <PermissionChecker
                                                                ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Print, IdentityRoleEnum.Maysternya]}>
                                                                <div className="onOpenProductionInfoRvCommand" onClick={() => OnOpenPrintInfoRvCommand(orderProduct.Id)} />
                                                            </PermissionChecker>
                                                            <div className="orderProduct__modalHeader">
                                                                <div className="brandContainer">
                                                                    <div className="qtyWContainer ">
                                                                        <div className="value">{orderProduct.Quantity}</div>
                                                                        <div className="label">Кол-во</div>
                                                                    </div>
                                                                    <div className="brand">{orderProduct.Product.Category.Name}</div>
                                                                    <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                                                    <div className="lexiconContainer">
                                                                        <div className="lexiconWrapper">
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Наименование:</div>

                                                                                <div className="lexValue">
                                                                                    <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                                                </div>

                                                                                {
                                                                                    orderProduct.Product.Lexicon.HasColors && orderProduct.Color ?
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                                                            <div className="colorBgLabel">
                                                                                                ({orderProduct.Color.Name})
                                                                                            </div>
                                                                                        </div> :
                                                                                        null
                                                                                }


                                                                            </div>
                                                                            {orderProduct.BaseProduct &&
                                                                                <div className="lexiconItem">
                                                                                    <div className="lexLabel">Основа:</div>

                                                                                    <div className="lexValue">
                                                                                        <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                                                    </div>

                                                                                    {orderProduct.BaseColor &&
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }}></div>
                                                                                            <div className="colorBgLabel">({orderProduct.BaseColor.Name})</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="picRowContainer">
                                                                        <div className="pictureCol">
                                                                            <div className="previewLabelContainer">
                                                                                Превью
                                                                            </div>
                                                                            <a className="pictureContainer"
                                                                                style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserPrewiev})` }}
                                                                                href={`${API.SERVER_URL}${orderProduct.UserPrewiev}`}
                                                                                download />
                                                                        </div>

                                                                        <div className="pictureCol">
                                                                            <div className="previewLabelContainer">
                                                                                Картинка
                                                                            </div>
                                                                            <a className={`pictureContainer ${IsZipFile(orderProduct.UserImage) ? 'zipp' : ''}`}
                                                                                style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserImage})` }}
                                                                                href={`${API.SERVER_URL}${orderProduct.UserImage}`} download />
                                                                        </div>

                                                                        {
                                                                            orderProduct.ProductPrintStatus.length > 0 &&
                                                                                (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                                                                    orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter) ?
                                                                                <div className="pictureCol">
                                                                                    <div className="previewLabelContainer">
                                                                                        Брак
                                                                                    </div>
                                                                                    <a className="pictureContainer"
                                                                                        style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.FailImage})` }}
                                                                                        href={`${API.SERVER_URL}${orderProduct.FailImage}`}
                                                                                        download />
                                                                                </div> :
                                                                                null
                                                                        }
                                                                    </div>

                                                                    <div className="statRowContainer">
                                                                        <div className="defaultFormControl">
                                                                            <div className="defaultFormControl__label">Статус печати</div>
                                                                            <div className="defaultFormControl__value">
                                                                                <div className="imitation_input">
                                                                                    {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                                        <>
                                                                                            {
                                                                                                orderProduct.ProductPrintStatus.length > 0 ?
                                                                                                    GetPrintStatusesForPartner(orderProduct.ProductPrintStatus[0].Status) :
                                                                                                    ''
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {orderProduct.ProductPrintStatus.length > 0 ?
                                                                                                GetPrintStatusName(orderProduct.ProductPrintStatus[0].Status) :
                                                                                                ''}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <PermissionChecker
                                                                            Classes='defaultFormControl'
                                                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                            <>
                                                                                <div className="defaultFormControl__label">Проверка</div>
                                                                                <div className="defaultFormControl__value">
                                                                                    <div className="imitation_input">
                                                                                        {orderProduct.PrintVerificationStatus ?
                                                                                            GetPrintVerificationStatusName(orderProduct.PrintVerificationStatus) :
                                                                                            ''}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        </PermissionChecker>

                                                                        <PermissionChecker
                                                                            Classes='defaultFormControl'
                                                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                            {
                                                                                orderProduct.ProductPrintStatus.length > 0 &&
                                                                                    (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                                                                        orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter) ?
                                                                                    <>
                                                                                        <div className="defaultFormControl__label">Повторно</div>
                                                                                        <div className="defaultFormControl__value">
                                                                                            <div className="imitation_input">
                                                                                                {orderProduct.CanBeReused !== null ?
                                                                                                    (orderProduct.CanBeReused == true ? 'Использовать' : 'Не использовать')
                                                                                                    : ''
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </> : null
                                                                            }
                                                                        </PermissionChecker>
                                                                    </div>

                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        {orderProduct.FinishPrintStatus ?
                                                                            <>
                                                                                <div className="defaultFormControl__label">Дата печати</div>
                                                                                <div className="defaultFormControl__value">
                                                                                    <div className="imitation_input">
                                                                                        {moment(orderProduct.FinishPrintStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </PermissionChecker>

                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        {orderProduct.FinishPrintStatus ?
                                                                            <>
                                                                                <div className="defaultFormControl__label">Печатал</div>
                                                                                <div className="defaultFormControl__value">
                                                                                    <div className="imitation_input">
                                                                                        {orderProduct.FinishPrintStatus.Partner.ContactName}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </PermissionChecker>

                                                                    <div className="commentsProductionContainer">
                                                                        <div className="formWrapperControl col6">
                                                                            <div className="formWrapperLabel">Комментарий заказчика</div>
                                                                            <div className="formWrapperValue">
                                                                                {orderProduct.UserImageComment ? orderProduct.UserImageComment : ''}
                                                                            </div>
                                                                        </div>

                                                                        <div className="formWrapperControl col6">
                                                                            <div className="formWrapperLabel">Комментарий печатника</div>
                                                                            <div className="formWrapperValue">
                                                                                {orderProduct.PrintComment ? orderProduct.PrintComment : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                                                    <div className="brandContainer ops__brandContainer">
                                                                        <div className="lexiconContainer">
                                                                            <div className="lexiconWrapper">
                                                                                <div className="lexiconItem">
                                                                                    <details open className="lexiconItem__DETAILS">
                                                                                        <summary>Доп опции</summary>
                                                                                        {
                                                                                            orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                                                <div className="lexiconItem"
                                                                                                    onMouseEnter={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(null)
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                                                    <div className="lexValue">
                                                                                                        <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                    </details>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null
                                        }
                                    </div>
                                </div> : null
                            }

                            {HasLaserItems() ?
                                <div className="orderTypeContainerRow">
                                    <div className="headerFormDefaultContainer">
                                        <div className="headerLabelWrapper">
                                            <div className="label">Текстиль</div>
                                        </div>
                                    </div>
                                    <div className="containerOrderProducts">
                                        {
                                            order.OrderProducts.length > 0 ?
                                                order.OrderProducts
                                                    .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Laser)
                                                    .map((orderProduct, index) =>
                                                        <div className="productList__item productListQTY__item" key={index}>
                                                            <PermissionChecker
                                                                ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Lasercut, IdentityRoleEnum.Maysternya]}>
                                                                <div className="onOpenProductionInfoRvCommand" onClick={() => OnOpenLaserInfoRvCommand(orderProduct.Id)} />
                                                            </PermissionChecker>
                                                            <div className="orderProduct__modalHeader">
                                                                <div className="brandContainer">
                                                                    <div className="qtyWContainer ">
                                                                        <div className="value">{orderProduct.Quantity}</div>
                                                                        <div className="label">Кол-во</div>
                                                                    </div>
                                                                    <div className="brand">{orderProduct.Product.Category.Name}</div>
                                                                    <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                                                    <div className="lexiconContainer">
                                                                        <div className="lexiconWrapper">
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Наименование:</div>

                                                                                <div className="lexValue">
                                                                                    <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                                                </div>

                                                                                {
                                                                                    orderProduct.Product.Lexicon.HasColors && orderProduct.Color ?
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                                                            <div className="colorBgLabel">
                                                                                                ({orderProduct.Color.Name})
                                                                                            </div>
                                                                                        </div> :
                                                                                        null
                                                                                }


                                                                            </div>
                                                                            {orderProduct.BaseProduct &&
                                                                                <div className="lexiconItem">
                                                                                    <div className="lexLabel">Основа:</div>

                                                                                    <div className="lexValue">
                                                                                        <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                                                    </div>

                                                                                    {orderProduct.BaseColor &&
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }} />
                                                                                            <div className="colorBgLabel">({orderProduct.BaseColor.Name})</div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    {orderProduct.Product.Lexicon.CanHaveUserImg ?
                                                                        <div className="picRowContainer">
                                                                            <div className="pictureCol">
                                                                                <div className="previewLabelContainer">
                                                                                    Превью
                                                                                </div>
                                                                                <a className="pictureContainer"
                                                                                    style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserPrewiev})` }}
                                                                                    href={`${API.SERVER_URL}${orderProduct.UserPrewiev}`}
                                                                                    download />
                                                                            </div>

                                                                            <div className="pictureCol">
                                                                                <div className="previewLabelContainer">
                                                                                    Картинка
                                                                                </div>
                                                                                <a className={`pictureContainer ${IsZipFile(orderProduct.UserImage) ? 'zipp' : ''}`}
                                                                                    style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserImage})` }}
                                                                                    href={`${API.SERVER_URL}${orderProduct.UserImage}`} download />
                                                                            </div>
                                                                        </div> : null
                                                                    }

                                                                    <div className="statRowContainer">
                                                                        <div className="defaultFormControl">
                                                                            <div className="defaultFormControl__label">Статус печати</div>
                                                                            <div className="defaultFormControl__value">
                                                                                <div className="imitation_input">
                                                                                    {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                                        <>
                                                                                            {
                                                                                                orderProduct.ProductLaserStatus.length > 0 ?
                                                                                                    GetLaserStatusesForPartner(orderProduct.ProductLaserStatus[0].Status) :
                                                                                                    ''
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {orderProduct.ProductLaserStatus.length > 0 ?
                                                                                                GetLaserStatusName(orderProduct.ProductLaserStatus[0].Status) :
                                                                                                ''}
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        {orderProduct.FinishLaserStatus ?
                                                                            <>
                                                                                <div className="defaultFormControl__label">Дата изготовления</div>
                                                                                <div className="defaultFormControl__value">
                                                                                    <div className="imitation_input">
                                                                                        {moment(orderProduct.FinishLaserStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </PermissionChecker>

                                                                    <PermissionChecker
                                                                        Classes='defaultFormControl'
                                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                                        {orderProduct.FinishLaserStatus ?
                                                                            <>
                                                                                <div className="defaultFormControl__label">Изготовил</div>
                                                                                <div className="defaultFormControl__value">
                                                                                    <div className="imitation_input">
                                                                                        {orderProduct.FinishLaserStatus.Partner.ContactName}
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            : null}
                                                                    </PermissionChecker>

                                                                    <div className="commentsProductionContainer">
                                                                        <div className="formWrapperControl col6">
                                                                            <div className="formWrapperLabel">Комментарий заказчика</div>
                                                                            <div className="formWrapperValue">
                                                                                {orderProduct.UserImageComment ? orderProduct.UserImageComment : ''}
                                                                            </div>
                                                                        </div>

                                                                        <div className="formWrapperControl col6">
                                                                            <div className="formWrapperLabel">Комментарий изготовителя</div>
                                                                            <div className="formWrapperValue">
                                                                                {orderProduct.PrintComment ? orderProduct.PrintComment : ''}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                                {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                                                    <div className="brandContainer ops__brandContainer">
                                                                        <div className="lexiconContainer">
                                                                            <div className="lexiconWrapper">
                                                                                <div className="lexiconItem">
                                                                                    <details open className="lexiconItem__DETAILS">
                                                                                        <summary>Доп опции</summary>
                                                                                        {
                                                                                            orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                                                <div className="lexiconItem"
                                                                                                    onMouseEnter={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                                                    }}
                                                                                                    onMouseLeave={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        props.ShowHoverBlock(null)
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                                                    <div className="lexValue">
                                                                                                        <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                    </details>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ) : null
                                        }
                                    </div>
                                </div> : null
                            }

                        </div>
                    </div>
                </div>
            </div>

            <div className="rightContentContainer borderSt">
                {/* right content */}
                <div className="headerFormDefaultContainer">
                    <div className="headerLabelWrapper">
                        <div className="label">
                            Заказ
                        </div>
                    </div>
                </div>

                <div className="order_information_component">
                    <div className="oi_item create_date_order_item">
                        <div className="icon date"></div>
                        <div className="content_title">Создан</div>
                        <div className="content"><Moment date={order.Created} format={'DD/MM/YYYY HH:mm'} /></div>
                    </div>
                    <div className="oi_item date_order_item">
                        <div className="icon date"></div>
                        <div className="content_title">Оформлен</div>
                        <div className="content">{order.ConfirmStatus ? <Moment date={order.ConfirmStatus.Created} format={'DD/MM/YYYY HH:mm'} /> : ''}</div>
                    </div>

                    <div className="oi_item type_order_item">
                        <div className="icon type"></div>
                        <div className="content_title">Тип</div>
                        <div className="content">{GetTypeName(order.OrderType)}</div>
                    </div>

                    <div className="oi_item operator_order_item">
                        <div className="icon source"></div>
                        <div className="content_title">Статус</div>
                        <div className="content"> {order.OrderStatus.length > 0 ? GetNameForOrderStatus(order.OrderStatus[0].Status, IsPartner(props.Authorization)) : null}</div>
                    </div>

                    <div className="oi_item source_order_item">
                        <div className="icon source"></div>
                        <div className="content_title">Партнёр</div>
                        <div className="content">{order.Partner.ContactName}</div>
                    </div>

                    <div className="oi_item source_order_item">
                        <div className="icon source"></div>
                        <div className="content_title">Комментарий</div>
                        <div className="content over__TEXT" title={order.GlobalComment}>{order.GlobalComment}</div>
                    </div>
                    {order.Promocodes && order.Promocodes.length > 0 &&
                    <div className="oi_item operator_order_item">
                        <div className="icon source"></div>
                        <div className="content_title">Промокоды</div>
                        
                            <div className="content">{order.Promocodes.map((promocode) => promocode.Name).join(", ")}</div>
                        
                    </div>
                    }
                    {order.Promocodes && order.Promocodes.length > 0 &&
                    <div className="oi_item operator_order_item">
                        <div className="icon source"></div>
                        <div className="content_title">Типы промокодов</div>
                        <div className="content">{order.Promocodes.map((promocode) => promocode.Type.Name).join(", ")}</div>
                    </div>
                    }
                </div>

                <div className="contentFormDefaultContainer">
                    <div className="headerFormDefaultContainer">
                        <div className="headerLabelWrapper">
                            <div className="label">
                                Товары
                                <PermissionChecker
                                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                    {' '}{order.PartnerTotalAmount} | {order.TotalAmount}
                                </PermissionChecker>
                            </div>

                            <PermissionChecker
                                Classes='editButtonControl'
                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                <div className="" onClick={() => OnStartProductUpdate()} />
                            </PermissionChecker>
                        </div>
                    </div>
                    <div className="scrollContainer">
                        {
                            order.OrderProducts.length > 0 ?
                                order.OrderProducts.filter(opItem => !opItem.ParentOrderProductID).map((mainOrderProduct, index) =>
                                    <div className="productList__item productListQTY__item" key={index}>
                                        <div className="orderProduct__modalHeader">
                                            {
                                                [mainOrderProduct].concat(order.OrderProducts.filter(childProduct => childProduct.ParentOrderProductID === mainOrderProduct.Id))
                                                    .map((orderProduct, innerIndex) =>
                                                        <div key={innerIndex}>
                                                            <div className="brandContainer">
                                                                <div className="qtyWContainer ">
                                                                    <div className="value">{orderProduct.Quantity}</div>
                                                                    <div className="label">Кол-во</div>
                                                                </div>

                                                                <div className="hide">
                                                                    {orderProduct.Product.ProductCode}
                                                                </div>

                                                                <div className="brand">{orderProduct.Product.Category.Name}</div>
                                                                <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                                                <div className="lexiconContainer">
                                                                    <div className="lexiconWrapper">
                                                                        <div className="lexiconItem">
                                                                            <div className="lexLabel">Наименование:</div>

                                                                            <div className="lexValue">
                                                                                <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                                            </div>

                                                                            {
                                                                                orderProduct.Product.Lexicon.HasColors && orderProduct.Color ?
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                                                        <div className="colorBgLabel">
                                                                                            ({orderProduct.Color.Name})
                                                                                        </div>
                                                                                    </div> :
                                                                                    null
                                                                            }


                                                                            {
                                                                                orderProduct.Product.Lexicon.HasOptions && orderProduct.Color ?
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg imgThumb__WRAPPER"
                                                                                            style={{ backgroundImage: `url(${API.CRM_URL + '/' + orderProduct.Color.Thumb})` }}
                                                                                            onMouseEnter={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(orderProduct.Color)
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(null)
                                                                                            }}
                                                                                        ></div>
                                                                                        <div className="colorBgLabel">
                                                                                            ({orderProduct.Color.Name})
                                                                                        </div>
                                                                                    </div> :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        {orderProduct.BaseProduct &&
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Основа:</div>
                                                                                <div className="lexValue">
                                                                                    <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                                                </div>
                                                                                {orderProduct.BaseColor &&
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }}></div>
                                                                                        <div className="colorBgLabel">({orderProduct.BaseColor.Name})</div>
                                                                                    </div>
                                                                                }

                                                                            </div>
                                                                        }
                                                                        <br />
                                                                        <PermissionChecker
                                                                            Classes=''
                                                                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                                                            <>
                                                                                <div className="lexiconItem ">
                                                                                    <div className="lexLabel">Цена для партнера</div>
                                                                                    <div className="lexValue pTotal__value">{orderProduct.PartnerTotal}</div>
                                                                                </div>

                                                                                <div className="lexiconItem ">
                                                                                    <div className="lexLabel">Цена для клиента</div>
                                                                                    <div className="lexValue pTotal__value">{orderProduct.Total}</div>
                                                                                </div>
                                                                            </>
                                                                        </PermissionChecker>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                                                <div className="brandContainer ops__brandContainer">
                                                                    <div className="lexiconContainer">
                                                                        <div className="lexiconWrapper">
                                                                            <div className="lexiconItem">
                                                                                <details open className="lexiconItem__DETAILS">
                                                                                    <summary>Доп опции</summary>
                                                                                    {
                                                                                        orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                                            <div className="lexiconItem"
                                                                                                onMouseEnter={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                                                }}
                                                                                                onMouseLeave={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    props.ShowHoverBlock(null)
                                                                                                }}
                                                                                            >
                                                                                                <div className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                                                <div className="lexValue">
                                                                                                    <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </details>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                            {orderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Production
                                                                || orderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction ?
                                                                <div className="brandContainer ops__brandContainer">
                                                                    <div className="defaultFormControl">
                                                                        <div className="defaultFormControl__label">Комментарий</div>
                                                                        <div className="defaultFormControl__value">
                                                                            <div className="imitation_input">
                                                                                {orderProduct.ProductionComment}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                ) :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderCardMainComponent)