import { connect } from "react-redux";
import * as React from "react";
import { DatePicker } from "material-ui";
import * as moment from 'moment';
import { bindActionCreators } from "redux";
import { ChangeRVSize } from "../../../../actions/right.view.actions";
import {
    GetReesters
} from '../../../../actions/sales/order.base.actions';
import { DebounceInput } from "react-debounce-input";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication,
        RVState: state.rightView
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetReesters,
    ChangeRVSize
}, dispatch);


const RegistersInfo = (props: any) => {
    const IntlPolyfill = require('intl');
    const DateTimeFormat = IntlPolyfill.DateTimeFormat;
    require('intl/locale-data/jsonp/ru-UA');

    const format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    const OnGenerateTTNUrl = (reester) => {
        let orders = '';

        reester.TTNs.forEach(element => {
            orders = orders + `/orders[]/${element}`
        });

        return `https://my.novaposhta.ua/orders/printMarking100x100${orders}/type/pdf8/apiKey/${reester.Partner.NovaPoshtaApiKey}/position/1/printDirection/horPrint/copies/1`
    }

    return (
        <div className="orderBaseFilter__panelContainer">
            <div className={'defaultFormControl dateCol'}>
                <div className="defaultFormControl__label">Дата создания реестра</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Дата сборки"
                        value={props.OrderBase.ReestersDate ? moment(props.OrderBase.ReestersDate).toDate() : props.OrderBase.ReestersDate}
                        onChange={(event, date: any) => {
                            const filter = {
                                ReestersDate: moment(date).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
                                ReestersTtn: props.OrderBase.ReestersTtn,
                                ReestersOrderCode: props.OrderBase.ReestersOrderCode
                            }
                            return props.GetReesters(filter)
                        }}
                    />
                    
                    <div className="clControl" onClick={() => {
                        const filter = {
                            ReestersDate: undefined,
                            ReestersTtn: props.OrderBase.ReestersTtn,
                            ReestersOrderCode: props.OrderBase.ReestersOrderCode
                        }
                        return props.GetReesters(filter)
                    }} 
                        
                    />
                </div>
            </div> 
            <div className={'defaultFormControl dateCol'}>
                <div className="defaultFormControl__label">ТТН</div>
                <div className="debounceInputWrapper__container">
                    <DebounceInput
                            minLength={5}
                            debounceTimeout={1000}
                            value={props.OrderBase.ReestersTtn}
                            onChange={event => { 
                                const filter = {
                                    ReestersDate: props.OrderBase.ReestersDate,
                                    ReestersTtn: event.target.value,
                                    ReestersOrderCode: props.OrderBase.ReestersOrderCode
                                }
                                return props.GetReesters(filter)
                            }} 
                            
                        /> 
                </div>
            </div>
            <div className={'defaultFormControl dateCol'}>
                <div className="defaultFormControl__label">Код заказа</div>
                <div className="debounceInputWrapper__container">
                    <DebounceInput
                            minLength={1}
                            debounceTimeout={1000}
                            value={props.OrderBase.ReestersOrderCode}
                            onChange={event => { 
                                const filter = {
                                    ReestersDate: props.OrderBase.ReestersDate,
                                    ReestersTtn: props.OrderBase.ReestersTtn,
                                    ReestersOrderCode: event.target.value
                                }
                                return props.GetReesters(filter)
                            }} 
                            />
                </div>
            </div>

            <ul className='orderBase__ReestersWrapper'>
                {
                    props.OrderBase.Reesters.length > 0 ?
                        props.OrderBase.Reesters.map((reester, index) =>
                            <li key={index} className="orderBase__Reesters__listItem">
                                <div className="itemName__obr">
                                    <div>
                                        <small>Партнёр</small>
                                    </div>
                                    <div>{reester.Partner.ContactName}</div>
                                </div>

                                <div className="itemNumber__obr" >
                                    <div>
                                        <small>Номер</small>
                                    </div>
                                    <div>{reester.Number}</div>
                                </div>
                                <div className="clear" />
                                <div className="defaultFormControl" >
                                    <button className="defaultFormButton dateCol2" onClick={() => window.open(`https://my.novaposhta.ua/scanSheet/printScanSheet/refs[]/${reester.Number}/type/pdf/apiKey/${reester.Partner.NovaPoshtaApiKey}`, "_blank")}>
                                        Реестр
                                    </button>

                                    <button className="defaultFormButton dateCol2" onClick={() => window.open(OnGenerateTTNUrl(reester), "_blank")}>
                                        Маркировки
                                    </button>
                                </div>
                            </li>
                        )
                        : null
                }


            </ul>

        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(RegistersInfo);