export class MenuModel {
    constructor(
        public Route: string,
        public Name: string,
        public ClassName: string = null,
        public IsRoot: boolean,
        public IsSubRoute: boolean,
        public Roles?: string[],
        public ChildMenuItems?: Array<MenuModel>,
    ) { }
}