import { Reducer } from "redux";
import { PriceListState, defaultPriceListState } from "../../states/stock/price.list.state";
import { PriceListKnownActions } from "../../actions/stock/price.list.actions";
import { PriceListOptionsKnownActions } from "../../actions/stock/price.list.options.actions";
import * as priceListExportActions from "../../actions/stock/price.list.export.actions"
import * as priceListActions from "../../actions/stock/price.list.actions"
import * as priceListOptionsActions from "../../actions/stock/price.list.options.actions"
import index from "react-moment";
import { Category } from "../../business.entities/category";
import * as PlatformActions from "../../actions/stock/platform.actions";
import { PlatformKnownActions } from "../../actions/stock/platform.actions";

export const reducer: Reducer<PriceListState> = (state: PriceListState, action: PriceListKnownActions | PriceListOptionsKnownActions | PlatformKnownActions) => {
  switch (action.type) {

    case priceListActions.SELECT_CONTENT:
      return Object.assign({}, state, { 
        SelectedComponent: action.payload
       } as PriceListState);

    case priceListActions.GET_PRICELIST:
      return Object.assign({}, state, { IsFetchInProccess: true, Page: action.payload.page } as PriceListState);

    case priceListExportActions.RENEW_OPENCART_SINGLE_FILE:
      return Object.assign({}, state, { IsFetchInProccess: true } as PriceListState);

    case priceListExportActions.RENEW_OPENCART_MULTIPLE_FILE:
      return Object.assign({}, state, { IsFetchInProccess: true } as PriceListState);

    case priceListActions.SET_PRICELIST_URL:
      return Object.assign({}, state, { PriceListUrl: action.payload } as PriceListState);

    case priceListActions.SET_PRICELIST:
      return Object.assign({}, state, { PricelistItems: action.payload.priceListItem, TotalPages: action.payload.totalPages, IsFetchInProccess: false } as PriceListState);

    case priceListOptionsActions.CHANGE_AVAILABLE_ONLY:
      return Object.assign({}, state, {
        AvailableOnly: action.payload
      } as PriceListState)

    case priceListOptionsActions.SET_LEXICONS:
      return Object.assign({}, state, { Lexicons: action.payload } as PriceListState);

    case priceListOptionsActions.SET_CATEGORIES:
      return Object.assign({}, state, { Categories: action.payload } as PriceListState);

    case priceListOptionsActions.SET_SUBCATEGORIES:
      return Object.assign({}, state, {
        Categories: Object.assign([], state.Categories, {
          [action.payload.index]: Object.assign({}, state.Categories[action.payload.index], {
            SubCategories: action.payload.categories
          })
        }),
      } as PriceListState);

    case priceListOptionsActions.SELECT_ROOT_CATEGORY:
      return Object.assign({}, state, { SelectedCategory: action.payload } as PriceListState);

    case priceListOptionsActions.ADD_FILTER:
      return Object.assign({}, state, { PriceListFilter: action.payload } as PriceListState);

    case priceListOptionsActions.ADD_LEXICON_PRICES:
      return Object.assign({}, state, { LexiconPriceItems: action.payload } as PriceListState);

    case priceListOptionsActions.ADD_PRODUCT_PRICES:
      return Object.assign({}, state, { ProductPriceItems: action.payload } as PriceListState);

    case priceListOptionsActions.ADD_EXPORT_PLATFORM:
      return Object.assign({}, state, { ExportPlatformConfig: action.payload } as PriceListState);

    case priceListOptionsActions.SET_OPTIONS:
      return Object.assign({}, state, { 
        Options: action.payload
      } as PriceListState);

    case PlatformActions.SET_ALL:
      return Object.assign({}, state, { Platforms: action.payload } as PriceListState);

    case priceListExportActions.FINISH_ACTIONS:
      return Object.assign({}, state, { IsFetchInProccess: false } as PriceListState);

    case priceListActions.UNMOUNT_COMPONENT:
      return Object.assign({}, defaultPriceListState);

    default:
      const exhaustiveCheck: any = action;
      return state || defaultPriceListState;
  }
};
