import { StatisticsTypeEnum, GetStatisticsTypesForSelectForBoss, GetStatisticsTypesForSelectForCoordinator } from "../../helpers/enums/statistics.type.enum";
import { GetTimePartArray } from "../../helpers/enums/time.part.enum";
import {GetStatisticsTypesForDiagrams} from "../../helpers/enums/diagram.stats.enum";
import { PrintingStatistics } from "../../business.entities/printing.statistics";

export class PrintingStatisticsState {
    Fetching: boolean;
    PrintingStatusesByUsers: PrintingStatistics[];
    StartDate: any;
    StartTime: any;
    EndDate: any;
    EndTime: any;
}

export const DefaultPrintingStatisticsState: PrintingStatisticsState = {
    Fetching: false,
    PrintingStatusesByUsers: [],
    StartDate: null,
    StartTime: null,
    EndDate: null,
    EndTime: null
}
