import * as React from 'react';
import ProductColor from '../../../../../../business.entities/product.color';
import { bindActionCreators } from 'redux';
import {
    ChangeCurrentOrderProductQuantity,
    SaveOrderProduct,
    UpdateOrderProductServer,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    CalculateProductTotal,
    SetExpressPrintProduct,
    SetExtraPrintProduct
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { List } from 'linqts';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { WizardState } from '../../../../../../states/sales/wizard.state';
import { OrderProduct } from '../../../../../../business.entities/order.product';
import { Order } from '../../../../../../business.entities/order';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ChangeCurrentOrderProductQuantity,
    SaveOrderProduct,
    UpdateOrderProductServer,
    CalculateProductTotal,
    SetExpressPrintProduct,
    SetExtraPrintProduct
}, dispatch);

const Quantity = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    document.onkeydown = (e) => {
        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            OnGoToNextOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 13 && !orderProductUpdate.serverActionInProcess) {
            event.preventDefault();

            !orderProductUpdate.currentOrderProduct.Id ?
                props.SaveOrderProduct(orderProductUpdate.currentOrderProduct) :
                props.UpdateOrderProductServer(orderProductUpdate.currentOrderProduct);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const SaveOrderProduct = () => {

        if (orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId == 1
            || orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId == 3) {
            let similarOrderProduct = new List(orderProductUpdate.currentOrderProducts)
                .Where(c => c.ProductId === orderProductUpdate.currentOrderProduct.ProductId
                    && c.ColorId === orderProductUpdate.currentOrderProduct.ColorId
                    && c.BaseProductId === orderProductUpdate.currentOrderProduct.BaseProductId
                    && c.BaseColorId === orderProductUpdate.currentOrderProduct.BaseColorId)
                .FirstOrDefault();

            if (similarOrderProduct) {

                let updatedProduct = Object.assign({}, similarOrderProduct,
                    {
                        Quantity: similarOrderProduct.Quantity + parseInt((orderProductUpdate as any).currentOrderProduct.Quantity),
                        PartnerPrice: orderProductUpdate.currentOrderProduct.PartnerPrice
                    } as OrderProduct);
                props.UpdateOrderProductServer(updatedProduct);
            } else {
                props.SaveOrderProduct(orderProductUpdate.currentOrderProduct);
            }

        } else {
            props.SaveOrderProduct(orderProductUpdate.currentOrderProduct);
        }
    }

    const AvailableQuantity = () => {
        // if (!orderProductUpdate.currentOrderProduct.ColorId
        //     && orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId === 1)
        //     return orderProductUpdate.currentOrderProduct.Product.Quantity;

        // if (!orderProductUpdate.currentOrderProduct.BaseColorId
        //     && orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId > 1)
        //     return 0;

        if (orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId == 1)
            if (orderProductUpdate.currentOrderProduct.Product.ProductColors.length > 0)
                return (new List(orderProductUpdate.currentOrderProduct.Product.ProductColors)
                    .FirstOrDefault((c: ProductColor) => c.ColorId === orderProductUpdate.currentOrderProduct.ColorId) as ProductColor).Quantity;
            else
                return orderProductUpdate.currentOrderProduct.Product.Quantity;

        if (orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId > 1)
            if (orderProductUpdate.currentOrderProduct.BaseProduct.ProductColors.length > 0)
                return (new List(orderProductUpdate.currentOrderProduct.BaseProduct.ProductColors)
                    .FirstOrDefault((c: ProductColor) => c.ColorId === orderProductUpdate.currentOrderProduct.BaseColorId) as ProductColor).Quantity;

    }

    const OnQuantityCahngeCommand = (value: number) => {
        if (!value)
            value = 0;

        props.ChangeCurrentOrderProductQuantity(value);

        let orderProduct = Object.assign({}, orderProductUpdate.currentOrderProduct, { Quantity: value } as OrderProduct)
        props.CalculateProductTotal(orderProduct);
    }

    return (
        <div className="priceContainer__content">
            <div className="priceContainer__contentLabel">Количество</div>
            <div className="priceContainer__contentWrapper wideWrapper">
                <div className="priceFormControl">
                    <div>Дополнительные услуги (экспресс печать и доставка):</div>
                    <ul className="additionalServicesChecboxUL">
                        <li className="additionalServicesChecboxLI">
                            <label className="checkMid">
                                <input type="radio" name="expressPrint" value="" checked={!orderProductUpdate.selectedExpressPrintProduct} 
                                    onChange={() => props.SetExpressPrintProduct(null)}/>
                                <div className="checkControl"></div>
                                Нет
                            </label>
                        </li>
                        {
                            orderProductUpdate.expressPrintProducts && 
                            orderProductUpdate.expressPrintProducts.map((serviceProduct, spIndex) => 
                                <li className="additionalServicesChecboxLI" key={spIndex}>
                                    <label className="checkMid">
                                        <input type="radio" name="expressPrint" value={serviceProduct.Id}
                                            checked={orderProductUpdate.selectedExpressPrintProduct && 
                                                orderProductUpdate.selectedExpressPrintProduct.Id === serviceProduct.Id}
                                            onChange={() => props.SetExpressPrintProduct(serviceProduct)} />
                                        <div className="checkControl"></div>
                                        {serviceProduct.Lexicon.OfficialPartnerName} ({serviceProduct.PartnerPrice})
                                    </label>
                                </li>
                            ) 
                        }
                    </ul>
                </div>
                <div className="priceFormControl">
                    <div>Дополнительные услуги (дополнительная печать):</div>
                    <ul className="additionalServicesChecboxUL">
                        <li className="additionalServicesChecboxLI">
                        <label className="checkMid">
                            <input type="radio" name="extraPrint" value="" checked={!orderProductUpdate.selectedAdditionalPrintProduct}
                                onChange={() => props.SetExtraPrintProduct(null)}/>
                            <div className="checkControl"></div>
                            Нет
                        </label>
                        </li>
                        {
                            orderProductUpdate.extraPrintProducts && 
                            orderProductUpdate.extraPrintProducts.map((serviceProduct, spIndex) => 
                                <li className="additionalServicesChecboxLI" key={spIndex}>
                                    <label className="checkMid">
                                        <input type="radio" name="extraPrint" value={serviceProduct.Id}
                                            checked={orderProductUpdate.selectedAdditionalPrintProduct && 
                                                orderProductUpdate.selectedAdditionalPrintProduct.Id === serviceProduct.Id}
                                            onChange={() => props.SetExtraPrintProduct(serviceProduct)}/>
                                        <div className="checkControl"></div>
                                        {serviceProduct.Lexicon.OfficialPartnerName} ({serviceProduct.PartnerPrice})
                                    </label>
                                </li>
                            ) 
                        }
                    </ul>
                </div>
            </div>

            <div className="priceContainer__contentWrapper wideWrapper">
                <div className="priceFormControl">
                    <div className="priceFormControl__label">Доступно</div>
                    <div className="priceFormControl__value">
                        <div className="imitation_input">{AvailableQuantity()}</div>
                    </div>
                </div>

                <div className="priceFormControl">
                    <div className="priceFormControl__label">Текущие</div>
                    <div className="priceFormControl__value clientPrice">
                        <input type="number"
                            defaultValue={(orderProductUpdate as any).currentOrderProduct.Quantity}
                            min={1}
                            autoFocus={true}
                            // disabled={}
                            onChange={(event) => OnQuantityCahngeCommand((event as any).target.value)}
                        />
                    </div>
                </div>
                <div className="dopDescription__container">
                    При оформлении заказа с количеством данного товара {'>'}= {orderProductUpdate.currentProduct.Wholesale.Count} цена составит {orderProductUpdate.currentProduct.WholesalePrice}/шт.
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Quantity)