import * as actionCreators from '../../actions/category.actions';
import * as API from '../../constants/api.constants';
import * as producActionCreators from '../../actions/stock/product.actions';
import { actions } from 'react-redux-form';
import { Category } from '../../business.entities/category';
import { error } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import storeProvider from '../../helpers/store.provider';

export const GetAutocompleteList = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_SUBCATEGORIES_AUTOCOMPLETE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_CATEGORY_FOR_AUTOCOMPLETE}?id=${action.payload.parent.value}&value=${action.payload.categoryName}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    let arrayToReturn: any[] = [];
                    response.Body.forEach((element: Category) => {
                        arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                    });

                    return actionCreators.GetSubcategoriesAutocompleteSuccess(arrayToReturn);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetCategorySelectList = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PARENT_CATEGORIES)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    let arrayToReturn: any[] = [];
                    response.Body.forEach((element: Category) => {
                        arrayToReturn.push({ value: element.Id.toString(), label: element.Name });
                    });

                    return producActionCreators.SetCategorySelect(arrayToReturn);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );