import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    GetUserPersonalInfo,
    UnmountComponent,
    GetUserFunds,
    GetConstructorPrice,
    GetPriceSergmentsForPartner,
    LogoEditMode,
    ChangePosition,
    ChangeLogoImg,
    UpdateWatermark,
    ChangePreviewImg
} from "../../../../actions/cabinet/personal.info.actions";
import { default as CurrentBalanceDetails } from "./current.balance.details";
import EditPersonalClientInfo from "./edit.personal.client.info";
import { RightViewState } from "../../../../states/right.view.state";
import { actions } from 'react-redux-form'
import * as  rightViewActions from "../../../../actions/right.view.actions";
import { PriceLevel, GetPricelevelName } from "../../../../helpers/enums/price.level.enum";
import { PriceTerm } from "../../../../business.entities/price.term";
import { Loader } from "../../../shared/loader";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../helpers/identity.roles';
import { routerActions } from "react-router-redux";
import { ClipboardHelper } from "../../../../helpers/clipboardHelper";
import { default as ConstructorPriceForm } from "./constructor.price.form";
import { success, error } from "react-notification-system-redux";
import { List } from "linqts";
import { ConstructorPriceItem } from "../../../../business.entities/constructor.prices";
import { default as ConstructorWarningRv } from "./constructor.warning.rv";
import { GetPositionName } from "../../../../helpers/enums/watermark.position.enum";
import * as API from "../../../../constants/api.constants";
import Select from 'react-select';
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";

export interface IPersonalInfoProps {
    GetUserPersonalInfo(UserNetUid: string): any;
    UserNetUid: string;
    Partner: any;
    UserInfo: any;
    SelectRVData(rvState: RightViewState): any;
    RemoveRVData(): any;
    ChangeForm: any;
    UnmountComponent();
    GetUserFunds(UserNetUid: string): any;
    Push: any;
    GetConstructorPrice(netUid: string);
    GetPriceSergmentsForPartner(netUid: string);
    LogoEditMode(value: boolean);
    ChangePosition(value);
    ChangeLogoImg(value);
    error(value);
    UpdateWatermark();
    ChangePreviewImg(value);
}

export interface IPersonalInfoState {

}

class PersonalInfo extends React.Component<IPersonalInfoProps, IPersonalInfoState> {
    constructor(props) {
        super(props);
        this.props.GetUserPersonalInfo(this.props.UserNetUid);
        this.props.GetUserFunds(this.props.UserNetUid);

        //get constructor prices
        this.props.GetConstructorPrice(this.props.UserNetUid);

        this.props.GetPriceSergmentsForPartner(this.props.UserNetUid);

        this.userSaleMethod = this.userSaleMethod.bind(this);
        this.userProductType = this.userProductType.bind(this);
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    private userSaleMethod() {
        if (this.props.UserInfo.userInfo) {

            let saleMethod = [];
            const methodsArray = [
                "Интернет-магазин",
                "Локальный магазин",
                "Инстаграм",
                "Вконтакте",
                "Офлайн-магазин со стендом",
                "Ни один путь сбыта не выбран"
            ];
            
            if (this.props.UserInfo.userInfo.OnlineShop) saleMethod.push(
                methodsArray[0]
            );
            if (this.props.UserInfo.userInfo.LocalShop) saleMethod.push(
                methodsArray[1]
            );
            if (this.props.UserInfo.userInfo.Instagram) saleMethod.push(
                methodsArray[2]
            );
            if (this.props.UserInfo.userInfo.Vkontakte) saleMethod.push(
                methodsArray[3]
            );
            if (this.props.UserInfo.userInfo.OfflineShop) saleMethod.push(
                methodsArray[4]
            );
            if (this.props.UserInfo.userInfo.Other) saleMethod.push(
                this.props.UserInfo.userInfo.OtherDescription
            );
            
            return !saleMethod.length ? methodsArray[5] : saleMethod.join(", ");
        }
    }

    private userProductType() {
        if (this.props.UserInfo.userInfo) {

            let productTypes = [];
            const productsArray = [
                "Силиконовые чехлы с печатью",
                "Кожанные чехлы",
                "Силиконовые чехлы",
                "Стекло и пленки",
                "Ни один продукт не выбран"
            ];

            this.props.UserInfo.userInfo.PrintSiliconeCases && productTypes.push(
                !productTypes.length ? productsArray[0] : " " + productsArray[0]
            );
            this.props.UserInfo.userInfo.LeatherCase && productTypes.push(
                !productTypes.length ? productsArray[1] : " " + productsArray[1]
            );
            this.props.UserInfo.userInfo.SiliconeCases && productTypes.push(
                !productTypes.length ? productsArray[2] : " " + productsArray[2]
            );
            this.props.UserInfo.userInfo.GlassAndFilm && productTypes.push(
                !productTypes.length ? productsArray[3] : " " + productsArray[3]
            );

            return productTypes.length === 0 ? productsArray[4] : productTypes.toString();
        }
    }

    private onOpenBalanceDetailsWindowCommand() {
        let rvState = new RightViewState();
        rvState.Title = "Детальнее (скидка на товар по ценовым уровням)";
        rvState.ViewSize = "rightViewGroupActions balanceDetailsWindow ";
        rvState.Component = CurrentBalanceDetails;
        rvState.CloseFunction = () => { this.onCloseBalanceDetailsWindowCommand(); };
        this.props.SelectRVData(rvState);
    }

    private OnOpenConstructorApproveRV() {
        let rvState = new RightViewState();
        rvState.Title = "Конструктор с персональным логотипом";
        rvState.ViewSize = "rightViewGroupActions constructorWarningRv__WW";
        rvState.Component = ConstructorWarningRv;
        rvState.CloseFunction = () => { this.props.RemoveRVData() };
        this.props.SelectRVData(rvState);
    }

    private onCloseBalanceDetailsWindowCommand() {
        this.props.RemoveRVData();
    }

    private onOpenEditPersonalClientInfoWindowCommand() {
        let rvState = new RightViewState();
        rvState.Title = "Редактировать";
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.Component = EditPersonalClientInfo;

        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
        };

        this.props.SelectRVData(rvState);

        //$('details#mStylePersFilters').removeAttr('open');

        var detail = document.getElementById("mStylePersFilters");
        detail.removeAttribute("open");

        //var len = details.length;
        //for (var i = 0; i < len; i++) {
        //    if (details[i] != openDetails) {
        //        details[i].removeAttribute("open");
        //    }
        //}
    }

    private OnOpenConstructorPricesCommand() {
        let rvState = new RightViewState();
        rvState.Title = "Цены конструктор";
        rvState.Component = ConstructorPriceForm;

        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
        };

        this.props.SelectRVData(rvState);
    }

    private GetNextLevelTerm = (priceLevel: PriceLevel) => {
        switch (priceLevel) {
            case PriceLevel.S0:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D1OrderPriceSum : 0;
            case PriceLevel.S1:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D2OrderPriceSum : 0;
            case PriceLevel.S2:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D3OrderPriceSum : 0;
            case PriceLevel.S3:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D4OrderPriceSum : 0;
            case PriceLevel.S4:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D5OrderPriceSum : 0;
            case PriceLevel.S5:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D6OrderPriceSum : 0;
            default:
                return 0;
        }
    }

    private GetLevelTerm = (priceLevel: PriceLevel) => {
        switch (priceLevel) {
            case PriceLevel.S1:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D1MinOrderSum : 0;
            case PriceLevel.S2:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D2MinOrderSum : 0;
            case PriceLevel.S3:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D3MinOrderSum : 0;
            case PriceLevel.S4:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D4MinOrderSum : 0;
            case PriceLevel.S5:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D5MinOrderSum : 0;
            case PriceLevel.S6:
                return (this.props.UserInfo.PriceTerm as PriceTerm) ? (this.props.UserInfo.PriceTerm as PriceTerm).D6MinOrderSum : 0;
            default:
                return 0;
        }
    }

    private OnOpenPartnersUrl = (url) => {
        if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1)
            url = 'http://' + url;

        window.open(url);
    }


    public OnFileUpload(event) {
        let files;
        let formData = new FormData();
        if (event.target.files) {
            files = Array.from(event.target.files)
            formData.append('files', files[0] as any, (files[0] as any).name);

            let reader = new FileReader();

            reader.onloadend = () => {
                this.props.ChangePreviewImg(reader.result);
            };

            reader.readAsDataURL(files[0]);
        }

        this.props.ChangeLogoImg(formData);
    }

    private OnValidateAndCopy() {
        if (this.props.UserInfo.ConstructorPrices.PriceItems.length > 0) {
            let result = new List<ConstructorPriceItem>(this.props.UserInfo.ConstructorPrices.PriceItems).Any(c => c.Price === 0)
            if (result) {
                this.props.error({
                    title: "Проверте правильность заполнения цен",
                    position: 'br',
                    autoDismiss: 3,
                })
            } else {
                ClipboardHelper.CopyTextWithMessage(
                    `https://print.caseshop.com.ua/${this.props.UserNetUid}`,
                    "Ссылка на конструктор добавлена в буфер обмена");
            }
        } else {
            this.props.error({
                title: "Проверте правильность заполнения цен",
                position: 'br',
                autoDismiss: 3,
            })
        }
    }

    public CancelEditLogoCommand() {
        const selectedPosition = this.props.UserInfo.Positions.find(c => c.value === this.props.UserInfo.userInfo.WatermarkPosition);
        this.props.ChangePosition(selectedPosition);

        this.props.LogoEditMode(false);
    }

    render() {
        const {
            OrganizationName,
            OrganizationCity,
            PageUrl,
            ContactName,
            ContactPhone,
            ContactEmail,
            DirectorsName,
            DirectorsPhone,
            DirectorsEmail
        } = this.props.UserInfo.userInfo;

        return (
            <div>
                <LeftMenuMobileControl />
                <div className="left_menu_component personalInfoContainer dWrapper">
                    <div className="left_menu_SCROLL">
                        {this.props.UserInfo.userInfo.Id ?
                            <div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Ценовой уровень</div>
                                    <div className="defaultFormControl__value">
                                        <div className="imitation_input">
                                            {GetPricelevelName(this.props.UserInfo.userInfo.PriceLevel)}
                                        </div>

                                        <div className="priceLevel__infoControl" onClick={() => this.onOpenBalanceDetailsWindowCommand()}></div>
                                    </div>
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">СУММА ОФОРМЛЕННЫХ ЗАКАЗОВ ЗА РАСЧЕТНЫЙ ПЕРИОД (ТЕКУЩИЙ МЕСЯЦ)</div>
                                    <div className="defaultFormControl__value">
                                        <div className="imitation_input">{this.props.UserInfo.LastTotal}</div>
                                    </div>
                                </div>
                                {this.props.UserInfo.userInfo.PriceLevel !== PriceLevel.S6 ?
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label"> СУММА ЗАКАЗОВ ЗА РАСЧЕТНЫЙ ПЕРИОД, НЕОБХОДИМАЯ ДЛЯ ПЕРЕХОДА НА СЛЕДУЮЩИЙ ЦЕНОВОЙ УРОВЕНЬ</div>
                                        <div className="defaultFormControl__value">
                                            <div className="imitation_input">{this.GetNextLevelTerm(this.props.UserInfo.userInfo.PriceLevel)}</div>
                                        </div>
                                    </div> : null
                                }
                                {this.props.UserInfo.userInfo.PriceLevel !== PriceLevel.S0 ?
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">СУММА ЗАКАЗОВ ЗА РАСЧЕТНЫЙ ПЕРИОД, НЕОБХОДИМАЯ ДЛЯ УДЕРЖАНИЯ ТЕКУЩЕГО ЦЕНОВОГО УРОВНЯ</div>
                                        <div className="defaultFormControl__value">
                                            <div className="imitation_input">>={this.GetLevelTerm(this.props.UserInfo.userInfo.PriceLevel)}</div>
                                        </div>
                                    </div> : null}
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Текущий баланс</div>
                                    <div className="defaultFormControl__value">
                                        <div className={`imitation_input ${this.props.UserInfo.Balance > 0 ? 'positive' : 'negative'}`}
                                            onClick={() => this.props.Push(`/cabinet/mutual-settlements`)}>
                                            {this.props.UserInfo.Balance}
                                        </div>
                                    </div>
                                </div>
                                {/* 
                        <div className="hr fl" />

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner]} simple>
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label occ">Онлайн конструктор</div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormButton" onClick={this.OnValidateAndCopy.bind(this)}>
                                    Копировать ссылку
                                </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormButton" onClick={() =>
                                    this.OnOpenConstructorPricesCommand()
                                }>
                                    Изменить цены конструктора
                                </div>
                            </div>
                        </PermissionChecker> */}

                                {/* { 
                            (this.props.Login === 'Boss' || this.props.Login === 'Test') &&
                            this.props.UserInfo.userInfo.EnabledConstructorWatermark ?
                                <>

                                    {

                                        this.props.UserInfo.LogoEditMode ?
                                            <div>
                                                <div className="defaultFormControl">
                                                    <div className="defaultFormControl__label">Логотип</div>
                                                </div>

                                                {this.props.UserInfo.Preview &&
                                                    <div className={`defaultFormControl iImg`}>
                                                        <img src={`${this.props.UserInfo.Preview}`} alt="" />
                                                    </div>
                                                }

                                                <div className="defaultFormControl">
                                                    <input type="file" onChange={(event) => { this.OnFileUpload(event) }} />
                                                </div>

                                                <div className="defaultFormControl">
                                                    <div className="defaultFormControl__label">Позиция</div>
                                                </div>

                                                <div className="defaultFormControl">
                                                    <Select
                                                        name="position"
                                                        options={this.props.UserInfo.Positions}
                                                        onChange={(value) => this.props.ChangePosition(value)}
                                                        value={this.props.UserInfo.SelectedPosition}
                                                        placeholder="Позиция" />
                                                </div>

                                                <div className="defaultFormControl">
                                                    <div className="defaultFormButton" onClick={() => this.props.UpdateWatermark()}>
                                                        Сохранить
                                                        </div>
                                                </div>

                                                <div className="defaultFormControl">
                                                    <div className="defaultFormButton" onClick={() => this.CancelEditLogoCommand()}>
                                                        Отмена
                                                        </div>
                                                </div>
                                            </div> :
                                            <>
                                                <div className="defaultFormControl">
                                                    <div className="defaultFormControl__label">Логотип</div>
                                                </div>

                                                {
                                                    this.props.UserInfo.userInfo.WatermarkUrl ?
                                                        <div className="defaultFormControl iImg">
                                                            <img src={`${API.SERVER_URL}${this.props.UserInfo.userInfo.WatermarkUrl}`} alt="" />
                                                        </div>
                                                        :
                                                        <div>
                                                            Загрузите логотип
                                                            </div>
                                                }

                                                <div className="defaultFormControl">
                                                    <div className="defaultFormControl__label">Позиция</div>
                                                </div>

                                                <div>
                                                    {GetPositionName(this.props.UserInfo.userInfo.WatermarkPosition)}
                                                </div>

                                                <div className="defaultFormControl">
                                                    <div className="defaultFormButton" onClick={() => this.props.LogoEditMode(true)}>
                                                        Изменить
                                                        </div>
                                                </div>
                                            </>
                                    }
                                </> :
                                (this.props.Login === 'Boss' || this.props.Login === 'Test') ? 
                                <>
                                    <div className="defaultFormControl">
                                        <div className="defaultFormButton" onClick={() => this.OnOpenConstructorApproveRV()}>
                                            Конструктор с персональным логотипом
                                            </div>
                                    </div>
                                </>:
                                null
                        } */}

                            </div> :
                            <Loader />
                        }
                    </div>
                </div>

                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">{OrganizationName}</span>
                        <span className="title_description">Организация</span>
                    </div>

                    <PermissionChecker
                        Classes='header__button__controls'
                        ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner]}>
                        <details className="dStyle" open>
                            <summary>
                                <div className="summaryFilter" />
                            </summary>
                            <div className="button__control" onClick={() => this.onOpenEditPersonalClientInfoWindowCommand()}>Редактировать персональную информацию</div>
                        </details>

                        <details className="mStyle" id="mStylePersFilters">
                            <summary>
                                <div className="summaryFilter" />
                            </summary>
                            <div className="button__control" onClick={() => this.onOpenEditPersonalClientInfoWindowCommand()}>Редактировать персональную информацию</div>
                        </details>
                    </PermissionChecker>
                </div>
                <div className="content_component personalFFContainer">
                    <div className="personal_information_component">
                        <div className="personalWrapperContent">
                            <div className="personalContactColContainer">
                                <div className="defaultFormControl__label">Контакты руководителя</div>
                                <ul className="defaultFormULControl__value">
                                    <li className="na">{DirectorsName}</li>
                                    <li className="ph">{DirectorsPhone}</li>
                                    <li className="em">{DirectorsEmail}</li>
                                </ul>
                            </div>
                            <div className="personalContactColContainer conLast">
                                <div className="defaultFormControl__label">Контактное лицо</div>
                                <ul className="defaultFormULControl__value">
                                    <li className="na">{ContactName}</li>
                                    <li className="ph">{ContactPhone}</li>
                                    <li className="em">{ContactEmail}</li>
                                </ul>
                            </div>

                            <div className="personalContactColLeftContainer">
                                <div className="defaultFormControl__label">Место расположения</div>
                                <div className="defaultFormIconControl__value mapPoint">{OrganizationCity}</div>
                            </div>
                            <div className="personalContactColRightContainer">
                                <div className="defaultFormControl__label">Ссылка на сайт или соц-сеть</div>
                                <a className="defaultFormIconControl__value urlPoint" onClick={() => this.OnOpenPartnersUrl(PageUrl)}>{PageUrl}</a>
                            </div>
                            <div className="personalContactColLeftContainer">
                                <div className="defaultFormControl__label">Пути сбыта</div>
                                <div className="defaultFormIconControl__value mapPoint">{this.userSaleMethod()}</div>
                            </div>
                            <div className="personalContactColRightContainer">
                                <div className="defaultFormControl__label">Вид продукции, который интересует</div>
                                <div className="defaultFormIconControl__value" >{this.userProductType()}</div>
                            </div>


                            <div className="contactUsContentContainer">
                                <div className="defaultFormControl__label">По вопросам пожеланий и предложений по работе портала пишите</div>
                                <div className="defaultFormIconControl__value">b2b.caseshope@gmail.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};
export default connect(state => ({
    Login: (state as any).authentication.UserName,
    UserNetUid: (state as any).authentication.UserNetUid,
    UserInfo: (state as any).cabinet.userInfo,
    Partner: (state as any).authentication.userInfo
}),
    dispatch => bindActionCreators({
        GetUserPersonalInfo,
        UnmountComponent,
        GetUserFunds,
        GetConstructorPrice,
        GetPriceSergmentsForPartner,
        ChangeForm: actions.change,
        SelectRVData: rightViewActions.SelectRVData,
        RemoveRVData: rightViewActions.RemoveRVData,
        Push: routerActions.push,
        success,
        error,
        LogoEditMode,
        ChangePosition,
        ChangeLogoImg,
        UpdateWatermark,
        ChangePreviewImg
    }, dispatch))(PersonalInfo);