import * as API from '../../constants/api.constants';
import * as rightViewActions from '../../actions/right.view.actions';
import * as cabinetActionsTypes from '../../actions/cabinet/personal.info.actions';
import * as personalInfoActionsCreators from '../../actions/cabinet/personal.info.actions';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import * as moment from 'moment';
import storeProvider from '../../helpers/store.provider';
import { PartnerPrice } from '../../business.entities/partner.price';
import { ApplicationState } from '../../Reducers';
import Product from '../../business.entities/product';
import { List } from 'linqts';

export const getUserPersonalInfo = (action$, state$) =>
  action$.ofType(cabinetActionsTypes.GET_USER_PERSONAL_INFO)
    .switchMap(action => {
      let getUrl = API.GET_USER_PERSONAL_INFO + "?netId=";
      return Observable.ajax.getJSON(getUrl + action.payload,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          // storeProvider.getStore().dispatch(success({
          //   title: `Персональная информация ${action.payload} успешно получена`,
          //   message: '',
          //   position: 'br',
          //   autoDismiss: 3,
          // }));

          storeProvider.getStore().dispatch(actions.change("partnerForm", response.Body.Partner));

          storeProvider.getStore().dispatch(personalInfoActionsCreators.ChangePosition(response.Body.Partner.WatermarkPosition));

          return personalInfoActionsCreators.GetUserPersonalInfoSuccess(response.Body.Partner);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));

        })
    });

export const UpdateWatermark = (action$, state$) =>
  action$.ofType(cabinetActionsTypes.UPDATE_WATERMARK)
    .switchMap(action => {
      return Observable.ajax.post(`${API.CHANGE_LOGO}?position=${state$.value.cabinet.userInfo.SelectedPosition}`,
        state$.value.cabinet.userInfo.NewImage,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Лого и позиция успешно изменена`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          storeProvider.getStore().dispatch(actions.change("partnerForm", response.response.Body));
          storeProvider.getStore().dispatch(personalInfoActionsCreators.LogoEditMode(false));
          storeProvider.getStore().dispatch(personalInfoActionsCreators.ChangePosition(response.response.Body.WatermarkPosition));
          
          return personalInfoActionsCreators.GetUserPersonalInfoSuccess(response.response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    });

  export const RemoveWatermark = (action$, state$) =>
    action$.ofType(cabinetActionsTypes.REMOVE_WATERMARK)
      .switchMap(action => {
        return Observable.ajax.post(`${API.REMOVE_LOGO}`,
        null,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
          .map((response: any) => {
            storeProvider.getStore().dispatch(success({
              title: `Лого успешно удален`,
              message: '',
              position: 'br',
              autoDismiss: 3,
            }));

            storeProvider.getStore().dispatch(actions.change("partnerForm", response.response.Body));
            storeProvider.getStore().dispatch(personalInfoActionsCreators.LogoEditMode(false));
            storeProvider.getStore().dispatch(personalInfoActionsCreators.SetRemovingLogo(false));

            return personalInfoActionsCreators.GetUserPersonalInfoSuccess(response.response.Body);
          }).catch((errorData: any) => {
            return Observable.of(ErrorHandler(errorData));
          })
      });

export const ActivateConstructor = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.ACTIVATE_CONSTRUCTOR)
    .switchMap(action => {
      return Observable.ajax.getJSON(`${API.ACTIVATE_CONSTRUCTOR}?partnerUid=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Персональная информация ${action.payload} успешно получена`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          storeProvider.getStore().dispatch(personalInfoActionsCreators.OpenActivateConstructor(false));

          return personalInfoActionsCreators.GetUserPersonalInfoSuccess(response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));

        })
    });

export const GetPriceSegmentsForPartner = (action$, store) =>
  action$.ofType(cabinetActionsTypes.GET_PRICE_SEGMENTS_FOR_PARTNER)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_PARTNER_PRICE_SEGMENT}?netId=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          // storeProvider.getStore().dispatch(success({
          // 	title: 'Цены и ставки успешно получены',
          // 	message: '',
          // 	position: 'br',
          // 	autoDismiss: 3,
          // }));
          let pricesSegments: PartnerPrice[] = response.Body;
          if ((storeProvider.getStore().getState() as ApplicationState).stock.price.products.length > 0) {
            let products: Product[] = (storeProvider.getStore().getState() as ApplicationState).stock.price.products;

            products.forEach((element: Product) => {
              let priceSegment: PartnerPrice = new List(pricesSegments)
                .Where(c => c.LexiconId === element.LexiconId)
                .FirstOrDefault();

              let priceSegmentIndex: number = pricesSegments.indexOf(priceSegment);
              if (priceSegmentIndex !== -1)
                pricesSegments[priceSegmentIndex].Price = element.PartnerPrice;
            });
          }

          return cabinetActionsTypes.SetPriceSergmentsForPartner(pricesSegments);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetFundsInfo = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.GET_FUNDS_INFO)
    .switchMap(action => {
      return Observable.ajax.getJSON(`${API.GET_FUNDS_INFO}?netId=${action.payload} `,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          return personalInfoActionsCreators.SetUserFunds(response.Body.Item1, response.Body.Item2, response.Body.Item3);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));

        })
    });

export const changeClientInfo = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.CHANGE_CLIENT_INFO)
    .switchMap(action =>
      Observable.ajax.post(
        API.UPDATE_PARTNER,
        JSON.stringify(action.payload),
        {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
        }
      ).map((response: any) => {
        storeProvider.getStore().dispatch(success({
          title: 'Информация обновлена успешно',
          message: '',
          position: 'br',
          autoDismiss: 4,
        }));
        actions.reset("partnerForm");

        storeProvider.getStore().dispatch(personalInfoActionsCreators.ChangeClientInfoSuccess(response.response.Body));
        return rightViewActions.RemoveRVData();
      }).catch((errorData: any) => {

        return Observable.of(ErrorHandler(errorData));
      })
    );

export const GetConstructorPrices = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.GET_CONSTRUCTOR_PRICE)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_PARTNER_CONSTRUCTOR_PRICE}?netUid=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          return personalInfoActionsCreators.SetConstructorPrice(response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const UpdateConstructorPrices = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.UPDATE_CONSTRUCTOR_PRICE)
    .switchMap(action =>
      Observable.ajax.post(`${API.UPDATE_PARTNER_CONSTRUCTOR_PRICE}`,
        action.payload,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Информация обновлена успешно',
            message: '',
            position: 'br',
            autoDismiss: 4,
          }));

          return personalInfoActionsCreators.SetConstructorPrice(response.response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetAllConstructorTutorials = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.GET_CONSTRUCTOR_TUTORIALS)
    .switchMap(action => {
      return Observable.ajax.getJSON(`${API.GET_TUTORIALS_FOR_CONSTRUCTOR}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Инструкции получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));
          
          return cabinetActionsTypes.SetConstructorTutorials(response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    });

       
export const UpdateTutorial = (action$, state$) =>
action$.ofType(cabinetActionsTypes.UPDATE_CONSTRUCTOR_TUTORIAL)
  .switchMap(action => {
    let formData = state$.value.cabinet.userInfo.UploadedVideo ? state$.value.cabinet.userInfo.UploadedVideo : new FormData();
    formData.append('data', JSON.stringify(state$.value.cabinet.userInfo.SelectedTutorial));

    return Observable.ajax.post(`${API.UPDATE_TUTORIAL}`,
      formData,
      { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
      .map((response: any) => {
        storeProvider.getStore().dispatch(success({
          title: `Инструкция изменена`,
          message: '',
          position: 'br',
          autoDismiss: 3,
        }));

        storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());
        storeProvider.getStore().dispatch(cabinetActionsTypes.ChangeVideo(null));
        storeProvider.getStore().dispatch(cabinetActionsTypes.SelectTutorial(undefined, false));

        return cabinetActionsTypes.GetConstructorTutorials();
      }).catch((errorData: any) => {
        return Observable.of(ErrorHandler(errorData));
      })
  });

export const GetConstructorUsers = (action$, store: Store<any>) =>
  action$.ofType(cabinetActionsTypes.GET_CONSTRUCTOR_USERS)
    .switchMap(action => {
      return Observable.ajax.getJSON(`${API.GET_CONSTRUCTOR_USERS}?active=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Пользователи получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));
          //debugger
          return cabinetActionsTypes.SetConstructorUsers(response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    });