import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../reducers/index";
import { Route } from "react-router";

interface IProps {
    Title: string;
    CloseFunction: any;
    Component: any;
    Props?: any;
    ViewSize?: string;
    CustomFunctions?: any;
}

export const RightView = (props: any) => {
    document.onkeydown = (e) => {
        if (e.keyCode == 27) {
            props.CloseFunction()
        }
    }

    document.onmousedown = (e) => {
        if ((e.target as HTMLElement).id == 'global_right_view') {
            props.CloseFunction()
        }
    }

    return (
        <div id='global_right_view' className="panel_component is_open">
            <div className={`panel_content_component default_component right_panel ${props.ViewSize}`}>
                <div className="header_panel">
                    <div className="item_title">{props.Title}</div>
                    <div className="close_panel_control" onClick={(event) => props.CloseFunction()}></div>
                </div>
                <div className="content_panel">
                    <Route path={props.history.location.pathname} component={props.Component} />
                </div>

                {
                    props.CustomFunctions &&
                    (props.CustomFunctions.UpFunction && props.CustomFunctions.DownFunction) &&
                    <div className="controlsArrowContainer">
                        <div className="arrowControl up" onClick={() => props.CustomFunctions.UpFunction()} />
                        <div className="arrowControl down" onClick={() => props.CustomFunctions.DownFunction()} />
                    </div>
                }
            </div>
        </div>
    );
}
export default connect((state: ApplicationState) => state.rightView)(RightView)