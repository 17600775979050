export const GET_PRINT_STATISTICS: string = '[API] GET_PRINT_STATISTICS';
export interface IGetPrintStatistics { type: '[API] GET_PRINT_STATISTICS'; payload: any; }
export const GetPrintStatistics = (startDate, endDate) =>
    <IGetPrintStatistics>{ type: GET_PRINT_STATISTICS, payload: {startDate: startDate, endDate: endDate} };

export const GET_PRINT_STATISTICS_SUCCESS: string = 'GET_PRINT_STATISTICS_SUCCESS';
export interface IGetPrintStatisticsSuccess { type: 'GET_PRINT_STATISTICS_SUCCESS'; payload: any; }
export const GetPrintStatisticsSuccess = (printStatisticsByUsers: string) =>
    <IGetPrintStatisticsSuccess>{ type: GET_PRINT_STATISTICS_SUCCESS, payload: printStatisticsByUsers };

export const SET_START_DATE: string = 'SET_START_DATE';
export interface ISetStartDate { type: 'SET_START_DATE'; payload: any; }
export const SetStartDate = (startDate: any) =>
    <ISetStartDate> { type: SET_START_DATE, payload: startDate }

export const SET_START_TIME: string = 'SET_START_TIME';
export interface ISetStartTime { type: 'SET_START_TIME'; payload: any; }
export const SetStartTime = (startTime: any) =>
    <ISetStartTime> { type: SET_START_TIME, payload: startTime }

export const SET_END_DATE: string = 'SET_END_DATE';
export interface ISetEndDate { type: 'SET_END_DATE'; payload: any; }
export const SetEndDate = (endDate: any) =>
    <ISetEndDate> { type: SET_END_DATE, payload: endDate }

export const SET_END_TIME: string = 'SET_END_TIME';
export interface ISetEndTime { type: 'SET_END_TIME'; payload: any; }
export const SetEndTime = (endTime: any) =>
    <ISetEndTime> { type: SET_END_TIME, payload: endTime }

export const APPLY_FILTERS: string = 'APPLY_FILTERS';
export interface IApplyFilters { type: 'APPLY_FILTERS'; payload: any; }
export const ApplyFilters = (filters: any) =>
    <IApplyFilters> { type: APPLY_FILTERS, payload: filters }

export const SET_FETCHING: string = 'SET_FETCHING';
export interface ISetFetching { type: 'SET_FETCHING'; payload: any; }
export const SetFetching = (isFetching: boolean) =>
    <ISetFetching> { type: SET_FETCHING, payload: isFetching }

export type PrintStatisticsKnownActions = IGetPrintStatistics | IGetPrintStatisticsSuccess | ISetStartDate | 
ISetStartTime | ISetEndDate | ISetEndTime | IApplyFilters | ISetFetching;