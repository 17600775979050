export class PartnerFilter {
    public Value: string;
    public OnlineShop: boolean;
    public LocalShop: boolean;
    public Instagram: boolean;
    public Vkontakte: boolean;
    public Other: boolean;
    public OfflineShop: boolean;
    public SiliconeCases: boolean;
    public PrintSiliconeCases: boolean;
    public LeatherCase: boolean;
    public PrintLeatherCase: boolean;
    public GlassAndFilm: boolean;
    public IsRegistered: boolean;
    public PriceLevel: any;
    public Canceled?: boolean;
    public EarlyPayment: boolean;
    public AfterPayment: boolean;
}