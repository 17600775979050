import { Partner } from "../../business.entities/partner";
import { RegistrationUser } from "../../business.entities/registration.user";
import { User } from "../../business.entities/user";
import { IdentityRoleEnum, GetIdentityRoleName } from "../../helpers/identity.roles";
import { PriceLevel, GetPricelevelName } from "../../helpers/enums/price.level.enum";
import { PartnerPaymentMethodEnum } from "../../helpers/enums/partner.payment.method.enum";

export class PartnerInfoState {
    partner: Partner;
    userName: string;
    user: User;
    selectedRole: any;
    pricelevels: any[];
    selectedLevel: any;
    partnerPaymentMethods: any[];
    selectedPartnerPaymentMethod: any;
    selectedPostOffice: any;
    selectedCity: any;
}

export const dafaultPartnerInfoState = {
    partner: new Partner(),
    userName: "",
    user: null,
    selectedRole: null,
    pricelevels: [
        { label: GetPricelevelName(PriceLevel.S0), value: PriceLevel.S0 },
        { label: GetPricelevelName(PriceLevel.S1), value: PriceLevel.S1 },
        { label: GetPricelevelName(PriceLevel.S2), value: PriceLevel.S2 },
        { label: GetPricelevelName(PriceLevel.S3), value: PriceLevel.S3 },
        { label: GetPricelevelName(PriceLevel.S4), value: PriceLevel.S4 },
        { label: GetPricelevelName(PriceLevel.S5), value: PriceLevel.S5 },
        { label: GetPricelevelName(PriceLevel.S6), value: PriceLevel.S6 },
        { label: GetPricelevelName(PriceLevel.P1), value: PriceLevel.P1 }
    ],
    selectedLevel: null,
    partnerPaymentMethods: [
        { label: "Предоплата", value: PartnerPaymentMethodEnum.EarlyPayment },
        { label: "Послеоплата", value: PartnerPaymentMethodEnum.PostPayment }
    ],
    selectedPartnerPaymentMethod: PartnerPaymentMethodEnum.EarlyPayment,
    selectedPostOffice: null,
    selectedCity: null,
}