export const FILTER_CHANGE: string = 'FILTER_CHANGE';
export interface IFilterChange { type: 'FILTER_CHANGE'; payload: any; }
export const FilterChange = (filter: any) =>
    <IFilterChange>{ type: FILTER_CHANGE, payload: filter };

export const GET_PARTNERS_SUCCESS: string = 'GET_PARTNERS_SUCCESS';
export interface IGetPartnersSuccess { type: 'GET_PARTNERS_SUCCESS'; payload: any; }
export const GetPartnersSuccess = (response: any) =>
    <IGetPartnersSuccess>{ type: GET_PARTNERS_SUCCESS, payload: response };

export const GET_PARTNERS_FAILED: string = 'GET_PARTNERS_FAILED';
export interface IGetPartnersFailed { type: 'GET_PARTNERS_FAILED'; payload: any; }
export const GetPartnersFailed = (response: any) =>
    <IGetPartnersFailed>{ type: GET_PARTNERS_FAILED, payload: response };

export const GET_PARTNERS_FILTERED_PAGINATED: string = '[API] GET_PARTNERS_FILTERED_PAGINATED';
export interface IGetPartnersFilteredPaginated { type: '[API] GET_PARTNERS_FILTERED_PAGINATED'; payload: any; }
export const GetPartnersFilteredPaginated = (count: number, page: number, filter: any) =>
    <IGetPartnersFilteredPaginated>{ type: GET_PARTNERS_FILTERED_PAGINATED, payload: { count: count, page: page, filter: filter } };

export const GET_WORKERS_PAGINATED: string = '[API] GET_WORKERS_PAGINATED';
export interface IGetWorkersPaginated { type: '[API] GET_WORKERS_PAGINATED'; payload: any; }
export const GetWorkersPaginated = (count: number, page: number) =>
    <IGetWorkersPaginated>{ type: GET_WORKERS_PAGINATED, payload: { count: count, page: page } };

export const CANCEL_PARTNER_MODE: string = 'PARTNER_GRID.CANCEL_PARTNER_MODE';
export interface ICancelPartnerMode { type: 'PARTNER_GRID.CANCEL_PARTNER_MODE'; payload: any; }
export const CancelPartnerMode = (enable) =>
    <ICancelPartnerMode>{ type: CANCEL_PARTNER_MODE, payload: enable };

export const CANCEL_PARTNER: string = '[API] PARTNER_GRID.CANCEL_PARTNER';
export interface ICancelPartner { type: '[API] PARTNER_GRID.CANCEL_PARTNER'; payload: any; }
export const CancelPartner = (partner) =>
    <ICancelPartner>{ type: CANCEL_PARTNER, payload: partner };

export const CANCEL_PARTNER_SUCCESS: string = 'PARTNER_GRID.CANCEL_PARTNER_SUCCESS';
export interface ICancelPartnerSuccess { type: 'PARTNER_GRID.CANCEL_PARTNER_SUCCESS'; payload: any; }
export const CancelPartnerSuccess = (partners) =>
    <ICancelPartnerSuccess>{ type: CANCEL_PARTNER_SUCCESS, payload: partners };

export const UNMOUNT_PARTNER_GRID: string = 'UNMOUNT_PARTNER_GRID';
export interface IUnmountPartnerGrid { type: 'UNMOUNT_PARTNER_GRID'; payload: any; }
export const UnmountPartnerGrid = () =>
    <IUnmountPartnerGrid>{ type: UNMOUNT_PARTNER_GRID };

export type PartnerGridKnownActions =
    IFilterChange |
    IGetPartnersSuccess |
    IGetPartnersFailed |
    IGetPartnersFilteredPaginated |
    IGetWorkersPaginated |
    ICancelPartner |
    ICancelPartnerMode |
    ICancelPartnerSuccess;