import { Partner } from "../business.entities/partner";

export class RegistrationState {
    partner: Partner;
    isRegisrationInProgress: boolean;
    isRegisteredCompleted: boolean;
}

export const defaultRegistrationState: RegistrationState = {
    partner: null,
    isRegisrationInProgress: false,
    isRegisteredCompleted: false
}