import { EntityBase } from "./entity.base";
import { Partner } from "./partner";
import { OrderProduct } from "./order.product";
import { PrintStatusEnum } from "../helpers/enums/print.status.enum";

export class ProductPrintStatus extends EntityBase {
    public Status: PrintStatusEnum;
    public OrderProductId: number;
    public UserId: number;
    public OrderProduct: OrderProduct;
    public Partner: Partner;
}