import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    ConfirmOrderProductSelection
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { OnGoToPrevStepCommand, OnGoToNextStepCommand, OnGoToNextOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { List } from 'linqts';
import ProductColor from '../../../../../../business.entities/product.color';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';
import { Order } from '../../../../../../business.entities/order';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    ConfirmOrderProductSelection
}, dispatch);

const BaseColor = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    document.onkeydown = (e) => {
        if ((event as any).keyCode == 38) {
            event.preventDefault();

            OnSelectTopColorCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();

            OnSelectBottomColorCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();

            OnConfirmColorSelection();
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            orderProductUpdate.currentOrderProduct.BaseColor &&
                OnGoToNextStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopColorCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentBaseColor) {
            const productColor = new List<ProductColor>(orderProductUpdate.currentBaseProduct.ProductColors)
                .FirstOrDefault(c => c.ColorId === (orderProductUpdate as any).currentBaseColor.Id);

            startIndex = orderProductUpdate.currentBaseProduct.ProductColors.indexOf(productColor);
            startIndex != 0 ? startIndex-- : startIndex = orderProductUpdate.currentBaseProduct.ProductColors.length - 1;
        }

        props.SelectCurrentBaseColor(orderProductUpdate.currentBaseProduct.ProductColors[startIndex].Color);
    }

    const OnSelectBottomColorCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentBaseColor) {
            const productColor = new List(orderProductUpdate.currentBaseProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === (orderProductUpdate as any).currentBaseColor.Id)

            startIndex = orderProductUpdate.currentBaseProduct.ProductColors.indexOf(productColor);
            startIndex != orderProductUpdate.currentBaseProduct.ProductColors.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentBaseColor(orderProductUpdate.currentBaseProduct.ProductColors[startIndex].Color);
    }

    const OnConfirmColorSelection = () => {
        if (orderProductUpdate.currentBaseColor) {
            props.ConfirmBaseColorSelect();
            //add copy for order card
            OnGoToNextOrderCardStepCommand(props);
        }
    }

    const OnSelectProductColorByMouse = (index) => {
        props.SelectCurrentBaseColor(orderProductUpdate.currentBaseProduct.ProductColors[index].Color);

        props.ConfirmBaseColorSelect();
            //add copy for order card

        OnGoToNextOrderCardStepCommand(props);
    }

    return (
        <div className="colorContainer__content">
            <div className="colorContainer__contentLabel">Цвет основы</div>
            <div className="colorContainer__contentWrapper content_scroll">
                {orderProductUpdate.currentBaseProduct.ProductColors.length > 0 && orderProductUpdate.currentBaseProduct.ProductColors.map((productColor, index) => {
                    return <div key={index}
                        className={`productColor__item  ${(productColor as ProductColor).Color.Id === (orderProductUpdate as any).currentBaseColor.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectProductColorByMouse(index)}>
                        <div className={`bgItem_container ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                            style={{ backgroundColor: productColor.Color.Code }} >
                            <div className="qty">
                                {(productColor as ProductColor).Quantity}
                            </div>
                        </div>
                        <div className="name">
                            {(productColor as ProductColor).Color.Name}
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseColor)