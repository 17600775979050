import * as actionCreators from "../../../../actions/stock/price.actions";
import { ApplicationState } from "../../../../Reducers/index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { PartnerPrice } from "../../../../business.entities/partner.price";
import * as rightViewActions from '../../../../actions/right.view.actions'
import { RightViewState } from "../../../../states/right.view.state";
import { PartnerPriceEdit } from "./partner.price.edit";
import { default as PartnerPriceLevelForm } from "./partner.price.level.form";
import { actions } from "react-redux-form";
import { Loader } from "../../../shared/loader";

namespace PartnerPriceGridComponent {
    export interface IProps {
        pricesSegments?: PartnerPrice[];

        OpenRV?: (rvData: RightViewState) => any;
        CloseRv?: () => any;
        ChangeForm?: (formName: string, model: any) => any;
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        pricesSegments: state.stock.price.partnerPriceSegments
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        OpenRV: rightViewActions.SelectRVData,
        CloseRv: rightViewActions.RemoveRVData,
        ChangeForm: actions.change
    }, dispatch);
};

const PartnerPriceGridComponent = (props: PartnerPriceGridComponent.IProps) => {

    const selectProductPrice = (price: PartnerPrice) => {
        props.ChangeForm("partnerPriceForm", price);

        let rvState = new RightViewState();
        rvState.Title = "Редактирование";
        rvState.Component = PartnerPriceLevelForm;
        rvState.CloseFunction = props.CloseRv;

        props.OpenRV(rvState);
    }

    return (
        <div className="grid_component">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell">
                                <span className="cell_content">Лексикон</span>
                            </div>
                            <div className="grid_cell partner_cell">
                                <span className="cell_content ">Цена партнёра</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S1, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д1, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S2, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д2, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S3, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д3, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S4, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д4, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S5, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д5, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S6, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Д6, грн.</span>
                            </div>
                            <div className="grid_cell s_cell">
                                <span className="cell_content">S7, %</span>
                            </div>
                            <div className="grid_cell d_cell">
                                <span className="cell_content">Опт, грн</span>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    props.pricesSegments.length > 0 ?
                        <div className="grid_scroll_content_component">
                            <div className="grid_wrapper_component">
                                {
                                    props.pricesSegments.map((price, index) => {
                                        return (
                                            <div key={index} className="grid_row mMEDIA___partnerPrice_ROW" onClick={() => { selectProductPrice(price) }}>
                                                <div className="grid_cell parthner__CasualName__CELL" data-tooltip="Лексикон">
                                                    <span className="cell_content">{price.Lexicon.CasualName}</span>
                                                </div>
                                                <div className="grid_cell partner_cell parthner__Price__CELL" data-tooltip="Цена партнёра">
                                                    <span className="cell_content">{price.Price}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S1, %">
                                                    <span className="cell_content">{price.S1Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д1, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S1Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S2, %">
                                                    <span className="cell_content">{price.S2Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д2, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S2Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S3, %">
                                                    <span className="cell_content">{price.S3Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д3, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S3Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S4, %">
                                                    <span className="cell_content">{price.S4Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д4, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S4Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S5, %">
                                                    <span className="cell_content">{price.S5Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д5, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S5Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S6, %">
                                                    <span className="cell_content">{price.S6Percents}</span>
                                                </div>
                                                <div className="grid_cell d_cell mD__CELL" data-tooltip="Д6, грн.">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S6Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                                <div className="grid_cell s_cell mS__CELL" data-tooltip="S7, %">
                                                    <span className="cell_content">{price.S7Percents}</span>
                                                </div>
                                                <div className="grid_cell parthner__OPTPrice__CELL d_cell " data-tooltip="Опт, грн">
                                                    <span className="cell_content">{(price.Price - (price.Price * (price.S7Percents * 0.01))).toFixed(2)}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        <Loader />
                }
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerPriceGridComponent)