export enum PrintStatusEnum {
    WaitingForAssembling = 1,
    ReadyForPrinting,
    PictureIssue,
    FailedByPrinter,
    FailedNotByPrinter,
    Finished
}

export const GetPrintStatusName = (status) => {
    switch (status) {
        case PrintStatusEnum.WaitingForAssembling:
            return 'Ожидает сборки';
        case PrintStatusEnum.ReadyForPrinting:
            return 'Готов к печати';
        case PrintStatusEnum.PictureIssue:
            return 'Вопрос по картинке';
        case PrintStatusEnum.FailedByPrinter:
            return 'Брак по вине печатника';
        case PrintStatusEnum.FailedNotByPrinter:
            return 'Брак не по вине печатника';
        case PrintStatusEnum.Finished:
            return 'Напечатан';
        default:
            return '';
    }
}

export const FilterStatusList = [
    {
        value: PrintStatusEnum.ReadyForPrinting,
        label: GetPrintStatusName(PrintStatusEnum.ReadyForPrinting)
    },
    {
        value: PrintStatusEnum.PictureIssue,
        label: GetPrintStatusName(PrintStatusEnum.PictureIssue)
    },
    {
        value: PrintStatusEnum.FailedByPrinter,
        label: GetPrintStatusName(PrintStatusEnum.FailedByPrinter)
    },
    {
        value: PrintStatusEnum.FailedNotByPrinter,
        label: GetPrintStatusName(PrintStatusEnum.FailedNotByPrinter)
    },
    {
        value: PrintStatusEnum.Finished,
        label: GetPrintStatusName(PrintStatusEnum.Finished)
    }
];

export const FilterStatusPartnerList = [
    {
        value: PrintStatusEnum.Finished,
        label: GetPrintStatusName(PrintStatusEnum.Finished)
    }
];