export enum ProductTypeEnum {
    Sale = 1,
    Print,
    Production,
    PrintProduction,
    Laser
}

export const GetOrderType = (type) => {
    switch (type) {
        case ProductTypeEnum.Sale:
            return 'sales_icon';

        case ProductTypeEnum.Print:
            return 'print_icon';

        case ProductTypeEnum.Production:
            return 'production_icon';

        case ProductTypeEnum.PrintProduction:
            return 'print_production_icon';

        case ProductTypeEnum.Laser:
            return 'laser_icon';

        default:
            return '';

    }
}