import { Product } from "../../business.entities/product";
import { ProductColor } from "../../business.entities/product.color";
import { Lexicon } from "../../business.entities/lexicon";
import { Category } from "../../business.entities/category";
import { SelectModel } from "../../helpers/select.model";


export const GET_PRODUCTS_SUCCESS: string = 'GET_PRODUCTS_SUCCESS';
export interface IGetProductsSuccess { type: 'GET_PRODUCTS_SUCCESS'; payload: any; }
export const GetProductsSuccess = (products: Product[]) =>
    <IGetProductsSuccess>{ type: GET_PRODUCTS_SUCCESS, payload: products };

export const SELECT_PRODUCT: string = 'SELECT_PRODUCT';
export interface ISelectProduct { type: 'SELECT_PRODUCT'; payload: any; }
export const SelectProduct = (product: Product) =>
    <ISelectProduct>{ type: SELECT_PRODUCT, payload: product };

export const DESELECT_PRODUCT: string = 'DESELECT_PRODUCT';
export interface IDeselectProduct { type: 'DESELECT_PRODUCT'; payload: any; }
export const DeselectProduct = () =>
    <IDeselectProduct>{ type: DESELECT_PRODUCT };

export const UPDATE_PRODUCT: string = '[API] UPDATE_PRODUCT';
export interface IUpdateProduct { type: '[API] UPDATE_PRODUCT'; payload: any; }
export const UpdateProduct = (product: Product) =>
    <IUpdateProduct>{ type: UPDATE_PRODUCT, payload: product };

export const SELECT_PRODUCT_COLOR: string = 'SELECT_PRODUCT_COLOR';
export interface ISelectProductColor { type: 'SELECT_PRODUCT_COLOR'; payload: any; }
export const SelectProductColor = (productColor: ProductColor) =>
    <ISelectProductColor>{ type: SELECT_PRODUCT_COLOR, payload: productColor };

export const UPDATE_PRODUCT_COLOR: string = '[API] UPDATE_PRODUCT_COLOR';
export interface IUpdateProductColor { type: '[API] UPDATE_PRODUCT_COLOR'; payload: any; }
export const UpdateProductColor = (productColor: ProductColor) =>
    <IUpdateProductColor>{ type: UPDATE_PRODUCT_COLOR, payload: productColor };

export const GET_PRODUCT_FILTERED_PAGINATED: string = '[API] GET_PRODUCT_FILTERED_PAGINATED';
export interface IGetProductFilteredPaginated { type: '[API] GET_PRODUCT_FILTERED_PAGINATED'; payload: any; }
export const GetProductFilteredPaginated = (count?: number, page?: number, lexiconId?: number, categoryId?: number, subCategoryId?: number) =>
    <IGetProductFilteredPaginated>{ type: GET_PRODUCT_FILTERED_PAGINATED, payload: { count: count, page: page, lexiconId: lexiconId, categoryId: categoryId, subCategoryId: subCategoryId } };

export const SET_LEXICON_SELECT: string = 'SET_LEXICON_SELECT';
export interface ISetLexiconSelect { type: 'SET_LEXICON_SELECT'; payload: any; }
export const SetLexiconSelect = (lexicons: Lexicon[]) =>
    <ISetLexiconSelect>{ type: SET_LEXICON_SELECT, payload: lexicons };

export const SET_CATEGORY_SELECT: string = 'SET_CATEGORY_SELECT';
export interface ISetCategorySelect { type: 'SET_CATEGORY_SELECT'; payload: any; }
export const SetCategorySelect = (categories: Category[]) =>
    <ISetCategorySelect>{ type: SET_CATEGORY_SELECT, payload: categories };

export const CHANGE_CATEGORY_SELECT: string = 'CHANGE_CATEGORY_SELECT';
export interface IChangeCategorySelect { type: 'CHANGE_CATEGORY_SELECT'; payload: any; }
export const ChangeCategorySelect = (value: SelectModel) =>
    <IChangeCategorySelect>{ type: CHANGE_CATEGORY_SELECT, payload: value };

export const CHANGE_LEXICON_SELECT: string = 'CHANGE_LEXICON_SELECT';
export interface IChangeLexiconSelect { type: 'CHANGE_LEXICON_SELECT'; payload: any; }
export const ChangeLexiconSelect = (value: SelectModel) =>
    <IChangeLexiconSelect>{ type: CHANGE_LEXICON_SELECT, payload: value };

export const CHANGE_SUBCATEGORY_SELECT: string = 'CHANGE_SUBCATEGORY_SELECT';
export interface IChangeSubcategorySelect { type: 'CHANGE_SUBCATEGORY_SELECT'; payload: any; }
export const ChangeSubcategorySelect = (value: SelectModel) =>
    <IChangeSubcategorySelect>{ type: CHANGE_SUBCATEGORY_SELECT, payload: value };

export const RESET_FILTER: string = 'RESET_FILTER';
export interface IResetFilter { type: 'RESET_FILTER'; payload: any; }
export const ResetFilter = () =>
    <IResetFilter>{ type: RESET_FILTER }

export const PRODUCT_UNMOUNT: string = 'PRODUCT_UNMOUNT';
export interface IProductUnmount { type: 'PRODUCT_UNMOUNT'; payload: any; }
export const ProductUnmount = () =>
    <IProductUnmount>{ type: PRODUCT_UNMOUNT };

export const SET_PRODUCTS: string = 'SET_PRODUCTS';
export interface ISetProducts { type: 'SET_PRODUCTS'; payload: any; }
export const SetProducts = (products: Product[]) =>
    <ISetProducts>{ type: SET_PRODUCTS, payload: products };

export const CHANGE_PRICE_CATEGORY_SELECT: string = 'PRODUCT.CHANGE_PRICE_CATEGORY_SELECT';
export interface IChangePriceCategorySelect { type: 'PRODUCT.CHANGE_PRICE_CATEGORY_SELECT'; payload: any; }
export const ChangePriceCategorySelect = (value: SelectModel) =>
    <IChangePriceCategorySelect>{ type: CHANGE_PRICE_CATEGORY_SELECT, payload: value };

export const CHANGE_PRICE_LEXICON_SELECT: string = 'PRODUCT.CHANGE_PRICE_LEXICON_SELECT';
export interface IChangePriceLexiconSelect { type: 'PRODUCT.CHANGE_PRICE_LEXICON_SELECT'; payload: any; }
export const ChangePriceLexiconSelect = (value: SelectModel) =>
    <IChangePriceLexiconSelect>{ type: CHANGE_PRICE_LEXICON_SELECT, payload: value };


export const BULK_PRICE_CHANGE: string = '[API] PRODUCT.BULK_PRICE_CHANGE';
export interface IBulkChangePrice { type: '[API] PRODUCT.BULK_PRICE_CHANGE'; payload: any; }
export const BulkChangePrice = (categoryId: number, lexiconId: number, price: number) =>
    <IBulkChangePrice>{ type: BULK_PRICE_CHANGE, payload: { categoryId: categoryId, lexiconId: lexiconId, price: price } };

export const UPDATE_RECOMENDED_PRICE: string = '[API] PRODUCT.UPDATE_RECOMENDED_PRICE';
export interface IUpdateRecomendedPrices { type: '[API] PRODUCT.UPDATE_RECOMENDED_PRICE'; payload: any; }
export const UpdateRecomendedPrices = (categoryId: number, lexiconId: number, price: number) =>
    <IUpdateRecomendedPrices>{ type: UPDATE_RECOMENDED_PRICE, payload: { categoryId: categoryId, lexiconId: lexiconId, price: price } };

export const UPDATE_PRODUCT_IMAGE: string = '[API] PRODUCT.UPDATE_PRODUCT_IMAGE';
export interface IUpdateProductImage { type: '[API] PRODUCT.UPDATE_PRODUCT_IMAGE'; payload: any; }
export const UpdateProductImage = (productId: number, formData: any) =>
    <IUpdateProductImage>{ type: UPDATE_PRODUCT_IMAGE, payload: { productId, formData } };

export const UPDATE_PRODUCT_COLOR_IMAGE: string = '[API] PRODUCT.UPDATE_PRODUCT_COLOR_IMAGE';
export interface IUpdateProductColorImage { type: '[API] PRODUCT.UPDATE_PRODUCT_COLOR_IMAGE'; payload: any; }
export const UpdateProductColorImage = (productColorId: number, number: number, formData: any) =>
    <IUpdateProductColorImage>{ type: UPDATE_PRODUCT_COLOR_IMAGE, payload: { productColorId, number, formData } };

export type ProductKnownActions =
    ISelectProduct |
    IDeselectProduct |
    IGetProductsSuccess |
    IUpdateProduct |
    ISelectProductColor |
    IUpdateProductColor |
    IGetProductFilteredPaginated |
    ISetLexiconSelect |
    ISetCategorySelect |
    IChangeCategorySelect |
    IChangeLexiconSelect |
    IChangeSubcategorySelect |
    IResetFilter |
    IProductUnmount |
    IChangePriceCategorySelect |
    IChangePriceLexiconSelect |
    IBulkChangePrice |
    IUpdateRecomendedPrices |
    IUpdateProductImage |
    IUpdateProductColorImage; 