import { Reducer } from "redux";
import {
    REMOVE_PARTNER_RV_DATA,
    SELECT_PARNTER_RV_DATA,
    PartnerKnownActions
} from "../../actions/partner/partner.actions";
import { PartnerState, defaultPartnerState } from "../../states/partner/partner.state";

export const reducer: Reducer<any> = (state: PartnerState, action: PartnerKnownActions) => {
    switch (action.type) {

        case SELECT_PARNTER_RV_DATA:
            return { ...state, PartnerRightView: action.payload };

        case REMOVE_PARTNER_RV_DATA:
            return { ...state, PartnerRightView: defaultPartnerState.PartnerRightView };

        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultPartnerState;
}