import { Category } from "../business.entities/category";
import { SelectModel } from "../helpers/select.model";

export const GET_PARENT_CATEGORIES = "[API] GET_PARENT_CATEGORIES";
export interface IGetParentCategories { type: "[API] GET_PARENT_CATEGORIES"; payload: any; }
export const GetParentCategories = () =>
    <IGetParentCategories>{ type: GET_PARENT_CATEGORIES };

export const GET_SUBCATEGORIES_AUTOCOMPLETE = "[API] GET_SUBCATEGORIES_AUTOCOMPLETE";
export interface IGetSubcategoriesAutocomplete { type: "[API] GET_SUBCATEGORIES_AUTOCOMPLETE"; payload: any; }
export const GetSubcategoriesAutocomplete = (categoryName: string, parent: SelectModel) =>
    <IGetSubcategoriesAutocomplete>{ type: GET_SUBCATEGORIES_AUTOCOMPLETE, payload: { categoryName, parent } };


export const GET_SUBCATEGORIES_AUTOCOMPLETE_SUCCESS = "[API] GET_SUBCATEGORIES_AUTOCOMPLETE_SUCCESS";
export interface IGetSubcategoriesAutocompleteSuccess { type: "[API] GET_SUBCATEGORIES_AUTOCOMPLETE_SUCCESS"; payload: any; }
export const GetSubcategoriesAutocompleteSuccess = (array: Category[]) =>
    <IGetSubcategoriesAutocompleteSuccess>{ type: GET_SUBCATEGORIES_AUTOCOMPLETE_SUCCESS, payload: array };

export type CategoryKnownActions =
    IGetParentCategories |
    IGetSubcategoriesAutocomplete |
    IGetSubcategoriesAutocompleteSuccess;