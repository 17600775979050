import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import { success } from 'react-notification-system-redux';

import * as mutualSettlementsActions from '../../actions/cabinet/mutual.settlements.actions';
import * as API from '../../constants/api.constants';
import { ErrorHandler } from '../../helpers/error.manager';
import Partner from '../../business.entities/partner';
import { List } from 'linqts';
import { MutualSettlement } from '../../business.entities/mutual.settlement';
import { actions } from 'react-redux-form';
import storeProvider from '../../helpers/store.provider';
import { Order } from '../../business.entities/order';
import { ofType } from 'redux-observable';
import { mergeMap, debounceTime } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

export const getAllPartners = (action$, store: Store<any>) =>
  action$.ofType(mutualSettlementsActions.GET_ALL_PARTNERS)
    .switchMap(action => {
      let getUrl = API.GET_ALL_PARTNERS;
      return Observable.ajax.getJSON(getUrl,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Партнеры успешно получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));
          let arrayToReturn: any[] = response.Body.map((element: Partner) => {
            return { value: element.NetUid.toString(), label: element.ContactName, partnerId: element.Id };
          });
          return mutualSettlementsActions.SetAllPartners(arrayToReturn);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    });

export const GetPartnerAutocomplete = (action$, store) =>
  action$.ofType(mutualSettlementsActions.GET_PARTNERS_AUTOCOMPLETE)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_PARTNER_AUTOCOMPLETE}?value=${action.payload ? action.payload : ''}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {

          let arrayToReturn: any[] = response.Body.map((element: Partner) => {
            return { value: element.NetUid.toString(), label: element.ContactName, partnerId: element.Id };
          });
          return mutualSettlementsActions.SetAllPartners(arrayToReturn);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const AddMutualSettlement = (action$, store) =>
  action$.ofType(mutualSettlementsActions.ADD_MUTUALSETTLEMENTS)
    .switchMap(action =>
      Observable.ajax.post(`${API.ADD_MUTALSETTLEMENT}`,
        JSON.stringify(action.payload),
        {
          "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`,
          "Content-Type": "application/json"
        })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Баланс успешно изменён`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          // let mutualSettlementToAdd = action.payload;
          // mutualSettlementToAdd.Id = response.response.Body;
          let mutualSettlements = storeProvider.getStore().getState().cabinet.mutualSettlements.MutualSettlements;
          mutualSettlements.push(response.response.Body);

          storeProvider.getStore().dispatch(mutualSettlementsActions.GetBalance(storeProvider.getStore().getState().cabinet.mutualSettlements.SelectedPartner.value))

          storeProvider.getStore().dispatch(actions.reset("mutualSettlementsReplenishmentOfBalanceForm"));
          storeProvider.getStore().dispatch(actions.reset("mutualSettlementsRefundsForm"));

          storeProvider.getStore().dispatch(mutualSettlementsActions.ChangeOrderSelect(null));

          return mutualSettlementsActions.SetAllMutualSettlements(new List(mutualSettlements as MutualSettlement[]).OrderByDescending(c => c.Created).ToArray(), storeProvider.getStore().getState().cabinet.mutualSettlements.TotalPages + 1);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetMutualSettlements = (action$, store) =>
  action$.ofType(mutualSettlementsActions.GET_MUTUALSETTLEMENTS)
    .switchMap(action =>
      Observable.ajax.post(`${API.GET_ALL_MUTALSETTLEMENT}?netId=${action.payload.netId}&page=${action.payload.page}&count=${action.payload.count}`,
        action.payload.filter,
        {
          "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`,
        })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Взаиморасчёты успешно получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          return mutualSettlementsActions.SetAllMutualSettlements(response.response.Body.Item1, response.response.Body.Item2);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );

export const GetBalance = (action$, store) =>
  action$.ofType(mutualSettlementsActions.GET_BALANCE)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_BALANCE}?netId=${action.payload}`,
        {
          "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`,
        })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: `Баланс успешно получены`,
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));

          return mutualSettlementsActions.SetBalance(response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );


export const GetOrderAutocomplete = (action$, store) =>
  action$.pipe(
    ofType(mutualSettlementsActions.GET_ORDERS_AUTOCOMPLETE),
    debounceTime(2000),
    mergeMap((action: any) =>
      ajax.getJSON(`${API.GET_ORDER_AUTOCOMPLETE}?partnerId=${action.payload.partnerId}&code=${action.payload.value ? action.payload.value : ''}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          let arrayToReturn: any[] = response.Body.map((element: Order) => {
            return { value: element.Id.toString(), label: element.OrderCode };
          });
          return mutualSettlementsActions.SetOrdersAutocomplete(arrayToReturn);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    )
  );

export const GetOrderFilterAutocomplete = (action$, store) =>
  action$.pipe(
    ofType(mutualSettlementsActions.GET_ORDERS_AUTOCOMPLETE_FILTER),
    debounceTime(2000),
    mergeMap((action: any) =>
      ajax.getJSON(`${API.GET_ALL_ORDER_AUTOCOMPLETE}?partnerId=${action.payload.partnerId}&code=${action.payload.value ? action.payload.value : ''}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          let arrayToReturn: any[] = response.Body.map((element: Order) => {
            return { value: element.Id.toString(), label: element.OrderCode };
          });
          return mutualSettlementsActions.SetOrdersAutocompleteFilter(arrayToReturn);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    )
  );

export const ExportMutualSettlements = (action$, store) => 
  action$.pipe(
    ofType(mutualSettlementsActions.EXPORT_MUTUAL_SETTLEMENTS),
    debounceTime(2000),
    mergeMap((action: any) =>
      Observable.ajax.post(`${API.EXPORT_MUTUAL_SETTLEMENTS}?netId=${action.payload.partnerId}`, action.payload.filter, 
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          return mutualSettlementsActions.ExportMutualSettlementsSuccess(response.response.Body);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    )
  );

export const LoadAfterpaymentsFile = (action$, store) =>
  action$.pipe(
    ofType(mutualSettlementsActions.LOAD_AFTERPAYMENS_FILE),
    debounceTime(2000),
    mergeMap((action: any) =>
      Observable.ajax.post(`${API.IMPORT_AFTERPAYMENT_FILE}`, 
      action.payload,
      { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`})
      .map((response: any) => {
        storeProvider.getStore().dispatch(success({
          title: `Оплаты успешно загружены`,
          message: '',
          position: 'br',
          autoDismiss: 3
        }));

        return mutualSettlementsActions.LoadAfterpaymentsFileSuccess()
      }).catch((errorData: any) => {
        return Observable.of(ErrorHandler(errorData));
      })
    )
  )