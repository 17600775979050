import { OrderBaseFilterEntity } from "../../helpers/order.base.fiter.entity";

export const GET_ALL_ORDERS = '[API] ORDER_BASE.GET_ALL_ORDERS';
export interface IGetAllOrders { type: '[API] ORDER_BASE.GET_ALL_ORDERS', payload: any }
export const GetAllOrders = (filter: OrderBaseFilterEntity, page: number, count: number) =>
    <IGetAllOrders>{ type: GET_ALL_ORDERS, payload: { filter, page, count } };

export const SET_ALL_ORDERS = 'ORDER_BASE.SET_ALL_ORDERS';
export interface ISetAllOrders { type: 'ORDER_BASE.SET_ALL_ORDERS', payload: any }
export const SetAllOrders = (orders) =>
    <ISetAllOrders>{ type: SET_ALL_ORDERS, payload: orders };

export const SELECT_ORDER_TYPE_FILTER = 'ORDER_BASE.SELECT_ORDER_TYPE_FILTER';
export interface ISelectOrderTypeFilter { type: 'ORDER_BASE.SELECT_ORDER_TYPE_FILTER', payload: any }
export const SelectOrderTypeFilter = (value) =>
    <ISelectOrderTypeFilter>{ type: SELECT_ORDER_TYPE_FILTER, payload: value };

export const SET_ORDER_STATUS_LIST_FILTER = 'ORDER_BASE.SET_ORDER_STATUS_LIST_FILTER';
export interface ISetOrderStatusListFilter { type: 'ORDER_BASE.SET_ORDER_STATUS_LIST_FILTER', payload: any }
export const SetOrderStatusListFilter = (value) =>
    <ISetOrderStatusListFilter>{ type: SET_ORDER_STATUS_LIST_FILTER, payload: value };

export const SET_PRINT_STATUS_LIST_FILTER = 'ORDER_BASE.SET_PRINT_STATUS_LIST_FILTER';
export interface ISetPrintStatusListFilter { type: 'ORDER_BASE.SET_PRINT_STATUS_LIST_FILTER', payload: any }
export const SetPrintStatusListFilter = (value) =>
    <ISetPrintStatusListFilter>{ type: SET_PRINT_STATUS_LIST_FILTER, payload: value };

export const SET_PROD_STATUS_LIST_FILTER = 'ORDER_BASE.SET_PROD_STATUS_LIST_FILTER';
export interface ISetProdStatusListFilter { type: 'ORDER_BASE.SET_PROD_STATUS_LIST_FILTER', payload: any }
export const SetProdStatusListFilter = (value) =>
    <ISetProdStatusListFilter>{ type: SET_PROD_STATUS_LIST_FILTER, payload: value };

export const SET_LASER_STATUS_LIST_FILTER = 'ORDER_BASE.SET_LASER_STATUS_LIST_FILTER';
export interface ISetLaserStatusListFilter { type: 'ORDER_BASE.SET_LASER_STATUS_LIST_FILTER', payload: any }
export const SetLaserStatusListFilter = (value) =>
    <ISetLaserStatusListFilter>{ type: SET_LASER_STATUS_LIST_FILTER, payload: value };

export const SELECT_ORDER_STATUS_FILTER = 'ORDER_BASE.SELECT_ORDER_STATUS_FILTER';
export interface ISelectOrderStatusFilter { type: 'ORDER_BASE.SELECT_ORDER_STATUS_FILTER', payload: any }
export const SelectOrderStatusFilter = (value) =>
    <ISelectOrderStatusFilter>{ type: SELECT_ORDER_STATUS_FILTER, payload: value };

export const SELECT_PARNTER_FOR_FILTER = 'ORDER_BASE.SELECT_PARNTER_FOR_FILTER';
export interface ISelectPartnerForFilter { type: 'ORDER_BASE.SELECT_PARNTER_FOR_FILTER', payload: any }
export const SelectPartnerForFilter = (value) =>
    <ISelectPartnerForFilter>{ type: SELECT_PARNTER_FOR_FILTER, payload: value };

export const GET_PARTNERS_FOR_FILTER = '[API] ORDER_BASE.GET_PARTNERS_FOR_FILTER';
export interface IGetPartnersForFilter { type: '[API] ORDER_BASE.GET_PARTNERS_FOR_FILTER', payload: any }
export const GetPartnersForFilter = (value) =>
    <IGetPartnersForFilter>{ type: GET_PARTNERS_FOR_FILTER, payload: value };

export const SET_PARTNERS_FOR_FILTER = 'ORDER_BASE.SET_PARTNERS_FOR_FILTER';
export interface ISetPartnersForFilter { type: 'ORDER_BASE.SET_PARTNERS_FOR_FILTER', payload: any }
export const SetPartnersForFilter = (value) =>
    <ISetPartnersForFilter>{ type: SET_PARTNERS_FOR_FILTER, payload: value };

export const GET_CLIENTS_FOR_FILTER = '[API] ORDER_BASE.GET_CLIENTS_FOR_FILTER';
export interface IGetClientsForFilter { type: '[API] ORDER_BASE.GET_CLIENTS_FOR_FILTER', payload: any }
export const GetClientsForFilter = (value) =>
    <IGetClientsForFilter>{ type: GET_CLIENTS_FOR_FILTER, payload: value };

export const SET_CLIENTS_FOR_FILTER = 'ORDER_BASE.SET_CLIENTS_FOR_FILTER';
export interface ISetClientsForFilter { type: 'ORDER_BASE.SET_CLIENTS_FOR_FILTER', payload: any }
export const SetClientsForFilter = (value) =>
    <ISetClientsForFilter>{ type: SET_CLIENTS_FOR_FILTER, payload: value };

export const SELECT_CLIENTS_FOR_FILTER = 'ORDER_BASE.SELECT_CLIENTS_FOR_FILTER';
export interface ISelectClientsForFilter { type: 'ORDER_BASE.SELECT_CLIENTS_FOR_FILTER', payload: any }
export const SelectClientsForFilter = (value) =>
    <ISelectClientsForFilter>{ type: SELECT_CLIENTS_FOR_FILTER, payload: value };

export const SELECT_DELIVERY_TYPE_FOR_FILTER = 'ORDER_BASE.SELECT_DELIVERY_TYPE_FOR_FILTER';
export interface ISelectDeliveryTypeForFilter { type: 'ORDER_BASE.SELECT_DELIVERY_TYPE_FOR_FILTER', payload: any }
export const SelectDeliveryTypeForFilter = (value) =>
    <ISelectDeliveryTypeForFilter>{ type: SELECT_DELIVERY_TYPE_FOR_FILTER, payload: value };

export const SELECT_PAYMENT_METHOD_FOR_FILTER = 'ORDER_BASE.SELECT_PAYMENT_METHOD_FOR_FILTER';
export interface ISelectPaymentMethodForFilter { type: 'ORDER_BASE.SELECT_PAYMENT_METHOD_FOR_FILTER', payload: any }
export const SelectPaymentMethodForFilter = (value) =>
    <ISelectPaymentMethodForFilter>{ type: SELECT_PAYMENT_METHOD_FOR_FILTER, payload: value };

export const CHANGE_ORDER_CODE_FOR_FILTER = 'ORDER_BASE.CHANGE_ORDER_CODE_FOR_FILTER';
export interface IChangeOrderCodeForFilter { type: 'ORDER_BASE.CHANGE_ORDER_CODE_FOR_FILTER', payload: any }
export const ChangeOrderCodeForFilter = (value) =>
    <IChangeOrderCodeForFilter>{ type: CHANGE_ORDER_CODE_FOR_FILTER, payload: value };

export const GET_ORDER_PRODUCTS_FOR_ORDER = '[API] ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ORDER';
export interface IGetOrderProductsForOrder { type: '[API] ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ORDER', payload: any }
export const GetOrderProductsForOrder = (value) =>
    <IGetOrderProductsForOrder>{ type: GET_ORDER_PRODUCTS_FOR_ORDER, payload: value };

export const SET_ORDER_PRODUCTS_FOR_ORDER = 'ORDER_BASE.SET_ORDER_PRODUCTS_FOR_ORDER';
export interface ISetOrderProductsForOrder { type: 'ORDER_BASE.SET_ORDER_PRODUCTS_FOR_ORDER', payload: any }
export const SetOrderProductsForOrder = (value) =>
    <ISetOrderProductsForOrder>{ type: SET_ORDER_PRODUCTS_FOR_ORDER, payload: value };

export const GET_ALL_STATUSES_FOR_ORDER = '[API] ORDER_BASE.GET_ALL_STATUSES_FOR_ORDER';
export interface IGetAllStatusesForOrder { type: '[API] ORDER_BASE.GET_ALL_STATUSES_FOR_ORDER', payload: any }
export const GetAllStatusesForOrder = (value) =>
    <IGetAllStatusesForOrder>{ type: GET_ALL_STATUSES_FOR_ORDER, payload: value };

export const SET_ALL_STATUSES_FOR_ORDER = 'ORDER_BASE.SET_ALL_STATUSES_FOR_ORDER';
export interface ISetAllStatusesForOrder { type: 'ORDER_BASE.SET_ALL_STATUSES_FOR_ORDER', payload: any }
export const SetAllStatusesForOrder = (value) =>
    <ISetAllStatusesForOrder>{ type: SET_ALL_STATUSES_FOR_ORDER, payload: value };

export const SELECT_ORDER = 'ORDER_BASE.SELECT_ORDER';
export interface ISelectOrder { type: 'ORDER_BASE.SELECT_ORDER', payload: any }
export const SelectOrder = (index, selected) =>
    <ISelectOrder>{ type: SELECT_ORDER, payload: { index, selected } };

export const GET_ORDERS_FOR_ASSEMBLING = '[API] ORDER_BASE.GET_ORDERS_FOR_ASSEMBLING';
export interface IGetOrdersForAssembling { type: '[API] ORDER_BASE.GET_ORDERS_FOR_ASSEMBLING', payload: any }
export const GetOrdersForAssembling = () =>
    <IGetOrdersForAssembling>{ type: GET_ORDERS_FOR_ASSEMBLING };

export const SEND_ORDERS_FOR_ASSEMBLE = '[API] ORDER_BASE.SEND_ORDERS_FOR_ASSEMBLE';
export interface ISendOrdersForAssemble { type: '[API] ORDER_BASE.SEND_ORDERS_FOR_ASSEMBLE', payload: any }
export const SendOrdersForAssemble = (value) =>
    <ISendOrdersForAssemble>{ type: SEND_ORDERS_FOR_ASSEMBLE, payload: value };

export const RESET_ORDER_BASE_FILTER = 'ORDER_BASE.RESET_ORDER_BASE_FILTER';
export interface IResetOrderBaseFilter { type: 'ORDER_BASE.RESET_ORDER_BASE_FILTER', payload: any }
export const ResetOrderBaseFilter = () =>
    <IResetOrderBaseFilter>{ type: RESET_ORDER_BASE_FILTER };

export const SET_CURRENT_ORDER_INDEX = 'ORDER_BASE.SET_CURRENT_ORDER_INDEX';
export interface ISetCurrentOrderIndex { type: 'ORDER_BASE.SET_CURRENT_ORDER_INDEX', payload: any }
export const SetCurrentOrderIndex = (value) =>
    <ISetCurrentOrderIndex>{ type: SET_CURRENT_ORDER_INDEX, payload: value };

export const CHANGE_PAYMENT_STATUS_FOR_ORDER = 'ORDER_BASE.CHANGE_PAYMENT_STATUS_FOR_ORDER';
export interface IChangePaymentStatusForOrder { type: 'ORDER_BASE.CHANGE_PAYMENT_STATUS_FOR_ORDER', payload: any }
export const ChangePaymentStatusForOrder = (value) =>
    <IChangePaymentStatusForOrder>{ type: CHANGE_PAYMENT_STATUS_FOR_ORDER, payload: value };

export const CHANGE_PAYMENT_DATE = 'ORDER_BASE.CHANGE_PAYMENT_DATE';
export interface IChangePaymentDate { type: 'ORDER_BASE.CHANGE_PAYMENT_DATE', payload: any }
export const ChangePaymentDate = (value) =>
    <IChangePaymentDate>{ type: CHANGE_PAYMENT_DATE, payload: value };

export const ADD_PAYMENT_STATUS = '[API] ORDER_BASE.ADD_PAYMENT_STATUS';
export interface IAddPaymentStatus { type: '[API] ORDER_BASE.ADD_PAYMENT_STATUS', payload: any }
export const AddPaymentStatus = (value) =>
    <IAddPaymentStatus>{ type: ADD_PAYMENT_STATUS, payload: value };

export const SET_PAYMENT_STATUS = 'ORDER_BASE.SET_PAYMENT_STATUS';
export interface ISetPaymentStatus { type: 'ORDER_BASE.SET_PAYMENT_STATUS', payload: any }
export const SetPaymentStatus = (value) =>
    <ISetPaymentStatus>{ type: SET_PAYMENT_STATUS, payload: value };

export const GET_ALL_PAYMENT_STATUSES_FOR_ORDER = '[API] ORDER_BASE.GET_ALL_PAYMENT_STATUSES_FOR_ORDER';
export interface IGetAllPaymentStatusesForOrder { type: '[API] ORDER_BASE.GET_ALL_PAYMENT_STATUSES_FOR_ORDER', payload: any }
export const GetAllPaymentStatusesForOrder = (value) =>
    <IGetAllPaymentStatusesForOrder>{ type: GET_ALL_PAYMENT_STATUSES_FOR_ORDER, payload: value };

export const SET_ALL_PAYMENT_STATUSES_FOR_ORDER = 'ORDER_BASE.SET_ALL_PAYMENT_STATUSES_FOR_ORDER';
export interface ISetAllPaymentStatusesForOrder { type: 'ORDER_BASE.SET_ALL_PAYMENT_STATUSES_FOR_ORDER', payload: any }
export const SetAllPaymentStatusesForOrder = (value) =>
    <ISetAllPaymentStatusesForOrder>{ type: SET_ALL_PAYMENT_STATUSES_FOR_ORDER, payload: value };

export const SELECT_CONFIRM_PAYMENT_STATUS_FOR_ORDER = 'ORDER_BASE.SELECT_CONFIRM_PAYMENT_STATUS_FOR_ORDER';
export interface ISelectConfirmPaymentStatusForOrder { type: 'ORDER_BASE.SELECT_CONFIRM_PAYMENT_STATUS_FOR_ORDER', payload: any }
export const SelectConfirmPaymentStatusForOrder = (value) =>
    <ISelectConfirmPaymentStatusForOrder>{ type: SELECT_CONFIRM_PAYMENT_STATUS_FOR_ORDER, payload: value };

export const ADD_CONFIRM_PAYMENT_STATUS = '[API] ORDER_BASE.ADD_CONFIRM_PAYMENT_STATUS';
export interface IAddConfirmPaymentStatus { type: '[API] ORDER_BASE.ADD_CONFIRM_PAYMENT_STATUS', payload: any }
export const AddConfirmPaymentStatus = (value) =>
    <IAddConfirmPaymentStatus>{ type: ADD_CONFIRM_PAYMENT_STATUS, payload: value };

export const SET_CURRENT_CONFIRM_PAYMENT_STATUS = 'ORDER_BASE.SET_CURRENT_CONFIRM_PAYMENT_STATUS';
export interface ISetCurrentConfirmPaymentStatus { type: 'ORDER_BASE.SET_CURRENT_CONFIRM_PAYMENT_STATUS', payload: any }
export const SetCurrentConfirmPaymentStatus = (value) =>
    <ISetCurrentConfirmPaymentStatus>{ type: SET_CURRENT_CONFIRM_PAYMENT_STATUS, payload: value };

export const GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER = '[API] ORDER_BASE.GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER';
export interface IGetAllConfirmPaymentStatusForOrder { type: '[API] ORDER_BASE.GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER', payload: any }
export const GetAllConfirmPaymentStatusForOrder = (value) =>
    <IGetAllConfirmPaymentStatusForOrder>{ type: GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER, payload: value };

export const SET_ALL_CONFIRM_PAYMENT_STATUSES_FOR_ORDER = 'ORDER_BASE.SET_ALL_CONFIRM_PAYMENT_STATUSES_FOR_ORDER';
export interface ISetAllConfirmPaymentStatusesForOrder { type: 'ORDER_BASE.SET_ALL_CONFIRM_PAYMENT_STATUSES_FOR_ORDER', payload: any }
export const SetAllConfirmPaymentStatusesForOrder = (value) =>
    <ISetAllConfirmPaymentStatusesForOrder>{ type: SET_ALL_CONFIRM_PAYMENT_STATUSES_FOR_ORDER, payload: value };

export const CHANGE_ORDER_PRODUCT_AVAILABILITY = '[API] ORDER_BASE.CHANGE_ORDER_PRODUCT_AVAILABILITY';
export interface IChangeOrderProductAvailability { type: '[API] ORDER_BASE.CHANGE_ORDER_PRODUCT_AVAILABILITY', payload: any }
export const ChangeOrderProductAvailability = (productNetId, productIndex, isAvailable) =>
    <IChangeOrderProductAvailability>{ type: CHANGE_ORDER_PRODUCT_AVAILABILITY, payload: { productNetId, productIndex, isAvailable } };

export const SET_PRODUCT_AVAILABILITY = 'ORDER_BASE.SET_PRODUCT_AVAILABILITY';
export interface ISetProductAvailability { type: 'ORDER_BASE.SET_PRODUCT_AVAILABILITY', payload: any }
export const SetProductAvailability = (productIndex, isAvailable, stockstatus, printStatus, productionStatus, laserStatus) =>
    <ISetProductAvailability>{ type: SET_PRODUCT_AVAILABILITY, payload: { productIndex, isAvailable, stockstatus, printStatus, productionStatus, laserStatus } };

export const GET_ALL_STOCK_STATUS_FOR_ORDER = '[API] ORDER_BASE.GET_ALL_STOCK_STATUS_FOR_ORDER';
export interface IGetAllStockStatusForOrder { type: '[API] ORDER_BASE.GET_ALL_STOCK_STATUS_FOR_ORDER', payload: any }
export const GetAllStockStatusForOrder = (value) =>
    <IGetAllStockStatusForOrder>{ type: GET_ALL_STOCK_STATUS_FOR_ORDER, payload: value };

export const SET_ALL_STOCK_STATUSES_FOR_ORDER = 'ORDER_BASE.SET_ALL_STOCK_STATUSES_FOR_ORDER';
export interface ISetAllStockStatusesForOrder { type: 'ORDER_BASE.SET_ALL_STOCK_STATUSES_FOR_ORDER', payload: any }
export const SetAllStockStatusesForOrder = (value) =>
    <ISetAllStockStatusesForOrder>{ type: SET_ALL_STOCK_STATUSES_FOR_ORDER, payload: value };

export const UPLOAD_FILES_FOR_ORDER_PRODUCT = '[API] ORDER_BASE.UPLOAD_FILES_FOR_ORDER_PRODUCT';
export interface IUploadFilesForOrderProduct { type: '[API] ORDER_BASE.UPLOAD_FILES_FOR_ORDER_PRODUCT', payload: any }
export const UploadFilesForOrderProduct = (formData, orderProductUid, orderUid, fileType) =>
    <IUploadFilesForOrderProduct>{ type: UPLOAD_FILES_FOR_ORDER_PRODUCT, payload: { formData, orderProductUid, orderUid, fileType } };

export const UPDATE_ORDER_PRODUCT = '[API] ORDER_BASE.UPDATE_ORDER_PRODUCT';
export interface IUpdateOrderProduct { type: '[API] ORDER_BASE.UPDATE_ORDER_PRODUCT', payload: any }
export const UpdateOrderProduct = (orderProduct) =>
    <IUpdateOrderProduct>{ type: UPDATE_ORDER_PRODUCT, payload: orderProduct };

export const SET_UPDATED_ORDER_PRODUCT = 'ORDER_BASE.SET_UPDATED_ORDER_PRODUCT';
export interface ISetUpdatedOrderProduct { type: 'ORDER_BASE.SET_UPDATED_ORDER_PRODUCT', payload: any }
export const SetUpdatedOrderProduct = (orderProduct, index, printStatus, productionStatus, laserStatus) =>
    <ISetUpdatedOrderProduct>{ type: SET_UPDATED_ORDER_PRODUCT, payload: { orderProduct, index, printStatus, productionStatus, laserStatus } };

export const CHANGE_PRINT_COMMENT = 'ORDER_BASE.CHANGE_PRINT_COMMENT';
export interface IChangePrintComment { type: 'ORDER_BASE.CHANGE_PRINT_COMMENT', payload: any }
export const ChangePrintComment = (comment, index, id) =>
    <IChangePrintComment>{ type: CHANGE_PRINT_COMMENT, payload: { comment, index, id } };

export const CHANGE_USER_IMAGE_COMMENT = 'ORDER_BASE.CHANGE_USER_IMAGE_COMMENT';
export interface IChangeUserImageComment { type: 'ORDER_BASE.CHANGE_USER_IMAGE_COMMENT', payload: any }
export const ChangeUserImageComment = (comment, index, id) =>
    <IChangeUserImageComment>{ type: CHANGE_USER_IMAGE_COMMENT, payload: { comment, index, id } };

export const CHANGE_PRODUCTION_OPERATOR_COMMENT = 'ORDER_BASE.CHANGE_PRODUCTION_OPERATOR_COMMENT';
export interface IChangeProductionOperatorComment { type: 'ORDER_BASE.CHANGE_PRODUCTION_OPERATOR_COMMENT', payload: any }
export const ChangeProductionOperatorComment = (comment, index) =>
    <IChangeProductionOperatorComment>{ type: CHANGE_PRODUCTION_OPERATOR_COMMENT, payload: { comment, index } };

export const CHANGE_PRODUCTION_COMMENT = 'ORDER_BASE.CHANGE_PRODUCTION_COMMENT';
export interface IChangeProductionComment { type: 'ORDER_BASE.CHANGE_PRODUCTION_COMMENT', payload: any }
export const ChangeProductionComment = (comment, index) =>
    <IChangeProductionComment>{ type: CHANGE_PRODUCTION_COMMENT, payload: { comment, index } };

export const UPDATE_ORDER = '[API] ORDER_BASE.UPDATE_ORDER';
export interface IUpdateOrder { type: '[API] ORDER_BASE.UPDATE_ORDER', payload: any }
export const UpdateOrder = (order) =>
    <IUpdateOrder>{ type: UPDATE_ORDER, payload: order };

export const SET_UPDATED_ORDER = 'ORDER_BASE.SET_UPDATED_ORDER';
export interface ISetUpdatedOrder { type: 'ORDER_BASE.SET_UPDATED_ORDER', payload: any }
export const SetUpdatedOrder = (order) =>
    <ISetUpdatedOrder>{ type: SET_UPDATED_ORDER, payload: order };

export const SELECT_ALL_ORDERES = 'ORDER_BASE.SELECT_ALL_ORDERES';
export interface ISelectAllOrder { type: 'ORDER_BASE.SELECT_ALL_ORDERES', payload: any }
export const SelectAllOrder = (orders) =>
    <ISelectAllOrder>{ type: SELECT_ALL_ORDERES, payload: orders };

export const REMOVE_FILE_FROM_ORDER_PRODUCT = '[API] ORDER_BASE.REMOVE_FILE_FROM_ORDER_PRODUCT';
export interface IRemoveFileFromOrderProduct { type: '[API] ORDER_BASE.REMOVE_FILE_FROM_ORDER_PRODUCT', payload: any }
export const RemoveFileFromOrderProduct = (orderProductUid, fileType) =>
    <IRemoveFileFromOrderProduct>{ type: REMOVE_FILE_FROM_ORDER_PRODUCT, payload: { orderProductUid, fileType } };

export const SELECT_START_DATE_FOR_FILTER = 'ORDER_BASE.SELECT_START_DATE_FOR_FILTER';
export interface ISelectStartDateForFilter { type: 'ORDER_BASE.SELECT_START_DATE_FOR_FILTER', payload: any }
export const SelectStartDateForFilter = (value) =>
    <ISelectStartDateForFilter>{ type: SELECT_START_DATE_FOR_FILTER, payload: value };

export const SELECT_END_DATE_FOR_FILTER = 'ORDER_BASE.SELECT_END_DATE_FOR_FILTER';
export interface ISelectEndDateForFilter { type: 'ORDER_BASE.SELECT_END_DATE_FOR_FILTER', payload: any }
export const SelectEndDateForFilter = (value) =>
    <ISelectEndDateForFilter>{ type: SELECT_END_DATE_FOR_FILTER, payload: value };

export const SELECT_PRINT_STATUS_FOR_FILTER = 'ORDER_BASE.SELECT_PRINT_STATUS_FOR_FILTER';
export interface ISelectPrintStatusForFilter { type: 'ORDER_BASE.SELECT_PRINT_STATUS_FOR_FILTER', payload: any }
export const SelectPrintStatusForFilter = (value) =>
    <ISelectPrintStatusForFilter>{ type: SELECT_PRINT_STATUS_FOR_FILTER, payload: value };

export const SELECT_TTN_DATE_FOR_FILTER = 'ORDER_BASE.SELECT_TTN_DATE_FOR_FILTER';
export interface ISelectTTNDateForFilter { type: 'ORDER_BASE.SELECT_TTN_DATE_FOR_FILTER', payload: any }
export const SelectTTNDateForFilter = (value) =>
    <ISelectTTNDateForFilter>{ type: SELECT_TTN_DATE_FOR_FILTER, payload: value };

export const SELECT_STOCK_STATUS_FOR_FILTER = 'ORDER_BASE.SELECT_STOCK_STATUS_FOR_FILTER';
export interface ISelectStockStatusForFilter { type: 'ORDER_BASE.SELECT_STOCK_STATUS_FOR_FILTER', payload: any }
export const SelectStockStatusForFilter = (value) =>
    <ISelectStockStatusForFilter>{ type: SELECT_STOCK_STATUS_FOR_FILTER, payload: value };

export const SELECT_PRODUCTION_STATUS_FOR_FILTER = 'ORDER_BASE.SELECT_PRODUCTION_STATUS_FOR_FILTER';
export interface ISelectProductionStatusForFilter { type: 'ORDER_BASE.SELECT_PRODUCTION_STATUS_FOR_FILTER', payload: any }
export const SelectProductionStatusForFilter = (value) =>
    <ISelectProductionStatusForFilter>{ type: SELECT_PRODUCTION_STATUS_FOR_FILTER, payload: value };

export const SELECT_LASER_STATUS_FOR_FILTER = 'ORDER_BASE.SELECT_LASER_STATUS_FOR_FILTER';
export interface ISelectLaserStatusForFilter { type: 'ORDER_BASE.SELECT_LASER_STATUS_FOR_FILTER', payload: any }
export const SelectLaserStatusForFilter = (value) =>
    <ISelectLaserStatusForFilter>{ type: SELECT_LASER_STATUS_FOR_FILTER, payload: value };

export const SELECT_ASSEMBLING_DATE_FOR_FILTER = 'ORDER_BASE.SELECT_ASSEMBLING_DATE_FOR_FILTER';
export interface ISelectAssemblingDateForFilter { type: 'ORDER_BASE.SELECT_ASSEMBLING_DATE_FOR_FILTER', payload: any }
export const SelectAssemblingDateForFilter = (value) =>
    <ISelectAssemblingDateForFilter>{ type: SELECT_ASSEMBLING_DATE_FOR_FILTER, payload: value };

export const UNMOUNT_COMPONENT = 'ORDER_BASE.UNMOUNT_COMPONENT';
export interface IUnmountComponent { type: 'ORDER_BASE.UNMOUNT_COMPONENT', payload: any }
export const UnmountComponent = (value) =>
    <IUnmountComponent>{ type: UNMOUNT_COMPONENT, payload: value };

export const GET_CLIENT_TO_RESTORE_FILTER = '[API] ORDER_BASE.GET_CLIENT_TO_RESTORE_FILTER';
export interface IGetClientToRestoreFilter { type: '[API] ORDER_BASE.GET_CLIENT_TO_RESTORE_FILTER', payload: any }
export const GetClientToRestoreFilter = (value) =>
    <IGetClientToRestoreFilter>{ type: GET_CLIENT_TO_RESTORE_FILTER, payload: value };

export const GET_PARTNER_TO_RESTORE_FILTER = '[API] ORDER_BASE.GET_PARTNER_TO_RESTORE_FILTER';
export interface IGetPartnerToRestoreFilter { type: '[API] ORDER_BASE.GET_PARTNER_TO_RESTORE_FILTER', payload: any }
export const GetPartnerToRestoreFilter = (value) =>
    <IGetPartnerToRestoreFilter>{ type: GET_PARTNER_TO_RESTORE_FILTER, payload: value };

export const CHANGE_CANT_REUSE_QUANTITY = 'ORDER_BASE.CHANGE_CANT_REUSE_QUANTITY';
export interface IChangeCantReuseQuantity { type: 'ORDER_BASE.CHANGE_CANT_REUSE_QUANTITY', payload: any }
export const ChangeCantReuseQuantity = (value) =>
    <IChangeCantReuseQuantity>{ type: CHANGE_CANT_REUSE_QUANTITY, payload: value };

export const SET_FAIL_PROCESSING = 'ORDER_BASE.SET_FAIL_PROCESSING';
export interface ISetFailProcessing { type: 'ORDER_BASE.SET_FAIL_PROCESSING', payload: any }
export const SetFailProcessing = (value) =>
    <ISetFailProcessing>{ type: SET_FAIL_PROCESSING, payload: value };

export const CHANGE_PRINT_REUSE_STATUS_MULTI = 'ORDER_BASE.CHANGE_PRINT_REUSE_STATUS_MULTI';
export interface IChangePrintReuseStatusMulti { type: 'ORDER_BASE.CHANGE_PRINT_REUSE_STATUS_MULTI', payload: any }
export const ChangePrintReuseStatusMulti = (status, index) =>
    <IChangePrintReuseStatusMulti>({ type: CHANGE_PRINT_REUSE_STATUS_MULTI, payload: { status, index } });

export const PROCESS_FAIL_MULTI = '[API] ORDER_BASE.PROCESS_FAIL_MULTI';
export interface IProcessFailMulti { type: '[API] ORDER_BASE.PROCESS_FAIL_MULTI', payload: any }
export const ProcessFailMulti = (orderProduct, failCount) =>
    <IProcessFailMulti>({ type: PROCESS_FAIL_MULTI, payload: { orderProduct, failCount } });

export const SET_ORDER_FOR_BASE = 'ORDER_BASE.SET_ORDER_FOR_BASE';
export interface ISetOrderOnBase { type: 'ORDER_BASE.SET_ORDER_FOR_BASE', payload: any }
export const SetOrderOnBase = (order) =>
    <ISetOrderOnBase>({ type: SET_ORDER_FOR_BASE, payload: order });

export const SET_ORDER_FOR_BASE_BY_INDEX = 'ORDER_BASE.SET_ORDER_FOR_BASE_BY_INDEX';
export interface ISetOrderOnBaseByIndex { type: 'ORDER_BASE.SET_ORDER_FOR_BASE_BY_INDEX', payload: any }
export const SetOrderOnBaseByIndex = (order, index) =>
    <ISetOrderOnBaseByIndex>({ type: SET_ORDER_FOR_BASE_BY_INDEX, payload: { order, index } });

export const GET_ORDERS_FOR_DECLARATIONS = '[API] ORDER_BASE.GET_ORDERS_FOR_DECLARATIONS';
export interface IGetOrdersForDeclarations { type: '[API] ORDER_BASE.GET_ORDERS_FOR_DECLARATIONS', payload: any }
export const GetOrdersForDeclarations = () =>
    <IGetOrdersForDeclarations>({ type: GET_ORDERS_FOR_DECLARATIONS });

export const CREATE_DECLARATIONS_DEFAULT = '[API] ORDER_BASE.CREATE_DECLARATIONS_DEFAULT';
export interface ICreateDeclarationsDefault { type: '[API] ORDER_BASE.CREATE_DECLARATIONS_DEFAULT', payload: any }
export const CreateDeclarationsDefault = (ids) =>
    <ICreateDeclarationsDefault>({ type: CREATE_DECLARATIONS_DEFAULT, payload: ids });

export const CREATE_DECLARATIONS_MULTI = '[API] ORDER_BASE.CREATE_DECLARATIONS_MULTI';
export interface ICreateDeclarationsMulti { type: '[API] ORDER_BASE.CREATE_DECLARATIONS_MULTI', payload: any }
export const CreateDeclarationsMulti = (ids) =>
    <ICreateDeclarationsMulti>({ type: CREATE_DECLARATIONS_MULTI, payload: ids });

export const ADD_ORDER_STATUS = '[API] ORDER_BASE.ADD_ORDER_STATUS';
export interface IAddOrderStatus { type: '[API] ORDER_BASE.ADD_ORDER_STATUS', payload: any }
export const AddOrderStatus = (orderStatus) =>
    <IAddOrderStatus>({ type: ADD_ORDER_STATUS, payload: orderStatus });

export const SET_ORDER_STATUS = 'ORDER_BASE.SET_ORDER_STATUS';
export interface ISetOrderStatus { type: 'ORDER_BASE.SET_ORDER_STATUS', payload: any }
export const SetOrderStatus = (status) =>
    <ISetOrderStatus>{ type: SET_ORDER_STATUS, payload: status };

export const CHANGE_TTN_FOR_FILTER = 'ORDER_BASE.CHANGE_TTN_FOR_FILTER';
export interface IChangeTtnForFilter { type: 'ORDER_BASE.CHANGE_TTN_FOR_FILTER', payload: any }
export const ChangeTtnForFilter = (value) =>
    <IChangeTtnForFilter>{ type: CHANGE_TTN_FOR_FILTER, payload: value };

export const GENERATE_REESTERS = '[API] ORDER_BASE.GENERATE_REESTERS';
export interface IGenerateReesters { type: '[API] ORDER_BASE.GENERATE_REESTERS', payload: any }
export const GenerateReesters = () =>
    <IGenerateReesters>{ type: GENERATE_REESTERS };

export const GET_REESTERS = '[API] ORDER_BASE.GET_REESTERS';
export interface IGetReesters { type: '[API] ORDER_BASE.GET_REESTERS', payload: any }
export const GetReesters = (date: any) =>
    <IGetReesters>{ type: GET_REESTERS, payload: date };

export const SET_REESTERS = 'ORDER_BASE.SET_REESTERS';
export interface ISetReesters { type: 'ORDER_BASE.SET_REESTERS', payload: any }
export const SetReesters = (reesters: any) =>
    <ISetReesters>{ type: SET_REESTERS, payload: reesters };

export const CHANGE_TTN_MANUAL = 'ORDER_BASE.CHANGE_TTN_MANUAL';
export interface IChangeTTNManual { type: 'ORDER_BASE.CHANGE_TTN_MANUAL', payload: any }
export const ChangeTTNManual = (ttn: any) =>
    <IChangeTTNManual>{ type: CHANGE_TTN_MANUAL, payload: ttn };

export const UPDATE_ORDER_TTN = '[API] ORDER_BASE.UPDATE_ORDER_TTN';
export interface IUpdateOrderTTN { type: '[API] ORDER_BASE.UPDATE_ORDER_TTN', payload: any }
export const UpdateOrderTTN = (orderId: number, ttn: any) =>
    <IUpdateOrderTTN>{ type: UPDATE_ORDER_TTN, payload: { orderId, ttn } };

export const SET_ORDER_TTN = 'ORDER_BASE.SET_ORDER_TTN';
export interface ISetOrderTTN { type: 'ORDER_BASE.SET_ORDER_TTN', payload: any }
export const SetOrderTTN = (ttn: any) =>
    <ISetOrderTTN>{ type: SET_ORDER_TTN, payload: ttn };

export const GET_AUTOCOMPLETE_MODEL_RESULT: string = '[API] ORDER_BASE.GET_AUTOCOMPLETE_MODEL_RESULT';
export interface IGetAutocompleteModelResult { type: '[API] ORDER_BASE.GET_AUTOCOMPLETE_MODEL_RESULT'; payload: any; }
export const GetAutocompleteModelResult = (value: string) =>
    <IGetAutocompleteModelResult>{ type: GET_AUTOCOMPLETE_MODEL_RESULT, payload: value };

export const GET_AUTOCOMPLETE_MODEL_SUCCESS = "ORDER_BASE.GET_AUTOCOMPLETE_MODEL_SUCCESS";
export interface IGetAutocompliteModelSuccess { type: "ORDER_BASE.GET_AUTOCOMPLETE_MODEL_SUCCESS"; payload: any }
export const GetAutocompliteModelSuccess = (optionData: any[]) =>
    <IGetAutocompliteModelSuccess>{ type: GET_AUTOCOMPLETE_MODEL_SUCCESS, payload: optionData };

export const CHANGE_MODEL_VALUE: string = 'ORDER_BASE.CHANGE_MODEL_VALUE';
export interface IChangeModelValue { type: 'ORDER_BASE.CHANGE_MODEL_VALUE'; payload: any; }
export const ChangeModelValue = (value: any) =>
    <IChangeModelValue>{ type: CHANGE_MODEL_VALUE, payload: value };


export const GET_ORDERS_FOR_SEND_FROM_STOCK = '[API] ORDER_BASE.GET_ORDERS_FOR_SEND_FROM_STOCK';
export interface IGetOrdersForSendFromStock { type: '[API] ORDER_BASE.GET_ORDERS_FOR_SEND_FROM_STOCK', payload: any }
export const GetOrdersForSendFromStock = () =>
    <IGetOrdersForSendFromStock>{ type: GET_ORDERS_FOR_SEND_FROM_STOCK };

export const SEND_ORDERS_FROM_STOCK = '[API] ORDER_BASE.SEND_ORDERS_FROM_STOCK';
export interface ISendOrdersFromStock { type: '[API] ORDER_BASE.SEND_ORDERS_FROM_STOCK', payload: any }
export const SendOrdersFromStock = (value) =>
    <ISendOrdersFromStock>{ type: SEND_ORDERS_FROM_STOCK, payload: value };

export const SEND_ORDERS_FROM_STOCK_RIGHT_VIEW = '[API] ORDER_BASE.SEND_FROM_STOCK_RIGHT_VIEW';
export interface ISendOrdersFromStockRightView { type: '[API] ORDER_BASE.SEND_FROM_STOCK_RIGHT_VIEW', payload: any }
export const SendOrdersFromStockRightView = (value) => 
    <ISendOrdersFromStockRightView> { type: SEND_ORDERS_FROM_STOCK_RIGHT_VIEW, payload: value };

export const SET_ORDERS_AFTER_SENDING_FROM_STOCK_RIGHT_VIEW = 'SET_ORDERS_AFTER_SENDING_FROM_STOCK_RIGHT_VIEW';
export interface ISetOrdersAfterSendingFromStockRightView { type: 'SET_ORDERS_AFTER_SENDING_FROM_STOCK_RIGHT_VIEW', payload: any }
export const SetOrdersAfterSendingFromStockRightView = (orders: any, count: any) =>
    <ISetOrdersAfterSendingFromStockRightView> { type: SET_ORDERS_AFTER_SENDING_FROM_STOCK_RIGHT_VIEW, payload: {orders, count} };

export const GENERATE_ORDER_EXPORT_LIST = '[API] ORDER_BASE.GENERATE_ORDER_EXPORT_LIST';
export interface IGenerateOrderExportList { type: '[API] ORDER_BASE.GENERATE_ORDER_EXPORT_LIST', payload: any }
export const GenerateOrderExportList = (filter) =>
    <IGenerateOrderExportList>{ type: GENERATE_ORDER_EXPORT_LIST, payload: filter };

export const SET_ORDER_EXPORT_LIST_URL = 'ORDER_BASE.SET_ORDER_EXPORT_LIST_URL';
export interface ISetOrderExportListUrl { type: 'ORDER_BASE.SET_ORDER_EXPORT_LIST_URL', payload: any }
export const SetOrderExportListUrl = (value) =>
    <ISetOrderExportListUrl>{ type: SET_ORDER_EXPORT_LIST_URL, payload: value };

export const FINISH_ACTION = 'ORDER_BASE.FINISH_ACTION';
export interface IFinishAction { type: 'ORDER_BASE.FINISH_ACTION', payload: any }
export const FinishAction = () =>
    <IFinishAction>{ type: FINISH_ACTION };

export const GET_AUTOCOMPLETE_LEXICON_RESULT: string = '[API] ORDER_BASE.GET_AUTOCOMPLETE_LEXICON_RESULT';
export interface IGetAutocompleteLexiconResult { type: '[API] ORDER_BASE.GET_AUTOCOMPLETE_LEXICON_RESULT'; payload: any; }
export const GetAutocompleteLexiconResult = (value: string) =>
    <IGetAutocompleteLexiconResult>{ type: GET_AUTOCOMPLETE_LEXICON_RESULT, payload: value };

export const GET_AUTOCOMPLETE_LEXICON_SUCCESS = "ORDER_BASE.GET_AUTOCOMPLETE_LEXICON_SUCCESS";
export interface IGetAutocompliteLexiconSuccess { type: "ORDER_BASE.GET_AUTOCOMPLETE_LEXICON_SUCCESS"; payload: any }
export const GetAutocompliteLexiconSuccess = (optionData: any[]) =>
    <IGetAutocompliteLexiconSuccess>{ type: GET_AUTOCOMPLETE_LEXICON_SUCCESS, payload: optionData };

export const CHANGE_LEXICON_VALUE: string = 'ORDER_BASE.CHANGE_LEXICON_VALUE';
export interface IChangeLexiconValue { type: 'ORDER_BASE.CHANGE_LEXICON_VALUE'; payload: any; }
export const ChangeLexiconValue = (value: any) =>
    <IChangeLexiconValue>{ type: CHANGE_LEXICON_VALUE, payload: value };

export const GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE: string = '[API] ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE';
export interface IGetOrderProductsForAllOrdersOnPage { type: '[API] ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE'; payload: any; }
export const GetOrderProductsForAllOrdersOnPage = (value: any) =>
    <IGetOrderProductsForAllOrdersOnPage> { type: GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE, payload: value };
  
export const GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE_SUCCESS: string = 'ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE_SUCCESS';
export interface IGetOrderProductsForAllOrdersOnPageSuccess { type: 'ORDER_BASE.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE_SUCCESS'; payload: any; }
export const GetOrderProductsForAllOrdersOnPageSuccess = (value: any) =>
    <IGetOrderProductsForAllOrdersOnPageSuccess> { type: GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE_SUCCESS, payload: value };

export const SET_PRODUCT_AVAILABILITY_FROM_PAGE_RV: string = 'SET_PRODUCT_AVAILABILITY_FROM_PAGE_RV';
export interface ISetProductAvailabilityFromPageRv { type: 'SET_PRODUCT_AVAILABILITY_FROM_PAGE_RV'; payload: any; }
export const SetProductAvailabilityFromPageRv = (orderNetId, orderProductIndex, isAvailable, stockstatus, printStatus, productionStatus, laserStatus) =>
    <ISetProductAvailabilityFromPageRv> { type: SET_PRODUCT_AVAILABILITY_FROM_PAGE_RV, payload: {orderNetId, orderProductIndex, isAvailable, stockstatus, printStatus, productionStatus, laserStatus} };

export const CHANGE_ORDER_PRODUCT_AVAILABILITY_FOR_PAGE: string = 'CHANGE_ORDER_PRODUCT_AVAILABILITY_FOR_PAGE';
export interface IChangeOrderProductAvailabilityForPageRv { type: 'CHANGE_ORDER_PRODUCT_AVAILABILITY_FOR_PAGE'; payload: any; }
export const ChangeOrderProductAvailabilityForPageRv = (productNetId, orderNetId, productIndex, isAvailable) => 
    <IChangeOrderProductAvailabilityForPageRv> { type: CHANGE_ORDER_PRODUCT_AVAILABILITY_FOR_PAGE, payload: {productNetId, orderNetId, productIndex, isAvailable} };

export type OrderBaseKnownActions =
    IGetAllOrders | ISetAllOrders | ISelectOrderTypeFilter | ISetOrderStatusListFilter | ISetPrintStatusListFilter | ISetProdStatusListFilter |
    ISelectOrderStatusFilter | IGetClientsForFilter | ISetClientsForFilter | ISelectClientsForFilter | ISelectDeliveryTypeForFilter |
    ISelectPaymentMethodForFilter | IChangeOrderCodeForFilter |
    IGetOrderProductsForOrder | ISetOrderProductsForOrder | IGetAllStatusesForOrder | ISetAllStatusesForOrder | ISelectOrder | IGetOrdersForAssembling |
    IResetOrderBaseFilter | ISendOrdersForAssemble | ISetCurrentOrderIndex | IChangePaymentStatusForOrder | IChangePaymentDate | IAddPaymentStatus |
    ISetPaymentStatus | ISetAllPaymentStatusesForOrder | IGetAllPaymentStatusesForOrder | ISelectConfirmPaymentStatusForOrder | IAddConfirmPaymentStatus |
    ISetCurrentConfirmPaymentStatus | IGetAllConfirmPaymentStatusForOrder | ISetAllConfirmPaymentStatusesForOrder | IChangeOrderProductAvailability |
    ISetProductAvailability | IGetAllStockStatusForOrder | ISetAllStockStatusesForOrder | IUploadFilesForOrderProduct | IUpdateOrderProduct |
    ISetUpdatedOrderProduct | IChangePrintComment | IChangeUserImageComment | IChangeProductionOperatorComment | IChangeProductionComment | IUpdateOrder |
    ISetUpdatedOrder | ISelectAllOrder | IRemoveFileFromOrderProduct | IGetPartnersForFilter | ISelectPartnerForFilter | ISetPartnersForFilter |
    ISelectStartDateForFilter | ISelectEndDateForFilter | ISelectPrintStatusForFilter | ISelectProductionStatusForFilter | ISelectAssemblingDateForFilter |
    IGetClientToRestoreFilter | IGetPartnerToRestoreFilter | IUnmountComponent | IChangeCantReuseQuantity | ISetFailProcessing |
    IChangePrintReuseStatusMulti | IProcessFailMulti | ISetOrderOnBase | IGetOrdersForDeclarations | ICreateDeclarationsDefault |
    ICreateDeclarationsMulti | ISelectStockStatusForFilter | IAddOrderStatus | ISetOrderStatus | IChangeTtnForFilter | IGenerateReesters |
    IGetReesters | ISetReesters | IChangeTTNManual | IUpdateOrderTTN | ISetOrderTTN | IFinishAction | ISelectTTNDateForFilter |
    IGetAutocompleteModelResult | IGetAutocompliteModelSuccess | IChangeModelValue | IGetOrdersForSendFromStock | ISendOrdersFromStock |
    ISetLaserStatusListFilter | ISelectLaserStatusForFilter | ISetOrderOnBaseByIndex | IGenerateOrderExportList | ISetOrderExportListUrl | 
    IGetAutocompleteLexiconResult | IGetAutocompliteLexiconSuccess | IChangeLexiconValue | IGetOrderProductsForAllOrdersOnPage | IGetOrderProductsForAllOrdersOnPageSuccess | ISetProductAvailabilityFromPageRv | ISendOrdersFromStockRightView | ISetOrdersAfterSendingFromStockRightView;