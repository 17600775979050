import * as React from "react";
import { TextBoxWithLabel } from "../../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../../helpers/validator.helper";
import { TextAreaWithLabel } from "../../../../shared/form.controls/textarea.with.label";
import { TextBox } from "../../../../shared/form.controls/textbox";

export interface IHoldBlock {
    MinModel: string;
    MaxModel: string;
    CommentModel: string;
    PriceLevel: string;
}

export const HoldBlock = (props: IHoldBlock) => {
    return (
        <div className="hold_block__item">
            <div className="item__title">
                {props.PriceLevel}
            </div>

            <div className="item__sub__title">
                Діапазон суми замовлень за розрахунковий період
            </div>

            <div className="hold__cold">
                <div className="defaultFormControl">
                    <TextBox
                        Type="text"
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                        }}
                        Model={props.MinModel}
                        IsRequired
                        ShowErrorOn='touched'
                        ValidatedOn='change'
                    />
                </div>
            </div>
          
            <div className="hold__cold last_child">
                <div className="defaultFormControl">
                    <TextBox
                        Type="text"
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                        }}
                        Model={props.MaxModel}
                        IsRequired
                        ShowErrorOn='touched'
                        ValidatedOn='change'
                    />
                </div>
            </div>

            <TextAreaWithLabel
                Validators={{
                    isRequired: (val: string) => !Validator.IsEmpty(val),
                }}
                ErrorMessages={{
                    isRequired: '*',
                }}
                ShowErrorOn='touched'
                ValidatedOn='change'
                LabelMessage='Comment'
                IsRequired={true}
                Model={props.CommentModel}
            />
        </div>
    );
}
