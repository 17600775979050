import { ClientBaseState } from "./client.base.state";
import { WizardState } from "./wizard.state";
import { OrderBaseState } from "./order.base.state";
import { OrderCardState } from "./order.card.state";
import { PrinterState } from "./printer.state";

export class SalesState {

}

export const defaultSalesState: SalesState = {

};

export interface IGlobalSalesState {
  clientBase: ClientBaseState;
  sales: SalesState;
  wizard: WizardState;
  orderBase: OrderBaseState;
  orderCard: OrderCardState;
  printers:PrinterState;
}