import { Partner } from "../../business.entities/partner";

export class PartnerGridState {
    filter: any;
    showUnauthorized: boolean;
    partners: Partner[];
    take: number;
    page: number;
    totalPages: number;
    isPageChange: boolean;
    fetching: boolean;
    cancelMode: boolean;
}

export const defaultPartnerGridState = {
    filter: {},
    showUnauthorized: true,
    partners: [],
    take: 25,
    page: 1,
    totalPages: 0,
    isPageChange: false,
    fetching: false,
    cancelMode: false
}