import * as actionCreators from '../../actions/partner/partner.info.actions';
import * as API from '../../constants/api.constants';
import * as rightViewAction from '../../actions/right.view.actions';
import { actions } from 'react-redux-form';
import { ADD_PARTNER } from '../../actions/registration.actions';
import { error, success } from 'react-notification-system-redux';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { push } from 'react-router-redux';
import { Store } from 'redux';
import { ErrorHandler } from '../../helpers/error.manager';
import storeProvider from '../../helpers/store.provider';
import { GetIdentityRoleName, IdentityRolesArray } from '../../helpers/identity.roles';
import { List } from 'linqts';
import { GetPricelevelName } from '../../helpers/enums/price.level.enum';

export const addUserPartner = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.ADD_USER_PARTNER)
        .switchMap(action =>
            Observable.ajax.post(`${API.SINGUP_PARTNER}?netId=${action.payload.partnerNetUid}`,
                JSON.stringify(action.payload.user),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {

                    storeProvider.getStore().dispatch(success({
                        title: `Аккаунт для пользователя ${action.payload.partnerNetUid} успешно создан`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    storeProvider.getStore().dispatch(actionCreators.GetPartner(action.payload.partnerNetUid));
                    storeProvider.getStore().dispatch(rightViewAction.RemoveRVData());

                    return actionCreators.AddUserPartnerSuccess(action.payload.user);
                }).catch((errorData: any) => {
                    // if (!isUnauthorized(errorData, store)) {
                    //     storeProvider.getStore().dispatch(error({
                    //         title: 'Ошибка в создании акаунта',
                    //         message: '',
                    //         position: 'br',
                    //         autoDismiss: 3,
                    //     }));
                    // }
                    actionCreators.AddUserPartnerFailed();

                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const updatePartner = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PARTNER)
        .switchMap(action =>
            Observable.ajax.post(`${API.UPDATE_PARTNER}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Информация партнера успешно изменена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return actionCreators.UpdatePartnerSuccess(response.response.Body);
                }).catch((errorData: any) => {
                    // if (!isUnauthorized(errorData, store)) {
                    //     storeProvider.getStore().dispatch(error({
                    //         title: 'Отшибка в изменении информации партнера',
                    //         message: '',
                    //         position: 'br',
                    //         autoDismiss: 3,
                    //     }));

                    // }
                    actionCreators.UpdatePartnerFailed();

                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const getPartner = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PARTNER)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PARTNER}?netId=` + action.payload,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    storeProvider.getStore().dispatch(success({
                        title: `Партнер ${action.payload} успешно получен`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    if (response.Body.User != null) {
                        storeProvider.getStore().dispatch(actions.change("partnerUserForm", { UserName: response.Body.User.UserName, Password: "" }));
                        storeProvider.getStore().dispatch(actions.change("partnerUserNameForm", { OldUserName: response.Body.User.UserName, NewUserName: "" }));
                    }

                    storeProvider.getStore().dispatch(actions.change("partnerForm", response.Body.Partner));

                    let selectedRole = null;
                    if (response.Body.Role) {
                        let role = new List(IdentityRolesArray).FirstOrDefault(c => c.value === response.Body.Role);
                        selectedRole = { value: response.Body.Role ? response.Body.Role : '', label: !role ? response.Body.Role : role.name }
                    }
                    debugger
                    if (response.Body.Partner.MinimumPriceLevel) {
                        storeProvider.getStore().dispatch(actionCreators.ChangeMinimumPriceLevel({ label: GetPricelevelName(response.Body.Partner.MinimumPriceLevel), value: response.Body.Partner.MinimumPriceLevel }));
                    }
                    if (response.Body.Partner.CityPostOffice && response.Body.Partner.PostCodePostOffice && response.Body.Partner.StreetCodePostOffice) {
                        storeProvider.getStore().dispatch(actionCreators.ChangePostOffice({ label: response.Body.Partner.PostCodePostOffice + " " + response.Body.Partner.StreetCodePostOffice, value: response.Body.Partner.PostCodePostOffice + " " + response.Body.Partner.StreetCodePostOffice }));
                        storeProvider.getStore().dispatch(actionCreators.ChangeCity({ label: response.Body.Partner.CityPostOffice, value: response.Body.Partner.CityPostOffice }));
                    }


                    return actionCreators.GetPartnerSuccess(response.Body.Partner, response.Body.User, selectedRole);
                }).catch((errorData: any) => {
                    actionCreators.GetPartnerFailed();

                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const updatePartnerUserName = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPDATE_PARTNER_USER_NAME)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.UPDATE_PARTNER_USER_NAME}?oldUserName=${action.payload.oldUserName}&newUserName=${action.payload.newUserName}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Имя учётной записи успешно изменён`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    storeProvider.getStore().getState().partner.partnerInfo.user.UserName = action.payload.newUserName;
                    storeProvider.getStore().dispatch(actions.reset("partnerUserNameForm"));
                    storeProvider.getStore().dispatch(rightViewAction.RemoveRVData());

                    return actionCreators.ClosePartnerUserRv();
                }).catch((errorData: any) => {
                    // if (!isUnauthorized(errorData, store)) {
                    //     storeProvider.getStore().dispatch(error({
                    //         title: 'ошибка в изменении имени учётной записи',
                    //         message: '',
                    //         position: 'br',
                    //         autoDismiss: 3,
                    //     }));
                    // }

                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const updatePartnerPasswordForce = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.UPADATE_PARTNER_PASSWORD_FORCE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.UPDATE_PARTNER_USER_PASSWORD_FORCE}?userName=${action.payload.userName}&newPassword=${action.payload.password}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Пароль успешно изменён`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    storeProvider.getStore().dispatch(actions.reset("partnerUserForm"));
                    storeProvider.getStore().dispatch(actions.change("partnerUserForm", { UserName: response.Body.UserName, Password: "" }));

                    return rightViewAction.RemoveRVData();
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const ChangeRole = (action$, state$) =>
    action$.ofType(actionCreators.UPADTE_ROLE)
        .switchMap(action => {
            return Observable.ajax.getJSON(`${API.CHANGE_ROLE}?netUid=${action.payload.netUid}&role=${action.payload.role}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: response.Message,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return rightViewAction.RemoveRVData();
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        }
        );
