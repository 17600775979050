import * as React from "react";
import { connect } from "react-redux";
import ProductColor from "../../../../../business.entities/product.color";
import { bindActionCreators } from "redux";
import {
    SelectCurrentProductColor,
    ConfirmProductColorSelect,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SetProductionCommentForOrderProduct,
    SelectOptionsForOrderProduct
} from '../../../../../actions/sales/wizard.actions';
import { List } from "linqts";
import { ProductPriceListItem } from "../../../../../business.entities/product.price.list.item";
import { GetOrderProductStepComponent } from "../../../../../helpers/enums/order.product.steps";
import { OnGoToNextStepCommand, OnGoToPrevStepCommand } from "../../../../../helpers/wizard.substep.helper";
import Product from "../../../../../business.entities/product";
import { WizardState } from "../../../../../states/sales/wizard.state";
import { OrderBaseState } from "../../../../../states/sales/order.base.state";
import { OrderProduct } from "../../../../../business.entities/order.product";
import { ProductTypeEnum } from "../../../../../helpers/enums/product.type.enum";
import * as API from "../../../../../constants/api.constants";
import { ShowHoverBlock } from "../../../../../actions/right.view.actions";

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectCurrentProductColor,
    ConfirmProductColorSelect,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SetProductionCommentForOrderProduct,
    ShowHoverBlock,
    SelectOptionsForOrderProduct
}, dispatch);

const AdditionalOptions = (props: any) => {
    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return true;

        return false;
    }

    document.onkeydown = (e) => {
        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            props.ShowHoverBlock(null);
            OnGoToNextStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }


    const OnSelectOptionByMouse = (option) => {
        //add options
        let options: any[] = Object.assign([], props.Wizard.currentOrderProduct.OrderProductAdditionalOption);

        if (new List(options).Any(c => c.AdditionalOption.OptionCategoryId === option.OptionCategoryId)) {
            options.forEach((c, index) => {
                if (c.AdditionalOption.OptionCategoryId === option.OptionCategoryId) {

                    if (c.Id) {
                        if (option.Id === c.AdditionalOption.Id)
                            c.Deleted = !c.Deleted;
                        else
                            c.Deleted = true;
                    } else
                        options.splice(index, 1);
                }
            })

            new List(options).Any(c => c.AdditionalOption.Id === option.Id) === false && options.push({ OptionId: option.Id, OrderProductId: null, AdditionalOption: option });
        } else
            options.push({ OptionId: option.Id, OrderProductId: null, AdditionalOption: option });

        props.SelectOptionsForOrderProduct(options);

        props.ShowHoverBlock(null);

        let lengthes = 0; 
        props.Wizard.currentProduct.OptionCategoryTree.map(c => lengthes += c.SubCategories.length);

        if(lengthes === options.filter(c => !c.Deleted).length)
            OnGoToNextStepCommand(props);
    }

    return (
        <div className="colorContainer__content ">
            <div className="colorContainer__contentLabel">Допольнительные опции товара</div>
            <div className={`colorContainer__contentWrapper addit__CONTENT content_scroll`}>
                {props.Wizard.currentProduct.OptionCategoryTree.length > 0 && props.Wizard.currentProduct.OptionCategoryTree.map((category, index) =>
                    <div className="g__LIST_ITEM" key={category.NetUID} >
                        {category.SubCategories.map((subCategory, index) =>
                            <div key={subCategory.NetUID}>
                                <div className="g__item__TITLE">
                                    {category.Name} - {subCategory.Name}
                                </div>

                                {subCategory.AdditionalOptions.map((option, index) => {
                                    return <div className={`productColor__item  ${(props.Wizard.currentOrderProduct as OrderProduct).OrderProductAdditionalOption
                                        && new List((props.Wizard.currentOrderProduct as OrderProduct).OrderProductAdditionalOption)
                                            .Any(c => c.OptionId === option.Id && !c.Deleted) ? 'isSelected' : ''}`}
                                        key={option.NetUID}
                                        onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(option) }}
                                        onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                        onClick={() => OnSelectOptionByMouse(option)}>
                                        <div className="name">{option.Name}</div>
                                    </div>
                                }
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalOptions)