import * as rightViewActions from '../../actions/right.view.actions';
import * as actionCreators from '../../actions/stock/lexicon.actions';
import * as API from '../../constants/api.constants';
import * as LexiconActionTypes from '../../actions/stock/lexicon.actions';
import * as productActionCreators from '../../actions/stock/product.actions';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { isUnauthorized } from '../../helpers/unauthorize.hendle';
import { Lexicon } from '../../business.entities/lexicon';
import { List } from 'linqts';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import { ErrorHandler } from '../../helpers/error.manager';
import storeProvider from '../../helpers/store.provider';

export const pageChange = (action$, store: Store<any>) =>
    action$.ofType(LexiconActionTypes.GET_LEXICONS_FILTERED_PAGINATED)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_LEXICONS_FILTERED_PAGINATED}?count=${action.payload.count}&page=${action.payload.page}&value=${action.payload.value}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Лексиконы получены',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));
                    return actionCreators.GetLexiconsSuccess(response.Body);
                }).catch((errorData: any) => {
                    actionCreators.GetLexiconsFailed({});

                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const updateLexicon = (action$, store: Store<any>) =>
    action$.ofType(LexiconActionTypes.UPDATE_LEXICON)
        .switchMap(action =>
            Observable.ajax.post(API.UPDATE_LEXICON, JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Лексикон успешно изменён',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    actions.reset("lexiconForm");

                    const lexiconToUpdate = new List(storeProvider.getStore().getState().stock.lexicon.lexicons)
                        .Where((c: Lexicon) => c.Id === response.response.Body.Id)
                        .FirstOrDefault();

                    const index = storeProvider.getStore().getState().stock.lexicon.lexicons.indexOf(lexiconToUpdate);

                    storeProvider.getStore().getState().stock.lexicon.lexicons[index] = response.response.Body;

                    storeProvider.getStore().dispatch(LexiconActionTypes.DeselectLexicon());
                    storeProvider.getStore().dispatch(rightViewActions.RemoveRVData());

                    return actionCreators.UpdateLexiconSuccess();
                }).catch((errorData: any) => {
                    actionCreators.UpdateLexiconFailed();

                    return Observable.of(ErrorHandler(errorData));
                })
        );


export const getAllLexicons = (action$, store: Store<any>) =>
    action$.ofType(LexiconActionTypes.GET_ALL_LEXICONS)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_LEXICONS}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Лексиконы получены',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    let arrayToReturn: any[] = [];
                    response.Body.forEach((element: Lexicon) => {
                        arrayToReturn.push({ value: element.Id.toString(), label: element.OfficialPartnerName });
                    });
                    return productActionCreators.SetLexiconSelect(arrayToReturn);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );    