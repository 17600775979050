import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    ConfirmOrderProductSelection,
    SelectOptionsForOrderProduct
} from '../../../../../../actions/sales/order.card.actions';
import { List } from "linqts";
import { OnGoToNextStepCommand, OnGoToPrevStepCommand } from "../../../../../../helpers/wizard.substep.helper";
import { ProductUpdateState, OrderCardState } from "../../../../../../states/sales/order.card.state";
import Product from "../../../../../../business.entities/product";
import { ShowHoverBlock } from "../../../../../../actions/right.view.actions";
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    SelectOptionsForOrderProduct,
    ShowHoverBlock
}, dispatch);

const AdditionalOptions = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return true;

        return false;
    }

    document.onkeydown = (e) => {
        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderCard.productUpdateSubState.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            props.ShowHoverBlock(null);
            OnGoToNextStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }


    const OnSelectOptionByMouse = (option) => {
        //add options
        let options: any[] = Object.assign([], orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption);

        if (new List(options).Any(c => c.AdditionalOption.OptionCategoryId === option.OptionCategoryId)) {
            options.forEach((c, index) => {
                if (c.AdditionalOption.OptionCategoryId === option.OptionCategoryId) {

                    if (c.Id) {
                        if (option.Id === c.AdditionalOption.Id)
                            c.Deleted = !c.Deleted;
                        else
                            c.Deleted = true;
                    } else
                        options.splice(index, 1);
                }
            })

            new List(options).Any(c => c.AdditionalOption.Id === option.Id) === false && options.push({ OptionId: option.Id, OrderProductId: null, AdditionalOption: option });
        } else
            options.push({ OptionId: option.Id, OrderProductId: null, AdditionalOption: option });

        props.SelectOptionsForOrderProduct(options);

        props.ShowHoverBlock(null);

        let lengthes = 0; 
        (orderProductUpdate.currentProduct as any).OptionCategoryTree.map(c => lengthes += c.SubCategories.length);
        if(lengthes === options.filter(c => !c.Deleted).length)
            OnGoToNextOrderCardStepCommand(props);
    }

    return (
        <div className="colorContainer__content">
            <div className="colorContainer__contentLabel">Допольнительные опции товара</div>
            <div className={`colorContainer__contentWrapper addit__CONTENT content_scroll`}>
                {(orderProductUpdate.currentProduct as any).OptionCategoryTree.length > 0 && (orderProductUpdate.currentProduct as any).OptionCategoryTree.map((category, index) =>
                    <div className="g__LIST_ITEM" key={category.NetUID} >
                        {category.SubCategories.map((subCategory, index) =>
                            <div key={subCategory.NetUID}>
                                <div className="g__item__TITLE">
                                    {category.Name} - {subCategory.Name}
                                </div>

                                {subCategory.AdditionalOptions.map((option, index) => {
                                    return <div className={`productColor__item  ${orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption
                                        && new List(orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption)
                                            .Any(c => c.OptionId === option.Id && !c.Deleted) ? 'isSelected' : ''}`}
                                        key={option.NetUID}
                                        onMouseEnter={(e) => {e.stopPropagation(); props.ShowHoverBlock(option) }}
                                        onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                        onClick={() => OnSelectOptionByMouse(option)}>
                                        <div className="name">{option.Name}</div>
                                    </div>
                                }
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AdditionalOptions)