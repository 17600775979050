import { DeliveryType } from './enums/delivery.type';
import { OrderStatusEnum } from './enums/order.status.enum';
import { OrderTypeEnum } from './enums/order.type.enum';
import { PaymentMethod } from './enums/payment.method';
import { StockStatusEnum } from './enums/stock.status.enum';

export class OrderBaseFilterEntity {
    public StartDate: any;
    public EndDate: any;
    public PartnerNetId: string;
    public OrderStatus: OrderStatusEnum;
    public StockStatus: StockStatusEnum;
    public DeliveryType: DeliveryType;
    public PaymentMethod: PaymentMethod;
    public OrderType: OrderTypeEnum;
    public Client: number;
    public OrderCode: string;
    public TTN: string;
    public TTNCreateDate: any;
    
    public PrintStatus: number;
    public ProductionStatus: number;
    public LaserStatus: number;
    public AssemblingDate: any;

    public Product: any;

    public ProductId: number;

    public Lexicon: any;
    
    public LexiconId: number;
}