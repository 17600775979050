import { EntityBase } from "./entity.base";
import { Order } from "./order";
import { Partner } from "./partner";

export class Client extends EntityBase {
  public FullName: string = '';
  public PhoneNumber: string = '';
  public Email: string = '';
  public TotalOrdersCount: number = 0;
  public PartnerId: number = 0;
  public TotalOrdersAmount: number = 0;
  public Partner: Partner;
  public LastOrder: Order;
  public Rating: any;
  public Orders: Order[];
}