import * as React from "react";
import { OrderProduct } from "../../../business.entities/order.product";
import { ProductTypeEnum } from "../../../helpers/enums/product.type.enum";
import { bindActionCreators } from "redux";
import { ShowHoverBlock } from '../../../actions/right.view.actions'
import { connect } from "react-redux";

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ShowHoverBlock
}, dispatch);

const OrderProductCommon = (props: any) => {
    const product: OrderProduct = props.product;

    return (
        <div className="rVProductDefaultContainer">
            <div className="qtyWContainer ">
                <div className="value">{product.Quantity}</div>
                <div className="label">Кол-во</div>
            </div>

            <div className="brand">
                {product.Product.Category.Name}
            </div>

            <div className="model">
                {product.Product.SubCategory.Name}
            </div>

            <div className="lexiconContainer">
                <div className="lexiconWrapper">
                    <div className="lexiconItem">
                        <div className="lexLabel">Наименование: </div>
                        <div className="lexValue">
                            <span>{product.Product.Lexicon.OfficialPartnerName}</span>
                        </div>
                        {product.Color &&
                            <div className="lexValueColor">
                                <div className="colorBg" style={{ backgroundColor: product.Color.Code }} ></div>
                                <div className="colorBgLabel">({product.Color.Name})</div>
                            </div>
                        }
                    </div>
                    {product.BaseProduct &&
                        <div className="lexiconItem">
                            <div className="lexLabel">Основа: </div>
                            <div className="lexValue">
                                <span>{product.BaseProduct.Lexicon.OfficialPartnerName}</span>
                            </div>
                            {product.BaseColor &&
                                <div className="lexValueColor">
                                    <div className="colorBg" style={{ backgroundColor: product.BaseColor.Code }} ></div>
                                    <div className="colorBgLabel">({product.BaseColor.Name})</div>
                                </div>
                            }
                        </div>
                    }
                </div>

            </div>
            {product.Product.Lexicon.HasAdditionalOptions &&
                <div className="brandContainer ops__brandContainer order_product__common">
                    <div className="lexiconContainer">
                        <div className="lexiconWrapper">
                            <div className="lexiconItem">
                                <details open className="lexiconItem__DETAILS">
                                    <summary>Доп опции</summary>
                                    {
                                        product.OrderProductAdditionalOption.map(orderProductOption =>
                                            <div className="lexiconItem"
                                                onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(orderProductOption.AdditionalOption) }}
                                                onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}>
                                                <div className="lexLabel">{orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.OptionCategory && orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                <div className="lexValue">
                                                    <span>{orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.OptionCategory && orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.Name}</span>
                                                </div>
                                            </div>
                                        )
                                    }

                                </details>
                            </div>
                        </div>
                    </div>
                </div> 
            }
            <div className="lexiconContainer">
                {product.Product.Lexicon.ProductTypeId === ProductTypeEnum.Production
                    || product.Product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction ?

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Комментарий</div>
                        <div className="defaultFormControl__value">
                            <div className="imitation_input">
                                {product.ProductionComment}
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        </div>
    )
}
export default connect(null, mapDispatchToProps)(OrderProductCommon)