import { ADD_PARTNER, SUCCESS_ADD_PARTNER, FAILED_ADD_PARTNER } from "../actions/registration.actions";
import { RegistrationState, defaultRegistrationState } from "../states/registration.state";
import { RegistrationKnownActions } from '../actions/registration.actions';
import { Reducer } from "redux";


export const reducer: Reducer<RegistrationState> = (state: RegistrationState, action: RegistrationKnownActions) => {
    switch (action.type) {
        case ADD_PARTNER:
            return { ...state, isRegisrationInProgress: true };

        case SUCCESS_ADD_PARTNER:
            return { ...state, isRegisrationInProgress: false, isRegisteredCompleted: true };

        case FAILED_ADD_PARTNER:
            return { ...state, isRegisrationInProgress: false };

        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultRegistrationState;
}