export enum OrderStatusEnum {
    Confirmed = 1,
    Waiting,
    Cancel,
    NotAvailableWaiting,
    NotAvailableNotWaiting,
    Sended,
    OnTheWay,
    Delivered,
    Recived,
    Overdue,
    Refused,
    WaitingForReturn,
    Return, 
    Recycled
}

export const GetNameForOrderStatus = (orderStatus: OrderStatusEnum, isPartner: boolean = false) => {
    switch (orderStatus) {
      case OrderStatusEnum.Confirmed:
        return 'Оформлен';
      case OrderStatusEnum.Waiting:
        return 'В Ожидании';
      case OrderStatusEnum.Cancel:
        return 'Отменён';
      case OrderStatusEnum.NotAvailableWaiting:
        return 'Нет в наличии, согласен ждать';
      case OrderStatusEnum.NotAvailableNotWaiting:
        return 'Нет в наличии, не согласен ждать';
      case OrderStatusEnum.Sended:
        return 'Отправлен';
      case OrderStatusEnum.OnTheWay:
        return 'В пути';
      case OrderStatusEnum.Delivered:
        return 'Доставлен';
      case OrderStatusEnum.Recived:
        return 'Получен';
      case OrderStatusEnum.Overdue:
        return 'Не забранный';
      case OrderStatusEnum.Refused:
        return 'Отказ';
      case OrderStatusEnum.WaitingForReturn:
        return 'Ожидание возврата';
      case OrderStatusEnum.Return:
        return 'Возврата';
      case OrderStatusEnum.Recycled:
        return isPartner ? 'Не забранный' : 'Утилизирован';
      default:
        return '';
    }
  }

  export const AdministratorStatusList = [
    {
      value: OrderStatusEnum.Confirmed,
      label: 'Оформлен'
    },
    {
      value: OrderStatusEnum.Waiting,
      label: 'В ожидании'
    },
    {
      value: OrderStatusEnum.NotAvailableWaiting,
      label: 'Нет в наличии, согласен ждать'
    },
    {
      value: OrderStatusEnum.NotAvailableNotWaiting,
      label: 'Нет в наличии, не согласен ждать'
    },
    {
      value: OrderStatusEnum.Cancel,
      label: 'Отменен'
    },
    {
      value: OrderStatusEnum.Sended,
      label: 'Отправлен'
    },
    {
      value: OrderStatusEnum.OnTheWay,
      label: 'В пути'
    },
    {
      value: OrderStatusEnum.Delivered,
      label: 'Доставлен'
    },
    {
      value: OrderStatusEnum.Recived,
      label: 'Получен'
    },
    {
      value: OrderStatusEnum.Overdue,
      label: 'Не забранный'
    },
    {
      value: OrderStatusEnum.Refused,
      label: 'Отказ'
    },
    // {
    //   value: OrderStatusEnum.WaitingForReturn,
    //   label: 'Ожидание возврата'
    // },
    // {
    //   value: OrderStatusEnum.Return,
    //   label: 'Возврат'
    // },
    {
      value: OrderStatusEnum.Recycled,
      label: 'Утилизирован'
    },
  ];

export const ClientStatusList = [
    {
      value: OrderStatusEnum.Confirmed,
      label: 'Оформлен'
    },
    {
      value: OrderStatusEnum.Waiting,
      label: 'В ожидании'
    },
    {
      value: OrderStatusEnum.NotAvailableWaiting,
      label: 'Нет в наличии, согласен ждать'
    },
    {
      value: OrderStatusEnum.NotAvailableNotWaiting,
      label: 'Нет в наличии, не согласен ждать'
    },
    {
      value: OrderStatusEnum.Cancel,
      label: 'Отменен'
    },
    {
      value: OrderStatusEnum.Sended,
      label: 'Отправлен'
    },
    {
      value: OrderStatusEnum.OnTheWay,
      label: 'В пути'
    },
    {
      value: OrderStatusEnum.Delivered,
      label: 'Доставлен'
    },
    {
      value: OrderStatusEnum.Recived,
      label: 'Получен'
    },
    {
      value: OrderStatusEnum.Overdue,
      label: 'Не забранный'
    },
    {
      value: OrderStatusEnum.Refused,
      label: 'Отказ'
    },
    // {
    //   value: OrderStatusEnum.Recycled,
    //   label: 'Не забранный'
    // },
  ];
