import { connect } from "react-redux";
import { ApplicationState } from "../../../../reducers";
import * as React from "react";
import { Form } from "react-redux-form";
import { bindActionCreators } from "redux";
import { TextBoxWithLabel } from "../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../helpers/validator.helper";
import Select from 'react-select';
import * as productActions from '../../../../actions/stock/product.actions';

const BulkPriceUpdateForm = (props: any) => {

    return (
        <div>
            <Form
                validateOn='change'
                model="bulkPriceUpdateForm"
                onSubmit={() => {
                    props.UpdatePrices(props.product.selectedPriceCategoryValue ? props.product.selectedPriceCategoryValue.value : 0,
                        props.product.selectedPriceLexiconValue ? props.product.selectedPriceLexiconValue.value : 0,
                        props.bulkPriceUpdateForm.Price
                    )
                }}>
                <div className="default_form_component">

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Бренды</div>
                        <div className="selectWrapper__container notPadd">
                            <Select
                                name="category"
                                placeholder="Бренды"
                                options={props.product.categorySelectList}
                                onChange={props.ChangePriceCategorySelect}
                                value={props.product.selectedPriceCategoryValue} />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Лексикон</div>
                        <div className="selectWrapper__container notPadd">
                            <Select
                                name="lexicon"
                                placeholder="Лексикон"
                                options={props.product.lexiconSelectList}
                                onChange={props.ChangePriceLexiconSelect}
                                value={props.product.selectedPriceLexiconValue} />
                        </div>
                    </div>

                    <TextBoxWithLabel
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                            isPrice: (val: string) => Validator.IsPriceMatchToPattern(val)
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                            isPrice: "Цена может быть только число, которое > 0 и не начинаться с 0 или ."
                        }}
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Цена партнёра'
                        IsRequired={true}
                        Model='.Price' />

                    <div className="defaultFormControl">
                        <button type="submit" className="defaultFormButton">Сохранить</button>
                    </div>
                </div>
            </Form>

            <Form
                validateOn='change'
                model="bulkRecomendedPriceUpdateForm"
                onSubmit={() => {
                    props.UpdateRecomendedPrices(props.product.selectedPriceCategoryValue ? props.product.selectedPriceCategoryValue.value : 0,
                        props.product.selectedPriceLexiconValue ? props.product.selectedPriceLexiconValue.value : 0,
                        props.bulkRecomendedPriceUpdateForm.Price
                    )
                }}>
                <div className="default_form_component">

                    <TextBoxWithLabel
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                            isPrice: (val: string) => Validator.IsPriceMatchToPattern(val)
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                            isPrice: "Цена может быть только число, которое > 0 и не начинаться с 0 или ."
                        }}
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='РРЦ'
                        IsRequired={true}
                        Model='.Price' />

                    <div className="defaultFormControl">
                        <button type="submit" className="defaultFormButton">Сохранить</button>
                    </div>
                </div>
            </Form>
        </div >
    );
}
export default connect((state: any) => ({ product: state.stock.product, 
    bulkPriceUpdateForm: state.bulkPriceUpdateForm,
    bulkRecomendedPriceUpdateForm: state.bulkRecomendedPriceUpdateForm }),
    (dispatch) => bindActionCreators({
        ChangePriceCategorySelect: productActions.ChangePriceCategorySelect,
        ChangePriceLexiconSelect: productActions.ChangePriceLexiconSelect,
        UpdatePrices: productActions.BulkChangePrice,
        UpdateRecomendedPrices: productActions.UpdateRecomendedPrices
    }, dispatch))(BulkPriceUpdateForm)