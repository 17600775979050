//import Printer from '../../business.entities/printer';
import PrintList from '../../business.entities/PrintList';


export class PrinterState {
    fetchInProgress: boolean;
    NameList: any[];
    ChoiceOfChangeTypeList: any[];
    SelectionOfProductTypeList: any[];
    ChoiceOfUrgencyList: any[];
    LexiconSelectionList: any[];
    SelectionOfAdditionalServicesList: any[];
    PrintersList: PrintList
}

export const defaultPrinterState: PrinterState = {
    fetchInProgress: false,
    NameList: [],
    ChoiceOfChangeTypeList: [],
    SelectionOfProductTypeList: [],
    ChoiceOfUrgencyList: [],
    LexiconSelectionList: [],
    SelectionOfAdditionalServicesList: [],
    PrintersList: new PrintList()
};