import * as React from "react";
import { Route, NavLink } from "react-router-dom";
import { LoginForm } from "./login.form/login.form";
import { RegistrationForm } from "./registration.form/registration.form";

export const AuthenticationLayout = (props: any) => (
    <div className="authentication_layout_component">
        <div className="authentication_layout_header">
            <div className="logo"></div>
            <div className="header_nav_component">
                <NavLink
                    to={`${props.match.url}/login`}
                    activeClassName='active'>
                    Войти
                </NavLink>

                <span>или</span>

                <NavLink
                    to={`${props.match.url}/register`}
                    activeClassName='active'>
                    Стать партнером
                </NavLink>
            </div>
        </div>

        <div className="authentication_layout_content">
            <Route path={`${props.match.url}/login`} component={LoginForm} />
        </div>

        <div className="authentication_layout_reg_content">
            <Route path={`${props.match.url}/register`} component={RegistrationForm} />
        </div>
    </div >
);