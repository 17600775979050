import { Lexicon } from "../../business.entities/lexicon";

export class LexiconState {
    SelectedLexicon: Lexicon;
    lexicons: Lexicon[];
    totalPages: number;
    fetching: boolean
}

export const defaultLexiconState: LexiconState = {
    SelectedLexicon: null,
    lexicons: [],
    totalPages: 0,
    fetching: false
}