import * as React from 'react';
import Product from '../../../../../../business.entities/product';
import ProductColor from '../../../../../../business.entities/product.color';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    ConfirmOrderProductSelection,
    ConfirmProductColorSelect,
    SelectCurrentProductColor,
    SetNextSubStep,
    SetProductionCommentForOrderProduct
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { GetOrderProductStepComponent } from '../../../../../../helpers/enums/order.product.steps';
import { List } from 'linqts';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { OrderBaseState } from '../../../../../../states/sales/order.base.state';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';
import { ProductPriceListItem } from '../../../../../../business.entities/product.price.list.item';
import { WizardState } from '../../../../../../states/sales/wizard.state';
import { ProductTypeEnum } from '../../../../../../helpers/enums/product.type.enum';
import * as API from '../../../../../../constants/api.constants';
import { ShowHoverBlock } from "../../../../../../actions/right.view.actions";

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectCurrentProductColor,
    ConfirmProductColorSelect,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SetProductionCommentForOrderProduct,
    ShowHoverBlock
}, dispatch);

const Color = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;
    document.onkeydown = (e) => {

        if ((event as any).keyCode == 38) {
            event.preventDefault();

            OnSelectTopColorCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();

            OnSelectBottomColorCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();

            OnConfirmColorSelection();
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct.Color) {
                // if (IsProduction(orderProductUpdate.currentProduct)) {
                //     orderProductUpdate.currentOrderProduct.ProductionComment && OnGoToNextOrderCardStepCommand(props);
                // } else {
                    OnGoToNextOrderCardStepCommand(props);
                // }

                props.ShowHoverBlock(null);
            }
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopColorCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentProductColor) {
            const productColor = new List(orderProductUpdate.currentProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === orderProductUpdate.currentProductColor.Id)

            startIndex = orderProductUpdate.currentProduct.ProductColors.indexOf(productColor);
            startIndex != 0 ? startIndex-- : startIndex = orderProductUpdate.currentProduct.ProductColors.length - 1;
        }

        props.SelectCurrentProductColor(orderProductUpdate.currentProduct.ProductColors[startIndex].Color);
    }

    const OnSelectBottomColorCommand = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentProductColor) {
            const productColor = new List(orderProductUpdate.currentProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === orderProductUpdate.currentProductColor.Id)

            startIndex = orderProductUpdate.currentProduct.ProductColors.indexOf(productColor);
            startIndex != orderProductUpdate.currentProduct.ProductColors.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentProductColor(orderProductUpdate.currentProduct.ProductColors[startIndex].Color);
    }

    const OnConfirmColorSelection = () => {
        if (orderProductUpdate.currentProductColor) {
            props.ConfirmProductColorSelect();

            // if (IsProduction(orderProductUpdate.currentProduct)) {
            //     orderProductUpdate.currentOrderProduct.ProductionComment && OnGoToNextOrderCardStepCommand(props);
            // } else {
                OnGoToNextOrderCardStepCommand(props);
            // }
        }

        props.ShowHoverBlock(null);
    }

    const OnSelectProductColorByMouse = (index) => {
        props.SelectCurrentProductColor(orderProductUpdate.currentProduct.ProductColors[index].Color);

        props.ConfirmProductColorSelect();

        const newProps = Object.assign({},
            props,
            {
                OrderCard: Object.assign({}, props.OrderCard, {
                    productUpdateSubState: Object.assign({}, orderProductUpdate, {
                        currentProductColor: orderProductUpdate.currentProduct.ProductColors[index].Color
                    } as ProductUpdateState),
                } as OrderCardState)
            });

        // if (IsProduction(orderProductUpdate.currentProduct)) {
        //     orderProductUpdate.currentOrderProduct.ProductionComment && OnGoToNextOrderCardStepCommand(newProps);
        // } else {
            OnGoToNextOrderCardStepCommand(newProps);
        // }

        props.ShowHoverBlock(null);
    }

    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return true;

        return false;
    }

    return (
        <div className="colorContainer__content">
            {IsProduction(orderProductUpdate.currentProduct) ?
                <div className="colorComennt__container">
                    <div className="colorContainer__contentLabel">Комментарий</div>
                    <div className={`colorComennt__ValueContainer`}>
                    {/* ${!orderProductUpdate.currentOrderProduct.ProductionComment ? 'isError' : ''} */}
                        <textarea value={orderProductUpdate.currentOrderProduct.ProductionComment}
                            autoFocus={true}
                            onChange={(event) => props.SetProductionCommentForOrderProduct(event.target.value)} />
                    </div>
                </div>
                : null}

            <div className="colorContainer__contentLabel">{orderProductUpdate.currentProduct.Lexicon.HasColors ? 'Цвет товара' : 'Опция товара'}</div>
            <div className={`colorContainer__contentWrapper content_scroll`}>
            {/* ${IsProduction(orderProductUpdate.currentProduct) && !orderProductUpdate.currentOrderProduct.ProductionComment ? 'isDisabled' : ''} */}
                {orderProductUpdate.currentProduct.ProductColors.length > 0 && orderProductUpdate.currentProduct.ProductColors.map((productColor, index) => {
                    return <div key={index}
                        className={`productColor__item  ${orderProductUpdate.currentProductColor && (productColor as ProductColor).Color.Id === orderProductUpdate.currentProductColor.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectProductColorByMouse(index)}>
                        {orderProductUpdate.currentProduct.Lexicon.HasColors ?
                            <>
                                <div className={`bgItem_container ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                                    style={{ backgroundColor: productColor.Color.Code }} >
                                    <div className="qty">
                                        {!IsProduction(orderProductUpdate.currentProduct) ? (productColor as ProductColor).Quantity : null}
                                    </div>
                                </div>
                                <div className="name">
                                    {(productColor as ProductColor).Color.Name}
                                </div>
                            </>
                            : null}
                        {orderProductUpdate.currentProduct.Lexicon.HasOptions ?
                            <>
                                <div className={`bgItem_container imgThumb__WRAPPER`}
                                    style={{ backgroundImage: `url(${API.CRM_URL + '/' + (productColor as ProductColor).Color.Thumb})` }}
                                    onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(productColor.Color) }}
                                    onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                >
                                    <div className="qty">
                                        {(productColor as ProductColor).Quantity}
                                    </div>
                                </div>

                                <div className="name">
                                    {(productColor as ProductColor).Color.Name}
                                </div>
                            </>
                            : null}
                    </div>
                })}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Color)