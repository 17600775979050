import * as React from "react";
import { LoginFormInput } from "../common/login.form.input";
import { LoginFormButton } from "../common/login.form.button";
import { LoginState } from "../../../states/login.state";
import * as ActionCreators from '../../../actions/login.actions'
import { connect } from "react-redux";
import { ApplicationState } from "../../../Reducers/index";
import * as PropTypes from "prop-types";


type AuthenticationProps =
    LoginState
    & typeof ActionCreators;

export namespace LoginForm {
    export interface IState {
        login: string;
        password: string;
    }
}

// @connect((state: ApplicationState) => state, ActionCreators as any)
class LoginFormComponent extends React.Component<AuthenticationProps, LoginForm.IState>{
    static contextTypes = {
        store: PropTypes.object
    }

    constructor(props) {
        super(props);

        document.onkeydown = (e) => {
            if ((event as any).keyCode == 13) {
                event.preventDefault();
                this.onLoginCommand();
            }
        }
    }

    componentWillUnmount(){
        document.onkeydown = null;
    }

    public onLoginCommand(): void {
    this.props.Login(this.state.login, this.state.password);
}

    public onChangeLoginInput(event: any): void {
    this.setState({ login: event.target.value });
}

    public onChangePasswordInput(event: any): void {
    this.setState({ password: event.target.value });
}

    public render(): JSX.Element {
    return (
        <div className="login_form_component">
            <form>
                <LoginFormInput
                    onChangeInput={this.onChangeLoginInput.bind(this)}
                    placeholder={"Login"}
                />

                <LoginFormInput
                    isPassword
                    onChangeInput={this.onChangePasswordInput.bind(this)}
                    placeholder={"Password"}
                />

                <LoginFormButton
                    onClickCommand={this.onLoginCommand.bind(this)}
                />
                <div className="forgot_password_control">Забыли пароль? Свяжитесь с координатором</div>
            </form>
        </div>
    );
}
}

export const LoginForm = connect((state: ApplicationState) => state, ActionCreators as any)(LoginFormComponent);
