import { MutualSettlementsState, DefaultMutualSettlementsState } from "../../states/cabinet/mutual.settlements.state";
import { MutualSettlementsKnownActions } from "../../actions/cabinet/mutual.settlements.actions";
import * as mutualSettlementsActionTypes from "../../actions/cabinet/mutual.settlements.actions";

export default function (state: MutualSettlementsState, action: MutualSettlementsKnownActions) {
  switch (action.type) {
    case mutualSettlementsActionTypes.SET_ALL_PARTNERS:
      return Object.assign({}, state, { AllPartners: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_PARTNER_SELECT:
      return Object.assign(state, {
        SelectedPartner: action.payload,
        Page: 1,
        Count: 25,
        AllOrders: DefaultMutualSettlementsState.AllOrders,
        SelectedOrder: DefaultMutualSettlementsState.SelectedOrder
      } as MutualSettlementsState);

    case mutualSettlementsActionTypes.SELECT_OPERATION_TYPE:
      return Object.assign({}, state, { SelectedOperationType: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.GET_MUTUALSETTLEMENTS:
      return Object.assign({}, state, { Fetching: true });

    case mutualSettlementsActionTypes.SET_ALL_MUTUALSETTLEMENTS:
      return Object.assign({}, state, { MutualSettlements: action.payload.mutualSettlements, TotalPages: action.payload.count, Fetching: false } as MutualSettlementsState);

    case mutualSettlementsActionTypes.SET_BALANCE:
      return Object.assign({}, state, { Balance: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.SET_ORDERS_AUTOCOMPLETE:
      return Object.assign({}, state, { AllOrders: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_ORDER_SELECT:
      return Object.assign({}, state, { SelectedOrder: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_PAGE:
      return Object.assign({}, state, { Page: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_LIMIT:
      return Object.assign({}, state, { Count: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.SET_ORDERS_AUTOCOMPLETE_FILTER:
      return Object.assign({}, state, { AllFilterOrders: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_OPERATION:
      return Object.assign({}, state, { Operation: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_ORDER:
      return Object.assign({}, state, { Order: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_START_DATE:
      return Object.assign({}, state, { StartDate: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CLEAR_FILTER:
      return Object.assign({}, state, {
        StartDate: null,
        EndDate: null,
        Order: null,
        Operation: null
      } as MutualSettlementsState);

    case mutualSettlementsActionTypes.CHANGE_END_DATE:
      return Object.assign({}, state, { EndDate: action.payload } as MutualSettlementsState);

    case mutualSettlementsActionTypes.UNMOUNT_COMPONENT:
      return Object.assign({}, DefaultMutualSettlementsState);

    case mutualSettlementsActionTypes.SET_FILTERED:
      return Object.assign({}, state, { IsFiltered: action.payload })

    case mutualSettlementsActionTypes.EXPORT_MUTUAL_SETTLEMENTS:
      return Object.assign({}, state, { Fetching: true })

    case mutualSettlementsActionTypes.EXPORT_MUTUAL_SETTLEMENTS_SUCCESS:
      return Object.assign({}, state, { ExportFileName: action.payload, Fetching: false })

    case mutualSettlementsActionTypes.SET_AFTERPAYMENTS_IMPORT_FILE:
      return Object.assign({}, state, { FileForImportAfterpayments: action.payload })

    case mutualSettlementsActionTypes.SET_AFTERPAYMENTS_IMPORT_FILE_NAME:
      return Object.assign({}, state, { FileNameForImportAfterpayments: action.payload })

    case mutualSettlementsActionTypes.LOAD_AFTERPAYMENTS_FILE_SUCCESS:
      return Object.assign({}, state, { FileNameForImportAfterpayments: "", FileForImportAfterpayments: null})

    default:
      const exhaustiveCheck: any = action;
  }
  return state || DefaultMutualSettlementsState;
} 