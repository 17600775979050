import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as React from 'react';
import { Form, actions } from 'react-redux-form';
import { TimePicker, DatePicker } from 'material-ui';
import { TextBoxWithLabel } from '../../../shared/form.controls/textbox.with.label';
import { Validator } from '../../../../helpers/validator.helper';
import { ApplicationState } from "../../../../reducers";
import * as moment from 'moment';
import { MutualSettlement } from "../../../../business.entities/mutual.settlement";
import { MutualSettlementOperationType } from "../../../../helpers/enums/mutualsettlement.operation.type";
import * as mutualSettlementAction from "../../../../actions/cabinet/mutual.settlements.actions";
import { MutualSettlementsState } from "../../../../states/cabinet/mutual.settlements.state";

const MutualSettlementsReplenishmentOfBalanceForm = (props: any) => {

    const AddMutualSettlement = (form: any) => {
        let preparedDate = form.Date.startOf('day').add(form.Time.get('hour'), 'hour').add(form.Time.get('minute'), 'minute')
        .format();

        let mutualSettlementToAdd = new MutualSettlement();

        mutualSettlementToAdd.Amount = form.Amount;
        mutualSettlementToAdd.Comment = form.Comment;
        mutualSettlementToAdd.OperationType = MutualSettlementOperationType.BalanceReplenishment;
        mutualSettlementToAdd.PartnerId = (props.MutualSettlements as MutualSettlementsState).SelectedPartner.partnerId;
        mutualSettlementToAdd.PaymentDate = preparedDate;
        props.Add(mutualSettlementToAdd);
    }

    return (
        <Form
            validateOn='change'
            model="mutualSettlementsReplenishmentOfBalanceForm"
            onSubmit={(form) => AddMutualSettlement(form)}
            className="mutualSettlementsReplenishmentOfBalanceForm"
        >
            <div className="default_form_component shadow_controls">
                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val.toString()),
                        isPrice: (val: number) => Validator.IsPriceMatchToPattern(val)
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        isPrice: 'Неверная сума'
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Сумма'
                    IsRequired
                    Model='.Amount' />

                <TextBoxWithLabel
                    Validators={{
                    }}
                    ErrorMessages={{
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Комментарий'
                    IsRequired={false}
                    Model='.Comment' />

                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">Дата</div>
                    <div className="dateTimeWrapper__container">
                        <DatePicker
                            floatingLabelText="Date"
                            value={props.Form.Date ? props.Form.Date.toDate() : props.Form.Date}
                            onChange={(event, date: any) => {
                                props.Change("mutualSettlementsReplenishmentOfBalanceForm.Date", moment(date));
                            }}
                        />
                    </div>
                </div>
                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">Время</div>
                    <div className="dateTimeWrapper__container">
                        <TimePicker
                            floatingLabelText="Time"
                            format="24hr"
                            value={props.Form.Time ? props.Form.Time.toDate() : props.Form.Time}
                            onChange={(event, time) => {
                                props.Change("mutualSettlementsReplenishmentOfBalanceForm.Time", moment(time));
                            }}
                        />
                    </div>
                </div>

                <div className="defaultFormControl">
                    <button type="submit" className="defaultFormButton">Сохранить</button>
                </div>
            </div>
        </Form>
    );
};

export default connect(
    (state: any) => ({
        Form: state.mutualSettlementsReplenishmentOfBalanceForm,
        MutualSettlements: state.cabinet.mutualSettlements
    }),
    (dispatch) => {
        return bindActionCreators({
            Add: mutualSettlementAction.AddMutualSettlements,
            Change: actions.change
        }, dispatch)
    })(MutualSettlementsReplenishmentOfBalanceForm);