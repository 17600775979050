import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import  DateTimeFilter from './filters';
import { PrintingStatisticsState } from '../../../../states/cabinet/print.stats.state';
import * as actions from '../../../../actions/cabinet/print.statistics.actions';
import * as moment from 'moment';
import { dispatch } from 'rxjs/internal-compatibility';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';
import { Loader } from '../../../shared/loader';


interface IPrintingStatisticsProps {
    PrintingStatistics: PrintingStatisticsState,
    SetStartDate(date);
    SetEndDate(date);
    SetStartTime(time);
    SetEndTime(time);
    GetPrintStatistics(startDate, endDate);
    SetFetching(isFetching);
}

function mapStateToProps(state) {
    return {
        Authorization: state.authentication,
        PrintingStatistics: state.cabinet.printStatistics
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetPrintStatistics: actions.GetPrintStatistics,
        SetFetching: actions.SetFetching
    }, dispatch)
}


class PrintingStatistics extends React.Component<any, any>{

    constructor(props) {
        super(props);
        let startDate = moment(new Date('0001-01-01T00:00:00')).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
        let endDate = moment(new Date('0001-01-01T00:00:00')).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
        props.SetFetching(true);
        props.GetPrintStatistics(startDate, endDate)
    }

    render() {
        return (
            <div className="cMutualSettlements__container2 rcPagination__component">

                <LeftMenuMobileControl />
                <DateTimeFilter/>
                <div className="content_header_component">
                <div className="page_title">
                    <span className="title_default">Статистика</span>
                    <span className="title_description"></span>
                </div>
            </div>
            <div className="content_component statisticsContent__CONTAINER">
            {this.props.PrintingStatistics.Fetching ? <Loader /> :
            
            <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content">{userPrintingStatuses.OperatorName}</span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
                
                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Ожидает сборки"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfWaitingForAssembly}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Готов к печати"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfWaitingForPrinting}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Вопрос по картинке"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfIssueWithImage}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Брак"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfFailed}
                                        </span>
                                    </div>
                                )
                            })}
                            
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Напечатан"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfPrinted}
                                        </span>
                                    </div>
                                )
                            })}
                            
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Ворпос по картинке"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfWaitingForPrintingToIssueWithImage}
                                        </span>
                                    </div>
                                )
                            })}

                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Брак"
                                </span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfWaitingForPrintingToFailedByPrinter}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Напечатан"</span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfWaitingForPrintingToPrinted}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Брак" в "Напечатан"</span>
                            </div>

                            {this.props.PrintingStatistics.PrintingStatusesByUsers.map(userPrintingStatuses => {
                                return (
                                    <div className="grid_cell cell__GRID_CONT">
                                        <span className="cell_content__GC">
                                        {userPrintingStatuses.CountOfFailedToPrinted}
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
        </div>
            </div>
        )
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PrintingStatistics);