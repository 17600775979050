import Partner from "../../business.entities/partner";
import { MutualSettlement } from "../../business.entities/mutual.settlement";
import { Order } from "../../business.entities/order";
import { MutualSettlementOperationType, GetMutaualSettlementName } from "../../helpers/enums/mutualsettlement.operation.type";

export class MutualSettlementsState {
  public AllPartners: any[];
  public SelectedPartner: any;
  public SelectedOperationType: any;
  public MutualSettlements: MutualSettlement[];
  public Balance: number;
  public AllOrders: any[];
  public SelectedOrder: any;
  public Page: number;
  public Count: number;
  public TotalPages: number;
  public Fetching: boolean;
  public AllFilterOrders: any[];
  public StartDate: Date;
  public EndDate: Date;
  public Order: any;
  public Orders: any;
  public Operation: MutualSettlementOperationType;
  public Operations: any;
  public IsFiltered: boolean;
  public ExportFileName: string;
  public FileNameForImportAfterpayments: string;
  public FileForImportAfterpayments: any;
}

export const DefaultMutualSettlementsState = {
  AllPartners: [],
  SelectedPartner: null,
  SelectedOperationType: null,
  MutualSettlements: [],
  Balance: 0,
  AllOrders: [],
  SelectedOrder: null,
  Page: 1,
  Count: 25,
  TotalPages: 0,
  Fetching: false,
  Operations: [
    {value: MutualSettlementOperationType.CreateOrder, label: GetMutaualSettlementName(MutualSettlementOperationType.CreateOrder) },
    {value: MutualSettlementOperationType.CancelOrder, label: GetMutaualSettlementName(MutualSettlementOperationType.CancelOrder) },
    {value: MutualSettlementOperationType.BalanceReplenishment, label: GetMutaualSettlementName(MutualSettlementOperationType.BalanceReplenishment) },
    {value: MutualSettlementOperationType.Refunds, label: GetMutaualSettlementName(MutualSettlementOperationType.Refunds) },
    // {value: MutualSettlementOperationType.DeliveryPayment, label: GetMutaualSettlementName(MutualSettlementOperationType.DeliveryPayment) },
    {value: MutualSettlementOperationType.ChangeOrderedProducts, label: GetMutaualSettlementName(MutualSettlementOperationType.ChangeOrderedProducts) },
    {value: MutualSettlementOperationType.AddedDiscount, label: GetMutaualSettlementName(MutualSettlementOperationType.AddedDiscount) },
    {value: MutualSettlementOperationType.RemoveDiscount, label: GetMutaualSettlementName(MutualSettlementOperationType.RemoveDiscount) },
    {value: MutualSettlementOperationType.FundsWithdrawal, label: GetMutaualSettlementName(MutualSettlementOperationType.FundsWithdrawal) },
    {value: MutualSettlementOperationType.ConstructorPayment, label: GetMutaualSettlementName(MutualSettlementOperationType.ConstructorPayment) },
  ],
  AllFilterOrders: [],
  StartDate: null,
  EndDate: null,
  Order: null,
  Operation: null,
  Orders: [],
  IsFiltered: false,
  ExportFileName: '',
  FileNameForImportAfterpayments: '',
  FileForImportAfterpayments: null
};