import * as React from "react";
import { ApplicationState } from "../../../../Reducers/index";
import { bindActionCreators } from "redux";
import * as rightViewActions from "../../../../actions/right.view.actions";
import { actions } from "react-redux-form";
import { PartnerWholesale } from "../../../../business.entities/partnerWholesale";
import { RightViewState } from "../../../../states/right.view.state";
import { connect } from "react-redux";
import { default as PartnerWholesaleForm } from "./partner.wholesale.form";

export interface IPartnerWholesaleTerms {
    SelectedWholesale: PartnerWholesale;
    WholesaleList: PartnerWholesale[];
    OpenRV: (rvData: any) => any;
    CloseRv: () => any;
    ChangeForm: (model: string, value: any) => any;
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        SelectedWholesale: state.stock.price.selectedWholesale,
        WholesaleList: state.stock.price.wholesaleList
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        OpenRV: rightViewActions.SelectRVData,
        CloseRv: rightViewActions.RemoveRVData,
        ChangeForm: actions.change
    }, dispatch);
};

const PartnerWholesaleTerms = (props: IPartnerWholesaleTerms) => {

    const selectWholesale = (wholesale: PartnerWholesale) => {
        
        props.ChangeForm("wholesaleForm", wholesale);

        let rvState = new RightViewState();
        rvState.Title = "Редактирование";
        rvState.Component = PartnerWholesaleForm;
        rvState.CloseFunction = props.CloseRv;
        
        props.OpenRV(rvState);
    }

    return (
        <div className="grid_component">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell">
                                <span className="cell_content">Лексикон</span>
                            </div>
                            <div className="grid_cell partner_cell">
                                <span className="cell_content">Условие</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        {
                            props.WholesaleList && props.WholesaleList.map((wholesale, index) => {
                                return (
                                    <div key={index} className="grid_row mMEDIA___partnerWholesaleTerms_ROW" onClick={() => selectWholesale(wholesale)}>
                                        <div className="grid_cell partnerWholesaleTerms__NAME_CELL" data-tooltip="Лексикон">
                                            <span className="cell_content">{wholesale.Lexicon.CasualName}</span>
                                        </div>
                                        <div className="grid_cell partnerWholesaleTerms__Count_CELL partner_cell" data-tooltip="Условие">
                                            <span className="cell_content">{wholesale.Count}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerWholesaleTerms);