export enum PrintVerificationStatusEnum {
    Passed = 1,
    Failed
}

export const GetPrintVerificationStatusName = (status: PrintVerificationStatusEnum) => {
    switch (status) {
        case PrintVerificationStatusEnum.Passed:
            return 'Пройдена';
        case PrintVerificationStatusEnum.Failed:
            return 'Не пройдена';
        default:
            return '';
    }


}