import * as React from "react";
import { connect } from "react-redux";
import { Form } from "react-redux-form";
import { TextBoxWithLabel } from "../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../helpers/validator.helper";
import { ProductPriceListItem } from "../../../../business.entities/product.price.list.item";
import { PriceListLexiconPriceItem } from "../../../../business.entities/price.list.lexicon.price.item";
import { PriceListProductPriceItem } from "../../../../business.entities/price.list.product.price.item";
import { List } from "linqts";
import { Lexicon } from "../../../../business.entities/lexicon";
import { PriceListState } from "../../../../states/stock/price.list.state";
import { AddOptions, AddProductPrices, AddLexiconPrices, UpdateOptions } from "../../../../actions/stock/price.list.options.actions";
import { bindActionCreators } from "redux";
import { PricelistOptions } from "../../../../business.entities/price.list.options";
import { LoginState } from "../../../../states/login.state";

export interface IPricelistRecomendedPriceForm {
    PricelistItemForm: ProductPriceListItem;
    PricelistLexiconPriceForm: PriceListLexiconPriceItem[];
    PricelistState: PriceListState;
    AuthenticationState: LoginState;

    AddOptions(priceListOptions: PricelistOptions);
    AddProductPrices(priceList: PriceListProductPriceItem[]);
    AddLexiconPrices(lexiconPrices: PriceListLexiconPriceItem[]);
    UpdateOptions(priceListOptions: PricelistOptions)
}

export const PricelistRecomendedPriceForm = (props: IPricelistRecomendedPriceForm) => {
    const onSavePrice = (priceListItem: ProductPriceListItem) => {
        let productPriceList;

        if (props.PricelistState.ProductPriceItems) {
            if (new List(props.PricelistState.ProductPriceItems).Any(c => c.ProductId === priceListItem.ProductId)) {
                let productPriceItem = new List(props.PricelistState.ProductPriceItems)
                    .FirstOrDefault(c => c.ProductId === priceListItem.ProductId);
                let index = props.PricelistState.ProductPriceItems.indexOf(productPriceItem);

                productPriceList = Object.assign([], props.PricelistState.ProductPriceItems, {
                    [index]: { ProductId: productPriceItem.ProductId, Price: priceListItem.RecomendedPartnerPrice }
                });

                props.AddProductPrices(productPriceList);
            } else {
                productPriceList = Object.assign([], props.PricelistState.ProductPriceItems);
                productPriceList.push({ ProductId: priceListItem.ProductId, Price: priceListItem.RecomendedPartnerPrice })

                props.AddProductPrices(productPriceList);
            }
        } else {
            productPriceList = Object.assign([], props.PricelistState.ProductPriceItems, {
                [0]: { ProductId: priceListItem.ProductId, Price: priceListItem.RecomendedPartnerPrice }
            })

            props.AddProductPrices(productPriceList);
        }

        if (props.PricelistState.Options) {
            props.PricelistState.Options.ProductPrices = JSON.stringify(productPriceList);
            props.UpdateOptions(props.PricelistState.Options);
        } else {
            let options = new PricelistOptions();
            options.PartnerId = props.AuthenticationState.userInfo.Id;
            options.PartnerNetId = props.AuthenticationState.userInfo.NetUid;
            options.ProductPrices = JSON.stringify(productPriceList);

            props.AddOptions(options);
        }
    }

    const onSaveLexiconPrices = (lexionPrices: PriceListLexiconPriceItem[]) => {
        let preparedLexiconPrices = new List(lexionPrices).Where(c => c.Price > 0).ToArray();
        props.AddLexiconPrices(preparedLexiconPrices);

        if (props.PricelistState.Options) {
            props.PricelistState.Options.LexiconPrices = JSON.stringify(preparedLexiconPrices);
            props.UpdateOptions(props.PricelistState.Options);
        } else {
            let options = new PricelistOptions();
            options.PartnerId = props.AuthenticationState.userInfo.Id;
            options.PartnerNetId = props.AuthenticationState.userInfo.NetUid;
            options.LexiconPrices = JSON.stringify(preparedLexiconPrices);

            props.AddOptions(options);
        }
    }

    const getLexionName = (lexionId: number) => {
        return new List<Lexicon>(props.PricelistState.Lexicons).FirstOrDefault((c: Lexicon) => c.Id === lexionId).OfficialPartnerName;
    }

    return (
        <div className='default_form_component shadow_controls'>
            <Form
                validateOn='change'
                model="pricelistItemForm"
                onSubmit={onSavePrice}
                className="display_table"
            >

                <div className="form__sub_title">
                    Изменить РРЦ на {props.PricelistItemForm.LexiconOfficialName} для {props.PricelistItemForm.CategoryName} {props.PricelistItemForm.SubCategoryName}
                </div>

                <TextBoxWithLabel
                    Validators={{
                        isNumeric: (val: string) => Validator.IsNumeric(val),
                    }}
                    ErrorMessages={{
                        isNumeric: 'Поле должно сожержать только цыфри'
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='РРЦ'
                    IsRequired={true}
                    Model='.RecomendedPartnerPrice'
                />

                <div className="controls mb__25">
                    <button type="submit" className="button_control">Сохранить</button>
                </div>
            </Form>

            <Form
                validateOn='change'
                model="pricelistLexiconPriceForm"
                onSubmit={onSaveLexiconPrices}
                className="display_table"
            >
                <div className="form__sub_title">
                    Изменить РРЦ для всех моделей на выбраный тип товара
                </div>
                {
                    props.PricelistLexiconPriceForm && props.PricelistLexiconPriceForm.map((item, key) =>
                        <TextBoxWithLabel
                            key={key}
                            Validators={{
                                isNumeric: (val: string) => Validator.IsNumeric(val),
                            }}
                            ErrorMessages={{
                                isNumeric: 'Поле должно сожержать только цыфри'
                            }}
                            ShowErrorOn='touched'
                            Type='text'
                            ValidatedOn='change'
                            LabelMessage={`${getLexionName(item.LexiconId)}`}
                            Model={`[${key}].Price`}
                        />
                    )
                }

                <div className="controls">
                    <button type="submit" className="button_control">Сохранить</button>
                </div>
            </Form>
        </div>
    );
}

export default connect(state => ({
    PricelistItemForm: (state as any).pricelistItemForm,
    PricelistLexiconPriceForm: (state as any).pricelistLexiconPriceForm,
    PricelistState: (state as any).stock.priceList,
    AuthenticationState: (state as any).authentication
}), (dispatch) => bindActionCreators({
    AddOptions,
    AddProductPrices,
    AddLexiconPrices,
    UpdateOptions
}, dispatch))(PricelistRecomendedPriceForm);