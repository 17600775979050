import * as React from "react";

export class LeftMenuMobileControl extends React.Component<any, any> {
    constructor(props) {
        super(props);

        (this as any).state = {
            isOpen: true
        }
    }

    render() {
        return (
            <div id="left_menuCONTROL_component__ID" className={`left_menuCONTROL_component ${(this as any).state.isOpen ? ' isMOB' : ''}`} onClick={() => (this as any).setState({ isOpen: !(this as any).state.isOpen })}>
                {/* <div className="cName__CONTENT hide">Развернуть меню</div> */}
                {(this as any).state.isOpen ? <div className="mscARROW cl"></div> : <div className="mscARROW "></div>}
                <div className="cName__CONTENT ">
                    {(this as any).state.isOpen ? 'Свернуть меню' : 'Развернуть меню'}
                </div>
            </div>
        )
    }
} 