import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    ConfirmOrderProductSelection
} from '../../../../../actions/sales/wizard.actions';
import { connect } from 'react-redux';
import { OnGoToPrevStepCommand, OnGoToNextStepCommand } from '../../../../../helpers/wizard.substep.helper';
import { List } from 'linqts';
import ProductColor from '../../../../../business.entities/product.color';
import { WizardState } from '../../../../../states/sales/wizard.state';

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseColor,
    ConfirmBaseColorSelect,
    ConfirmOrderProductSelection
}, dispatch);

const BaseColor = (props: any) => {

    document.onkeydown = (e) => {
        if ((event as any).keyCode == 38) {
            event.preventDefault();

            OnSelectTopColorCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();

            OnSelectBottomColorCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();

            OnConfirmColorSelection();
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            (props.Wizard as WizardState).currentOrderProduct.BaseColor &&
                OnGoToNextStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopColorCommand = () => {
        let startIndex = 0;

        if (props.Wizard.currentBaseColor) {
            const productColor = new List(props.Wizard.currentBaseProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === props.Wizard.currentBaseColor.Id)

            startIndex = props.Wizard.currentBaseProduct.ProductColors.indexOf(productColor);
            startIndex != 0 ? startIndex-- : startIndex = props.Wizard.currentBaseProduct.ProductColors.length - 1;
        }

        props.SelectCurrentBaseColor(props.Wizard.currentBaseProduct.ProductColors[startIndex].Color);
    }

    const OnSelectBottomColorCommand = () => {
        let startIndex = 0;

        if (props.Wizard.currentBaseColor) {
            const productColor = new List(props.Wizard.currentBaseProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === props.Wizard.currentBaseColor.Id)

            startIndex = props.Wizard.currentBaseProduct.ProductColors.indexOf(productColor);
            startIndex != props.Wizard.currentBaseProduct.ProductColors.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentBaseColor(props.Wizard.currentBaseProduct.ProductColors[startIndex].Color);
    }

    const OnConfirmColorSelection = () => {
        if (props.Wizard.currentBaseColor) {
            props.ConfirmBaseColorSelect();

            OnGoToNextStepCommand(props);
        }
    }

    const OnSelectProductColorByMouse = (index) => {
        props.SelectCurrentBaseColor(props.Wizard.currentBaseProduct.ProductColors[index].Color);

        props.ConfirmBaseColorSelect();

        OnGoToNextStepCommand(props);
    }

    return (
        <div className="colorContainer__content">
            <div className="colorContainer__contentLabel">Цвет основы</div>
            <div className="colorContainer__contentWrapper content_scroll">
                {props.Wizard.currentBaseProduct.ProductColors.length > 0 && props.Wizard.currentBaseProduct.ProductColors.map((productColor, index) => {
                    return <div key={index}
                        className={`productColor__item  ${(productColor as ProductColor).Color.Id === props.Wizard.currentBaseColor.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectProductColorByMouse(index)}>
                        <div className={`bgItem_container ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                            style={{ backgroundColor: productColor.Color.Code }} >
                            <div className="qty">
                                {(productColor as ProductColor).Quantity}
                            </div>
                        </div>
                        <div className="name">
                            {(productColor as ProductColor).Color.Name}
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseColor)