import { Partner } from "../business.entities/partner";

export const ADD_PARTNER: string = '[API] ADD_PARTNER';
export interface PartnerRegistration { type: 'PARTNER_REGISTRATION'; payload: any; }
export const PartnerRegistration = (partner: Partner) =>
    <PartnerRegistration>{ type: ADD_PARTNER, payload: partner };

export const SUCCESS_ADD_PARTNER: string = 'SUCCESS_ADD_PARTNER';
export interface SuccessRegistration { type: 'SUCCESS_PARTNER_REGISTRATION'; payload: any; }
export const SuccessRegistration = () =>
    <SuccessRegistration>{ type: SUCCESS_ADD_PARTNER };

export const FAILED_ADD_PARTNER: string = 'FAILED_ADD_PARTNER';
export interface FailedRegistration { type: 'FAILED_PARTNER_REGISTRATION'; payload: any; }
export const FailedRegistration = () =>
    <FailedRegistration>{ type: FAILED_ADD_PARTNER };

export type RegistrationKnownActions = PartnerRegistration | SuccessRegistration | FailedRegistration;