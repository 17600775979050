import * as React from 'react';
import Select from 'react-select';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    GetAllOrders,
    GetClientsForFilter,
    SelectClientsForFilter,
    SelectOrderStatusFilter,
    SelectOrderTypeFilter,
    SelectDeliveryTypeForFilter,
    SelectPaymentMethodForFilter,
    ChangeOrderCodeForFilter,
    SelectPartnerForFilter,
    GetPartnersForFilter,
    SelectEndDateForFilter,
    SelectTTNDateForFilter,
    SelectStartDateForFilter,
    SelectPrintStatusForFilter,
    SelectProductionStatusForFilter,
    SelectAssemblingDateForFilter,
    SelectStockStatusForFilter,
    ChangeTtnForFilter,
    GetAutocompleteModelResult,
    ChangeModelValue,
    SelectLaserStatusForFilter,
    GetAutocompleteLexiconResult,
    ChangeLexiconValue
} from '../../../../actions/sales/order.base.actions';
import {OrderBaseFilterEntity} from '../../../../helpers/order.base.fiter.entity';
import {Validator} from '../../../../helpers/validator.helper';
import {DebounceInput} from 'react-debounce-input';
import * as moment from 'moment';
import {DatePicker} from 'material-ui';
import {OrderBaseState} from '../../../../states/sales/order.base.state';
import PermissionChecker from '../../../shared/permission.checker.component';
import {IdentityRoleEnum, AllIdentityRolesExept} from '../../../../helpers/identity.roles';
import { PaymentMethod } from '../../../../helpers/enums/payment.method';

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectOrderTypeFilter,
    SelectOrderStatusFilter,
    GetAllOrders,
    GetClientsForFilter,
    SelectClientsForFilter,
    SelectDeliveryTypeForFilter,
    SelectPaymentMethodForFilter,
    ChangeOrderCodeForFilter,
    SelectPartnerForFilter,
    GetPartnersForFilter,
    SelectEndDateForFilter,
    SelectStartDateForFilter,
    SelectPrintStatusForFilter,
    SelectProductionStatusForFilter,
    SelectAssemblingDateForFilter,
    SelectStockStatusForFilter,
    ChangeTtnForFilter,
    SelectTTNDateForFilter,
    GetAutocompleteModelResult,
    ChangeModelValue,
    SelectLaserStatusForFilter,
    GetAutocompleteLexiconResult,
    ChangeLexiconValue
}, dispatch);

const OrderBaseFilter = (props: any) => {

    const IntlPolyfill = require('intl');
    const DateTimeFormat = IntlPolyfill.DateTimeFormat;
    require('intl/locale-data/jsonp/ru-UA');

    const format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    const OnChangeOrderTypeCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectOrderTypeFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.OrderType = value.value;
            else
                filter.OrderType = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeOrderStatusCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectOrderStatusFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.OrderStatus = value.value;
            else
                filter.OrderStatus = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnClientPhoneNumberChangeCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            if (value && Validator.IsNumeric(value)) {
                props.GetClientsForFilter(value);
            }

            if (value === '')
                props.GetClientsForFilter('');
        }
    }

    const OnChangeClientCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectClientsForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.Client = parseInt(value.value);
            else
                filter.Client = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeDeliveryType = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectDeliveryTypeForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.DeliveryType = value.value;
            else
                filter.DeliveryType = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnPartnerAutocompleteChangeCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.GetPartnersForFilter(value);
        }
    }

    const OnChangePartnerMethod = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectPartnerForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.PartnerNetId = value.value;
            else
                filter.PartnerNetId = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeStockStatusMethod = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectStockStatusForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.StockStatus = value.value;
            else
                filter.StockStatus = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangePaymentMethod = (value) => {
        if (!props.OrderBase.fetchInProgress) {

            props.SelectPaymentMethodForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.PaymentMethod = value.value;
            else
                filter.PaymentMethod = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeOrderCodeMethod = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.ChangeOrderCodeForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.OrderCode = value;
            else
                filter.OrderCode = '';

            props.GetAllOrders(filter);
        }
    }

    const OnChangeTTNMethod = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.ChangeTtnForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.TTN = value;
            else
                filter.TTN = '';

            props.GetAllOrders(filter);
        }
    }

    const OnChangeTTNDateCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectTTNDateForFilter(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);
            // .hour(23)
            let filter = BuildFilter();

            if (value)
                filter.TTNCreateDate = moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
            else
                filter.TTNCreateDate = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeStartDateCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectStartDateForFilter(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);

            let filter = BuildFilter();

            if (value)
                filter.StartDate = moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
            else
                filter.StartDate = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeEndDateCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectEndDateForFilter(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);

            let filter = BuildFilter();

            if (value)
                filter.EndDate = moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
            else
                filter.EndDate = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangePrintStatusCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectPrintStatusForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.PrintStatus = value.value;
            else
                filter.PrintStatus = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeProductionStatusCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectProductionStatusForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.ProductionStatus = value.value;
            else
                filter.ProductionStatus = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangLaserStatusCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectLaserStatusForFilter(value);

            let filter = BuildFilter();

            if (value)
                filter.LaserStatus = value.value;
            else
                filter.LaserStatus = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeAssemblingDateCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.SelectAssemblingDateForFilter(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);

            let filter = BuildFilter();

            if (value)
                filter.AssemblingDate = moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
            else
                filter.AssemblingDate = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeModelCommand = (value) => {
        if (!props.OrderBase.fetchInProgress) {
            props.ChangeModelValue(value);

            let filter = BuildFilter();

            if (value)
                filter.ProductId = value.value;
            else
                filter.ProductId = undefined;

            props.GetAllOrders(filter);
        }
    }

    const OnChangeLexiconCommand = (value) => {
        //debugger;
        if (!props.OrderBase.fetchInProgress) {
            props.ChangeLexiconValue(value);

            let filter = BuildFilter();

            if (value)
                filter.LexiconId = value.value;
            else
                filter.LexiconId = undefined;

            props.GetAllOrders(filter);
        }
    }

    const BuildFilter = () => {
        let filter = new OrderBaseFilterEntity();

        if (props.OrderBase.selectedPartner)
            filter.PartnerNetId = props.OrderBase.selectedPartner.value;

        if (props.OrderBase.selectedOrderType)
            filter.OrderType = props.OrderBase.selectedOrderType.value;

        if (props.OrderBase.selectedOrderStatus)
            filter.OrderStatus = props.OrderBase.selectedOrderStatus.value;

        if (props.OrderBase.selectedStockStatus)
            filter.StockStatus = props.OrderBase.selectedStockStatus.value;

        if ((props.OrderBase as OrderBaseState).filterStartDate)
            filter.StartDate = moment((props.OrderBase as OrderBaseState).filterStartDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((props.OrderBase as OrderBaseState).filterEndDate)
            filter.EndDate = moment((props.OrderBase as OrderBaseState).filterEndDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((props.OrderBase as OrderBaseState).TTNCreateDateFilter)
            filter.TTNCreateDate = (props.OrderBase as OrderBaseState).TTNCreateDateFilter.format('YYYY-MM-DDTHH:mm:ss');

        if ((props.OrderBase as OrderBaseState).orderCode)
            filter.OrderCode = (props.OrderBase as OrderBaseState).orderCode;

        if ((props.OrderBase as OrderBaseState).selectedClient)
            filter.Client = (props.OrderBase as OrderBaseState).selectedClient.value;

        if ((props.OrderBase as OrderBaseState).selectedDeliveryType)
            filter.DeliveryType = (props.OrderBase as OrderBaseState).selectedDeliveryType.value;

        if ((props.OrderBase as OrderBaseState).selectedPaymentMethod)
            filter.PaymentMethod = (props.OrderBase as OrderBaseState).selectedPaymentMethod.value;

        if ((props.OrderBase as OrderBaseState).selectedPrintStatus)
            filter.PrintStatus = (props.OrderBase as OrderBaseState).selectedPrintStatus.value;

        if ((props.OrderBase as OrderBaseState).selectedProductionStatus)
            filter.ProductionStatus = (props.OrderBase as OrderBaseState).selectedProductionStatus.value;

        if ((props.OrderBase as OrderBaseState).selectedLaserStatus)
            filter.LaserStatus = (props.OrderBase as OrderBaseState).selectedLaserStatus.value;

        if ((props.OrderBase as OrderBaseState).TTN)
            filter.TTN = (props.OrderBase as OrderBaseState).TTN;

        if ((props.OrderBase as OrderBaseState).filterAssemblingDate)
            filter.AssemblingDate = (props.OrderBase as OrderBaseState).filterAssemblingDate.format('YYYY-MM-DDTHH:mm:ss');

        if ((props.OrderBase as OrderBaseState).selectedModel)
            filter.ProductId = (props.OrderBase as OrderBaseState).selectedModel.value;

        if ((props.OrderBase as OrderBaseState).selectedLexicon)
            filter.LexiconId = (props.OrderBase as OrderBaseState).selectedLexicon.value;

        return filter;
    }

    const onAutocompleteModelInputChange = (value: string) => {
        value && props.GetAutocompleteModelResult(value);
    }


    ///
    const onAutocompleteLexiconInputChange = (value: string) => {
        value && props.GetAutocompleteLexiconResult(value);
    }

    return (
        <div className="orderBaseFilter__panelContainer">
            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                <div className="defaultFormControl__label">Партнёр</div>
                <div className="selectWrapper__container">
                    <Select
                        name="partners"
                        isClearable
                        clearValue={() => {
                            OnPartnerAutocompleteChangeCommand(null)
                        }}
                        onInputChange={OnPartnerAutocompleteChangeCommand}
                        options={props.OrderBase.partnerList}
                        onChange={(value) => {
                            OnChangePartnerMethod(value)
                        }}
                        value={props.OrderBase.selectedPartner}
                        placeholder="Партнёр"/>
                </div>
            </PermissionChecker>

            <div className="defaultFormControl dateCol">
                <div className="defaultFormControl__label">Начальная дата</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Начальная дата"
                        value={props.OrderBase.filterStartDate ? props.OrderBase.filterStartDate.toDate() : props.OrderBase.filterStartDate}
                        onChange={(event, date: any) => {
                            OnChangeStartDateCommand(date)
                        }}
                    />
                    <div className="clControl" onClick={() => OnChangeStartDateCommand(null)}/>
                </div>
            </div>

            <div className="defaultFormControl dateCol last">
                <div className="defaultFormControl__label">Конечная дата</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Конечная дата"
                        value={props.OrderBase.filterEndDate ? props.OrderBase.filterEndDate.toDate() : props.OrderBase.filterEndDate}
                        onChange={(event, date: any) => {
                            OnChangeEndDateCommand(date)
                        }}
                    />
                    <div className="clControl" onClick={() => OnChangeEndDateCommand(null)}/>
                </div>
            </div>

            <div className="defaultFormControl  dateCol ffirst">
                <div className="defaultFormControl__label">Код заказа</div>
                <div className="defaultFormControl__value">
                    <DebounceInput
                        minLength={2}
                        debounceTimeout={1000}
                        value={props.OrderBase.orderCode}
                        onChange={event => OnChangeOrderCodeMethod(event.target.value)}/>
                </div>
            </div>

            <div className="defaultFormControl  dateCol last">
                <div className="defaultFormControl__label">ТТН</div>
                <div className="defaultFormControl__value">
                    <DebounceInput
                        minLength={2}
                        debounceTimeout={300}
                        value={props.OrderBase.TTN}
                        onChange={event => OnChangeTTNMethod(event.target.value)}/>
                </div>
            </div>

            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                <div className="defaultFormControl__label">Тип заказа</div>
                <div className="selectWrapper__container">
                    <Select
                        name="orderType"
                        isClearable
                        clearValue={() => {
                            OnChangeOrderTypeCommand(null)
                        }}
                        options={props.OrderBase.orderTypeList}
                        onChange={(value) => {
                            OnChangeOrderTypeCommand(value)
                        }}
                        value={props.OrderBase.selectedOrderType}
                        placeholder="Тип заказа"/>
                </div>
            </PermissionChecker>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Модель</div>
                <div className="selectWrapper__container">
                    <Select
                        name="model"
                        onInputChange={onAutocompleteModelInputChange}
                        options={props.OrderBase.modelList}
                        onChange={OnChangeModelCommand}
                        value={props.OrderBase.selectedModel}
                        placeholder="Введите модель"
                    />
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Лексикон</div>
                <div className="selectWrapper__container">
                    <Select
                        name="lexicon"
                        onInputChange={onAutocompleteLexiconInputChange}
                        options={props.OrderBase.lexiconList}
                        onChange={OnChangeLexiconCommand}
                        value={props.OrderBase.selectedLexicon}
                        placeholder="Введите лексикон"
                    />
                </div>
            </div> 

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Статус заказа</div>
                <div className="selectWrapper__container">
                    <Select
                        name="orderStatus"
                        isClearable
                        clearValue={() => {
                            OnChangeOrderStatusCommand(null)
                        }}
                        options={props.OrderBase.orderStatusList}
                        onChange={(value) => {
                            OnChangeOrderStatusCommand(value)
                        }}
                        value={props.OrderBase.selectedOrderStatus}
                        placeholder="Статус заказа"/>
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Клиент</div>
                <div className="selectWrapper__container">
                    <Select
                        name="clients"
                        isClearable
                        onInputChange={OnClientPhoneNumberChangeCommand}
                        clearValue={() => {
                            OnChangeClientCommand(null)
                        }}
                        options={props.OrderBase.clientList}
                        onChange={(value) => {
                            OnChangeClientCommand(value)
                        }}
                        value={props.OrderBase.selectedClient}
                        placeholder="Клиент"/>
                </div>
            </div>

            <div className="defaultFormControl   dateCol ffirst">
                <div className="defaultFormControl__label">Тип доставки</div>
                <div className="selectWrapper__container">
                    <Select
                        name="deliveryType"
                        isClearable
                        clearValue={() => {
                            OnChangeDeliveryType(null)
                        }}
                        options={props.OrderBase.deliveryTypeList}
                        onChange={(value) => {
                            OnChangeDeliveryType(value)
                        }}
                        value={props.OrderBase.selectedDeliveryType}
                        placeholder="Тип доставки"/>
                </div>
            </div>

            <div className="defaultFormControl   dateCol">
                <div className="defaultFormControl__label">Способ оплаты</div>
                <PermissionChecker 
                    Classes='selectWrapper__container'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Maysternya])}
                >
                {/* <div className="selectWrapper__container"> */}
                    <Select
                        name="paymentMethod"
                        isClearable
                        clearValue={() => {
                            OnChangePaymentMethod(null)
                        }}
                        options={props.OrderBase.paymentMethodList}
                        onChange={(value) => {
                            OnChangePaymentMethod(value)
                        }}
                        value={props.OrderBase.selectedPaymentMethod}
                        placeholder="Способ оплаты"/>
                {/* </div> */}
                </PermissionChecker>

                <PermissionChecker 
                    Classes='selectWrapper__container'
                    ViewRoles={[IdentityRoleEnum.Maysternya]}
                >
                    <Select
                        name="paymentMethod"
                        value={
                            {
                                value: PaymentMethod.Certificate,
                                label: 'Сертификат-промокод'
                            }
                        }
                        options={
                            [
                                {
                                    value: PaymentMethod.Certificate,
                                    label: 'Сертификат-промокод'
                                }
                            ]
                        }
                        placeholder="Способ оплаты"/>

                </PermissionChecker>
            </div>

            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Manufacture, IdentityRoleEnum.Lasercut])}>
                <div className="defaultFormControl__label">Статус печати</div>
                <div className="selectWrapper__container">
                    <Select
                        name="printStatus"
                        isClearable
                        clearValue={() => {
                            OnChangePrintStatusCommand(null)
                        }}
                        options={props.OrderBase.printStatusList}
                        onChange={(value) => {
                            OnChangePrintStatusCommand(value)
                        }}
                        value={props.OrderBase.selectedPrintStatus}
                        placeholder="Статус печати"/>
                </div>
            </PermissionChecker>

            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Print, IdentityRoleEnum.Lasercut])}>
                <div className="defaultFormControl__label">Статус производства</div>
                <div className="selectWrapper__container">
                    <Select
                        name="productionStatus"
                        isClearable
                        clearValue={() => {
                            OnChangeProductionStatusCommand(null)
                        }}
                        options={props.OrderBase.productionStatusList}
                        onChange={(value) => {
                            OnChangeProductionStatusCommand(value)
                        }}
                        value={props.OrderBase.selectedProductionStatus}
                        placeholder="Статус производства"/>
                </div>
            </PermissionChecker>

            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Manufacture, IdentityRoleEnum.Print])}>
                <div className="defaultFormControl__label">Статус печати</div>
                <div className="selectWrapper__container">
                    <Select
                        name="productionStatus"
                        isClearable
                        clearValue={() => {
                            OnChangLaserStatusCommand(null)
                        }}
                        options={props.OrderBase.laserStatusList}
                        onChange={(value) => {
                            OnChangLaserStatusCommand(value)
                        }}
                        value={props.OrderBase.selectedLaserStatus}
                        placeholder="Статус печати"/>
                </div>
            </PermissionChecker>

            <PermissionChecker
                Classes={'defaultFormControl'}
                ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                <div className="defaultFormControl__label">Статус сборки</div>
                <div className="selectWrapper__container">
                    <Select
                        name="stockStatus"
                        isClearable
                        clearValue={() => {
                            OnChangeStockStatusMethod(null)
                        }}
                        options={props.OrderBase.stockStatusList}
                        onChange={(value) => {
                            OnChangeStockStatusMethod(value)
                        }}
                        value={props.OrderBase.selectedStockStatus}
                        placeholder="Статус наличия"/>
                </div>
            </PermissionChecker>

            <PermissionChecker
                Classes={'defaultFormControl dateCol'}
                ViewRoles={AllIdentityRolesExept([])}>
                <div className="defaultFormControl__label">Дата сборки</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Дата сборки"
                        value={props.OrderBase.filterAssemblingDate ? props.OrderBase.filterAssemblingDate.toDate() : props.OrderBase.filterAssemblingDate}
                        onChange={(event, date: any) => {
                            OnChangeAssemblingDateCommand(date)
                        }}
                    />
                    <div className="clControl" onClick={() => OnChangeAssemblingDateCommand(null)}/>
                </div>
            </PermissionChecker>

            <PermissionChecker
                Classes={'defaultFormControl dateCol'}
                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya, IdentityRoleEnum.Partner, IdentityRoleEnum.Sklad]}>
                <div className="defaultFormControl__label">Дата ТТН</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Дата ТТН"
                        value={props.OrderBase.TTNCreateDateFilter ? props.OrderBase.TTNCreateDateFilter.toDate() : props.OrderBase.TTNCreateDateFilter}
                        onChange={(event, date: any) => {
                            OnChangeTTNDateCommand(date)
                        }}
                    />
                    <div className="clControl" onClick={() => OnChangeTTNDateCommand(null)}/>
                </div>
            </PermissionChecker>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderBaseFilter);