import * as React from "react";
import { Redirect } from "react-router";

export const StartRouteRedirect = (props: any) => {
    if (props.role === "Administrator" && props.path == "/") {
        return (<Redirect exact from="/" to="/stock/products" />);
    } 

    if (props.role === "User" && props.path == "/") {
        return (<Redirect exact from="/" to="/cabinet/personal-info" />);
    } 

    if (props.role === "Manufacture" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 

    if (props.role === "Lasercut" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 

    if (props.role === "Sklad" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 

    if (props.role === "Print" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 

    if (props.role === "Coordinator" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 

    if (props.role === "Boss" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    } 
    
    if (props.role === "Admin" && props.path == "/") {
        return (<Redirect exact from="/" to="/stock/products" />);
    } 

    if (props.role === "Sklad" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    }

    if (props.role === "Partner" && props.path == "/") {
        return (<Redirect exact from="/" to="/cabinet/client-info" />);
    }

    if (props.role === "Booker" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    }

    if (props.role === "Maysternya" && props.path == "/") {
        return (<Redirect exact from="/" to="/sales/order-base" />);
    }

    if(props.path !== "/")
        return null;
}