import { Partner } from "../../business.entities/partner";
import { ConstructorPrices } from "../../business.entities/constructor.prices";

export const GET_USER_PERSONAL_INFO: string = '[API] GET_USER_PERSONAL_INFO';
export interface IGetUserPersonalInfo { type: '[API] GET_USER_PERSONAL_INFO'; payload: string; }
export const GetUserPersonalInfo = (UserNetUid: string) =>
  <IGetUserPersonalInfo>{ type: GET_USER_PERSONAL_INFO, payload: UserNetUid };

export const GET_USER_PERSONAL_INFO_SUCCESS: string = 'GET_USER_PERSONAL_INFO_SUCCESS';
export interface IGetUserPersonalInfoSuccess { type: 'GET_USER_PERSONAL_INFO_SUCCESS'; payload: any; }
export const GetUserPersonalInfoSuccess = (UserInfo: any) =>
  <IGetUserPersonalInfoSuccess>{ type: GET_USER_PERSONAL_INFO_SUCCESS, payload: UserInfo };

export const GET_FUNDS_INFO: string = '[API] GET_FUNDS_INFO';
export interface IGetUserFunds { type: '[API] GET_FUNDS_INFO'; payload: any; }
export const GetUserFunds = (userUid) =>
  <IGetUserFunds>{ type: GET_FUNDS_INFO, payload: userUid };

export const SET_FUNDS_INFO: string = 'SET_FUNDS_INFO';
export interface ISetUserFunds { type: 'SET_FUNDS_INFO'; payload: any; }
export const SetUserFunds = (terms, lastTotal, balance) =>
  <ISetUserFunds>{ type: SET_FUNDS_INFO, payload: { terms, lastTotal, balance } };

export const CHANGE_CLIENT_INFO: string = '[API] CHANGE_CLIENT_INFO';
export interface IChangeClientInfo { type: '[API] CHANGE_CLIENT_INFO'; payload: any; }
export const ChangeClientInfo = (changedUser: Partner) =>
  <IChangeClientInfo>{ type: CHANGE_CLIENT_INFO, payload: changedUser };

export const CHANGE_CLIENT_INFO_SUCCESS: string = 'CHANGE_CLIENT_INFO_SUCCESS';
export interface IChangeClientInfoSuccess { type: 'CHANGE_CLIENT_INFO_SUCCESS'; payload: any; }
export const ChangeClientInfoSuccess = (changedUserInfo: Partner) =>
  <IChangeClientInfoSuccess>{ type: CHANGE_CLIENT_INFO_SUCCESS, payload: changedUserInfo };

export const GET_CONSTRUCTOR_PRICE: string = '[API] PERSONAL_INFO.GET_CONSTRUCTOR_PRICE';
export interface IGetConstructorPrice { type: '[API] PERSONAL_INFO.GET_CONSTRUCTOR_PRICE'; payload: any; }
export const GetConstructorPrice = (netUid) =>
  <IGetConstructorPrice>{ type: GET_CONSTRUCTOR_PRICE, payload: netUid };

export const ADD_CONSTRUCTOR_PRICE: string = '[API] PERSONAL_INFO.ADD_CONSTRUCTOR_PRICE';
export interface IAddConstructorPrice { type: '[API] PERSONAL_INFO.ADD_CONSTRUCTOR_PRICE'; payload: any; }
export const AddConstructorPrice = (prices: ConstructorPrices) =>
  <IAddConstructorPrice>{ type: ADD_CONSTRUCTOR_PRICE, payload: prices };

export const UPDATE_CONSTRUCTOR_PRICE: string = '[API] PERSONAL_INFO.UPDATE_CONSTRUCTOR_PRICE';
export interface IUpdateConstructorPrice { type: '[API] PERSONAL_INFO.UPDATE_CONSTRUCTOR_PRICE'; payload: any; }
export const UpdateConstructorPrice = (prices: ConstructorPrices) =>
  <IUpdateConstructorPrice>{ type: UPDATE_CONSTRUCTOR_PRICE, payload: prices };

export const SET_CONSTRUCTOR_PRICE: string = 'PERSONAL_INFO.SET_CONSTRUCTOR_PRICE';
export interface ISetConstructorPrice { type: 'PERSONAL_INFO.SET_CONSTRUCTOR_PRICE'; payload: any; }
export const SetConstructorPrice = (prices: ConstructorPrices) =>
  <ISetConstructorPrice>{ type: SET_CONSTRUCTOR_PRICE, payload: prices };

export const CHANGE_CONSTRUCTOR_PRICE_ITEM: string = 'PERSONAL_INFO.CHANGE_CONSTRUCTOR_PRICE_ITEM';
export interface IChangeConstructorPriceItem { type: 'PERSONAL_INFO.CHANGE_CONSTRUCTOR_PRICE_ITEM'; payload: any; }
export const ChangeConstructorPriceItem = (value, index) =>
  <IChangeConstructorPriceItem>{ type: CHANGE_CONSTRUCTOR_PRICE_ITEM, payload: { value, index } };

export const GET_PRICE_SEGMENTS_FOR_PARTNER: string = '[API] PERSONAL_INFO.GET_PRICE_SEGMENTS_FOR_PARTNER';
export interface IGetPriceSergmentsForPartner { type: '[API] PERSONAL_INFO.GET_PRICE_SEGMENTS_FOR_PARTNER'; payload: any; }
export const GetPriceSergmentsForPartner = (partnerNetid: string) =>
  <IGetPriceSergmentsForPartner>{ type: GET_PRICE_SEGMENTS_FOR_PARTNER, payload: partnerNetid };

export const SET_PRICE_SEGMENTS_FOR_PARTNER: string = 'PERSONAL_INFO.SET_PRICE_SEGMENTS_FOR_PARTNER';
export interface ISetPriceSergmentsForPartner { type: 'PERSONAL_INFO.SET_PRICE_SEGMENTS_FOR_PARTNER'; payload: any; }
export const SetPriceSergmentsForPartner = (segments: any[]) =>
  <ISetPriceSergmentsForPartner>{ type: SET_PRICE_SEGMENTS_FOR_PARTNER, payload: segments };

export const ACTIVATE_CONSTRUCTOR: string = '[API] PERSONAL_INFO.ACTIVATE_CONSTRUCTOR';
export interface IActivateConstructor { type: '[API] PERSONAL_INFO.ACTIVATE_CONSTRUCTOR'; payload: any; }
export const ActivateConstructor = (partnerNetid: string) =>
  <IActivateConstructor>{ type: ACTIVATE_CONSTRUCTOR, payload: partnerNetid };

export const UNMOUNT_COMPONENT: string = 'PERSONAL_INFO.CHANGE_CLIENT_INFO_SUCCESS';
export interface IUnmountComponent { type: 'PERSONAL_INFO.CHANGE_CLIENT_INFO_SUCCESS'; payload: any; }
export const UnmountComponent = () =>
  <IUnmountComponent>{ type: UNMOUNT_COMPONENT };

export const LOGO_EDIT_MODE: string = 'PERSONAL_INFO.LOGO_EDIT_MODE';
export interface ILogoEditMode { type: 'PERSONAL_INFO.LOGO_EDIT_MODE'; payload: any; }
export const LogoEditMode = (value) =>
  <ILogoEditMode>{ type: LOGO_EDIT_MODE, payload: value };

export const CHANGE_POSITION: string = 'PERSONAL_INFO.CHANGE_POSITION';
export interface IChangePosition { type: 'PERSONAL_INFO.CHANGE_POSITION'; payload: any; }
export const ChangePosition = (value) =>
  <IChangePosition>{ type: CHANGE_POSITION, payload: value };

export const CHANGE_LOGO_IMG: string = 'PERSONAL_INFO.CHANGE_LOGO_IMG';
export interface IChangeLogoImg { type: 'PERSONAL_INFO.CHANGE_LOGO_IMG'; payload: any; }
export const ChangeLogoImg = (value) =>
  <IChangeLogoImg>{ type: CHANGE_LOGO_IMG, payload: value };

export const CHANGE_PREVIEW_IMG: string = 'PERSONAL_INFO.CHANGE_PREVIEW_IMG';
export interface IChangePreviewImg { type: 'PERSONAL_INFO.CHANGE_PREVIEW_IMG'; payload: any; }
export const ChangePreviewImg = (value) =>
  <IChangePreviewImg>{ type: CHANGE_PREVIEW_IMG, payload: value };

export const SET_REMOVING_LOGO: string = 'PERSONAL_INFO.SET_REMOVING_LOGO';
export interface ISetRemovingLogo { type: 'PERSONAL_INFO.SET_REMOVING_LOGO'; payload: any; }
export const SetRemovingLogo = (value) => 
  <ISetRemovingLogo>{ type: SET_REMOVING_LOGO, payload: value };

export const UPDATE_WATERMARK: string = '[API] PERSONAL_INFO.UPDATE_WATERMARK';
export interface IUpdateWatermark { type: '[API] PERSONAL_INFO.UPDATE_WATERMARK'; payload: any; }
export const UpdateWatermark = () =>
  <IUpdateWatermark>{ type: UPDATE_WATERMARK };

export const REMOVE_WATERMARK: string = '[API] PERSONAL_INFO.REMOVE_WATERMARK';
export interface IRemoveWatermark { type: '[API] PERSONAL_INFO.REMOVE_WATERMARK'; payload: any; }
export const RemoveWatermark = () =>
  <IRemoveWatermark>{ type: REMOVE_WATERMARK };

export const OPEN_ACTIVATE_CONSTRUCTOR: string = 'PERSONAL_INFO.OPEN_ACTIVATE_CONSTRUCTOR';
export interface IOpenActivateConstructor { type: 'PERSONAL_INFO.OPEN_ACTIVATE_CONSTRUCTOR'; payload: any; }
export const OpenActivateConstructor = (open: boolean) =>
  <IOpenActivateConstructor>{ type: OPEN_ACTIVATE_CONSTRUCTOR, payload: open };

export const OPEN_POSITION: string = 'PERSONAL_INFO.OPEN_POSITION';
export interface IOpenPosition { type: 'PERSONAL_INFO.OPEN_POSITION'; payload: any; }
export const OpenPosition = (open: boolean) =>
  <IOpenPosition>{ type: OPEN_POSITION, payload: open };

export const OPEN_PRICES: string = 'PERSONAL_INFO.OPEN_PRICES';
export interface IOpenPrices { type: 'PERSONAL_INFO.OPEN_PRICES'; payload: any; }
export const OpenPrices = (open: boolean) =>
  <IOpenPrices>{ type: OPEN_PRICES, payload: open };

export const OPEN_LOGO_PREVIEW: string = 'PERSONAL_INFO.OPEN_LOGO_PREVIEW';
export interface IOpenLogoPreview { type: 'PERSONAL_INFO.OPEN_LOGO_PREVIEW'; payload: any; }
export const OpenLogoPreview = (open: boolean) =>
  <IOpenLogoPreview>{ type: OPEN_LOGO_PREVIEW, payload: open };

export const OPEN_INTEGRATION_INFO: string = 'PERSONAL_INFO.OPEN_INTEGRATION_INFO';
export interface IOpenIntegrationInfo { type: 'PERSONAL_INFO.OPEN_INTEGRATION_INFO'; payload: any; }
export const OpenIntegrationInfo = (open: boolean) =>
  <IOpenIntegrationInfo>{ type: OPEN_INTEGRATION_INFO, payload: open };

export const GET_CONSTRUCTOR_TUTORIALS: string = '[API] PERSONAL_INFO.GET_CONSTRUCTOR_TUTORIALS';
export interface IGetConstructorTutorials { type: '[API] PERSONAL_INFO.GET_CONSTRUCTOR_TUTORIALS'; payload: any; }
export const GetConstructorTutorials = () =>
  <IGetConstructorTutorials>{ type: GET_CONSTRUCTOR_TUTORIALS };

export const SET_CONSTRUCTOR_TUTORIALS: string = 'PERSONAL_INFO.SET_CONSTRUCTOR_TUTORIALS';
export interface ISetConstructorTutorials { type: 'PERSONAL_INFO.SET_CONSTRUCTOR_TUTORIALS'; payload: any; }
export const SetConstructorTutorials = (tutorials) =>
  <ISetConstructorTutorials>{ type: SET_CONSTRUCTOR_TUTORIALS, payload: tutorials };

export const UPDATE_CONSTRUCTOR_TUTORIAL: string = '[API] PERSONAL_INFO.UPDATE_CONSTRUCTOR_TUTORIAL';
export interface IUpdateConstructorTutorial { type: '[API] PERSONAL_INFO.UPDATE_CONSTRUCTOR_TUTORIAL'; payload: any; }
export const UpdateConstructorTutorial = (tutorial) =>
  <IUpdateConstructorTutorial>{ type: UPDATE_CONSTRUCTOR_TUTORIAL, payload: tutorial };

export const SELECT_TUTORIAL: string = 'PERSONAL_INFO.SELECT_TUTORIAL';
export interface ISelectTutorial { type: 'PERSONAL_INFO.SELECT_TUTORIAL'; payload: any; }
export const SelectTutorial = (tutorial, editMode) =>
  <ISelectTutorial>{ type: SELECT_TUTORIAL, payload: { tutorial, editMode } };

export const CHANGE_TUTORIAL: string = 'PERSONAL_INFO.CHANGE_TUTORIAL';
export interface IChangeTutorial { type: 'PERSONAL_INFO.CHANGE_TUTORIAL'; payload: any; }
export const ChangeTutorial = (tutorial) =>
  <IChangeTutorial>{ type: CHANGE_TUTORIAL, payload: tutorial };

export const CHANGE_VIDEO: string = 'PERSONAL_INFO.CHANGE_VIDEO';
export interface IChangeVideo { type: 'PERSONAL_INFO.CHANGE_VIDEO'; payload: any; }
export const ChangeVideo = (video) =>
  <IChangeVideo>{ type: CHANGE_VIDEO, payload: video };

export const GET_CONSTRUCTOR_USERS: string = '[API] PERSONAL_INFO.GET_CONSTRUCTOR_USERS';
export interface IGetConstructorUsers { type: '[API] PERSONAL_INFO.GET_CONSTRUCTOR_USERS'; payload: any; }
export const GetConstructorUsers = (isActive) =>
  <IGetConstructorUsers>{ type: GET_CONSTRUCTOR_USERS, payload: isActive };

export const SET_CONSTRUCTOR_USERS: string = 'PERSONAL_INFO.SET_CONSTRUCTOR_USERS';
export interface ISetConstructorUsers { type: 'PERSONAL_INFO.SET_CONSTRUCTOR_USERS'; payload: any; }
export const SetConstructorUsers = (partnes) =>
  <ISetConstructorUsers>{ type: SET_CONSTRUCTOR_USERS, payload: partnes };

export type PersonalInfoKnownActions =
  IChangeClientInfoSuccess | IChangeClientInfo | IGetUserPersonalInfoSuccess | IGetUserPersonalInfo | IUnmountComponent |
  IGetUserFunds | ISetUserFunds | IGetConstructorPrice | ISetConstructorPrice | IAddConstructorPrice | IUpdateConstructorPrice |
  IChangeConstructorPriceItem | IGetPriceSergmentsForPartner | ISetPriceSergmentsForPartner | IActivateConstructor | ILogoEditMode |
  IChangePosition | IChangeLogoImg | IUpdateWatermark | IRemoveWatermark | IChangePreviewImg | ISetRemovingLogo | IOpenActivateConstructor | IOpenPosition | IOpenPrices |
  IOpenLogoPreview | IOpenIntegrationInfo | IGetConstructorTutorials | ISetConstructorTutorials | IUpdateConstructorTutorial | ISelectTutorial |
  IChangeTutorial | IChangeVideo | IGetConstructorUsers | ISetConstructorUsers;