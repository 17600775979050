import { Observable } from "rxjs/Observable";
import { success, error } from "react-notification-system-redux";
import { actions } from "react-redux-form";
import { Partner } from "../../business.entities/partner";
import { isUnauthorized } from "../../helpers/unauthorize.hendle";
import * as API from "../../constants/api.constants";
import * as clientBaseActions from "../../actions/sales/client.base.actions";
import { SelectModel } from "../../helpers/select.model";
import { ErrorHandler } from "../../helpers/error.manager";
import storeProvider from "../../helpers/store.provider";

export const GetAutocompletePartnerResult = (action$, store) =>
  action$.ofType(clientBaseActions.GET_AUTOCOMPLETE_PARTNER_RESULT)
    .switchMap(action =>
      Observable.ajax.getJSON(`${API.GET_PARTNER_AUTOCOMPLETE}?value=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Партнеры успешно получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));
          let arrayToReturn: SelectModel[] = response.Body.map((element: Partner) => {
            return { value: element.NetUid, label: element.ContactName };
          });

          return clientBaseActions.SetAutocompletePartnerResult(arrayToReturn);
        }).catch((errorData: any) => {
          return Observable.of(ErrorHandler(errorData));
        })
    );


export const GetClientsForSelectedPartner = (action$, store) =>
  action$.ofType(clientBaseActions.GET_CLIENTS_FOR_SELECTED_PARTNER)
    .switchMap(action =>
    Observable.ajax.getJSON(`${API.GET_ALL_CLIENTS_FILTERED_ANG_PAGINATED}?filter=${action.payload}`,
        { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
        .map((response: any) => {
          storeProvider.getStore().dispatch(success({
            title: 'Клиенты успешно получены',
            message: '',
            position: 'br',
            autoDismiss: 3,
          }));
          return clientBaseActions.SetClientsResult(response.Body);
        }).catch((errorData: any) => {

          return Observable.of(ErrorHandler(errorData));
        })
    );
