import { Reducer } from "redux";
import { LexiconState, defaultLexiconState } from "../../states/stock/lexicon.state";
import * as lexiconActions from "../../actions/stock/lexicon.actions"

export const reducer: Reducer<LexiconState> = (state: LexiconState, action: lexiconActions.LexiconKnownActions) => {
    switch (action.type) {
        case lexiconActions.SELECT_LEXICON:
            return Object.assign({}, state, { SelectedLexicon: action.payload });

        case lexiconActions.DESELECT_LEXICON:
            return Object.assign({}, state, { SelectedLexicon: defaultLexiconState.SelectedLexicon });

        case lexiconActions.GET_LEXICONS_FILTERED_PAGINATED:
            return Object.assign({}, state, { fetching: true });

        case lexiconActions.GET_LEXICONS_SUCCESS:
            return Object.assign({}, state, { totalPages: action.payload.TotalPagesCount, lexicons: action.payload.Collection, fetching: false });

        case lexiconActions.GET_LEXICONS_FAILED:
            return Object.assign({}, state, { SelectedLexicon: defaultLexiconState.SelectedLexicon, fetching: false });

        case lexiconActions.UPDATE_LEXICON:
            return Object.assign({}, state);

        case lexiconActions.UPDATE_LEXICON_SUCCESS:
            return Object.assign({}, state, { SelectedLexicon: defaultLexiconState.SelectedLexicon });

        case lexiconActions.UPDATE_LEXICON_FAILED:
            return Object.assign({}, state);

        case lexiconActions.UNMOUNT_LEXICON:
            return Object.assign({}, defaultLexiconState);

        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultLexiconState;
}