import * as React from "react";
import { connect } from "react-redux";
import { GetIdentityRoleByName, IdentityRoleEnum } from "../../helpers/identity.roles";
import { LoginState } from "../../states/login.state";

const mapStateToProps = (state) => {
    return {
        Authorization: state.authentication
    };
};

export interface IPermissionChecker {
    Authorization: LoginState;
    ViewRoles?: IdentityRoleEnum[];
    EditRoles?: IdentityRoleEnum[];
    Classes?: string;
    DataTooltip?: string;
    children?: any;
    simple?: any;
}

const PermissionChecker = (props: IPermissionChecker) => {

    if (props.EditRoles && props.EditRoles.length == 0) {
        props.EditRoles = props.ViewRoles;
    }

    const IsVisible = () => {
        if (props.ViewRoles) {
            const role = GetIdentityRoleByName(props.Authorization.Role)
            if ([...[IdentityRoleEnum.Administrator], ...props.ViewRoles].indexOf(role) === -1)
                return false;
        }

        return true;
    }

    const IsUseable = (event) => {
        if (props.EditRoles) {
            const role = GetIdentityRoleByName(props.Authorization.Role)
            if ([...[IdentityRoleEnum.Administrator], ...props.EditRoles].indexOf(role) === -1) {
                if (event)
                    event.stopPropagation();
                return false;
            }
        }

        return true;
    }

    if (props.simple)
        return (
            <>
                {
                    IsVisible() ?
                        <>
                            {props.children}
                        </>
                        : null}
            </>
        )
    else

        return (
            <>
                {
                    IsVisible() ?
                        <div className={`${props.Classes} ${IsUseable(null) ? '' : 'isDisabled'}`}
                            data-tooltip={`${props.DataTooltip ? props.DataTooltip:''}`}
                            onClickCapture={(event) => IsUseable(event)}
                            onChangeCapture={(event) => IsUseable(event)}>
                            {props.children}
                        </div>
                        : null}
            </>
        );
}
export default connect(mapStateToProps)(PermissionChecker);