import * as React from "react";
import { connect } from "react-redux";
import * as moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { bindActionCreators } from "redux";
import { DatePicker } from "material-ui";
import { GetAuditEventTypeName } from "../../../../../helpers/enums/audit.event.type.enum";
import Moment from "react-moment";
import { AuditEntity } from "../../../../../business.entities/audit.entity";
import { AuditEntityProperty } from "../../../../../business.entities/audit.entity.property";
import { ApplicationState } from "../../../../../reducers";
import { getTranslate, getActiveLanguage } from "react-localize-redux";
import { OrderTypeEnum, GetTypeName } from "../../../../../helpers/enums/order.type.enum";
import { List } from "linqts";
import { PaymentStatusEnum, GetPaymentStatusName } from "../../../../../helpers/enums/payment.status.enum";
import { PaymentMethod, GetPaymentMethodName } from "../../../../../helpers/enums/payment.method";
import { DeliveryType, GetDeliveryTypeName } from "../../../../../helpers/enums/delivery.type";

export interface IAuditComponent {
    AuditEntities: AuditEntity[];
}
const OrderAuditComponent = (props: any) => {
    const orderCard = props.orderCardState;
    const rvState = props.rightViewState;

    const IntlPolyfill = require('intl');
    const DateTimeFormat = IntlPolyfill.DateTimeFormat;

    const KEY_LIST = ['AssemblingCell', 'RecipientPhoneNumber'];

    require('intl/locale-data/jsonp/ru-UA');

    const format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    const prepareValue = (value: any) => {
        if (!isNaN(value.Value as any) && new List(KEY_LIST).All(c => c !== value.Name))
            return ` ${parseFloat((Math.round((value.Value as any) * 100) / 100) as any).toFixed(2)}`;

        if (value.Value in OrderTypeEnum) {
            return ` ${GetTypeName(OrderTypeEnum[value.Value])}`;
        }

        if (value.Value in PaymentMethod) {
            return ` ${GetPaymentMethodName(PaymentMethod[value.Value])}`;
        }

        if (value.Value in DeliveryType) {
            return ` ${GetDeliveryTypeName(DeliveryType[value.Value])}`;
        }
        
        return ` ${value.Value}`;
    }

    return (
        <div className="">

            <div className="audit_component">
                {
                    orderCard.auditEntities && orderCard.auditEntities.map((auditEntity: AuditEntity, index: number) =>
                        <div className="audit_item">
                            <div className="audit_header_component">
                                <div className="created_log">
                                    <span className="date"><Moment format={'DD'} date={auditEntity.Created}></Moment></span>
                                    <div className="weekday_month">
                                        <span className="year"><Moment format={'YYYY'} date={auditEntity.Created}></Moment></span>
                                        <span className="month"><Moment format={'MMMM'} date={auditEntity.Created} /></span>

                                    </div>
                                    <span className="weekday"><Moment format={'HH:mm'} date={auditEntity.Created} />
                                    </span>
                                </div>

                                <div className="created_log_user">
                                    <div className="status">{GetAuditEventTypeName(auditEntity.Type)}</div>
                                    <div className="user">
                                        {auditEntity.UpdatedBy}</div>
                                </div>
                            </div>

                            <div className="item_content">
                                {auditEntity.TargetEntityId ?
                                    <div className="item_content__top__title">
                                        <div className="top__title">
                                            {
                                                auditEntity.TargetEntity.Product.Lexicon.OfficialPartnerName + ' ' + auditEntity.TargetEntity.Product.Name
                                            }
                                        </div>
                                    </div> :
                                    <div className="item_content__top__title">
                                        <div className="top__title">
                                            Заказ
                                        </div>

                                    </div>
                                }

                                {auditEntity.TargetEntityId ?
                                    <div className="item_content__dess__title">
                                        {auditEntity.EventTypeDescription}
                                    </div> :
                                    <div className="item_content__dess__title">
                                        {auditEntity.EventTypeDescription}
                                    </div>
                                }

                                <div className="item">
                                    <div className="item_content_title">
                                        Новое значение
                                    </div>
                                    <div className="value_item">
                                        {
                                            auditEntity.NewValues.map((newValue: AuditEntityProperty, index: number) =>
                                                <div className="value_name" key={index}>
                                                    {`${props.Translate(newValue.Name)} `} :
                                                    {prepareValue(newValue)}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item_content_title">
                                        Старое значение
                                    </div>
                                    <div className="value_item">
                                        {
                                            auditEntity.OldValues && auditEntity.OldValues.map((oldValue: AuditEntityProperty, index: number) =>
                                                <div className="value_name" key={index}>
                                                    {`${props.Translate(oldValue.Name)} `} :
                                                            {prepareValue(oldValue)}
                                                </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
export default connect(
    (state: any) => ({
        rightViewState: state.rightView, orderCardState: state.sales.orderCard,
        Translate: getTranslate(state.locale),
        CurrentLanguage: getActiveLanguage(state.locale).code
    }),
    (dispatch) => bindActionCreators({

    }, dispatch))(OrderAuditComponent)