import { EntityBase } from "./entity.base";
import { OrderStatusEnum } from "../helpers/enums/order.status.enum";
import Partner from "./partner";

export class OrderStatus extends EntityBase{
    public Status: OrderStatusEnum;
    public OrderId: number;
    public UserId: number;

    public Partner: Partner;
}