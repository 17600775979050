import * as API from '../../constants/api.constants';
import * as orderBaseActions from '../../actions/sales/order.base.actions';
import { ajax } from 'rxjs/ajax';
import { catchError, map, mergeMap, debounceTime } from 'rxjs/operators';
import { error as errorNotification, success as successNotification, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { OrderBaseFilterEntity } from '../../helpers/order.base.fiter.entity';
import { Client } from '../../business.entities/client';
import storeProvider from '../../helpers/store.provider';
import { Order } from '../../business.entities/order';
import { GetPaymentStatusName, PaymentStatusEnum } from '../../helpers/enums/payment.status.enum';
import { OrderProduct } from '../../business.entities/order.product';
import { GetPrintStatusName } from '../../helpers/enums/print.status.enum';
import { GetLaserStatusName } from '../../helpers/enums/laser.status.enum';
import { PrintVerificationStatusEnum } from '../../helpers/enums/print.verification.status.enum';
import { List } from 'linqts';
import { debounce } from 'rxjs/operator/debounce';
import { GetProductionStatusName } from '../../helpers/enums/production.status';
import Partner from '../../business.entities/partner';
import { setCookie } from '../../helpers/cookies';
import { RemoveRVData } from '../../actions/right.view.actions';

export const GetAllOrders = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ALL_ORDERS),
        mergeMap((action: any) => {
            return ajax.post(`${API.GET_ALL_ORDER}?page=${action.payload.page}&count=${action.payload.count}`,
                JSON.stringify(action.payload.filter),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        var expirydate = new Date();
                        expirydate.setTime(expirydate.getTime() + (100 * 60 * 60 * 24 * 100))
                        setCookie('filter', JSON.stringify(action.payload.filter), expirydate);

                        return orderBaseActions.SetAllOrders((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )


export const GetClientsForFilter = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_CLIENTS_FOR_FILTER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CLIENTS_BY_PARTNTER_AND_PHONE}?search=${action.payload ? action.payload : ''}&uid=`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {

                        let arrayToReturn: any[] = [];
                        (response as any).Body.forEach((element: Client) => {
                            arrayToReturn.push({ value: element.Id.toString(), label: element.PhoneNumber, object: element });
                        });

                        return orderBaseActions.SetClientsForFilter(arrayToReturn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetOrderProductsForOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ORDER_PRODUCTS_FOR_ORDER),
        debounceTime(500),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ALL_ORDER_PRODUCT_BY_ORDER_UID}?uid=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        let orderProducts: OrderProduct[] = (response as any).Body;

                        orderProducts.forEach(product => {
                            if (product.ProductPrintStatus.length > 0)
                                product.SelectedPrintStatus = {
                                    value: product.ProductPrintStatus[0].Status,
                                    label: GetPrintStatusName(product.ProductPrintStatus[0].Status)
                                };

                            if (product.PrintVerificationStatus)
                                product.SelectedVerificationStatus = {
                                    value: product.PrintVerificationStatus,
                                    label: product.PrintVerificationStatus == null ?
                                        'Выберите статус' :
                                        (product.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                                }

                            product.SelectedReuseOption = {
                                value: product.CanBeReused === null ? undefined : product.CanBeReused,
                                label: product.CanBeReused === null ?
                                    'Выберите опцию' :
                                    (product.CanBeReused === false ? 'Не использовать' : 'Использовать')
                            }

                            if (product.ProductProductionStatus.length > 0)
                                product.SelectedProductionStatus = {
                                    value: product.ProductProductionStatus[0].Status,
                                    label: GetProductionStatusName(product.ProductProductionStatus[0].Status)
                                };

                            if (product.ProductLaserStatus.length > 0)
                                product.SelectedLaserStatus = {
                                    value: product.ProductLaserStatus[0].Status,
                                    label: GetLaserStatusName(product.ProductLaserStatus[0].Status)
                                };
                        });

                        return orderBaseActions.SetOrderProductsForOrder(orderProducts);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAllStatusesForOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ALL_STATUSES_FOR_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ALL_STATUSES_FOR_ORDER}?uid=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetAllStatusesForOrder((response as any).Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetOrdersForAssembling = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ORDERS_FOR_ASSEMBLING),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDER_FOR_ASSEMBLING}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        let orders = (response as any).Body;
                        if (orders)
                            orders.forEach(order => {
                                order.IsSelected = true;
                            });

                        return orderBaseActions.SetAllOrders({ Item1: orders, Item2: 0 });
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetOrdersForSendFromStock = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ORDERS_FOR_SEND_FROM_STOCK),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDERS_FOR_SEND_FROM_STOCK}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {

                        let orders = (response as any).Body;
                        if (orders)
                            orders.forEach(order => {
                                order.IsSelected = true;
                            });

                        return orderBaseActions.SetAllOrders({ Item1: orders, Item2: 0 });
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SendFromStock = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.SEND_ORDERS_FROM_STOCK),
        mergeMap((action: any) =>
            ajax.post(`${API.SEND_FROM_STOCK}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(RemoveRVData());
                        return orderBaseActions.SetAllOrders({ Item1: response.response.Body, Item2: 0 });
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SendFromStockRightView = (action$, state$) => 
    action$.pipe(
        ofType(orderBaseActions.SEND_ORDERS_FROM_STOCK_RIGHT_VIEW),
        mergeMap((action: any) => 
            ajax.post(`${API.SEND_FROM_STOCK}`,
            action.payload,
            { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
            .pipe(
                map(response => {
                    storeProvider.getStore().dispatch(success({
                        title: `Заказ успешно отправлен со склада`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return orderBaseActions.SetOrdersAfterSendingFromStockRightView(response.response.Body, 0);
                }),
                catchError(error => {
                    return Observable.of(ErrorHandler(error));
                })
            )
        )
    )

export const GetOrdersForDeclarations = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ORDERS_FOR_DECLARATIONS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ORDER_FOR_DECLARATIONS}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        let orders = (response as any).Body;
                        if (orders)
                            orders.forEach(order => {
                                order.IsSelected = true;
                            });

                        return orderBaseActions.SetAllOrders({ Item1: orders, Item2: 0 });
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const SendForAssemble = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.SEND_ORDERS_FOR_ASSEMBLE),
        mergeMap((action: any) =>
            ajax.post(`${API.SEND_ORDERS_FOR_ASSEMBLE}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(RemoveRVData());
                        return orderBaseActions.SetAllOrders({ Item1: response.response.Body, Item2: 0 });
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const CreateDeclarationsDefault = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CREATE_DECLARATIONS_DEFAULT),
        mergeMap((action: any) => {
            //debugger
            return ajax.post(`${API.DECLARATIONS_DEFAULT}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(RemoveRVData());
                        return orderBaseActions.SetAllOrders({ Item1: response.response.Body, Item2: 0 });
                    }),
                    catchError(error => {
                        storeProvider.getStore().dispatch(orderBaseActions.SetAllOrders({ Item1: [], Item2: 0 }));

                        let errors = JSON.parse(error.response.Message).Message;

                        JSON.parse(errors).forEach(error => {
                            storeProvider.getStore().dispatch(
                                errorNotification({
                                    title: 'Ошибка АПИ',
                                    message: error,
                                    position: 'br',
                                    autoDismiss: 0,
                                }))
                        });

                        return Observable.empty();
                    })
                )
        }
        )
    )

export const CreateDeclarationsMulti = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CREATE_DECLARATIONS_MULTI),
        mergeMap((action: any) =>
            ajax.post(`${API.DECLARATIONS_MULTI}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(RemoveRVData());
                        return orderBaseActions.SetAllOrders({ Item1: response.response.Body, Item2: 0 });
                    }),
                    catchError(error => {
                        storeProvider.getStore().dispatch(orderBaseActions.SetAllOrders({ Item1: [], Item2: 0 }));
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const AddPaymentStatus = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.ADD_PAYMENT_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_PAYMENT_STATUS_FOR_ORDER}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetPaymentStatus(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAllPaymentStatusForOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ALL_PAYMENT_STATUSES_FOR_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ALL_PAYMENT_STATUS_FOR_ORDER}?netId=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetAllPaymentStatusesForOrder((response as any).Body);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const AddConfirmPaymentStatus = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.ADD_CONFIRM_PAYMENT_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_CONFIRM_PAYMENT_STATUS}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(orderBaseActions.ChangePaymentStatusForOrder({
                            value: response.response.Body.Item2.Status,
                            label: GetPaymentStatusName(response.response.Body.Item2.Status)
                        }));

                        if (response.response.Body.Item2.Status === PaymentStatusEnum.PaymentTime)
                            storeProvider.getStore().dispatch(orderBaseActions.ChangePaymentDate(new Date(response.response.Body.Item2.PaymentTime)));

                        return orderBaseActions.SetCurrentConfirmPaymentStatus(response.response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAllConfirmPaymentStatusForOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER}?netId=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetAllConfirmPaymentStatusesForOrder((response as any).Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ChangeOrderProductAvailability = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_ORDER_PRODUCT_AVAILABILITY),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.CHANGE_ORDER_PRODUCT_AVAILABILITY}?uid=${action.payload.productNetId}&status=${action.payload.isAvailable}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetProductAvailability(
                            action.payload.productIndex,
                            (response as any).Body.Item1,
                            (response as any).Body.Item2,
                            (response as any).Body.Item3,
                            (response as any).Body.Item4,
                            (response as any).Body.Item5);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const ChangeOrdersOnPageAvailability = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_ORDER_PRODUCT_AVAILABILITY_FOR_PAGE),
        mergeMap((action: any) => 
            ajax.getJSON(`${API.CHANGE_ORDER_PRODUCT_AVAILABILITY}?uid=${action.payload.productNetId}&status=${action.payload.isAvailable}`,
            { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
            .pipe(
                map(response => {
                    return orderBaseActions.SetProductAvailabilityFromPageRv(
                        action.payload.orderNetId,
                        action.payload.productIndex,
                        (response as any).Body.Item1,
                        (response as any).Body.Item2,
                        (response as any).Body.Item3,
                        (response as any).Body.Item4,
                        (response as any).Body.Item5);
                }),
                catchError(error => {
                    return Observable.of(ErrorHandler(error));
                })
            )
        )
    )

export const GetAllStockStatusForOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ALL_STOCK_STATUS_FOR_ORDER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_ALL_STOCK_STATUSES_FOR_ORDER}?netId=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetAllStockStatusesForOrder((response as any).Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UploadImage = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.UPLOAD_FILES_FOR_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.UPLOAD_ORDER_PRODUCT_IMAGE_ORDER}?netId=${action.payload.orderUid}&orderProductUid=${action.payload.orderProductUid}&fileType=${action.payload.fileType}`,
                action.payload.formData,
                {
                    "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {

                        let updatedOrderProduct: OrderProduct = (response as any).response.Body;
                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderBase.currentOrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderBase.currentOrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return orderBaseActions.SetUpdatedOrderProduct(updatedOrderProduct, index, null, null, null);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RemoveFile = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.REMOVE_FILE_FROM_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.REMOVE_FILE_FROM_ORDER_PRODUCT}?orderProductUid=${action.payload.orderProductUid}&fileType=${action.payload.fileType}`,
                action.payload.formData,
                {
                    "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`
                })
                .pipe(
                    map(response => {
                        let updatedOrderProduct: OrderProduct = (response as any).response.Body;
                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderBase.currentOrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderBase.currentOrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return orderBaseActions.SetUpdatedOrderProduct(updatedOrderProduct, index, null, null, null);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.UPDATE_ORDER_PRODUCT),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER_PRODUCT}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    mergeMap(response => {
                        //debugger
                        let updatedOrderProduct: OrderProduct = (response as any).response.Body.orderProductToReturn;
                        let currentOrderProduct = new List<OrderProduct>(storeProvider.getStore().getState().sales.orderBase.currentOrderProducts)
                            .Where(c => c.Id === updatedOrderProduct.Id)
                            .FirstOrDefault();

                        let index = storeProvider.getStore().getState().sales.orderBase.currentOrderProducts.indexOf(currentOrderProduct);

                        if (updatedOrderProduct.ProductPrintStatus.length > 0)
                            updatedOrderProduct.SelectedPrintStatus = {
                                value: updatedOrderProduct.ProductPrintStatus[0].Status,
                                label: GetPrintStatusName(updatedOrderProduct.ProductPrintStatus[0].Status)
                            };

                        if (updatedOrderProduct.PrintVerificationStatus)
                            updatedOrderProduct.SelectedVerificationStatus = {
                                value: updatedOrderProduct.PrintVerificationStatus,
                                label: updatedOrderProduct.PrintVerificationStatus == null ?
                                    'Выберите статус' :
                                    (updatedOrderProduct.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                            }

                        updatedOrderProduct.SelectedReuseOption = {
                            value: updatedOrderProduct.CanBeReused === null ? undefined : updatedOrderProduct.CanBeReused,
                            label: updatedOrderProduct.CanBeReused === null ?
                                'Выберите опцию' :
                                (updatedOrderProduct.CanBeReused === false ? 'Не использовать' : 'Использовать')
                        }

                        if (updatedOrderProduct.ProductProductionStatus.length > 0)
                            updatedOrderProduct.SelectedProductionStatus = {
                                value: updatedOrderProduct.ProductProductionStatus[0].Status,
                                label: GetProductionStatusName(updatedOrderProduct.ProductProductionStatus[0].Status)
                            };

                        if (updatedOrderProduct.ProductLaserStatus.length > 0)
                            updatedOrderProduct.SelectedLaserStatus = {
                                value: updatedOrderProduct.ProductLaserStatus[0].Status,
                                label: GetLaserStatusName(updatedOrderProduct.ProductLaserStatus[0].Status)
                            };

                        return of(orderBaseActions.SetOrderStatus((response as any).response.Body.orderStatusToReturn),
                            orderBaseActions.SetUpdatedOrderProduct(
                                updatedOrderProduct,
                                index,
                                (response as any).response.Body.PrintStatus,
                                (response as any).response.Body.ProductionStatus,
                                (response as any).response.Body.LaserStatus));
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateOrder = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.UPDATE_ORDER),
        mergeMap((action: any) =>
            ajax.post(`${API.UPDATE_ORDER}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetUpdatedOrder((response as any).response.Body);
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const UpdateUserImageComment = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_USER_IMAGE_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const orderProducts = storeProvider.getStore().getState().sales.orderBase.currentOrderProducts;

            let orderProduct: OrderProduct = new List<OrderProduct>(orderProducts).FirstOrDefault(c => c.Id === action.payload.id);

            return orderBaseActions.UpdateOrderProduct(orderProduct);
        })
    )

export const UpdatePrintComment = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_PRINT_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const orderProducts = storeProvider.getStore().getState().sales.orderBase.currentOrderProducts;

            let orderProduct: OrderProduct = new List<OrderProduct>(orderProducts).FirstOrDefault(c => c.Id === action.payload.id);

            return orderBaseActions.UpdateOrderProduct(orderProduct);
        })
    )

export const UpdateProductionOperatorComment = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_PRODUCTION_OPERATOR_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const order = storeProvider.getStore().getState().sales.orderBase.orders[storeProvider.getStore().getState().sales.orderBase.currentOrderIndex];

            return orderBaseActions.UpdateOrder(order);
        })
    )

export const UpdateProductionComment = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.CHANGE_PRODUCTION_COMMENT),
        debounceTime(2000),
        map((action: any) => {
            const order = storeProvider.getStore().getState().sales.orderBase.orders[storeProvider.getStore().getState().sales.orderBase.currentOrderIndex];

            return orderBaseActions.UpdateOrder(order);
        })
    )

export const ProcessFailedOrderProduct = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.PROCESS_FAIL_MULTI),
        mergeMap((action: any) =>
            ajax.post(`${API.PROCESS_FAIL_ORDER_PRODUCT_MULTI}?failCount=${action.payload.failCount}`,
                action.payload.orderProduct,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        //go to order base and show notification
                        let order: Order = (response as any).response.Body;

                        const filteredOrders = state$.value.sales.orderBase.orders.filter(c => c.Id === order.Id);
                        const index = state$.value.sales.orderBase.orders.indexOf(filteredOrders[0]);

                        order.OrderProducts.forEach(product => {
                            if (product.ProductPrintStatus.length > 0)
                                product.SelectedPrintStatus = {
                                    value: product.ProductPrintStatus[0].Status,
                                    label: GetPrintStatusName(product.ProductPrintStatus[0].Status)
                                };

                            if (product.PrintVerificationStatus)
                                product.SelectedVerificationStatus = {
                                    value: product.PrintVerificationStatus,
                                    label: product.PrintVerificationStatus == null ?
                                        'Выберите статус' :
                                        (product.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                                }

                            product.SelectedReuseOption = {
                                value: product.CanBeReused === null ? undefined : product.CanBeReused,
                                label: product.CanBeReused === null ?
                                    'Выберите опцию' :
                                    (product.CanBeReused === false ? 'Не использовать' : 'Использовать')
                            }

                            if (product.ProductProductionStatus.length > 0)
                                product.SelectedProductionStatus = {
                                    value: product.ProductProductionStatus[0].Status,
                                    label: GetProductionStatusName(product.ProductProductionStatus[0].Status)
                                };
                        });

                        return orderBaseActions.SetOrderOnBaseByIndex(order, index);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetPartnerAutocomplete = (action$, store) =>
    action$.ofType(orderBaseActions.GET_PARTNERS_FOR_FILTER)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PARTNER_AUTOCOMPLETE}?value=${action.payload ? action.payload : ''}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    let arrayToReturn: any[] = response.Body.map((element: Partner) => {
                        return { value: element.NetUid.toString(), label: element.ContactName, partnerId: element.Id };
                    });

                    return orderBaseActions.SetPartnersForFilter(arrayToReturn);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetClientToRestoreFilter = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_CLIENT_TO_RESTORE_FILTER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_CLIENT}?clientId=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        const client: Client = (response as any).Body;
                        return orderBaseActions.SelectClientsForFilter({ value: client.Id.toString(), label: client.PhoneNumber, object: client });
                    }),
                    catchError(error => {


                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetPartnerToRestoreFilter = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_PARTNER_TO_RESTORE_FILTER),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PARTNER}?netId=${action.payload}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        const partner: Partner = (response as any).Body.Partner;
                        return orderBaseActions.SelectPartnerForFilter({ value: partner.NetUid.toString(), label: partner.ContactName, partnerId: partner.Id });
                    }),
                    catchError(error => {

                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const AddOrderStatus = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.ADD_ORDER_STATUS),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_ORDER_STATUS}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(success({
                            title: (response as any).Message,
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));

                        return orderBaseActions.SetOrderStatus((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GenerateReesters = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GENERATE_REESTERS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GENERATE_REESTERS}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(success({
                            title: (response as any).Message,
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));

                        if ((response as any).Body && (response as any).Body.Item2) {
                            let errors = JSON.parse((response as any).Body.Item2);

                            errors.forEach(error => {
                                storeProvider.getStore().dispatch(
                                    errorNotification({
                                        title: 'Ошибка АПИ',
                                        message: error,
                                        position: 'br',
                                        autoDismiss: 0,
                                    }))
                            });
                        }

                        return orderBaseActions.FinishAction();
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetReesters = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_REESTERS),
        mergeMap((action: any) => {
            return ajax.post(`${API.GET_REESTERS_FILTERED}`,
                JSON.stringify({
                    Date: action.payload.ReestersDate,
                    TTN: action.payload.ReestersTtn,
                    OrderCode: action.payload.ReestersOrderCode
                }),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(success({
                            title: (response as any).response.Message,
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));

                        return orderBaseActions.SetReesters((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

export const ChangeTTNHard = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.UPDATE_ORDER_TTN),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.SET_TTN_HARD}?orderId=${action.payload.orderId}&ttn=${action.payload.ttn}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        storeProvider.getStore().dispatch(success({
                            title: (response as any).Message,
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));
                        return orderBaseActions.SetOrderTTN(action.payload.ttn);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetAutocompleteModelResult = (action$, state) =>
    action$.ofType(orderBaseActions.GET_AUTOCOMPLETE_MODEL_RESULT)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_AUTOCOMPLETE_MODEL_RESULT}?value=${action.payload}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Модели получены',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    let modelSelectOptionData = response.Body.map(option =>
                        ({ value: option.Id.toString(), label: `${option.Parent.Name} ${option.Name}` })
                    );

                    return orderBaseActions.GetAutocompliteModelSuccess(modelSelectOptionData);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetAutocompleteLexiconResult = (action$, state) =>
    action$.ofType(orderBaseActions.GET_AUTOCOMPLETE_LEXICON_RESULT)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_AUTOCOMPLETE_LEXICON_RESULT}?value=${action.payload}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Лексиконы получены',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    let lexiconSelectOptionData = response.Body.map(option =>
                        ({ value: option.Id.toString(), label: `${option.OfficialPartnerName}` })
                    );

                    return orderBaseActions.GetAutocompliteLexiconSuccess(lexiconSelectOptionData);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetOrderExportUrl = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GENERATE_ORDER_EXPORT_LIST),
        mergeMap((action: any) => {
            return ajax.post(`${API.GET_ORDER_EXPORT_URL}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return orderBaseActions.SetOrderExportListUrl((response as any).response.Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

    export const GetOrderProductsForAllOrdersOnPage = (action$, state$) =>
    action$.pipe(
        ofType(orderBaseActions.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE),
        mergeMap((action: any) => {
            return ajax.post(`${API.GET_ORDER_PRODUCTS_FOR_PAGE}?page=${action.payload.page}&count=${action.payload.count}`,
                JSON.stringify(action.payload.filter),
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {                        
                        let orders: any[] = (response as any).response.Body;
                        
                        orders.forEach(order => {
                            order.OrderProducts.forEach(product => {
                                if (product.ProductPrintStatus.length > 0)
                                product.SelectedPrintStatus = {
                                    value: product.ProductPrintStatus[0].Status,
                                    label: GetPrintStatusName(product.ProductPrintStatus[0].Status)
                                };

                            if (product.PrintVerificationStatus)
                                product.SelectedVerificationStatus = {
                                    value: product.PrintVerificationStatus,
                                    label: product.PrintVerificationStatus == null ?
                                        'Выберите статус' :
                                        (product.PrintVerificationStatus === PrintVerificationStatusEnum.Failed ? 'Не пройдена' : 'Пройдена')
                                }

                            product.SelectedReuseOption = {
                                value: product.CanBeReused === null ? undefined : product.CanBeReused,
                                label: product.CanBeReused === null ?
                                    'Выберите опцию' :
                                    (product.CanBeReused === false ? 'Не использовать' : 'Использовать')
                            }

                            if (product.ProductProductionStatus.length > 0)
                                product.SelectedProductionStatus = {
                                    value: product.ProductProductionStatus[0].Status,
                                    label: GetProductionStatusName(product.ProductProductionStatus[0].Status)
                                };

                            if (product.ProductLaserStatus.length > 0)
                                product.SelectedLaserStatus = {
                                    value: product.ProductLaserStatus[0].Status,
                                    label: GetLaserStatusName(product.ProductLaserStatus[0].Status)
                                };
                            });
                        });

                        return orderBaseActions.GetOrderProductsForAllOrdersOnPageSuccess(orders)
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )