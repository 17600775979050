import * as React from 'react';
import { ApplicationState } from '../../../../Reducers/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form } from 'react-redux-form';
import { HoldBlock } from './formblocks/hold.block';
import { Partner } from '../../../../business.entities/partner';
import { PriceTerm } from '../../../../business.entities/price.term';
import { ReachBlock } from './formblocks/reach.block';
import { TextAreaWithLabel } from '../../../shared/form.controls/textarea.with.label';
import { TextBox } from '../../../shared/form.controls/textbox';
import { TextBoxWithLabel } from '../../../shared/form.controls/textbox.with.label';
import { Validator } from '../../../../helpers/validator.helper';
import * as priceActions from "../../../../actions/stock/price.actions";

export interface IPartnerPriceLevelTerms {
    SelectedPartner: Partner;
    CreatePriceTerm: (priceTerm: PriceTerm) => any;
    UpdatePriceTerms: (priceTerm: PriceTerm) => any;
    RemovePriceTerm: (netid: string) => any;
    CurrentPriceTerm: PriceTerm;
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        SelectedPartner: state.stock.price.selectedPartner,
        CurrentPriceTerm: (state as any).priceLevelTerms
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        CreatePriceTerm: priceActions.CreatePartnerPriceTerms,
        UpdatePriceTerms: priceActions.UpdatePartnerPriceTerms,
        RemovePriceTerm: priceActions.RemovePriceTerm
    }, dispatch);
};

const PartnerPriceLevelTerms = (props: IPartnerPriceLevelTerms | any) => {
    const onSubmitCommand = (priceTerm: PriceTerm) => {
        let partnerTermToUpdate = Object.assign({}, priceTerm);
        if (props.SelectedPartner) {
            partnerTermToUpdate.IsDefault = false;

            if (!priceTerm.PartnerId) {
                partnerTermToUpdate.PartnerId = (props.SelectedPartner as any).partnerId;

                props.CreatePriceTerm(partnerTermToUpdate);
            } else {
                props.UpdatePriceTerms(priceTerm);
            }
        } else {
            props.UpdatePriceTerms(priceTerm);
        }
    }

    const onRemovePriceTerm = () => {
        !props.CurrentPriceTerm.IsDefault && props.RemovePriceTerm(props.CurrentPriceTerm.NetUid);
    }

    return (
        <Form
            validateOn='change'
            model="priceLevelTerms"
            onSubmit={onSubmitCommand}
        >

            <div className="price_level__terms__row">
                <div className="terms__row__title">
                    Умови досягнення цінових рівнів
                </div>

                <ReachBlock
                    PriceLevel="Дропшипінг 1 (Д1)"
                    ValueModel=".D1OrderPriceSum"
                    CommentModel=".D1SumComment"
                />

                <ReachBlock
                    PriceLevel="Дропшипінг 2 (Д2)"
                    ValueModel=".D2OrderPriceSum"
                    CommentModel=".D2SumComment"
                />

                <ReachBlock
                    PriceLevel="Дропшипінг 3 (Д3)"
                    ValueModel=".D3OrderPriceSum"
                    CommentModel=".D3SumComment"
                />

                <ReachBlock
                    PriceLevel="Дропшипінг 4 (Д4)"
                    ValueModel=".D4OrderPriceSum"
                    CommentModel=".D4SumComment"
                />

                <ReachBlock
                    PriceLevel="Дропшипінг 5 (Д5)"
                    ValueModel=".D5OrderPriceSum"
                    CommentModel=".D5SumComment"
                />

                <ReachBlock
                    PriceLevel="Дропшипінг 6 (Д6)"
                    ValueModel=".D6OrderPriceSum"
                    CommentModel=".D6SumComment"
                />
            </div>
            <div className="price_level__terms__row">
                <div className="terms__row__title">
                    Умови утримання цінових рівнів
                </div>

                <HoldBlock
                    PriceLevel="Дропшипінг 1 (Д1)"
                    CommentModel=".D1RangeComment"
                    MinModel=".D1MinOrderSum"
                    MaxModel=".D1MaxOrderSum"
                />

                <HoldBlock
                    PriceLevel="Дропшипінг 2 (Д2)"
                    CommentModel=".D2RangeComment"
                    MinModel=".D2MinOrderSum"
                    MaxModel=".D2MaxOrderSum"
                />

                <HoldBlock
                    PriceLevel="Дропшипінг 3 (Д3)"
                    CommentModel=".D3RangeComment"
                    MinModel=".D3MinOrderSum"
                    MaxModel=".D3MaxOrderSum"
                />

                <HoldBlock
                    PriceLevel="Дропшипінг 4 (Д4)"
                    CommentModel=".D4RangeComment"
                    MinModel=".D4MinOrderSum"
                    MaxModel=".D4MaxOrderSum"
                />

                <HoldBlock
                    PriceLevel="Дропшипінг 5 (Д5)"
                    CommentModel=".D5RangeComment"
                    MinModel=".D5MinOrderSum"
                    MaxModel=".D5MaxOrderSum"
                />

                <HoldBlock
                    PriceLevel="Дропшипінг 6 (Д6)"
                    CommentModel=".D6RangeComment"
                    MinModel=".D6MinOrderSum"
                    MaxModel=".D6MaxOrderSum"
                />
            </div>
            <div className="price_level__terms__row">
                <div className="row__line__style"></div>
                <div className="defaultFormControl ">
                    <div className="rightPullControls ">
                        {
                            !props.CurrentPriceTerm.IsDefault ?
                                <div className="defaultFormButton mWidth" onClick={onRemovePriceTerm}>Удалить</div>
                                : null
                        }
                        <button type="submit" className="defaultFormButton mWidth">Сохранить</button>
                    </div>
                </div>
            </div>
        </Form>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PartnerPriceLevelTerms)