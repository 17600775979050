import * as Api from '../constants/api.constants';
import { actions } from 'react-redux-form';
import { ADD_PARTNER } from '../actions/registration.actions';
import { ErrorHandler } from '../helpers/error.manager';
import { FailedRegistration, SuccessRegistration } from '../actions/registration.actions';
import { isUnauthorized } from '../helpers/unauthorize.hendle';
import { Observable } from 'rxjs/Observable';
import { push } from 'react-router-redux';
import { success } from 'react-notification-system-redux';
import storeProvider from '../helpers/store.provider';
import { debounceTime, map } from 'rxjs/operators';
import { ofType } from 'redux-observable';

export const addPartner = (action$, store) =>
    action$.ofType(ADD_PARTNER)
        .switchMap(action =>
            Observable.ajax.post(Api.ADD_PARTNER, JSON.stringify(action.payload), { "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Регистрация прошла успешно',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    actions.reset("partnerForm");
                    
                    return SuccessRegistration();
                }).catch((errorData: any) => {
                    storeProvider.getStore().dispatch(FailedRegistration());
                    return Observable.of(ErrorHandler(errorData, store));
                })
        );

export const AfterRegister = (action$, state$) =>
    action$.pipe(
        ofType(ADD_PARTNER),
        debounceTime(15000),
        map((action: any) => {
            return push('/auth/login');
        })
    )