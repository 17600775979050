import { Reducer } from "redux";
import { AviabilityState, defaultAvailabilityState } from "../../states/stock/aviability.state";
import { AviabilityKnownActions } from "../../actions/stock/aviability.actions";
import * as AbiavilityActionTypes from "../../actions/stock/aviability.actions";
// fetchInProccess: true
const reducer: Reducer<AviabilityState> = (state: AviabilityState, action: AviabilityKnownActions) => {
  switch (action.type) {
    case AbiavilityActionTypes.GET_AUTOCOMPLETE_LEXICONS_SUCCESS:
      return { ...state, optionLexiconList: action.payload };

    case AbiavilityActionTypes.GET_AUTOCOMPLETE_MODEL_SUCCESS:
      return { ...state, optionModelList: action.payload };

    case AbiavilityActionTypes.GET_AUTOCOMPLETE_ARTICULUS_SUCCESS:
      return { ...state, optionArticulusList: action.payload };

    case AbiavilityActionTypes.GET_FILTER_SUB_CATEGORY_LIST_SUCCESS:
      return { ...state, optionFilterSubCategoryList: action.payload };

    case AbiavilityActionTypes.GET_FILTER_CATEGORY_SUCCESS:
      return { ...state, optionFilterCategoryList: action.payload };

    case AbiavilityActionTypes.CHANGE_LEXICON_VALUE:
      return {
        ...state,
        selectedLexiconValue: action.payload,
        filterChanged: true
      };

    case AbiavilityActionTypes.CHANGE_MODEL_VALUE:
      return {
        ...state,
        selectedModelValue: action.payload,
        productValue: action.payload,
        selectedFilterCategoryValue: null,
        filterChanged: true
      };

    case AbiavilityActionTypes.CHANGE_ARTICULUS_VALUE:
      return {
        ...state,
        selectedArticulusValue: action.payload,
        filterChanged: true
      };

    case AbiavilityActionTypes.CHANGE_FILTER_CATEGORY_VALUE:
      return {
        ...state,
        selectedFilterCategoryValue: action.payload,
        selectedModelValue: null,
        productValue: null,
        filterChanged: true
      };

    case AbiavilityActionTypes.CHANGE_FILTER_SUB_CATEGORY_VALUE:
      return {
        ...state,
        selectedFilterSubCategoryValue: action.payload,
        productValue: action.payload,
        selectedModelValue: null,
        filterChanged: true
      };

    case AbiavilityActionTypes.RESET_SELECTED_VALUES:
      return {
        ...state,
        optionModelList: [],
        optionArticulusList: [],
        optionLexiconList: [],
        optionFilterSubCategoryList: [],

        selectedModelValue: null,
        selectedArticulusValue: null,
        selectedLexiconValue: null,
        selectedFilterCategoryValue: null,
        selectedFilterSubCategoryValue: null,
        totalPages: 0,
        categories: []
      };

    case AbiavilityActionTypes.GET_CATEGORIES_FOR_SELECTED_PRODUCT:
      return { ...state, fetchInProccess: true };

    case AbiavilityActionTypes.GET_CATEGORIES_FOR_SELECTED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetchInProccess: false,
        filterChanged: false,
        categories: action.payload.categories,
        totalPages: action.payload.totalPages
      };

    case AbiavilityActionTypes.UNMOUNT_COMPONENT:
      return Object.assign({}, defaultAvailabilityState);

    default:
      const exhaustiveCheck: any = action;
      return state || defaultAvailabilityState;
  }
};

export default reducer;