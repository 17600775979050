import { Platform } from "../../business.entities/platform";

export const GET_ALL: string = '[API] PLATFORM.GET_ALL';
export interface IGetAll { type: '[API] PLATFORM.GET_ALL'; payload: any; }
export const GetAll = () =>
    <IGetAll>{ type: GET_ALL };

export const SET_ALL: string = 'PLATFORM.SET_ALL';
export interface ISetAll { type: 'PLATFORM.SET_ALL'; payload: any; }
export const SetAll = (platforms: Platform[]) =>
    <ISetAll>{ type: SET_ALL, payload: platforms };

export type PlatformKnownActions = IGetAll | ISetAll;