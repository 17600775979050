import Product from "../../business.entities/product";
import { TempOrder } from "../../business.entities/temp.order";
import { actions } from "react-redux-form";
import { OrderProduct } from "../../business.entities/order.product";

export const APPROVE_ORDER_CREATION = 'WIZARD.APPROVE_ORDER_CREATION';
export interface IApproveOrderCreation { type: 'WIZARD.APPROVE_ORDER_CREATION', payload: any }
export const ApproveOrderCreation = () =>
    <IApproveOrderCreation>{ type: APPROVE_ORDER_CREATION };

export const CREATE_TEMP_ORDER = '[API] WIZARD.CREATE_TEMP_ORDER';
export interface ICreateTempOrder { type: '[API] WIZARD.CREATE_TEMP_ORDER' }
export const CreateTempOrder = () =>
    <ICreateTempOrder>{ type: CREATE_TEMP_ORDER };

export const SET_TEMP_ORDER = 'WIZARD.SET_TEMP_ORDER';
export interface ISetTempOrder { type: 'WIZARD.SET_TEMP_ORDER', payload: any }
export const SetTempOrder = (tempOrder: TempOrder) =>
    <ISetTempOrder>{ type: SET_TEMP_ORDER, payload: tempOrder };

export const SELECT_WIZARD_STEP = 'WIZARD.SELECT_WIZARD_STEP';
export interface ISelectWizardStep { type: 'WIZARD.SELECT_WIZARD_STEP', payload: any }
export const SelectWizardStep = (component, stepNumber) =>
    <ISelectWizardStep>{ type: SELECT_WIZARD_STEP, payload: { component, stepNumber } };

export const CLEAR_WIZARD = 'WIZARD.CLEAR_WIZARD';
export interface IClearWizard { type: 'WIZARD.CLEAR_WIZARD', payload: any }
export const ClearWizard = () =>
    <IClearWizard>{ type: CLEAR_WIZARD, payload: {} };

export const GET_CATEGORIES = '[API] WIZARD.GET_CATEGORIES';
export interface IGetCategories { type: '[API] WIZARD.GET_CATEGORIES', payload: any }
export const GetCategories = (value: string) =>
    <IGetCategories>{ type: GET_CATEGORIES, payload: value };

export const SET_CATEGORIES = 'WIZARD.SET_CATEGORIES';
export interface ISetCategories { type: 'WIZARD.SET_CATEGORIES', payload: any }
export const SetCategories = value => <ISetCategories>({ type: SET_CATEGORIES, payload: value });

export const GET_SUBCATEGORIES = '[API] WIZARD.GET_SUBCATEGORIES';
export interface IGetSubCategories { type: '[API] WIZARD.GET_SUBCATEGORIES', payload: any }
export const GetSubCategories = (id, value) => <IGetSubCategories>{ type: GET_SUBCATEGORIES, payload: { id, value } };

export const SET_SUBCATEGORIES = 'WIZARD.SET_SUBCATEGORIES';
export interface ISetSubCategories { type: 'WIZARD.SET_SUBCATEGORIES', payload: any }
export const SetSubCategories = value => <ISetSubCategories>({ type: SET_SUBCATEGORIES, payload: value });

export const GET_PRODUCTS = '[API] WIZARD.GET_PRODUCTS';
export interface IGetProducts { type: '[API] WIZARD.GET_PRODUCTS', payload: any }
export const GetProducts = (categoryId, subCategoryId) => <IGetProducts>{ type: GET_PRODUCTS, payload: { categoryId, subCategoryId } };

export const GET_ADDITIONAL_SERVICE_PRODUCTS = '[API] WIZARD.GET_ADDITIONAL_SERVICE_PRODUCTS';
export interface IGetAdditionalServiceProducts { type: '[API] WIZARD.GET_ADDITIONAL_SERVICE_PRODUCTS' }
export const GetAdditionalServiceProducts = () => <IGetAdditionalServiceProducts>{ type: GET_ADDITIONAL_SERVICE_PRODUCTS };

export const SET_PRODUCTS = 'WIZARD.SET_PRODUCTS';
export interface ISetProducts { type: 'WIZARD.SET_PRODUCTS', payload: any }
export const SetProducts = value => <ISetProducts>({ type: SET_PRODUCTS, payload: value });

export const SET_ADDITIONAL_SERVICE_PRODUCTS = 'WIZARD.SET_ADDITIONAL_SERVICE_PRODUCTS';
export interface ISetAdditionalServiceProducts { type: 'WIZARD.SET_ADDITIONAL_SERVICE_PRODUCTS', payload: any }
export const SetAdditionalServiceProducts = value => <ISetAdditionalServiceProducts>({ type: SET_ADDITIONAL_SERVICE_PRODUCTS, payload: value });

export const SET_EXPRESS_PRINT_PRODUCT = 'WIZARD.SET_EXPRESS_PRINT_PRODUCT';
export interface ISetExpressPrintProduct { type: 'WIZARD.SET_EXPRESS_PRINT_PRODUCT', payload: Product }
export const SetExpressPrintProduct = product => <ISetExpressPrintProduct>({ type: SET_EXPRESS_PRINT_PRODUCT, payload: product });

export const SET_EXTRA_PRINT_PRODUCT = 'WIZARD.SET_EXTRA_PRINT_PRODUCT';
export interface ISetExtraPrintProduct { type: 'WIZARD.SET_EXTRA_PRINT_PRODUCT', payload: Product }
export const SetExtraPrintProduct = product => <ISetExtraPrintProduct>({ type: SET_EXTRA_PRINT_PRODUCT, payload: product });

export const SELECT_CATEGORY = 'WIZARD.SELECT_CATEGORY';
export interface ISelectCategory { type: 'WIZARD.SELECT_CATEGORY', payload: any }
export const SelectCategory = value => <ISelectCategory>({ type: SELECT_CATEGORY, payload: value });

export const SELECT_SUBCATEGORY = 'WIZARD.SELECT_SUBCATEGORY';
export interface ISelectSubCategory { type: 'WIZARD.SELECT_SUBCATEGORY', payload: any }
export const SelectSubCategory = value => <ISelectSubCategory>({ type: SELECT_SUBCATEGORY, payload: value });

export const SET_SUBCATEGORY_REF = 'WIZARD.SET_SUBCATEGORY_REF';
export interface ISetSubCategoryRef { type: 'WIZARD.SET_SUBCATEGORY_REF', payload: any }
export const SetSubCategoryRef = value => <ISetSubCategoryRef>({ type: SET_SUBCATEGORY_REF, payload: value });

export const SELECT_CURRENT_PRODUCT = 'WIZARD.SELECT_CURRENT_PRODUCT';
export interface ISelectCurrentProduct { type: 'WIZARD.SELECT_CURRENT_PRODUCT', payload: any }
export const SelectCurrentProduct = value => <ISelectCurrentProduct>({ type: SELECT_CURRENT_PRODUCT, payload: value });

export const SET_NEXT_SUBSTEP = 'WIZARD.SET_NEXT_SUBSTEP';
export interface ISetNextSubStep { type: 'WIZARD.SET_NEXT_SUBSTEP', payload: any }
export const SetNextSubStep = (stepEnum, component) => <ISetNextSubStep>({ type: SET_NEXT_SUBSTEP, payload: { stepEnum, component } });

export const CONFIRM_PRODUCT_SELECT = 'WIZARD.CONFIRM_PRODUCT_SELECT';
export interface IConfirmProductSelect { type: 'WIZARD.CONFIRM_PRODUCT_SELECT', payload: any }
export const ConfirmProductSelect = () => <IConfirmProductSelect>({ type: CONFIRM_PRODUCT_SELECT });

export const SET_PATTERN = 'WIZARD.SET_PATTERN';
export interface ISetPattern { type: 'WIZARD.SET_PATTERN', payload: any }
export const SetPattern = (pattern) => <ISetPattern>({ type: SET_PATTERN, payload: pattern });

export const SELECT_CURRENT_PRODUCT_COLOR = 'WIZARD.SELECT_CURRENT_PRODUCT_COLOR';
export interface ISelectCurrentProductColor { type: 'WIZARD.SELECT_CURRENT_PRODUCT_COLOR', payload: any }
export const SelectCurrentProductColor = value => <ISelectCurrentProductColor>({ type: SELECT_CURRENT_PRODUCT_COLOR, payload: value });

export const CONFIRM_PRODUCT_COLOR_SELECT = 'WIZARD.CONFIRM_PRODUCT_COLOR_SELECT';
export interface IConfirmProductColorSelect { type: 'WIZARD.CONFIRM_PRODUCT_COLOR_SELECT', payload: any }
export const ConfirmProductColorSelect = () => <IConfirmProductColorSelect>({ type: CONFIRM_PRODUCT_COLOR_SELECT });

export const CANCEL_CURRENT_ORDER_PRODUCT = 'WIZARD.CANCEL_CURRENT_ORDER_PRODUCT';
export interface ICancelCurrentOrderProduct { type: 'WIZARD.CANCEL_CURRENT_ORDER_PRODUCT', payload: any }
export const CancelCurrentOrderProduct = () => <ICancelCurrentOrderProduct>({ type: CANCEL_CURRENT_ORDER_PRODUCT });

export const CHANGE_CURRENT_ORDER_PRODUCT_PRICE = 'WIZARD.CHANGE_CURRENT_ORDER_PRODUCT_PRICE';
export interface IChangeCurrentOrderProductPrice { type: 'WIZARD.CHANGE_CURRENT_ORDER_PRODUCT_PRICE', payload: any }
export const ChangeCurrentOrderProductPrice = value => <IChangeCurrentOrderProductPrice>({ type: CHANGE_CURRENT_ORDER_PRODUCT_PRICE, payload: value });

export const CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY = 'WIZARD.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY';
export interface IChangeCurrentOrderProductQuantity { type: 'WIZARD.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY', payload: any }
export const ChangeCurrentOrderProductQuantity = value => <IChangeCurrentOrderProductQuantity>({ type: CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY, payload: value });

export const SET_BASES_FOR_PRODUCT = 'WIZARD.SET_BASES_FOR_PRODUCT';
export interface ISetBasesForProduct { type: 'WIZARD.SET_BASES_FOR_PRODUCT', payload: Product[] }
export const SetBasesForProduct = (bases: Product[]) => <ISetBasesForProduct>({ type: SET_BASES_FOR_PRODUCT, payload: bases });

export const SELECT_CURRENT_BASE_PRODUCT = 'WIZARD.SELECT_CURRENT_BASE_PRODUCT';
export interface ISelectCurrentBaseProduct { type: 'WIZARD.SELECT_CURRENT_BASE_PRODUCT', payload: Product }
export const SelectCurrentBaseProduct = (value: Product) => <ISelectCurrentBaseProduct>({ type: SELECT_CURRENT_BASE_PRODUCT, payload: value });

export const CONFIRM_CURRENT_BASE_PRODUCT = 'WIZARD.CONFIRM_CURRENT_BASE_PRODUCT';
export interface IConfirmBaseProductSelect { type: 'WIZARD.CONFIRM_CURRENT_BASE_PRODUCT' }
export const ConfirmBaseProductSelect = () => <IConfirmBaseProductSelect>({ type: CONFIRM_CURRENT_BASE_PRODUCT });

export const SELECT_CURRENT_BASE_COLOR = 'WIZARD.SELECT_CURRENT_BASE_COLOR';
export interface ISelectCurrentBaseColor { type: 'WIZARD.SELECT_CURRENT_BASE_COLOR', payload: any }
export const SelectCurrentBaseColor = value => <ISelectCurrentBaseColor>({ type: SELECT_CURRENT_BASE_COLOR, payload: value });

export const CONFIRM_BASE_COLOR_SELECT = 'WIZARD.CONFIRM_BASE_COLOR_SELECT';
export interface IConfirmBaseColorSelect { type: 'WIZARD.CONFIRM_BASE_COLOR_SELECT', payload: any }
export const ConfirmBaseColorSelect = () => <IConfirmBaseColorSelect>({ type: CONFIRM_BASE_COLOR_SELECT });

export const GO_TO_PREV_STEP = 'WIZARD.GO_TO_PREV_STEP';
export interface IGotoPrevStep { type: 'WIZARD.GO_TO_PREV_STEP' }
export const GotoPrevStep = () => <IGotoPrevStep>({ type: GO_TO_PREV_STEP });

export const GO_TO_NEXT_STEP = 'WIZARD.GO_TO_NEXT_STEP';
export interface IGotoNextStep { type: 'WIZARD.GO_TO_NEXT_STEP' }
export const GotoNextStep = () => <IGotoNextStep>({ type: GO_TO_NEXT_STEP });

export const SAVE_ORDER_PRODUCT = '[API] WIZARD.SAVE_ORDER_PRODUCT';
export interface ISaveOrderProduct { type: '[API] WIZARD.SAVE_ORDER_PRODUCT', payload: OrderProduct }
export const SaveOrderProduct = (orderProduct: OrderProduct) => <ISaveOrderProduct>({ type: SAVE_ORDER_PRODUCT, payload: orderProduct });

export const SET_ORDER_PRODUCT = 'WIZARD.SET_ORDER_PRODUCT';
export interface ISetOrderProduct { type: 'WIZARD.SET_ORDER_PRODUCT', payload: any }
export const SetOrderProduct = (orderProduct, partnerTotal, total, orderType) => <ISetOrderProduct>({ type: SET_ORDER_PRODUCT, payload: { orderProduct, partnerTotal, total, orderType } });

export const SET_ORDER_PRODUCT_BY_INDEX = 'WIZARD.SET_ORDER_PRODUCT_BY_INDEX';
export interface ISetOrderProductByIndex { type: 'WIZARD.SET_ORDER_PRODUCT_BY_INDEX', payload: any }
export const SetOrderProductByIndex = (orderProduct, index, partnerTotal, total, orderType) =>
    <ISetOrderProductByIndex>({ type: SET_ORDER_PRODUCT_BY_INDEX, payload: { orderProduct, index, partnerTotal, total, orderType } });

export const CHANGE_PRODUCT_SELECTION_MODE = 'WIZARD.CHANGE_PRODUCT_SELECTION_MODE';
export interface IChangeProductSelectionMode { type: 'WIZARD.CHANGE_PRODUCT_SELECTION_MODE', payload: boolean }
export const ChangeProductSelectionMode = (isProductMode: boolean) =>
    <IChangeProductSelectionMode>({ type: CHANGE_PRODUCT_SELECTION_MODE, payload: isProductMode });

export const SELECT_ORDER_PRODUCT = 'WIZARD.SELECT_ORDER_PRODUCT';
export interface ISelectOrderProduct { type: 'WIZARD.SELECT_ORDER_PRODUCT', payload: any }
export const SelectOrderProduct = index => <ISelectOrderProduct>({ type: SELECT_ORDER_PRODUCT, payload: index });

export const CONFIRM_ORDER_PRODUCT_SELECTION = 'WIZARD.CONFIRM_ORDER_PRODUCT_SELECTION';
export interface IConfirmOrderProductSelection { type: 'WIZARD.CONFIRM_ORDER_PRODUCT_SELECTION', payload: any }
export const ConfirmOrderProductSelection = orderProduct => <IConfirmOrderProductSelection>({ type: CONFIRM_ORDER_PRODUCT_SELECTION, payload: orderProduct });

export const REMOVE_ORDER_PRODUCT_CLIENT = 'WIZARD.REMOVE_ORDER_PRODUCT_CLIENT';
export interface IRemoveOrderProductClient { type: 'WIZARD.REMOVE_ORDER_PRODUCT_CLIENT', payload: any }
export const RemoveOrderProductClient = (index, partnerTotal, total, orderType) =>
    <IRemoveOrderProductClient>({ type: REMOVE_ORDER_PRODUCT_CLIENT, payload: { index, partnerTotal, total, orderType } });

export const GET_CLIENTS_FOR_PARTNER = '[API] WIZARD.GET_CLIENTS_FOR_PARTNER';
export interface IGetClientsForPartner { type: '[API] WIZARD.GET_CLIENTS_FOR_PARTNER', payload: any }
export const GetClientsForPartner = (search: string) =>
    <IGetClientsForPartner>({ type: GET_CLIENTS_FOR_PARTNER, payload: search });

export const SET_CLIENT_PHONE = 'WIZARD.SET_CLIENT_PHONE';
export interface ISetClientPhone { type: 'WIZARD.SET_CLIENT_PHONE', payload: any }
export const SetClientPhone = (phone, mode) =>
    <ISetClientPhone>({ type: SET_CLIENT_PHONE, payload: { phone, mode } });

export const SET_CLIENTS_FOR_PARTNER = 'WIZARD.SET_CLIENTS_FOR_PARTNER';
export interface ISetClientsForPartner { type: 'WIZARD.SET_CLIENTS_FOR_PARTNER', payload: any }
export const SetClientsForPartner = clients =>
    <ISetClientsForPartner>({ type: SET_CLIENTS_FOR_PARTNER, payload: clients });

export const SELECT_CLIENT_PARTNER = 'WIZARD.SELECT_CLIENT_PARTNER';
export interface ISelectClientPartner { type: 'WIZARD.SELECT_CLIENT_PARTNER', payload: any }
export const SelectClientPartner = value =>
    <ISelectClientPartner>({ type: SELECT_CLIENT_PARTNER, payload: value });

export const RESET_CLIENT_INFO = 'WIZARD.RESET_CLIENT_INFO';
export interface IResetClientInfo { type: 'WIZARD.RESET_CLIENT_INFO' }
export const ResetClientInfo = () =>
    <IResetClientInfo>({ type: RESET_CLIENT_INFO });

export const CHANGE_RECIPIENT = 'WIZARD.CHANGE_RECIPIENT';
export interface IChangeRecipient { type: 'WIZARD.CHANGE_RECIPIENT', payload: boolean }
export const ChangeRecipient = (value) =>
    <IChangeRecipient>({ type: CHANGE_RECIPIENT, payload: value });

export const SET_DELIVERY_TYPE = 'WIZARD.SET_DELIVERY_TYPE';
export interface ISetDeliveryType { type: 'WIZARD.SET_DELIVERY_TYPE', payload: any }
export const SetDeliveryType = (value) =>
    <ISetDeliveryType>({ type: SET_DELIVERY_TYPE, payload: value });

export const SET_PAYMENT_METHOD = 'WIZARD.SET_PAYMENT_METHOD';
export interface ISetPaymentMethod { type: 'WIZARD.SET_PAYMENT_METHOD', payload: any }
export const SetPaymentMethod = (value) =>
    <ISetPaymentMethod>({ type: SET_PAYMENT_METHOD, payload: value });

export const CHANGE_NEW_CLIENT_NAME = 'WIZARD.CHANGE_NEW_CLIENT_NAME';
export interface IChangeNewClientName { type: 'WIZARD.CHANGE_NEW_CLIENT_NAME', payload: any }
export const ChangeNewClientName = (value) =>
    <IChangeNewClientName>({ type: CHANGE_NEW_CLIENT_NAME, payload: value });

export const CHANGE_NEW_CLIENT_EMAIL = 'WIZARD.CHANGE_NEW_CLIENT_EMAIL';
export interface IChangeNewClientEmail { type: 'WIZARD.CHANGE_NEW_CLIENT_EMAIL', payload: any }
export const ChangeNewClientEmail = (value) =>
    <IChangeNewClientEmail>({ type: CHANGE_NEW_CLIENT_EMAIL, payload: value });

export const REMOVE_ORDER_PRODUCT_SERVER = '[API] WIZARD.REMOVE_ORDER_PRODUCT_SERVER';
export interface IRemoveOrderProductServer { type: '[API] WIZARD.REMOVE_ORDER_PRODUCT_SERVER', payload: any }
export const RemoveOrderProductServer = (value) =>
    <IRemoveOrderProductServer>({ type: REMOVE_ORDER_PRODUCT_SERVER, payload: value });

export const UPDATE_ORDER_PRODUCT_SERVER = '[API] WIZARD.UPDATE_ORDER_PRODUCT_SERVER';
export interface IUpdateOrderProductServer { type: '[API] WIZARD.UPDATE_ORDER_PRODUCT_SERVER', payload: any }
export const UpdateOrderProductServer = (value) =>
    <IUpdateOrderProductServer>({ type: UPDATE_ORDER_PRODUCT_SERVER, payload: value });

export const UPDATE_ORDER_PRODUCT_CLIENT = 'WIZARD.UPDATE_ORDER_PRODUCT_CLIENT';
export interface IUpdateOrderProductClient { type: 'WIZARD.UPDATE_ORDER_PRODUCT_CLIENT', payload: any }
export const UpdateOrderProductClient = (value, index, partnerTotal, total) =>
    <IUpdateOrderProductClient>({ type: UPDATE_ORDER_PRODUCT_CLIENT, payload: { value, index, partnerTotal, total } });

export const OPEN_FINISH_MENU = 'WIZARD.OPEN_FINISH_MENU';
export interface IOpenFinishMenu { type: 'WIZARD.OPEN_FINISH_MENU', payload: any }
export const OpenFinishMenu = (value) =>
    <IOpenFinishMenu>({ type: OPEN_FINISH_MENU, payload: value });

export const UPDATE_TEMP_ORDER = '[API] WIZARD.UPDATE_TEMP_ORDER';
export interface IUpdateTempOrder { type: '[API] WIZARD.UPDATE_TEMP_ORDER', payload: any }
export const UpdateTempOrder = (value) =>
    <IUpdateTempOrder>({ type: UPDATE_TEMP_ORDER, payload: value });

export const ADD_NEW_CLIENT = '[API] WIZARD.ADD_NEW_CLIENT';
export interface IAddNewClient { type: '[API] WIZARD.ADD_NEW_CLIENT', payload: any }
export const AddNewClient = (value) =>
    <IAddNewClient>({ type: ADD_NEW_CLIENT, payload: value });

export const SET_NEW_CLIENT = 'WIZARD.SET_NEW_CLIENT';
export interface ISetNewClient { type: 'WIZARD.SET_NEW_CLIENT', payload: any }
export const SetNewClient = (value) =>
    <ISetNewClient>({ type: SET_NEW_CLIENT, payload: value });

export const CHANGE_RECIPIENT_NAME = 'WIZARD.CHANGE_RECIPIENT_NAME';
export interface IChangeRecipientName { type: 'WIZARD.CHANGE_RECIPIENT_NAME', payload: any }
export const ChangeRecipientName = (value) =>
    <IChangeRecipientName>({ type: CHANGE_RECIPIENT_NAME, payload: value });

export const CHANGE_RECIPIENT_PHONE = 'WIZARD.CHANGE_RECIPIENT_PHONE';
export interface IChangeRecipientPhone { type: 'WIZARD.CHANGE_RECIPIENT_PHONE', payload: any }
export const ChangeRecipientPhone = (value) =>
    <IChangeRecipientPhone>({ type: CHANGE_RECIPIENT_PHONE, payload: value });

export const CALCULATE_PRODUCT_TOTAL = '[API] WIZARD.CALCULATE_PRODUCT_TOTAL';
export interface ICalculateProductTotal { type: '[API] WIZARD.CALCULATE_PRODUCT_TOTAL', payload: any }
export const CalculateProductTotal = (value) =>
    <ICalculateProductTotal>({ type: CALCULATE_PRODUCT_TOTAL, payload: value });

export const SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT = 'WIZARD.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT';
export interface ISetTotalForCurrentOrderProduct { type: 'WIZARD.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT', payload: any }
export const SetTotalForCurrentOrderProduct = (value) =>
    <ISetTotalForCurrentOrderProduct>({ type: SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT, payload: value });

//NOVAPOSHTA
export const GET_CITIES_FOR_AUTOCOMPLETE = 'WIZARD.GET_CITIES_FOR_AUTOCOMPLETE';
export interface IGetCitiesForAutocomplete { type: 'WIZARD.GET_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const GetCitiesForAutocomplete = (value) =>
    <IGetCitiesForAutocomplete>({ type: GET_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const GET_WEREHOUSES_FOR_AUTOCOMPLETE = 'WIZARD.GET_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface IGetWerehousesForAutocomplete { type: 'WIZARD.GET_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const GetWerehousesForAutocomplete = (value) =>
    <IGetWerehousesForAutocomplete>({ type: GET_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const SET_CITIES_FOR_AUTOCOMPLETE = 'WIZARD.SET_CITIES_FOR_AUTOCOMPLETE';
export interface ISetCitiesForAutocomplete { type: 'WIZARD.SET_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const SetCitiesForAutocomplete = (value) =>
    <ISetCitiesForAutocomplete>({ type: SET_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const SET_WEREHOUSES_FOR_AUTOCOMPLETE = 'WIZARD.SET_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface ISetWerehousesForAutocomplete { type: 'WIZARD.SET_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const SetWerehousesForAutocomplete = (value) =>
    <ISetWerehousesForAutocomplete>({ type: SET_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_CITIES_FOR_AUTOCOMPLETE = 'WIZARD.SELECT_CITIES_FOR_AUTOCOMPLETE';
export interface ISelectCitiesForAutocomplete { type: 'WIZARD.SELECT_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const SelectCitiesForAutocomplete = (value) =>
    <ISelectCitiesForAutocomplete>({ type: SELECT_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_WEREHOUSES_FOR_AUTOCOMPLETE = 'WIZARD.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface ISelectWerehousesForAutocomplete { type: 'WIZARD.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const SelectWerehousesForAutocomplete = (value) =>
    <ISelectWerehousesForAutocomplete>({ type: SELECT_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const CHECK_FOR_TEMP_ORDER = '[API] WIZARD.CHECK_FOR_TEMP_ORDER';
export interface ICheckForTempOrder { type: '[API] WIZARD.CHECK_FOR_TEMP_ORDER', payload: any }
export const CheckForTempOrder = () =>
    <ICheckForTempOrder>({ type: CHECK_FOR_TEMP_ORDER });

export const SET_CHECKED_TEMP_ORDER = 'WIZARD.SET_CHECKED_TEMP_ORDER';
export interface ISetCheckedTempOrder { type: 'WIZARD.SET_CHECKED_TEMP_ORDER', payload: any }
export const SetCheckedTempOrder = (value) =>
    <ISetCheckedTempOrder>({ type: SET_CHECKED_TEMP_ORDER, payload: value });

export const SELECT_FINISH_STATUS = 'WIZARD.SELECT_FINISH_STATUS';
export interface ISelectFinishStatus { type: 'WIZARD.SELECT_FINISH_STATUS', payload: any }
export const SelectFinishStatus = (value, validated) =>
    <ISelectFinishStatus>({ type: SELECT_FINISH_STATUS, payload: { value, validated } });

export const CHANGE_GLOBAL_COMMENT = 'WIZARD.CHANGE_GLOBAL_COMMENT';
export interface IChangeGlobalComment { type: 'WIZARD.CHANGE_GLOBAL_COMMENT', payload: any }
export const ChangeGlobalComment = (comment) =>
    <IChangeGlobalComment>({ type: CHANGE_GLOBAL_COMMENT, payload: comment });

export const SET_GLOBAL_COMMENT = '[API] WIZARD.SET_GLOBAL_COMMENT';
export interface ISetGlobalComment { type: '[API] WIZARD.SET_GLOBAL_COMMENT', payload: any }
export const SetGlobalComment = (comment) =>
    <ISetGlobalComment>({ type: SET_GLOBAL_COMMENT, payload: comment });

export const ADD_NEW_ORDER_WITH_STATUS = '[API] WIZARD.ADD_NEW_ORDER_WITH_STATUS';
export interface IAddNewOrderWithStatus { type: '[API] WIZARD.ADD_NEW_ORDER_WITH_STATUS', payload: any }
export const AddNewOrderWithStatus = (tempOrderId, statusId) =>
    <IAddNewOrderWithStatus>({ type: ADD_NEW_ORDER_WITH_STATUS, payload: { tempOrderId, statusId } });

export const PROCESS_TEMP_ORDER = '[API] WIZARD.PROCESS_TEMP_ORDER';
export interface IProcessTempOrder { type: '[API] WIZARD.PROCESS_TEMP_ORDER', payload: any }
export const ProcessTempOrder = (uid) =>
    <IProcessTempOrder>({ type: PROCESS_TEMP_ORDER, payload: uid });

export const UPLOAD_USER_IMAGE = '[API] WIZARD.UPLOAD_USER_IMAGE';
export interface IUploadUserImage { type: '[API] WIZARD.UPLOAD_USER_IMAGE', payload: any }
export const UploadUserImage = (formData, tempOrdernetId, fileType) =>
    <IUploadUserImage>({ type: UPLOAD_USER_IMAGE, payload: { formData, tempOrdernetId, fileType } });

export const UPLOAD_USER_PREVIEW = '[API] WIZARD.UPLOAD_USER_PREVIEW';
export interface IUploadUserPreview { type: '[API] WIZARD.UPLOAD_USER_PREVIEW', payload: any }
export const UploadUserPreview = (formData, tempOrdernetId, fileType) =>
    <IUploadUserPreview>({ type: UPLOAD_USER_PREVIEW, payload: { formData, tempOrdernetId, fileType } });

export const SET_USER_PREVIEW = 'WIZARD.SET_USER_PREVIEW';
export interface ISetUserPreview { type: 'WIZARD.SET_USER_PREVIEW', payload: any }
export const SetUserPreview = (url, fileType) =>
    <ISetUserPreview>({ type: SET_USER_PREVIEW, payload: { url, fileType } });

export const SET_USER_IMAGE = 'WIZARD.SET_USER_IMAGE';
export interface ISetUserImage { type: 'WIZARD.SET_USER_IMAGE', payload: any }
export const SetUserImage = (url, fileType) =>
    <ISetUserImage>({ type: SET_USER_IMAGE, payload: { url, fileType } });

export const SET_USER_IMAGE_COMMENT = 'WIZARD.SET_USER_IMAGE_COMMENT';
export interface ISetUserImageComment { type: 'WIZARD.SET_USER_IMAGE_COMMENT', payload: any }
export const SetUserImageComment = (comment) =>
    <ISetUserImageComment>({ type: SET_USER_IMAGE_COMMENT, payload: comment });

export const GET_BASES_FOR_ORDERPRODUCT = '[API] WIZARD.GET_BASES_FOR_ORDERPRODUCT';
export interface IGetBasesForOrderProduct { type: '[API] WIZARD.GET_BASES_FOR_ORDERPRODUCT', payload: any }
export const GetBasesForOrderProduct = (categoryId, subCategoryId) =>
    <IGetBasesForOrderProduct>({ type: GET_BASES_FOR_ORDERPRODUCT, payload: { categoryId, subCategoryId } });

export const CHANGE_UKR_CITY_NAME = 'WIZARD.CHANGE_UKR_CITY_NAME';
export interface IChangeUkrCityName { type: 'WIZARD.CHANGE_UKR_CITY_NAME', payload: any }
export const ChangeUkrCityName = (value) =>
    <IChangeUkrCityName>({ type: CHANGE_UKR_CITY_NAME, payload: value });

export const CHANGE_UKR_STREET_NAME = 'WIZARD.CHANGE_UKR_STREET_NAME';
export interface IChangeUkrStreetName { type: 'WIZARD.CHANGE_UKR_STREET_NAME', payload: any }
export const ChangeUkrStreetName = (value) =>
    <IChangeUkrStreetName>({ type: CHANGE_UKR_STREET_NAME, payload: value });

export const CHANGE_UKR_BUILDING_NUM = 'WIZARD.CHANGE_UKR_BUILDING_NUM';
export interface IChangeUkrBuildingNum { type: 'WIZARD.CHANGE_UKR_BUILDING_NUM', payload: any }
export const ChangeUkrBuildingNum = (value) =>
    <IChangeUkrBuildingNum>({ type: CHANGE_UKR_BUILDING_NUM, payload: value });

export const CHANGE_UKR_FLAT_NUM = 'WIZARD.CHANGE_UKR_FLAT_NUM';
export interface IChangeUkrFlatNum { type: 'WIZARD.CHANGE_UKR_FLAT_NUM', payload: any }
export const ChangeUkrFlatNum = (value) =>
    <IChangeUkrFlatNum>({ type: CHANGE_UKR_FLAT_NUM, payload: value });

export const CHANGE_UKR_ZIP = 'WIZARD.CHANGE_UKR_ZIP';
export interface IChangeUkrZip { type: 'WIZARD.CHANGE_UKR_ZIP', payload: any }
export const ChangeUkrZip = (value) =>
    <IChangeUkrZip>({ type: CHANGE_UKR_ZIP, payload: value });

export const VALIDATE_FOR_STATUS = '[API] WIZARD.VALIDATE_FOR_STATUS';
export interface IValidateForStatus { type: '[API] WIZARD.VALIDATE_FOR_STATUS', payload: any }
export const ValidateForStatus = (tempOrderId, statusId) =>
    <IValidateForStatus>({ type: VALIDATE_FOR_STATUS, payload: { tempOrderId, statusId } });

export const UNMOUNT_COMPONENT = 'WIZARD.UNMOUNT_COMPONENT';
export interface IUnmountComponent { type: 'WIZARD.UNMOUNT_COMPONENT', payload: any }
export const UnmountComponent = () =>
    <IUnmountComponent>({ type: UNMOUNT_COMPONENT });

export const GET_DELIVERY_PRICE = '[API] WIZARD.GET_DELIVERY_PRICE';
export interface IGetDeliveryPrice { type: '[API] WIZARD.GET_DELIVERY_PRICE', payload: any }
export const GetDeliveryPrice = (deliveryType, paymentMethod) =>
    <IGetDeliveryPrice>({ type: GET_DELIVERY_PRICE, payload: { deliveryType, paymentMethod } });

export const SET_DELIVERY_PRICE = 'WIZARD.SET_DELIVERY_PRICE';
export interface ISetDeliveryPrice { type: 'WIZARD.SET_DELIVERY_PRICE', payload: any }
export const SetDeliveryPrice = (value) =>
    <ISetDeliveryPrice>({ type: SET_DELIVERY_PRICE, payload: value });

export const CALCULATE_SEND_DATE = '[API] WIZARD.CALCULATE_SEND_DATE';
export interface ICalculateSendDate { type: '[API] WIZARD.CALCULATE_SEND_DATE', payload: any }
export const CalculateSendDate = (orderType) =>
    <ICalculateSendDate>({ type: CALCULATE_SEND_DATE, payload: orderType });

export const SET_SEND_DATE = 'WIZARD.SET_SEND_DATE';
export interface ISetSendDate { type: 'WIZARD.SET_SEND_DATE', payload: any }
export const SetSendDate = (value) =>
    <ISetSendDate>({ type: SET_SEND_DATE, payload: value });

export const CALCULATER_DELIVERY_DATE = 'WIZARD.CALCULATER_DELIVERY_DATE';
export interface ICalculateDeliveryDate { type: 'WIZARD.CALCULATER_DELIVERY_DATE', payload: any }
export const CalculateDeliveryDate = (value) =>
    <ICalculateDeliveryDate>({ type: CALCULATER_DELIVERY_DATE, payload: value });

export const SET_DELIVERY_DATE = 'WIZARD.SET_DELIVERY_DATE';
export interface ISetDeliveryDate { type: 'WIZARD.SET_DELIVERY_DATE', payload: any }
export const SetDeliveryDate = (value) =>
    <ISetDeliveryDate>({ type: SET_DELIVERY_DATE, payload: value });

export const SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT = 'WIZARD.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT';
export interface ISetProductionCommentForOrderProduct { type: 'WIZARD.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT', payload: any }
export const SetProductionCommentForOrderProduct = (value) =>
    <ISetProductionCommentForOrderProduct>({ type: SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT, payload: value });

export const SET_PROCESSING_ORDER_STATE = 'WIZARD.SET_PROCESSING_ORDER_STATE';
export interface ISetProcessingOrderState { type: 'WIZARD.SET_PROCESSING_ORDER_STATE', payload: any }
export const SetProcessingOrderState = () =>
    <ISetProcessingOrderState>({ type: SET_PROCESSING_ORDER_STATE });

export const REFRESH_ORDERPRODUCT = '[API] WIZARD.REFRESH_ORDERPRODUCT';
export interface IRefreshOrderProduct { type: '[API] WIZARD.REFRESH_ORDERPRODUCT', payload: any }
export const RefreshOrderProduct = (netid, index) =>
    <IRefreshOrderProduct>{ type: REFRESH_ORDERPRODUCT, payload: { netid, index } };

export const SET_REFRESHED_ORDERPRODUCT = 'WIZARD.SET_REFRESHED_ORDERPRODUCT';
export interface ISetRefreshedOrderProduct { type: 'WIZARD.SET_REFRESHED_ORDERPRODUCT', payload: any }
export const SetRefreshedOrderProduct = (orderProduct, index) =>
    <ISetRefreshedOrderProduct>{ type: SET_REFRESHED_ORDERPRODUCT, payload: { orderProduct, index } };

export const GET_TEMP_ORDER = '[API] WIZARD.GET_TEMP_ORDER';
export interface IGetTempOrder { type: '[API] WIZARD.GET_TEMP_ORDER', payload: any }
export const GetTempOrder = (netId) =>
    <IGetTempOrder>{ type: GET_TEMP_ORDER, payload: netId };

export const GET_OPTIONS_FOR_LEXICON = '[API] WIZARD.GET_OPTIONS_FOR_LEXICON';
export interface IGetOptionsForLexicon { type: '[API] WIZARD.GET_OPTIONS_FOR_LEXICON', payload: any }
export const GetOptionsForLexicon = (uid) =>
    <IGetOptionsForLexicon>{ type: GET_OPTIONS_FOR_LEXICON, payload: uid };

export const SET_OPTIONS_FOR_LEXICON = 'WIZARD.SET_OPTIONS_FOR_LEXICON';
export interface ISetOptionsForLexicon { type: 'WIZARD.SET_OPTIONS_FOR_LEXICON', payload: any }
export const SetOptionsForLexicon = (tree) =>
    <ISetOptionsForLexicon>{ type: SET_OPTIONS_FOR_LEXICON, payload: tree };

export const SELECT_OPTION_FOR_ORDER_PRODUCT = 'WIZARD.SELECT_OPTION_FOR_ORDER_PRODUCT';
export interface ISelectOptionsForOrderProduct { type: 'WIZARD.SELECT_OPTION_FOR_ORDER_PRODUCT', payload: any }
export const SelectOptionsForOrderProduct = (options) =>
    <ISelectOptionsForOrderProduct>{ type: SELECT_OPTION_FOR_ORDER_PRODUCT, payload: options };
    
export const GET_PRODUCT_BASES = '[API] WIZARD.GET_PRODUCT_BASES';
export interface IGetProductBases { type: '[API] WIZARD.GET_PRODUCT_BASES', payload: any }
export const GetProductBases = (lexiconId: number, categoryId: number, subcategoryId: number) =>
        <IGetProductBases>{ type: GET_PRODUCT_BASES, payload: {lexiconId: lexiconId, categoryId: categoryId, subcategoryId: subcategoryId} };

export type WizardKnownActions =
    IApproveOrderCreation | ISelectWizardStep | IClearWizard | IGetCategories | ISetCategories | IGetSubCategories |
    ISetSubCategories | ISelectCategory | ISelectSubCategory | IGetProducts | ISetProducts | ISetSubCategoryRef | ISelectCurrentProduct |
    ISetNextSubStep | IConfirmProductSelect | ISelectCurrentProductColor | IConfirmProductColorSelect | ISetPattern | ICancelCurrentOrderProduct |
    IGotoNextStep | IGotoPrevStep | IChangeCurrentOrderProductPrice | IChangeCurrentOrderProductQuantity | ISetBasesForProduct | ISelectCurrentBaseProduct |
    IConfirmBaseProductSelect | ISelectCurrentBaseColor | IConfirmBaseColorSelect | ISetTempOrder | ISetOrderProduct | IChangeProductSelectionMode |
    ISelectOrderProduct | IConfirmOrderProductSelection | IRemoveOrderProductClient | IGetClientsForPartner | ISetClientsForPartner | ISetClientPhone |
    ISelectClientPartner | IResetClientInfo | IChangeRecipient | ISetDeliveryType | ISetPaymentMethod | ISaveOrderProduct | IChangeNewClientName |
    IChangeNewClientEmail | IRemoveOrderProductServer | IUpdateOrderProductClient | IOpenFinishMenu | IAddNewClient | ISetNewClient | IChangeRecipientName |
    IChangeRecipientPhone | ICalculateProductTotal | ISetTotalForCurrentOrderProduct | IGetCitiesForAutocomplete | IGetWerehousesForAutocomplete |
    ISetCitiesForAutocomplete | ISetWerehousesForAutocomplete | ISelectCitiesForAutocomplete | ISelectWerehousesForAutocomplete | ICheckForTempOrder |
    ISetCheckedTempOrder | ISelectFinishStatus | IAddNewOrderWithStatus | IProcessTempOrder | IUploadUserImage | ISetUserImage | ISetUserImageComment |
    IGetBasesForOrderProduct | IChangeUkrCityName | IChangeUkrBuildingNum | IChangeUkrFlatNum | IChangeUkrZip | IChangeUkrStreetName | IValidateForStatus |
    IChangeGlobalComment | IUnmountComponent | IGetDeliveryPrice | ISetDeliveryPrice | ICalculateSendDate | ISetSendDate | ICalculateDeliveryDate |
    ISetDeliveryDate | ISetProductionCommentForOrderProduct | IUpdateTempOrder | ISetProcessingOrderState | IRefreshOrderProduct |
    ISetRefreshedOrderProduct | IGetTempOrder | IUploadUserPreview | ISetUserPreview | ISetOrderProductByIndex | ISelectOptionsForOrderProduct | 
    IGetOptionsForLexicon | ISetOptionsForLexicon | IGetProductBases | IGetAdditionalServiceProducts | ISetAdditionalServiceProducts |
    ISetExpressPrintProduct | ISetExtraPrintProduct;