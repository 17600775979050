export const GET_ALL: string = '[API] TUTORIAL.GET_ALL';
export interface IGetAllTutorials { type: '[API] TUTORIAL.GET_ALL'; payload: any }
export const GetAllTutorials = (page, count) =>
    <IGetAllTutorials>{ type: GET_ALL, payload: { page, count } };

export const SET_ALL: string = 'TUTORIAL.SET_ALL';
export interface ISetAllTutorials { type: 'TUTORIAL.SET_ALL'; payload: any }
export const SetAllTutorials = (tutorials, total) =>
    <ISetAllTutorials>{ type: SET_ALL, payload: { tutorials, total } };

export const CHANGE_NAME: string = 'TUTORIAL.CHANGE_NAME';
export interface IChangeName { type: 'TUTORIAL.CHANGE_NAME'; payload: any }
export const ChangeName = (name) =>
    <IChangeName>{ type: CHANGE_NAME, payload: name };

export const CHANGE_DESCRIPTION: string = 'TUTORIAL.CHANGE_DESCRIPTION';
export interface IChangeDescription { type: 'TUTORIAL.CHANGE_DESCRIPTION'; payload: any }
export const ChangeDescription = (name) =>
    <IChangeDescription>{ type: CHANGE_DESCRIPTION, payload: name };

export const CHANGE_ORDER: string = 'TUTORIAL.CHANGE_ORDER';
export interface IChangeOrder { type: 'TUTORIAL.CHANGE_ORDER'; payload: any }
export const ChangeOrder = (name) =>
    <IChangeOrder>{ type: CHANGE_ORDER, payload: name };

export const CHANGE_VIDEO: string = 'TUTORIAL.CHANGE_VIDEO';
export interface IChangeVideo { type: 'TUTORIAL.CHANGE_VIDEO'; payload: any }
export const ChangeVideo = (name) =>
    <IChangeVideo>{ type: CHANGE_VIDEO, payload: name };

export const CHANGE_DELETED: string = 'TUTORIAL.CHANGE_DELETED';
export interface IChangeDeleted { type: 'TUTORIAL.CHANGE_DELETED'; payload: any }
export const ChangeDeleted = (deleted) =>
    <IChangeDeleted>{ type: CHANGE_DELETED, payload: deleted };

export const NEW_TUTORIAL: string = '[API] TUTORIAL.NEW_TUTORIAL';
export interface INewTutorial { type: '[API] TUTORIAL.NEW_TUTORIAL'; payload: any }
export const NewTutorial = (tutorial, form) =>
    <INewTutorial>{ type: NEW_TUTORIAL, payload: { tutorial, form } };

export const SELECT_TUTORIAL: string = 'TUTORIAL.SELECT_TUTORIAL';
export interface ISelectTutorial { type: 'TUTORIAL.SELECT_TUTORIAL'; payload: any }
export const SelectTutorial = (tutorial, key) =>
    <ISelectTutorial>{ type: SELECT_TUTORIAL, payload: { tutorial, key } };

export const DESELECT_TUTORIAL: string = 'TUTORIAL.DESELECT_TUTORIAL';
export interface IDeselectTutorial { type: 'TUTORIAL.DESELECT_TUTORIAL'; payload: any }
export const DeselectTutorial = () =>
    <IDeselectTutorial>{ type: DESELECT_TUTORIAL };

export const UPDATE_TUTORIAL: string = '[API] TUTORIAL.UPDATE_TUTORIAL';
export interface IUpdateTutorial { type: '[API] TUTORIAL.UPDATE_TUTORIAL'; payload: any }
export const UpdateTutorial = (tutorial, form) =>
    <IUpdateTutorial>{ type: UPDATE_TUTORIAL, payload: { tutorial, form } };
    
export const UNMUONT: string = 'TUTORIAL.UNMUONT';
export interface IUnmount { type: 'TUTORIAL.UNMUONT'; payload: any }
export const Unmount = () =>
    <IUnmount>{ type: UNMUONT };

export type TutorialKnownActions =
    IGetAllTutorials |
    ISetAllTutorials |
    IChangeName |
    IChangeDescription |
    IChangeOrder |
    IChangeVideo |
    INewTutorial |
    IUnmount |
    ISelectTutorial |
    IDeselectTutorial | 
    IUpdateTutorial |
    IChangeDeleted;