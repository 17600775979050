import * as React from "react";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { Form, actions, Control, Errors } from "react-redux-form";
import { LoginFormInput } from "../../authentication/common/login.form.input";
import { LoginFormButton } from "../../authentication/common/login.form.button";
import { RegistrationFormCheckbox } from "../common/registration.form.checkbox";
import { Partner } from "../../../business.entities/partner";
import * as ActionCreators from '../../../actions/registration.actions';
import { RegistrationState } from "../../../states/registration.state";
import { ApplicationState } from "../../../reducers/index";
import { Validator, MaxLength } from "../../../helpers/validator.helper";
import { TextBox } from "../../shared/form.controls/textbox";

export namespace RegistrationForm {
    export interface IProps {
        PartnerRegistration: any;
        newPartner: Partner;
        ResetForm: any;
        partnerFormReducer: any;
        RegistrationState: RegistrationState;
    }
}

const mapStateToProps = (state) => {
    return {
        RegistrationState: state.registration,
        newPartner: state.partnerForm,
        partnerFormReducer: state.partnerFormReducer
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        PartnerRegistration: ActionCreators.PartnerRegistration,
        ResetForm: actions.reset,
    }, dispatch);
};

class RegistrationFormComponent extends React.Component<RegistrationForm.IProps, any> {

    constructor(props) {
        super(props);
        actions.submit('partnerForm');
    }

    public onRegistrationCommand() {

        if (!this.props.RegistrationState.isRegisrationInProgress) {
            this.props.PartnerRegistration(this.props.newPartner);
            //window.gtag('event', 'Стать_партнером', { 'event_category': 'ButtonConfirm', 'event_action': 'Beapartner', });
        }
    }

    public onInputChangeCommand(event: any): void {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentWillUnmount() {
        this.props.ResetForm("partnerForm");
    }

    private SellTypeValidation(): boolean {
        if (this.props.newPartner.Vkontakte ||
            this.props.newPartner.Instagram ||
            this.props.newPartner.LocalShop ||
            this.props.newPartner.OnlineShop ||
            this.props.newPartner.Other) {

            return true;
        } else {

            return false;
        }
    }

    private InterestProduct(): boolean {
        if (this.props.newPartner.SiliconeCases ||
            this.props.newPartner.LeatherCase ||
            this.props.newPartner.PrintSiliconeCases ||
            this.props.newPartner.GlassAndFilm) {

            return true;
        } else {

            return false;
        }
    }

    public render(): JSX.Element {
        return (
            <>
                {!this.props.RegistrationState.isRegisteredCompleted ?
                    <Form
                        validateOn='change'
                        model="partnerForm"
                        // validators={{
                        //     '': {
                        //         isAnyProdSelected: (vals) => this.InterestProduct(),
                        //         isAnySaleTypeSelected: (vals) => this.SellTypeValidation()
                        //     }
                        // }}
                        onSubmit={
                            this.onRegistrationCommand.bind(this)
                        }>
                        <div className="registration_form_component">
                            <div className="two_col_list2">

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Имя и фамилия'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isCyrylic: (val: string) => Validator.IsCyrylicLetters(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isCyrylic: 'Поле должно содержать только кириллицу',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactName'
                                    />
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Контактный телефон'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isPhone: (val: string) => Validator.IsPhone(val),
                                            IsMaxLength: MaxLength(10)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isPhone: 'Не валидный номер телефона',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}
                                        MaxLength={10}
                                        ShowErrorOn='touched'
                                        Type='text'
                                        Placeholder="0123456789"
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactPhone'
                                    />
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Email'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            IsEmail: (val: string) => Validator.IsEmail(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                            IsEmail: 'Не валидный емейл'
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.ContactEmail'
                                    />
                                </div>

                                {/* <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Название организации'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            IsMaxLength: MaxLength(50)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'Слишком длинное название',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.OrganizationName'
                                    />
                                </div> */}

                                {/* <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Город'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isCyrylic: (val: string) => Validator.IsCyrylicLetters(val)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isCyrylic: 'Поле должно содержать только кириллицу',
                                            IsMaxLength: 'Слишком длинное название',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.OrganizationCity'
                                    />
                                </div> */}

                                {/* <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">{'Ссылка на сайт или соцсеть'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            isUrl: (val: string) => Validator.IsUrl(val),
                                            IsMaxLength: MaxLength(200)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            isUrl: 'Не валидний адрес',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.PageUrl'
                                    />
                                </div> */}

                            </div>
                            {/* <div className="two_col_list last">
                                <div className="registration_form_control">
                                    {
                                        this.props.partnerFormReducer.$form.errors.isAnySaleTypeSelected ?
                                            <Errors
                                                model="partnerForm"
                                                messages={{
                                                    isAnySaleTypeSelected: "Нужно выбрать один пункт"
                                                }}
                                            /> : null
                                    }
                                    <div>
                                        <div className="form_title_component">Путь сбыта</div>
                                        <RegistrationFormCheckbox
                                            model=".OnlineShop"
                                            text="Интернет-магазин"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".LocalShop"
                                            text="Локальный магазин"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".Instagram"
                                            text="Инстаграм"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".Vkontakte"
                                            text="Вконтакте"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".Other"
                                            text="Другое"
                                        />
                                    </div>
                                </div>
                                {
                                    this.props.newPartner.Other === true ?
                                        <div className="defaultFormControl newPartnerOth">
                                            <div className="defaultFormControl__label">{'Путь сбыта'}</div>
                                            <TextBox
                                                Validators={{
                                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                                    IsMaxLength: MaxLength(75)
                                                }}

                                                ErrorMessages={{
                                                    isRequired: '*',
                                                    IsMaxLength: 'TooManyCharacters',
                                                }}

                                                ShowErrorOn='touched'
                                                Type='text'
                                                ValidatedOn='change'
                                                IsRequired={true}
                                                Model='.OtherDescription'
                                            />
                                        </div> : null
                                }

                                <div className="registration_form_control">
                                    {
                                        this.props.partnerFormReducer.$form.errors.isAnyProdSelected ?
                                            <Errors
                                                model="partnerForm"
                                                messages={{
                                                    isAnyProdSelected: "Нужно выбрать один пункт"
                                                }}
                                            /> : null
                                    }

                                    <div>
                                        <div className="form_title_component">Интересующие товары</div>
                                        <RegistrationFormCheckbox
                                            model=".PrintSiliconeCases"
                                            text="Силиконовые чехлы с печатью"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".LeatherCase"
                                            text="Кожанные чехлы"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".SiliconeCases"
                                            text="Силиконовые чехлы"
                                        />
                                        <RegistrationFormCheckbox
                                            model=".GlassAndFilm"
                                            text="Стекло и пленки"
                                        />
                                    </div>
                                </div>
                            </div> */}
                            <div className="defaultFormControl">
                                <button type="submit" className="login_form_button" >
                                    Стать партнером
                                </button>
                            </div>
                        </div>
                    </Form> :
                    <div className="successPartContainer ">
                        <div className="authentication_layout_header">
                            <div className="logo"></div>
                            <div className="header_nav_component">

                            </div>
                        </div>
                        <div className="authentication_layout_content">
                            <div className="ttSuccessTitle">
                                <b>Спасибо за регистрацию!</b>

                                Мы позвоним Вам на протяжении 24 часов
                                    для подтверждения регистрации и
                                    открытия доступа к личному кабинету.
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export const RegistrationForm = connect(mapStateToProps, mapDispatchToProps)(RegistrationFormComponent);

