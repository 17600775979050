import * as React from "react";
import { connect } from "react-redux";
import ProductColor from "../../../../../business.entities/product.color";
import { bindActionCreators } from "redux";
import {
    SelectCurrentProductColor,
    ConfirmProductColorSelect,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SetProductionCommentForOrderProduct
} from '../../../../../actions/sales/wizard.actions';
import { List } from "linqts";
import { ProductPriceListItem } from "../../../../../business.entities/product.price.list.item";
import { GetOrderProductStepComponent } from "../../../../../helpers/enums/order.product.steps";
import { OnGoToNextStepCommand, OnGoToPrevStepCommand } from "../../../../../helpers/wizard.substep.helper";
import Product from "../../../../../business.entities/product";
import { WizardState } from "../../../../../states/sales/wizard.state";
import { OrderBaseState } from "../../../../../states/sales/order.base.state";
import { OrderProduct } from "../../../../../business.entities/order.product";
import { ProductTypeEnum } from "../../../../../helpers/enums/product.type.enum";
import * as API from "../../../../../constants/api.constants";
import { ShowHoverBlock } from "../../../../../actions/right.view.actions";

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectCurrentProductColor,
    ConfirmProductColorSelect,
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ConfirmOrderProductSelection,
    SetProductionCommentForOrderProduct,
    ShowHoverBlock
}, dispatch);

const Color = (props: any) => {
    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return true;

        return false;
    }

    document.onkeydown = (e) => {
        if ((event as any).keyCode == 38) {
            event.preventDefault();

            OnSelectTopColorCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();

            OnSelectBottomColorCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();

            OnConfirmColorSelection();
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();

            if ((props.Wizard as WizardState).currentOrderProduct.Color) {
                OnGoToNextStepCommand(props);
                props.ShowHoverBlock(null);
            }

        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopColorCommand = () => {
        if (IsProduction(props.Wizard.currentProduct) && !props.Wizard.currentOrderProduct.ProductionComment)
            return;

        let startIndex = 0;

        if (props.Wizard.currentProductColor) {
            const productColor = new List(props.Wizard.currentProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === props.Wizard.currentProductColor.Id)

            startIndex = props.Wizard.currentProduct.ProductColors.indexOf(productColor);
            startIndex != 0 ? startIndex-- : startIndex = props.Wizard.currentProduct.ProductColors.length - 1;
        }

        props.SelectCurrentProductColor(props.Wizard.currentProduct.ProductColors[startIndex].Color);
    }

    const OnSelectBottomColorCommand = () => {
        if (IsProduction(props.Wizard.currentProduct) && !props.Wizard.currentOrderProduct.ProductionComment)
            return;

        let startIndex = 0;

        if (props.Wizard.currentProductColor) {
            const productColor = new List(props.Wizard.currentProduct.ProductColors)
                .FirstOrDefault(c => (c as ProductColor).ColorId === props.Wizard.currentProductColor.Id)

            startIndex = props.Wizard.currentProduct.ProductColors.indexOf(productColor);
            startIndex != props.Wizard.currentProduct.ProductColors.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentProductColor(props.Wizard.currentProduct.ProductColors[startIndex].Color);
    }

    const OnConfirmColorSelection = () => {
        if (props.Wizard.currentProductColor) {
            props.ConfirmProductColorSelect();

            // if (IsProduction((props.Wizard as WizardState).currentProduct)) {
            //     (props.Wizard as WizardState).currentOrderProduct.ProductionComment && OnGoToNextStepCommand(props);
            // } else {
                OnGoToNextStepCommand(props);
            // }

            props.ShowHoverBlock(null);
            // if (((props.Wizard as WizardState).currentProduct.Lexicon.ProductTypeId == ProductTypeEnum.Production
            //     || (props.Wizard as WizardState).currentProduct.Lexicon.ProductTypeId == ProductTypeEnum.PrintProduction) &&
            //     (props.Wizard as WizardState).currentOrderProduct.ProductionComment)
            //     OnGoToNextStepCommand(props);
        }
    }

    const OnSelectProductColorByMouse = (index) => {
        props.SelectCurrentProductColor(props.Wizard.currentProduct.ProductColors[index].Color);

        props.ConfirmProductColorSelect();

        const newProps = Object.assign({},
            props,
            {
                Wizard: Object.assign({}, props.Wizard, {
                    currentProductColor: props.Wizard.currentProduct.ProductColors[index].Color
                } as WizardState),
            });

        // if (IsProduction((props.Wizard as WizardState).currentProduct)) {
        //     (props.Wizard as WizardState).currentOrderProduct.ProductionComment && OnGoToNextStepCommand(newProps);
        // } else {
        OnGoToNextStepCommand(newProps);
        // }

        props.ShowHoverBlock(null);
    }

    return (
        <div className="colorContainer__content">

            {IsProduction(props.Wizard.currentProduct) ?
                <div className="colorComennt__container">
                    <div className="colorContainer__contentLabel">Комментарий</div>

                    <div className={`colorComennt__ValueContainer `}>
                        {/* ${!props.Wizard.currentOrderProduct.ProductionComment ? 'isError' : ''} */}
                        <textarea value={props.Wizard.currentOrderProduct.ProductionComment}
                            autoFocus={true}
                            onChange={(event) => props.SetProductionCommentForOrderProduct(event.target.value)} />
                    </div>
                </div>
                : null
            }

            <div className="colorContainer__contentLabel">{props.Wizard.currentProduct.Lexicon.HasColors ? 'Цвет товара' : 'Опция товара'}</div>
            <div className={`colorContainer__contentWrapper content_scroll`}>
                {/* ${IsProduction(props.Wizard.currentProduct) && !props.Wizard.currentOrderProduct.ProductionComment ? 'isDisabled' : ''} */}
                {props.Wizard.currentProduct.ProductColors.length > 0 && props.Wizard.currentProduct.ProductColors.map((productColor, index) => {
                    return <div key={index}
                        className={`productColor__item  ${(productColor as ProductColor).Color.Id === props.Wizard.currentProductColor.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectProductColorByMouse(index)}>
                        {props.Wizard.currentProduct.Lexicon.HasColors ?
                            <>
                                <div className={`bgItem_container ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                                    style={{ backgroundColor: productColor.Color.Code }} >
                                    <div className="qty">
                                        {!IsProduction(props.Wizard.currentProduct) ? (productColor as ProductColor).Quantity : null}
                                    </div>
                                </div>
                                <div className="name">
                                    {(productColor as ProductColor).Color.Name}
                                </div>
                            </>
                            : null}

                        {props.Wizard.currentProduct.Lexicon.HasOptions ?
                            <>
                                <div className={`bgItem_container imgThumb__WRAPPER`}
                                    style={{ backgroundImage: `url(${API.CRM_URL + '/' + (productColor as ProductColor).Color.Thumb})` }}
                                    onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(productColor.Color) }}
                                    onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                >
                                    <div className="qty">
                                        {(productColor as ProductColor).Quantity}
                                    </div>
                                </div>

                                <div className="name">
                                    {(productColor as ProductColor).Color.Name}
                                </div>
                            </>
                            : null}

                        {/* <div className={`bgItem_container ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                            style={{ backgroundColor: productColor.Color.Code }} >
                            <div className="qty">
                                {!IsProduction(props.Wizard.currentProduct) ? (productColor as ProductColor).Quantity : null}
                            </div>
                        </div>
                        <div className="name">
                            {(productColor as ProductColor).Color.Name}
                        </div> */}

                    </div>
                })}
            </div>

        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Color)