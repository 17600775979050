import { Partner } from "../../business.entities/partner";
import { SelectModel } from "../../helpers/select.model";
import { default as PartnerPriceGridComponent } from "../../components/dashboard/stock/prices/partner.price.grid";
import { PartnerPrice } from "../../business.entities/partner.price";
import { Product } from "../../business.entities/product";
import { PriceTerm } from "../../business.entities/price.term";
import { PartnerWholesale } from "../../business.entities/partnerWholesale";
import { AuditEntity } from "../../business.entities/audit.entity";

export class PriceState {
    partnerAutocompleteList: SelectModel[]
    selectedPartner: SelectModel;
    selectedContentComponent: any;
    partnerPriceSegments: PartnerPrice[];
    categorySelectList: SelectModel[];
    subcategoryAutocompleteList: SelectModel[];
    selectedCategory: SelectModel;
    selectedSubcategory: SelectModel;
    products: Product[];
    productsFetchInProgress: boolean;
    productFilterChanged: boolean;
    partner: Partner;
    priceTerm: PriceTerm;
    wholesaleList: PartnerWholesale[];
    selectedWholesale: PartnerWholesale;
    auditInfo: AuditEntity[];
    auditStartDate: any;
    auditEndDate: any;
}

export const defaultPriceState: PriceState = {
    partnerAutocompleteList: [],
    selectedPartner: null,
    selectedContentComponent: PartnerPriceGridComponent,
    partnerPriceSegments: [],
    categorySelectList: [],
    subcategoryAutocompleteList: [],
    selectedCategory: null,
    selectedSubcategory: null,
    products: [],
    productsFetchInProgress: false,
    productFilterChanged: false,
    partner: null,
    priceTerm: null,
    wholesaleList: [],
    selectedWholesale: null,
    auditInfo: null,
    auditStartDate: null,
    auditEndDate: null
}
