import * as React from "react";
import { TextBoxWithLabel } from "../../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../../helpers/validator.helper";
import { TextAreaWithLabel } from "../../../../shared/form.controls/textarea.with.label";

export interface IReachBlock {
    ValueModel: string;
    CommentModel: string;
    PriceLevel: string;
}

export const ReachBlock = (props: IReachBlock) => {
    return (
        <div className="reach_block__item">
            <div className="item__title">
                {props.PriceLevel}
            </div>

            <TextBoxWithLabel
                Validators={{
                    isRequired: (val: string) => !Validator.IsEmpty(val),
                }}
                ErrorMessages={{
                    isRequired: '*',
                }}
                ShowErrorOn='touched'
                Type='text'
                ValidatedOn='change'
                LabelMessage="Сума замовлень за розрахунковий період"
                IsRequired
                Model={props.ValueModel} />

            <TextAreaWithLabel
                Validators={{
                    isRequired: (val: string) => !Validator.IsEmpty(val),
                }}
                ErrorMessages={{
                    isRequired: '*',
                }}
                ShowErrorOn='touched'
                ValidatedOn='change'
                LabelMessage="Коментарий"
                IsRequired
                Model={props.CommentModel}
            />
        </div>
    );
}
