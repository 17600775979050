import * as API from '../../../../../../constants/api.constants';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SetUserImage,
    SetUserImageComment,
    UploadUserImage,
    UploadFilesForOrderProduct
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';
import { OrderProductFileTypeEnum } from '../../../../../../helpers/enums/order.productfile.type.enum';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SetNextSubStep,
    CancelCurrentOrderProduct,
    UploadUserImage,
    SetUserImage,
    UploadFilesForOrderProduct,
    SetUserImageComment
}, dispatch);


const UserImage = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    document.onkeydown = (event) => {
        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 39 || (event as any).keyCode === 13) {
            event.preventDefault();
            OnGoToNextOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnFileUpload = (event, fileType) => {
        let files;
        let formData = new FormData();
        if (event.target.files) {
            files = Array.from(event.target.files)
            formData.append('files', files[0] as any, (files[0] as any).name);
        }
        orderProductUpdate.currentOrderProduct.Id ?
            props.UploadFilesForOrderProduct(formData, orderProductUpdate.currentOrderProduct.NetUid, orderCard.order.NetUid, fileType) :
            props.UploadUserImage(formData, orderCard.order.NetUid, fileType);
    }

    const OnSetUserImageCommentCommand = (value) => {
        props.SetUserImageComment(value);
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip';
    }

    return (
        <div className="userImageView__content uiContainer">
            <div className="userImageContainer__contentLabel">Изображение</div>
            <div className="userImageContainer__contentWrapper">
                <div className="uploadImageWrapper__container uiConItem">
                    <div className="uploadImageWrapper__item"
                        style={{ backgroundImage: `url(${API.SERVER_URL}${orderProductUpdate.currentOrderProduct.UserPrewiev})` }}
                        onClick={() => OnFileUpload(event, OrderProductFileTypeEnum.Preview)}>
                        {!orderProductUpdate.currentOrderProduct.UserPrewiev &&
                            <label>
                                <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Preview)} />
                            </label>
                        }
                        <div className="lab">Превью</div>

                    </div>
                </div>
                <div className="uploadImageWrapper__container uiConItem">
                    <div className={`uploadImageWrapper__item ${IsZipFile(orderProductUpdate.currentOrderProduct.UserImage) ? 'zipp' : ''}`}
                        style={{ backgroundImage: `url(${API.SERVER_URL}${orderProductUpdate.currentOrderProduct.UserImage})` }}
                        onClick={() => OnFileUpload(event, OrderProductFileTypeEnum.UserImage)}>
                        {!orderProductUpdate.currentOrderProduct.UserImage &&
                            <label>
                                <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.UserImage)} />
                            </label>
                        }
                        <div className="lab">Файл</div>
                    </div>
                </div>

                <div className="uploadImageComennt__container">
                    <div className="uploadImageControl">
                        <div className="uploadImageControl__label">Коментарий</div>
                        <div className="uploadImageControl__value">
                            <textarea value={orderProductUpdate.currentOrderProduct.UserImageComment}
                                onChange={(event) => OnSetUserImageCommentCommand(event.target.value)} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserImage)