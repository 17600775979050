 export const SERVER_URL = 'https://tech2.caseshop.com.ua/';
// export const SERVER_URL = 'https://partnerserver.caseshop.com.ua/';
//export const SERVER_URL = 'http://localhost:57830/';

// export const SERVER_URL = 'http://78.152.175.67:15020/';
// export const SERVER_URL = 'http://78.152.175.67:14001/';
//export const PRINT_URL = 'http://localhost:59319/'

// export const PRINT_URL = 'http://78.152.175.67:8025/constructor/product/';
//export const PRINT_URL = 'https://print-test.azurewebsites.net/constructor/product/';
 export const PRINT_URL = 'https://print.caseshop.com.ua/constructor/product/';

//export const CRM_URL = 'http://localhost:17059';
// export const CRM_URL = 'http://78.152.175.67:15021/';
 export const CRM_URL = 'https://crm.caseshop.net.ua';

export const LOGIN = SERVER_URL + 'api/v1/usermanagement/token';
export const REFRESH = SERVER_URL + 'api/v1/usermanagement/refresh';
export const ADD_PARTNER = SERVER_URL + 'api/v1/partners/new';
export const CHANGE_ROLE = SERVER_URL + 'api/v1/usermanagement/change/role';

export const GET_ALL_PARTNERS = SERVER_URL + 'api/v1/partners/all';
export const GET_ALL_PARTNERS_COUNT = SERVER_URL + 'api/v1/partners/count';
export const GET_UNREGISTERED_PARTNERS = SERVER_URL + 'api/v1/partners/all/unregistered';

//Partners grid(admin)
export const GET_PARTNERS_LIMITED = SERVER_URL + 'api/v1/partners/all/limited';
export const GET_UNREGISTERED_PARTNERS_LIMITED = SERVER_URL + 'api/v1/partners/all/unregistered/limited';
export const GET_PARTNERS_PAGINATED = SERVER_URL + 'api/v1/partners/all/paginated';
export const GET_UNREGISTERED_PARTNERS_PAGINATED = SERVER_URL + 'api/v1/partners/all/unregistered/paginated';
export const GET_PARTNERS_FILTERED_PAGINATED = SERVER_URL + 'api/v1/partners/all/filtered/paginated';
export const GET_WORKERS_PAGINATED = SERVER_URL + 'api/v1/partners/workers/paginated';
export const GET_PARTNER = SERVER_URL + 'api/v1/partners/get';
export const GET_PARTNER_HISTORY = SERVER_URL + 'api/v1/partners/history';
export const SINGUP_PARTNER = SERVER_URL + 'api/v1/usermanagement/signup';
export const UPDATE_PARTNER = SERVER_URL + 'api/v1/partners/update';
export const UPDATE_PARTNER_USER_NAME = SERVER_URL + 'api/v1/usermanagement/update/username';
export const UPDATE_PARTNER_USER_PASSWORD_FORCE = SERVER_URL + 'api/v1/usermanagement/password/reset/force';
export const CHECK_PARTNER_NAME_AVAILABILITY = SERVER_URL + 'api/v1/usermanagement/check/username';

//Cabinet
export const GET_USER_PERSONAL_INFO = SERVER_URL + "api/v1/partners/get";
export const GET_FUNDS_INFO = SERVER_URL + "api/v1/partners/funds/info";
export const ACTIVATE_CONSTRUCTOR = SERVER_URL + 'api/v1/partners/activate/constructor';
export const CHANGE_LOGO = SERVER_URL + 'api/v1/partners/change/logo';
export const REMOVE_LOGO = SERVER_URL + 'api/v1/partners/remove/logo';
export const GET_CONSTRUCTOR_USERS = SERVER_URL + 'api/v1/partners/get/constructor/users';

//Product types
export const GET_ALL_PRODUCT_TYPES = SERVER_URL + 'api/v1/producttypes/all';
export const GET_PRODUCT_TYPE = SERVER_URL + 'api/v1/producttypes/get';

//Lexicons(admin)
export const GET_ALL_LEXICONS = SERVER_URL + 'api/v1/lexicons/all';
export const GET_ALL_LEXICONS_LIMITED = SERVER_URL + 'api/v1/lexicons/all/limited';
export const GET_LEXICON = SERVER_URL + 'api/v1/lexicons/get';
export const UPDATE_LEXICON = SERVER_URL + 'api/v1/lexicons/update';
export const GET_LEXICONS_PAGINATED = SERVER_URL + 'api/v1/lexicons/all/paginated';
export const GET_LEXICONS_FILTERED_PAGINATED = SERVER_URL + 'api/v1/lexicons/all/filtered/paginated';

//products(admin)
export const GET_ALL_PRODUCTS = SERVER_URL + 'api/v1/products/all';
export const GET_PRODUCTS_PAGINATED = SERVER_URL + 'api/v1/products/all/paginated';
export const GET_PRODUCT = SERVER_URL + 'api/v1/products/get';
export const UPDATE_PRODUCT = SERVER_URL + 'api/v1/products/update';
export const UPDATE_PRODUCT_COLOR = SERVER_URL + 'api/v1/products/color/update';
export const GET_PRODUCT_FILTERED_PAGINATED = SERVER_URL + 'api/v1/products/all/filtered/paginated';
export const UPDATE_PRODUCTS_PRICE = SERVER_URL + 'api/v1/products/update/prices';
export const GET_FOR_WIZARD = SERVER_URL + 'api/v1/products/all/filtered';
export const GET_BASES = SERVER_URL + 'api/v1/products/base';
export const GET_BASE_PRODUCTS = SERVER_URL + 'api/v1/products/filtered/bases';
export const UPDATE_RECOMENDED_PRICES = SERVER_URL + 'api/v1/products/update/recomended';
export const ADD_PRODUCT_IMAGE = SERVER_URL + 'api/v1/products/add/image';
export const ADD_PRODUCT_COLOR_IMAGE = SERVER_URL + 'api/v1/products/color/add/image';
export const GET_PARTNER_PRICE_LIST = SERVER_URL + 'api/v1/products/get/pricelist';
export const GET_ADDITIONAL_SERVICE_PRODUCTS = SERVER_URL + 'api/v1/products/additional_services';

//Promocode
export const GET_PROMOCODES_FILTERED = SERVER_URL + 'api/v1/promocode/get/filtered'
export const GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_NAME = SERVER_URL + 'api/v1/promocode/autocomplete/name'
export const GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_STATUS = SERVER_URL + 'api/v1/promocode/autocomplete/status'
export const GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_SHOP_ADDRESS = SERVER_URL + 'api/v1/promocode/autocomplete/address'
export const GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_CERTIFICATE_TYPE = SERVER_URL + 'api/v1/promocode/autocomplete/type'
export const UPLOAD_PROMOCODES_FROM_FILE = SERVER_URL + 'api/v1/promocode/upload'
export const DELETE_PROMOCODES = SERVER_URL + 'api/v1/promocode/delete'
export const EXPORT_PROMOCODES = SERVER_URL + 'api/v1/promocode/export'

//Aviability
export const GET_AUTOCOMPLETE_LEXICON_RESULT = SERVER_URL + 'api/v1/lexicons/all/autocomplete';
export const GET_AUTOCOMPLETE_MODEL_RESULT = SERVER_URL + 'api/v1/categories/sub/all/autocomplete';
export const GET_AUTOCOMPLETE_ARTICULUS_RESULT = SERVER_URL + 'api/v1/products/all/autocomplete';

//Printers

export const GET_All_PRINTERS =
  SERVER_URL + "api/v1/printers/allprint";
export const GET_PRINTER_NAME =
  SERVER_URL + "api/v1/printers/defaultname";
export const GET_PRINTER_CHOICE_OF_CHANGE_TYPE =
  SERVER_URL + "api/v1/printers/defaultchoicechange";
export const GET_PRINTER_SELECTION_OF_PRODUCT_TYPE =
  SERVER_URL + "api/v1/printers/defaultselectionproduct";
export const GET_PRINTER_CHOICE_OF_URGENCY =
  SERVER_URL + "api/v1/printers/defaultchoiceofurgency";
export const GET_PRINTER_LEXICON_SELECTION =
  SERVER_URL + "api/v1/printers/defaultlexiconselection";
export const ADD_PRINTER = SERVER_URL + "api/v1/printers/setnewprinter";
export const REMOVE_PRINTER_ID = SERVER_URL + "api/v1/printers/remove";


// TODO
export const GET_PRINTER_SELECTION_OF_ADDITIONAL_SERVICE =
  SERVER_URL + "api/v1/printers/defaultselectionadditionalservices";

//Category
export const GET_CATEGORY_FOR_AUTOCOMPLETE = SERVER_URL + 'api/v1/categories/all/autocomplete';
export const GET_PARENT_CATEGORIES = SERVER_URL + 'api/v1/categories/all';
export const GET_SUB_CATEGORIES = SERVER_URL + 'api/v1/categories/all/subcategories';

export const GET_CATEGORIES_FOR_SELECTED_PRODUCT = SERVER_URL + 'api/v1/categories/all/filtered';

//Price
export const GET_PARTNER_AUTOCOMPLETE = SERVER_URL + 'api/v1/partners/all/autocomplete';

export const GET_ALL_DEFAULT_PRICE_SEGMENTS = SERVER_URL + 'api/v1/partners/price/all/default';
export const UPDATE_PARTNER_PRICE_SEGMENT = SERVER_URL + 'api/v1/partners/price/update';
export const GET_PARTNER_PRICE_SEGMENT = SERVER_URL + 'api/v1/partners/price/all/partner';
export const CREATE_PARTNER_PRICE_SEGMENT = SERVER_URL + 'api/v1/partners/price/new';
export const DELETE_PARTNER_PRICE_SEGMENT = SERVER_URL + 'api/v1/partners/price/delete';

export const GET_PARTNER_CONSTRUCTOR_PRICE = SERVER_URL + 'api/v1/partners/price/constructor/get';
export const ADD_PARTNER_CONSTRUCTOR_PRICE = SERVER_URL + 'api/v1/partners/price/constructor/add';
export const UPDATE_PARTNER_CONSTRUCTOR_PRICE = SERVER_URL + 'api/v1/partners/price/constructor/update';

export const GET_PRICE_TERMS = SERVER_URL + 'api/v1/partners/price/terms/get/partner';
export const CREATE_PRICE_TERMS = SERVER_URL + 'api/v1/partners/price/terms/new';
export const UPDATE_PRICE_TERMS = SERVER_URL + 'api/v1/partners/price/terms/update';
export const DELETE_PRICE_TERMS = SERVER_URL + 'api/v1/partners/price/terms/delete';

export const GET_WHOLESALE = SERVER_URL + 'api/v1/partners/price/wholesale/all';
export const CREATE_WHOLESALE = SERVER_URL + 'api/v1/partners/price/wholesale/new';
export const UPDATE_WHOLESALE = SERVER_URL + 'api/v1/partners/price/wholesale/update';
export const DELETE_WHOLESALE = SERVER_URL + 'api/v1/partners/price/wholesale/delete';

//Pricelist
export const GET_PRICELIST_PAGINATED = SERVER_URL + 'api/v1/pricelist/all';
export const GET_PRICELIST_URL = SERVER_URL + 'api/v1/pricelist/download';

//Pricelist options
export const ADD_PRICELIST_OPTIONS = SERVER_URL + 'api/v1/pricelist/options/new';
export const UPDATE_PRICELIST_OPTIONS = SERVER_URL + 'api/v1/pricelist/options/update';
export const REMOVE_PRICELIST_OPTIONS = SERVER_URL + 'api/v1/pricelist/options/delete';
export const GET_PRICELIST_OPTIONS = SERVER_URL + 'api/v1/pricelist/options/get';
export const GET_PRICELIST_OPTIONS_BY_PARTNER = SERVER_URL + 'api/v1/pricelist/options/get/partner';

//Platforms
export const GET_ALL_PLATFORMS = SERVER_URL + 'api/v1/platform/all';

//Clients
export const GET_ALL_CLIENTS_FILTERED_ANG_PAGINATED = SERVER_URL + 'api/v1/clients/all/filtered';
export const GET_CLIENTS_BY_PARTNTER_AND_PHONE = SERVER_URL + 'api/v1/clients/all/filterd/partner';
export const ADD_CLIENT = SERVER_URL + 'api/v1/clients/new';
export const GET_CLIENT = SERVER_URL + 'api/v1/clients/get';

//EXPORT PRODUCT
export const RENEW_OPENCART_SINGLE_COLOR_FILE = SERVER_URL + 'api/v1/pricelist/opencart/single/renew';
export const RENEW_OPENCART_MULTIPLE_COLOR_FILE = SERVER_URL + 'api/v1/pricelist/opencart/multiple/renew';

//FOLDERS
export const EXPORT_ASSORTMENT_FOLDER = 'AssotrmentUpload';

//FILES
export const OPENCART_SINGLE_COLOR_EXPORT_FILE = 'opencart_export_single_products.xlsx'
export const OPENCART_MULTIPLE_COLOR_EXPORT_FILE = 'opencart_export_multiple_products.xlsx'

//APPEALS 
export const GET_ALL_APPEALS = SERVER_URL + 'appeal/all';

//MUTUAL SETTLEMENT
export const GET_ALL_MUTALSETTLEMENT = SERVER_URL + 'api/v1/mutualsettlement/all';
export const ADD_MUTALSETTLEMENT = SERVER_URL + 'api/v1/mutualsettlement/add';
export const GET_BALANCE = SERVER_URL + 'api/v1/mutualsettlement/balance';
export const EXPORT_MUTUAL_SETTLEMENTS = SERVER_URL + 'api/v1/mutualsettlement/export';
export const IMPORT_AFTERPAYMENT_FILE = SERVER_URL + 'api/v1/mutualsettlement/afterpayments/import';

//ORDER
export const ADD_ORDER = SERVER_URL + 'api/v1/order/new';
export const UPDATE_ORDER = SERVER_URL + 'api/v1/order/update';
export const GET_ALL_ORDER = SERVER_URL + 'api/v1/order/all';
export const REMOVE_ORDER = SERVER_URL + 'api/v1/order/remove';
export const GET_ALL_ORDER_BY_PARTNER = SERVER_URL + 'api/v1/all/partner';
export const GET_ORDER_BY_ID = SERVER_URL + 'api/v1/order/get';
export const GET_ORDER_BY_UID = SERVER_URL + 'api/v1/order/get/default';
export const GET_ORDER_FOR_ASSEMBLING = SERVER_URL + 'api/v1/order/get/assembling';
export const GET_ORDER_FOR_DECLARATIONS = SERVER_URL + 'api/v1/order/get/declarations';
export const SEND_ORDERS_FOR_ASSEMBLE = SERVER_URL + 'api/v1/order/assemble';
export const CHECK_STATUS_TEMP = SERVER_URL + 'api/v1/order/check/status/temp';
export const CHECK_STATUS = SERVER_URL + 'api/v1/order/check/status';
export const GET_ORDER_AUTOCOMPLETE = SERVER_URL + 'api/v1/order/autocomplete/get';
export const GET_ALL_ORDER_AUTOCOMPLETE = SERVER_URL + 'api/v1/order/autocomplete/all';
export const GET_ORDER_AUDIT = SERVER_URL + 'api/v1/order/get/audit';
export const GET_ORDER_EXPORT_URL = SERVER_URL + 'api/v1/order/export';

export const GET_ORDERS_FOR_SEND_FROM_STOCK = SERVER_URL + 'api/v1/order/get/stock/send';
export const SEND_FROM_STOCK = SERVER_URL + 'api/v1/order/stock/send';

//ORDER STATUS
export const ADD_ORDER_STATUS = SERVER_URL + 'api/v1/order/status/add';
export const GET_ALL_STATUSES_FOR_ORDER = SERVER_URL + 'api/v1/order/status/all';

//PAYMENT STATUS
export const ADD_PAYMENT_STATUS_FOR_ORDER = SERVER_URL + 'api/v1/order/payment/add';
export const GET_ALL_PAYMENT_STATUS_FOR_ORDER = SERVER_URL + 'api/v1/order/payment/all/order';

//CONFIRM PAYMENT STATUS
export const ADD_CONFIRM_PAYMENT_STATUS = SERVER_URL + 'api/v1/order/payment/confirm/add';
export const GET_ALL_CONFIRM_PAYMENT_STATUS_FOR_ORDER = SERVER_URL + 'api/v1/order/payment/confirm/all/order';

//STOCK STATUS
export const GET_ALL_STOCK_STATUSES_FOR_ORDER = SERVER_URL + 'api/v1/order/stock/status/all/order';

//TEMP ORDER
export const ADD_TEMP_ORDER = SERVER_URL + 'api/v1/order/temp/new';
export const UPDATE_TEMP_ORDER = SERVER_URL + 'api/v1/order/temp/update';
export const REMOVE_TEMP_ORDER = SERVER_URL + 'api/v1/order/temp/remove';
export const GET_ALL_TEMP_ORDER_BY_PARTNER = SERVER_URL + 'api/v1/order/temp/all/partner';
export const GET_TEMP_ORDER_BY_ID = SERVER_URL + 'api/v1/order/temp/get';
export const GET_TEMP_ORDER_BY_UID = SERVER_URL + 'api/v1/order/temp/get/default';
export const CHECK_FOR_UNFINISHED_TEMP = SERVER_URL + 'api/v1/order/temp/check';
export const PROCESS_TEMP_ORDER = SERVER_URL + 'api/v1/order/temp/process';

//ORDER PRODUCT
export const ADD_ORDER_PRODUCT = SERVER_URL + 'api/v1/order/product/new';
export const ADD_ORDER_PRODUCT_WITH_SERVICES = SERVER_URL + 'api/v1/order/product/new/with_services';
export const UPDATE_ORDER_PRODUCT = SERVER_URL + 'api/v1/order/product/update';
export const REMOVE_ORDER_PRODUCT = SERVER_URL + 'api/v1/order/product/remove';
export const GET_ORDER_PRODUCT_BY_ID = SERVER_URL + 'api/v1/order/product/get';
export const GET_ORDER_PRODUCT_BY_UID = SERVER_URL + 'api/v1/order/product/get/default';
export const GET_ALL_ORDER_PRODUCT_BY_ORDER_ID = SERVER_URL + 'api/v1/order/product/get/order';
export const GET_ALL_ORDER_PRODUCT_BY_ORDER_UID = SERVER_URL + 'api/v1/order/product/get/order/default';
export const GET_PRICE_FOR_PRODUCT = SERVER_URL + 'api/v1/order/product/get/total/quantity';
export const UPLOAD_ORDER_PRODUCT_IMAGE = SERVER_URL + 'api/v1/order/product/upload/image';
export const UPLOAD_ORDER_PRODUCT_PREVIEW = SERVER_URL + 'api/v1/order/product/upload/preview';
export const CHANGE_ORDER_PRODUCT_AVAILABILITY = SERVER_URL + 'api/v1/order/product/availability';
export const UPLOAD_ORDER_PRODUCT_IMAGE_ORDER = SERVER_URL + 'api/v1/order/product/upload/image/order';
export const UPLOAD_ORDER_PRODUCT_IMAGE_WIZARD = SERVER_URL + 'api/v1/order/product/upload/image/wizard';
export const PROCESS_FAIL_ORDER_PRODUCT_MULTI = SERVER_URL + 'api/v1/order/product/proccess/fail/multi';
export const REMOVE_FILE_FROM_ORDER_PRODUCT = SERVER_URL + 'api/v1/order/product/remove/image';
export const SET_FORCE_WHOLESALE = SERVER_URL + 'api/v1/order/product/force/wholesale';
export const GET_ADDITIONAL_OPTIONS_TREE = SERVER_URL + 'api/v1/order/product/get/options';
export const GET_ORDER_PRODUCTS_FOR_PAGE = SERVER_URL + 'api/v1/order/product/get/page';

//DELIVERY 
export const GET_DELIVERY = SERVER_URL + 'api/v1/delivery/get/price';
export const CALCULATE_SEND_DATE = SERVER_URL + 'api/v1/delivery/calulate/send';
export const DECLARATIONS_DEFAULT = SERVER_URL + 'api/v1/delivery/declarations';
export const DECLARATIONS_MULTI = SERVER_URL + 'api/v1/delivery/declarations/multi';

export const GENERATE_REESTERS = SERVER_URL + 'api/v1/delivery/generate/reesters';
export const GET_REESTERS = SERVER_URL + 'api/v1/delivery/get/reesters';
export const GET_REESTERS_FILTERED = SERVER_URL + 'api/v1/delivery/get/reesters/filtered';

export const SET_TTN_HARD = SERVER_URL + 'api/v1/delivery/set/ttn';

//TUTORIAL
export const NEW_TUTORIAL = SERVER_URL + 'api/v1/tutorial/new';
export const UPDATE_TUTORIAL = SERVER_URL + 'api/v1/tutorial/update';
export const GET_ALL_TUTORIALS = SERVER_URL + 'api/v1/tutorial/all/paginated';
export const GET_TUTORIALS_FOR_CONSTRUCTOR = SERVER_URL + 'api/v1/tutorial/get/constructor';

//STATISTICS
export const GET_FINANCES_STATS = SERVER_URL + 'api/v1/statistics/get/finances';
export const GET_PARTNERS_STATS = SERVER_URL + 'api/v1/statistics/get/partners';
export const GET_PRODUCTION_STATS = SERVER_URL + 'api/v1/statistics/get/production';
export const GET_LASER_STATS = SERVER_URL + 'api/v1/statistics/get/laser';
export const GET_PRINT_STATS = SERVER_URL + 'api/v1/statistics/get/print';
export const GET_PAYMENT_STATS = SERVER_URL + 'api/v1/statistics/get/payment';
export const GET_DELIVERY_STATS = SERVER_URL + 'api/v1/statistics/get/delivery';
export const GET_CLIENT_STATS = SERVER_URL + 'api/v1/statistics/get/client';
export const GET_ORDER_STATS = SERVER_URL + 'api/v1/statistics/get/order';
export const GET_FINISHED_ORDER_PRODUCT = SERVER_URL + 'api/v1/statistics/get/finished/order/products';
export const GET_FINANCES_DIAGRAM = SERVER_URL + 'api/v1/statistics/get/finances/diagram';
export const GET_ORDERS_DIAGRAM = SERVER_URL + 'api/v1/statistics/get/orders/diagram';
export const GET_FINISHED_ORDER_PRODUCT_DIAGRAM = SERVER_URL + 'api/v1/statistics/get/finished/order/products/diagram';

//PRINT STATISTICS
export const GET_PRINT_STATISTICS = SERVER_URL + 'api/v1/printingstatistics/get';

//NOVA POSHTA API
export const NOVA_POSHTA_GET_CITIES = 'https://api.novaposhta.ua/v2.0/json/Address/searchSettlements/';
export const NOVA_POSHTA_GET_WEREHOUSES =  SERVER_URL + 'api/v1/delivery/get/warehouses' //'https://api.novaposhta.ua/v2.0/json/AddressGeneral/getWarehouses/';
export const NOVA_POSHTA_CALCULATE_DELIVERY_DATE = 'https://api.novaposhta.ua/v2.0/json/getDocumentDeliveryDate/';
export const NOVA_POSHTA_API_KEY = '85eb18be10a5ece0025f9eaffa0ac0a6';


//Ukr poshta pdfs
export const UKR_POSHTA_GET_CITIES = 'https://www.ukrposhta.ua/address-classifier-ws/get_regions_by_region_ua';

export const UKR_POSHTA_PDF_DOWNLOAD_ENDPOINT = SERVER_URL + 'api/v1/delivery/get/up/shipments';
export const GET_REGION_UKR_POSHTA = SERVER_URL + 'api/v1/delivery/get/region';
export const GET_AREA_UKR_POSHTA = SERVER_URL + 'api/v1/delivery/get/area';
export const GET_CITY_UKR_POSHTA = SERVER_URL + 'api/v1/delivery/get/city';