export enum PromocodeStatus {
    NotUsed = 1,
    Used = 2
}

export const GetPromocodeStatusName = (status: PromocodeStatus) => {
    switch(status){
        case PromocodeStatus.NotUsed:
            return 'Не использован';
        case PromocodeStatus.Used:
            return 'Использован';

        default:
            return '';
    }
}