import * as React from 'react';
import * as ReactDOM from 'react-dom';
import configureStore from './store/store.config';
import createBrowserHistory from 'history/createBrowserHistory';
import storeProvider from './helpers/store.provider';
import { AppInit } from './helpers/app.init';
import { ApplicationState } from './Reducers/index';
import { History } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import Routing from './routing';
import 'rxjs';
//import './assets/styles/main.scss';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/empty';
import { initialize, addTranslationForLanguage, setActiveLanguage } from 'react-localize-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { hot } from 'react-hot-loader';
import 'rc-pagination/assets/index.css';
import 'rc-select/assets/index.css';

const history: any = createBrowserHistory();
const initialState = (window as any).initialReduxState as ApplicationState;
const store = configureStore(history, initialState);
storeProvider.init(store);
const json = require('./ru.json');
const languages = ['ru'];

store.dispatch(initialize(languages));
store.dispatch(addTranslationForLanguage(json, 'ru'));
store.dispatch(setActiveLanguage('ru'));

ReactDOM.render(
  <MuiThemeProvider>
    <Provider store={store}>
      <Router history={history}>
        <Routing path='/' onEnter={AppInit(store)} />
      </Router>
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);