import * as React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { StatisticsState } from '../../../../states/cabinet/statistics.state';
import * as actions from "../../../../actions/cabinet/statistics.actions";
import { Loader } from '../../../shared/loader';
import {
    StatisticsTypeEnum,
    GetStatisticsTypesForSelectForBoss,
    GetStatisticsTypesForSelectForCoordinator,
    GetStatisticsTypesForSelectForPartner,
    GetStatisticsTypesForSelectForPrint,
    GetStatisticsTypesForSelectForManufacture,
    GetStatisticsTypesForSelectForLasercut
} from '../../../../helpers/enums/statistics.type.enum';
import { default as Finances } from "./finances";
import { default as Partners } from "./partners";
import { default as Production } from "./production";
import { default as Print } from "./print";
import { default as Payment } from "./payment";
import { default as Delivery } from "./delivery";
import { default as Client } from "./clients";
import { default as Order } from "./order";
import { default as FinishedProducts } from "./finishedProducts";
import { TimePicker, DatePicker } from 'material-ui';
import * as moment from 'moment';
import { Route } from 'react-router-dom';
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept } from '../../../../helpers/identity.roles';
import { List } from 'linqts';
import { default as DiagramsComponent } from './diagrams.component';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';
import { GetPartnerStatisticsTypesForDiagrams, GetStatisticsTypesForDiagrams, DiagramStatsEnum } from '../../../../helpers/enums/diagram.stats.enum';
import { default as Laser } from './laser';

export interface IStatisticsProps {
    Statistics: StatisticsState;
    GetFinances(parnterId, start, end);
    GetPartnersSatats(parnterId, start, end);
    GetProductionStats(parnterId, start, end);
    GetLaserStats(parnterId, start, end);
    GetPrintStats(parnterId, start, end);
    GetPaymentStats(parnterId, start, end);
    GetDeliveryStats(parnterId, start, end);
    GetClientStats(parnterId, start, end);
    GetOrderStats(parnterId, start, end);
    GetFinishedProductsStats(parnterId, start, end);
    GetPartners(value);
    ChangeStat(value);
    ChangePartner(value);
    Unmount();
    ChangeStartDate(value);
    ChangeEndDate(value);
    SetOptions(value);
    ChangeDiagrammMode(mode: boolean);
    SelectPartnerForDiagram(valeu);
    RemovePartnerFromList(index);
    SelectPartnerForList(value);
    SetDiagramOptions(value);
    SelectDiagramOption(value);
    SelectDiagramStatOption(value);
    AddStatForList(value);
    RemoveStatFromList(index);
    ChangeTimepart(value);
    GetFinancesDiagram(value);
    GetOrdersDiagram(value);
    GetFinishedOrderProductsDiagram(value);
    ChangeDiagramStartDate(date);
    ChangeDiagramEndDate(date);
    ResetDiagrams();
    GetLexicons();
    ResetPartnersDiagrams();
}

function mapStateToProps(state) {
    return {
        Authorization: state.authentication,
        Statistics: state.cabinet.statistics
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetFinances: actions.GetFinanceStats,
        GetPartnersSatats: actions.GetPartnerStats,
        GetProductionStats: actions.GetParoductionSatats,
        GetLaserStats: actions.GetLaserSatats,
        GetPrintStats: actions.GetPrintStats,
        GetPaymentStats: actions.GetPaymentStats,
        GetDeliveryStats: actions.GetDeliveryStats,
        GetClientStats: actions.GetClientStats,
        GetOrderStats: actions.GetOrderStats,
        GetFinishedProductsStats: actions.GetFinishedProductsStats,
        ChangeStat: actions.ChangeStat,
        GetPartners: actions.GetPartnersForFilter,
        ChangePartner: actions.ChangePartner,
        ChangeStartDate: actions.ChangeStartDate,
        ChangeEndDate: actions.ChangeEndDate,
        SetOptions: actions.SetOptions,
        ChangeDiagrammMode: actions.ChangeDiagrammMode,
        SelectPartnerForDiagram: actions.SelectPartnerForDiagram,
        RemovePartnerFromList: actions.RemovePartnerFromList,
        SelectPartnerForList: actions.SelectPartnerForList,
        SetDiagramOptions: actions.SetDiagramOptions,
        SelectDiagramOption: actions.SelectDiagramOption,
        SelectDiagramStatOption: actions.SelectDiagramStatOption,
        AddStatForList: actions.AddStatForList,
        RemoveStatFromList: actions.RemoveStatFromList,
        ChangeTimepart: actions.ChangeTimepart,
        GetFinancesDiagram: actions.GetFinancesDiagram,
        GetOrdersDiagram: actions.GetOrdersDiagram,
        SetDiagram: actions.SetDiagram,
        ChangeDiagramStartDate: actions.ChangeDiagramStartDate,
        ChangeDiagramEndDate: actions.ChangeDiagramEndDate,
        ResetDiagrams: actions.ResetDiagrams,
        ResetPartnersDiagrams: actions.ResetPartnerDiagrams,
        GetLexicons: actions.GetLexicons,
        GetFinishedOrderProductsDiagram: actions.GetFinishedOrderProductsDiagram,
        Unmount: actions.Unmount,
    }, dispatch);
}

class Statistics extends React.Component<any, any>{

    public IntlPolyfill = require('intl');
    // public jsonp =  require('intl/locale-data/jsonp/ru-UA');

    public format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    constructor(props) {
        super(props);


        if (props.Authorization.Role === "Coordinator") {
            this.props.SetOptions(GetStatisticsTypesForSelectForCoordinator());
            this.props.SetDiagramOptions(GetStatisticsTypesForDiagrams());

            this.props.GetFinances(null, null, null, null);
            this.props.GetPartners("");
        }

        if (props.Authorization.Role === "Boss" || props.Authorization.Role === "Administrator") {
            this.props.SetOptions(GetStatisticsTypesForSelectForBoss());
            this.props.SetDiagramOptions(GetStatisticsTypesForDiagrams());

            this.props.GetFinances(null, null, null, null);
            this.props.GetPartners("");
        }

        if (props.Authorization.Role === "Partner") {
            const stats = GetStatisticsTypesForSelectForPartner();

            const diagrammStats = GetPartnerStatisticsTypesForDiagrams();

            this.props.SetDiagramOptions(diagrammStats);
            this.props.SelectDiagramOption(diagrammStats[0]);

            this.props.SetOptions(stats);
            this.props.ChangeStat(stats[0]);

            this.props.GetOrderStats(null, (this.props as any).Authorization.UserNetUid, null, null);
        }

        if (props.Authorization.Role === "Print") {
            this.props.SetOptions(GetStatisticsTypesForSelectForPrint());
            this.props.GetFinances(null, null, null, null);
            this.props.GetPartners("");
        }

        if (props.Authorization.Role === "Manufacture") {
            this.props.SetOptions(GetStatisticsTypesForSelectForManufacture());
            this.props.GetFinances(null, null, null, null);
            this.props.GetPartners("");
        }

        if (props.Authorization.Role === "Lasercut") {
            this.props.SetOptions(GetStatisticsTypesForSelectForLasercut());
            this.props.GetFinances(null, null, null, null);
            this.props.GetPartners("");
        }
        // this.props.SetOptions(props.Authorization.Role !== "Coordinator" ? GetStatisticsTypesForSelectForBoss() : GetStatisticsTypesForSelectForCoordinator());

        // this.props.GetFinances(null, null, null);
        // this.props.GetPartners("");
        if (props.Authorization.Role !== "Partner")
            this.props.GetLexicons();

    }

    componentWillUnmount() {
        this.props.Unmount();
    }

    public GetComponent() {
        switch (this.props.Statistics.SelectedType.value) {
            case StatisticsTypeEnum.Finances:
                return <Route path={`${(this.props as any).match.url}`} component={Finances} />
            case StatisticsTypeEnum.Partners:
                return <Route path={`${(this.props as any).match.url}`} component={Partners} />;
            case StatisticsTypeEnum.Production:
                return <Route path={`${(this.props as any).match.url}`} component={Production} />;
            case StatisticsTypeEnum.Laser:
                return <Route path={`${(this.props as any).match.url}`} component={Laser} />;
            case StatisticsTypeEnum.Print:
                return <Route path={`${(this.props as any).match.url}`} component={Print} />;
            case StatisticsTypeEnum.Payment:
                return <Route path={`${(this.props as any).match.url}`} component={Payment} />;
            case StatisticsTypeEnum.Delivery:
                return <Route path={`${(this.props as any).match.url}`} component={Delivery} />;
            case StatisticsTypeEnum.Client:
                return <Route path={`${(this.props as any).match.url}`} component={Client} />;
            case StatisticsTypeEnum.Order:
                return <Route path={`${(this.props as any).match.url}`} component={Order} />;
            case StatisticsTypeEnum.FinishedProducts:
                return <Route path={`${(this.props as any).match.url}`} component={FinishedProducts} />;
        }
    }

    public OnChangePartenrCommand(value) {
        this.props.ChangePartner(value);
        this.GetStats(
            value ? value.partnerId : null,
            null,
            this.props.Statistics.StartDate ? this.props.Statistics.StartDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            this.props.Statistics.EndDate ? this.props.Statistics.EndDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            null);
    }

    public OnPartnerAutocompleteChangeCommand(value) {
        this.props.GetPartners(value);
    }

    public OnChangeStartDateCommand(value) {
        this.props.ChangeStartDate(value ? moment(value).hour(0).minutes(0).seconds(0) : null);
        this.GetStats(
            this.props.Statistics.SelectedPartner ? this.props.Statistics.SelectedPartner.partnerId : null,
            this.props.Authorization.Role === 'Partner' ? (this.props as any).Authorization.UserNetUid : null,
            value ? moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            this.props.Statistics.EndDate ? this.props.Statistics.EndDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            null);
    }

    public OnChangeEndDateCommand(value) {
        this.props.ChangeEndDate(value ? moment(value).hour(0).minutes(0).seconds(0) : null);
        this.GetStats(
            this.props.Statistics.SelectedPartner ? this.props.Statistics.SelectedPartner.partnerId : null,
            this.props.Authorization.Role === 'Partner' ? (this.props as any).Authorization.UserNetUid : null,
            this.props.Statistics.StartDate ? this.props.Statistics.StartDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            value ? moment(value).hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            null);
    }

    public GetStats(partnerId, uid, start, end, type: any): void {
        switch (type ? type.value : this.props.Statistics.SelectedType.value) {
            case StatisticsTypeEnum.Finances:
                this.props.GetFinances(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Partners:
                this.props.GetPartnersSatats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Production:
                this.props.GetProductionStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Laser:
                this.props.GetLaserStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Print:
                this.props.GetPrintStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Payment:
                this.props.GetPaymentStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Delivery:
                this.props.GetDeliveryStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Client:
                this.props.GetClientStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.Order:
                this.props.GetOrderStats(partnerId, uid, start, end);
                break;
            case StatisticsTypeEnum.FinishedProducts:
                this.props.GetFinishedProductsStats(partnerId, uid, start, end);
                break;
        }
    }

    public OnChangeStatCommand(value): void {
        this.props.ChangeStat(value);
        this.GetStats(
            this.props.Statistics.SelectedPartner ? this.props.Statistics.SelectedPartner.partnerId : null,
            this.props.Authorization.Role === "Partner" ? (this.props as any).Authorization.UserNetUid : null,
            this.props.Statistics.StartDate ? this.props.Statistics.StartDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            this.props.Statistics.EndDate ? this.props.Statistics.EndDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : null,
            value)
    }

    public OnGetDiagramDataCommand(): void {
        this.props.SetDiagram([]);

        let diagramBody: any = {
            Stats: this.props.Statistics.SelectedStatsList.map(c => { return { Id: this.props.Statistics.SelectedDiagramType.value, Group: c.value } }),
            PartnerUids: this.props.Authorization.Role === 'Partner' ? [(this.props as any).Authorization.UserNetUid] : this.props.Statistics.SelectedPartnerList.map(c => c.value),
            StartDate: this.props.Statistics.DiagramStartDate ? this.props.Statistics.DiagramStartDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : undefined,
            EndDate: this.props.Statistics.DiagramEndDate ? this.props.Statistics.DiagramEndDate.hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss') : undefined,
            Ticks: this.props.Statistics.SelectedTimePart.value
        }

        switch (this.props.Statistics.SelectedDiagramType.value) {
            case DiagramStatsEnum.Finances:
                this.props.GetFinancesDiagram(diagramBody);
                break;
            case DiagramStatsEnum.OrderCount:
            case DiagramStatsEnum.OrderPrice:
                this.props.GetOrdersDiagram(diagramBody);
                break;
            case DiagramStatsEnum.FinishedProducts:
                this.props.GetFinishedOrderProductsDiagram(diagramBody);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="cMutualSettlements__container2 rcPagination__component">

                <LeftMenuMobileControl />
                <div className='left_menu_component'>
                    <div className="left_menu_SCROLL">
                        {!this.props.Statistics.DiagrammMode ?
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Показатель</div>
                                <div className="selectWrapper__container">
                                    <Select
                                        name="stats"
                                        options={this.props.Statistics.TypesList}
                                        value={this.props.Statistics.SelectedType}
                                        onChange={(value) => this.OnChangeStatCommand(value)}
                                        placeholder="Показатель"
                                    />
                                </div>
                            </div> :
                            <>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Группа</div>
                                    <div className="selectWrapper__container">
                                        <Select
                                            name="stats"
                                            // isOptionDisabled={(option) => new List<any>(this.props.Statistics.SelectedStatsList).Any(c => c.value === option.value) }
                                            options={this.props.Statistics.DiagramTypes}
                                            value={this.props.Statistics.SelectedDiagramType}
                                            onChange={(value) => this.props.Statistics.SelectedStatsList.length === 0 && this.props.SelectDiagramOption(value)}
                                            placeholder="Группа"
                                        />
                                    </div>
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Показатель</div>
                                    <div className="selectWrapper__container">
                                        <Select
                                            name="stats"
                                            isOptionDisabled={(option) => new List<any>(this.props.Statistics.SelectedStatsList).Any(c => c.value === option.value)}
                                            options={this.props.Statistics.StatsList}
                                            value={this.props.Statistics.SelectedDiagrammStat}
                                            onChange={(value) => this.props.SelectDiagramStatOption(value)}
                                            placeholder="Показатель"
                                        />
                                    </div>
                                </div>

                                <div className={`defaultFormControl ${this.props.Statistics.SelectedStatsList && new List<any>(this.props.Statistics.SelectedStatsList).Any(c => c.value === this.props.Statistics.SelectedDiagrammStat.value) ? 'disabled' : ''}`}>
                                    <div className="rightPullControls2">
                                        <div className="defaultFormButton" onClick={() => (this.props.Statistics.SelectedDiagrammStat && !new List<any>(this.props.Statistics.SelectedStatsList).Any(c => c.value === this.props.Statistics.SelectedDiagrammStat.value)) && this.props.AddStatForList(this.props.Statistics.SelectedDiagrammStat)}>
                                            Добавить показатель
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {!this.props.Statistics.DiagrammMode ?
                            <PermissionChecker ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])} simple>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Партнёр</div>
                                    <div className="selectWrapper__container">
                                        <Select
                                            name="partner"
                                            options={this.props.Statistics.PartnerList}
                                            value={this.props.Statistics.SelectedPartner}
                                            onInputChange={(value) => this.OnPartnerAutocompleteChangeCommand(value)}
                                            onChange={(value) => this.OnChangePartenrCommand(value)}
                                            placeholder="Партнёр"
                                        />
                                    </div>
                                </div>

                                <div className="defaultFormControl">
                                    <div className="rightPullControls2">
                                        <div className="defaultFormButton" onClick={() => { this.OnChangePartenrCommand(null) }}>
                                            Очистить партнёра
                                        </div>
                                    </div>
                                </div>
                            </PermissionChecker>
                            :
                            <PermissionChecker ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])} simple>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Партнёр</div>
                                    <div className="selectWrapper__container">
                                        <Select
                                            name="partner"
                                            options={this.props.Statistics.PartnerList}
                                            value={this.props.Statistics.PartnerForList}
                                            isOptionDisabled={(option) => new List<any>(this.props.Statistics.SelectedPartnerList).Any(c => c.value === option.value)}
                                            onInputChange={(value) => this.OnPartnerAutocompleteChangeCommand(value)}
                                            onChange={(value) => this.props.SelectPartnerForList(value)}
                                            placeholder="Партнёр"
                                        />
                                    </div>
                                </div>

                                <div className={`defaultFormControl ${this.props.Statistics.PartnerForList && new List<any>(this.props.Statistics.SelectedPartnerList).Any(c => c.value === this.props.Statistics.PartnerForList.value) ? 'disabled' : ''}`}>
                                    <div className="rightPullControls2">
                                        <div className="defaultFormButton" onClick={() => ((this.props.Statistics.PartnerForList && this.props.Statistics.PartnerForList.value) && !new List<any>(this.props.Statistics.SelectedPartnerList).Any(c => c.value === this.props.Statistics.PartnerForList.value)) && this.props.SelectPartnerForDiagram(this.props.Statistics.PartnerForList)}>
                                            Добавить партнёра
                                        </div>
                                    </div>
                                </div>

                            </PermissionChecker>
                        }

                        {!this.props.Statistics.DiagrammMode ?
                            <>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Начальная дата</div>
                                    <div className="dateTimeWrapper__container">
                                        <DatePicker
                                            // DateTimeFormat={this.IntlPolyfill.DateTimeFormat}
                                            formatDate={this.format}
                                            floatingLabelText="Начальная дата"
                                            value={this.props.Statistics.StartDate ? this.props.Statistics.StartDate.toDate() : this.props.Statistics.StartDate}
                                            onChange={(event, date: any) =>
                                                this.OnChangeStartDateCommand(date)
                                            }
                                        />
                                        <div className="clControl" onClick={() => this.OnChangeStartDateCommand(null)} />
                                    </div>
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Конечная дата</div>
                                    <div className="dateTimeWrapper__container">
                                        <DatePicker
                                            formatDate={this.format}
                                            floatingLabelText="Конечная дата"
                                            value={this.props.Statistics.EndDate ? this.props.Statistics.EndDate.toDate() : this.props.Statistics.EndDate}
                                            onChange={(event, date: any) =>
                                                this.OnChangeEndDateCommand(date)
                                            }
                                        />
                                        <div className="clControl" onClick={() => this.OnChangeEndDateCommand(null)} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Начальная дата</div>
                                    <div className="dateTimeWrapper__container">
                                        <DatePicker
                                            // DateTimeFormat={this.IntlPolyfill.DateTimeFormat}
                                            formatDate={this.format}
                                            floatingLabelText="Начальная дата"
                                            value={this.props.Statistics.DiagramStartDate ? this.props.Statistics.DiagramStartDate.toDate() : this.props.Statistics.DiagramStartDate}
                                            onChange={(event, date: any) =>
                                                this.props.ChangeDiagramStartDate(date ? moment(date).hour(0).minutes(0).seconds(0) : null)

                                            }
                                        />
                                        <div className="clControl" onClick={() => this.props.ChangeDiagramStartDate(null)} />
                                    </div>
                                </div>

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Конечная дата</div>
                                    <div className="dateTimeWrapper__container">
                                        <DatePicker
                                            formatDate={this.format}
                                            floatingLabelText="Конечная дата"
                                            value={this.props.Statistics.DiagramEndDate ? this.props.Statistics.DiagramEndDate.toDate() : this.props.Statistics.DiagramEndDate}
                                            onChange={(event, date: any) =>
                                                this.props.ChangeDiagramEndDate(date ? moment(date).hour(0).minutes(0).seconds(0) : null)
                                            }
                                        />
                                        <div className="clControl" onClick={() => this.props.ChangeDiagramEndDate(null)} />
                                    </div>
                                </div>
                            </>
                        }

                        {this.props.Statistics.DiagrammMode ?
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Группировать по:</div>
                                <div className="selectedTimePart">
                                    {this.props.Statistics.TimeParts.map((part, index) =>
                                        <div className={`${this.props.Statistics.SelectedTimePart.value === part.value ? 'is_selected' : ''}`} key={index} onClick={() => this.props.ChangeTimepart(part)}>
                                            {part.label}
                                        </div>
                                    )}
                                </div>
                            </div>
                            : null}

                        {this.props.Statistics.DiagrammMode ?

                            <div className={`defaultFormControl`}>
                                <div className="rightPullControls2">
                                    <div className="defaultFormButton" onClick={() => this.OnGetDiagramDataCommand()}>
                                        Сформировать диаграммы
                </div>
                                </div>
                            </div> : null}

                        {this.props.Statistics.DiagrammMode ?
                            <div className="statistics_DiagrammMode__CONTENT">

                                <details className="detailsStatistics__COMPONENT">
                                    <summary>Выбраные показатели: {this.props.Statistics.SelectedStatsList.length}</summary>

                                    <div>
                                        {this.props.Statistics.SelectedStatsList.length > 0 && this.props.Statistics.SelectedStatsList.map((stat, index) =>
                                            <div className="statItem" key={stat.value}>
                                                <span>{stat.label}</span>
                                                <span className="remove" onClick={() => this.props.RemoveStatFromList(index)}>X</span>
                                            </div>)}
                                    </div>
                                </details>

                                <PermissionChecker ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])} simple>
                                    <details className="detailsStatistics__COMPONENT">
                                        <summary>Выбраные партнёры: {this.props.Statistics.SelectedPartnerList.length}</summary>

                                        <div>
                                            {this.props.Statistics.SelectedPartnerList.length > 0 && this.props.Statistics.SelectedPartnerList.map((partner, index) =>
                                                <div className="statItem" key={partner.value}>
                                                    <span>{partner.label}</span>
                                                    <span className="remove" onClick={() => this.props.RemovePartnerFromList(index)}>X</span>
                                                </div>)}
                                        </div>
                                    </details>
                                </PermissionChecker>

                            </div>
                            : null
                        }

                    </div>
                </div>

                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Статистика</span>
                        <span className="title_description"></span>
                    </div>

                    <label className="checkMid" >
                        <input type="checkbox" name="available" defaultChecked={this.props.Statistics.DiagrammMode} onClick={() => this.props.ChangeDiagrammMode(!this.props.Statistics.DiagrammMode)} />
                        <div className="checkControl"></div>
                            Диаграммы
                    </label>

                    {this.props.Statistics.DiagrammMode ?
                        <div className="header__button__controls">
                            <details className="dStyle" open>
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>
                                <div className="button__control" onClick={() =>  this.props.Authorization.Role === 'Partner' ? this.props.ResetPartnersDiagrams() : this.props.ResetDiagrams()}>
                                    Сбросить диаграммы
                                </div>
                            </details>

                            <details className="mStyle">
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>
                                <div className="button__control" onClick={() =>  this.props.Authorization.Role === 'Partner' ? this.props.ResetPartnersDiagrams() : this.props.ResetDiagrams()}>
                                    Сбросить диаграммы
                                </div>
                            </details>
                        </div>
                        :
                        null
                    }

                </div>
                <div className="content_component statisticsContent__CONTAINER">
                    {
                        this.props.Statistics.DiagrammMode ?
                            this.props.Statistics.Fetching ?
                                <Loader /> :
                                <Route path={`${(this.props as any).match.url}`} component={DiagramsComponent} />
                            :
                            this.props.Statistics.Fetching || !this.props.Statistics.StatisticsEntity ?
                                <Loader /> :
                                this.GetComponent()
                    }
                </div>
            </div>
        )
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(Statistics);