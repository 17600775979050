import { Lexicon } from "../../business.entities/lexicon";
import { Category } from "../../business.entities/category";
import { ProductPriceListItem } from "../../business.entities/product.price.list.item";
import { PriceListFilterItem } from "../../business.entities/price.list.filter.item";
import { PricelistOptions } from "../../business.entities/price.list.options";
import { default as PriceListGrid } from "../../components/dashboard/stock/price.list/price.list.grid";
import { PriceListLexiconPriceItem } from "../../business.entities/price.list.lexicon.price.item";
import { PriceListProductPriceItem } from "../../business.entities/price.list.product.price.item";
import { Platform } from "../../business.entities/platform";
import { ExportPtatformConfigItem } from "../../business.entities/platformConfigItem";

export class PriceListState {
    Lexicons: Lexicon[];
    Categories: Category[];
    Subcategories: Category[];
    PricelistItems: ProductPriceListItem[];
    PriceListFilter: PriceListFilterItem[];
    LexiconPriceItems: PriceListLexiconPriceItem[];
    ProductPriceItems: PriceListProductPriceItem[];
    ExportPlatformConfig: ExportPtatformConfigItem[];
    Options: PricelistOptions;
    SelectedComponent: any;
    IsGlobalLexicons: boolean;
    SelectedCategory: Category;
    SelectedSubCategory: Category;
    PriceListUrl: string;
    Platforms: Platform[];
    AvailableOnly: boolean;
    IsFetchInProccess: boolean;
    Page: number;
    TotalPages: number;
    Limit: number;

    RenewSingleInProccess: boolean;
    RenewMultipleInProccess: boolean;
}

export const defaultPriceListState: PriceListState = {
    Lexicons: [],
    Categories: [],
    Subcategories: [],
    PricelistItems: [],
    PriceListFilter: [],
    LexiconPriceItems: [],
    ProductPriceItems: [],
    ExportPlatformConfig: [],
    Options: null,
    SelectedComponent: PriceListGrid,
    IsGlobalLexicons: true,
    SelectedCategory: null,
    SelectedSubCategory: null,
    PriceListUrl: null,
    Platforms: [],
    AvailableOnly: false,
    IsFetchInProccess: false,
    Page: 1,
    TotalPages: 0,
    Limit: 40,
    RenewSingleInProccess: false,
    RenewMultipleInProccess: false
}