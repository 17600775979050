import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as React from "react";
import {
    UpdateConstructorTutorial,
    ChangeTutorial,
    ChangeVideo
} from "../../../../actions/cabinet/personal.info.actions";
import { Loader } from "../../../shared/loader";

const EditConstructorTutorialComponent = (props: any) => {

    const OnFileUpload = (event) => {
        const files = Array.from(event.target.files)
        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);
        props.ChangeVideo(formData);
    }

    const OnChangeTutorialCommand = (name: string) => {
        const updateTutorial = Object.assign({}, props.UserInfo.SelectedTutorial, {
            Name: name
        });

        props.ChangeTutorial(updateTutorial);
    }

    return (
        <div className="editTutorialContainer">

            <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Имя
                </div>
                <div className="defaultFormControl__value">
                    <input type="text" onChange={(event) => OnChangeTutorialCommand(event.target.value)} value={props.UserInfo.SelectedTutorial.Name} />
                </div>
            </div>


            {/* file */}
            <div className="defaultFormControl ">
                <div className="defaultFormControl__label">
                    Видео
                </div>
                {props.UserInfo.SelectedTutorial.Link || props.UserInfo.UploadedVideo ?
                    <div className="defaultFormControl__value">
                        <div className="imitation_inputText wordWrap">
                            {props.UserInfo.UploadedVideo ? "Uploaded video" : props.UserInfo.SelectedTutorial.Link}

                            <span className="rControl" onClick={() => props.ChangeVideo(null)}>Удалить</span>
                        </div>
                    </div> :
                    <label className="onFileUploadControl">
                        <div className="label">
                            Видео
                            <input type="file" accept="video/*" onChange={(event) => OnFileUpload(event)} />
                        </div>
                    </label>
                }
            </div>

            <div className="defaultFormControl">
                <div className={`defaultFormButtonControl ${props.UserInfo.Fetching ? 'isDisabled' : ''}`} onClick={() => !props.UserInfo.Fetching && props.UpdateConstructorTutorial(
                    props.UserInfo.SelectedTutorial)}>
                    Сохранить
                    </div>
            </div>
        </div>
    );
}
export default connect((state: any) => ({
    UserInfo: (state as any).cabinet.userInfo,
}),
    dispatch => bindActionCreators({
        ChangeTutorial,
        UpdateConstructorTutorial,
        ChangeVideo
    }, dispatch))(EditConstructorTutorialComponent);