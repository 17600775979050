import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import CreateOrder from "./create.order/create.order";
import { default as OrdersBase } from "./orders.base/orders.base";
import ClientsBase from "./clients.base/clients.base";
import PrintersBase from "./printers.base/printers.base";
import { connect } from "react-redux";
import { MenuProvider } from "../../../helpers/menu.provider";
import { default as OrderCard } from "./order.card/order.card";
import { default as Wizard } from "./create.order/wizard";

interface ISalesComponentProps {
    match: any;
    Authentication: any;
    MenuProvider: any;
    history: any;
}

class SalesComponent extends React.Component<ISalesComponentProps, any> {
    static defaultProps = {
        MenuProvider: new MenuProvider()
    };

    render() {
        const {
            match,
            Authentication,
            MenuProvider,
            history
        } = this.props;
        const role = Authentication.Role;
        const startPath = MenuProvider.GetFirstAllowedRoutesForRole(role, "sales");
        const currentLocation = history.location.pathname;
        return (
            <div>
                {currentLocation === this.props.match.url ?
                    <Redirect from={`${this.props.match.url}`} to={`/${startPath}`} /> : null
                }
                <Switch>
                    <Route path={`${match.url}/create-order`} component={CreateOrder} />
                    <Route path={`${match.url}/order-base`} component={OrdersBase} />
                    <Route path={`${match.url}/clients-base`} component={ClientsBase} />
                    <Route path={`${match.url}/printers-base`} component={PrintersBase} />
                    <Route path={`${match.url}/order-card/:netid`} component={OrderCard} />
                    <Route path={`${match.url}/wizard/:netId`} component={Wizard} isExact />
                </Switch>
            </div>
        );
    }
}
export default connect(state => ({
    Authentication: (state as any).authentication
}))(SalesComponent);