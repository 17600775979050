import { connect } from "react-redux";
import { ApplicationState } from "../../../../reducers";
import * as React from "react";
import { Form } from "react-redux-form";
import { bindActionCreators } from "redux";
import { TextBoxWithLabel } from "../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../helpers/validator.helper";
import Select from 'react-select';
import * as productActions from '../../../../actions/stock/product.actions';

const mapStateToProps = (state) => {
    return {
        Authorization: state.authentication,
        Printer: state.sales.printers,
        RVState: state.rightView,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ChangePriceCategorySelect: productActions.ChangePriceCategorySelect,
    ChangePriceLexiconSelect: productActions.ChangePriceLexiconSelect,
    UpdatePrices: productActions.BulkChangePrice,
    UpdateRecomendedPrices: productActions.UpdateRecomendedPrices
}, dispatch);

const BulkPriceUpdateForm = (props: any) => {

    return (
        //      <div className="orderBaseFilter__panelContainer">
        //     <div className={'defaultFormControl dateCol'}>
        //         <div className="defaultFormControl__label">Дата создания реестра</div>
        //         <div className="dateTimeWrapper__container">


        //             <div className="clControl" onClick={() => {

        //             }}

        //             />
        //         </div>
        //     </div>
        //     <div className={'defaultFormControl dateCol'}>
        //         <div className="defaultFormControl__label">ТТН</div>
        //         <div className="debounceInputWrapper__container">

        //         </div>
        //     </div>
        //     <div className={'defaultFormControl dateCol'}>
        //         <div className="defaultFormControl__label">Код заказа</div>
        //         <div className="debounceInputWrapper__container">

        //         </div>
        //     </div>

        //     <ul className='orderBase__ReestersWrapper'>
        //     </ul>
        // </div>
        <div>
            <Form
                validateOn='change'
                model="bulkPriceUpdateForm"
            >
                <div className="default_form_component">
                    <div className="defaultFormControl">
                        <div className="grid_scroll_content_component">
                            <div className=" grid_wrapper_component ">

                                {props.Printer.PrintersList.map((printer, index) => {
                                    debugger
                                    if (index === props.RVState.Props) {
                                        return <div key={index}>
                                            <div  >
                                                <div >
                                                    {printer.LexiconSelection.map((t, index: number) => {
                                                        debugger
                                                        return <span key={index}>{index + 1 + ": " + t.Lexicon + " . "}<div></div></span>
                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                    }

                                })}
                            </div>
                        </div>
                        {/* <div className="selectWrapper__container notPadd">
                            {props.Printer.PrintersList.map((printer, index) => {

                                <div style={{ whiteSpace: "break-spaces", overflow: "hidden", overflowY: "scroll" }} className='grid_cell obCell__code oBase__CELL' >
                                    <div className="cell_content obCell__codeC">
                                        {printer.LexiconSelection.map((t, index: number) => {
                                            debugger
                                            return <div key={index}>{t.Lexicon}</div>
                                        })}
                                    </div>
                                </div>
                            })}
                        </div> */}
                    </div>

                    {/* <div className="defaultFormControl">
                        <button type="submit" className="defaultFormButton">Сохранить</button>
                    </div> */}
                </div>
            </Form>

        </div >
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(BulkPriceUpdateForm)