import { success } from "react-notification-system-redux";
import { Store } from "redux";
import { Observable } from "rxjs";
import * as actionCreators from '../../actions/partner/promocode.actions';
import * as API from '../../constants/api.constants';
import { GetPromocodeStatusName } from "../../helpers/enums/promocode.status.enum";
import { ErrorHandler } from "../../helpers/error.manager";
import storeProvider from "../../helpers/store.provider";

export const getPromocodesFiltered = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PROMOCODES_FILTERED)
        .switchMap(action =>
            Observable.ajax.post(`${API.GET_PROMOCODES_FILTERED}`,
                JSON.stringify(action.payload), //filter
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    //debugger;
                    storeProvider.getStore().dispatch(success({
                        title: `Промокоды успешно загружены`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));
                    
                    return actionCreators.SetPromocodes(response.response.Body);

                }).catch((errorData: any) => {

                    return Observable.of(ErrorHandler(errorData));
                    
                })
        );

export const deletePromocodes = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.DELETE_PROMOCODES)
        .switchMap(action => {
            return Observable.ajax.post(`${API.DELETE_PROMOCODES}`,
            JSON.stringify(action.payload),
            { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type":"application/json" })
            .map((response: any) => {
                //debugger;
                storeProvider.getStore().dispatch(success({
                    title: `Выбраные промокоды успешно удалены`,
                    message: '',
                    position: 'br',
                    autoDismiss: 3,
                }));

                return actionCreators.SetPromocodes(response.response.Body);
                
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData))
            })
        })

export const getPromocodeNamesAutocomplete = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PROMOCODE_NAMES_AUTOCOMPLETE)
        .switchMap(action => {
            return Observable.ajax.get(`${API.GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_NAME}?filter=${action.payload}`,      // change here address ....
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    let modelSelectOptionData = response.response.Body.map(promocode =>
                        ({ value: promocode.Id.toString(), label: `${promocode.Name}` })
                    );

                    return actionCreators.GetPromocodeNamesAutocompleteSuccess(modelSelectOptionData);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
            }
        );

export const getPromocodeStatusesAutocomplete = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PROMOCODE_STATUSES_AUTOCOMPLETE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_STATUS}?filter=${action.payload}`,
            { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
            .map((response: any) => {
                //debugger;
                let modelSelectOptionData = response.Body.map(status => 
                    ({ value: status.toString(), label: `${GetPromocodeStatusName(status)}`})
                );
                return actionCreators.GetPromocodeStatusesAutocompleteSuccess(modelSelectOptionData);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );
        
export const getPromocodeShopAddressAutocomplete = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_SHOP_ADDRESS}?filter=${action.payload}`,     /// HERE TOO.
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    let modelSelectOptionData = response.Body.map((shopAddress, index) =>
                        ({ value: (index + 1).toString(), label: `${shopAddress}` })
                    );

                    return actionCreators.GetPromocodeShopAddressAutocompleteSuccess(modelSelectOptionData);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const getPromocodeTypeAutocomplete = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.GET_PROMOCODE_TYPE_AUTOCOMPLETE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_PROMOCODES_AUTOCOMPLETE_BY_CERTIFICATE_TYPE}?filter=${action.payload}`, // TOO.
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    let modelSelectOptionData = response.Body.map((promocodeType, index) =>
                        ({ value: (index + 1).toString(), label: `${promocodeType}` })
                    );

                    return actionCreators.GetPromocodeTypeAutocompleteSuccess(modelSelectOptionData);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const savePromocodes = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.SAVE_FILE_WITH_PROMOCODES)
        .switchMap(action => 
            Observable.ajax.post(`${API.UPLOAD_PROMOCODES_FROM_FILE}`,
            action.payload,
            { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
            .map((response: any) => {
                return storeProvider.getStore().dispatch(success({
                    title: `Промокоды успешно добавлены`,
                    message: '',
                    position: 'br',
                    autoDismiss: 3,
                }));
                
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const exportPromocodes = (action$, store: Store<any>) =>
    action$.ofType(actionCreators.EXPORT_PROMOCODES)
        .switchMap(action =>
            Observable.ajax.post(`${API.EXPORT_PROMOCODES}`,
            action.payload,
            { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
            .map((response: any) => {
                
                return actionCreators.ExportPromocodesSuccess(response.response.Body)
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );