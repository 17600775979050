import { bindActionCreators } from "redux";
import * as React from "react";
import Moment from 'react-moment';
import { connect } from "react-redux";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import { Loader } from "../../../shared/loader";
import { OrderStatus } from "../../../../business.entities/order.status";
import { GetNameForOrderStatus } from "../../../../helpers/enums/order.status.enum";
import { IsPartner } from "../../../../helpers/role.validator";
import { ApplicationState } from "../../../../reducers";

const mapStateToProps = (state: ApplicationState) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication
    };
};
type props = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

const OrderStatusesRV = (props: any) => {

    return (
        <>
        <div className="grid_component oossrv">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell cStatus">
                                <div className="cell_content ">Статус</div>
                            </div>
                            <div className="grid_cell cCreated">
                                <div className="cell_content ">Дата присвоения</div>
                            </div>
                            <div className="grid_cell cUserId">
                                <div className="cell_content ">Присвоил</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        {
                            (props.OrderBase as OrderBaseState).currentOrderStatuses ?
                                (props.OrderBase as OrderBaseState).currentOrderStatuses.map((status, index) =>
                                    <div className="grid_row mMEDIA___orderStatus__GRID_ROW" key={index}>
                                        <div className="grid_cell cStatus" data-tooltip="Статус">
                                            <div className="cell_content ">
                                                {GetNameForOrderStatus((status as OrderStatus).Status, IsPartner(props.Authorization))}
                                            </div>
                                        </div>
                                        <div className="grid_cell cCreated" data-tooltip="Дата присвоения">
                                            <div className="cell_content cContent">
                                                <Moment format="DD/MM/YYYY HH:mm" date={(status as OrderStatus).Created} />
                                            </div>
                                        </div>
                                        <div className="grid_cell cUserId" data-tooltip="Присвоил">
                                            <div className="cell_content ">
                                                {status.Partner ? status.Partner.ContactName : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <Loader />
                        }
                    </div>
                </div>

            </div>

        </div>
        <div className='orBssComment'>
            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Комментарий</div>
                <div className="defaultFormControlTextatea__value">
                    <div className="imitation_inputText">
                        {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].GlobalComment}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusesRV)