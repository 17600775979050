import * as moment from 'moment';
import * as printersActions from '../../actions/sales/printers.actions';
import Print from '../../business.entities/printer';

import {
    catchError,
    debounceTime,
    map,
    mergeMap,
    concatMap
} from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ofType } from 'redux-observable';
import * as API from '../../constants/api.constants';
import { ErrorHandler } from '../../helpers/error.manager';
import { Observable, of, pipe } from 'rxjs';
export const GetName = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_NAME),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_NAME}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    // map(response => {
                    //     let arrayToReturn: any[] = [];
                    //     (response as any).Body.forEach((element: Print) => {
                    //         arrayToReturn.push({
                    //              value: element.Id.toString(),
                    //              label: element.UserName,
                    //             });
                    //     });
                         
                    //     return arrayToReturn;
                    // }),
                    map(response => {
                        let ss = (response as any).Body.map((element: Print, index: number) => {
                            return {
                                ...element,
                                value: element.Id.toString(),
                                label: element.UserName,
                            }

                        })
                        return ss;
                    }),
                    map(data => printersActions.SetName(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetchoiceСhange = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_CHOICE_OF_CHANGE_TYPE),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_CHOICE_OF_CHANGE_TYPE}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    // map(response => {
                    //     let arrayToReturn: any[] = [];
                    //     (response as any).Body.forEach((element: Print) => {
                    //         arrayToReturn.push({
                    //              value: element.Id.toString(),
                    //              label: element.ChoiceOfChangeType,
                    //             });
                    //     });
                         
                    //     return arrayToReturn;
                    // }),
                    map(response => {
                        let ss = (response as any).Body.map((element: Print, index: number) => {
                            return {
                                ...element,
                                value: element.NetUid,
                                label: element.ChoiceOfChangeType,
                            }

                        })
                        return ss;
                    }),
                    map(data => printersActions.SetChoiceOfChangeType(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetSelectionProduct = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_SELECTION_OF_PRODUCT_TYPE),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_SELECTION_OF_PRODUCT_TYPE}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    // map(response => {
                    //     let arrayToReturn: any[] = [];
                    //     (response as any).Body.forEach((element: Print) => {
                    //         arrayToReturn.push({
                    //              value: element.Id.toString(),
                    //              label: element.Name,
                    //             });
                    //     });
                         
                    //     return arrayToReturn;
                    // }),
                    map(response => {
                        let ss = (response as any).Body.map((element: Print, index: number) => {
                            return {
                                ...element,
                                value: element.NetUid,
                                label: element.Name,
                            }

                        })
                        return ss;
                    }),
                    map(data => printersActions.SetSelectionOfProductType(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetChoiceOfUrgency = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_CHOICE_OF_URGENCY),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_CHOICE_OF_URGENCY}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                //     map(response => {
                //         let arrayToReturn: any[] = [];
                //         (response as any).Body.forEach((element: Print) => {
                //             arrayToReturn.push({
                //                  value: element.Id.toString(),
                //                  label: element.ChoiceOfUrgency,
                //                 });
                //         });
                         
                //         return arrayToReturn;
                //     }),
                 map(response => {
                     let ss = (response as any).Body.map((element: Print, index: number) => {
                         return {
                             ...element,
                             value: element.NetUid,
                             label: element.ChoiceOfUrgency,
                         }

                     })

                     // let arrayToReturn: any[] = [];
                     // (response as any).Body.forEach((element: Print) => {
                     //     arrayToReturn.push({
                     //          value: element.NetUid,
                     //          label: element.OfficialPartnerName,
                     //         });
                     // });

                     return ss;
                 }),
                    map(data => printersActions.SetChoiceOfUrgency(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const GetPrinters = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_PRINTERS),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_All_PRINTERS}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe( 
                    //     map(response => {
                    //         let arrayToReturn: any[] = [];
                    //         (response as any).Body.forEach((element: Print) => {
                    //             arrayToReturn.push({
                    //                  value: element.Id.toString(),
                    //                  label: element.ChoiceOfUrgency,
                    //                 });
                    //         });
                    //         return arrayToReturn;
                    //     }),
                    // map(response => {
                    //     let ss = (response as any).Body.map((element, index: number) => {
                    //         return {
                    //             ...element,
                    //             value: element.NetUid,
                    //             label: element.ChoiceOfUrgency,
                    //         }

                    //     })
                    //     return ss;
                    // }),
                map(function (data) {
                    
                        return printersActions.SetPrinters((data as any).Body);
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )


export const GetLexiconSelection = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_LEXICON_SELECTION),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_LEXICON_SELECTION}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        let ss = (response as any).Body.map((element: Print,index:number)=>{
                            return {
                                ...element,
                                value: element.Id.toString(),
                                label: element.OfficialPartnerName,
                                   }

                        })
                        // let arrayToReturn: any[] = [];
                        // (response as any).Body.forEach((element: Print) => {
                        //     arrayToReturn.push({
                        //          value: element.NetUid,
                        //          label: element.OfficialPartnerName,
                        //         });
                        // });
                         
                         return ss;
                    }),
                    map(data => printersActions.SetLexiconSelection(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )
export const GetSelectionAdditionalServices = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.GET_SELECTION_OF_ADDITIONAL_SERVICE),
        mergeMap((action: any) =>
            ajax.getJSON(`${API.GET_PRINTER_SELECTION_OF_ADDITIONAL_SERVICE}`,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    // map(response => {
                    //     let arrayToReturn: any[] = [];
                    //     (response as any).Body.forEach((element: Print) => {
                    //         arrayToReturn.push({
                    //              value: element.Id.toString(),
                    //              label: element.Name,
                    //             });
                    //     });
                    map(response => {
                        let ss = (response as any).Body.map((element: Print, index: number) => {
                            return {
                                ...element,
                                value: element.Id.toString(),
                                label: element.CasualName,
                            }

                        })
                        return ss;
                    }),
                    map(data => printersActions.SetSelectionOfAdditionalServices(data)),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )
    


export const AddNewClient = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.ADD_NEW_PRINTER),
        mergeMap((action: any) =>
            ajax.post(`${API.ADD_PRINTER}`,
                action.payload,
                { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .pipe(
                    map(response => {
                        return printersActions.SetNewPrinter(response.response.Body), printersActions.GetPrinters("");
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        )
    )

export const RemovePrinter = (action$, state$) =>
    action$.pipe(
        ofType(printersActions.REMOVE_PRINTER),
        mergeMap((action: any) => {
            return ajax.getJSON(`${API.REMOVE_PRINTER_ID}?NetUid=${action.payload}`,
                { 'Authorization': `Bearer ${state$.value.authentication.AuthenticationToken}` })
                .pipe(
                    map(response => {
                        debugger
                        return  printersActions.GetPrinters("");
                    }),
                    catchError(error => {
                        return Observable.of(ErrorHandler(error));
                    })
                )
        }
        )
    )

    
//return ajax.getJSON(`${API.GET_ORDER_BY_UID}?netUid=${action.payload}`,

// export const GetSelectionServices = (action$, state$) =>
//     action$.pipe(
//         ofType(printersActions.GET_SELECTION_OF_ADDITIONAL_SERVICE),
//         mergeMap((action: any) =>
//             ajax.getJSON(`${API.GET_PRINTER_SELECTION_OF_ADDITIONAL_SERVICE}`,
//                 { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}` })
//                 .pipe(
//                      map(response => printersActions.SetPrintersList((response as any).Body)),
//                     // map(response => {
//                     //     let arrayToReturn: any[] = [];
//                     //     (response as any).Body.forEach((element: Print) => {
//                     //         arrayToReturn.push({
//                     //              value: element.Id.toString(),
//                     //              label: element.SelectionOfAdditionalServices,
//                     //             });
//                     //     });
                         
//                     //     return arrayToReturn;
//                     // }),
//                     // map(data => printersActions.SetSelectionOfAdditionalServices(data)),
//                     catchError(error => {
//                         return Observable.of(ErrorHandler(error));
//                     })
//                 )
//         )
//     )
    

     //   map(response => wizardActions.SetProducts((response as any).Body)),