import * as React from "react";

namespace SelectControl {
    export interface IProps {
        options: any[];
        onChangeMethod: any;
    }

    export interface OptionModel {
        value: number;
        name: string;
    }
}

export const SelectControl = (props: SelectControl.IProps) => {

    const changeMethodWrapper = (event) => {
        props.onChangeMethod(event.target);
    }

    return (
        <select className="selectForForm" onChange={(event) => changeMethodWrapper(event)}>
            {
                props.options.map((option, index) => {
                    return <option key={index} value={option.value}>{option.name}</option>
                })
            }
        </select>
    );
}