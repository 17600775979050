import * as React from "react";
import { connect } from "react-redux";

export const Payment = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество заказов со статусом "Предоплата"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество отправленных заказов оплачиваемых из личного баланса. Заказы по самовывозу входят в показатель. Отправленным считается заказ если на момент проверки он уже переводился в статус 'Отправлен' в независимости от поточного статуса.
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PrepaidCount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество заказов со статусом "Наложеный платеж"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество отправленных заказов наложенным платежом. Заказы по самовывозу не входят в показатель. Отправленным считается заказ если на момент проверки он уже переводился в статус 'Отправлен' в независимости от поточного статуса.
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.AfterPaidCount}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(Payment);
