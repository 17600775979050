import { EntityBase } from "./entity.base";
import { Partner } from "./partner";

export class PriceTerm extends EntityBase {
    public D1OrderPriceSum: number = 0;
    public D2OrderPriceSum: number = 0;
    public D3OrderPriceSum: number = 0;
    public D4OrderPriceSum: number = 0;
    public D5OrderPriceSum: number = 0;
    public D6OrderPriceSum: number = 0;

    public D1MinOrderSum: number = 0;
    public D2MinOrderSum: number = 0;
    public D3MinOrderSum: number = 0;
    public D4MinOrderSum: number = 0;
    public D5MinOrderSum: number = 0;
    public D6MinOrderSum: number = 0;

    public D1MaxOrderSum: number = 0;
    public D2MaxOrderSum: number = 0;
    public D3MaxOrderSum: number = 0;
    public D4MaxOrderSum: number = 0;
    public D5MaxOrderSum: number = 0;
    public D6MaxOrderSum: number = 0;

    public D1SumComment: string;
    public D2SumComment: string;
    public D3SumComment: string;
    public D4SumComment: string;
    public D5SumComment: string;
    public D6SumComment: string;

    public D1RangeComment: string;
    public D2RangeComment: string;
    public D3RangeComment: string;
    public D4RangeComment: string;
    public D5RangeComment: string;
    public D6RangeComment: string;

    public IsDefault: boolean;
    public PartnerId?: number;
    public Partner: Partner;
}

