import * as React from 'react';
import Product from '../../../../business.entities/product';
import products from '../../stock/products/products';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    ChangeProductSelectionMode,
    ConfirmOrderProductSelection,
    ConfirmProductSelect,
    GetAdditionalServiceProducts,
    GetProducts,
    GetSubCategories,
    RemoveOrderProductClient,
    SelectCategory,
    SelectCurrentProduct,
    SelectOrderProduct,
    SelectSubCategory,
    SelectWizardStep,
    SetBasesForProduct,
    SetNextSubStep,
    SetPattern,
    SetSubCategoryRef,
    GetClientsForPartner,
    RemoveOrderProductServer,
    GetCitiesForAutocomplete,
    UpdateTempOrder,
    GetBasesForOrderProduct,
    CalculateSendDate,
    RefreshOrderProduct,
    GetOptionsForLexicon,
    GetProductBases
} from '../../../../actions/sales/wizard.actions';
import { Color } from '../../../../business.entities/color';
import {
    ColorSaleType,
    DesignPrintType,
    LaserType,
    LaserTypeWithOptions,
    LaserTypeWithImage,
    PrintProductionType,
    PrintType,
    ProductionType,
    SaleType,
    ColorSaleTypeWithOptions,
    SaleTypeWithOptions,
    DesignPrintTypeWithOptions,
    PrintTypeWithOptions,
    ProductionTypeWithOptions,
    PrintProductionTypeWithOptions
} from '../../../../helpers/adding.product.patterns';
import { connect } from 'react-redux';
import { default as InfoStep } from './info.step';
import { GetOrderProductStepComponent } from '../../../../helpers/enums/order.product.steps';
import { List } from 'linqts';
import { OrderProductStep as OrderProductStepEnum } from '../../../../helpers/enums/order.product.steps';
import { ProductTypeEnum } from '../../../../helpers/enums/product.type.enum';
import { WizardState } from '../../../../states/sales/wizard.state';
import { Order } from '../../../../business.entities/order';
import { TempOrder } from '../../../../business.entities/temp.order';
import * as API from '../../../../constants/api.constants'
import { ApplicationState } from '../../../../reducers';
import { LoginState } from '../../../../states/login.state';
import { ShowHoverBlock } from "../../../../actions/right.view.actions";

export interface IOrderProductStep {
    Wizard: WizardState;
    Authentication: LoginState;
    SelectCategory(category: any): void;
    SelectSubCategory(category: any): void;
    GetSubCategories(id, name): void;
    GetProducts(categroyId, subCategoryId): void;
    SetSubCategoryRef(ref): void;
    SelectCurrentProduct(product): void;
    ConfirmProductSelect(): void;
    GetAdditionalServiceProducts(): void;
    SetNextSubStep(stepEnum, component): void;
    SetPattern(pattern): void;
    CancelCurrentOrderProduct(): void;
    SetBasesForProduct(bases: Product[]): void;
    SelectOrderProduct(index: number): void;
    ChangeProductSelectionMode(isProductMode: boolean): void;
    ConfirmOrderProductSelection(orderProduct): void;
    RemoveOrderProductServer(orderProduct): void;
    SelectWizardStep(component: React.ComponentClass, stepNumber: number);
    GetClientsForPartner(search: string);
    GetCitiesForAutocomplete(search: string);
    UpdateTempOrder(order: Order);
    GetBasesForOrderProduct(categoryId: number, subcategoryId: number);
    CalculateSendDate(orderType);
    RefreshOrderProduct(netid, index);
    GetOptionsForLexicon(netId);
    GetProductBases(lexiconId: number, categoryId: number, subcategoryId: number)
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        Wizard: state.sales.wizard,
        Authentication: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectCategory,
    SelectSubCategory,
    GetSubCategories,
    GetProducts,
    SetSubCategoryRef,
    SelectCurrentProduct,
    ConfirmProductSelect,
    GetAdditionalServiceProducts,
    SetNextSubStep,
    SetPattern,
    CancelCurrentOrderProduct,
    SetBasesForProduct,
    SelectOrderProduct,
    ChangeProductSelectionMode,
    ConfirmOrderProductSelection,
    RemoveOrderProductServer,
    SelectWizardStep,
    GetClientsForPartner,
    GetCitiesForAutocomplete,
    UpdateTempOrder,
    GetBasesForOrderProduct,
    CalculateSendDate,
    RefreshOrderProduct,
    ShowHoverBlock,
    GetOptionsForLexicon,
    GetProductBases
}, dispatch);

const OrderProductStep = (props: IOrderProductStep) => {

    document.onkeydown = (e) => {
        if (!props.Wizard.isFinishMenuOpen) {
            if ((event as any).ctrlKey && (event as any).keyCode == 70) {
                event.preventDefault();
            }

            ProductKeyBoardLogic();

            CancelLogic();
        }
    }

    const ProductKeyBoardLogic = () => {
        if (!props.Wizard.orderProductSubStep) {

            if ((event as any).ctrlKey && (event as any).keyCode == 70) {
                event.preventDefault();
                props.Wizard.categorySelectRef.current.focus();
            }
            if (props.Wizard.categorySelectRef.current
                && props.Wizard.subcategorySelectRef.current)
                if (!props.Wizard.categorySelectRef.current.state.menuIsOpen
                    && !props.Wizard.subcategorySelectRef.current.state.menuIsOpen
                    && props.Wizard.productList.length > 0) {

                    if ((event as any).keyCode == 38) {
                        event.preventDefault();

                        props.Wizard.categorySelectRef.current.blur();
                        props.Wizard.subcategorySelectRef.current.blur();

                        if (!props.Wizard.orderProductSubStep) {
                            props.Wizard.productSelectionMode ?
                                OnSelectTopProduct() :
                                OnSelectTopOrderProduct();
                        }
                    }

                    if ((event as any).keyCode == 40) {
                        event.preventDefault();

                        props.Wizard.categorySelectRef.current.blur();
                        props.Wizard.subcategorySelectRef.current.blur();

                        if (!props.Wizard.orderProductSubStep) {
                            props.Wizard.productSelectionMode ?
                                OnSelectBottomProduct() :
                                OnSelectBottomOrderProduct();
                        }
                    }

                    if (props.Wizard.order.OrderProducts.length > 0) {
                        if ((event as any).keyCode === 37) {
                            event.preventDefault();
                            props.ChangeProductSelectionMode(true);
                        }

                        if ((event as any).keyCode === 39) {
                            event.preventDefault();
                            props.ChangeProductSelectionMode(false);
                        }

                        if ((event as any).keyCode === 46 && !props.Wizard.serverActionInProcess) {
                            event.preventDefault();
                            !props.Wizard.productSelectionMode &&
                                props.RemoveOrderProductServer(props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex]);

                            if (props.Wizard.order.OrderProducts.length > 1) {
                                let startIndex = 0;

                                if (props.Wizard.orderProductIndex !== 0) {
                                    startIndex = props.Wizard.order.OrderProducts.length - 2;
                                }

                                props.SelectOrderProduct(startIndex);
                            }

                            if (props.Wizard.order.OrderProducts.length === 1) {
                                props.SelectOrderProduct(0);
                                props.ChangeProductSelectionMode(true);
                            }
                        }
                    }

                    if ((event as any).keyCode == 13 && props.Wizard.currentProduct) {
                        event.preventDefault();

                        if (!props.Wizard.orderProductSubStep) {
                            props.Wizard.productSelectionMode ?
                                OnProductSelectConfirm() :
                                OnConfirmSelectOrderProduct();
                        }
                    }
                }

            if ((event as any).ctrlKey
                && (event as any).keyCode == 50) {
                event.preventDefault();

                if (!props.Wizard.categorySelectRef.current.state.menuIsOpen
                    && !props.Wizard.subcategorySelectRef.current.state.menuIsOpen) {

                    props.SelectWizardStep(InfoStep, 2);
                    props.GetCitiesForAutocomplete('а');

                    if (props.Wizard.clients.length === 0)
                        props.GetClientsForPartner("");

                    if ((props.Wizard.order as Order).OrderType > 0)
                        props.CalculateSendDate((props.Wizard.order as Order).OrderType);
                }
            }
        }
    }

    const CancelLogic = () => {
        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }
    }

    const OnSelectTopOrderProduct = () => {
        let startIndex = props.Wizard.orderProductIndex ? props.Wizard.orderProductIndex : 0;

        startIndex != 0 ? startIndex-- : startIndex = props.Wizard.order.OrderProducts.length - 1;

        props.SelectOrderProduct(startIndex);
    }

    const OnSelectBottomOrderProduct = () => {
        let startIndex = props.Wizard.orderProductIndex ? props.Wizard.orderProductIndex : 0

        startIndex != props.Wizard.order.OrderProducts.length - 1 ? startIndex++ : startIndex = 0;

        props.SelectOrderProduct(startIndex);
    }

    const OnSelectTopProduct = () => {
        let startIndex = 0;

        if (props.Wizard.currentProduct) {
            startIndex = props.Wizard.productList.indexOf(props.Wizard.currentProduct);
            startIndex != 0 ? startIndex-- : startIndex = props.Wizard.productList.length - 1;
        }

        OnSelectProductCommand(startIndex);
    }

    const OnSelectBottomProduct = () => {
        let startIndex = 0;

        if (props.Wizard.currentProduct) {
            startIndex = props.Wizard.productList.indexOf(props.Wizard.currentProduct);
            startIndex != props.Wizard.productList.length - 1 ? startIndex++ : startIndex = 0;
        }

        OnSelectProductCommand(startIndex);
    }

    const OnSelectProductCommand = (index: number) => {
        props.Wizard.productList && props.SelectCurrentProduct(props.Wizard.productList[index]);
        document.getElementById(props.Wizard.productList[index].Id.toString()).focus();
    }

    const OnSelectProductConfirmByMouse = async (index) => {

        props.SelectCurrentProduct(props.Wizard.productList[index]);

        if (props.Wizard.productList[index]) {
            const curentProduct = props.Wizard.productList[index];
            if (!curentProduct.Lexicon.CanBeBase){
                await props.SetBasesForProduct([]);
                await props.GetProductBases(curentProduct.LexiconId, curentProduct.CategoryId, curentProduct.SubCategoryId);
            }
            props.ConfirmProductSelect();
            props.GetAdditionalServiceProducts();
            const pattern = GetPatternForCurrentProduct(props.Wizard.productList[index]);


            props.SetPattern(pattern);

            if (pattern[0] === OrderProductStepEnum.Price) {
                if (!props.Wizard.productList[index].Lexicon.HasColors) {

                    let similarOrderProduct = null;
                    if (props.Wizard.productList[index].Lexicon.ProductTypeId === ProductTypeEnum.Sale)
                        similarOrderProduct = new List((props.Wizard.order as Order).OrderProducts)
                            .Where(c => c.ProductId === props.Wizard.productList[index].Id)
                            .FirstOrDefault();

                    //select similar product...
                    if (similarOrderProduct)
                        props.ConfirmOrderProductSelection(similarOrderProduct);
                }
            }

            props.SetNextSubStep(pattern[0], GetOrderProductStepComponent(pattern[0]));
        }
    }

    const OnCategorySelectCommand = (value: any) => {
        props.SelectCategory(value);
        props.GetSubCategories(value ? value.value : null, '');
        props.Wizard.subcategorySelectRef.current.focus();
    }

    const OnSubCategorySelectCommand = (value: any) => {
        props.SelectSubCategory(value);
        props.GetProducts(props.Wizard.selectedCategory ? props.Wizard.selectedCategory.value : null, value.value);
        props.Wizard.subcategorySelectRef.current.blur();
    }

    const OnProductSelectConfirm = () => {
        if (props.Wizard.currentProduct) {
            props.ConfirmProductSelect();
            props.GetAdditionalServiceProducts();

            const pattern = GetPatternForCurrentProduct(props.Wizard.currentProduct);

            if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
                const baseProducts = new List(props.Wizard.productList)
                    .Where(c => c.Lexicon.CanBeBase && c.Lexicon.ProductTypeId === 1)
                    .ToArray();

                props.SetBasesForProduct(baseProducts);
            }

            props.SetPattern(pattern);

            if (pattern[0] === OrderProductStepEnum.Price) {
                if (!props.Wizard.currentProduct.Lexicon.HasColors) {

                    let similarOrderProduct = null;
                    if (props.Wizard.currentProduct.Lexicon.ProductTypeId === ProductTypeEnum.Sale)
                        similarOrderProduct = new List((props.Wizard.order as Order).OrderProducts)
                            .Where(c => c.ProductId === props.Wizard.currentProduct.Id)
                            .FirstOrDefault();

                    //select similar product...
                    if (similarOrderProduct)
                        props.ConfirmOrderProductSelection(similarOrderProduct);
                }
            }

            props.SetNextSubStep(pattern[0], GetOrderProductStepComponent(pattern[0]));
        }
    }

    const OnConfirmSelectOrderProduct = async () => {
        props.ConfirmOrderProductSelection(props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex]);

        const pattern = GetPatternForCurrentProduct(props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex].Product);

        if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
            await props.SetBasesForProduct([]);
            await props.GetProductBases(
                props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex].Product.LexiconId,
                props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex].Product.CategoryId,
                props.Wizard.order.OrderProducts[props.Wizard.orderProductIndex].Product.SubCategoryId
            )
        }

        props.SetPattern(pattern);

        props.SetNextSubStep(pattern[0], GetOrderProductStepComponent(pattern[0]));
    }

    const OnConfirmSelectOrderProductByMouse = async (orderProduct) => {
        const index = props.Wizard.order.OrderProducts.indexOf(orderProduct);

        props.SelectOrderProduct(index);

        props.ConfirmOrderProductSelection(orderProduct);

        const pattern = GetPatternForCurrentProduct(orderProduct.Product);

        if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
            await props.SetBasesForProduct([]);
            await props.GetProductBases(
                orderProduct.Product.LexiconId,
                orderProduct.Product.CategoryId,
                orderProduct.Product.SubCategoryId
            )
        }

        props.SetPattern(pattern);

        props.SetNextSubStep(pattern[0], GetOrderProductStepComponent(pattern[0]));
    }

    const GetPatternForCurrentProduct = (product) => {
        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Sale) {
            if (product.Lexicon.HasColors || product.Lexicon.HasOptions)
                if (product.Lexicon.HasAdditionalOptions)
                    return ColorSaleTypeWithOptions;
                else
                    return ColorSaleType;

            if (!product.Lexicon.HasColors)
                if (product.Lexicon.HasAdditionalOptions)
                    return SaleTypeWithOptions;
                else
                    return SaleType;
        }

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Print) {
            if (product.Lexicon.HasDesigns)
                if (product.Lexicon.HasAdditionalOptions)
                    return DesignPrintTypeWithOptions;
                else
                    return DesignPrintType;

            if (product.Lexicon.CanHaveUserImg)
                if (product.Lexicon.HasAdditionalOptions)
                    return PrintTypeWithOptions;
                else
                    return PrintType;
        }

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Production)
            if (product.Lexicon.HasAdditionalOptions)
                return ProductionTypeWithOptions;
            else
                return ProductionType;

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction)
            if (product.Lexicon.HasAdditionalOptions)
                return PrintProductionTypeWithOptions;
            else
                return PrintProductionType;

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Laser){
            if(product.Lexicon.CanHaveUserImg)
                return LaserTypeWithImage;
            else
                return LaserTypeWithOptions;
        }
    }

    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return false;

        return true;
    }

    const OnRemoveOrderProductCommand = (orderProduct) => {
        !props.Wizard.serverActionInProcess &&
            props.RemoveOrderProductServer(orderProduct);
    }

    const OnGetSubCategoriesAutocomplete = (value) => {
        props.GetSubCategories(props.Wizard.selectedCategory ? props.Wizard.selectedCategory.value : null, value);
    }

    const OnOpenConstructorCommand = (orpderProduct) => {
        window.open(`${API.PRINT_URL}${orpderProduct.Product.ProductCode}/${orpderProduct.Id}/true/${props.Authentication.UserNetUid}/${props.Wizard.order.NetUid}`, "_self");
    }

    const OnRefreshProductCommand = (netId, index) => {
        props.RefreshOrderProduct(netId, index);
    }

    return (
        <div className="orderProductStep__container">
            <div className="orderProductStepHeader__container">
                <div className="controlSelect">
                    <Select
                        ref={props.Wizard.categorySelectRef}
                        name="category"
                        isClearable
                        clearValue={() => { OnCategorySelectCommand(null) }}
                        openMenuOnFocus={true}
                        options={props.Wizard.categoryList}
                        onChange={(value) => OnCategorySelectCommand(value)}
                        value={props.Wizard.selectedCategory}
                        placeholder="Бренд"
                    />
                </div>

                <div className="controlSelect">
                    <Select
                        ref={props.Wizard.subcategorySelectRef}
                        openMenuOnFocus={true}
                        name="subcategory"
                        options={props.Wizard.subCategoryList}
                        onChange={(value) => OnSubCategorySelectCommand(value)}
                        onInputChange={OnGetSubCategoriesAutocomplete}
                        value={props.Wizard.selectedSubCategory}
                        placeholder="Модель"
                    />
                </div>
            </div>

            <div className="orderProductStepContent__container">
                <div className="productList__container">
                    {props.Wizard.productList && props.Wizard.productList.map((product, index) =>
                        <div className={`productList__item ${(props.Wizard.productSelectionMode && props.Wizard.currentProduct.Id === product.Id) ? 'isSelected' : ''}`}
                            key={index}
                            onClick={() => OnSelectProductConfirmByMouse(index)} >

                            <input type="text" className='tabIndex_hideControl' id={`${product.Id}`} tabIndex={-1} />

                            <div className="productItem__information">
                                <div className="code">
                                    <div className="label">{product.PartnerProductCode}</div>
                                </div>
                                <div className="name">
                                    <div className="nameWrapper">
                                        <div className="officialPartnerName">
                                            {product.Lexicon.OfficialPartnerName}
                                        </div>
                                    </div>
                                </div>
                                <div className="price">
                                    <div className="label">{product.PartnerPrice}</div>
                                </div>
                            </div>
                            <div className="productItem__colors">
                                {product.Lexicon.HasColors && product.ProductColors && product.ProductColors.map((productColor, colorIndex) =>
                                    <div className="colorItem" key={`${index}-${colorIndex}`}>
                                        <div className={`dotStyle  ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`} style={{ backgroundColor: productColor.Color.Code }} >
                                            <div className="hide">
                                                {productColor.Color.Name}
                                            </div>
                                            <div className="qty">
                                                {IsProduction(product) ? productColor.Quantity : null}
                                            </div>
                                        </div>


                                    </div>
                                )}

                                {product.Lexicon.HasOptions && product.ProductColors && product.ProductColors.map((productColor, colorIndex) =>
                                    <div className="colorItem" key={`${index}-${colorIndex}`}>
                                        <div className={`dotStyle imgThumb__WRAPPER `} style={{ backgroundImage: `url(${API.CRM_URL + '/' + productColor.Color.Thumb})` }}
                                            onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(productColor.Color) }}
                                            onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                        >
                                            <div className="hide">
                                                {productColor.Color.Name}
                                            </div>
                                            <div className="qty">
                                                {productColor.Quantity}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>

                <div className="orderProductsContent__container">
                    {(props.Wizard.order && props.Wizard.order.OrderProducts.length > 0) && props.Wizard.order.OrderProducts.map((orderProduct, index) =>
                        <div className={`productList__item productListQTY__item ${(index === props.Wizard.orderProductIndex && !props.Wizard.productSelectionMode) ? 'isSelected' : ''} `}
                            key={index}>

                            {orderProduct.ProductType == ProductTypeEnum.Print || orderProduct.ProductType == ProductTypeEnum.Laser ?
                                <>
                                    <div className="goToConstructor" onClick={() => OnOpenConstructorCommand(orderProduct)} />
                                    {/* <div className="goToConstructorRef" onClick={() => OnRefreshProductCommand(orderProduct.NetUid, index)} /> */}
                                </>
                                : null}

                            <div className={`orderProduct__modalHeader ${orderProduct.BaseProduct ? 'ddd' : ''}`}>
                                <div className="brandContainer" onClick={() => OnConfirmSelectOrderProductByMouse(orderProduct)}>
                                    <div className="qtyWContainer ">
                                        <div className="value">{orderProduct.Quantity}</div>
                                        <div className="label">Кол-во</div>
                                    </div>
                                    <div className="brand">{orderProduct.Product.Category.Name}</div>
                                    <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                    <div className="lexiconContainer">
                                        <div className="lexiconWrapper">
                                            <div className="lexiconItem">
                                                <div className="lexLabel">{'Наименование: '}</div>
                                                <div className="lexValue">
                                                    <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                </div>

                                                {orderProduct.Product.Lexicon.HasColors && orderProduct.Color &&
                                                    <div className="lexValueColor">
                                                        <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                        <div className="colorBgLabel">
                                                            ({orderProduct.Color.Name})
                                                        </div>
                                                    </div>
                                                }

                                                {orderProduct.Product.Lexicon.HasOptions && orderProduct.Color &&
                                                    <>
                                                        <div className="lexValueColor imgThumb__WRAPPER">
                                                            <div className="colorBg" style={{ backgroundImage: `url(${API.CRM_URL + '/' + orderProduct.Color.Thumb})` }}
                                                                onMouseEnter={(e) => { e.stopPropagation(); props.ShowHoverBlock(orderProduct.Color) }}
                                                                onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}
                                                            ></div>
                                                            <div className="colorBgLabel">
                                                                ({orderProduct.Color.Name})
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                            {orderProduct.BaseProduct &&
                                                <div className="lexiconItem">
                                                    <div className="lexLabel">{'Основа:'}</div>
                                                    <div className="lexValue">
                                                        <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                    </div>

                                                    {orderProduct.BaseColor &&
                                                        <div className="lexValueColor">
                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }} />
                                                            <div className="colorBgLabel">
                                                                ({orderProduct.BaseColor.Name})
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {!IsProduction(orderProduct.Product) ?
                                            <div className="lexiconItem">
                                                <div className="lexLabel">{'Производственный комментарий:'}</div>
                                                <div className="lexValue">
                                                    <span>{orderProduct.ProductionComment}</span>
                                                </div>
                                            </div> : null
                                        }

                                        <div className="lexiconItem ">
                                            <div className="lexLabel">Цена для партнера</div>
                                            <div className="lexValue pTotal__value">{orderProduct.PartnerTotal}</div>
                                        </div>
                                        <div className="lexiconItem ">
                                            <div className="lexLabel">Цена для клиента</div>
                                            <div className="lexValue pTotal__value">{orderProduct.Total}</div>
                                        </div>


                                    </div>

                                </div>
                                {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                    <div className="brandContainer ops__brandContainer">
                                        <div className="lexiconContainer">
                                            <div className="lexiconWrapper">
                                                <div className="lexiconItem">
                                                    <details open className="lexiconItem__DETAILS">
                                                        <summary>Доп опции</summary>
                                                        {
                                                            orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                <div className="lexiconItem"
                                                                onMouseEnter={(e) => {e.stopPropagation(); props.ShowHoverBlock(orderProductOption.AdditionalOption) }}
                                                                onMouseLeave={(e) => { e.stopPropagation(); props.ShowHoverBlock(null) }}>
                                                                    <div className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                    <div className="lexValue">
                                                                        <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </details>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                                }
                            </div>

                            <div className="removeOrderProduct__button" onClick={() =>
                                OnRemoveOrderProductCommand(orderProduct)} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderProductStep);
