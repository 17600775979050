export const GET_PROMOCODES_FILTERED: string = '[API] GET_PROMOCODES_FILTERED';
export interface IGetPromocodesFiltered { type: '[API] GET_PROMOCODES_FILTERED', payload: any }
export const GetPromocodesFiltered = (filter, page?, count?) =>
    <IGetPromocodesFiltered>{ type: GET_PROMOCODES_FILTERED, payload: { filter: filter, page: page, count: count } };

export const SET_PROMOCODES: string = 'SET_PROMOCODES';
export interface ISetPromocodes { type: 'SET_PROMOCODES', payload: any}
export const SetPromocodes = (value) =>
    <ISetPromocodes>{ type: SET_PROMOCODES, payload: value };

export const SELECT_PROMOCODE: string = 'SELECT_PROMOCODE';
export interface ISelectPromocode { type: 'SELECT_PROMOCODE', payload: any }
export const SelectPromocode = (value) =>
    <ISelectPromocode>{ type: SELECT_PROMOCODE, payload: value };

export const SUCCESS_GET_PROMOCODES: string = 'SUCCESS_GET_PROMOCODES';
export interface IGetPromocodesSuccess { type: 'SUCCESS_GET_PROMOCODES', payload: any }
export const GetPromocodesSuccess = (value: any) =>
    <IGetPromocodesSuccess>{ type: SUCCESS_GET_PROMOCODES, payload: value };

export const DELETE_PROMOCODES: string = 'DELETE_PROMOCODES';
export interface IDeletePromocodes { type: 'DELETE_PROMOCODES', payload: any }
export const DeletePromocodes = (value: any, filter: any, page?: any, count?: any) =>
    <IDeletePromocodes>{ type: DELETE_PROMOCODES, payload: {promocodes: value, filter: filter, page: page, count: count} };

export const SELECT_PROMOCODE_NAME_FILTER: string = 'SELECT_PROMOCODE_NAME_FILTER';
export interface ISelectPromocodeNameFilter { type: 'SELECT_PROMOCODE_NAME_FILTER', payload: any }
export const SelectPromocodeNameFilter = (value) => 
    <ISelectPromocodeNameFilter>{ type: SELECT_PROMOCODE_NAME_FILTER, payload: value };

export const SELECT_PROMOCODE_USED_DATE_FILTER: string = 'SELECT_PROMOCODE_USED_DATE_FILTER';
export interface ISelectPromocodeUsedDateFilter { type: 'SELECT_PROMOCODE_USED_DATE_FILTER', payload: any}
export const SelectPromocodeUsedDateFilter = (value) =>
    <ISelectPromocodeUsedDateFilter> { type: SELECT_PROMOCODE_USED_DATE_FILTER, payload: value };

export const UNSELECT_PROMOCODE_USED_DATE_FILTER: string = 'UNSELECT_PROMOCODE_USED_DATE_FILTER';
export interface IUnselectPromocodeUsedDateFilter { type: 'UNSELECT_PROMOCODE_USED_DATE_FILTER' }
export const UnselectPromocodeUsedDateFilter = (value) =>
    <IUnselectPromocodeUsedDateFilter> { type: UNSELECT_PROMOCODE_USED_DATE_FILTER }

export const SELECT_PROMOCODE_SHOP_ADDRESS_FILTER: string = 'SELECT_PROMOCODE_SHOP_ADDRESS_FILTER';
export interface ISelectPromocodeShopAddressFilter { type: 'SELECT_PROMOCODE_SHOP_ADDRESS_FILTER', payload: any}
export const SelectPromocodeShopAddressFilter = (value) =>
    <ISelectPromocodeShopAddressFilter> { type: SELECT_PROMOCODE_SHOP_ADDRESS_FILTER, payload: value };

export const SELECT_PROMOCODE_STATUS_FILTER: string = 'SELECT_PROMOCODE_STATUS_FILTER';
export interface ISelectPromocodeStatusFilter { type: 'SELECT_PROMOCODE_STATUS_FILTER', payload: any }
export const SelectPromocodeStatusFilter = (value) =>
    <ISelectPromocodeStatusFilter> { type: SELECT_PROMOCODE_STATUS_FILTER, payload: value };

export const SELECT_PROMOCODE_TYPE_FILTER: string = 'SELECT_PROMOCODE_TYPE_FILTER';
export interface ISelectPromocodeTypeFilter { type: 'SELECT_PROMOCODE_TYPE_FILTER', payload: any }
export const SelectPromocodeTypeFilter = (value) =>
    <ISelectPromocodeTypeFilter> { type: SELECT_PROMOCODE_TYPE_FILTER, payload: value };

export const SELECT_PROMOCODE_PRICE_FILTER: string = 'SELECT_PROMOCODE_PRICE_FILTER';
export interface ISelectPromocodePriceFilter { type: 'SELECT_PROMOCODE_PRICE_FILTER', payload: any }
export const SelectPromocodePriceFilter = (value) =>
    <ISelectPromocodePriceFilter> { type: SELECT_PROMOCODE_PRICE_FILTER, payload: value }

export const GET_PROMOCODE_NAMES_AUTOCOMPLETE: string = '[API] GET_PROMOCODE_NAMES_AUTOCOMPLETE';
export interface IGetPromocodeNamesAutocomplete { type: '[API] GET_PROMOCODE_NAMES_AUTOCOMPLETE', payload: any }
export const GetPromocodeNamesAutocomplete = (value) =>
    <IGetPromocodeNamesAutocomplete> { type: GET_PROMOCODE_NAMES_AUTOCOMPLETE, payload: value }

export const GET_PROMOCODE_STATUSES_AUTOCOMPLETE: string = '[API] GET_PROMOCODE_STATUSES_AUTOCOMPLETE';
export interface IGetPromocodeStatusesAutocomplete { type: '[API] GET_PROMOCODE_STATUSES_AUTOCOMPLETE', payload: any }
export const GetPromocodeStatusesAutocomplete = (value) =>
    <IGetPromocodeStatusesAutocomplete> { type: GET_PROMOCODE_STATUSES_AUTOCOMPLETE, payload: value }

export const GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE: string = '[API] GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE';
export interface IGetPromocodeShopAddressAutocomplete { type: '[API] GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE', payload: any }
export const GetPromocodeShopAddressAutocomplete = (value) =>
    <IGetPromocodeShopAddressAutocomplete> { type: GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE, payload: value }

export const GET_PROMOCODE_TYPE_AUTOCOMPLETE: string = '[API] GET_PROMOCODE_TYPE_AUTOCOMPLETE';
export interface IGetPromocodeTypeAutocomplete { type: '[API] GET_PROMOCODE_TYPE_AUTOCOMPLETE', payload: any }
export const GetPromocodeTypeAutocomplete = (value) =>
    <IGetPromocodeTypeAutocomplete> { type: GET_PROMOCODE_TYPE_AUTOCOMPLETE, payload: value }

export const GET_PROMOCODE_NAMES_AUTOCOMPLETE_SUCCESS: string = 'GET_PROMOCODE_NAMES_AUTOCOMPLETE_SUCCESS';
export interface IGetPromocodeNamesAutocompleteSuccess { type: 'GET_PROMOCODE_NAMES_AUTOCOMPLETE_SUCCESS', payload: any } 
export const GetPromocodeNamesAutocompleteSuccess = (value) =>
    <IGetPromocodeNamesAutocompleteSuccess> { type: GET_PROMOCODE_NAMES_AUTOCOMPLETE_SUCCESS, payload: value }

export const GET_PROMOCODE_STATUSES_AUTOCOMPLETE_SUCCESS: string = 'GET_PROMOCODE_STATUSES_AUTOCOMPLETE_SUCCESS';
export interface IGetPromocodeStatusesAutocompleteSuccess { type: 'GET_PROMOCODE_STATUSES_AUTOCOMPLETE_SUCCESS', payload: any }
export const GetPromocodeStatusesAutocompleteSuccess = (value) =>
    <IGetPromocodeStatusesAutocompleteSuccess> { type: GET_PROMOCODE_STATUSES_AUTOCOMPLETE_SUCCESS, payload: value }

export const GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE_SUCCESS: string = 'GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE_SUCCESS';
export interface IGetPromocodeShopAddressAutocompleteSuccess { type: 'GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE_SUCCESS', payload: any}
export const GetPromocodeShopAddressAutocompleteSuccess = (value) => 
    <IGetPromocodeShopAddressAutocompleteSuccess> { type: GET_PROMOCODE_SHOP_ADDRESS_AUTOCOMPLETE_SUCCESS, payload: value}

export const GET_PROMOCODE_TYPE_AUTOCOMPLETE_SUCCESS: string = 'GET_PROMOCODE_TYPE_AUTOCOMPLETE_SUCCESS';
export interface IGetPromocodeTypeAutocompleteSuccess { type: 'GET_PROMOCODE_TYPE_AUTOCOMPLETE_SUCCESS', payload: any }
export const GetPromocodeTypeAutocompleteSuccess = (value) =>
    <IGetPromocodeTypeAutocompleteSuccess> { type: GET_PROMOCODE_TYPE_AUTOCOMPLETE_SUCCESS, payload:value }

export const SELECT_FILE_WITH_PROMOCODES: string = 'SELECT_FILE_WITH_PROMOCODES';
export interface ISelectFileWithPromocodes { type: 'SELECT_FILE_WITH_PROMOCODES', payload: any } 
export const SelectFileWithPromocodes = (value) =>
    <ISelectFileWithPromocodes> { type: SELECT_FILE_WITH_PROMOCODES, payload: value }

export const ADD_FILE_WITH_PROMOCODES: string = 'ADD_FILE_WITH_PROMOCODES';
export interface IAddFileWithPromocodes { type: 'ADD_FILE_WITH_PROMOCODES', payload: any }
export const AddFileWithPromocodes = (value) =>
    <IAddFileWithPromocodes> { type: ADD_FILE_WITH_PROMOCODES, payload: value }

export const SAVE_FILE_WITH_PROMOCODES: string = '[API] SAVE_FILE_WITH_PROMOCODES';
export interface ISaveFileWithPromocodes { type: '[API] SAVE_FILE_WITH_PROMOCODES', payload: any }
export const SaveFileWithPromocodes = (value) =>
    <ISaveFileWithPromocodes> { type: SAVE_FILE_WITH_PROMOCODES, payload: value }

export const SELECT_FILE_WITH_PROMOCODES_NAME: string = 'SELECT_FILE_WITH_PROMOCODES_NAME';
export interface ISelectFileWithPromocodesName { type: 'SELECT_FILE_WITH_PROMOCODES_NAME', payload: any }
export const SelectFileWithPromocodesName = (value) =>
    <ISelectFileWithPromocodesName> { type: SELECT_FILE_WITH_PROMOCODES_NAME, payload: value}

export const EXPORT_PROMOCODES: string = '[API] EXPORT_PROMOCODES';
export interface IExportPromocodes { type: '[API] EXPORT_PROMOCODES', payload: any }
export const ExportPromocodes = (value) =>
    <IExportPromocodes> { type: EXPORT_PROMOCODES, payload: value }

export const EXPORT_PROMOCODES_SUCCESS: string = 'EXPORT_PROMOCODES_SUCCESS';
export interface IExportPromocodesSuccess { type: 'EXPORT_PROMOCODES_SUCCESS', payload: any }
export const ExportPromocodesSuccess = (value) =>
    <IExportPromocodesSuccess> { type: EXPORT_PROMOCODES_SUCCESS, payload: value }

export type PromocodeKnownActions =
    IGetPromocodesFiltered | ISetPromocodes | IGetPromocodesSuccess 
    | ISelectPromocodeNameFilter | ISelectPromocodeUsedDateFilter | ISelectPromocodeShopAddressFilter
    | ISelectPromocodeStatusFilter | ISelectPromocodeTypeFilter | ISelectPromocodePriceFilter
    | ISelectFileWithPromocodes | IAddFileWithPromocodes | IExportPromocodesSuccess 
    