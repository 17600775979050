import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../../reducers";

const Design = (props: any) => {

    return(
        <div>
            Design + comment?
        </div>
    )
}
export default connect((state: ApplicationState) => state.sales.wizard)(Design)