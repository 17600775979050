import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
    CalculateProductTotal,
    ChangeCurrentOrderProductPrice,
    CancelCurrentOrderProduct,
    SetNextSubStep
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { PrintProductionType } from '../../../../../../helpers/adding.product.patterns';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';
import { error } from 'react-notification-system-redux';
import { OrderProductStep } from '../../../../../../helpers/enums/order.product.steps';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ChangeCurrentOrderProductPrice,
    CalculateProductTotal
}, dispatch);

const Price = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    document.onkeydown = (event) => {
        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 39 || (event as any).keyCode === 13) {

            event.preventDefault();

            if (orderProductUpdate.currentStepEnum === OrderProductStep.Price) {
                if (!orderProductUpdate.currentOrderProduct.Price)
                    return;
            }

            console.log(orderProductUpdate.currentOrderProduct.Product.Wholesale ? 'WHOLESALE EXIST' : 'WHOLESALE NOT EXIST')
            props.CalculateProductTotal(orderProductUpdate.currentOrderProduct);
            OnGoToNextOrderCardStepCommand(props);
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnPriceCahngeCommand = (value: number) => {

        props.ChangeCurrentOrderProductPrice(value)
    }

    return (
        <div className="priceContainer__content">
            <div className="priceContainer__contentLabel">Цена</div>
            <div className="priceContainer__contentWrapper">
                <div className="priceFormControl">
                    <div className="priceFormControl__label">Партнёрская</div>
                    <div className="priceFormControl__value">
                        <div className="imitation_input">{orderProductUpdate.currentOrderProduct.PartnerPrice}</div>
                    </div>
                </div>

                <div className="priceFormControl">
                    <div className="priceFormControl__label">Клиентская</div>
                    <div className="priceFormControl__value clientPrice">
                        <input type="number"
                            value={(orderProductUpdate as any).currentOrderProduct.Price}
                            min={orderProductUpdate.currentOrderProduct.PartnerPrice}
                            onChange={event => OnPriceCahngeCommand((event as any).target.value)}
                            autoFocus={true}
                        />
                    </div>
                </div>

                <div className="dopDescription__container">
                    Поле для ввода цены отправки, заполняется в случае, когда необходимая цена отправки отличается от личной цены (сума наложеного платежа Вашему клиенту)
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Price)