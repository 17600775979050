import * as React from 'react';
import Select from 'react-select';
import { connect } from "react-redux";
import * as clientBaseActions from '../../../../actions/sales/client.base.actions';
import { Client } from '../../../../business.entities/client';
import { LoginState } from '../../../../states/login.state';
import { NavLink } from 'react-router-dom';
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, GetIdentityRoleName } from '../../../../helpers/identity.roles';
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import { checkPropTypes } from 'prop-types';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';

export interface IClientsBaseProps {
    clientBase: any;
    ChangePartnerValue: any;
    UnmountClientBaseComponent: any;
    Authorization: LoginState;

    GetClientsForSelectedPartner(filteredData: string): any;
    GetAutocompletePartnerResult(value: string): any;
    ResetCommand(): any;
}

export interface IClientsBaseState {
    page: number;
    filterIdValue: string;
    filterClientNameValue: string;
    filterClientPhoneValue: string;
    filterOrderCodeValue: string;
}

class ClientsBase extends React.Component<IClientsBaseProps, IClientsBaseState> {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            filterIdValue: "",
            filterClientNameValue: "",
            filterClientPhoneValue: "",
            filterOrderCodeValue: "",
        };

        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya)) {
            this.props.GetAutocompletePartnerResult("");
        } else {
            this.props.ChangePartnerValue({ value: this.props.Authorization.UserNetUid, label: this.props.Authorization.UserName });
            this.GetClients(1, { value: this.props.Authorization.UserNetUid, label: this.props.Authorization.UserName }, null, null, null, null);
        }

        this.onAutocompletePartnerInputChange = this.onAutocompletePartnerInputChange.bind(this);
        this.OnChangeFilterIdCommand = this.OnChangeFilterIdCommand.bind(this);
    }

    componentWillReceiveProps(nextProps: IClientsBaseProps) {
        let {
            selectedPartnerValue,
            filterChanged,
            fetchInProgress,
        } = nextProps.clientBase;

        if (filterChanged && !fetchInProgress) {
            this.setState({ page: 1 });
        }
    }

    componentWillUnmount() {
        this.props.UnmountClientBaseComponent();
    }

    private GetClients(
        page: number,
        PartnerNetId: any,
        id: number,
        clientName: string,
        clientPhone: string,
        orderCode: string
    ) {
        let filteredData = {
            Id: id,
            ClientName: clientName,
            ClientPhone: clientPhone,
            OrderCode: orderCode,
            PartnerNetId: PartnerNetId ? PartnerNetId.value : null,
            Page: page,
        };
        this.props.GetClientsForSelectedPartner(JSON.stringify(filteredData));
    }

    private onResetFilferCommand() {
        this.props.ResetCommand();

        this.setState({
            page: 1,
            filterIdValue: "",
            filterClientNameValue: "",
            filterClientPhoneValue: "",
            filterOrderCodeValue: "",
        });

        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner)) {
            this.props.ChangePartnerValue({ value: this.props.Authorization.UserNetUid, label: this.props.Authorization.UserName });
        }
    }

    private onAutocompletePartnerInputChange(value: string) {
        value && this.props.GetAutocompletePartnerResult(value);
    }

    private OnChangeFilterIdCommand(value: string) {
        this.setState({ page: 1, filterIdValue: value });
        let partnerNetId = this.props.clientBase.selectedPartnerValue;
        this.GetClients(
            1,
            partnerNetId,
            Number(value),
            this.state.filterClientNameValue || null,
            this.state.filterClientPhoneValue || null,
            this.state.filterOrderCodeValue || null
        );
    }

    private OnChangeFilterClientNameCommand(value: string) {
        this.setState({ page: 1, filterClientNameValue: value });
        let partnerNetId = this.props.clientBase.selectedPartnerValue;
        this.GetClients(
            1,
            partnerNetId,
            this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
            value,
            this.state.filterClientPhoneValue || null,
            this.state.filterOrderCodeValue || null
        );
    }

    private OnChangeFilterClientPhoneCommand(value: string) {
        this.setState({ page: 1, filterClientPhoneValue: value });
        let partnerNetId = this.props.clientBase.selectedPartnerValue;
        this.GetClients(
            1,
            partnerNetId,
            this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
            this.state.filterClientNameValue || null,
            value,
            this.state.filterOrderCodeValue || null
        );
    }
    private OnChangeFilterOrderCodeCommand(value: string) {
        this.setState({ page: 1, filterOrderCodeValue: value });
        let partnerNetId = this.props.clientBase.selectedPartnerValue;
        this.GetClients(
            1,
            partnerNetId,
            this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
            this.state.filterClientNameValue || null,
            this.state.filterClientPhoneValue || null,
            value
        );
    }

    private OnSelectProductCommand(client) {
        console.log(client)
    }


    public OnChangePageCommand(current, pageSize) {
        this.setState({ page: current });
        this.GetClients(
            current,
            this.props.clientBase.selectedPartnerValue,
            this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
            this.state.filterClientNameValue || null,
            this.state.filterClientPhoneValue || null,
            this.state.filterOrderCodeValue || null
        );
    }

    public OnSelectPartnerCommand(value) {
        this.props.ChangePartnerValue(value);
        this.setState({ page: 1, filterClientNameValue: '' });
        this.GetClients(
            1,
            value,
            null,
            this.state.filterClientNameValue || null,
            this.state.filterClientPhoneValue || null,
            this.state.filterOrderCodeValue || null
        );

        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private OnPrevPageCommand() {
        let { page } = this.state;

        if (page > 1) {
            page--;
            this.GetClients(
                page,
                this.props.clientBase.selectedPartnerValue,
                this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
                this.state.filterClientNameValue || null,
                this.state.filterClientPhoneValue || null,
                this.state.filterOrderCodeValue || null
            );
            this.setState({ page });
        }
    }

    private OnNextPageCommand() {
        let { page } = this.state;
        let { totalPages } = this.props.clientBase;
        if (totalPages >= page) {
            page++;
            this.GetClients(
                page,
                this.props.clientBase.selectedPartnerValue,
                this.state.filterIdValue ? Number(this.state.filterIdValue) : null,
                this.state.filterClientNameValue || null,
                this.state.filterClientPhoneValue || null,
                this.state.filterOrderCodeValue || null
            );
            this.setState({ page });
        }
    }

    render() {

        return (
            <div className="master_content_component rcPagination__component" >
                <LeftMenuMobileControl />
                <div className="left_menu_component clientBaseInfoContainer">
                    <div className="left_menu_SCROLL">
                        <PermissionChecker
                            Classes='defaultFormControl'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                            <div className="defaultFormControl__label">Партнеры</div>
                            <div className="selectWrapper__container notPadd">
                                < Select
                                    name="partners"
                                    onInputChange={this.onAutocompletePartnerInputChange}
                                    options={this.props.clientBase.optionPartnerList}
                                    onChange={(value) => this.OnSelectPartnerCommand(value)}
                                    value={this.props.clientBase.selectedPartnerValue}
                                    placeholder="Партнеры" />
                            </div>
                        </PermissionChecker>

                        {
                            this.props.clientBase.selectedPartnerValue &&
                            <div>
                                {/* <div className="defaultFormControl">
                                <div className="defaultFormControl__label">ID</div>
                                <div className="defaultFormControl__value notPadd">
                                    < input
                                        type="text"
                                        value={this.state.filterIdValue}
                                        onChange={(event) => this.OnChangeFilterIdCommand(event.target.value)}
                                        placeholder="ID" />
                                </div>
                            </div> */}
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Клиент</div>
                                    <div className="defaultFormControl__value notPadd">
                                        < input
                                            type="text"
                                            value={this.state.filterClientNameValue}
                                            onChange={(event) => this.OnChangeFilterClientNameCommand(event.target.value)}
                                            placeholder="Клиент" />
                                    </div>
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Телефон</div>
                                    <div className="defaultFormControl__value notPadd">
                                        < input
                                            type="text"
                                            value={this.state.filterClientPhoneValue}
                                            onChange={(event) => this.OnChangeFilterClientPhoneCommand(event.target.value)}
                                            placeholder="Телефон" />
                                    </div>
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Номер Заказа</div>
                                    <div className="defaultFormControl__value notPadd">
                                        < input
                                            type="text"
                                            value={this.state.filterOrderCodeValue}
                                            onChange={(event) => this.OnChangeFilterOrderCodeCommand(event.target.value)}
                                            placeholder="Номер Заказа" />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="defaultFormControl">
                            <div className="defaultFormButton" onClick={() => this.onResetFilferCommand()}>Сброс</div>
                        </div>
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">База Клиентов</span>
                        <span className="title_description">Поиск</span>
                    </div>
                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">
                                        <div className="grid_cell cellClientBase cellClientBase__id">
                                            <span className="cell_content">ID</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__client">
                                            <span className="cell_content">Клиент</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__phone">
                                            <span className="cell_content">Телефон</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__email">
                                            <span className="cell_content">E-Mail</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__rating">
                                            <span className="cell_content">Рейтинг</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__qty">
                                            <span className="cell_content">Кол-во заказов</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__total">
                                            <span className="cell_content">Сумма заказов</span>
                                        </div>
                                        <div className="grid_cell cellClientBase cellClientBase__lastNumber">
                                            <span className="cell_content">Номер последнего заказа</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid_scroll_content_component">
                                <div className="grid_wrapper_component">
                                    {
                                        this.props.clientBase.clients.length > 0 ?
                                            this.props.clientBase.clients.map((client: Client, index) => {
                                                return (
                                                    <div key={index} className="grid_row mMEDIA___clientsBase_ROW" onClick={(event) => { this.OnSelectProductCommand(client) }}>
                                                        <div className="grid_cell cellClientBase cellClientBase__id" data-tooltip="ID">
                                                            <span className="cell_content">{client.Id}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__client" data-tooltip="Клиент">
                                                            <span className="cell_content">{client.FullName}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__phone" data-tooltip="Телефон">
                                                            <span className="cell_content">{client.PhoneNumber}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__email" data-tooltip="E-Mail">
                                                            <span className="cell_content" data-tooltip={client.Email}>{client.Email}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__rating" data-tooltip="Рейтинг">
                                                            <span className="cell_content">{client.Rating}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__qty" data-tooltip="Кол-во заказов">
                                                            <span className="cell_content">{client.TotalOrdersCount}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__total" data-tooltip="Сумма заказов">
                                                            <span className="cell_content">{client.TotalOrdersAmount}</span>
                                                        </div>
                                                        <div className="grid_cell cellClientBase cellClientBase__lastNumber" data-tooltip="Номер последнего заказа">
                                                            <span className="cell_content">
                                                                {client.LastOrder ?
                                                                    <NavLink className="cell_content" to={`/sales/order-card/${client.LastOrder.NetUid}`}>
                                                                        {client.LastOrder.OrderCode}
                                                                    </NavLink>
                                                                    : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                )
                                            }) : null
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                    <Pagination
                        ref="paginatorRef"
                        locale={Locale}
                        defaultPageSize={20}
                        defaultCurrent={this.state.page}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.clientBase.totalPages}
                    />
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({ clientBase: (state as any).sales.clientBase, Authorization: (state as any).authentication }),
    clientBaseActions as any)
    (ClientsBase);

