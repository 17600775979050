import * as API from '../../../../../constants/api.constants';
import * as React from 'react';
import Select from 'react-select';
import {bindActionCreators} from 'redux';
import {
    ChangePrintComment,
    ChangeUserImageComment,
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    RemoveFileFromOrderProduct,
} from '../../../../../actions/sales/order.card.actions';
import {connect} from 'react-redux';
import {List} from 'linqts';
import {OrderCardState} from '../../../../../states/sales/order.card.state';
import {OrderProduct} from '../../../../../business.entities/order.product';
import {default as OrderProductCommon} from '../../../../shared/right.view/order.product.common';
import {OrderProductFileTypeEnum} from '../../../../../helpers/enums/order.productfile.type.enum';
import {GetIdentityRoleName, AllIdentityRolesExept} from '../../../../../helpers/identity.roles';
import PermissionChecker from '../../../../shared/permission.checker.component';
import {IdentityRoleEnum} from '../../../../../helpers/identity.roles';
import * as moment from 'moment';
import {Loader} from '../../../../shared/loader';
import {OrderStatusEnum} from '../../../../../helpers/enums/order.status.enum';
import {GetLaserStatusName, LaserStatusEnum} from '../../../../../helpers/enums/laser.status.enum';
import {ProductLaserStatus} from '../../../../../business.entities/product.laser.status';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    UploadFilesForOrderProduct,
    RemoveFileFromOrderProduct,
}, dispatch);

const LaserInfoRv = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProduct: OrderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === orderCard.selectedOrderProductId);

    const IsActionAllowed = () => {
        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Lasercut)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderCard.order.AssemlingStatus)
                return true;

            if (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
                return true;

            if (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
                return true;
        }

        return false;
    }

    const OnSelectLaserStatusCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        let newStatus: ProductLaserStatus = new ProductLaserStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = orderProduct.Id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductLaserStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnFileUpload = (event, type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        const files = Array.from(event.target.files)
        let formData = new FormData();
        formData.append('files', files[0] as any, (files[0] as any).name);
        props.UploadFilesForOrderProduct(formData, orderProduct.NetUid, orderCard.order.NetUid, type);
    }

    const ChangeUserImageComment = (value, productId) => {
        const orderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = orderCard.order.OrderProducts.indexOf(orderProduct);

        props.ChangeUserImageComment(value, index, productId);
    }

    const ChangePrintComment = (value, productId) => {
        const orderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = orderCard.order.OrderProducts.indexOf(orderProduct);

        props.ChangePrintComment(value, index, productId);
    }

    const OnRemoveFileCommand = (type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print))
            return;

        props.RemoveFileFromOrderProduct(id, type);
    }

    const GetLaserStatusesForPartner = (status) => {
        if (status === LaserStatusEnum.Producted)
            return GetLaserStatusName(LaserStatusEnum.Producted);
        else
            return '';
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    return (
        <div className="printRVContainer">
            <div className="currentOrderProducts__item">
                <OrderProductCommon product={orderProduct}/>

                {orderProduct.Product.Lexicon.CanHaveUserImg ?
                    <div className="">
                        <PermissionChecker
                            Classes='pictureCol'
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                            <div className="previewLabelContainer">Превью</div>
                            <div className="pictureContainer"
                                 style={{backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserPrewiev})`}}>
                                {!orderProduct.UserPrewiev ?
                                    <label className="onMiniFileUploadControl mTop">
                                        <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Preview, orderProduct.Id)}/>
                                    </label> :
                                    <>
                                        <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Preview, orderProduct.NetUid)}/>
                                        <a className="" href={`${API.SERVER_URL}${orderProduct.UserPrewiev}`} download/>
                                    </>
                                }
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='pictureCol'
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                            <div className="previewLabelContainer">Картинка/Архив</div>
                            <div className={`pictureContainer ${IsZipFile(orderProduct.UserImage) ? 'zipp' : ''}`}
                                 style={{backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserImage})`}}>
                                {!orderProduct.UserImage ?
                                    <label className="onMiniFileUploadControl mTop">
                                        <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.UserImage, orderProduct.Id)}/>
                                    </label> :
                                    <>
                                        <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.UserImage, orderProduct.NetUid)}/>
                                        <a className="" href={`${API.SERVER_URL}${orderProduct.UserImage}`} download/>
                                    </>
                                }
                            </div>
                        </PermissionChecker>
                    </div> : null
                }
                
                {orderProduct.ProductLaserStatus.length > 0 ?
                    <div>
                        <PermissionChecker
                            Classes='defaultFormControl'
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Lasercut]}>
                            <div className="defaultFormControl__label">Статус лазера</div>

                            {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                <div className="defaultFormControl__value">
                                    <div className="imitation_input">
                                        {orderProduct.ProductLaserStatus.length > 0 ?
                                            GetLaserStatusesForPartner(orderProduct.ProductLaserStatus[0].Status) :
                                            ''}
                                    </div>
                                </div>
                                :
                                <div className="selectWrapper__container">
                                    <Select
                                        name="printStatus"
                                        isDisabled={orderProduct.ProductLaserStatus[0].Status === LaserStatusEnum.WaitingForAssembling || (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status !== OrderStatusEnum.Confirmed)}
                                        openMenuOnFocus={true}
                                        options={orderCard.laserStatusList}
                                        onChange={(value) => OnSelectLaserStatusCommand(value, orderProduct.Id)}
                                        value={orderProduct.SelectedLaserStatus}
                                        placeholder="Статус лазера"/>
                                </div>
                            }
                        </PermissionChecker>
                    </div>
                    : null
                }

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishLaserStatus ?
                        <>
                            <div className="defaultFormControl__label">Дата изготовления</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {moment(orderProduct.FinishLaserStatus.Created).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishLaserStatus ?
                        <>
                            <div className="defaultFormControl__label">Изготовил</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {orderProduct.FinishLaserStatus.Partner.ContactName}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                    <div className="defaultFormControl__label">Комментарий заказчика</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderProduct.UserImageComment ? orderProduct.UserImageComment : ''}
                                  onChange={(event) => ChangeUserImageComment(event.target.value, orderProduct.Id)}/>

                        {
                            orderCard.fetchInProgress ?
                                <Loader/> : null
                        }
                    </div>
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Lasercut]}
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    <div className="defaultFormControl__label">Комментарий изготовителя</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderProduct.PrintComment ? orderProduct.PrintComment : ''}
                                  onChange={(event) => ChangePrintComment(event.target.value, orderProduct.Id)}/>

                        {
                            orderCard.fetchInProgress ?
                                <Loader/> : null
                        }
                    </div>
                </PermissionChecker>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LaserInfoRv);