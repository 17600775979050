import * as API from '../constants/api.constants';
import * as moment from 'moment';
import { deleteCookie, setCookie } from '../helpers/cookies';
import {
    Logout,
} from '../actions/login.actions';
import { push } from 'react-router-redux';
import { TokenHelper } from '../helpers/jwt.parser';

export default function refreshTockenMiddleware(store) {
    return dispatch => action => {
        console.log(action);
        let current = moment().toDate();
        let expires = new Date(store.getState().authentication.Exp);
        
        if (action.type)
            if (action.type.indexOf('[API]') !== -1 && store.getState().authentication.RefreshToken)
                if (current > expires) {
                    if (!store.getState().authentication.RefreshPromise)
                        return refreshToken(store)
                            .then(() => dispatch(action),
                                (err) => {
                                    deleteCookie('token');
                                    deleteCookie('refresh');
                                    dispatch(Logout());
                                    return dispatch(push('/auth/login'));
                                });
                    else
                        return store.getState().authentication.RefreshPromise
                            .then(() => dispatch(action),
                                (err) => {
                                    deleteCookie('token');
                                    deleteCookie('refresh');
                                    dispatch(Logout());
                                    return dispatch(push('/auth/login'));
                                });
                }

        return dispatch(action);
    };
}

async function refreshToken(store) {
    console.log({ type: "REFRESH_TOKEN" });

    let promise = fetch(`${API.REFRESH}`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(store.getState().authentication.RefreshToken)
    }).then((response) =>
        response.json()
    ).then(data => {
        const tockenP = TokenHelper.parseJwt(data.Body.AccessToken);
        setCookie("token", data.Body.AccessToken, tockenP.exp * 1000);
        setCookie("refresh", data.Body.RefreshToken, tockenP.exp * 1000);

        store.getState().authentication.RefreshPromise = null;
        store.getState().authentication.AuthenticationToken = data.Body.AccessToken;
        store.getState().authentication.RefreshToken = data.Body.RefreshToken;
        store.getState().authentication.Exp = tockenP.exp * 1000;
        store.getState().authentication.isAuthenticated = true;

        return Promise.resolve();
    }).catch((res) => {
        return Promise.reject(res);
    });

    store.getState().authentication.RefreshPromise = promise;

    return promise;
}
