import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { PartnerPrice } from "../../../../business.entities/partner.price";
import { PriceLevel } from "../../../../helpers/enums/price.level.enum";

const CurrentBalanceDetails = (props) => {

    const SelectedPriceLevelIndex = () => {
        if (props.PriceLevel === PriceLevel.S0)
            return 0;

        if (props.PriceLevel === PriceLevel.S1)
            return 1;

        if (props.PriceLevel === PriceLevel.S2)
            return 2;

        if (props.PriceLevel === PriceLevel.S3)
            return 3;

        if (props.PriceLevel === PriceLevel.S4)
            return 4;

        if (props.PriceLevel === PriceLevel.S5)
            return 5;

        if (props.PriceLevel === PriceLevel.S6)
            return 6;

        if (props.PriceLevel === PriceLevel.P1){
            return 7;
        }
    }

    return (
        <div className="currentBalanceDetails__container grid_component">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell">
                                <span className="cell_content">Наименование</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() === 0 ? 'selected' : ''}`}>
                                <span className="cell_content">S0 (D0), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 1 ? 'selected' : ''}`}>
                                <span className="cell_content">S1 (D1), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 2 ? 'selected' : ''}`}>
                                <span className="cell_content">S2 (D2), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 3 ? 'selected' : ''}`}>
                                <span className="cell_content">S3 (D3), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 4 ? 'selected' : ''}`}>
                                <span className="cell_content">S4 (D4), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 5 ? 'selected' : ''}`}>
                                <span className="cell_content">S5 (D5), %</span>
                            </div>

                            <div className={`grid_cell s_cell ${SelectedPriceLevelIndex() == 6 ? 'selected' : ''}`}>
                                <span className="cell_content">S6 (D6), %</span>
                            </div>

                            <div className={`grid_cell s_cell`}>
                                <span className="cell_content">Опт, %</span>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    props.PartnerPrices && props.PartnerPrices.length > 0 ?
                        <div className="grid_scroll_content_component">
                            <div className="grid_wrapper_component cBalanceDetails__GRID_WRAP">
                                {
                                    props.PartnerPrices.map((partnerPrice: PartnerPrice, index: number) => {
                                        return (
                                            <div key={index} className="grid_row mMEDIA___curentBalanceDetails_ROW">
                                                <div className="grid_cell cBD___CELL cBD_OfficialPartnerName__CELL" data-tooltip="Наименование">
                                                    <span className="cell_content">{partnerPrice.Lexicon.OfficialPartnerName}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() === 0 ? 'selected' : ''}`} data-tooltip="S0 (D0), %">
                                                    <span className="cell_content">0</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 1 ? 'selected' : ''}`} data-tooltip="S1 (D1), %">
                                                    <span className="cell_content">{partnerPrice.S1Percents}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 2 ? 'selected' : ''}`} data-tooltip="S2 (D2), %">
                                                    <span className="cell_content">{partnerPrice.S2Percents}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 3 ? 'selected' : ''}`} data-tooltip="S3 (D3), %">
                                                    <span className="cell_content">{partnerPrice.S3Percents}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 4 ? 'selected' : ''}`} data-tooltip="S4 (D4), %">
                                                    <span className="cell_content">{partnerPrice.S4Percents}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 5 ? 'selected' : ''}`} data-tooltip="S5 (D5), %">
                                                    <span className="cell_content">{partnerPrice.S5Percents}</span>
                                                </div>

                                                <div className={`grid_cell cBD___CELL s_cell ${SelectedPriceLevelIndex() == 6 ? 'selected' : ''}`} data-tooltip="S6 (D6), %">
                                                    <span className="cell_content">{partnerPrice.S6Percents}</span>
                                                </div>

                                                <div className="grid_cell cBD___CELL s_cell" data-tooltip="Опт, %">
                                                    <span className="cell_content">{partnerPrice.S7Percents}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </div>
    );
}
export default connect(state => ({ PartnerPrices: (state as any).cabinet.userInfo.PartnerPrices, PriceLevel: (state as any).cabinet.userInfo.userInfo.PriceLevel }))(CurrentBalanceDetails);
