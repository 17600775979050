import * as API from '../../../../constants/api.constants';
import * as categoryActionCreators from '../../../../actions/category.actions';
import * as lexiconActionCreators from '../../../../actions/stock/lexicon.actions';
import * as productActions from '../../../../actions/stock/product.actions';
import * as React from 'react';
import * as rightViewActionCreators from '../../../../actions/right.view.actions';
import * as stockActionCreators from '../../../../actions/stock/stock.actions';
import { actions } from 'react-redux-form';
import { bindActionCreators } from 'redux';
import { Category } from '../../../../business.entities/category';
import { connect } from 'react-redux';
import { default as ProductForm } from './product.form';
import { LoginState } from '../../../../states/login.state';
import { Observable } from 'rxjs/Observable';
import { Product } from '../../../../business.entities/product';
import { RightView } from '../../../shared/right.view/right.view';
import { RightViewState } from '../../../../states/right.view.state';
import { SelectModel } from '../../../../helpers/select.model';
import { Loader } from '../../../shared/loader';
import { default as BulkPriceUpdateForm } from "./price.bulk.update.form";
import storeProvider from "../../../../helpers/store.provider";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../helpers/identity.roles';
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import RCSelect from 'rc-select';
import Select from 'react-select';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';

namespace ProductsComponent {
    export interface IProps {
        GetProductFilteredPaginated: any;
        SelectProduct: any;
        DeselectProduct: any;
        ChangeForm: any;
        ResetForm: any;
        SelectProductColor: any;
        GetAutocompleteResult: any;
        GetLexioconSelectList: any;
        GetCategorySelectList: any;
        ChangeLexiconValue: any;
        ChangeCategoryValue: any;
        ChangeSubcategoryValue: any;
        ResetFilter: any;
        OpenRv: (rvData: RightViewState) => any;
        CloseRv: () => any;
        ProductUnmount: any;
        Authentication: LoginState;
        product: any;
        ChangePriceLexiconValue: any;
        ChangePriceCategoryValue: any;
    }

    export interface IState {
        currentPage: number;
        currentPageSize: number;
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetProductFilteredPaginated: productActions.GetProductFilteredPaginated,
        SelectProduct: productActions.SelectProduct,
        DeselectProduct: productActions.DeselectProduct,
        SelectProductColor: productActions.SelectProductColor,
        ChangeLexiconValue: productActions.ChangeLexiconSelect,
        ChangeCategoryValue: productActions.ChangeCategorySelect,
        ChangePriceLexiconValue: productActions.ChangePriceLexiconSelect,
        ChangePriceCategoryValue: productActions.ChangePriceCategorySelect,
        ChangeSubcategoryValue: productActions.ChangeSubcategorySelect,
        ProductUnmount: productActions.ProductUnmount,
        ResetFilter: productActions.ResetFilter,
        ChangeForm: actions.change,
        ResetForm: actions.reset,
        GetAutocompleteResult: categoryActionCreators.GetSubcategoriesAutocomplete,
        GetCategorySelectList: categoryActionCreators.GetParentCategories,
        GetLexioconSelectList: lexiconActionCreators.GetAllLexicons,
        OpenRv: rightViewActionCreators.SelectRVData,
        CloseRv: rightViewActionCreators.RemoveRVData,
    }, dispatch);
};

class ProductsComponent extends React.Component<ProductsComponent.IProps, ProductsComponent.IState> {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            currentPageSize: 25
        };

        this.initComponentData(this.props);
    }

    // componentWillReceiveProps(nextProps: ProductsComponent.IProps) {
    // 	this.initComponentData(nextProps);
    // 	const {
    // 		filterChanged,
    // 		fetchInProccess,
    // 		selectedLexiconValue,
    // 		selectedCategoryValue,
    // 		selectedSubcategoryValue,
    // 	} = nextProps.product;

    // 	if (filterChanged && !fetchInProccess) {
    // 		this.setState(() => ({
    // 			currentPage: 1,
    // 			currentPageSize: 25
    // 		}));

    // 		console.log(this.state.currentPage);
    // 		console.log(this.state.currentPageSize);

    // 		this.props.GetProductFilteredPaginated(
    // 			25,
    // 			1,
    // 			selectedLexiconValue && Number(selectedLexiconValue.value),
    // 			selectedCategoryValue && Number(selectedCategoryValue.value),
    // 			selectedSubcategoryValue && Number(selectedSubcategoryValue.value));

    // 		selectedCategoryValue &&
    // 			nextProps.GetAutocompleteResult("", selectedCategoryValue);
    // 	}
    // }

    componentWillUnmount() {
        this.props.ProductUnmount();
    }

    private initComponentData(props: ProductsComponent.IProps) {
        if (props.Authentication.AuthenticationToken !== '' && props.product.isFirstLoad && !props.product.fetchInProccess) {
            this.GetProductsFiltered();

            this.props.GetLexioconSelectList();
            this.props.GetCategorySelectList();
        }
    }

    private GetProductsFiltered() {
        const {
            selectedLexiconValue,
            selectedCategoryValue,
            selectedSubcategoryValue,
        } = this.props.product;

        this.setState(() => ({
            currentPage: 1,
            currentPageSize: 25
        }));

        this.props.GetProductFilteredPaginated(
            25,
            1,
            selectedLexiconValue && selectedLexiconValue.value,
            selectedCategoryValue && selectedCategoryValue.value,
            selectedSubcategoryValue && selectedSubcategoryValue.value);
    }

    public OnSelectProductCommand(product: Product) {
        this.props.ChangeForm('productForm', product);

        if (product.ProductColors.length > 0) {
            this.props.ChangeForm('productColorForm', product.ProductColors[0]);
        }

        this.props.SelectProduct(product);

        let rvState = new RightViewState();
        rvState.Title = "Редактирование";
        rvState.Component = ProductForm;
        rvState.CloseFunction = this.OnDeselectProductCommand.bind(this);

        this.props.OpenRv(rvState);
    }

    public OnDeselectProductCommand() {
        this.props.CloseRv();
        this.props.DeselectProduct();
        this.props.ResetForm("productForm");
    }

    public OnChangePageSizeCommand(current, pageSize): void {
        const {
            selectedLexiconValue,
            selectedCategoryValue,
            selectedSubcategoryValue,
        } = this.props.product;

        if (!this.props.product.fetchInProccess) {
            this.setState(() => ({
                currentPage: 1,
                currentPageSize: pageSize
            }));

            this.props.GetProductFilteredPaginated(
                this.state.currentPageSize,
                pageSize,
                selectedLexiconValue && selectedLexiconValue.value,
                selectedCategoryValue && selectedCategoryValue.value,
                selectedSubcategoryValue && selectedSubcategoryValue.value);
        }
    }

    public OnChangePageCommand(current, pageSize): void {
        const {
            selectedLexiconValue,
            selectedCategoryValue,
            selectedSubcategoryValue,
        } = this.props.product;

        if (!this.props.product.fetchInProccess) {
            this.setState(() => ({
                currentPage: current
            }));

            this.props.GetProductFilteredPaginated(
                this.state.currentPageSize,
                current,
                selectedLexiconValue && selectedLexiconValue.value,
                selectedCategoryValue && selectedCategoryValue.value,
                selectedSubcategoryValue && selectedSubcategoryValue.value);
        }
    }

    // private OnNextPageCommand() {
    // 	const {
    // 		fetchInProccess,
    // 		take,
    // 		selectedLexiconValue,
    // 		selectedCategoryValue,
    // 		selectedSubcategoryValue,
    // 		totalPages
    // 	} = this.props.product;

    // 	if (!fetchInProccess) {
    // 		let { page } = this.state;

    // 		if (totalPages > page) {
    // 			page++;
    // 			this.props.GetProductFilteredPaginated(
    // 				take,
    // 				page,
    // 				selectedLexiconValue && selectedLexiconValue.value,
    // 				selectedCategoryValue && selectedCategoryValue.value,
    // 				selectedSubcategoryValue && selectedSubcategoryValue.value);

    // 			this.setState({ page: page });
    // 		}
    // 	}
    // }

    // private OnPrevPageCommand() {
    // 	const {
    // 		fetchInProccess,
    // 		take,
    // 		selectedLexiconValue,
    // 		selectedCategoryValue,
    // 		selectedSubcategoryValue
    // 	} = this.props.product;

    // 	if (!fetchInProccess) {
    // 		let { page } = this.state;

    // 		if (page > 1) {
    // 			page--;
    // 			this.props.GetProductFilteredPaginated(
    // 				take,
    // 				page,
    // 				selectedLexiconValue && selectedLexiconValue.value,
    // 				selectedCategoryValue && selectedCategoryValue.value,
    // 				selectedSubcategoryValue && selectedSubcategoryValue.value);
    // 			this.setState({ page: page });
    // 		}
    // 	}
    // }

    private OnSelectSubcategoryCommand(value: any) {
        this.props.ChangeSubcategoryValue(value);
    }

    private OnSelectCategoryCommand(value: any) {
        this.props.ChangeCategoryValue(value);
        value && this.props.GetAutocompleteResult("", value);
    }

    private OnSelectLexiconCommand(value: any) {
        this.props.ChangeLexiconValue(value);
    }

    private onAutocompleteInputChange(value: any) {
        value &&
            this.props.GetAutocompleteResult(value, this.props.product.selectedCategoryValue);
    }

    private onOpenPriceChangeForm() {
        let rvState = new RightViewState();
        rvState.Title = "Редактирование";
        rvState.Component = BulkPriceUpdateForm;
        rvState.CloseFunction = () => {
            this.props.CloseRv();
            this.props.ResetForm('bulkPriceUpdateForm');
            this.props.ChangePriceCategoryValue(null);
            this.props.ChangePriceLexiconValue(null);
        };

        this.props.OpenRv(rvState);
    }

    private OnResetFilterCommand() {
        const {
            filterChanged,
            fetchInProccess,
            selectedLexiconValue,
            selectedCategoryValue,
            selectedSubcategoryValue,
        } = this.props.product;

        if (!fetchInProccess) {
            this.setState(() => ({
                currentPage: 1,
                currentPageSize: 25
            }));

            console.log(this.state.currentPage);
            console.log(this.state.currentPageSize);

            this.props.GetProductFilteredPaginated(
                25,
                1,
                selectedLexiconValue && Number(selectedLexiconValue.value),
                selectedCategoryValue && Number(selectedCategoryValue.value),
                selectedSubcategoryValue && Number(selectedSubcategoryValue.value));

            selectedCategoryValue &&
                this.props.GetAutocompleteResult("", selectedCategoryValue);
        }

        // this.props.ResetFilter();
    }

    render() {
        return (
            <div className="master_content_component rcPagination__component">
                <LeftMenuMobileControl />
                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Товар</div>
                            <div className="selectWrapper__container notPadd">
                                < Select
                                    name="lexicon"
                                    placeholder="Наименование"
                                    isClearable
                                    clearValue={() => { this.props.ChangeLexiconValue(null) }}
                                    options={this.props.product.lexiconSelectList}
                                    onChange={this.props.ChangeLexiconValue}
                                    value={this.props.product.selectedLexiconValue} />
                            </div>
                        </div>
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Бренды</div>
                            <div className="selectWrapper__container notPadd">
                                < Select
                                    name="category"
                                    placeholder="Бренды"
                                    isClearable
                                    clearValue={() => { this.props.ChangeCategoryValue(null) }}
                                    options={this.props.product.categorySelectList}
                                    onChange={this.OnSelectCategoryCommand.bind(this)}
                                    value={this.props.product.selectedCategoryValue} />
                            </div>
                        </div>

                        {
                            this.props.product.selectedCategoryValue &&
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Модели</div>
                                <div className="selectWrapper__container notPadd">
                                    < Select
                                        name="subCategory"
                                        placeholder="Модели"
                                        isClearable
                                        clearValue={() => { this.props.ChangeSubcategoryValue(null) }}
                                        options={this.props.product.autocompleteList}
                                        onInputChange={this.onAutocompleteInputChange.bind(this)}
                                        onChange={this.props.ChangeSubcategoryValue}
                                        value={this.props.product.selectedSubcategoryValue}
                                    />
                                </div>
                            </div>
                        }

                        <div className="defaultFormControl">
                            <div className="defaultFormButton"
                                onClick={(event) =>
                                    this.OnResetFilterCommand()
                                }>Применить</div>
                        </div>
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Товары</span>
                        <span className="title_description">Склад товаров</span>
                    </div>
                    <PermissionChecker
                        DataTooltip=""
                        ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Administrator, IdentityRoleEnum.Admin]}>

                        <div className="header__button__controls">
                            <details className="dStyle" open>
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>
                                <div className="button__control" onClick={(event) => { this.onOpenPriceChangeForm() }}>
                                    Изменение цены
							    </div>
                            </details>

                            <details className="mStyle">
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>
                                <div className="button__control" onClick={(event) => { this.onOpenPriceChangeForm() }}>
                                    Изменение цены
							    </div>
                            </details>
                        </div>
                    </PermissionChecker>
                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">
                                        <div className="grid_cell cell__product_code">
                                            <span className="cell_content">Код</span>
                                        </div>
                                        <div className="grid_cell cell__official_partner_name">
                                            <span className="cell_content">Официальное имя</span>
                                        </div>
                                        <div className="grid_cell cell__casual_name">
                                            <span className="cell_content">Лексикон</span>
                                        </div>
                                        <div className="grid_cell cell__product_colors">
                                            <span className="cell_content">Цвета</span>
                                        </div>
                                        <div className="grid_cell cell__quantity">
                                            <span className="cell_content">Кол-во</span>
                                        </div>

                                        <PermissionChecker
                                            Classes='grid_cell cell__partner_price'
                                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                                            <span className="cell_content">Цена</span>
                                        </PermissionChecker>

                                        <PermissionChecker
                                            Classes='grid_cell cell__partner_price'
                                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                                            <span className="cell_content">РРЦ</span>
                                        </PermissionChecker>

                                    </div>
                                </div>
                            </div>
                            {
                                this.props.product.products.length > 0 ?

                                    <div className="grid_scroll_content_component">
                                        <div className='grid_wrapper_component'>
                                            {
                                                this.props.product.products.length > 0 &&
                                                this.props.product.products.map((product, index) => {
                                                    return (
                                                        <div key={index} className="grid_row mMEDIA___products_ROW" onClick={(event) => { this.OnSelectProductCommand(product) }}>
                                                            <div className="grid_cell products__CODE__CELL cell__product_code" data-tooltip="Код">
                                                                <span className="cell_content">{product.PartnerProductCode}</span>
                                                            </div>
                                                            <div className="grid_cell cell__official_partner_name" data-tooltip="Официальное имя">
                                                                <span className="cell_content">{`${product.Lexicon.OfficialPartnerName} для ${product.Category.Name} ${product.SubCategory ? product.SubCategory.Name : ''}`}</span>
                                                            </div>
                                                            <div className="grid_cell cell__casual_name" data-tooltip="Лексикон">
                                                                <span className="cell_content">{product.Lexicon.OfficialPartnerName}</span>
                                                            </div>
                                                            <div className="grid_cell cell__product_colors" data-tooltip="Цвета">
                                                                <span className="cell_content">{product.ProductColors.length}</span>
                                                            </div>
                                                            <div className="grid_cell cell__quantity" data-tooltip="Кол-во">
                                                                <span className="cell_content">{product.Quantity}</span>
                                                            </div>

                                                            <PermissionChecker
                                                                DataTooltip="Цена"
                                                                Classes='grid_cell cell__partner_price products__PartnerPrice__CELL'
                                                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                                                                <span className="cell_content">{product.PartnerPrice}</span>
                                                            </PermissionChecker>

                                                            <PermissionChecker
                                                                DataTooltip="РРЦ"
                                                                Classes='grid_cell cell__partner_price products__RPP__CELL'
                                                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                                                                <span className="cell_content">{(product as Product).RecomendedPartnerPrice}</span>
                                                            </PermissionChecker>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Loader />
                            }
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        selectComponentClass={RCSelect}
                        showSizeChanger
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.currentPage}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.product.totalPages}
                    />
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({ product: (state as any).stock.product, Authentication: (state as any).authentication }),
    mapDispatchToProps)
    (ProductsComponent);

