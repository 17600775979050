import * as React from "react";

export interface ILoginFormButton {
    onClickCommand(): void;
    name?: string;
}

export const LoginFormButton = (props: ILoginFormButton) => {
    return (
        <div className="login_form_button"
            onClick={props.onClickCommand} >
            {props.name ? props.name : "Войти"}
        </div>
    );
};