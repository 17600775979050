import Product from "../../business.entities/product"
import { ProductColor } from "../../business.entities/product.color";
import { Category } from "../../business.entities/category";
import { SelectModel } from "../../helpers/select.model";

export class ProductState {
    products: Product[];
    selectedProduct: Product;
    fetchInProccess: boolean;
    take: number;
    page: number;
    totalPages: number;
    selectedProductColor: ProductColor;
    
    autocompleteList: SelectModel[];
    lexiconSelectList: SelectModel[];
    categorySelectList: SelectModel[];

    selectedLexiconValue: SelectModel;
    selectedCategoryValue: SelectModel;
    selectedSubcategoryValue: SelectModel;

    filterChanged: boolean;
    isFirstLoad: boolean;
    selectedRVComponent: any;
    
    selectedPriceLexiconValue: SelectModel;
    selectedPriceCategoryValue: SelectModel;
}

export const defaultProductState = {
    products: [],
    selectedProduct: null,
    fetchInProccess: false,
    take: 20,
    page: 1,
    totalPages: 1,
    selectedProductColor: null,
    autocompleteList: [],
    lexiconSelectList: [],
    categorySelectList: [],
    selectedLexiconValue: null,
    selectedCategoryValue: null,
    selectedSubcategoryValue: null,
    filterChanged: false,
    isFirstLoad: true,
    selectedRVComponent: null,
    selectedPriceLexiconValue: null,
    selectedPriceCategoryValue: null,
}