import { Observable } from "rxjs/Observable";
import storeProvider from './store.provider';
import * as API from '../constants/api.constants'
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/debounce';
import 'rxjs/add/observable/timer';
import { actions } from "react-redux-form";

export class ServerValidator {
    public static CheckNameAvailability = (val: string) =>
        Observable.ajax.getJSON(`${API.CHECK_PARTNER_NAME_AVAILABILITY}?username=${val}`)
            .map((response: any) => {
                return response.Body.Succeeded;
            }).catch((errorData: any) => {
                return (Observable as any).empty();
            }).toPromise()
}