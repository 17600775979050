export enum AuditEventType {
    New,
    Edit,
    Delete,
    LogIn,
    LogOut
}

export const GetAuditEventTypeName = (eventType: AuditEventType) => {
    switch (eventType) {
        case 0:
            return "Создание";
        case 1:
            return "Редактирование";
        case 2:
            return "Удаление";
        case 3:
            return "Вход";
        case 4:
            return "Выход";
    }
}