import { bindActionCreators } from "redux";
import * as React from "react";
import { connect } from "react-redux";
import PermissionChecker from '../../../shared/permission.checker.component';
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import {
    GetOrdersForAssembling,
    SendOrdersForAssemble,
    GetOrdersForDeclarations,
    CreateDeclarationsDefault,
    CreateDeclarationsMulti,
    GenerateReesters,
    GetOrdersForSendFromStock,
    SendOrdersFromStock
} from '../../../../actions/sales/order.base.actions';
import { List } from "linqts";
import { Order } from "../../../../business.entities/order";
import { Loader } from "../../../shared/loader";
import { IdentityRoleEnum } from "../../../../helpers/identity.roles";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetOrdersForAssembling,
    SendOrdersForAssemble,
    GetOrdersForDeclarations,
    CreateDeclarationsDefault,
    CreateDeclarationsMulti,
    GenerateReesters,
    GetOrdersForSendFromStock,
    SendOrdersFromStock
}, dispatch);

const GroupActions = (props: any) => {
    const OnSendForAssembleCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            let ids = new List<Order>(props.OrderBase.orders)
                .Where(c => c.IsSelected)
                .Select(c => c.Id)
                .ToArray();

            ids.length > 0 && props.SendOrdersForAssemble(ids);
        }
    }

    const OnSendFromStockCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            let ids = new List<Order>(props.OrderBase.orders)
                .Where(c => c.IsSelected)
                .Select(c => c.Id)
                .ToArray();

            ids.length > 0 && props.SendOrdersFromStock(ids);
        }
    }


    const OnCreateDeclarationsDefaultCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            let ids = new List<Order>(props.OrderBase.orders)
                .Where(c => c.IsSelected)
                .Select(c => c.Id)
                .ToArray();

            ids.length > 0 && props.CreateDeclarationsDefault(ids);
        }
    }

    const OnCreateDeclarationsMultiCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            let ids = new List<Order>(props.OrderBase.orders)
                .Where(c => c.IsSelected)
                .Select(c => c.Id)
                .ToArray();

            ids.length > 0 && props.CreateDeclarationsMulti(ids);
        }
    }

    const OnGenerateReestersCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            props.GenerateReesters();
        }
    }

    return (
        <>
            {
                !props.OrderBase.fetchInProgress ?
                    <div className="sgoupActRVContainer">
                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={() => {
                                    if (!props.OrderBase.fetchInProgress)
                                        props.GetOrdersForAssembling();
                                }}>
                                Отфильтровать для сборки
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={OnSendForAssembleCommand}>
                                Отправить на сборку
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={() => {
                                    if (!props.OrderBase.fetchInProgress)
                                        props.GetOrdersForDeclarations();
                                }}>
                                Отфильтровать для ТТН
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={OnCreateDeclarationsDefaultCommand}>
                                Оформить ТТН 1:1
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={OnCreateDeclarationsMultiCommand}>
                                Оформить ТТН 1:N
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={OnGenerateReestersCommand}>
                                Сформировать реестры
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className=""
                                onClick={() => {
                                    if (!props.OrderBase.fetchInProgress)
                                        props.GetOrdersForSendFromStock();
                                }}>
                                Отфильтровать для отправки
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                            <div className=""
                                onClick={OnSendFromStockCommand}>
                                Отправить со склада
                            </div>
                        </PermissionChecker>
                    </div> :
                    <Loader />
            }
        </>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupActions)