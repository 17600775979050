import { LeftMenuItemModel } from "../../../../helpers/left.menu.item.model";
import * as priceActions from "../../../../actions/stock/price.actions";
import PartnerPriceLevelTerms from "./partner.pricelevel.terms";
import PartnerWholesaleTerms from "./partner.wholesale.terms";
import PartnerPriceGridComponent from "./partner.price.grid";
import { Partner } from "../../../../business.entities/partner";
import { PartnerPrice } from "../../../../business.entities/partner.price";
import { Product } from "../../../../business.entities/product";
import { SelectModel } from "../../../../helpers/select.model";
import { ApplicationState } from "../../../../Reducers/index";
import { PartnerAudit } from "./partner.audit";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from 'react-select';
import * as React from "react";
import { List } from "linqts";
import { Route } from "react-router";
import * as  stockActions from "../../../../actions/stock/stock.actions";
import * as  rightViewActions from "../../../../actions/right.view.actions";
import { PriceState } from "../../../../states/stock/price.state";
import { RightViewState } from "../../../../states/right.view.state";
import { default as AuditComponent, IAuditComponent } from "../../../shared/audit.component/audit.component";
import * as moment from 'moment';
import { renderToStaticMarkup } from "react-dom/server";
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";

namespace PriceComponent {
    export interface IProps {
        history: any;
        price: PriceState;
        GetPartnersAutocomplete: (value: string) => any;
        ChangePartnerSelect: (value: SelectModel) => any;
        SelectContentComponent: (component: any) => any;
        GetAllDefaultPrices: () => any;
        GetPriceCategorySelectList: () => any;
        ChangePriceCategory: (category: SelectModel) => any;
        GetPriceSubcategorySelectList: (categoryName: string, parent: SelectModel) => any;
        ChangePriceSubcategory: (subcategory?: SelectModel) => any;
        GetProductsForPrices: (categoryId: number, subcategoryId: number) => any;
        GetPriceSergmentsForPartner: (netuid: string) => any;
        GetPriceTerms: (netuid: string) => any;
        GetWholesaleList: (netid: string) => any;
        OpenRV: (rvData: any) => any;
        CloseRv: () => any;
        GetHistory: any;
        RemoveHistory: () => any;
        UnmountPriceComponent(): any;
        SetStartDate: any;
        SetEndDate: any;
    }

    export interface IState {
        leftMenuItems: LeftMenuItemModel[]
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        GetPartnersAutocomplete: priceActions.GetPartnersAutocomplete,
        ChangePartnerSelect: priceActions.ChangePartnerSelect,
        SelectContentComponent: priceActions.SelectContentComponent,
        GetAllDefaultPrices: priceActions.GetAllDefaultPrices,
        GetPriceCategorySelectList: priceActions.GetPriceCategorySelectList,
        ChangePriceCategory: priceActions.ChangePriceCategory,
        GetPriceSubcategorySelectList: priceActions.GetPriceSubcategorySelectList,
        ChangePriceSubcategory: priceActions.ChangePriceSubcategory,
        GetProductsForPrices: priceActions.GetProductsForPrices,
        GetPriceSergmentsForPartner: priceActions.GetPriceSergmentsForPartner,
        GetPriceTerms: priceActions.GetPriceTerms,
        GetWholesaleList: priceActions.GetWholesaleList,
        GetHistory: priceActions.GetPartnerHistory,
        RemoveHistory: priceActions.RemovePartnerHistory,
        OpenRV: rightViewActions.SelectRVData,
        CloseRv: rightViewActions.RemoveRVData,
        UnmountPriceComponent: priceActions.UnmountPriceComponent,
        SetStartDate: priceActions.SetStartDate,
        SetEndDate: priceActions.SetEndDate
    }, dispatch);
};

class PriceComponent extends React.Component<PriceComponent.IProps | any, PriceComponent.IState> {
    constructor(props) {
        super(props);
        this.state = {
            leftMenuItems: [
                {
                    Id: 1,
                    IsSelected: true,
                    Label: "Цена партнера и процентная ставка",
                    Component: PartnerPriceGridComponent,
                    AuditEntityName: "PartnerPrice"
                } as LeftMenuItemModel,
                {
                    Id: 2,
                    IsSelected: false,
                    Label: "Условия достижения и рамки ценового уровня",
                    Component: PartnerPriceLevelTerms,
                    AuditEntityName: "PriceTerm"
                } as LeftMenuItemModel,
                {
                    Id: 3,
                    IsSelected: false,
                    Label: "Условия получения оптовой цены",
                    Component: PartnerWholesaleTerms,
                    AuditEntityName: "Wholesale"
                } as LeftMenuItemModel,
            ]
        }

        this.props.GetAllDefaultPrices();
        this.props.GetPriceTerms("");
        this.props.GetPriceCategorySelectList();
        this.props.GetPartnersAutocomplete("");
        this.props.GetWholesaleList("");
    }

    componentWillReceiveProps(nextProps: PriceComponent.IProps) {
        const {
            productFilterChanged,
            productsFetchInProgress,
            selectedCategory,
            selectedSubcategory,
            auditInfo,
            auditStartDate
        } = nextProps.price;
        if (productFilterChanged && !productsFetchInProgress && selectedCategory && selectedSubcategory) {
            this.props.GetProductsForPrices(
                parseInt(selectedCategory.value, 10),
                parseInt(selectedSubcategory.value, 10)
            );
        }

        if (auditInfo && JSON.stringify(this.props.price.auditInfo) !== JSON.stringify(auditInfo)) {
            let selectedMenuItem: LeftMenuItemModel = new List(this.state.leftMenuItems)
                .Where(c => c.IsSelected)
                .FirstOrDefault();
            let rvState = new RightViewState();
            rvState.Title = "Иcтория";
            rvState.Component = AuditComponent;
            rvState.ViewSize = "audit__modal__component";
            rvState.CloseFunction = () => { this.props.RemoveHistory(); this.props.CloseRv(); this.props.SetStartDate(null); this.props.SetEndDate(null); };
            rvState.Props = { AuditEntities: auditInfo, SelectedMenuItem: selectedMenuItem };

            this.props.OpenRV(rvState);
        }
    }

    componentWillUnmount() {
        this.props.UnmountPriceComponent();
    }

    private onAutocompleteInputChange(value: string) {
        this.props.GetPartnersAutocomplete(value);
    }

    private onSubcategoryInputChangeCommand(value: string) {
        this.props.GetPriceSubcategorySelectList(value, this.props.price.selectedCategory);
    }

    private onChangePartnerSelectCommand(value: SelectModel) {
        this.props.ChangePartnerSelect(value);
        value && this.props.GetPriceSergmentsForPartner(value.value);
        !value && this.props.GetAllDefaultPrices();
        this.props.GetPriceTerms(value ? value.value : "");
        this.props.GetWholesaleList(value ? value.value : "");
    }

    private onSelectMenuItemCommand(selectedMenuItem: LeftMenuItemModel) {
        if (!selectedMenuItem.IsSelected) {
            let { leftMenuItems } = this.state;

            leftMenuItems.forEach(element => {
                element.IsSelected = false;
            });

            let element = new List<LeftMenuItemModel>(leftMenuItems)
                .Where(c => c.Id === selectedMenuItem.Id)
                .FirstOrDefault();

            let elementIndex = leftMenuItems.indexOf(element);
            leftMenuItems[elementIndex].IsSelected = true;

            this.setState({ leftMenuItems: leftMenuItems });

            this.props.SelectContentComponent(selectedMenuItem.Component);
        }
    }

    private onGetHistory() {
        let selectedMenuItem: LeftMenuItemModel = new List(this.state.leftMenuItems)
            .Where(c => c.IsSelected)
            .FirstOrDefault();

        this.props.GetHistory(
            this.props.price.selectedPartner ? this.props.price.selectedPartner.value : "",
            selectedMenuItem.AuditEntityName,
            this.props.price.auditStartDate ? this.props.price.auditStartDate.format('DD MMMM YYYY') : this.props.price.auditStartDate,
            this.props.price.auditEndDate ? this.props.price.auditEndDate.format('DD MMMM YYYY') : this.props.price.auditEndDate
        )
    }

    render() {
        return (
            <div className="master_content_component">
                <LeftMenuMobileControl />
                <div className="left_menu_component priceInfoContainer">
                    <div className="left_menu_SCROLL">
                        <div className="selectWrapperContent_menu_component">
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">
                                    Партнеры
                        </div>
                                <div className="selectWrapper__container">
                                    < Select
                                        name="partner"
                                        options={this.props.price.partnerAutocompleteList}
                                        onInputChange={this.onAutocompleteInputChange.bind(this)}
                                        onChange={this.onChangePartnerSelectCommand.bind(this)}
                                        value={this.props.price.selectedPartner}
                                        placeholder="Партнеры"
                                    />
                                </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">
                                    Марки
                        </div>
                                <div className="selectWrapper__container">
                                    < Select
                                        name="category"
                                        options={this.props.price.categorySelectList}
                                        onChange={this.props.ChangePriceCategory}
                                        value={this.props.price.selectedCategory}
                                        placeholder="Марки"
                                    />
                                </div>
                            </div>

                            {
                                this.props.price.selectedCategory &&
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Модели</div>
                                    <div className="defaultFormControl">
                                        < Select
                                            name="subCategory"
                                            options={this.props.price.subcategoryAutocompleteList}
                                            onInputChange={this.onSubcategoryInputChangeCommand.bind(this)}
                                            onChange={this.props.ChangePriceSubcategory}
                                            value={this.props.price.selectedSubcategory}
                                            placeholder="Модели"
                                        />
                                    </div>
                                </div>

                            }
                        </div>
                        <div className="pControlsWrapperContainer">
                            <div className="defaultFormControl">
                                <div className="deliveryCheckWrapper__container">
                                    <div
                                        className={'bHBCheckbox ' + (this.state.leftMenuItems[0].IsSelected ? "isSelected" : "")}
                                        onClick={() => this.onSelectMenuItemCommand(this.state.leftMenuItems[0])}
                                    >
                                        <span className="control__item">
                                            {this.state.leftMenuItems[0].Label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="defaultFormControl">
                                <div className="deliveryCheckWrapper__container">
                                    <div className={'bHBCheckbox ' + (this.state.leftMenuItems[1].IsSelected ? "isSelected" : "")} onClick={() => this.onSelectMenuItemCommand(this.state.leftMenuItems[1])}>
                                        <span className="control__item">
                                            {this.state.leftMenuItems[1].Label}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="defaultFormControl">
                                <div className="deliveryCheckWrapper__container">
                                    <div className={'bHBCheckbox ' + (this.state.leftMenuItems[2].IsSelected ? "isSelected" : "")} onClick={() => this.onSelectMenuItemCommand(this.state.leftMenuItems[2])}>
                                        <span className="control__item">
                                            {this.state.leftMenuItems[2].Label}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className={'defaultFormControl '}>
                                <div className="defaultFormButton" onClick={() => this.onGetHistory()}>Иcтория</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Ценообразование</span>
                        <span className="title_description">Цены и ставки</span>
                    </div>
                </div>

                <div className="content_component prices__page__component">
                    <Route path={this.props.history.location.pathName} component={this.props.price.selectedContentComponent} />
                </div>
            </div>
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ price: state.stock.price }),
    mapDispatchToProps)
    (PriceComponent);
