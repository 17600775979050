import { MutualSettlementOperationType } from "../helpers/enums/mutualsettlement.operation.type";
import { Order } from "./order";
import Partner from "./partner";
import { EntityBase } from "./entity.base";
import { AppealType } from "./appeal.type";

export class MutualSettlement extends EntityBase {
    public PartnerId: number;
    public OperationType: MutualSettlementOperationType;
    public OrderId: number;
    public Comment: string = '';
    public AppealTypeId: number;
    public Amount: number;
    public Balance?: number;
    public PaymentDate: Date;
    public Order: Order;
    public AppealType: AppealType;
    public Partner: Partner;
    public Reason: string = '';
    public TTN: string = '';
}