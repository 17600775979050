import { EntityBase } from "./entity.base";
import { Partner } from "./partner";

export class PricelistOptions extends EntityBase {
    public PartnerNetId: string;

    public PartnerId: number;

    public Filter: string;

    public LexiconPrices: string;

    public ProductPrices: string;

    public ExportConfigure: string;

    public AvailableOnly: boolean = false;
}