import { Partner } from "../../business.entities/partner";
import { PriceLevel } from "../../helpers/enums/price.level.enum";
import { any } from "prop-types";
import { ConstructorPrices } from "../../business.entities/constructor.prices";
import { GetPositionName, WatermarkPositionEnum } from "../../helpers/enums/watermark.position.enum";

export class UserInfoState {
  public userInfo: Partner;
  public PriceTerm: any;
  public LastTotal: any;
  public Balance: any;
  public ConstructorPrices: ConstructorPrices;
  public PartnerPrices: any[];
  public LogoEditMode: boolean;
  public ConstructorPriceMode: boolean;
  public LogoPreviewMode: boolean;
  public IntegrationInfoMode: boolean;
  public SelectedPosition: any;
  public NewImage: any;
  public Preview: any;
  public IsRemovingLogo: boolean;
  public Tutorials: any[];
  public SelectedTutorial: any;
  public EditTutorialMode: boolean;
  public UploadedVideo: any;
  public Fetching: boolean;
  public ConstructorUsers: any[];
}

export const DefaultUserInfoState: UserInfoState = {
  userInfo: new Partner(),
  PriceTerm: null,
  LastTotal: null,
  Balance: null,
  ConstructorPrices: null,
  PartnerPrices: [],
  LogoEditMode: false,
  ConstructorPriceMode: false,
  LogoPreviewMode: false,
  IntegrationInfoMode: false,
  SelectedPosition: WatermarkPositionEnum.TopLeft,
  NewImage: null,
  Preview: null,
  IsRemovingLogo: false,
  Tutorials: [],
  SelectedTutorial: null,
  EditTutorialMode: false,
  UploadedVideo: null,
  Fetching: false,
  ConstructorUsers: []
};