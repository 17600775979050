export const RENEW_OPENCART_SINGLE_FILE: string = '[API] PRICELIST_EXPORT.RENEW_OPENCART_SINGLE_FILE';
export interface IRenewOpencartSingleFile { type: '[API] PRICELIST_EXPORT.RENEW_OPENCART_SINGLE_FILE'; payload: any; }
export const RenewOpencartSingleFile = () =>
    <IRenewOpencartSingleFile>{ type: RENEW_OPENCART_SINGLE_FILE };

export const RENEW_OPENCART_MULTIPLE_FILE: string = '[API] PRICELIST_EXPORT.RENEW_OPENCART_MULTIPLE_FILE';
export interface IRenewOpencartMultipleFile { type: '[API] PRICELIST_EXPORT.RENEW_OPENCART_MULTIPLE_FILE'; payload: any; }
export const RenewOpencartMultipleFile = () =>
    <IRenewOpencartMultipleFile>{ type: RENEW_OPENCART_MULTIPLE_FILE };

export const FINISH_ACTIONS: string = 'PRICELIST_EXPORT.FINISH_ACTIONS';
export interface IFinishAction { type: 'PRICELIST_EXPORT.FINISH_ACTIONS'; payload: any; }
export const FinishAction = () =>
    <IFinishAction>{ type: FINISH_ACTIONS };

export type PriceListExportKnownActions =
    IRenewOpencartSingleFile |
    IRenewOpencartMultipleFile |
    IFinishAction;