export enum PartnerPaymentMethodEnum {
    EarlyPayment,
    PostPayment
}

export const GetPartnerPaymentMethodName = (value: PartnerPaymentMethodEnum) => {
    switch (value) {
        case PartnerPaymentMethodEnum.EarlyPayment:
            return 'Предоплата';
        case PartnerPaymentMethodEnum.PostPayment:
            return 'Послеоплата';
    }
}