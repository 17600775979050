import { deleteCookie, getCookie } from "./cookies";
import { Logout } from "../actions/login.actions";
import { push } from "react-router-redux";
import { error as errorNotification } from "react-notification-system-redux";
import { Observable } from "rxjs/Observable";
import * as API from '../constants/api.constants';
import storeProvider from "./store.provider";

export function ErrorHandler(error: any, store?: any, action?: any) {
    if (error.status === 400) {
        return errorNotification({
            title: JSON.parse(error.response.Message).Message,
            message: '',
            position: 'br',
            autoDismiss: 3,
        });


    }

    if (error.status === 401) {
        //debugger
    }

    // if (error.status === 404) {

    //     return;
    // }

    return errorNotification({
        title: "Something goes wrong",
        message: '',
        position: 'br',
        autoDismiss: 3,
    });
}