export enum PaymentStatusEnum {
    Recived = 1,
    PaymentTime,
    Waiting
}

export const GetPaymentStatusName = (status) => {
    switch (status) {
        case PaymentStatusEnum.Recived:
            return 'Получена';
        case PaymentStatusEnum.PaymentTime:
            return 'Время';
        case PaymentStatusEnum.Waiting:
            return 'Не получена';
        default:
            return '';
    }
}
