export const GET_FINANCE_STATS: string = '[API] STATISTICS.GET_FINANCE_STATS';

export interface IGetFinanceStats {
    type: '[API] STATISTICS.GET_FINANCE_STATS';
    payload: any
}

export const GetFinanceStats = (partnerId, partnerUid, start, end) =>
    <IGetFinanceStats>{type: GET_FINANCE_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_PARNTER_STATS: string = '[API] STATISTICS.GET_PARNTER_STATS';

export interface IGetPartnerStats {
    type: '[API] STATISTICS.GET_PARNTER_STATS';
    payload: any
}

export const GetPartnerStats = (partnerId, partnerUid, start, end) =>
    <IGetPartnerStats>{type: GET_PARNTER_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_PRODUCTION_STATS: string = '[API] STATISTICS.GET_PRODUCTION_STATS';

export interface IGetParoductionSatats {
    type: '[API] STATISTICS.GET_PRODUCTION_STATS';
    payload: any
}

export const GetParoductionSatats = (partnerId, partnerUid, start, end) =>
    <IGetParoductionSatats>{type: GET_PRODUCTION_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_LASER_STATS: string = '[API] STATISTICS.GET_LASER_STATS';

export interface IGetLaserSatats {
    type: '[API] STATISTICS.GET_LASER_STATS';
    payload: any
}

export const GetLaserSatats = (partnerId, partnerUid, start, end) =>
    <IGetLaserSatats>{type: GET_LASER_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_PRINT_STATS: string = '[API] STATISTICS.GET_PRINT_STATS';

export interface IGetPrintStats {
    type: '[API] STATISTICS.GET_PRINT_STATS';
    payload: any
}

export const GetPrintStats = (partnerId, partnerUid, start, end) =>
    <IGetPrintStats>{type: GET_PRINT_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_PAYMENT_STATS: string = '[API] STATISTICS.GET_PAYMENT_STATS';

export interface IGetPaymentStats {
    type: '[API] STATISTICS.GET_PAYMENT_STATS';
    payload: any
}

export const GetPaymentStats = (partnerId, partnerUid, start, end) =>
    <IGetPaymentStats>{type: GET_PAYMENT_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_DELIVERY_STATS: string = '[API] STATISTICS.GET_DELIVERY_STATS';

export interface IGetDeliveryStats {
    type: '[API] STATISTICS.GET_DELIVERY_STATS';
    payload: any
}

export const GetDeliveryStats = (partnerId, partnerUid, start, end) =>
    <IGetDeliveryStats>{type: GET_DELIVERY_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_CLIENT_STATS: string = '[API] STATISTICS.GET_CLIENT_STATS';

export interface IGetClientStats {
    type: '[API] STATISTICS.GET_CLIENT_STATS';
    payload: any
}

export const GetClientStats = (partnerId, partnerUid, start, end) =>
    <IGetClientStats>{type: GET_CLIENT_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_ORDER_STATS: string = '[API] STATISTICS.GET_ORDER_STATS';

export interface IGetOrderStats {
    type: '[API] STATISTICS.GET_ORDER_STATS';
    payload: any
}

export const GetOrderStats = (partnerId, partnerUid, start, end) =>
    <IGetOrderStats>{type: GET_ORDER_STATS, payload: {partnerId, partnerUid, start, end}};

export const GET_FINISHED_PRODUCTS_STATS: string = '[API] STATISTICS.GET_FINISHED_PRODUCTS_STATS';

export interface IGetFinishedProductsStats {
    type: '[API] STATISTICS.GET_FINISHED_PRODUCTS_STATS';
    payload: any
}

export const GetFinishedProductsStats = (partnerId, partnerUid, start, end) =>
    <IGetFinishedProductsStats>{type: GET_FINISHED_PRODUCTS_STATS, payload: {partnerId, partnerUid, start, end}};

export const SET_STATS: string = 'STATISTICS.SET_STATS';

export interface ISetStats {
    type: 'STATISTICS.SET_STATS';
    payload: any
}

export const SetStats = (stats) =>
    <ISetStats>{type: SET_STATS, payload: stats};

export const SET_OPTIONS: string = 'STATISTICS.SET_OPTIONS';

export interface ISetOptions {
    type: 'STATISTICS.SET_OPTIONS';
    payload: any
}

export const SetOptions = (stats) =>
    <ISetOptions>{type: SET_OPTIONS, payload: stats};

export const CHANGE_PARTNER: string = 'STATISTICS.CHANGE_PARTNER';

export interface IChangePartner {
    type: 'STATISTICS.CHANGE_PARTNER';
    payload: any
}

export const ChangePartner = (parnter) =>
    <IChangePartner>{type: CHANGE_PARTNER, payload: parnter};

export const CHANGE_START_DATE: string = 'STATISTICS.CHANGE_START_DATE';

export interface IChangeStartDate {
    type: 'STATISTICS.CHANGE_START_DATE';
    payload: any
}

export const ChangeStartDate = (date) =>
    <IChangeStartDate>{type: CHANGE_START_DATE, payload: date};

export const CHANGE_END_DATE: string = 'STATISTICS.CHANGE_END_DATE';

export interface IChangeEndDate {
    type: 'STATISTICS.CHANGE_END_DATE';
    payload: any
}

export const ChangeEndDate = (date) =>
    <IChangeEndDate>{type: CHANGE_END_DATE, payload: date};

export const GET_PARTNERS_FOR_FILTER = '[API] STATISTICS.GET_PARTNERS_FOR_FILTER';

export interface IGetPartnersForFilter {
    type: '[API] STATISTICS.GET_PARTNERS_FOR_FILTER',
    payload: any
}

export const GetPartnersForFilter = (value) =>
    <IGetPartnersForFilter>{type: GET_PARTNERS_FOR_FILTER, payload: value};

export const SET_PARTNERS_FOR_FILTER = 'STATISTICS.SET_PARTNERS_FOR_FILTER';

export interface ISetPartnersForFilter {
    type: 'STATISTICS.SET_PARTNERS_FOR_FILTER',
    payload: any
}

export const SetPartnersForFilter = (value) =>
    <ISetPartnersForFilter>{type: SET_PARTNERS_FOR_FILTER, payload: value};

export const CHANGE_STAT = 'STATISTICS.CHANGE_STAT';

export interface IChangeStat {
    type: 'STATISTICS.CHANGE_STAT',
    payload: any
}

export const ChangeStat = (value) =>
    <IChangeStat>{type: CHANGE_STAT, payload: value};

export const CHANGE_DIAGRAMM_MODE = 'STATISTICS.CHANGE_DIAGRAMM_MODE';

export interface IChangeDiagrammMode {
    type: 'STATISTICS.CHANGE_DIAGRAMM_MODE',
    payload: any
}

export const ChangeDiagrammMode = (mode: boolean) =>
    <IChangeDiagrammMode>{type: CHANGE_DIAGRAMM_MODE, payload: mode};

export const ADD_PARTNER_FOR_DIAGRAM = 'STATISTICS.ADD_PARTNER_FOR_DIAGRAM';

export interface ISelectPartnerForDiagram {
    type: 'STATISTICS.ADD_PARTNER_FOR_DIAGRAM',
    payload: any
}

export const SelectPartnerForDiagram = (partner: any) =>
    <ISelectPartnerForDiagram>{type: ADD_PARTNER_FOR_DIAGRAM, payload: partner};

export const REMOVE_PARTNER_FROM_LIST = 'STATISTICS.REMOVE_PARTNER_FROM_LIST';

export interface IRemovePartnerFromList {
    type: 'STATISTICS.REMOVE_PARTNER_FROM_LIST',
    payload: any
}

export const RemovePartnerFromList = (index) =>
    <IRemovePartnerFromList>{type: REMOVE_PARTNER_FROM_LIST, payload: index};

export const SELECT_PARTNER_FOR_LIST = 'STATISTICS.SELECT_PARTNER_FOR_LIST';

export interface ISelectPartnerForList {
    type: 'STATISTICS.SELECT_PARTNER_FOR_LIST',
    payload: any
}

export const SelectPartnerForList = (value) =>
    <ISelectPartnerForList>{type: SELECT_PARTNER_FOR_LIST, payload: value};

export const SET_DIAGRAMM_OPTIONS = 'STATISTICS.SET_DIAGRAMM_OPTIONS';

export interface ISetDiagramOptions {
    type: 'STATISTICS.SET_DIAGRAMM_OPTIONS',
    payload: any
}

export const SetDiagramOptions = (value) =>
    <ISetDiagramOptions>{type: SET_DIAGRAMM_OPTIONS, payload: value};

export const SELECT_DIAGRAMM_OPTION = 'STATISTICS.SELECT_DIAGRAMM_OPTION';

export interface ISelectDiagramOption {
    type: 'STATISTICS.SELECT_DIAGRAMM_OPTION',
    payload: any
}

export const SelectDiagramOption = (value) =>
    <ISelectDiagramOption>{type: SELECT_DIAGRAMM_OPTION, payload: value};

export const SELECT_DIAGRAMM_STAT_OPTION = 'STATISTICS.SELECT_DIAGRAMM_STAT_OPTION';

export interface ISelectDiagramStatOption {
    type: 'STATISTICS.SELECT_DIAGRAMM_STAT_OPTION',
    payload: any
}

export const SelectDiagramStatOption = (value) =>
    <ISelectDiagramStatOption>{type: SELECT_DIAGRAMM_STAT_OPTION, payload: value};

export const ADD_STAT_FOR_LIST = 'STATISTICS.ADD_STAT_FOR_LIST';

export interface IAddStatForList {
    type: 'STATISTICS.ADD_STAT_FOR_LIST',
    payload: any
}

export const AddStatForList = (value) =>
    <IAddStatForList>{type: ADD_STAT_FOR_LIST, payload: value};

export const REMOVE_STAT_FROM_LIST = 'STATISTICS.REMOVE_STAT_FROM_LIST';

export interface IRemoveStatFromList {
    type: 'STATISTICS.REMOVE_STAT_FROM_LIST',
    payload: any
}

export const RemoveStatFromList = (index) =>
    <IRemoveStatFromList>{type: REMOVE_STAT_FROM_LIST, payload: index};

export const CHANGE_TIMEPART = 'STATISTICS.CHANGE_TIMEPART';

export interface IChangeTimepart {
    type: 'STATISTICS.CHANGE_TIMEPART',
    payload: any
}

export const ChangeTimepart = (timepart) =>
    <IChangeTimepart>{type: CHANGE_TIMEPART, payload: timepart};

export const GET_FINANCES_DIAGRAM = '[API] STATISTICS.GET_FINANCES_DIAGRAM';

export interface IGetFinancesDiagram {
    type: '[API] STATISTICS.GET_FINANCES_DIAGRAM',
    payload: any
}

export const GetFinancesDiagram = (value) =>
    <IGetFinancesDiagram>{type: GET_FINANCES_DIAGRAM, payload: value};

export const GET_ORDERS_DIAGRAM = '[API] STATISTICS.GET_ORDERS_DIAGRAM';

export interface IGetOrdersDiagram {
    type: '[API] STATISTICS.GET_ORDERS_DIAGRAM',
    payload: any
}

export const GetOrdersDiagram = (value) =>
    <IGetOrdersDiagram>{type: GET_ORDERS_DIAGRAM, payload: value};

export const GET_FINISHED_ORDER_PRODUCTS_DIAGRAM = '[API] STATISTICS.GET_FINISHED_ORDER_PRODUCTS_DIAGRAM';

export interface IGetFinishedOrderProductsDiagram {
    type: '[API] STATISTICS.GET_FINISHED_ORDER_PRODUCTS_DIAGRAM',
    payload: any
}

export const GetFinishedOrderProductsDiagram = (value) =>
    <IGetFinishedOrderProductsDiagram>{type: GET_FINISHED_ORDER_PRODUCTS_DIAGRAM, payload: value};

export const SET_DIAGRAM = 'STATISTICS.SET_DIAGRAM';

export interface ISetDiagram {
    type: 'STATISTICS.SET_DIAGRAM',
    payload: any
}

export const SetDiagram = (value) =>
    <ISetDiagram>{type: SET_DIAGRAM, payload: {value}};

export const ADD_CHART = 'STATISTICS.ADD_CHART';

export interface IAddChart {
    type: 'STATISTICS.ADD_CHART',
    payload: any
}

export const AddChart = (value) =>
    <IAddChart>{type: ADD_CHART, payload: value};

export const CHANGE_DIAGRAM_START_DATE = 'STATISTICS.CHANGE_DIAGRAM_START_DATE';

export interface IChangeDiagramStartDate {
    type: 'STATISTICS.CHANGE_DIAGRAM_START_DATE',
    payload: any
}

export const ChangeDiagramStartDate = (value) =>
    <IChangeDiagramStartDate>{type: CHANGE_DIAGRAM_START_DATE, payload: value};

export const CHANGE_DIAGRAM_END_DATE = 'STATISTICS.CHANGE_DIAGRAM_END_DATE';

export interface IChangeDiagramEndDate {
    type: 'STATISTICS.CHANGE_DIAGRAM_END_DATE',
    payload: any
}

export const ChangeDiagramEndDate = (value) =>
    <IChangeDiagramEndDate>{type: CHANGE_DIAGRAM_END_DATE, payload: value};

export const RESET_DIAGRAMS = 'STATISTICS.RESET_DIAGRAMS';

export interface IResetDiagrams {
    type: 'STATISTICS.RESET_DIAGRAMS',
    payload: any
}

export const ResetDiagrams = () =>
    <IResetDiagrams>{type: RESET_DIAGRAMS};

export const RESET_PARTNER_DIAGRAMS = 'STATISTICS.RESET_PARTNER_DIAGRAMS';

export interface IResetPartnerDiagrams {
    type: 'STATISTICS.RESET_PARTNER_DIAGRAMS',
    payload: any
}

export const ResetPartnerDiagrams = () =>
    <IResetPartnerDiagrams>{type: RESET_PARTNER_DIAGRAMS};

export const GET_LEXICONS: string = '[API] STATISTICS.GET_LEXICONS';

export interface IGetLexicons {
    type: '[API] STATISTICS.GET_LEXICONS';
    payload: any
}

export const GetLexicons = () =>
    <IGetLexicons>{type: GET_LEXICONS};

export const SET_LEXICONS: string = 'STATISTICS.SET_LEXICONS';

export interface ISetLexicons {
    type: 'STATISTICS.SET_LEXICONS';
    payload: any
}

export const SetLexicons = (lexicons) =>
    <ISetLexicons>{type: SET_STATS, payload: lexicons};

export const UNMUONT: string = 'STATISTICS.UNMUONT';

export interface IUnmount {
    type: 'STATISTICS.UNMUONT';
    payload: any
}

export const Unmount = () =>
    <IUnmount>{type: UNMUONT};

export type StatisticsKnownActions =
    IUnmount | IGetFinanceStats | ISetStats | IChangePartner | IChangeStartDate | IChangeEndDate | IGetPartnersForFilter |
    ISetPartnersForFilter | ISetOptions | IGetPartnerStats | IChangeStat | IGetParoductionSatats | IGetPrintStats |
    IGetPaymentStats | IGetDeliveryStats | IGetClientStats | IGetOrderStats | IGetFinishedProductsStats | IChangeDiagrammMode |
    ISelectPartnerForDiagram | IRemovePartnerFromList | ISelectPartnerForList | ISetDiagramOptions | ISelectDiagramOption |
    IAddStatForList | IRemoveStatFromList | ISelectDiagramStatOption | IChangeTimepart | IGetFinancesDiagram | ISetDiagram | IAddChart |
    IChangeDiagramStartDate | IChangeDiagramEndDate | IResetDiagrams | IGetOrdersDiagram | IGetLexicons | ISetLexicons | IGetFinishedOrderProductsDiagram |
    IResetPartnerDiagrams | IGetLaserSatats;