import { bindActionCreators } from "redux";
import * as React from "react";
import { connect } from "react-redux";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import {
} from '../../../../actions/sales/order.base.actions';
import { StockStatus } from "../../../../business.entities/stock.status";
import Moment from "react-moment";
import { Loader } from "../../../shared/loader";
import { GetStockStatusName } from "../../../../helpers/enums/stock.status.enum";

const mapStateToProps = (state) => {
    return {
        OrderBase: (state.sales.orderBase as OrderBaseState) 
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

const StockInfo = (props: any) => {

    return (
        <div>
            <div className="grid_component">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell cStatus">
                                <div className="cell_content ">Статус</div>
                            </div>
                            <div className="grid_cell cCreated">
                                <div className="cell_content ">Дата создания</div>
                            </div>
                            <div className="grid_cell cUserId">
                                <div className="cell_content ">Пользователь</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        {
                            (props.OrderBase as OrderBaseState).currentStockStatuses ?
                                (props.OrderBase as OrderBaseState).currentStockStatuses.map((status, index) =>
                                    <div className="grid_row mMEDIA___orderStatus__GRID_ROW" key={index}>
                                        <div className="grid_cell cStatus" data-tooltip="Статус">
                                            <div className="cell_content ">
                                                {GetStockStatusName((status as StockStatus).Status)}
                                            </div>
                                        </div>
                                        <div className="grid_cell cCreated" data-tooltip="Дата присвоения">
                                            <div className="cell_content cContent">
                                                <Moment format="DD/MM/YYYY HH:mm" date={(status as StockStatus).Created} />
                                            </div>
                                        </div>
                                        <div className="grid_cell cUserId" data-tooltip="Присвоил">
                                            <div className="cell_content ">
                                                {status.Partner ? status.Partner.ContactName : null}
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                <Loader />
                        }
                    </div>
                </div>

            </div>
        </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(StockInfo)