import { PersonalInfoKnownActions } from "../../actions/cabinet/personal.info.actions";
import { DefaultUserInfoState, UserInfoState } from "../../states/cabinet/user.info.state";
import * as cabinetActionTypes from "../../actions/cabinet/personal.info.actions"
import { ConstructorPrices, ConstructorPriceItem } from "../../business.entities/constructor.prices";
import Partner from "../../business.entities/partner";

export default function (state: UserInfoState, action: PersonalInfoKnownActions) {
  switch (action.type) {
    case cabinetActionTypes.GET_USER_PERSONAL_INFO_SUCCESS:
      return Object.assign({}, state, {
        userInfo: action.payload,
        LogoPreviewMode: action.payload.EnabledConstructorWatermark
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_CLIENT_INFO_SUCCESS:
      return { ...state, userInfo: action.payload };

    case cabinetActionTypes.SET_FUNDS_INFO:
      return Object.assign({}, state, {
        PriceTerm: action.payload.terms,
        LastTotal: action.payload.lastTotal,
        Balance: action.payload.balance
      } as UserInfoState);

    case cabinetActionTypes.SET_CONSTRUCTOR_PRICE:
      return Object.assign({}, state, {
        ConstructorPrices: action.payload
      } as UserInfoState);

    case cabinetActionTypes.SET_PRICE_SEGMENTS_FOR_PARTNER:
      return Object.assign({}, state, {
        PartnerPrices: action.payload
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_CONSTRUCTOR_PRICE_ITEM:
      return Object.assign({}, state, {
        ConstructorPrices: Object.assign({}, state.ConstructorPrices, {
          PriceItems: Object.assign([], state.ConstructorPrices.PriceItems,
            {
              [action.payload.index]: Object.assign({}, state.ConstructorPrices.PriceItems[action.payload.index], {
                Price: action.payload.value
              } as ConstructorPriceItem)
            })
        } as ConstructorPrices)
      } as UserInfoState);

    case cabinetActionTypes.LOGO_EDIT_MODE:
      return Object.assign({}, state, {
        LogoEditMode: action.payload,
        ConstructorPriceMode: action.payload === true ? false : state.ConstructorPriceMode,
        IntegrationInfoMode: action.payload === true ? false : state.IntegrationInfoMode,
        LogoPreviewMode: action.payload === false ? state.userInfo.EnabledConstructorWatermark : state.LogoPreviewMode,
        SelectedPosition: action.payload === false ? state.userInfo.WatermarkPosition : state.SelectedPosition,
        NewImage: DefaultUserInfoState.NewImage,
        Preview: DefaultUserInfoState.Preview
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_POSITION:
      return Object.assign({}, state, {
        SelectedPosition: action.payload
      } as UserInfoState);

    case cabinetActionTypes.OPEN_PRICES:
      return Object.assign({}, state, {
        ConstructorPriceMode: action.payload,
        LogoEditMode: action.payload === true ? false : state.LogoEditMode,
        IntegrationInfoMode: action.payload === true ? false : state.IntegrationInfoMode,
        LogoPreviewMode: state.userInfo.EnabledConstructorWatermark
      } as UserInfoState);

    case cabinetActionTypes.OPEN_LOGO_PREVIEW:
      return Object.assign({}, state, {
        LogoPreviewMode: true
      } as UserInfoState);

    case cabinetActionTypes.OPEN_INTEGRATION_INFO:
      return Object.assign({}, state, {
        IntegrationInfoMode: action.payload,
        LogoEditMode: action.payload === true ? false : state.LogoEditMode,
        ConstructorPriceMode: action.payload === true ? false : state.ConstructorPriceMode,
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_LOGO_IMG:
      return Object.assign({}, state, {
        NewImage: action.payload
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_PREVIEW_IMG:
      return Object.assign({}, state, {
        Preview: action.payload
      } as UserInfoState);
    
    case cabinetActionTypes.SET_REMOVING_LOGO: 
      return Object.assign({}, state, {
        IsRemovingLogo: action.payload
      } as UserInfoState);

    case cabinetActionTypes.SET_CONSTRUCTOR_TUTORIALS:
      return Object.assign({}, state, {
        Tutorials: action.payload,
        Fetching: false
      } as UserInfoState);

    case cabinetActionTypes.SELECT_TUTORIAL:
      return Object.assign({}, state, {
        SelectedTutorial: action.payload.tutorial,
        EditTutorialMode: action.payload.editMode
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_TUTORIAL:
      return Object.assign({}, state, {
        SelectedTutorial: action.payload,
      } as UserInfoState);

    case cabinetActionTypes.CHANGE_VIDEO:
      return Object.assign({}, state, {
        UploadedVideo: action.payload,
        SelectedTutorial: Object.assign({}, state.SelectedTutorial, {
          Link: action.payload ? state.SelectedTutorial.Link : ''
        })
      } as UserInfoState);

    case cabinetActionTypes.GET_CONSTRUCTOR_USERS:
      return Object.assign({}, state, {
        Fetching: true
      } as UserInfoState);

    case cabinetActionTypes.SET_CONSTRUCTOR_USERS:
      return Object.assign({}, state, {
        Fetching: false,
        ConstructorUsers: action.payload
      } as UserInfoState);

    case cabinetActionTypes.UPDATE_CONSTRUCTOR_TUTORIAL:
      return Object.assign({}, state, {
        Fetching: true,
      } as UserInfoState);

    case cabinetActionTypes.UNMOUNT_COMPONENT:
      return Object.assign({}, DefaultUserInfoState);

    default:
      const exhaustiveCheck: any = action;
  }

  return state || DefaultUserInfoState;
}