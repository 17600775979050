import { TutorialKnownActions } from "../../actions/cabinet/tutorial.actions";
import { TutorialState, DefaultTutorialState } from "../../states/cabinet/tutorial.state";
import * as tutorialActionTypes from "../../actions/cabinet/tutorial.actions";

export default function (state: TutorialState, action: TutorialKnownActions) {
    switch (action.type) {
        case tutorialActionTypes.GET_ALL:
            return Object.assign({}, state, { fetching: true } as TutorialState);

        case tutorialActionTypes.SET_ALL:
            return Object.assign({}, state, { tutorials: action.payload.tutorials, total: action.payload.total, fetching: false } as TutorialState);

        case tutorialActionTypes.NEW_TUTORIAL:
            return Object.assign({}, state, { fetching: true } as TutorialState);

        case tutorialActionTypes.UPDATE_TUTORIAL:
            return Object.assign({}, state, { fetching: true } as TutorialState);

        case tutorialActionTypes.CHANGE_NAME:
            return Object.assign({}, state, {
                selectedTutorial: Object.assign({}, state.selectedTutorial, {
                    Name: action.payload
                })
            } as TutorialState);

        case tutorialActionTypes.CHANGE_DESCRIPTION:
            return Object.assign({}, state, {
                selectedTutorial: Object.assign({}, state.selectedTutorial, {
                    Description: action.payload
                })
            } as TutorialState);

        case tutorialActionTypes.CHANGE_ORDER:
            return Object.assign({}, state, {
                selectedTutorial: Object.assign({}, state.selectedTutorial, {
                    Order: action.payload
                })
            } as TutorialState);

        case tutorialActionTypes.CHANGE_DELETED:
            return Object.assign({}, state, {
                selectedTutorial: Object.assign({}, state.selectedTutorial, {
                    Deleted: action.payload
                })
            } as TutorialState);

        case tutorialActionTypes.CHANGE_VIDEO:
            return Object.assign({}, state, {
                uploadedVideo: action.payload,
                selectedTutorial: Object.assign({}, state.selectedTutorial, {
                    Link: action.payload ? state.selectedTutorial.Link : ''
                })
            } as TutorialState);

        case tutorialActionTypes.SELECT_TUTORIAL:
            return Object.assign({}, state, {
                tutorials: Object.assign([], state.tutorials, {
                    [action.payload.key]: Object.assign({}, state.tutorials[action.payload.key], {
                        IsSelected: true
                    })
                }),
                selectedTutorial: action.payload.tutorial
            } as TutorialState);

        case tutorialActionTypes.DESELECT_TUTORIAL:
            return Object.assign({}, state, {
                tutorials: Object.assign([], state.tutorials.map((tutorial) => { tutorial.IsSelected = false; return tutorial; })),
                selectedTutorial: {}
            } as TutorialState);

        case tutorialActionTypes.UNMUONT:
            return DefaultTutorialState;

        default:
            const exhaustiveCheck: any = action;
    }

    return state || DefaultTutorialState;
} 