import * as React from 'react';
import { bindActionCreators } from 'redux';
import {
    CalculateProductTotal,
    CancelCurrentOrderProduct,
    SaveOrderProduct,
    SetNextSubStep,
    SetOrderProduct,
    UpdateOrderProductServer
} from '../../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { GetOrderProductStepComponent, GetOrderProductStepName, OrderProductStep } from '../../../../../../helpers/enums/order.product.steps';
import { OnGoToNextOrderCardStepCommand, OnGoToPrevOrderCardStepCommand } from '../../../../../../helpers/wizard.substep.helper';
import { Route } from 'react-router';
import { WizardState } from '../../../../../../states/sales/wizard.state';
import { OrderCardState, ProductUpdateState } from '../../../../../../states/sales/order.card.state';
import { ProductTypeEnum } from '../../../../../../helpers/enums/product.type.enum';
import { List } from 'linqts';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard

    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SetOrderProduct,
    UpdateOrderProductServer,
    SaveOrderProduct,
    CalculateProductTotal
}, dispatch);

const PopupWrapper = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProductUpdate: ProductUpdateState = orderCard.productUpdateSubState;

    const GenerateTotalLabel = () => {

        let firstPart = orderProductUpdate.currentOrderProduct.PartnerPrice;

        let secondPart = orderProductUpdate.currentOrderProduct.Price;

        if (orderProductUpdate.currentOrderProduct.PartnerTotal)
            firstPart = orderProductUpdate.currentOrderProduct.PartnerTotal;

        if (orderProductUpdate.currentOrderProduct.Total)
            secondPart = orderProductUpdate.currentOrderProduct.Total;

        return `${firstPart} | ${secondPart}`;
    }

    const IsLastStep = () => {
        if (orderProductUpdate.orderProductStepPattern[orderProductUpdate.orderProductStepPattern.length - 1]
            === orderProductUpdate.currentStepEnum) {
            return true;
        }

        return false;
    }

    const OnNextButtonCommand = () => {
        if (!orderProductUpdate.serverActionInProcess)
            if (orderProductUpdate.orderProductStepPattern[orderProductUpdate.orderProductStepPattern.length - 1]
                === (orderProductUpdate.currentStepEnum)) {
                if (orderProductUpdate.serverActionInProcess === false) {
                    !orderProductUpdate.currentOrderProduct.Id ?
                        props.SaveOrderProduct(orderProductUpdate.currentOrderProduct) :
                        props.UpdateOrderProductServer(orderProductUpdate.currentOrderProduct);
                }
            } else {
                if (IsNextStepAllowed()) {

                    orderProductUpdate.currentStepEnum === OrderProductStep.Price
                        && props.CalculateProductTotal(orderProductUpdate.currentOrderProduct);

                    OnGoToNextOrderCardStepCommand(props);
                }
            }
    }

    const IsValidStepInfo = (step: OrderProductStep) => {
        // if (step === OrderProductStep.ProductColor) {
        //     if (!(props.Wizard as WizardState).currentOrderProduct.ColorId
        //         || !(props.Wizard as WizardState).currentOrderProduct.Color)
        //         return false;
        // }

        // if (step === OrderProductStep.BaseProduct) {
        //     if (!(props.Wizard as WizardState).currentOrderProduct.BaseProductId
        //         || !(props.Wizard as WizardState).currentOrderProduct.BaseProduct)
        //         return false;
        // }

        // if (step === OrderProductStep.BaseProductColor) {
        //     if (!(props.Wizard as WizardState).currentOrderProduct.BaseColorId
        //         || !(props.Wizard as WizardState).currentOrderProduct.Id)
        //         return false;
        // }

        // if (step === OrderProductStep.Design) {

        // }

        // if (step === OrderProductStep.Picture) {

        // }

        // if (step === OrderProductStep.Price) {

        // }

        // if (step === OrderProductStep.Quantity) {

        // }

        return true;
    }

    const IsNextStepAllowed = () => {
        if (orderProductUpdate.serverActionInProcess)
            return false;

        if (orderProductUpdate.currentStepEnum === OrderProductStep.ProductColor) {
            if (!orderProductUpdate.currentOrderProduct.ColorId
                || !orderProductUpdate.currentOrderProduct.Color)
                return false;

            // if (orderProductUpdate.currentProduct.Lexicon.ProductTypeId == ProductTypeEnum.Production
            //     || orderProductUpdate.currentProduct.Lexicon.ProductTypeId == ProductTypeEnum.PrintProduction )
            //     return false;

            // &&
            // !orderProductUpdate.currentOrderProduct.ProductionComment
        }

        if (orderProductUpdate.currentStepEnum === OrderProductStep.AdditionalOption) {
            if (!(orderProductUpdate.currentProduct as any).OptionCategoryTree || (orderProductUpdate.currentProduct as any).OptionCategoryTree.length === 0)
                return true;

            if (!orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption)
                return false;

            // if (orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption.filter(c => !c.Deleted).length !== (orderProductUpdate.currentProduct as any).OptionCategoryTree.length)
            
            let lengthes = 0;
            (orderProductUpdate.currentProduct as any).OptionCategoryTree.map(c => lengthes += c.SubCategories.length);
            if (lengthes !== orderProductUpdate.currentOrderProduct.OrderProductAdditionalOption.filter(c => !c.Deleted).length)
                return false;
        }

        if (orderProductUpdate.currentStepEnum === OrderProductStep.BaseProduct) {
            if (!orderProductUpdate.currentOrderProduct.BaseProductId
                || !orderProductUpdate.currentOrderProduct.BaseProduct)
                return false;
        }

        if (orderProductUpdate.currentStepEnum === OrderProductStep.BaseProductColor) {
            if (!orderProductUpdate.currentOrderProduct.BaseColorId
                || !orderProductUpdate.currentOrderProduct.Id)
                return false;
        }


        if (orderProductUpdate.currentStepEnum === OrderProductStep.Price) {
            if (!orderProductUpdate.currentOrderProduct.Price)
                return false;
        }

        return true;
    }

    const AnySimilar = () => {
        if (orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Print
            || orderProductUpdate.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction)
            return false;

        if (orderProductUpdate.currentOrderProducts && orderProductUpdate.currentOrderProducts.length === 0)
            return false;

        return new List(orderProductUpdate.currentOrderProducts)
            .Where(c => c.ProductId === orderProductUpdate.currentOrderProduct.ProductId
                && c.ColorId === orderProductUpdate.currentOrderProduct.ColorId
                && c.BaseProductId === orderProductUpdate.currentOrderProduct.BaseProductId
                && c.BaseColorId === orderProductUpdate.currentOrderProduct.BaseColorId
                && c.Id !== orderProductUpdate.currentOrderProduct.Id)
            .Any();
    }


    return (
        <div className="productSelect__modalWrapperContainer" >
            <div className="productSelect__modalContainer">
                <div className="closeModalIcon__control" onClick={() => props.CancelCurrentOrderProduct()} />

                {AnySimilar() ?
                    <div className="notErroContainer">
                        Подобный товар уже добавлен. При сохранении товары будут соединены.
                    </div> : null
                }

                <div className="productSelect__modalHeader">
                    <div className="brandContainer">
                        <div className="brandWrapperContainer2">
                            <div className="brand">
                                {/* Бренд */}
                                {orderProductUpdate.currentOrderProduct.Product.Category.Name}
                            </div>
                            <div className="model">
                                {/* Модель */}
                                {orderProductUpdate.currentOrderProduct.Product.SubCategory.Name}
                            </div>
                            <div className="lexiconContainer">
                                <div className="lexiconWrapper">
                                    <div className="lexiconItem">
                                        <div className="lexLabel">{'Наименование: '}</div>
                                        <div className="lexValue">
                                            <span>{orderProductUpdate.currentOrderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                        </div>
                                        {orderProductUpdate.currentOrderProduct.Color ?
                                            <div className="lexValueColor">
                                                <div className="colorBg" style={{ backgroundColor: orderProductUpdate.currentOrderProduct.Color.Code === 'clear' ? '' : orderProductUpdate.currentOrderProduct.Color.Code }} />
                                                {/* Товар цвет*/}
                                                <div className="colorBgLabel">
                                                    ({orderProductUpdate.currentOrderProduct.Color.Name})
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    {orderProductUpdate.currentOrderProduct.BaseProduct ?
                                        <div className="lexiconItem">
                                            <div className="lexLabel">{'Основа: '}</div>
                                            <div className="lexValue">
                                                <span>{orderProductUpdate.currentOrderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                            </div>
                                            {orderProductUpdate.currentOrderProduct.BaseColor ?
                                                <div className="lexValueColor">
                                                    <div className="colorBg" style={{ backgroundColor: orderProductUpdate.currentOrderProduct.BaseColor.Code === 'clear' ? '' : orderProductUpdate.currentOrderProduct.BaseColor.Code }} />
                                                    {/* Товар цвет*/}
                                                    <div className="colorBgLabel">
                                                        ({orderProductUpdate.currentOrderProduct.BaseColor.Name})
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>

                            <div className="qtyContainer">
                                <div className="hide2">
                                    {/* Количество */}
                                </div>

                                <div className="hide2">
                                    {/* Тотал */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="informationContainer">

                        <div className="dopContainer">
                            <div className="priceWrapper">
                                <div className="priceItem partnerPrice">
                                    <div className="value">
                                        <span className="pPrice_value">{orderProductUpdate.currentOrderProduct.PartnerPrice}</span>
                                        <span className="price_value">/{orderProductUpdate.currentOrderProduct.Price}</span>
                                    </div>
                                    <div className="label">{'Цена'}</div>
                                </div>

                                {
                                    !orderProductUpdate.currentOrderProduct.Price || !orderProductUpdate.currentOrderProduct.Quantity ?
                                        <div className="priceItem ">
                                            <div className="value">{GenerateTotalLabel()}</div>
                                            <div className="label">{'Всего'} : {orderProductUpdate.currentOrderProduct.Quantity} шт.</div>
                                        </div>
                                        : <div className="priceItem ">
                                            <div className="value">{GenerateTotalLabel()}</div>
                                            <div className="label">{'Всего'} : {orderProductUpdate.currentOrderProduct.Quantity} шт.</div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productSelect__modalContent">
                    <Route path={props.history.location.pathName}
                        component={orderProductUpdate.orderProductSubStep} />
                </div>
                <div className="productSelect__modalSteps">
                    <div className="productSelect__modalStepsWrapper">
                        {/* steps */}

                        {orderProductUpdate.orderProductStepPattern
                            && orderProductUpdate.orderProductStepPattern.map((step, index) =>
                                <div key={index}
                                    className={`stepItem ${step === orderProductUpdate.currentStepEnum ? 'isSelected' : ''} ${IsValidStepInfo(step) ? '' : 'isError'}`}>
                                    <div className="stepLabel">{GetOrderProductStepName(step)}</div>
                                </div>
                            )}
                    </div>
                </div>

                <div className="wrapperModalArrow__container">
                    <div className="arrowButton__control left" onClick={() => OnGoToPrevOrderCardStepCommand(props)}>
                        <div className="arrowIcon__container" />
                    </div>

                    <div className={`arrowButton__control ${IsLastStep() ? 'last' : ''} ${IsNextStepAllowed() ? '' : 'isDisabled'}`}
                        onClick={() => OnNextButtonCommand()}>
                        <div className="arrowIcon__container" />
                    </div>
                </div>
            </div>

            <div className="closeModalButton__control" onClick={() => props.CancelCurrentOrderProduct()} />
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupWrapper)