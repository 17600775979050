import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserInfoState } from "../../../../states/cabinet/user.info.state";
import { Loader } from "../../../shared/loader";

const ConstructorUsers = (props) => {

    return (
        <div className="container__ConstructorUsers">
            <div className="ccUTitle">
                Партнёр
                </div>
            <ul>
                {
                    (props.UserInfo as UserInfoState).Fetching ?
                        <Loader /> :
                        (props.UserInfo as UserInfoState).ConstructorUsers.map(partner =>
                            <li>
                                {partner.ContactName}
                            </li>
                        )
                }
            </ul>
        </div>
    );
}
export default connect(
    state => ({
        UserInfo: (state as any).cabinet.userInfo
    }),
    dispatch => bindActionCreators({

    }, dispatch)
)(ConstructorUsers)
