import { PrintStatisticsKnownActions } from "../../actions/cabinet/print.statistics.actions";
import { DefaultPrintingStatisticsState, PrintingStatisticsState } from "../../states/cabinet/print.stats.state";
import * as statisticsActionTypes from "../../actions/cabinet/print.statistics.actions";
import { Reducer } from "redux";

export const reducer: Reducer<PrintingStatisticsState> = (state: PrintingStatisticsState, action: PrintStatisticsKnownActions) => {
    switch (action.type) {
        case statisticsActionTypes.SET_START_DATE:
            return Object.assign({}, state, {
                StartDate: action.payload
            } as PrintingStatisticsState)

        case statisticsActionTypes.SET_START_TIME:
            return Object.assign({}, state, {
                StartTime: action.payload
            } as PrintingStatisticsState)

        case statisticsActionTypes.SET_END_DATE:
            return Object.assign({}, state, {
                EndDate: action.payload
            } as PrintingStatisticsState)

        case statisticsActionTypes.SET_END_TIME:
            return Object.assign({}, state, {
                EndTime: action.payload
            } as PrintingStatisticsState)

        case statisticsActionTypes.GET_PRINT_STATISTICS_SUCCESS:
            return Object.assign({}, state, {
                PrintingStatusesByUsers: action.payload,
                Fetching: false
            } as PrintingStatisticsState)
        case statisticsActionTypes.SET_FETCHING:
            return Object.assign({}, state, {
                Fetching: action.payload
            } as PrintingStatisticsState)
        default:
            const exhaustiveCheck: any = action;
    }
    
    return state || DefaultPrintingStatisticsState;
}