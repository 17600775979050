import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import { Form, actions } from "react-redux-form";
import { TextBoxWithLabel } from "../../../../shared/form.controls/textbox.with.label";
import { MaxLength, Validator } from "../../../../../helpers/validator.helper";
import * as ActionCreators from "../../../../../actions/partner/partner.info.actions";
import { ServerValidator } from "../../../../../helpers/server.validation.helper";
import { Observable } from "rxjs";
import { SelectControl } from "../../../../shared/custom.controls/select";
import { IdentityRolesArray } from "../../../../../helpers/identity.roles";

const mapStateToProps = (state) => {
    return {
        selectedPartner: state.partnerForm,
        partnerUser: state.partnerUserForm
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        CreateUserForPartner: ActionCreators.AddUserPartner,
        ResetForm: actions.reset,
        ChangeForm: actions.change
    }, dispatch);
};

class UserRegistrationForm extends React.Component<any, any> {

    componentWillUnmount() {
        this.props.ResetForm("partnerUserForm");
    }

    public IsPasswordValid(val): Promise<any> {
        return (Observable as any).of(val).map((val) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!_-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!_-]{8,}$/g.test(val)
        ).toPromise();
    }

    public IsPasswordExist(val): Promise<any> {
        return (Observable as any).of(val).map((val) => (!val || val === '') ? true : false).toPromise();
    }

    public OnChangeRoleCommmand(role: any) : void {
        this.props.ChangeForm('partnerUserForm.Role', role.value);
    }

    render() {
        return (
            <Form
                className="default_form_component shadow_controls"
                validateOn='submit'
                model="partnerUserForm"
                onSubmit={(partnerUserForm: any) => this.props.CreateUserForPartner(this.props.selectedPartner.NetUid, partnerUserForm)}>

                <div className="form_row_component">

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Роль</div>
                        <div className="selectWrapper__container notPadd">
                            <SelectControl
                                onChangeMethod={(value) => this.OnChangeRoleCommmand(value)}
                                options={IdentityRolesArray}
                            />
                        </div>
                    </div>

                    <TextBoxWithLabel
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                            IsMaxLength: MaxLength(20),
                        }}
                        AsyncValidators={{
                            isUniqueName: (val: string, done: any) =>
                                ServerValidator.CheckNameAvailability(val).then(res => done(res))
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                            IsMaxLength: 'TooManyCharacters',
                            isUniqueName: 'Name is occupied'
                        }}
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        AsyncValidateOn='blur'
                        LabelMessage='Логін'
                        IsRequired={false}
                        Model='.UserName' />

                    <TextBoxWithLabel
                        AsyncValidators={{
                            isRequired: (val: string, done: any) => this.IsPasswordExist(val).then(res => done(res)),
                            isPasswordMatchToPattern: (val: string, done: any) =>
                                this.IsPasswordValid(val).then(res => done(res))
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                            isPasswordMatchToPattern: 'PasswordMatchToPattern'
                        }}
                        ShowErrorOn="touched"
                        Type='text'
                        ValidatedOn='change'
                        AsyncValidateOn='blur'
                        LabelMessage='Пароль'
                        IsRequired={false}
                        Model='.Password' />
                </div>
                <div className="defaultFormControl">
                    <button type="submit" className="defaultFormButton" >
                        Создать
                    </button>
                </div>
            </ Form>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRegistrationForm);
