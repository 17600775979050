import { Reducer } from "redux";
import { LoginState, defaultLoginState } from "../states/login.state";
import { AuthenticationKnownActions, SET_USER_INFO, SET_REFRESHED_INFO, SET_REFRESH_PROPMISE } from '../actions/login.actions'
import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from "../actions/login.actions";
import { RegistrationUser } from "../business.entities/registration.user";
import { read } from "fs";
import { debounce } from "rxjs/operator/debounce";

export const reducer: Reducer<LoginState> = (state: LoginState, action: any) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, isLoginInProgress: true };

        case LOGIN_SUCCESS:
            return {
                ...state,
                AuthenticationToken: action.payload.token,
                RefreshToken: action.payload.refresh,
                UserName: action.payload.userLogin,
                Role: action.payload.role,
                UserNetUid: action.payload.userNetUid,
                Exp: action.payload.exp,
                isAuthenticated: true,
                isLoginInProgress: false
            };

        case LOGIN_FAILED:
            return { ...state, isLoginInProgress: false };

        case LOGOUT:
            return { ...defaultLoginState };

        case SET_USER_INFO:
            return { ...state, userInfo: action.payload };

        case SET_REFRESHED_INFO:
            return Object.assign(action.payload);

        case SET_REFRESH_PROPMISE:
            return { ...state, RefreshPromise: action.payload }

        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultLoginState;
}