import * as React from "react";
import { connect } from "react-redux";

export const FinishedProducts = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        {
                            model && model.Data && model.Data.map((model) =>
                                <>
                                    <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                        <div className="grid_cell cell__GRID_name">
                                            <span className="cell_content__GC">
                                                Количество проданных товаров {model.LexiconName}
                                            </span>
                                        </div>

                                        <div className="grid_cell">
                                            <span className="cell_content__GC">
                                                Количество товаров "{model.LexiconName}" оформленных заказов, которые на момент проверки уже переводились в статус отправки "Отправлен"
                                            </span>
                                        </div>

                                        <div className="grid_cell cell__GRID_CONT">
                                            <span className="cell_content__GC">
                                                {model.Quantity}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                        <div className="grid_cell cell__GRID_name">
                                            <span className="cell_content__GC">
                                            Стоимость проданных товаров {model.LexiconName}
                                            </span>
                                        </div>

                                        <div className="grid_cell">
                                            <span className="cell_content__GC">
                                                Партнерская стоимость товаров "{model.LexiconName}" оформленных заказов, которые на момент проверки уже переводились в статус отправки "Отправлен"
                                            </span>
                                        </div>

                                        <div className="grid_cell cell__GRID_CONT">
                                            <span className="cell_content__GC">
                                                {model.Total}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                                        <div className="grid_cell cell__GRID_name"></div>
                                        <div className="grid_cell"></div>
                                        <div className="grid_cell cell__GRID_CONT"></div>
                                    </div>
                                </>
                            )
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(FinishedProducts);
