import * as Authentication from '../services/authentication.service';
import * as AviabilityServises from '../services/stock/aviability.service';
import * as CabinetEpics from '../services/cabinet/cabinet.service';
import * as CategoryActions from '../services/stock/category.service';
import * as ClientBaseServise from '../services/sales/client.base.service';
import * as LexiconActions from '../services/stock/lexicon.service';
import * as LoginReducer from './login.reducer';
import * as RegistrationReducer from './registration.reducer';
import * as MutualSettlement from '../services/cabinet/mutual.settlements.service';
import * as PartnerActions from '../services/partner/partner.service';
import * as PartnerGridActions from '../services/partner/partner.grid.service';
import * as PlatformActions from '../services/stock/platform.service';
import * as PriceActions from '../services/stock/price.service';
import * as PriceListActions from '../services/stock/price.list.service';
import * as ProductActions from '../services/stock/product.service';
import * as Registration from '../services/registration.serrvice';
import * as StatisticService from '../services/cabinet/statistics.service';
import * as PromocodeService from '../services/partner/promocode.service';
import AviabilityReducer from '../Reducers/stock.reducers/aviability.reducer';
import ClientBaseReducer from './sales/client.base.reducer';
import MutualSettlementsReducer from './cabinet.reducers/mutual.settlements.reducer';
import Partner from '../business.entities/partner';
import PartnerWholesale from '../business.entities/partnerWholesale';
import personalInforedusers from '../reducers/cabinet.reducers/personal.info.reducer';
import ProductColor from '../business.entities/product.color';
import RegistrationUser from '../business.entities/registration.user';
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { dafaultPartnerInfoState } from '../states/partner/partner.info.state';
import { defaultAvailabilityState } from '../states/stock/aviability.state';
import { defaultClientBaseState } from '../states/sales/client.base.state';
import { DefaultTutorialState } from '../states/cabinet/tutorial.state';
import { defaultLexiconState } from '../states/stock/lexicon.state';
import { defaultLoginState, LoginState } from '../states/login.state';
import { DefaultMutualSettlementsState } from '../states/cabinet/mutual.settlements.state';
import { defaultPartnerGridState } from '../states/partner/partner.grid.state';
import { defaultPartnerState, IGlobalPartnerState } from '../states/partner/partner.state';
import { defaultPriceListState } from '../states/stock/price.list.state';
import { defaultPriceState } from '../states/stock/price.state';
import { defaultProductState } from '../states/stock/product.state';
import { defaultRegistrationState, RegistrationState } from '../states/registration.state';
import { defaultSalesState, IGlobalSalesState, SalesState } from '../states/sales/sales.state';
import { DefaultUserInfoState } from '../states/cabinet/user.info.state';
import { defaultWizardState, WizardState } from '../states/sales/wizard.state';
import { formReducer, modelReducer } from 'react-redux-form';
import { IGlobalCabinetState } from '../states/cabinet/cabinet.state';
import { IGlobalStockState } from '../states/stock/stock.state';
import { Lexicon } from '../business.entities/lexicon';
import { PartnerPrice } from '../business.entities/partner.price';
import { PriceTerm } from '../business.entities/price.term';
import { Product } from '../business.entities/product';
import { ProductPriceListItem } from '../business.entities/product.price.list.item';
import { reducer as rightViewReducer } from './right.view.reducer';
import { reducer as PartnerReducer } from './partner.reducers/partner.reducer';
import { reducer as PromocodeReducer } from './partner.reducers/promocode.reducer';
import { reducer as ProductReducer } from './stock.reducers/product.reducer';
import { reducer as PartnerInfoReducer } from './partner.reducers/partner.info.reducer';
import { reducer as PriceListReducer } from './stock.reducers/price.list.reducer';
import { reducer as PartnerGridReducer } from './partner.reducers/partner.grid.reducer';
import { reducer as PriceReducer } from './stock.reducers/price.reducers';
import { reducer as LexiconReducer } from './stock.reducers/lexicon.reducer';
import { RightViewState } from '../states/right.view.state';
import { UpdatePartnerUserName } from '../helpers/form.models/update.partner.user.name';
import { reducer as WizardReducer } from './sales/wizard.reducer';
import * as WizardService from "../services/sales/wizard.service";
import { defaultOrderBaseState } from '../states/sales/order.base.state';
import { reducer as OrderBaseReducer } from './sales/order.base.reducer';
import { reducer as OrderCardReducer } from './sales/order.card.reducer';
import { reducer as PrintReducer } from './sales/printer.reducer';
import * as OrderBase from '../services/sales/order.base.service';
import * as OrderCard from '../services/sales/order.card.service';
import * as Printers from '../services/sales/printers.service';
import * as Tutorial from '../services/cabinet/tutorial.service';
import { defaultOrderCardState } from '../states/sales/order.card.state';
import { defaultPrinterState } from '../states/sales/printer.state';
import TutorialReducer from './cabinet.reducers/tutorial.reducer';
import { reducer as StatisticsReducer } from './cabinet.reducers/statistics.reducer';
import { DefaultStatisticsState } from '../states/cabinet/statistics.state';
import { defaultPromocodeState } from '../states/partner/promocode.state';
import { DefaultPrintingStatisticsState, PrintingStatisticsState } from '../states/cabinet/print.stats.state';
import * as PrintStatisticsService from '../services/cabinet/print.statistics.service';
import {reducer as PrintStatisticsReducer} from './cabinet.reducers/print.statistics.reducer';

export interface ApplicationState {
    authentication: LoginState,
    notifications: any[],
    registration: RegistrationState,
    partner: IGlobalPartnerState,
    stock: IGlobalStockState,
    cabinet: IGlobalCabinetState,
    sales: IGlobalSalesState,
    rightView: RightViewState,

}

export const applicationState: ApplicationState = {
    authentication: defaultLoginState,
    notifications: [],
    registration: defaultRegistrationState,
    partner: {
        partner: defaultPartnerState,
        partnerGrid: defaultPartnerGridState,
        partnerInfo: dafaultPartnerInfoState,
        partnerPromocodes: defaultPromocodeState
    },
    stock: {
        lexicon: defaultLexiconState,
        product: defaultProductState,
        price: defaultPriceState,
        aviability: defaultAvailabilityState,
        priceList: defaultPriceListState
    },
    cabinet: {
        userInfo: DefaultUserInfoState,
        mutualSettlements: DefaultMutualSettlementsState,
        tutorial: DefaultTutorialState,
        statistics: DefaultStatisticsState,
        printStatistics: DefaultPrintingStatisticsState
    },
    sales: {
        sales: defaultSalesState,
        clientBase: defaultClientBaseState,
        wizard: defaultWizardState,
        orderBase: defaultOrderBaseState,
        orderCard: defaultOrderCardState,
        printers: defaultPrinterState
    },
    rightView: null
}

const epicsArray = [
    ...(Object as any).values(Registration),
    ...(Object as any).values(Authentication),
    ...(Object as any).values(PartnerActions),
    ...(Object as any).values(PartnerGridActions),
    ...(Object as any).values(LexiconActions),
    ...(Object as any).values(ProductActions),
    ...(Object as any).values(CategoryActions),
    ...(Object as any).values(PriceActions),
    ...(Object as any).values(CabinetEpics),
    ...(Object as any).values(AviabilityServises),
    ...(Object as any).values(ClientBaseServise),
    ...(Object as any).values(PriceListActions),
    ...(Object as any).values(PlatformActions),
    ...(Object as any).values(MutualSettlement),
    ...(Object as any).values(WizardService),
    ...(Object as any).values(OrderBase),
    ...(Object as any).values(OrderCard),
    ...(Object as any).values(Printers),
    ...(Object as any).values(Tutorial),
    ...(Object as any).values(StatisticService),
    ...(Object as any).values(PromocodeService),
    ...(Object as any).values(PrintStatisticsService)
];

export const epics = combineEpics(
    ...epicsArray
);

const combinedStockReducer = combineReducers({
    lexicon: LexiconReducer,
    product: ProductReducer,
    price: PriceReducer,
    aviability: AviabilityReducer,
    priceList: PriceListReducer
});

const combinedPartnerReducer = combineReducers<any>({
    partnerGrid: PartnerGridReducer,
    partnerInfo: PartnerInfoReducer,
    partner: PartnerReducer,
    promocode: PromocodeReducer
});

const combinedCabinetReducer = combineReducers({
    userInfo: personalInforedusers,
    mutualSettlements: MutualSettlementsReducer,
    tutorial: TutorialReducer,
    statistics: StatisticsReducer,
    printStatistics: PrintStatisticsReducer
});

const combinedSalesReducer = combineReducers<SalesState>({
    clientBase: ClientBaseReducer,
    wizard: WizardReducer,
    orderBase: OrderBaseReducer,
    orderCard: OrderCardReducer,
    printers: PrintReducer
});

export const reducers = {
    authentication: LoginReducer.reducer,
    registration: RegistrationReducer.reducer,
    cabinet: combinedCabinetReducer,
    stock: combinedStockReducer,
    partner: combinedPartnerReducer,
    sales: combinedSalesReducer,
    rightView: rightViewReducer,

    partnerUserFormReducer: formReducer('partnerUserForm'),
    partnerUserForm: modelReducer('partnerUserForm', new RegistrationUser()),

    partnerFormReducer: formReducer('partnerForm'),
    partnerForm: modelReducer('partnerForm', new Partner()),

    lexiconFormReducer: formReducer('lexiconForm'),
    lexiconForm: modelReducer('lexiconForm', new Lexicon()),

    partnerUserNameReducer: formReducer('partnerUserNameForm'),
    partnerUserNameForm: modelReducer('partnerUserNameForm', new UpdatePartnerUserName()),

    productFormReducer: formReducer('productForm'),
    productForm: modelReducer('productForm', new Product()),

    productColorFormReducer: formReducer('productColorForm', new ProductColor()),
    productColorForm: modelReducer('productColorForm', new ProductColor()),

    partnerPriceFormReducer: formReducer('partnerPriceForm'),
    partnerPriceForm: modelReducer('partnerPriceForm', new PartnerPrice()),

    priceLevelTermsReducer: formReducer('priceLevelTerms'),
    priceLevelTerms: modelReducer('priceLevelTerms', new PriceTerm()),

    wholesaleFormReducer: formReducer('wholesaleForm'),
    wholesaleForm: modelReducer('wholesaleForm', new PartnerWholesale()),

    pricelistItemFormReducer: formReducer('pricelistItemForm'),
    pricelistItemForm: modelReducer('pricelistItemForm', new ProductPriceListItem()),

    pricelistLexiconPriceFormReducer: formReducer('pricelistLexiconPriceForm'),
    pricelistLexiconPriceForm: modelReducer('pricelistLexiconPriceForm', { LexionPrices: [] }),

    platformConfigFormReducer: formReducer('platformConfigForm'),
    platformConfigForm: modelReducer('platformConfigForm', { ExportPlatformConfigItem: [] }),

    bulkPriceUpdateFormReducer: formReducer('bulkPriceUpdateForm'),
    bulkPriceUpdateForm: modelReducer('bulkPriceUpdateForm', { Price: 0 }),

    bulkRecomendedPriceUpdateFormReducer: formReducer('bulkRecomendedPriceUpdateForm'),
    bulkRecomendedPriceUpdateForm: modelReducer('bulkRecomendedPriceUpdateForm', { Price: 0 }),

    mutualSettlementsCustomerPaymentFormReducer: formReducer('mutualSettlementsCustomerPaymentForm'),
    mutualSettlementsCustomerPaymentForm: modelReducer('mutualSettlementsCustomerPaymentForm', { TTN: '', amount: '' }),

    mutualSettlementsReplenishmentOfBalanceFormReducer: formReducer('mutualSettlementsReplenishmentOfBalanceForm'),
    mutualSettlementsReplenishmentOfBalanceForm: modelReducer('mutualSettlementsReplenishmentOfBalanceForm', { Amount: '', Comment: '', Date: null, Time: null }),

    mutualSettlementsFundsWithdrawalFormReducer: formReducer('mutualSettlementsFundsWithdrawalForm'),
    mutualSettlementsFundsWithdrawalForm: modelReducer('mutualSettlementsFundsWithdrawalForm', { Amount: '', Comment: '', Date: null, Time: null }),

    mutualSettlementsRefundsFormReducer: formReducer('mutualSettlementsRefundsForm'),
    mutualSettlementsRefundsForm: modelReducer('mutualSettlementsRefundsForm', { TTN: '', amount: '', reason: 'Вислали не той товар', comment: '' }),

    mutualSettlementsPaymentForDeliveryFormReducer: formReducer('mutualSettlementsPaymentForDeliveryForm'),
    mutualSettlementsPaymentForDeliveryForm: modelReducer('mutualSettlementsPaymentForDeliveryForm', { TTN: '', amount: '', comment: '' }),
};