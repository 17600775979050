import * as React from 'react';
import {
    ApproveOrderCreation,
    ClearWizard,
    CreateTempOrder,
    GetCategories,
    CheckForTempOrder,
    ProcessTempOrder,
    GetDeliveryPrice,
    GetTempOrder
} from '../../../../actions/sales/wizard.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { default as Wizard } from './wizard';
import { LoginState } from '../../../../states/login.state';
import { WizardState } from '../../../../states/sales/wizard.state';
import { Loader } from '../../../shared/loader';
import { Switch, Route } from "react-router-dom";
import { push } from 'react-router-redux';


export interface ICreateOrderProps {
    Authentication: LoginState;
    Wizard: WizardState;
    ApproveOrderCreation(): void;
    ClearWizard(): void;
    GetCategories(value: string): void;
    CreateTempOrder(): void;
    CheckForTempOrder(): void;
    ProcessTempOrder(uid): void;
    GetDeliveryPrice(deliveryType, paymentmethod);
    GetTempOrder(netId);
}

export interface ICreateOrderState {

}

class CreateOrder extends React.Component<ICreateOrderProps, ICreateOrderState> {
    constructor(props) {
        super(props);

        // if (props.match.params.netId != null) {
        //     props.GetTempOrder(props.match.params.netId);
        // } else
        !props.Wizard.tempOrderChecked &&
            props.CheckForTempOrder();

        props.GetDeliveryPrice(props.Wizard.order.DeliveryType, props.Wizard.order.PaymentMethod);
    }

    componentWillReceiveProps(nextProps) {
        // if (this.props.Wizard.categorySelectRef.current && !this.props.Wizard.selectedCategory) {
        //     (!nextProps.Wizard.selectedCategory && !nextProps.Wizard.isFinishMenuOpen) &&
        //         this.props.Wizard.categorySelectRef.current.focus();
        // }
    }

    componentWillUnmount() {
        // this.props.ClearWizard();
    }

    public OnApproveOrderCreation() {
        this.props.ClearWizard();
        this.props.GetCategories("");
        this.props.ApproveOrderCreation();
        this.props.CreateTempOrder();
    }

    public OnContinueTempOrderCommand() {
        this.props.GetCategories("");
        this.props.ApproveOrderCreation();
        (this.props as any).history.push(`wizard/${this.props.Wizard.order.NetUid}`);
    }

    public IsTempOrderExist() {
        if (this.props.Wizard.tempOrderChecked) {
            if (this.props.Wizard.order)
                if (this.props.Wizard.order.Id)
                    return true;
        }

        return false;
    }

    render() {
        return (
            <div className="createOrced__container">

                {!this.props.Wizard.tempOrderChecked ?
                    <Loader /> :
                    <>
                        {
                            // !this.props.Wizard.isApproved ?
                            !this.IsTempOrderExist() ?
                                <div className="wizardModal__container">
                                    <div className="modal__title">Создать новий заказ?</div>
                                    <div className="onApproveOrderCreation__button sinBtn" onClick={() => this.OnApproveOrderCreation()}>Продолжить</div>
                                </div>
                                :
                                <div className="wizardModal__container">
                                    <div className="modal__title">Завершить незавершеный заказ?</div>
                                    <div className="onApproveOrderCreation__button" onClick={() => this.OnContinueTempOrderCommand()}>Дооформить</div>
                                    <div className="onApproveOrderCreation__button" onClick={() => this.OnApproveOrderCreation()}>Создать новый</div>
                                </div>
                            // : <Route path={`${(this.props as any).match.url}/:netid`} component={Wizard} />
                            // <Wizard />
                        }
                    </>
                }
            </div>
        )
    }
};
export default connect(state => ({
    Authentication: (state as any).authentication,
    Wizard: (state as any).sales.wizard
}), dispatch => bindActionCreators({
    ApproveOrderCreation,
    ClearWizard,
    GetCategories,
    CreateTempOrder,
    CheckForTempOrder,
    ProcessTempOrder,
    GetDeliveryPrice,
    GetTempOrder
}, dispatch))
    (CreateOrder);
