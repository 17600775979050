import * as React from "react";

export interface ILoginFormInput {
    isPassword?: boolean;
    placeholder: string;
    name?: string;
    onChangeInput?: any;
    value?: any;
    readOnly?: boolean;
}

export const LoginFormInput = (props: ILoginFormInput) => {

    const onValueChangeCommand = (event: any) => {
        if (props.onChangeInput)
            props.onChangeInput(event);
    }

    return (
        <div className="form_control">
            <input
                disabled={props.readOnly}
                autoComplete={'off'}
                name={props.name}
                type={props.isPassword ? "password" : "text"}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event) => (onValueChangeCommand(event))}
            />
        </div>
    );
};