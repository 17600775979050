import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ConstructorPriceItem, ConstructorPrices } from '../../../../business.entities/constructor.prices';
import { Validator } from '../../../../helpers/validator.helper';
import { UpdateConstructorPrice, ChangeConstructorPriceItem, OpenPrices } from "../../../../actions/cabinet/personal.info.actions";
import { List } from 'linqts';
import { setFlagsFromString } from 'v8';
import { error as ErrorNotificationTrigger } from "react-notification-system-redux";

export interface IConstructorPriceFormProps {
    ConstructorPrices: ConstructorPrices
    ChangeConstructorPriceItem(index, value);
    UpdateConstructorPrice(prices)
    ErrorNotificationTrigger(notification);
    OpenPrices(value);
}

function mapStateToProps(state) {
    return {
        ConstructorPrices: (state as any).cabinet.userInfo.ConstructorPrices
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ChangeConstructorPriceItem,
        UpdateConstructorPrice,
        ErrorNotificationTrigger,
        OpenPrices
    }, dispatch);
}

export const ConstructorPriceForm = (props: IConstructorPriceFormProps) => {

    const OnUpdatePrices = () => {
        if (new List<ConstructorPriceItem>(props.ConstructorPrices.PriceItems).Any(c => Validator.IsPriceMatchToPattern(c.Price) == false))
            props.ErrorNotificationTrigger({
                title: 'Ошибка',
                message: 'Проверте правильность цен',
                position: 'br',
                autoDismiss: 5,
            });
        else {
            props.UpdateConstructorPrice(Object.assign({}, props.ConstructorPrices, {
                Prices: JSON.stringify(props.ConstructorPrices.PriceItems),
                PriceItems: []
            } as ConstructorPrices));
        }
    }

    const GetRCCForLexicon = (lexiconId: number) => {
        switch (lexiconId) {
            case 11:
                return 'РЦЦ составляет 199 грн';
            case 67:
                return 'РЦЦ составляет 299 грн';
            case 15:
                return 'РЦЦ составляет 95 грн';
            case 16:
                return 'РЦЦ составляет 39 грн';
            case 70:
                return 'РЦЦ составляет 174 грн';
            case 72:
                return 'РЦЦ составляет 84 грн';
            case 264:
                return 'РЦЦ составляет 135 грн';
            case 17:
                return 'РЦЦ составляет 39 грн';
            case 71:
                return 'РЦЦ составляет 74 грн';
            case 378:
                return 'РЦЦ составляет 399 грн';
            case 387:
                return 'РЦЦ составляет 249 грн';
            default:
                return '';
        }
    }

    return (
        <div className="default_form_component">
            {
                props.ConstructorPrices.PriceItems &&
                props.ConstructorPrices.PriceItems.length > 0 &&
                props.ConstructorPrices.PriceItems.map((item: ConstructorPriceItem, index) =>
                    <>
                        {
                            index === 4 &&
                            <div className="headerLabel const__PF" key={66613666}>Товары дополнительного предложения</div>
                        }
                        <div className="defaultFormControl cPrice__FORM" key={item.LexiconId}>
                            <div className="defaultFormControl__label">{item.LexiconName}</div>
                            <div className="defaultFormControl__value">
                                <input type="number" value={item.Price} onChange={((event) => props.ChangeConstructorPriceItem(event.target.value, index))} />
                            </div>
                            <div className="cPrice__value">
                                {GetRCCForLexicon(item.LexiconId)}
                            </div>
                        </div>
                    </>
                )
            }
            <br />
            <div className="defaultFormControl_WR">
                <div className="defaultFormControl minimal backButton">
                    <div className="defaultFormButton" onClick={() => props.OpenPrices(false)}>Вернуться назад</div>
                </div>

                <div className="defaultFormControl minimal">
                    <div className="defaultFormButton" onClick={OnUpdatePrices} >Сохранить изменения</div>
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ConstructorPriceForm);
