import * as React from "react";
import { Order } from "../../../../../business.entities/order";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderCardState } from "../../../../../states/sales/order.card.state";
import { ValidateForStatus, ChangeGlobalComment, AddOrderStatus } from "../../../../../actions/sales/order.card.actions";
import { List } from "linqts";
import { OrderStatus } from "../../../../../business.entities/order.status";
import { OrderStatusEnum } from "../../../../../helpers/enums/order.status.enum";
import PermissionChecker from '../../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../../helpers/identity.roles';
import { DeliveryType } from "../../../../../helpers/enums/delivery.type";
import { Loader } from "../../../../shared/loader";

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ValidateForStatus,
    ChangeGlobalComment,
    AddOrderStatus
}, dispatch);

const OrderCardStatuses = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const order: Order = props.OrderCard.order;

    const OnValidateForStatusCommand = (status) => {
        props.ValidateForStatus(order.NetUid, status);
    }

    const OnSaveStatusForOrderCommand = () => {
        if (!orderCard.fetchInProgress) {
            const selectedStatus = new List(orderCard.finishStatus).FirstOrDefault(c => c.isSelected);

            if (orderCard.order.OrderStatus.length > 0) {
                if (orderCard.order.OrderStatus[0].Status != selectedStatus.id) {
                    props.AddOrderStatus({ Status: selectedStatus.id, OrderId: orderCard.order.Id });
                }
            } else {
                props.AddOrderStatus({ Status: selectedStatus.id, OrderId: orderCard.order.Id });
            }
        }
    }

    return (
        <div className="statusRVContainer">
            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Изменить комментарий</div>
                <div className="defaultFormControlTextatea__value">
                    <textarea name="global_comment"
                        value={order.GlobalComment}
                        onChange={(event) => props.ChangeGlobalComment(event.target.value)} />
                    {
                        orderCard.fetchInProgress ?
                            <Loader /> : null
                    }
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Статус</div>
                <div className="deliveryCheckWrapper__container">

                    {orderCard.finishStatus.map((status, index) => {
                        if (status.id === OrderStatusEnum.Recived) {
                            if (order.DeliveryType === DeliveryType.Self)
                                return (<PermissionChecker
                                    ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                    <label key={index}
                                        className={`bHBCheckbox ${status.isSelected ? 'isSelected' : ''}`}
                                        onClick={() => OnValidateForStatusCommand(status.id)}>
                                        <span>{status.name}</span>
                                    </label>
                                </PermissionChecker>)
                        } else
                            return (
                                <label key={index}
                                    className={`bHBCheckbox ${status.isSelected ? 'isSelected' : ''}`}
                                    onClick={() => OnValidateForStatusCommand(status.id)}>
                                    <span>{status.name}</span>
                                </label>
                            )
                    }
                    )}



                </div>

                {
                    orderCard.isValidated ?
                        <div className="defaultFormControl">
                            <div className="defaultFormButton" onClick={OnSaveStatusForOrderCommand}>Сохранить</div>
                        </div> :
                        null
                }

            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderCardStatuses);