import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../reducers";
import { Route } from "react-router";
import { GetOrderProductStepComponent, GetOrderProductStepName, OrderProductStep } from "../../../../../helpers/enums/order.product.steps";
import { WizardState } from "../../../../../states/sales/wizard.state";
import {
    SetNextSubStep, SetOrderProduct, CancelCurrentOrderProduct, UpdateOrderProductServer,
    OpenFinishMenu, SaveOrderProduct, CalculateProductTotal
} from '../../../../../actions/sales/wizard.actions';
import { bindActionCreators } from "redux";
import { OnGoToPrevStepCommand, OnGoToNextStepCommand } from "../../../../../helpers/wizard.substep.helper";
import { ProductTypeEnum } from "../../../../../helpers/enums/product.type.enum";
import { List } from "linqts";
import { Order } from "../../../../../business.entities/order";
import { OrderProduct } from "../../../../../business.entities/order.product";
import { OrderTypeEnum } from "../../../../../helpers/enums/order.type.enum";

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SetOrderProduct,
    UpdateOrderProductServer,
    OpenFinishMenu,
    SaveOrderProduct,
    CalculateProductTotal
}, dispatch);

const PopupWrapper = (props: any) => {

    const GenerateTotalLabel = () => {

        let firstPart = (props.Wizard as WizardState).currentOrderProduct.PartnerPrice;

        let secondPart = (props.Wizard as WizardState).currentOrderProduct.Price;

        if ((props.Wizard as WizardState).currentOrderProduct.PartnerTotal)
            firstPart = (props.Wizard as WizardState).currentOrderProduct.PartnerTotal;

        if ((props.Wizard as WizardState).currentOrderProduct.Total)
            secondPart = (props.Wizard as WizardState).currentOrderProduct.Total;

        return `${firstPart} | ${secondPart}`;
    }

    const IsLastStep = () => {
        if ((props.Wizard as WizardState).orderProductStepPattern[(props.Wizard as WizardState).orderProductStepPattern.length - 1]
            === (props.Wizard as WizardState).currentStepEnum) {
            return true;
        }

        return false;
    }

    const OnNextButtonCommand = () => {
        if (!props.Wizard.serverActionInProcess)
            if ((props.Wizard as WizardState).orderProductStepPattern[(props.Wizard as WizardState).orderProductStepPattern.length - 1]
                === (props.Wizard as WizardState).currentStepEnum) {
                if (!props.Wizard.serverActionInProcess) {
                    !props.Wizard.currentOrderProduct.Id ?
                        props.SaveOrderProduct(props.Wizard.currentOrderProduct) :
                        props.UpdateOrderProductServer(props.Wizard.currentOrderProduct);
                }
            } else {
                if (IsNextStepAllowed()) {

                    props.Wizard.currentStepEnum === OrderProductStep.Price
                        && props.CalculateProductTotal(props.Wizard.currentOrderProduct);

                    OnGoToNextStepCommand(props);
                }
            }
    }

    const IsValidStepInfo = (step: OrderProductStep) => {
        return true;
    }

    const IsNextStepAllowed = () => {
        if (props.Wizard.serverActionInProcess)
            return false;

        if ((props.Wizard as WizardState).currentStepEnum === OrderProductStep.ProductColor) {
            if (!(props.Wizard as WizardState).currentOrderProduct.ColorId
                || !(props.Wizard as WizardState).currentOrderProduct.Color)
                return false;
        }

        if ((props.Wizard as WizardState).currentStepEnum === OrderProductStep.AdditionalOption) {
            if (!props.Wizard.currentProduct.OptionCategoryTree || props.Wizard.currentProduct.OptionCategoryTree.length === 0)
                return true;

            if (!(props.Wizard as WizardState).currentOrderProduct.OrderProductAdditionalOption)
                return false;

            let lengthes = 0;
            props.Wizard.currentProduct.OptionCategoryTree.map(c => lengthes += c.SubCategories.length);

            if (lengthes !== (props.Wizard as WizardState).currentOrderProduct.OrderProductAdditionalOption.filter(c => !c.Deleted).length)
                return false;
        }

        if ((props.Wizard as WizardState).currentStepEnum === OrderProductStep.BaseProduct) {
            if (!(props.Wizard as WizardState).currentOrderProduct.BaseProductId
                || !(props.Wizard as WizardState).currentOrderProduct.BaseProduct)
                return false;
        }

        if ((props.Wizard as WizardState).currentStepEnum === OrderProductStep.BaseProductColor) {
            if (!(props.Wizard as WizardState).currentOrderProduct.BaseColorId
                || !(props.Wizard as WizardState).currentOrderProduct.Id)
                return false;
        }

        return true;
    }

    const AnySimilar = () => {

        if (props.Wizard.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Print
            || props.Wizard.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction)
            return false;

        if ((props.Wizard.order as Order).OrderProducts && (props.Wizard.order as Order).OrderProducts.length === 0)
            return false;

        return new List((props.Wizard.order as Order).OrderProducts)
            .Where(c => c.ProductId === props.Wizard.currentOrderProduct.ProductId
                && c.ColorId === props.Wizard.currentOrderProduct.ColorId
                && c.BaseProductId === props.Wizard.currentOrderProduct.BaseProductId
                && c.BaseColorId === props.Wizard.currentOrderProduct.BaseColorId
                && c.Id !== props.Wizard.currentOrderProduct.Id)
            .Any();
    }

    return (
        <div className="productSelect__modalWrapperContainer" >
            <div className="productSelect__modalContainer">
                <div className="closeModalIcon__control" onClick={() => props.CancelCurrentOrderProduct()} />

                {AnySimilar() ?
                    <div className="notErroContainer">
                        Подобный товар уже добавлен. При сохранении товары будут соединены.
                    </div> : null
                }

                <div className="productSelect__modalHeader">
                    <div className="brandContainer">
                        <div className="brandWrapperContainer2">
                            <div className="brand">
                                {/* Бренд */}
                                {(props.Wizard as WizardState).currentOrderProduct.Product.Category.Name}
                            </div>
                            <div className="model">
                                {/* Модель */}
                                {(props.Wizard as WizardState).currentOrderProduct.Product.SubCategory.Name}
                            </div>
                            <div className="lexiconContainer">
                                <div className="lexiconWrapper">
                                    <div className="lexiconItem">
                                        <div className="lexLabel">{'Наименование:'}</div>

                                        <div className="lexValue">
                                            <span>{(props.Wizard as WizardState).currentOrderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                        </div>
                                        {(props.Wizard as WizardState).currentOrderProduct.Color ?
                                            <div className="lexValueColor">
                                                <div className="colorBg" style={{ backgroundColor: (props.Wizard as WizardState).currentOrderProduct.Color.Code === 'clear' ? '' : (props.Wizard as WizardState).currentOrderProduct.Color.Code }} />
                                                {/* Товар цвет*/}
                                                <div className="colorBgLabel">
                                                    ({(props.Wizard as WizardState).currentOrderProduct.Color.Name})
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    {(props.Wizard as WizardState).currentOrderProduct.BaseProduct ?
                                        <div className="lexiconItem">
                                            <div className="lexLabel">{'Основа'}</div>

                                            <div className="lexValue">
                                                <span>{(props.Wizard as WizardState).currentOrderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                            </div>
                                            {(props.Wizard as WizardState).currentOrderProduct.BaseColor ?
                                                <div className="lexValueColor">
                                                    <div className="colorBg" style={{ backgroundColor: (props.Wizard as WizardState).currentOrderProduct.BaseColor.Code === 'clear' ? '' : (props.Wizard as WizardState).currentOrderProduct.BaseColor.Code }} />
                                                    {/* Товар цвет*/}
                                                    <div className="colorBgLabel">
                                                        ({(props.Wizard as WizardState).currentOrderProduct.BaseColor.Name})
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            </div>

                            <div className="qtyContainer">
                                <div className="hide2">
                                    {/* Количество */}
                                </div>

                                <div className="hide2">
                                    {/* Тотал */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="informationContainer">

                        <div className="dopContainer">
                            <div className="priceWrapper">
                                <div className="priceItem partnerPrice">
                                    <div className="value">
                                        <span className="pPrice_value">
                                            {`${(props.Wizard as WizardState).currentOrderProduct.PartnerPrice} | ${(props.Wizard as WizardState).currentOrderProduct.Price}`}
                                        </span>
                                        {/* <span className="price_value">|{(props.Wizard as WizardState).currentOrderProduct.Price}</span> */}
                                    </div>
                                    <div className="label">{'Цена'}</div>
                                </div>

                                {
                                    !(props.Wizard as WizardState).currentOrderProduct.Price || !(props.Wizard as WizardState).currentOrderProduct.Quantity ?
                                        <div className="priceItem ">
                                            <div className="value">{GenerateTotalLabel()}</div>
                                            <div className="label">{'Всего'} : {(props.Wizard as WizardState).currentOrderProduct.Quantity} шт.</div>
                                        </div>
                                        : <div className="priceItem ">
                                            <div className="value">{GenerateTotalLabel()}</div>
                                            <div className="label">{'Всего'} : {(props.Wizard as WizardState).currentOrderProduct.Quantity} шт.</div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="productSelect__modalContent">
                    <Route path={props.history.location.pathName}
                        component={props.Wizard.orderProductSubStep} />
                </div>
                <div className="productSelect__modalSteps">
                    <div className="productSelect__modalStepsWrapper">
                        {/* steps */}

                        {(props.Wizard as WizardState).orderProductStepPattern
                            && (props.Wizard as WizardState).orderProductStepPattern.map((step, index) =>
                                <div key={index}
                                    className={`stepItem ${step === (props.Wizard as WizardState).currentStepEnum ? 'isSelected' : ''} ${IsValidStepInfo(step) ? '' : 'isError'}`}>
                                    <div className="stepLabel">{GetOrderProductStepName(step)}</div>
                                </div>
                            )}
                    </div>
                </div>

                <div className="wrapperModalArrow__container">
                    <div className="arrowButton__control left" onClick={() => OnGoToPrevStepCommand(props)}>
                        <div className="arrowIcon__container" />
                    </div>

                    <div className={`arrowButton__control ${IsLastStep() ? 'last' : ''} ${IsNextStepAllowed() ? '' : 'isDisabled'}`}
                        onClick={() => OnNextButtonCommand()}>
                        <div className="arrowIcon__container" />
                    </div>
                </div>
            </div>

            <div className="closeModalButton__control" onClick={() => props.CancelCurrentOrderProduct()} />
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(PopupWrapper)