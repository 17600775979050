export enum StockStatusEnum {
    Available = 1,
    Partly,
    NotAvailable,
    Assembling
}

export const GetStockStatusName = (status: StockStatusEnum) => {
    switch (status) {
        case StockStatusEnum.Available:
            return 'В наличии';
        case StockStatusEnum.Partly:
            return 'Частично';
        case StockStatusEnum.NotAvailable:
            return 'Не в наличии';
        case StockStatusEnum.Assembling:
            return 'Отправлен на сборку';
        default:
            return '';
    }
}