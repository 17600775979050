export default {
    // Options.jsx
    items_per_page: '',
    jump_to: '',
    jump_to_confirm: '',
    page: 'стр.',
  
    // Pagination.jsx
    prev_page: 'Предыдущая',
    next_page: 'Следующая',
    prev_5: 'Назад 5',
    next_5: 'Вперёд 5',
    prev_3: 'Назад 3',
    next_3: 'Вперёд 3',
  };