import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CancelCurrentOrderProduct, SetNextSubStep, ChangeCurrentOrderProductPrice, CalculateProductTotal } from "../../../../../actions/sales/wizard.actions";
import { OnGoToPrevStepCommand, OnGoToNextStepCommand } from "../../../../../helpers/wizard.substep.helper";
import { PrintProductionType } from "../../../../../helpers/adding.product.patterns";

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    ChangeCurrentOrderProductPrice,
    CalculateProductTotal
}, dispatch);

const Price = (props: any) => {

    document.onkeydown = (event) => {
        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39 || (event as any).keyCode === 13) {
            event.preventDefault();

            props.CalculateProductTotal(props.Wizard.currentOrderProduct);
            OnGoToNextStepCommand(props);
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnPriceCahngeCommand = (value: number) => {
        props.ChangeCurrentOrderProductPrice(value)
    }

    return (
        <div className="priceContainer__content">
            <div className="priceContainer__contentLabel">Цена</div>
            <div className="priceContainer__contentWrapper">
                <div className="priceFormControl">
                    <div className="priceFormControl__label">Партнёрская</div>
                    <div className="priceFormControl__value">
                        <div className="imitation_input">{props.Wizard.currentOrderProduct.PartnerPrice}</div>
                    </div>
                </div>

                <div className="priceFormControl">
                    <div className="priceFormControl__label">Клиентская</div>
                    <div className="priceFormControl__value clientPrice">
                        <input type="number"
                            defaultValue={props.Wizard.currentOrderProduct.Price}
                            min={props.Wizard.currentOrderProduct.PartnerPrice}
                            onChange={event => OnPriceCahngeCommand((event as any).target.value)}
                            autoFocus={true}
                        />
                    </div>
                </div>

                <div className="dopDescription__container">
                    Поле для ввода цены отправки, заполняется в случае, когда необходимая цена отправки отличается от личной цены (сума наложеного платежа Вашему клиенту)
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Price)