import Product from "../../business.entities/product";
import { OrderProduct } from "../../business.entities/order.product";

export const GET_ORDER_FOR_ORDER_CARD = '[API] ORDER_CARD.GET_ORDER_FOR_ORDER_CARD';
export interface IGetOrderForOrderCard { type: '[API] ORDER_CARD.GET_ORDER_FOR_ORDER_CARD', payload: any }
export const GetOrderForOrderCard = (value) =>
    <IGetOrderForOrderCard>{ type: GET_ORDER_FOR_ORDER_CARD, payload: value };


export const SET_ORDER_FOR_ORDER_CARD = 'ORDER_CARD.SET_ORDER_FOR_ORDER_CARD';
export interface ISetOrderForOrderCard { type: 'ORDER_CARD.SET_ORDER_FOR_ORDER_CARD', payload: any }
export const SetOrderForOrderCard = (value) =>
    <ISetOrderForOrderCard>{ type: SET_ORDER_FOR_ORDER_CARD, payload: value };

export const UPDATE_ORDER = '[API] ORDER_CARD.UPDATE_ORDER';
export interface IUpdateOrder { type: '[API] ORDER_CARD.UPDATE_ORDER', payload: any }
export const UpdateOrder = (value) =>
    <IUpdateOrder>{ type: UPDATE_ORDER, payload: value };

export const UPDATE_ORDER_SILENT = '[API] ORDER_CARD.UPDATE_ORDER_SILENT';
export interface IUpdateOrderSilent { type: '[API] ORDER_CARD.UPDATE_ORDER_SILENT', payload: any }
export const UpdateOrderSilent = (value) =>
    <IUpdateOrderSilent>{ type: UPDATE_ORDER_SILENT, payload: value };

export const GET_CLIENTS_FOR_PARTNER = '[API] ORDER_CARD.GET_CLIENTS_FOR_PARTNER';
export interface IGetClientsForPartner { type: '[API] ORDER_CARD.GET_CLIENTS_FOR_PARTNER', payload: any }
export const GetClientsForPartner = (value, uid) =>
    <IGetClientsForPartner>{ type: GET_CLIENTS_FOR_PARTNER, payload: { value, uid } };

export const SET_CLIENTS_FOR_PARTNER = 'ORDER_CARD.SET_CLIENTS_FOR_PARTNER';
export interface ISetClientsForPartner { type: 'ORDER_CARD.SET_CLIENTS_FOR_PARTNER', payload: any }
export const SetClientsForPartner = (value) =>
    <ISetClientsForPartner>{ type: SET_CLIENTS_FOR_PARTNER, payload: value };

export const SET_CLIENT_PHONE = 'ORDER_CARD.SET_CLIENT_PHONE';
export interface ISetClientPhone { type: 'ORDER_CARD.SET_CLIENT_PHONE', payload: any }
export const SetClientPhone = (value, mode) =>
    <ISetClientPhone>{ type: SET_CLIENT_PHONE, payload: { value, mode } };

export const SELECT_CLIENT = 'ORDER_CARD.SELECT_CLIENT';
export interface ISelectClient { type: 'ORDER_CARD.SELECT_CLIENT', payload: any }
export const SelectClient = (value) =>
    <ISelectClient>{ type: SELECT_CLIENT, payload: value };

export const RESET_CLIENT_RV = 'ORDER_CARD.RESET_CLIENT_RV';
export interface IResetClientRv { type: 'ORDER_CARD.RESET_CLIENT_RV', payload: any }
export const ResetClientRv = () =>
    <IResetClientRv>{ type: RESET_CLIENT_RV };

export const RESET_CLIENT = 'ORDER_CARD.RESET_CLIENT';
export interface IResetClient { type: 'ORDER_CARD.RESET_CLIENT', payload: any }
export const ResetClient = () =>
    <IResetClient>{ type: RESET_CLIENT };

export const CHANGE_NEW_CLIENT_NAME = 'ORDER_CARD.CHANGE_NEW_CLIENT_NAME';
export interface IChangeNewClientName { type: 'ORDER_CARD.CHANGE_NEW_CLIENT_NAME', payload: any }
export const ChangeNewClientName = (value) =>
    <IChangeNewClientName>{ type: CHANGE_NEW_CLIENT_NAME, payload: value };

export const CHANGE_NEW_CLIENT_EMAIL = 'ORDER_CARD.CHANGE_NEW_CLIENT_EMAIL';
export interface IChangeNewClientEmail { type: 'ORDER_CARD.CHANGE_NEW_CLIENT_EMAIL', payload: any }
export const ChangeNewClientEmail = (value) =>
    <IChangeNewClientEmail>{ type: CHANGE_NEW_CLIENT_EMAIL, payload: value };

export const SET_CURRENT_RECIPIENT = 'ORDER_CARD.SET_CURRENT_RECIPIENT';
export interface ISetCurrentRecipient { type: 'ORDER_CARD.SET_CURRENT_RECIPIENT', payload: any }
export const SetCurrentRecipient = (name, phone) =>
    <ISetCurrentRecipient>{ type: SET_CURRENT_RECIPIENT, payload: { name, phone } };

export const CHANGE_RECIPIENT = 'ORDER_CARD.CHANGE_RECIPIENT';
export interface IChangeRecipient { type: 'ORDER_CARD.CHANGE_RECIPIENT', payload: any }
export const ChangeRecipient = (value) =>
    <IChangeRecipient>{ type: CHANGE_RECIPIENT, payload: value };

export const CHANGE_RECIPIENT_NAME = 'ORDER_CARD.CHANGE_RECIPIENT_NAME';
export interface IChangeRecipientName { type: 'ORDER_CARD.CHANGE_RECIPIENT_NAME', payload: any }
export const ChangeRecipientName = (value) =>
    <IChangeRecipientName>{ type: CHANGE_RECIPIENT_NAME, payload: value };

export const CHANGE_RECIPIENT_PHONE = 'ORDER_CARD.CHANGE_RECIPIENT_PHONE';
export interface IChangeRecipientPhone { type: 'ORDER_CARD.CHANGE_RECIPIENT_PHONE', payload: any }
export const ChangeRecipientPhone = (value) =>
    <IChangeRecipientPhone>{ type: CHANGE_RECIPIENT_PHONE, payload: value };

export const SET_CURRENT_DELIVERY_INFO = 'ORDER_CARD.SET_CURRENT_DELIVERY_INFO';
export interface ISetCurrentDeliveryInfo { type: 'ORDER_CARD.SET_CURRENT_DELIVERY_INFO', payload: any }
export const SetCurrentDeliveryInfo = (value) =>
    <ISetCurrentDeliveryInfo>{ type: SET_CURRENT_DELIVERY_INFO, payload: value };

export const SELECT_DELIERY_TYPE = 'ORDER_CARD.SELECT_DELIERY_TYPE';
export interface ISelectDeliveryType { type: 'ORDER_CARD.SELECT_DELIERY_TYPE', payload: any }
export const SelectDeliveryType = (value) =>
    <ISelectDeliveryType>{ type: SELECT_DELIERY_TYPE, payload: value };

//UKRPOSHTA
export const GET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.GET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface IGetRegionUkrPoshtaForAutocomplete { type: 'ORDER_CARD.GET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const GetRegionUkrPoshtaForAutocomplete = (value) =>
    <IGetRegionUkrPoshtaForAutocomplete>({ type: GET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const GET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.GET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface IGetAreaUkrPoshtaForAutocomplete { type: 'ORDER_CARD.GET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const GetAreaUkrPoshtaForAutocomplete = (value) =>
    <IGetAreaUkrPoshtaForAutocomplete>({ type: GET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const GET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.GET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface IGetCityUkrPoshtaForAutocomplete { type: 'ORDER_CARD.GET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const GetCityUkrPoshtaForAutocomplete = (value) =>
    <IGetCityUkrPoshtaForAutocomplete>({ type: GET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SELECT_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISelectRegionUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SELECT_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SelectRegionUkrPoshtaForAutocomplete = (value) =>
    <ISelectRegionUkrPoshtaForAutocomplete>({ type: SELECT_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SELECT_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISelectAreaUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SELECT_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SelectAreaUkrPoshtaForAutocomplete = (value) =>
    <ISelectAreaUkrPoshtaForAutocomplete>({ type: SELECT_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SELECT_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISelectCityUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SELECT_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SelectCityUkrPoshtaForAutocomplete = (value) =>
    <ISelectCityUkrPoshtaForAutocomplete>({ type: SELECT_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISetRegionUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SetRegionUkrPoshtaForAutocomplete = (value) =>
    <ISetRegionUkrPoshtaForAutocomplete>({ type: SET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISetAreaUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SetAreaUkrPoshtaForAutocomplete = (value) =>
    <ISetAreaUkrPoshtaForAutocomplete>({ type: SET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

export const SET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE = 'ORDER_CARD.SET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE';
export interface ISetCityUkrPoshtaForAutocomplete { type: 'ORDER_CARD.SET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE', payload: any }
export const SetCityUkrPoshtaForAutocomplete = (value) =>
    <ISetCityUkrPoshtaForAutocomplete>({ type: SET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE, payload: value });

//NOVAPOSHTA
export const GET_CITIES_FOR_AUTOCOMPLETE = 'ORDER_CARD.GET_CITIES_FOR_AUTOCOMPLETE';
export interface IGetCitiesForAutocomplete { type: 'ORDER_CARD.GET_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const GetCitiesForAutocomplete = (value) =>
    <IGetCitiesForAutocomplete>({ type: GET_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const GET_WEREHOUSES_FOR_AUTOCOMPLETE = 'ORDER_CARD.GET_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface IGetWerehousesForAutocomplete { type: 'ORDER_CARD.GET_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const GetWerehousesForAutocomplete = (value) =>
    <IGetWerehousesForAutocomplete>({ type: GET_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const SET_CITIES_FOR_AUTOCOMPLETE = 'ORDER_CARD.SET_CITIES_FOR_AUTOCOMPLETE';
export interface ISetCitiesForAutocomplete { type: 'ORDER_CARD.SET_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const SetCitiesForAutocomplete = (value) =>
    <ISetCitiesForAutocomplete>({ type: SET_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const SET_WEREHOUSES_FOR_AUTOCOMPLETE = 'ORDER_CARD.SET_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface ISetWerehousesForAutocomplete { type: 'ORDER_CARD.SET_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const SetWerehousesForAutocomplete = (value) =>
    <ISetWerehousesForAutocomplete>({ type: SET_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_CITIES_FOR_AUTOCOMPLETE = 'ORDER_CARD.SELECT_CITIES_FOR_AUTOCOMPLETE';
export interface ISelectCitiesForAutocomplete { type: 'ORDER_CARD.SELECT_CITIES_FOR_AUTOCOMPLETE', payload: any }
export const SelectCitiesForAutocomplete = (value) =>
    <ISelectCitiesForAutocomplete>({ type: SELECT_CITIES_FOR_AUTOCOMPLETE, payload: value });

export const SELECT_WEREHOUSES_FOR_AUTOCOMPLETE = 'ORDER_CARD.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE';
export interface ISelectWerehousesForAutocomplete { type: 'ORDER_CARD.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE', payload: any }
export const SelectWerehousesForAutocomplete = (value) =>
    <ISelectWerehousesForAutocomplete>({ type: SELECT_WEREHOUSES_FOR_AUTOCOMPLETE, payload: value });

export const CHANGE_UKR_CITY_NAME = 'ORDER_CARD.CHANGE_UKR_CITY_NAME';
export interface IChangeUkrCityName { type: 'ORDER_CARD.CHANGE_UKR_CITY_NAME', payload: any }
export const ChangeUkrCityName = (value) =>
    <IChangeUkrCityName>({ type: CHANGE_UKR_CITY_NAME, payload: value });

export const CHANGE_UKR_STREET_NAME = 'ORDER_CARD.CHANGE_UKR_STREET_NAME';
export interface IChangeUkrStreetName { type: 'ORDER_CARD.CHANGE_UKR_STREET_NAME', payload: any }
export const ChangeUkrStreetName = (value) =>
    <IChangeUkrStreetName>({ type: CHANGE_UKR_STREET_NAME, payload: value });

export const CHANGE_UKR_BUILDING_NUM = 'ORDER_CARD.CHANGE_UKR_BUILDING_NUM';
export interface IChangeUkrBuildingNum { type: 'ORDER_CARD.CHANGE_UKR_BUILDING_NUM', payload: any }
export const ChangeUkrBuildingNum = (value) =>
    <IChangeUkrBuildingNum>({ type: CHANGE_UKR_BUILDING_NUM, payload: value });

export const CHANGE_UKR_FLAT_NUM = 'ORDER_CARD.CHANGE_UKR_FLAT_NUM';
export interface IChangeUkrFlatNum { type: 'ORDER_CARD.CHANGE_UKR_FLAT_NUM', payload: any }
export const ChangeUkrFlatNum = (value) =>
    <IChangeUkrFlatNum>({ type: CHANGE_UKR_FLAT_NUM, payload: value });

export const CHANGE_UKR_ZIP = 'ORDER_CARD.CHANGE_UKR_ZIP';
export interface IChangeUkrZip { type: 'ORDER_CARD.CHANGE_UKR_ZIP', payload: any }
export const ChangeUkrZip = (value) =>
    <IChangeUkrZip>({ type: CHANGE_UKR_ZIP, payload: value });

export const SELECT_ORDER_PRODUCT_FOR_RV = 'ORDER_CARD.SELECT_ORDER_PRODUCT_FOR_RV';
export interface ISelectOrderProductForRv { type: 'ORDER_CARD.SELECT_ORDER_PRODUCT_FOR_RV', payload: any }
export const SelectOrderProductForRv = (orderProductId) =>
    <ISelectOrderProductForRv>({ type: SELECT_ORDER_PRODUCT_FOR_RV, payload: orderProductId });

export const UPDATE_ORDER_PRODUCT = '[API] ORDER_CARD.UPDATE_ORDER_PRODUCT';
export interface IUpdateOrderProduct { type: '[API] ORDER_CARD.UPDATE_ORDER_PRODUCT', payload: any }
export const UpdateOrderProduct = (orderProduct) =>
    <IUpdateOrderProduct>{ type: UPDATE_ORDER_PRODUCT, payload: orderProduct };

export const SET_UPDATED_ORDER_PRODUCT = 'ORDER_CARD.SET_UPDATED_ORDER_PRODUCT';
export interface ISetUpdatedOrderProduct { type: 'ORDER_CARD.SET_UPDATED_ORDER_PRODUCT', payload: any }
export const SetUpdatedOrderProduct = (orderProduct, index) =>
    <ISetUpdatedOrderProduct>{ type: SET_UPDATED_ORDER_PRODUCT, payload: { orderProduct, index } };

export const UPLOAD_FILES_FOR_ORDER_PRODUCT = '[API] ORDER_CARD.UPLOAD_FILES_FOR_ORDER_PRODUCT';
export interface IUploadFilesForOrderProduct { type: '[API] ORDER_CARD.UPLOAD_FILES_FOR_ORDER_PRODUCT', payload: any }
export const UploadFilesForOrderProduct = (formData, orderProductUid, orderUid, fileType) =>
    <IUploadFilesForOrderProduct>{ type: UPLOAD_FILES_FOR_ORDER_PRODUCT, payload: { formData, orderProductUid, orderUid, fileType } };

export const CHANGE_PRINT_COMMENT = 'ORDER_CARD.CHANGE_PRINT_COMMENT';
export interface IChangePrintComment { type: 'ORDER_CARD.CHANGE_PRINT_COMMENT', payload: any }
export const ChangePrintComment = (comment, index, id) =>
    <IChangePrintComment>{ type: CHANGE_PRINT_COMMENT, payload: { comment, index, id } };

export const CHANGE_USER_IMAGE_COMMENT = 'ORDER_CARD.CHANGE_USER_IMAGE_COMMENT';
export interface IChangeUserImageComment { type: 'ORDER_CARD.CHANGE_USER_IMAGE_COMMENT', payload: any }
export const ChangeUserImageComment = (comment, index, id) =>
    <IChangeUserImageComment>{ type: CHANGE_USER_IMAGE_COMMENT, payload: { comment, index, id } };

export const SET_USER_IMAGE_COMMENT = 'ORDER_CARD.SET_USER_IMAGE_COMMENT';
export interface ISetUserImageComment { type: 'ORDER_CARD.SET_USER_IMAGE_COMMENT', payload: any }
export const SetUserImageComment = (comment) =>
    <ISetUserImageComment>{ type: SET_USER_IMAGE_COMMENT, payload: comment };

export const CHANGE_PRODUCTION_OPERATOR_COMMENT = 'ORDER_CARD.CHANGE_PRODUCTION_OPERATOR_COMMENT';
export interface IChangeProductionOperatorComment { type: 'ORDER_CARD.CHANGE_PRODUCTION_OPERATOR_COMMENT', payload: any }
export const ChangeProductionOperatorComment = (comment) =>
    <IChangeProductionOperatorComment>{ type: CHANGE_PRODUCTION_OPERATOR_COMMENT, payload: comment };

export const CHANGE_PRODUCTION_COMMENT = 'ORDER_CARD.CHANGE_PRODUCTION_COMMENT';
export interface IChangeProductionComment { type: 'ORDER_CARD.CHANGE_PRODUCTION_COMMENT', payload: any }
export const ChangeProductionComment = (comment) =>
    <IChangeProductionComment>{ type: CHANGE_PRODUCTION_COMMENT, payload: comment };

export const SET_PRODUCT_UPDATE_MODE = 'ORDER_CARD.SET_PRODUCT_UPDATE_MODE';
export interface ISetProductUpdateMode { type: 'ORDER_CARD.SET_PRODUCT_UPDATE_MODE', payload: any }
export const SetProductUpdateMode = (isActive, orderProducts) =>
    <ISetProductUpdateMode>{ type: SET_PRODUCT_UPDATE_MODE, payload: { isActive, orderProducts } };

export const RESET_PRODUCT_UPDATE_MODE = 'ORDER_CARD.RESET_PRODUCT_UPDATE_MODE';
export interface IResetProductUpdateMode { type: 'ORDER_CARD.RESET_PRODUCT_UPDATE_MODE', payload: any }
export const ResetProductUpdateMode = () =>
    <IResetProductUpdateMode>{ type: RESET_PRODUCT_UPDATE_MODE };

export const GET_CATEGORIES = '[API] ORDER_CARD.GET_CATEGORIES';
export interface IGetCategories { type: '[API] ORDER_CARD.GET_CATEGORIES', payload: any }
export const GetCategories = (value: string) =>
    <IGetCategories>{ type: GET_CATEGORIES, payload: value };

export const SET_CATEGORIES = 'ORDER_CARD.SET_CATEGORIES';
export interface ISetCategories { type: 'ORDER_CARD.SET_CATEGORIES', payload: any }
export const SetCategories = value => <ISetCategories>({ type: SET_CATEGORIES, payload: value });

export const GET_SUBCATEGORIES = '[API] ORDER_CARD.GET_SUBCATEGORIES';
export interface IGetSubCategories { type: '[API] ORDER_CARD.GET_SUBCATEGORIES', payload: any }
export const GetSubCategories = (id, value) => <IGetSubCategories>{ type: GET_SUBCATEGORIES, payload: { id, value } };

export const SET_SUBCATEGORIES = 'ORDER_CARD.SET_SUBCATEGORIES';
export interface ISetSubCategories { type: 'ORDER_CARD.SET_SUBCATEGORIES', payload: any }
export const SetSubCategories = value => <ISetSubCategories>({ type: SET_SUBCATEGORIES, payload: value });

export const GET_PRODUCTS = '[API] ORDER_CARD.GET_PRODUCTS';
export interface IGetProducts { type: '[API] ORDER_CARD.GET_PRODUCTS', payload: any }
export const GetProducts = (categoryId, subCategoryId, orderNetId) =>
    <IGetProducts>{ type: GET_PRODUCTS, payload: { categoryId, subCategoryId, orderNetId } };

export const SET_PRODUCTS = 'ORDER_CARD.SET_PRODUCTS';
export interface ISetProducts { type: 'ORDER_CARD.SET_PRODUCTS', payload: any }
export const SetProducts = value => <ISetProducts>({ type: SET_PRODUCTS, payload: value });

export const GET_ADDITIONAL_SERVICE_PRODUCTS = '[API] WIZARD.GET_ADDITIONAL_SERVICE_PRODUCTS';
export interface IGetAdditionalServiceProducts { type: '[API] WIZARD.GET_ADDITIONAL_SERVICE_PRODUCTS' }
export const GetAdditionalServiceProducts = () => <IGetAdditionalServiceProducts>{ type: GET_ADDITIONAL_SERVICE_PRODUCTS };

export const SET_ADDITIONAL_SERVICE_PRODUCTS = 'WIZARD.SET_ADDITIONAL_SERVICE_PRODUCTS';
export interface ISetAdditionalServiceProducts { type: 'WIZARD.SET_ADDITIONAL_SERVICE_PRODUCTS', payload: any }
export const SetAdditionalServiceProducts = value => <ISetAdditionalServiceProducts>({ type: SET_ADDITIONAL_SERVICE_PRODUCTS, payload: value });

export const SET_EXPRESS_PRINT_PRODUCT = 'WIZARD.SET_EXPRESS_PRINT_PRODUCT';
export interface ISetExpressPrintProduct { type: 'WIZARD.SET_EXPRESS_PRINT_PRODUCT', payload: Product }
export const SetExpressPrintProduct = product => <ISetExpressPrintProduct>({ type: SET_EXPRESS_PRINT_PRODUCT, payload: product });

export const SET_EXTRA_PRINT_PRODUCT = 'WIZARD.SET_EXTRA_PRINT_PRODUCT';
export interface ISetExtraPrintProduct { type: 'WIZARD.SET_EXTRA_PRINT_PRODUCT', payload: Product }
export const SetExtraPrintProduct = product => <ISetExtraPrintProduct>({ type: SET_EXTRA_PRINT_PRODUCT, payload: product });

export const SELECT_CATEGORY = 'ORDER_CARD.SELECT_CATEGORY';
export interface ISelectCategory { type: 'ORDER_CARD.SELECT_CATEGORY', payload: any }
export const SelectCategory = value => <ISelectCategory>({ type: SELECT_CATEGORY, payload: value });

export const SELECT_SUBCATEGORY = 'ORDER_CARD.SELECT_SUBCATEGORY';
export interface ISelectSubCategory { type: 'ORDER_CARD.SELECT_SUBCATEGORY', payload: any }
export const SelectSubCategory = value => <ISelectSubCategory>({ type: SELECT_SUBCATEGORY, payload: value });

export const CHANGE_PRODUCT_SELECTION_MODE = 'ORDER_CARD.CHANGE_PRODUCT_SELECTION_MODE';
export interface IChangeProductSelectionMode { type: 'ORDER_CARD.CHANGE_PRODUCT_SELECTION_MODE', payload: boolean }
export const ChangeProductSelectionMode = (isProductMode: boolean) =>
    <IChangeProductSelectionMode>({ type: CHANGE_PRODUCT_SELECTION_MODE, payload: isProductMode });

export const REMOVE_ORDER_PRODUCT_SERVER = '[API] ORDER_CARD.REMOVE_ORDER_PRODUCT_SERVER';
export interface IRemoveOrderProductServer { type: '[API] ORDER_CARD.REMOVE_ORDER_PRODUCT_SERVER', payload: any }
export const RemoveOrderProductServer = (value) =>
    <IRemoveOrderProductServer>({ type: REMOVE_ORDER_PRODUCT_SERVER, payload: value });

export const REMOVE_ORDER_PRODUCT_CLIENT = 'ORDER_CARD.REMOVE_ORDER_PRODUCT_CLIENT';
export interface IRemoveOrderProductClient { type: 'ORDER_CARD.REMOVE_ORDER_PRODUCT_CLIENT', payload: any }
export const RemoveOrderProductClient = (index, partnerTotal, total, orderType, status) =>
    <IRemoveOrderProductClient>({ type: REMOVE_ORDER_PRODUCT_CLIENT, payload: { index, partnerTotal, total, orderType, status } });

export const SELECT_ORDER_PRODUCT = 'ORDER_CARD.SELECT_ORDER_PRODUCT';
export interface ISelectOrderProduct { type: 'ORDER_CARD.SELECT_ORDER_PRODUCT', payload: any }
export const SelectOrderProduct = index => <ISelectOrderProduct>({ type: SELECT_ORDER_PRODUCT, payload: index });

export const CONFIRM_ORDER_PRODUCT_SELECTION = 'ORDER_CARD.CONFIRM_ORDER_PRODUCT_SELECTION';
export interface IConfirmOrderProductSelection { type: 'ORDER_CARD.CONFIRM_ORDER_PRODUCT_SELECTION', payload: any }
export const ConfirmOrderProductSelection = orderProduct =>
    <IConfirmOrderProductSelection>({ type: CONFIRM_ORDER_PRODUCT_SELECTION, payload: orderProduct });

export const CANCEL_CURRENT_ORDER_PRODUCT = 'ORDER_CARD.CANCEL_CURRENT_ORDER_PRODUCT';
export interface ICancelCurrentOrderProduct { type: 'ORDER_CARD.CANCEL_CURRENT_ORDER_PRODUCT', payload: any }
export const CancelCurrentOrderProduct = () => <ICancelCurrentOrderProduct>({ type: CANCEL_CURRENT_ORDER_PRODUCT });

export const SELECT_CURRENT_PRODUCT = 'ORDER_CARD.SELECT_CURRENT_PRODUCT';
export interface ISelectCurrentProduct { type: 'ORDER_CARD.SELECT_CURRENT_PRODUCT', payload: any }
export const SelectCurrentProduct = value => <ISelectCurrentProduct>({ type: SELECT_CURRENT_PRODUCT, payload: value });

export const CONFIRM_PRODUCT_SELECT = 'ORDER_CARD.CONFIRM_PRODUCT_SELECT';
export interface IConfirmProductSelect { type: 'ORDER_CARD.CONFIRM_PRODUCT_SELECT', payload: any }
export const ConfirmProductSelect = () => <IConfirmProductSelect>({ type: CONFIRM_PRODUCT_SELECT });

export const SET_BASES_FOR_PRODUCT = 'ORDER_CARD.SET_BASES_FOR_PRODUCT';
export interface ISetBasesForProduct { type: 'ORDER_CARD.SET_BASES_FOR_PRODUCT', payload: Product[] }
export const SetBasesForProduct = (bases: Product[]) => <ISetBasesForProduct>({ type: SET_BASES_FOR_PRODUCT, payload: bases });

export const SET_PATTERN = 'ORDER_CARD.SET_PATTERN';
export interface ISetPattern { type: 'ORDER_CARD.SET_PATTERN', payload: any }
export const SetPattern = (pattern) => <ISetPattern>({ type: SET_PATTERN, payload: pattern });

export const SET_NEXT_SUBSTEP = 'ORDER_CARD.SET_NEXT_SUBSTEP';
export interface ISetNextSubStep { type: 'ORDER_CARD.SET_NEXT_SUBSTEP', payload: any }
export const SetNextSubStep = (stepEnum, component) => <ISetNextSubStep>({ type: SET_NEXT_SUBSTEP, payload: { stepEnum, component } });

export const GET_BASES_FOR_ORDERPRODUCT = '[API] ORDER_CARD.GET_BASES_FOR_ORDERPRODUCT';
export interface IGetBasesForOrderProduct { type: '[API] ORDER_CARD.GET_BASES_FOR_ORDERPRODUCT', payload: any }
export const GetBasesForOrderProduct = (categoryId, subCategoryId) =>
    <IGetBasesForOrderProduct>({ type: GET_BASES_FOR_ORDERPRODUCT, payload: { categoryId, subCategoryId } });

export const SELECT_CURRENT_BASE_COLOR = 'ORDER_CARD.SELECT_CURRENT_BASE_COLOR';
export interface ISelectCurrentBaseColor { type: 'ORDER_CARD.SELECT_CURRENT_BASE_COLOR', payload: any }
export const SelectCurrentBaseColor = value => <ISelectCurrentBaseColor>({ type: SELECT_CURRENT_BASE_COLOR, payload: value });

export const CONFIRM_BASE_COLOR_SELECT = 'ORDER_CARD.CONFIRM_BASE_COLOR_SELECT';
export interface IConfirmBaseColorSelect { type: 'ORDER_CARD.CONFIRM_BASE_COLOR_SELECT', payload: any }
export const ConfirmBaseColorSelect = () => <IConfirmBaseColorSelect>({ type: CONFIRM_BASE_COLOR_SELECT });

export const SELECT_CURRENT_BASE_PRODUCT = 'ORDER_CARD.SELECT_CURRENT_BASE_PRODUCT';
export interface ISelectCurrentBaseProduct { type: 'ORDER_CARD.SELECT_CURRENT_BASE_PRODUCT', payload: Product }
export const SelectCurrentBaseProduct = (value: Product) => <ISelectCurrentBaseProduct>({ type: SELECT_CURRENT_BASE_PRODUCT, payload: value });

export const CONFIRM_CURRENT_BASE_PRODUCT = 'ORDER_CARD.CONFIRM_CURRENT_BASE_PRODUCT';
export interface IConfirmBaseProductSelect { type: 'ORDER_CARD.CONFIRM_CURRENT_BASE_PRODUCT' }
export const ConfirmBaseProductSelect = () => <IConfirmBaseProductSelect>({ type: CONFIRM_CURRENT_BASE_PRODUCT });

export const SELECT_CURRENT_PRODUCT_COLOR = 'ORDER_CARD.SELECT_CURRENT_PRODUCT_COLOR';
export interface ISelectCurrentProductColor { type: 'ORDER_CARD.SELECT_CURRENT_PRODUCT_COLOR', payload: any }
export const SelectCurrentProductColor = value => <ISelectCurrentProductColor>({ type: SELECT_CURRENT_PRODUCT_COLOR, payload: value });

export const CONFIRM_PRODUCT_COLOR_SELECT = 'ORDER_CARD.CONFIRM_PRODUCT_COLOR_SELECT';
export interface IConfirmProductColorSelect { type: 'ORDER_CARD.CONFIRM_PRODUCT_COLOR_SELECT', payload: any }
export const ConfirmProductColorSelect = () => <IConfirmProductColorSelect>({ type: CONFIRM_PRODUCT_COLOR_SELECT });

export const CALCULATE_PRODUCT_TOTAL = '[API] ORDER_CARD.CALCULATE_PRODUCT_TOTAL';
export interface ICalculateProductTotal { type: '[API] ORDER_CARD.CALCULATE_PRODUCT_TOTAL', payload: any }
export const CalculateProductTotal = (value) =>
    <ICalculateProductTotal>({ type: CALCULATE_PRODUCT_TOTAL, payload: value });

export const SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT = 'ORDER_CARD.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT';
export interface ISetTotalForCurrentOrderProduct { type: 'ORDER_CARD.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT', payload: any }
export const SetTotalForCurrentOrderProduct = (value) =>
    <ISetTotalForCurrentOrderProduct>({ type: SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT, payload: value });

export const CHANGE_CURRENT_ORDER_PRODUCT_PRICE = 'ORDER_CARD.CHANGE_CURRENT_ORDER_PRODUCT_PRICE';
export interface IChangeCurrentOrderProductPrice { type: 'ORDER_CARD.CHANGE_CURRENT_ORDER_PRODUCT_PRICE', payload: any }
export const ChangeCurrentOrderProductPrice = value => <IChangeCurrentOrderProductPrice>({ type: CHANGE_CURRENT_ORDER_PRODUCT_PRICE, payload: value });

export const CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY = 'ORDER_CARD.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY';
export interface IChangeCurrentOrderProductQuantity { type: 'ORDER_CARD.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY', payload: any }
export const ChangeCurrentOrderProductQuantity = value => <IChangeCurrentOrderProductQuantity>({ type: CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY, payload: value });

export const SAVE_ORDER_PRODUCT = '[API] ORDER_CARD.SAVE_ORDER_PRODUCT';
export interface ISaveOrderProduct { type: '[API] ORDER_CARD.SAVE_ORDER_PRODUCT', payload: OrderProduct }
export const SaveOrderProduct = (orderProduct: OrderProduct) => <ISaveOrderProduct>({ type: SAVE_ORDER_PRODUCT, payload: orderProduct });

export const UPDATE_ORDER_PRODUCT_SERVER = '[API] ORDER_CARD.UPDATE_ORDER_PRODUCT_SERVER';
export interface IUpdateOrderProductServer { type: '[API] ORDER_CARD.UPDATE_ORDER_PRODUCT_SERVER', payload: any }
export const UpdateOrderProductServer = (value) =>
    <IUpdateOrderProductServer>({ type: UPDATE_ORDER_PRODUCT_SERVER, payload: value });

export const SET_ORDER_PRODUCT = 'ORDER_CARD.SET_ORDER_PRODUCT';
export interface ISetOrderProduct { type: 'ORDER_CARD.SET_ORDER_PRODUCT', payload: any }
export const SetOrderProduct = (orderProduct, partnerTotal, total, orderType, status) => <ISetOrderProduct>({ type: SET_ORDER_PRODUCT, payload: { orderProduct, partnerTotal, total, orderType, status } });

export const SET_ORDER_PRODUCT_BY_INDEX = 'ORDER_CARD.SET_ORDER_PRODUCT_BY_INDEX';
export interface ISetOrderProductByIndex { type: 'ORDER_CARD.SET_ORDER_PRODUCT_BY_INDEX', payload: any }
export const SetOrderProductByIndex = (orderProduct, index, partnerTotal, total, orderType, status) =>
    <ISetOrderProductByIndex>({ type: SET_ORDER_PRODUCT_BY_INDEX, payload: { orderProduct, index, partnerTotal, total, orderType, status } });

export const UPDATE_ORDER_PRODUCT_CLIENT = 'ORDER_CARD.UPDATE_ORDER_PRODUCT_CLIENT';
export interface IUpdateOrderProductClient { type: 'ORDER_CARD.UPDATE_ORDER_PRODUCT_CLIENT', payload: any }
export const UpdateOrderProductClient = (value, index, partnerTotal, total, status) =>
    <IUpdateOrderProductClient>({ type: UPDATE_ORDER_PRODUCT_CLIENT, payload: { value, index, partnerTotal, total, status } });

export const UPLOAD_USER_IMAGE = '[API] ORDER_CARD.UPLOAD_USER_IMAGE';
export interface IUploadUserImage { type: '[API] ORDER_CARD.UPLOAD_USER_IMAGE', payload: any }
export const UploadUserImage = (formData, tempOrdernetId, fileType) =>
    <IUploadUserImage>({ type: UPLOAD_USER_IMAGE, payload: { formData, tempOrdernetId, fileType } });

export const SET_USER_IMAGE = 'ORDER_CARD.SET_USER_IMAGE';
export interface ISetUserImage { type: 'ORDER_CARD.SET_USER_IMAGE', payload: any }
export const SetUserImage = (url, fileType) =>
    <ISetUserImage>({ type: SET_USER_IMAGE, payload: { url, fileType } });

export const SET_CURRENT_ORDER_STATUS = 'ORDER_CARD.SET_CURRENT_ORDER_STATUS';
export interface ISetCurrentOrderStatus { type: 'ORDER_CARD.SET_CURRENT_ORDER_STATUS', payload: any }
export const SetCurrentOrderStatus = (index) =>
    <ISetCurrentOrderStatus>({ type: SET_CURRENT_ORDER_STATUS, payload: index });

export const VALIDATE_FOR_STATUS = '[API] ORDER_CARD.VALIDATE_FOR_STATUS';
export interface IValidateForStatus { type: '[API] ORDER_CARD.VALIDATE_FOR_STATUS', payload: any }
export const ValidateForStatus = (orderId, statusId) =>
    <IValidateForStatus>({ type: VALIDATE_FOR_STATUS, payload: { orderId, statusId } });

export const SELECT_FINISH_STATUS = 'ORDER_CARD.SELECT_FINISH_STATUS';
export interface ISelectFinishStatus { type: 'ORDER_CARD.SELECT_FINISH_STATUS', payload: any }
export const SelectFinishStatus = (value, validated) =>
    <ISelectFinishStatus>({ type: SELECT_FINISH_STATUS, payload: { value, validated } });

export const CHANGE_GLOBAL_COMMENT = 'ORDER_CARD.CHANGE_GLOBAL_COMMENT';
export interface IChangeGlobalComment { type: 'ORDER_CARD.CHANGE_GLOBAL_COMMENT', payload: any }
export const ChangeGlobalComment = (comment) =>
    <IChangeGlobalComment>({ type: CHANGE_GLOBAL_COMMENT, payload: comment });

export const CHANGE_PRINT_REUSE_STATUS_MULTI = 'ORDER_CARD.CHANGE_PRINT_REUSE_STATUS_MULTI';
export interface IChangePrintReuseStatusMulti { type: 'ORDER_CARD.CHANGE_PRINT_REUSE_STATUS_MULTI', payload: any }
export const ChangePrintReuseStatusMulti = (status, index) =>
    <IChangePrintReuseStatusMulti>({ type: CHANGE_PRINT_REUSE_STATUS_MULTI, payload: { status, index } });

export const CHANGE_CANT_REUSE_QUANTITY = 'ORDER_CARD.CHANGE_CANT_REUSE_QUANTITY';
export interface IChangeCantReuseQuantity { type: 'ORDER_CARD.CHANGE_CANT_REUSE_QUANTITY', payload: any }
export const ChangeCantReuseQuantity = (value) =>
    <IChangeCantReuseQuantity>({ type: CHANGE_CANT_REUSE_QUANTITY, payload: value });

export const PROCESS_FAIL_MULTI = '[API] ORDER_CARD.PROCESS_FAIL_MULTI';
export interface IProcessFailMulti { type: '[API] ORDER_CARD.PROCESS_FAIL_MULTI', payload: any }
export const ProcessFailMulti = (orderProduct, failCount) =>
    <IProcessFailMulti>({ type: PROCESS_FAIL_MULTI, payload: { orderProduct, failCount } });

export const ADD_ORDER_STATUS = '[API] ORDER_CARD.ADD_ORDER_STATUS';
export interface IAddOrderStatus { type: '[API] ORDER_CARD.ADD_ORDER_STATUS', payload: any }
export const AddOrderStatus = (orderStatus) =>
    <IAddOrderStatus>({ type: ADD_ORDER_STATUS, payload: orderStatus });

export const REMOVE_FILE_FROM_ORDER_PRODUCT = '[API] ORDER_CARD.REMOVE_FILE_FROM_ORDER_PRODUCT';
export interface IRemoveFileFromOrderProduct { type: '[API] ORDER_CARD.REMOVE_FILE_FROM_ORDER_PRODUCT', payload: any }
export const RemoveFileFromOrderProduct = (orderProductUid, fileType) =>
    <IRemoveFileFromOrderProduct>{ type: REMOVE_FILE_FROM_ORDER_PRODUCT, payload: { orderProductUid, fileType } };

export const UNMOUNT_COMPONENT = 'ORDER_CARD.UNMOUNT_COMPONENT';
export interface IUnmountComponent { type: 'ORDER_CARD.UNMOUNT_COMPONENT', payload: any }
export const UnmountComponent = (value) =>
    <IUnmountComponent>{ type: UNMOUNT_COMPONENT, payload: value };

export const SET_ORDER_STATUS = 'ORDER_CARD.SET_ORDER_STATUS';
export interface ISetOrderStatus { type: 'ORDER_CARD.SET_ORDER_STATUS', payload: any }
export const SetOrderStatus = (status) =>
    <ISetOrderStatus>{ type: SET_ORDER_STATUS, payload: status };

export const SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT = 'WIZARD.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT';
export interface ISetProductionCommentForOrderProduct { type: 'WIZARD.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT', payload: any }
export const SetProductionCommentForOrderProduct = (value) =>
    <ISetProductionCommentForOrderProduct>({ type: SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT, payload: value });

export const SET_FAIL_PROCESSING = 'WIZARD.SET_FAIL_PROCESSING';
export interface ISetFailProcessing { type: 'WIZARD.SET_FAIL_PROCESSING', payload: any }
export const SetFailProcessing = (value) =>
    <ISetFailProcessing>({ type: SET_FAIL_PROCESSING, payload: value });

export const SET_FORCE_WHOLESALE = '[API] WIZARD.SET_FORCE_WHOLESALE';
export interface ISetForceWholesale { type: '[API] WIZARD.SET_FORCE_WHOLESALE', payload: any }
export const SetForceWholesale = (value) =>
    <ISetForceWholesale>({ type: SET_FORCE_WHOLESALE, payload: value });

export const GET_ORDER_AUDIT = '[API] ORDER_CARD.GET_ORDER_AUDIT';
export interface IGetOrderAudit { type: '[API] ORDER_CARD.GET_ORDER_AUDIT', payload: any }
export const GetOrderAudit = (netId: any) =>
    <IGetOrderAudit>({ type: GET_ORDER_AUDIT, payload: netId });

export const SET_ORDER_AUDIT = 'ORDER_CARD.SET_ORDER_AUDIT';
export interface ISetOrderAudit { type: 'ORDER_CARD.SET_ORDER_AUDIT', payload: any }
export const SetOrderAudit = (valud) =>
    <ISetOrderAudit>({ type: SET_ORDER_AUDIT, payload: valud });

export const REFRESH_ORDERPRODUCT = '[API] ORDER_CARD.REFRESH_ORDERPRODUCT';
export interface IRefreshOrderProduct { type: '[API] ORDER_CARD.REFRESH_ORDERPRODUCT', payload: any }
export const RefreshOrderProduct = (netid, index) =>
    <IRefreshOrderProduct>{ type: REFRESH_ORDERPRODUCT, payload: { netid, index } };

export const SET_REFRESHED_ORDERPRODUCT = 'ORDER_CARD.SET_REFRESHED_ORDERPRODUCT';
export interface ISetRefreshedOrderProduct { type: 'ORDER_CARD.SET_REFRESHED_ORDERPRODUCT', payload: any }
export const SetRefreshedOrderProduct = (orderProduct, index) =>
    <ISetRefreshedOrderProduct>{ type: SET_REFRESHED_ORDERPRODUCT, payload: { orderProduct, index } };

export const SELECT_OPTION_FOR_ORDER_PRODUCT = 'ORDER_CARD.SELECT_OPTION_FOR_ORDER_PRODUCT';
export interface ISelectOptionsForOrderProduct { type: 'ORDER_CARD.SELECT_OPTION_FOR_ORDER_PRODUCT', payload: any }
export const SelectOptionsForOrderProduct = (options) =>
    <ISelectOptionsForOrderProduct>{ type: SELECT_OPTION_FOR_ORDER_PRODUCT, payload: options };

export const GET_PRODUCT_BASES_FOR_ORDER_CARD = 'ORDER_CARD.GET_PRODUCT_BASES_FOR_ORDER_CARD';
export interface IGetProductBasesForOrderCard { type: 'ORDER_CARD.GET_PRODUCT_BASES_FOR_ORDER_CARD', payload: any }
export const GetProductBasesForOrderCard = (lexiconId: number, categoryId: number, subcategoryId: number) =>
    <IGetProductBasesForOrderCard>{ type: GET_PRODUCT_BASES_FOR_ORDER_CARD, payload: { lexiconId: lexiconId, categoryId: categoryId, subcategoryId: subcategoryId } };

export const GET_PRODUCT_BASES_FOR_ORDER_CARD_SUCCESS = 'ORDER_CARD.GET_PRODUCT_BASES_FOR_ORDER_CARD_SUCCESS';
export interface IGetProductBasesForOrderCardSuccess { type: 'ORDER_CARD.GET_PRODUCT_BASES_FOR_ORDER_CARD_SUCCESS', payload: any }
export const GetProductBasesForOrderCardSuccess = (payload) =>
    <IGetProductBasesForOrderCardSuccess>{ type: GET_PRODUCT_BASES_FOR_ORDER_CARD_SUCCESS, payload };

export type OrderCardKnownActions =
    IGetOrderForOrderCard | ISetOrderForOrderCard | IGetClientsForPartner | ISetClientsForPartner | ISetClientPhone | ISelectClient | IResetClientRv |
    IResetClient | IChangeNewClientName | IChangeNewClientEmail | IChangeRecipient | ISetCurrentRecipient | IChangeRecipientPhone | IChangeRecipientName |
    IUpdateOrder | ISetCurrentDeliveryInfo | ISelectDeliveryType | IGetCitiesForAutocomplete | IGetWerehousesForAutocomplete | ISetCitiesForAutocomplete |
    ISetWerehousesForAutocomplete | ISelectCitiesForAutocomplete | ISelectWerehousesForAutocomplete | IChangeUkrCityName | IChangeUkrBuildingNum |
    IChangeUkrFlatNum | IChangeUkrZip | IChangeUkrStreetName | ISelectOrderProductForRv | IUpdateOrderProduct | ISetUpdatedOrderProduct |
    IUploadFilesForOrderProduct | IChangePrintComment | IChangeUserImageComment | IChangeProductionOperatorComment | IChangeProductionComment |
    ISetProductUpdateMode | IResetProductUpdateMode | IGetCategories | ISetCategories | IGetSubCategories | ISetSubCategories | ISelectCategory |
    ISelectSubCategory | IGetProducts | ISetProducts | IChangeProductSelectionMode | IRemoveOrderProductServer | IRemoveOrderProductClient |
    ISelectOrderProduct | IConfirmOrderProductSelection | ICancelCurrentOrderProduct | ISelectCurrentProduct | IConfirmProductSelect | ISetBasesForProduct |
    ISetPattern | ISetNextSubStep | IGetBasesForOrderProduct | ISelectCurrentBaseColor | IConfirmBaseColorSelect | ISelectCurrentBaseProduct | IConfirmBaseProductSelect |
    ISelectCurrentProductColor | IConfirmProductColorSelect | ICalculateProductTotal | ISetTotalForCurrentOrderProduct | IChangeCurrentOrderProductPrice |
    IChangeCurrentOrderProductQuantity | ISaveOrderProduct | IUpdateOrderProductServer | ISetOrderProduct | IUpdateOrderProductClient | IUploadUserImage |
    ISetUserImage | ISetCurrentOrderStatus | IValidateForStatus | ISelectFinishStatus | IChangeGlobalComment | IChangePrintReuseStatusMulti |
    IChangeCantReuseQuantity | IProcessFailMulti | IAddOrderStatus | IRemoveFileFromOrderProduct | IUnmountComponent | ISetOrderStatus |
    ISetProductionCommentForOrderProduct | ISetFailProcessing | ISetForceWholesale | IUpdateOrderSilent | IGetOrderAudit | ISetOrderAudit |
    ISetUserImageComment | IRefreshOrderProduct | ISetRefreshedOrderProduct | ISetOrderProductByIndex | ISelectOptionsForOrderProduct |
    IGetProductBasesForOrderCard | IGetProductBasesForOrderCardSuccess | IGetRegionUkrPoshtaForAutocomplete | ISetRegionUkrPoshtaForAutocomplete | ISelectRegionUkrPoshtaForAutocomplete |
    ISetAreaUkrPoshtaForAutocomplete | ISelectAreaUkrPoshtaForAutocomplete | IGetCityUkrPoshtaForAutocomplete | ISetCityUkrPoshtaForAutocomplete | ISelectCityUkrPoshtaForAutocomplete | IGetAdditionalServiceProducts | ISetAdditionalServiceProducts |
    ISetExpressPrintProduct | ISetExtraPrintProduct;