import { Reducer } from "redux";
import { defaultRightViewState, RightViewState } from "../states/right.view.state";
import { RightViewKnownActions } from "../actions/right.view.actions";
import * as RightViewActions from "../actions/right.view.actions";

export const reducer: Reducer<RightViewState> = (state: RightViewState, action: RightViewKnownActions) => {
    switch (action.type) {

        case RightViewActions.SELECT_RV_DATA:
            return {
                ...state,
                ViewSize: action.payload.ViewSize,
                CloseFunction: action.payload.CloseFunction,
                CustomFunctions: action.payload.CustomFunctions,
                Component: action.payload.Component,
                Props: action.payload.Props,
                Title: action.payload.Title
            };

        case RightViewActions.REMOVE_RV_DATA:
            return {
                ...state,
                ViewSize: null,
                CloseFunction: null,
                CustomFunctions: null,
                Component: null,
                Props: null,
                Title: null
            };
        case RightViewActions.CHANGE_RV_TITLE:
            return {
                ...state,
                Title: action.payload
            };
        case RightViewActions.CHANGE_RV_SIZE:
            return {
                ...state,
                ViewSize: action.payload
            };
        case RightViewActions.SHOW_HOVER_BLOCK:
            return {
                ...state,
                HoverData: action.payload
            };
        case RightViewActions.SET_DISPLAYING_IMAGE:
            return {
                ...state,
                DisplayedProductImage: action.payload
            }
        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultRightViewState;
}