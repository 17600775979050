import * as React from "react";
import { connect } from "react-redux";
import { RightViewState } from "../../../../states/right.view.state";
import { ConstructorPriceItem } from "../../../../business.entities/constructor.prices";
import { List } from "linqts";
import { ClipboardHelper } from "../../../../helpers/clipboardHelper";
import { default as ConstructorPriceForm } from "../personal.info/constructor.price.form";
import { bindActionCreators } from "redux";
import {
    GetUserPersonalInfo,
    UnmountComponent,
    GetConstructorPrice,
    GetPriceSergmentsForPartner,
    LogoEditMode,
    ChangePosition,
    ChangeLogoImg,
    UpdateWatermark,
    RemoveWatermark,
    ChangePreviewImg,
    SetRemovingLogo,
    ActivateConstructor,
    OpenActivateConstructor,
    OpenPosition,
    OpenPrices,
    OpenLogoPreview,
    OpenIntegrationInfo,
    GetConstructorTutorials,
    UpdateConstructorTutorial,
    SelectTutorial,
    GetConstructorUsers,
    SetConstructorUsers
} from "../../../../actions/cabinet/personal.info.actions";
import { actions } from 'react-redux-form'
import * as  rightViewActions from "../../../../actions/right.view.actions";
import { Loader } from "../../../shared/loader";
import { success, error } from "react-notification-system-redux";
import * as API from "../../../../constants/api.constants";
import { GetPositionName, WatermarkPositionEnum } from "../../../../helpers/enums/watermark.position.enum";
import { default as ConstructorWarningRv } from "../personal.info/constructor.warning.rv";
import { Route } from "react-router";
import { GetIdentityRoleName, IdentityRoleEnum } from "../../../../helpers/identity.roles";
import EditConstructorTutorialComponent from "./constructor.tutorial.edit";
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";
import { default as ConstructorUsers } from "./constructor.users";
import PermissionChecker from '../../../shared/permission.checker.component';
import { debug } from "util";
import { runInThisContext } from "vm";
import { DefaultUserInfoState } from "../../../../states/cabinet/user.info.state";

class ConstructorInfo extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.props.GetUserPersonalInfo(this.props.Authorization.UserNetUid);

        this.props.GetConstructorPrice(this.props.Authorization.UserNetUid);

        this.props.GetConstructorTutorials();
    }

    private OnValidateAndCopy() {
        if (this.props.UserInfo.ConstructorPrices.PriceItems.length > 0) {
            let result = new List<ConstructorPriceItem>(this.props.UserInfo.ConstructorPrices.PriceItems).Any(c => c.Price === 0)
            if (result) {
                this.props.error({
                    title: "Проверте правильность заполнения цен",
                    position: 'br',
                    autoDismiss: 3,
                })
            } else {
                ClipboardHelper.CopyTextWithMessage(
                    `https://print.caseshop.com.ua/${this.props.Authorization.UserNetUid}`,
                    "Ссылка на конструктор добавлена в буфер обмена");
            }
        } else {
            this.props.error({
                title: "Проверте правильность заполнения цен",
                position: 'br',
                autoDismiss: 3,
            })
        }
    }

    private OnOpenMinifier() {
        window.open('bit.ly', '_blank');
    }

    public OnFileUpload(event) {
        let files;
        let formData = new FormData();
        if (event.target.files) {
            files = Array.from(event.target.files)
            formData.append('files', files[0] as any, (files[0] as any).name);

            let reader = new FileReader();
            
            this.props.SetRemovingLogo(false);

            reader.onloadend = () => {
                this.props.ChangePreviewImg(reader.result);
            };

            reader.readAsDataURL(files[0]);
        }

        this.props.ChangeLogoImg(formData);
    }

    public OnFileRemove() {
        this.props.SetRemovingLogo(true);
        this.props.ChangePreviewImg(DefaultUserInfoState.Preview);
        this.props.ChangeLogoImg(DefaultUserInfoState.NewImage);
    }

    public CancelEditLogoCommand() {
        const selectedPosition = this.props.UserInfo.Positions.find(c => c.value === this.props.UserInfo.userInfo.WatermarkPosition);
        this.props.ChangePosition(selectedPosition);

        this.props.LogoEditMode(false);
    }

    private OnOpenConstructorApproveRV() {
        this.props.OpenActivateConstructor(true);
    }

    public OnSelectTutorial(tutorial, editMode) {

        if (!tutorial && !editMode) {
            this.props.SelectTutorial(tutorial, editMode);
            return;
        }

        if (editMode) {
            this.props.SelectTutorial(tutorial, editMode);

            //rv
            let rvState = new RightViewState();
            rvState.Title = "Редактировать";
            rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
            rvState.Component = EditConstructorTutorialComponent;

            rvState.CloseFunction = () => {
                this.props.RemoveRVData();
            };

            this.props.SelectRVData(rvState);
        } else {
            this.props.SelectTutorial(tutorial, false)
        }
    }

    public OnOpenConstructorUsersRV(isActive) {

        this.props.RemoveRVData();

        this.props.GetConstructorUsers(isActive);

        //rv
        let rvState = new RightViewState();
        rvState.Title = isActive ? "Активные пользователи" : "Пасивные пользователи";
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.Component = ConstructorUsers;

        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetConstructorUsers([]);
        };

        this.props.SelectRVData(rvState);
    }

    render() {
        return (
            <div className="constructor_info__PAGECON">
                {this.props.UserInfo.SelectedTutorial && !this.props.UserInfo.EditTutorialMode ?
                    <div className="video_full_component">
                        <div className="close_video_full" onClick={() => this.OnSelectTutorial(null, false)}></div>
                        <iframe width="420" height="345" src={`${API.SERVER_URL}${this.props.UserInfo.SelectedTutorial.Link}`} allowFullScreen></iframe>

                        <div className="VVwrapCont">
                            {/*
                              <Video
                                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                                <source  type='video/webm; codecs="vp8, vorbis"' />
                              </Video>
                             */}
                        </div>
                    </div> : null
                }

                <LeftMenuMobileControl />
                <div className="left_menu_component contructorMenu">
                    <div className="left_menu_SCROLL">
                        <div>
                            <div className="defaultFormControl mB">
                                <div className="defaultFormControl__label occ">Онлайн-конструктор</div>
                            </div>

                            <p>
                                Представляем уникальную возможность интеграции нашего онлайн-конструктора Вам на сайт.
                            </p>

                            <p className="mB">
                                Установка займет не более 10 минут. Она не требует специальних знаний. Перед установкой, рекомендуем посмотреть видео-инструкции в правой части этой страницы.
                            </p>

                            <p>
                                <b>
                                    План действий:
                            </b>
                            </p>

                            <p>
                                1. Заполнить цены на чехлы с печатью и дополнительные товары.
                        </p>

                            <div className="defaultFormControl mB">
                                <div className="defaultFormButton"
                                    onClick={() => {
                                        this.props.OpenPrices(!this.props.UserInfo.ConstructorPriceMode);
                                        let element = document.getElementById('left_menuCONTROL_component__ID').click();
                                    }}>
                                    Изменить цены конструктора
                            </div>
                            </div>

                            <p>
                                2. Определиться с защитой макета чехла. По-умолчанию, макет защищен нашим логотипом "caseshop.com.ua". Вы можете его оставить или настроить свой собственный.
                        </p>

                            <div className="defaultFormControl mB">
                                <div className="defaultFormButton"
                                    onClick={() => {
                                        this.props.LogoEditMode(!this.props.UserInfo.LogoEditMode);
                                        let element = document.getElementById('left_menuCONTROL_component__ID').click();
                                    }}>
                                    Настройка отображения логотипа
                                </div>
                            </div>

                            <p>
                                3. Скопироватьперсональную ссылку и вставить себе на сайт или социальные сети.
                        </p>

                            <div className="defaultFormControl mB">
                                <div className="defaultFormButton" onClick={() => {
                                    this.props.OpenIntegrationInfo(!this.props.UserInfo.IntegrationInfoMode);
                                    let element = document.getElementById('left_menuCONTROL_component__ID').click();
                                }}>
                                    Интеграция конструктора
                            </div>
                            </div>


                            <PermissionChecker
                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                <>
                                    <div className="defaultFormControl mB">
                                        <div className="defaultFormButton" onClick={() => {
                                            this.OnOpenConstructorUsersRV(true)
                                        }}>
                                            Активные пользователей
                                </div>
                                    </div>

                                    <div className="defaultFormControl mB">
                                        <div className="defaultFormButton" onClick={() =>
                                            this.OnOpenConstructorUsersRV(false)
                                        }>
                                            Пасивные пользователей
                                </div>
                                    </div>
                                </>
                            </PermissionChecker>



                        </div>
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default"></span>
                        <span className="title_description"></span>
                    </div>
                </div>
                <div className="content_component constructor_content_component">
                    <div className='wrapper'>

                        {
                            (!this.props.UserInfo.LogoEditMode && !this.props.UserInfo.ConstructorPriceMode && !this.props.UserInfo.IntegrationInfoMode) ?
                                <div className="videoWrapper__container">
                                    <div className="videoWrapper__text">
                                        Мы подготовили ряд коротких видео, которые помогут быстро настроить и интегрировать конструктор
                                    </div>
                                    <div className="videoWrapper__content">
                                        {
                                            this.props.UserInfo.Tutorials && this.props.UserInfo.Tutorials.length > 0 ?

                                                this.props.UserInfo.Tutorials.map(tutorial =>
                                                    <div className="video_wrapper videoItem__container1 ">
                                                        <div className="video_title">{tutorial.Name}</div>
                                                        <div className="playBtn__vid" onClick={() => this.OnSelectTutorial(tutorial, false)}></div>
                                                        {this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                                                            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                                                            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
                                                            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya) ?
                                                            <div className="editBtn__vid" onClick={() => this.OnSelectTutorial(tutorial, true)}></div>
                                                            : null}

                                                        <div className="videoWrapper"></div>
                                                    </div>
                                                )
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.props.UserInfo.LogoEditMode ?
                                !this.props.UserInfo.LogoPreviewMode ?
                                    <>
                                        <div className='headerLabel'>
                                            Настройка отображения логотипа
                                        </div>
                                        <div className="bodyContainer">
                                            <p className="redColor">
                                                Ввиду необходимости програмного обслуживания даной функции, цена на чехол с печатью буден немного выше.
                                            </p>
                                            <p className="redColor">
                                                При подключении услуги, стоимость одного чехла оформленного через конструктор увеличится на 7 грн от текущего ценового уровня.
                                            </p>
                                            <p>
                                                В случае отмены дальнейшей настройки отображения логотипа, можете сразу перейти к пункту №3
                                            </p>
                                        </div>

                                        <div className="defaultFormControl  minimal backButton">
                                            <div className="defaultFormButton" onClick={() => this.props.LogoEditMode(false)}>
                                                Вернуться назад
                                            </div>
                                        </div>

                                        <div className="defaultFormControl minimal">
                                            <div className="defaultFormButton" onClick={() => this.props.OpenLogoPreview(true)}>
                                                Получить онлайн конструктор с персональным логотипом
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className='headerLabel'>
                                            Настройка отображения логотипа
                                        </div>

                                        <p>
                                            Для начала выберите место расположения Вашего логотипа. Всего есть 6 вариантов.
                                        </p>
                                        <p>
                                            Логотип будет по вертикали - отцентрирован, по горизонтале - прижат вправо или влево к оси чехла в зависимости от Вашего выбора.
                                        </p>

                                        <div className='positionSelectContainer'>
                                            <div className={`positionOption po__1 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.TopLeft ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.TopLeft)
                                            }}>
                                            </div>

                                            <div className={`positionOption po__2 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.TopRight ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.TopRight)
                                            }}>
                                            </div>

                                            <div className={`positionOption po__3 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.MidLeft ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.MidLeft)
                                            }}>
                                            </div>

                                            <div className={`positionOption po__4 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.MidRight ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.MidRight)
                                            }}>
                                            </div>

                                            <div className={`positionOption po__5 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.BottomLeft ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.BottomLeft)
                                            }}>
                                            </div>

                                            <div className={`positionOption po__6 ${this.props.UserInfo.SelectedPosition === WatermarkPositionEnum.BottomRight ? 'selected' : ''}`} onClick={() => {
                                                this.props.ChangePosition(WatermarkPositionEnum.BottomRight)
                                            }}>
                                            </div>
                                        </div>

                                        <p>
                                            Далее загрузите Ваш логотип в формате .png.
                                        </p>
                                        <p>
                                            После этого сохраните изменения и перейдите в пункт №3
                                        </p>

                                        <div className="logoContainer">
                                            {
                                                (this.props.UserInfo.IsRemovingLogo ?
                                                    <img />
                                                    :
                                                    (this.props.UserInfo.Preview ?
                                                        <img src={this.props.UserInfo.Preview} alt="" />
                                                        : 
                                                        (this.props.UserInfo.userInfo.WatermarkUrl ?
                                                            <img src={`${API.SERVER_URL}${this.props.UserInfo.userInfo.WatermarkUrl}`} alt="" />
                                                            :
                                                            <img />
                                                        )
                                                    )
                                                )  
                                            }

                                            <label className="constructor__Button">
                                                <input type="file" onChange={(event) => { this.OnFileUpload(event) }} />
                                                <div className="uLoad">Загрузите логотип</div>
                                            </label>
                                        </div>
                                        
                                        {this.props.UserInfo.userInfo.OfflineShop &&
                                            <div className="defaultFormControl minimal" onClick={() => this.OnFileRemove()}>
                                                <div className="redFormButton" onClick={() => { this.OnFileRemove() }}>Удалить логотип</div>
                                            </div>
                                        }

                                        <br />

                                        <div className={`constructorWatermarksControlsContainer ${this.props.UserInfo.userInfo.OfflineShop ? '' : 'closer__to__form'}`}>
                                            <div className="defaultFormControl minimal backButton">
                                                <div className="defaultFormButton" onClick={() => this.props.LogoEditMode(false)}>
                                                    Вернуться назад
                                                </div>
                                            </div>
                                            <div className="defaultFormControl minimal">
                                                <div className="defaultFormButton" onClick={() => {this.props.UserInfo.IsRemovingLogo ? this.props.RemoveWatermark() : this.props.UpdateWatermark()}}>
                                                    Сохранить
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                : null
                        }

                        {
                            this.props.UserInfo.ConstructorPriceMode ?
                                <div className="container__ConstructorPriceMode">
                                    <div className='headerLabel'>
                                        Цены на чехлы с печатью
                                    </div>
                                    <Route path={`${this.props.match.url}`} component={ConstructorPriceForm} />
                                </div>
                                : null
                        }

                        {
                            this.props.UserInfo.IntegrationInfoMode ?
                                <>
                                    <div>
                                        <div className="container__IntegrationInfoMode">
                                            <div className='headerLabel'>Настройка отображения логотипа</div>
                                            <div className="bodyContainer">

                                                <p>
                                                    Настройка завершена! Сейчас Вы можете скопировать Вашу персональную ссылку для интеграции конструктора на сайт или в социальную сеть.
                                                    </p>

                                                <div className="defaultFormControl minimal">
                                                    <div className="defaultFormButton" onClick={this.OnValidateAndCopy.bind(this)}>Копировать ссылку</div>
                                                </div>
                                            </div>

                                            <div className='headerLabel'>Наши рекомендации по интеграции на сайт</div>
                                            <div className="bodyContainer bodyLg">
                                                <p>
                                                    Интеграция на сайт может происходить двумя способами.
                                                </p>
                                                <p>
                                                    Можно вставить ссылку в качестве гиперссылки в опись товара с печатью или под картинку, при нажатии на которую конструктор будет открываться в новой вкладке. Данный метод являетса предпочтительным, так как не требует дополнительных настроек и отображение элементов конструктора будет безупречным.
                                                </p>

                                                <p>
                                                    Так же Вы можете вставить окно конструктора в среду страницы Вашего сайта как фрейм страницу истользуя следующий элемент кода:
                                                    <b>{` <iframe src="[Ваша ссылка на конструктор]"></iframe> `}</b><br />
                                                    Этот метод мы рекомендуем менше, так как при неправильной настройке к индивидуальным особеностей сайта есть вероятность «кривого» отображения елементов конструктора.
                                                </p>


                                            </div>

                                            <div className='headerLabel'>Наши рекомендации по интеграции в социальнык сети</div>
                                            <div className="bodyContainer bodyLg">
                                                <p>
                                                    Разместив полученую ссылку в профиле Вашей компании, Ваши клиенты смогут перейти в полноэкранную
                                                    версию конструктора и оформлять там заказы. Также Вы можете делиться данной ссылкой с Вашими клиентами
                                                    в месенджерах или рассылках.
                                                </p>

                                                <p>
                                                    Ввиду того, что по-умолчанию в ссылке отображается наш домен, рекомендуем изменить ссылку с помощью сервиса <a href='https://bitly.com/' target='_blank'>bit.ly</a>. Кроме «маскировки», ссылка станет намного презентабельней за счет меньшей длины.
                                                </p>
                                            </div>
                                        </div>

                                        <div className='headerLabel'>Прием заказов</div>
                                        <div className="bodyContainer bodyLg">
                                            <p>
                                                Все заказы, которые Ваши клиенты будут оформлять через конструктор автоматически будут попадать в «Базу заказов»
                                                в статусе «В ожидании». Вам нужно будет позвонить для подтверждения заказа.
                                            </p>
                                            <p>
                                                Также заверим, что мы никаким образом не используем информацию в заказах о Ваших клиентах. В первую очередь мы
                                                нацелены на долгострочное сотрудничество с Вами и можем гарантировать порядочность.
                                            </p>

                                            <p>
                                                Если у Вас остались вопросы по интеграции, Вы всегда можете задать их руководителю партнерского отдела написав на почту
                                            </p>

                                            <div>
                                                <a href="mailto:b2b.caseshop@gmail.com">b2b.caseshop@gmail.com</a>
                                            </div>

                                            <p>
                                                Желаем хороших продаж!
                                            </p>
                                            <div className="defaultFormControl minimal backButton">
                                                <div className="defaultFormButton" onClick={() => this.props.OpenIntegrationInfo(false)}>
                                                    Вернуться назад
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(
    state => ({
        UserInfo: (state as any).cabinet.userInfo,
        Authorization: (state as any).authentication
    }),
    dispatch => bindActionCreators({
        GetUserPersonalInfo,
        UnmountComponent,
        GetConstructorPrice,
        GetPriceSergmentsForPartner,
        ChangeForm: actions.change,
        SelectRVData: rightViewActions.SelectRVData,
        RemoveRVData: rightViewActions.RemoveRVData,
        success,
        error,
        LogoEditMode,
        ChangePosition,
        ChangeLogoImg,
        SetRemovingLogo,
        UpdateWatermark,
        RemoveWatermark,
        ChangePreviewImg,
        ActivateConstructor,
        OpenActivateConstructor,
        OpenPosition,
        OpenPrices,
        OpenLogoPreview,
        OpenIntegrationInfo,
        GetConstructorTutorials,
        UpdateConstructorTutorial,
        SelectTutorial,
        GetConstructorUsers,
        SetConstructorUsers
    }, dispatch)
)(ConstructorInfo)