import { PartnerGridState, defaultPartnerGridState } from "./partner.grid.state";
import { RightViewState } from "../right.view.state";
import { PartnerInfoState, dafaultPartnerInfoState } from "./partner.info.state";
import { PromocodeState } from "./promocode.state";

export class PartnerState {
    public PartnerRightView: RightViewState;
}

export const defaultPartnerState  = {
     PartnerRightView: null
}

export interface IGlobalPartnerState{
    partnerGrid: PartnerGridState,
    partnerInfo: PartnerInfoState,
    partnerPromocodes: PromocodeState,
    partner: PartnerState
}