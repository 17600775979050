import { Reducer } from "redux";
import { ProductState, defaultProductState } from "../../states/stock/product.state";
import * as productActions from "../../actions/stock/product.actions";
import * as categoryActions from "../../actions/category.actions";
import { debounce } from "rxjs/operator/debounce";
import { log } from "util";

export const reducer: Reducer<ProductState> = (state: ProductState, action: productActions.ProductKnownActions | categoryActions.CategoryKnownActions) => {

	switch (action.type) {
		case productActions.GET_PRODUCT_FILTERED_PAGINATED:
			return { ...state, fetchInProccess: true };

		case productActions.GET_PRODUCTS_SUCCESS:
			return {
				...state,
				filterChanged: false,
				products: action.payload.Collection,
				totalPages: action.payload.TotalPagesCount,
				fetchInProccess: false,
				isFirstLoad: false
			};

		case productActions.SELECT_PRODUCT:
			return { ...state, selectedProduct: action.payload };

		case productActions.DESELECT_PRODUCT:
			return {
				...state,
				selectedProduct: defaultProductState.selectedProduct,
				selectedProductColor: null
			};

		case productActions.SELECT_PRODUCT_COLOR:
			return { ...state, selectedProductColor: action.payload };

		case productActions.UPDATE_PRODUCT_COLOR:
			return { ...state };

		case categoryActions.GET_SUBCATEGORIES_AUTOCOMPLETE:
			return { ...state };

		case categoryActions.GET_SUBCATEGORIES_AUTOCOMPLETE_SUCCESS:
			return { ...state, autocompleteList: action.payload };

		case productActions.SET_LEXICON_SELECT:
			return { ...state, lexiconSelectList: action.payload };

		case productActions.SET_CATEGORY_SELECT:
			return { ...state, categorySelectList: action.payload };

		case productActions.CHANGE_CATEGORY_SELECT:
			return {
				...state, filterChanged: true,
				selectedCategoryValue: action.payload,
				autocompleteList: [],
				selectedSubcategoryValue: null
			};

		case productActions.CHANGE_LEXICON_SELECT:
			return { ...state, filterChanged: true, selectedLexiconValue: action.payload };

		case productActions.CHANGE_SUBCATEGORY_SELECT:
			return {
				...state,
				filterChanged: true,
				selectedSubcategoryValue: action.payload,
				autocompleteList: []
			};

		case productActions.RESET_FILTER:
			return {
				...state,
				filterChanged: true,
				selectedCategoryValue: null,
				selectedLexiconValue: null,
				selectedSubcategoryValue: null,
				autocompleteList: []
			};

		case productActions.PRODUCT_UNMOUNT:
			return { ...defaultProductState };

		case productActions.SET_PRODUCTS:
			return Object.assign({}, state, { products: action.payload } as ProductState);

		case productActions.CHANGE_PRICE_CATEGORY_SELECT:
			return Object.assign({}, state, { selectedPriceCategoryValue: action.payload } as ProductState);

		case productActions.CHANGE_PRICE_LEXICON_SELECT:
			return Object.assign({}, state, { selectedPriceLexiconValue: action.payload } as ProductState);

		default:
			const exhaustiveCheck: any = action;
	}

	return state || defaultProductState;
}