import { Order } from "../../business.entities/order";
import { OrderBaseFilterEntity } from "../../helpers/order.base.fiter.entity";
import { OrderTypeEnum } from "../../helpers/enums/order.type.enum";
import { DeliveryType } from "../../helpers/enums/delivery.type";
import { PaymentMethod } from "../../helpers/enums/payment.method";
import { PaymentStatusEnum } from "../../helpers/enums/payment.status.enum";
import { PaymentStatus } from "../../business.entities/payment.status";
import { ConfirmPaymentStatus } from "../../business.entities/confirm.payment.status";
import { ConfirmPaymentStatusEnum } from "../../helpers/enums/confirm.payment.status.enum";
import { StockStatus } from "../../business.entities/stock.status";
import { PrintStatusEnum, GetPrintStatusName } from "../../helpers/enums/print.status.enum";
import { PrintVerificationStatusEnum } from "../../helpers/enums/print.verification.status.enum";
import { ProductionStatusEnum, GetProductionStatusName } from "../../helpers/enums/production.status";
import { StockStatusEnum, GetStockStatusName } from "../../helpers/enums/stock.status.enum";
import { any } from "prop-types";

export class OrderBaseState {
    orders: Order[];
    fetchInProgress: boolean;
    filter: OrderBaseFilterEntity;
    orderTypeList: any[];
    selectedOrderType: any;
    orderStatusList: any[];
    selectedOrderStatus: any;
    clientList: any[];
    partnerList: any[];
    selectedClient: any;
    deliveryTypeList: any[];
    selectedDeliveryType: any;
    paymentMethodList: any[];
    selectedPaymentMethod: any;
    orderCode: string;
    currentOrderProducts: any[];
    currentOrderStatuses: any[];
    ordersCount: number;
    currentOrderIndex: number;
    paymentStatusList: any[];
    selectedPaymentStatusForOrder: any;
    selectedPaymentDate: Date;
    orderPaymentStatuses: PaymentStatus[];
    confirmPaymentStatusList: any[];
    selectedConfirmPaymentStatusForOrder: any;
    orderConfirmPaymentStatus: ConfirmPaymentStatus[];
    currentStockStatuses: StockStatus[];
    printStatusList: any[];
    printVerifyStatusList: any[];
    reuseStatusList: any[];
    productionStatusList: any[];
    laserStatusList: any[];
    selectedPartner: any;
    filterStartDate: any;
    filterEndDate: any;
    selectedPrintStatus: any;
    selectedProductionStatus: any;
    selectedLaserStatus: any;
    filterAssemblingDate: any;
    cantReuseQuantity: number;
    isFailProcessing: boolean;
    stockStatusList: any[];
    selectedStockStatus: any;
    TTN: string;
    Reesters: any[];
    ReestersDate: any;
    ReestersTtn: any;
    ReestersOrderCode: any;
    CustomTTN: any;
    TTNCreateDateFilter: any;
    modelList: any[];
    selectedModel: any;
    exportOrderUrl: string;
    lexiconList: any[];
    selectedLexicon: any;
    orderProductsForPage: any[];
}

export const defaultOrderBaseState: OrderBaseState = {
    orders: [],
    fetchInProgress: false,
    filter: new OrderBaseFilterEntity(),
    orderTypeList: [
        {
            value: OrderTypeEnum.Sale,
            label: 'Продажа'
        },
        {
            value: OrderTypeEnum.Print,
            label: 'Печать'
        },
        {
            value: OrderTypeEnum.Production,
            label: 'Производство'
        },
        {
            value: OrderTypeEnum.ProductionAndPrint,
            label: 'Печать + Производство + Текстиль'
        },
        {
            value: OrderTypeEnum.Laser,
            label: 'Текстиль'
        },
    ],
    selectedOrderType: null,
    orderStatusList: [],
    selectedOrderStatus: null,
    clientList: [],
    selectedClient: null,
    deliveryTypeList: [
        {
            value: DeliveryType.Nova,
            label: 'Нова Пошта'
        },
        {
            value: DeliveryType.Ukr,
            label: 'Укр пошта'
        },
        {
            value: DeliveryType.Self,
            label: 'Самовывоз'
        },
    ],
    selectedDeliveryType: null,
    paymentMethodList: [
        {
            value: PaymentMethod.Prepaid,
            label: 'Предоплата'
        },
        {
            value: PaymentMethod.Afterpaid,
            label: 'Наложеный платёж'
        },
        {
            value: PaymentMethod.Certificate,
            label: 'Сертификат-промокод'
        }
    ],
    selectedPaymentMethod: null,
    orderCode: '',
    currentOrderProducts: null,
    currentOrderStatuses: null,
    ordersCount: 0,
    currentOrderIndex: null,
    paymentStatusList: [
        {
            value: PaymentStatusEnum.Waiting,
            label: 'Не получена'
        },
        {
            value: PaymentStatusEnum.PaymentTime,
            label: 'Время'
        },
        {
            value: PaymentStatusEnum.Recived,
            label: 'Получена',
            isDisabled: true
        }
    ],
    selectedPaymentStatusForOrder: null,
    selectedPaymentDate: null,
    orderPaymentStatuses: null,
    confirmPaymentStatusList: [
        {
            label: 'Не подтверждена',
            value: ConfirmPaymentStatusEnum.NotConfirmed
        },
        {
            label: 'Подтверждена',
            value: ConfirmPaymentStatusEnum.Confirmed
        }
    ],
    selectedConfirmPaymentStatusForOrder: null,
    orderConfirmPaymentStatus: [],
    currentStockStatuses: null,
    printStatusList: [],
    printVerifyStatusList: [
        {
            value: null,
            label: 'Выберите статус'
        },
        {
            value: PrintVerificationStatusEnum.Failed,
            label: 'Не пройдена'
        },
        {
            value: PrintVerificationStatusEnum.Passed,
            label: 'Пройдена'
        }
    ],
    reuseStatusList: [
        {
            value: null,
            label: 'Выберите опцию'
        },
        {
            value: false,
            label: 'Не использовать'
        },
        {
            value: true,
            label: 'Использовать'
        }
    ],
    productionStatusList: [],
    stockStatusList: [
        {
            value: StockStatusEnum.Assembling,
            label: GetStockStatusName(StockStatusEnum.Assembling)
        },
        {
            value: StockStatusEnum.Available,
            label: GetStockStatusName(StockStatusEnum.Available)
        },
        {
            value: StockStatusEnum.Partly,
            label: GetStockStatusName(StockStatusEnum.Partly)
        },
        {
            value: StockStatusEnum.NotAvailable,
            label: GetStockStatusName(StockStatusEnum.NotAvailable)
        },
    ],
    laserStatusList: [],
    partnerList: [],
    selectedPartner: null,
    filterStartDate: null,
    filterEndDate: null,
    selectedPrintStatus: null,
    selectedProductionStatus: null,
    filterAssemblingDate: null,
    cantReuseQuantity: 1,
    isFailProcessing: false,
    selectedStockStatus: null,
    TTN: '',
    Reesters: [],
    ReestersDate: null,
    ReestersTtn: "",
    ReestersOrderCode: "",
    CustomTTN: '',
    TTNCreateDateFilter: null,
    modelList: [],
    selectedModel: null,
    exportOrderUrl: null,
    lexiconList: [],
    selectedLexicon: null,
    orderProductsForPage: []
};

