import { string } from "prop-types";
import * as React from "react";
import { connect } from "react-redux";

export const Finances = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component finances__GRID_CONTAINER">
                        <div className="grid_row   mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Полученные на счет средства от партнеров
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Сумма проведенных операций "Пополнение баланса"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.BalanceReplenishmentsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row   mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Возвращенные партнеру средства
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Сумма проведенных операций "Возврат средств"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.RefundsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row   mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Количество проданных товаров типа "Печать"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Количество товаров типа "Печать" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.PrintItemsQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Партнерская стоимость проданных товаров типа "Печать"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Партнерская стоимость товаров типа "Печать" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.PrintItemsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Количество проданных товаров типа "Продажи"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Количество товаров типа "Продажи" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.SaleItemsQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Партнерская стоимость проданных товаров типа "Продажи"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Партнерская стоимость товаров типа "Продажи" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.SaleItemsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Количество проданных товаров типа "Производство"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Количество товаров типа "Производство" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.ProdItemsQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Партнерская стоимость проданных товаров типа "Производство"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Партнерская стоимость товаров типа "Производство" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.ProdItemsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Количество проданных товаров типа "Текстиль"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Количество товаров типа "Текстиль" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.LaserItemsQuantity}
                                </span>
                            </div>
                        </div>
                        
                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Партнерская стоимость проданных товаров типа "Текстиль"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Партнерская стоимость товаров типа "Текстиль" оформленных заказов, которые на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.LaserItemsAmount}
                                </span>
                            </div>
                        </div>
                        
                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Средства списанные за персональный конструктор
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Средства списанные за персональный конструктор
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.ConstructorPaymentsAmount}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                <span className="cell_content__GC">
                                    Партнерская стоимость товаров и услуг со статусом "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell" data-tooltip="Описание">
                                <span className="cell_content__GC">
                                    Партнерская сумма всех товаров и услуг, которые, на момент проверки имели статус "Отправлен на сборку"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                <span className="cell_content__GC">
                                    {model.SumOfAmount}
                                </span>
                            </div>
                        </div>

                        {model.OrdersRelated &&
                            <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name" data-tooltip="Показатель">
                                    <span className="cell_content__GC">
                                        Номера заказов, по которым отображается статистика в отчете
                                    </span>
                                </div>

                                <div className="grid_cell" data-tooltip="Описание">
                                    <span className="cell_content__GC">
                                        {model.OrdersRelated.join(", ")}
                                    </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT" data-tooltip="Значение">
                                    <span className="cell_content__GC">
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(Finances);
