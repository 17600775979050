import { Partner } from "../../business.entities/partner";
import { User } from "../../business.entities/user";

export const ADD_USER_PARTNER: string = '[API] ADD_USER_PARTNER';
export interface IAddUserPartner { type: '[API] ADD_USER_PARTNER', payload: any }
export const AddUserPartner = (netuid: any, user: any,) =>
    <IAddUserPartner>{ type: ADD_USER_PARTNER, payload: { partnerNetUid: netuid, user: user } };


export const SUCCESS_ADD_USER_PARTNER: string = 'SUCCESS_ADD_USER_PARTNER';
export interface IAddUserPartnerSuccess { type: 'SUCCESS_ADD_USER_PARTNER', payload: any }
export const AddUserPartnerSuccess = (user: any) =>
    <IAddUserPartnerSuccess>{ type: SUCCESS_ADD_USER_PARTNER, payload: user };

export const FAILED_ADD_USER_PARTNER: string = 'FAILED_ADD_USER_PARTNER';
export interface IAddUserPartnerFailed { type: 'FAILED_ADD_USER_PARTNER', payload: any }
export const AddUserPartnerFailed = () =>
    <IAddUserPartnerFailed>{ type: FAILED_ADD_USER_PARTNER };

export const PARTNER_UPDATE_FAILED: string = 'PARTNER_UPDATE_FAILED';
export interface IPartnerUpdateFailed { type: 'PARTNER_UPDATE_FAILED', payload: any }
export const UpdatePartnerFailed = () =>
    <IPartnerUpdateFailed>{ type: PARTNER_UPDATE_FAILED };

export const UPDATE_PARTNER: string = '[API] UPDATE_PARTNER';
export interface IUpadatePartners { type: '[API] UPDATE_PARTNER', payload: any }
export const UpdatePartner = (partner: Partner) =>
    <IUpadatePartners>{ type: UPDATE_PARTNER, payload: partner };

export const PARTNER_UPDATE_SUCCESS: string = 'PARTNER_UPDATE_SUCCESS';
export interface IPartnerUpdateSuccess { type: 'PARTNER_UPDATE_SUCCESS', payload: any }
export const UpdatePartnerSuccess = (partner: Partner) =>
    <IPartnerUpdateSuccess>{ type: PARTNER_UPDATE_SUCCESS, payload: partner };

export const GET_PARTNER: string = '[API] GET_PARTNER';
export interface IGetPartner { type: '[API] GET_PARTNER', payload: any }
export const GetPartner = (netuid: string) =>
    <IGetPartner>{ type: GET_PARTNER, payload: netuid };

export const GET_PARTNER_SUCCESS: string = 'GET_PARTNER_SUCCESS';
export interface IGetPartnerSuccess { type: 'GET_PARTNER_SUCCESS', payload: any }
export const GetPartnerSuccess = (partner: any, user: any, role: any) =>
    <IGetPartnerSuccess>{ type: GET_PARTNER_SUCCESS, payload: { partner, user, role } };

export const GET_PARTNER_FAILED: string = 'GET_PARTNER_FAILED';
export interface IGetPartnerFailed { type: 'GET_PARTNER_FAILED', payload: any }
export const GetPartnerFailed = () =>
    <IGetPartnerFailed>{ type: GET_PARTNER_FAILED };

export const OPEN_PARTNER_USER_RV: string = 'OPEN_PARTNER_USER_RV';
export interface IOpenPartnerUserRv { type: 'OPEN_PARTNER_USER_RV', payload: any }
export const OpenPartnerUserRv = (isOpen: boolean, component: any, title: string) =>
    <IOpenPartnerUserRv>{ type: OPEN_PARTNER_USER_RV, payload: { isOpen: isOpen, component: component, title: title } };

export const CLOSE_PARTNER_USER_RV: string = 'CLOSE_PARTNER_USER_RV';
export interface IClosePartnerUserRv { type: 'CLOSE_PARTNER_USER_RV', payload: any }
export const ClosePartnerUserRv = () =>
    <IClosePartnerUserRv>{ type: CLOSE_PARTNER_USER_RV };

export const UPDATE_PARTNER_USER_NAME: string = '[API] UPDATE_PARTNER_USER_NAME';
export interface IUpdatePartnerUserName { type: '[API] UPDATE_PARTNER_USER_NAME', payload: any }
export const UpdatePartnerUserName = (oldUserName: string, newUserName: string) =>
    <IUpdatePartnerUserName>{ type: UPDATE_PARTNER_USER_NAME, payload: { oldUserName: oldUserName, newUserName: newUserName } };

export const UPADATE_PARTNER_PASSWORD_FORCE: string = '[API] UPADATE_PARTNER_PASSWORD_FORCE';
export interface IUpdatePartnerPasswordForce { type: '[API] UPADATE_PARTNER_PASSWORD_FORCE', payload: any }
export const UpdatePartnerPasswordForce = (userName: string, password: string) =>
    <IUpdatePartnerPasswordForce>{ type: UPADATE_PARTNER_PASSWORD_FORCE, payload: { userName: userName, password: password } };

export const CHANGE_MINIMUL_PRICE_LEVEL: string = 'CHANGE_MINIMUL_PRICE_LEVEL';
export interface IChangeMinimumPriceLevel { type: 'CHANGE_MINIMUL_PRICE_LEVEL', payload: any }
export const ChangeMinimumPriceLevel = (value: any) =>
    <IChangeMinimumPriceLevel>{ type: CHANGE_MINIMUL_PRICE_LEVEL, payload: value };
export const CHANGE_POST_OFFICE: string = 'CHANGE_POST_OFFICE';
export interface IChangePostOffice { type: 'CHANGE_POST_OFFICE', payload: any }
export const ChangePostOffice = (value: any) =>
    <IChangePostOffice>{ type: CHANGE_POST_OFFICE, payload: value };

export const CHANGE_CITY: string = 'CHANGE_CITY';
export interface IChangeCity { type: 'CHANGE_CITY', payload: any }
export const ChangeCity = (value: any) =>
    <IChangeCity>{ type: CHANGE_CITY, payload: value };

export const UNMOUNT_PARTNER_INFO: string = 'UNMOUNT_PARTNER_INFO';
export interface IUnmountPartnerInfo { type: 'UNMOUNT_PARTNER_INFO', payload: any }
export const UnmountPartnerInfo = () =>
    <IUnmountPartnerInfo>{ type: UNMOUNT_PARTNER_INFO };

export const SELECT_ROLE: string = 'SELECT_ROLE';
export interface ISelectRole { type: 'SELECT_ROLE', payload: any }
export const SelectRole = (role) =>
    <ISelectRole>{ type: SELECT_ROLE, payload: role };

export const UPADTE_ROLE: string = '[API] UPADTE_ROLE';
export interface IUpdateRole { type: '[API] UPADTE_ROLE', payload: any }
export const UpdateRole = (netUid, role) =>
    <IUpdateRole>{ type: UPADTE_ROLE, payload: { netUid, role } };

export const CHANGE_PARTNER_PAYMENT_METHOD: string = 'CHANGE_PARTNER_PAYMENT_METHOD';
export interface IChangePartnerPaymentMethod { type: 'CHANGE_PARTNER_PAYMENT_METHOD', payload: any }
export const ChangePartnerPaymentMethod = (value: any) =>
    <IChangePartnerPaymentMethod>{ type: CHANGE_PARTNER_PAYMENT_METHOD, payload: value };

export type PartnerKnownActions =
    IAddUserPartner |
    IAddUserPartnerSuccess |
    IAddUserPartnerFailed |
    IUpadatePartners |
    IPartnerUpdateSuccess |
    IPartnerUpdateFailed |
    IGetPartner |
    IGetPartnerSuccess |
    IOpenPartnerUserRv |
    IClosePartnerUserRv |
    IUpdatePartnerUserName |
    IUpdatePartnerPasswordForce |
    ISelectRole |
    IUpdateRole |
    IChangeMinimumPriceLevel |
    IChangePostOffice |
    IChangeCity |
    IChangePartnerPaymentMethod;