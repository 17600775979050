import * as API from '../../constants/api.constants';
import * as statisticsActionTypes from '../../actions/cabinet/statistics.actions';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { ErrorHandler } from '../../helpers/error.manager';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import storeProvider from '../../helpers/store.provider';
import { ajax } from 'rxjs/ajax';
import Partner from '../../business.entities/partner';
import * as React from 'react';
import Chart from "chart.js"
import { GetStatisticsTypesForDiagrams, DiagramStatsEnum } from '../../helpers/enums/diagram.stats.enum';
import { List } from 'linqts';

export const GetFinanceStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_FINANCE_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_FINANCES_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetProductionStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_PRODUCTION_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_PRODUCTION_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetLaserStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_LASER_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_LASER_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const GetPrintStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_PRINT_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_PRINT_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetPaymentStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_PAYMENT_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_PAYMENT_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetDeliveryStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_DELIVERY_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_DELIVERY_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetPartnerStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_PARNTER_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_PARTNERS_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetClientStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_CLIENT_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_CLIENT_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetOrderStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_ORDER_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_ORDER_STATS}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetFinishedProductsStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_FINISHED_PRODUCTS_STATS)
        .switchMap(action =>
            ajax.post(`${API.GET_FINISHED_ORDER_PRODUCT}`,
                {
                    PartnerId: action.payload.partnerId,
                    PartnerUid: action.payload.partnerUid,
                    Start: action.payload.start,
                    End: action.payload.end
                },
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    return statisticsActionTypes.SetStats(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetPartnerAutocomplete = (action$, store) =>
    action$.ofType(statisticsActionTypes.GET_PARTNERS_FOR_FILTER)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PARTNER_AUTOCOMPLETE}?value=${action.payload ? action.payload : ''}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    let arrayToReturn: any[] = response.Body.map((element: Partner) => {
                        return { value: element.NetUid.toString(), label: element.ContactName, partnerId: element.Id };
                    });

                    return statisticsActionTypes.SetPartnersForFilter(arrayToReturn);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetFinancesDiagrams = (action$, store) =>
    action$.ofType(statisticsActionTypes.GET_FINANCES_DIAGRAM)
        .switchMap(action =>
            Observable.ajax.post(`${API.GET_FINANCES_DIAGRAM}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .map((response: any) => {
                    let canvasArray = [];

                    return statisticsActionTypes.SetDiagram(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetOrdersDiagrams = (action$, store) =>
    action$.ofType(statisticsActionTypes.GET_ORDERS_DIAGRAM)
        .switchMap(action =>
            Observable.ajax.post(`${API.GET_ORDERS_DIAGRAM}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .map((response: any) => {
                    let canvasArray = [];

                    return statisticsActionTypes.SetDiagram(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetFinishedOrderProductsDiagrams = (action$, store) =>
    action$.ofType(statisticsActionTypes.GET_FINISHED_ORDER_PRODUCTS_DIAGRAM)
        .switchMap(action =>
            Observable.ajax.post(`${API.GET_FINISHED_ORDER_PRODUCT_DIAGRAM}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, 'Content-Type': 'application/json' })
                .map((response: any) => {
                    let canvasArray = [];

                    return statisticsActionTypes.SetDiagram(response.response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );


export const GetLexicons = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_LEXICONS)
        .switchMap(action =>
            ajax.getJSON(`${API.GET_ALL_LEXICONS}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    let diagrams = Object.assign([], storeProvider.getStore().getState().cabinet.statistics.DiagramTypes);

                    const item = new List<any>(diagrams).FirstOrDefault(c => c.value === DiagramStatsEnum.FinishedProducts);
                    const index = diagrams.indexOf(item);

                    diagrams[index].subOptions = response.Body.map(c => { return { label: c.OfficialPartnerName, value: c.Id } });

                    return statisticsActionTypes.SetDiagramOptions(diagrams);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );