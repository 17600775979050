import * as React from 'react';
import Product from '../../../../../business.entities/product';
import Select from 'react-select';
import {bindActionCreators} from 'redux';
import {
    CancelCurrentOrderProduct,
    ChangeProductSelectionMode,
    ConfirmProductSelect,
    RemoveOrderProductServer,
    ResetProductUpdateMode,
    SelectCurrentProduct,
    SetProductUpdateMode,
    SetNextSubStep,
    SelectCategory,
    GetSubCategories,
    SelectSubCategory,
    GetProducts,
    SelectOrderProduct,
    SetPattern,
    ConfirmOrderProductSelection,
    GetBasesForOrderProduct,
    SetBasesForProduct,
    UpdateOrderProductServer,
    SetForceWholesale,
    RefreshOrderProduct,
    GetProductBasesForOrderCard,
    GetAdditionalServiceProducts
} from '../../../../../actions/sales/order.card.actions';
import {
    ColorSaleType,
    DesignPrintType,
    PrintProductionType,
    PrintType,
    ProductionType,
    SaleType,
    ProductionTypeWithOptions,
    PrintTypeWithOptions,
    PrintProductionTypeWithOptions,
    DesignPrintTypeWithOptions,
    SaleTypeWithOptions,
    ColorSaleTypeWithOptions, LaserTypeWithImage, LaserTypeWithOptions
} from '../../../../../helpers/adding.product.patterns';
import {connect} from 'react-redux';
import {GetNameForOrderStatus} from '../../../../../helpers/enums/order.status.enum';
import {GetOrderProductStepComponent, GetOrderProductOrderCardStepComponent} from '../../../../../helpers/enums/order.product.steps';
import {List} from 'linqts';
import {Order} from '../../../../../business.entities/order';
import {OrderCardState, ProductUpdateState} from '../../../../../states/sales/order.card.state';
import {OrderProductStep as OrderProductStepEnum} from '../../../../../helpers/enums/order.product.steps';
import {ProductTypeEnum} from '../../../../../helpers/enums/product.type.enum';
import {OrderProduct} from '../../../../../business.entities/order.product';
import {OrderTypeEnum} from '../../../../../helpers/enums/order.type.enum';
import {PrintStatusEnum} from '../../../../../helpers/enums/print.status.enum';
import {ProductionStatusEnum} from '../../../../../helpers/enums/production.status';
import PermissionChecker from '../../../../shared/permission.checker.component';
import {IdentityRoleEnum, AllIdentityRolesExept, GetIdentityRoleName} from '../../../../../helpers/identity.roles';
import * as API from '../../../../../constants/api.constants';
import {IsPartner} from '../../../../../helpers/role.validator';
import {ShowHoverBlock} from '../../../../../actions/right.view.actions';
import {LaserStatusEnum} from '../../../../../helpers/enums/laser.status.enum';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard,
        Authentication: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SetProductUpdateMode,
    ResetProductUpdateMode,
    ChangeProductSelectionMode,
    RemoveOrderProductServer,
    CancelCurrentOrderProduct,
    SelectCurrentProduct,
    ConfirmProductSelect,
    SetNextSubStep,
    SelectCategory,
    GetSubCategories,
    SelectSubCategory,
    GetProducts,
    SelectOrderProduct,
    SetPattern,
    ConfirmOrderProductSelection,
    GetBasesForOrderProduct,
    SetBasesForProduct,
    UpdateOrderProductServer,
    SetForceWholesale,
    RefreshOrderProduct,
    ShowHoverBlock,
    GetProductBasesForOrderCard,
    GetAdditionalServiceProducts
}, dispatch);

const OrderProductUpdateComponent = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const order: Order = orderCard.order;
    const orderProductUpdate: ProductUpdateState = props.OrderCard.productUpdateSubState;

    document.onkeydown = (e) => {
        if ((event as any).ctrlKey && (event as any).keyCode == 70) {
            event.preventDefault();
        }

        ProductKeyBoardLogic();

        CancelLogic();
    }

    const ProductKeyBoardLogic = () => {
        if (!orderProductUpdate.orderProductSubStep) {

            if ((event as any).ctrlKey && (event as any).keyCode == 70) {
                event.preventDefault();
                orderProductUpdate.categorySelectRef.current.focus();
            }
            if (orderProductUpdate.categorySelectRef.current
                && orderProductUpdate.subcategorySelectRef.current)
                if (!orderProductUpdate.categorySelectRef.current.state.menuIsOpen
                    && !orderProductUpdate.subcategorySelectRef.current.state.menuIsOpen
                    && orderProductUpdate.productList.length > 0) {

                    if ((event as any).keyCode == 38) {
                        event.preventDefault();

                        orderProductUpdate.categorySelectRef.current.blur();
                        orderProductUpdate.subcategorySelectRef.current.blur();

                        if (!orderProductUpdate.orderProductSubStep) {
                            orderProductUpdate.productSelectionMode ?
                                OnSelectTopProduct() :
                                OnSelectTopOrderProduct();
                        }
                    }

                    if ((event as any).keyCode == 40) {
                        event.preventDefault();

                        orderProductUpdate.categorySelectRef.current.blur();
                        orderProductUpdate.subcategorySelectRef.current.blur();

                        if (!orderProductUpdate.orderProductSubStep) {
                            orderProductUpdate.productSelectionMode ?
                                OnSelectBottomProduct() :
                                OnSelectBottomOrderProduct();
                        }
                    }

                    if (orderProductUpdate.currentOrderProducts.length > 0) {
                        if ((event as any).keyCode === 37) {
                            event.preventDefault();
                            props.ChangeProductSelectionMode(true);
                        }

                        if ((event as any).keyCode === 39) {
                            event.preventDefault();
                            props.ChangeProductSelectionMode(false);
                        }

                        if ((event as any).keyCode === 46 && !orderProductUpdate.serverActionInProcess) {
                            event.preventDefault();
                            !orderProductUpdate.productSelectionMode &&
                            props.RemoveOrderProductServer(orderProductUpdate.currentOrderProducts[props.Wizard.orderProductIndex]);

                            if (orderProductUpdate.currentOrderProducts.length > 1) {
                                let startIndex = 0;

                                if (orderProductUpdate.orderProductIndex !== 0) {
                                    startIndex = orderProductUpdate.currentOrderProducts.length - 2;
                                }

                                props.SelectOrderProduct(startIndex);
                            }

                            if (orderProductUpdate.currentOrderProducts.length === 1) {
                                props.SelectOrderProduct(0);
                                props.ChangeProductSelectionMode(true);
                            }
                        }
                    }

                    if ((event as any).keyCode == 13 && orderProductUpdate.currentProduct) {
                        event.preventDefault();

                        if (!orderProductUpdate.orderProductSubStep) {
                            orderProductUpdate.productSelectionMode ?
                                OnProductSelectConfirm() :
                                OnConfirmSelectOrderProduct();
                        }
                    }
                }
        }
    }

    const CancelLogic = () => {
        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (orderProductUpdate.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }
    }

    const OnSelectTopOrderProduct = () => {
        let startIndex = orderProductUpdate.orderProductIndex ? orderProductUpdate.orderProductIndex : 0;

        startIndex != 0 ? startIndex-- : startIndex = orderProductUpdate.currentOrderProducts.length - 1;

        props.SelectOrderProduct(startIndex);
    }

    const OnSelectBottomOrderProduct = () => {
        let startIndex = orderProductUpdate.orderProductIndex ? orderProductUpdate.orderProductIndex : 0

        startIndex != orderProductUpdate.currentOrderProducts.length - 1 ? startIndex++ : startIndex = 0;

        props.SelectOrderProduct(startIndex);
    }

    const OnSelectTopProduct = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentProduct) {
            startIndex = orderProductUpdate.productList.indexOf(orderProductUpdate.currentProduct);
            startIndex != 0 ? startIndex-- : startIndex = orderProductUpdate.productList.length - 1;
        }

        OnSelectProductCommand(startIndex);
    }

    const OnSelectBottomProduct = () => {
        let startIndex = 0;

        if (orderProductUpdate.currentProduct) {
            startIndex = orderProductUpdate.productList.indexOf(orderProductUpdate.currentProduct);
            startIndex != orderProductUpdate.productList.length - 1 ? startIndex++ : startIndex = 0;
        }

        OnSelectProductCommand(startIndex);
    }

    const OnSelectProductCommand = (index: number) => {
        orderProductUpdate.productList && props.SelectCurrentProduct(orderProductUpdate.productList[index]);
        document.getElementById(orderProductUpdate.productList[index].Id.toString()).focus();
    }

    const OnSelectProductConfirmByMouse = (index) => {
        props.SelectCurrentProduct(orderProductUpdate.productList[index]);

        if (orderProductUpdate.productList[index]) {
            props.ConfirmProductSelect();
            props.GetAdditionalServiceProducts();
            const pattern = GetPatternForCurrentProduct(orderProductUpdate.productList[index]);

            if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
                const baseProducts = new List(orderProductUpdate.productList)
                    .Where(c => c.Lexicon.CanBeBase && c.Lexicon.ProductTypeId === 1)
                    .ToArray();

                props.SetBasesForProduct(baseProducts);
            }

            props.SetPattern(pattern);

            if (pattern[0] === OrderProductStepEnum.Price) {
                if (!orderProductUpdate.productList[index].Lexicon.HasColors) {

                    let similarOrderProduct = null;
                    if (orderProductUpdate.productList[index].Lexicon.ProductTypeId === ProductTypeEnum.Sale)
                        similarOrderProduct = new List(orderProductUpdate.currentOrderProducts)
                            .Where(c => c.ProductId === orderProductUpdate.productList[index].Id)
                            .FirstOrDefault();

                    //select similar product...
                    if (similarOrderProduct)
                        props.ConfirmOrderProductSelection(similarOrderProduct);
                }
            }

            props.SetNextSubStep(pattern[0], GetOrderProductOrderCardStepComponent(pattern[0]));
        }
    }

    const OnCategorySelectCommand = (value: any) => {
        props.SelectCategory(value);
        props.GetSubCategories(value ? value.value : null, '');
        orderProductUpdate.subcategorySelectRef.current.focus();
    }

    const OnSubCategorySelectCommand = (value: any) => {
        props.SelectSubCategory(value);
        props.GetProducts(orderProductUpdate.selectedCategory ? orderProductUpdate.selectedCategory.value : null, value.value, order.NetUid);
        orderProductUpdate.subcategorySelectRef.current.blur();
    }

    const OnProductSelectConfirm = () => {
        if (orderProductUpdate.currentProduct) {
            props.ConfirmProductSelect();
            props.GetAdditionalServiceProducts();

            const pattern = GetPatternForCurrentProduct(orderProductUpdate.currentProduct);

            if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
                const baseProducts = new List(orderProductUpdate.productList)
                    .Where(c => c.Lexicon.CanBeBase && c.Lexicon.ProductTypeId === 1)
                    .ToArray();

                props.SetBasesForProduct(baseProducts);
            }

            props.SetPattern(pattern);

            if (pattern[0] === OrderProductStepEnum.Price) {
                if (!orderProductUpdate.currentProduct.Lexicon.HasColors) {

                    let similarOrderProduct = null;
                    if (orderProductUpdate.currentProduct.Lexicon.ProductTypeId === ProductTypeEnum.Sale)
                        similarOrderProduct = new List(orderProductUpdate.currentOrderProducts)
                            .Where(c => c.ProductId === orderProductUpdate.currentProduct.Id)
                            .FirstOrDefault();

                    //select similar product...
                    if (similarOrderProduct)
                        props.ConfirmOrderProductSelection(similarOrderProduct);
                }
            }

            props.SetNextSubStep(pattern[0], GetOrderProductOrderCardStepComponent(pattern[0]));
        }
    }

    const OnConfirmSelectOrderProduct = async () => {
        if (!IsEnableCangeAction(orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex]))
            return;

        props.ConfirmOrderProductSelection(orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex]);

        const pattern = GetPatternForCurrentProduct(orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product);

        if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
            await props.SetBasesForProduct([]);
            await props.GetProductBasesForOrderCard(
                orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product.LexiconId,
                orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product.CategoryId,
                orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product.SubCategoryId,
            )
            // props.GetBasesForOrderProduct(
            //     orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product.CategoryId,
            //     orderProductUpdate.currentOrderProducts[orderProductUpdate.orderProductIndex].Product.SubCategoryId);
            }

        props.SetPattern(pattern);

        props.SetNextSubStep(pattern[0], GetOrderProductOrderCardStepComponent(pattern[0]));
    }

    const OnConfirmSelectOrderProductByMouse = async (orderProduct) => {
        if (!IsEnableCangeAction(orderProduct))
            return;

        const index = orderProductUpdate.currentOrderProducts.indexOf(orderProduct);

        props.SelectOrderProduct(index);

        props.ConfirmOrderProductSelection(orderProduct);

        const pattern = GetPatternForCurrentProduct(orderProduct.Product);

        if (pattern.indexOf(OrderProductStepEnum.BaseProduct) != -1) {
            await props.SetBasesForProduct([]);
            await props.GetProductBasesForOrderCard(
                orderProduct.Product.LexiconId,
                orderProduct.Product.CategoryId,
                orderProduct.Product.SubCategoryId
            )
        }
            //props.GetBasesForOrderProduct(orderProduct.Product.CategoryId, orderProduct.Product.SubCategoryId);

        props.SetPattern(pattern);

        props.SetNextSubStep(pattern[0], GetOrderProductOrderCardStepComponent(pattern[0]));
    }

    const GetPatternForCurrentProduct = (product) => {
        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Sale) {
            if (product.Lexicon.HasColors || product.Lexicon.HasOptions)
                if (product.Lexicon.HasAdditionalOptions)
                    return ColorSaleTypeWithOptions;
                else
                    return ColorSaleType;

            if (!product.Lexicon.HasColors)
                if (product.Lexicon.HasAdditionalOptions)
                    return SaleTypeWithOptions;
                else
                    return SaleType;
        }

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Print) {
            if (product.Lexicon.HasDesigns)
                if (product.Lexicon.HasAdditionalOptions)
                    return DesignPrintTypeWithOptions;
                else
                    return DesignPrintType;

            if (product.Lexicon.CanHaveUserImg)
                if (product.Lexicon.HasAdditionalOptions)
                    return PrintTypeWithOptions;
                else
                    return PrintType;
        }

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Production)
            if (product.Lexicon.HasAdditionalOptions)
                return ProductionTypeWithOptions;
            else
                return ProductionType;

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction)
            if (product.Lexicon.HasAdditionalOptions)
                return PrintProductionTypeWithOptions;
            else
                return PrintProductionType;

        if (product.Lexicon.ProductTypeId === ProductTypeEnum.Laser) {
            if (product.Lexicon.CanHaveUserImg)
                return LaserTypeWithImage;
            else
                return LaserTypeWithOptions;
        }
    }


    const IsProduction = (product: Product) => {
        if (product.Lexicon.ProductTypeId === 3 || product.Lexicon.ProductTypeId === 4)
            return false;

        return true;
    }

    const OnRemoveOrderProductCommand = (orderProduct) => {
        !orderProductUpdate.serverActionInProcess &&
        props.RemoveOrderProductServer(orderProduct);
    }

    const OnEndProductUpdate = () => {
        props.SetProductUpdateMode(false, []);
        props.ResetProductUpdateMode();
    }

    const IsEnableCangeAction = (orderProduct: OrderProduct) => {
        if (props.Authentication.Role == GetIdentityRoleName(IdentityRoleEnum.Partner) && order.StockStatus.length > 0)
            return;

        if (orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.Print ||
            orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.ProductionAndPrint) {

            if (orderProduct.ProductPrintStatus.length > 0)
                if (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
                    return false;

            if (orderProduct.CanBeReused === false)
                return false;
        }

        if (orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.Production)
            if (orderProduct.ProductProductionStatus.length > 0)
                if (orderProduct.ProductProductionStatus[0].Status === ProductionStatusEnum.Producted)
                    return false;

        if (orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.Laser)
            if (orderProduct.ProductLaserStatus.length > 0)
                if (orderProduct.ProductLaserStatus[0].Status === LaserStatusEnum.Producted)
                    return false;

        return true;
    }

    const OnChangeForceWholesalePrice = (orderProduct: OrderProduct) => {
        if (orderProduct.ProductPrintStatus.length > 0 && orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
            return;

        if (!orderProductUpdate.serverActionInProcess) {
            let updatedProduct = Object.assign({}, orderProduct,
                {
                    ForceWholesale: !orderProduct.ForceWholesale
                } as OrderProduct);

            props.SetForceWholesale(updatedProduct);
        }
    }

    const IsPrintAndSuccess = (orderProduct: OrderProduct) => {
        if (orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.Print ||
            orderProduct.Product.Lexicon.ProductTypeId === OrderTypeEnum.ProductionAndPrint) {

            if (orderProduct.ProductPrintStatus.length > 0) {
                if (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
                    return true;

                if (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                    orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter)
                    return false;
            }
        }

        return null;
    }

    const OnGetSubCategoriesAutocomplete = (value) => {
        props.GetSubCategories(orderProductUpdate.selectedCategory ? orderProductUpdate.selectedCategory.value : null, value);
    }

    const OnOpenConstructorCommand = (orpderProduct) => {
        if (!IsEnableCangeAction(orpderProduct))
            return;

        window.open(`${API.PRINT_URL}${orpderProduct.Product.ProductCode}/${orpderProduct.Id}/false/${props.Authentication.UserNetUid}/${order.NetUid}`, '_self');
    }

    const OnRefreshProductCommand = (netId, index) => {
        props.RefreshOrderProduct(netId, index);
    }

    return (
        <div className="createOrced__container orderPUpdateComponent">
            <div className="content_header_component">
                <div className="page_title">
                    <span className="title_default">{order.OrderCode}</span>
                    <span className="title_description">
                        Статус:{' '}
                        {order.OrderStatus.length > 0 ? GetNameForOrderStatus(order.OrderStatus[0].Status, IsPartner(props.Authentication)) : null}
                    </span>
                </div>

                <div className="page_title priceHeaderContainer">
                    <span className="title_default">{order.PartnerTotalAmount} {' | '} {order.TotalAmount}</span>
                    <span className="title_description">
                        Цена
                    </span>
                </div>

                <div className="header__button__controls">
                    <div className="button__control" onClick={OnEndProductUpdate}>Закрыть</div>
                </div>
            </div>

            <div className="orderProductStep__container">
                <div className="orderProductStepHeader__container">
                    <div className="controlSelect">
                        <Select
                            ref={orderProductUpdate.categorySelectRef}
                            name="category"
                            openMenuOnFocus={true}
                            isClearable
                            clearValue={() => {
                                OnCategorySelectCommand(null)
                            }}
                            options={orderProductUpdate.categoryList}
                            onChange={(value) => OnCategorySelectCommand(value)}
                            value={orderProductUpdate.selectedCategory}
                            placeholder="Бренд"
                        />
                    </div>

                    <div className="controlSelect">
                        <Select
                            ref={orderProductUpdate.subcategorySelectRef}
                            openMenuOnFocus={true}
                            name="subcategory"
                            options={orderProductUpdate.subCategoryList}
                            onChange={(value) => OnSubCategorySelectCommand(value)}
                            value={orderProductUpdate.selectedSubCategory}
                            onInputChange={OnGetSubCategoriesAutocomplete}
                            placeholder="Модель"
                        />
                    </div>
                </div>

                <div className="orderProductStepContent__container">
                    <div className="productList__container">
                        {orderProductUpdate.productList && orderProductUpdate.productList.map((product, index) =>
                            <div
                                className={`productList__item ${(orderProductUpdate.productSelectionMode && orderProductUpdate.currentProduct.Id === product.Id) ? 'isSelected' : ''}`}
                                key={index}
                                onClick={() => OnSelectProductConfirmByMouse(index)}>

                                <input type="text" className='tabIndex_hideControl' id={`${product.Id}`} tabIndex={-1}/>

                                <div className="productItem__information">
                                    <div className="code">
                                        <div className="label">{product.PartnerProductCode}</div>
                                    </div>
                                    <div className="name">
                                        <div className="nameWrapper">
                                            <div className="officialPartnerName">
                                                {product.Lexicon.OfficialPartnerName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="price">
                                        <div className="label">{product.PartnerPrice}</div>
                                    </div>
                                </div>
                                <div className="productItem__colors">
                                    {product.Lexicon.HasColors && product.ProductColors && product.ProductColors.map((productColor, colorIndex) =>
                                        <div className="colorItem" key={`${index}-${colorIndex}`}>
                                            <div className={`dotStyle ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                                                 style={{backgroundColor: productColor.Color.Code}}>
                                                <div className="hide">
                                                    {productColor.Color.Name}
                                                </div>
                                                <div className="qty">
                                                    {IsProduction(product) ? productColor.Quantity : null}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {product.Lexicon.HasOptions && product.ProductColors && product.ProductColors.map((productColor, colorIndex) =>
                                        <div className="colorItem" key={`${index}-${colorIndex}`}>
                                            <div className={`dotStyle imgThumb__WRAPPER`} style={{backgroundImage: `url(${API.CRM_URL + '/' + productColor.Color.Thumb})`}}
                                                 onMouseEnter={(e) => {
                                                     e.stopPropagation();
                                                     props.ShowHoverBlock(productColor.Color)
                                                 }}
                                                 onMouseLeave={(e) => {
                                                     e.stopPropagation();
                                                     props.ShowHoverBlock(null)
                                                 }}
                                            >
                                                <div className="hide">
                                                    {productColor.Color.Name}
                                                </div>
                                                <div className="qty">
                                                    {productColor.Quantity}
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* {product.ProductColors && product.ProductColors.map((productColor, colorIndex) =>
                                        <div className="colorItem" key={`${index}-${colorIndex}`}>

                                            <div className={`dotStyle ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`}
                                                style={{ backgroundColor: productColor.Color.Code == 'clear' ? '' : productColor.Color.Code }} >
                                                <div className="hide">
                                                    {productColor.Color.Name}
                                                </div>
                                                <div className="qty">
                                                    {IsProduction(product) ? productColor.Quantity : null}
                                                </div>
                                            </div>


                                        </div>
                                    )} */}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="orderProductsContent__container">
                        {(orderProductUpdate.currentOrderProducts.length > 0) && orderProductUpdate.currentOrderProducts.map((orderProduct, index) =>
                            <div
                                className={`productList__item productListQTY__item ${(index === orderProductUpdate.orderProductIndex && !orderProductUpdate.productSelectionMode) ? 'isSelected' : ''}
                                ${IsPrintAndSuccess(orderProduct) === true ? 'isSucc' : ''} ${IsPrintAndSuccess(orderProduct) === false ? 'isNotSucc' : ''}`}
                                key={index}>

                                {orderProduct.ProductType == ProductTypeEnum.Print ?
                                    <>
                                        <div className="goToConstructor" onClick={() => OnOpenConstructorCommand(orderProduct)}/>
                                        {/* <div className="goToConstructorRef" onClick={() => OnRefreshProductCommand(orderProduct.NetUid, index)} /> */}
                                    </>
                                    : null}

                                <div className={`orderProduct__modalHeader ${orderProduct.BaseProduct ? 'ddd' : ''}`}>
                                    <div className="brandContainer" onClick={() => OnConfirmSelectOrderProductByMouse(orderProduct)}>
                                        <div className="qtyWContainer ">
                                            <div className="value">{orderProduct.Quantity}</div>
                                            <div className="label">Кол-во</div>
                                        </div>
                                        <div className="brand">{orderProduct.Product.Category.Name}</div>
                                        <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                        <div className="lexiconContainer">
                                            <div className="lexiconWrapper">
                                                <div className="lexiconItem">
                                                    <div className="lexLabel">{'НАИМЕНОВАНИЕ: '}</div>
                                                    {/* {orderProduct.Color &&
                                                        <div className="lexValueColor">
                                                            <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code == 'clear' ? '' : orderProduct.Color.Code }} />
                                                            <div className="colorBgLabel">
                                                                ({orderProduct.Color.Name})
                                                        </div>
                                                        </div>
                                                    } */}

                                                    {orderProduct.Product.Lexicon.HasColors && orderProduct.Color &&
                                                    <div className="lexValueColor">
                                                        <div className="colorBg" style={{backgroundColor: orderProduct.Color.Code}}/>
                                                        <div className="colorBgLabel">
                                                            ({orderProduct.Color.Name})
                                                        </div>
                                                    </div>
                                                    }

                                                    {orderProduct.Product.Lexicon.HasOptions && orderProduct.Color &&
                                                    <div className="lexValueColor">
                                                        <div className="colorBg imgThumb__WRAPPER" style={{backgroundImage: `url(${API.CRM_URL + '/' + orderProduct.Color.Thumb})`}}
                                                             onMouseEnter={(e) => {
                                                                 e.stopPropagation();
                                                                 props.ShowHoverBlock(orderProduct.Color)
                                                             }}
                                                             onMouseLeave={(e) => {
                                                                 e.stopPropagation();
                                                                 props.ShowHoverBlock(null)
                                                             }}
                                                        ></div>
                                                        <div className="colorBgLabel">
                                                            ({orderProduct.Color.Name})
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="lexLabel hide">{'Товар лекс: '}</div>
                                                    <div className="lexValue">
                                                        <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                                    </div>
                                                </div>
                                                {orderProduct.BaseProduct &&
                                                <div className="lexiconItem">
                                                    <div className="lexLabel">{'ОСНОВА:'}</div>
                                                    {orderProduct.BaseColor &&
                                                    <div className="lexValueColor">
                                                        <div className="colorBg"
                                                             style={{backgroundColor: orderProduct.BaseColor.Code == 'clear' ? '' : orderProduct.BaseColor.Code}}/>
                                                        <div className="colorBgLabel">
                                                            ({orderProduct.BaseColor.Name})
                                                        </div>
                                                    </div>
                                                    }
                                                    <div className="lexValue">
                                                        <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                    </div>
                                                </div>
                                                }


                                                <div className="lexiconItem ">
                                                    <div className="lexLabel">Цена для партнера</div>
                                                    <div className="lexValue pTotal__value">{orderProduct.PartnerTotal}</div>
                                                </div>
                                                <div className="lexiconItem ">
                                                    <div className="lexLabel">Цена для клиента</div>
                                                    <div className="lexValue pTotal__value">{orderProduct.Total}</div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                    <div className="brandContainer ops__brandContainer">
                                        <div className="lexiconContainer">
                                            <div className="lexiconWrapper">
                                                <div className="lexiconItem">
                                                    <details open className="lexiconItem__DETAILS">
                                                        <summary>Доп опции</summary>
                                                        {
                                                            orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                <div className="lexiconItem"
                                                                     onMouseEnter={(e) => {
                                                                         e.stopPropagation();
                                                                         props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                     }}
                                                                     onMouseLeave={(e) => {
                                                                         e.stopPropagation();
                                                                         props.ShowHoverBlock(null)
                                                                     }}>
                                                                    <div className="lexLabel">{orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                    <div className="lexValue">
                                                                        <span>{orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption.Name}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    </details>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }


                                    {!IsProduction(orderProduct.Product) ?
                                        <div className="brandContainer ops__brandContainer">
                                            <div className="defaultFormControl">
                                                <div className="defaultFormControl__label">Комментарий</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {orderProduct.ProductionComment}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null
                                    }
                                </div>

                                <PermissionChecker
                                    Classes='opuComRowContainer'
                                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                    <label className="checkbox_control orderPupCheck">
                                        <input type="checkbox" checked={orderProduct.ForceWholesale} onClick={() => OnChangeForceWholesalePrice(orderProduct)}/>
                                        <div className="checkbox_icon"/>
                                        <div className="checkbox_lb">
                                            Оптовая цена
                                        </div>
                                    </label>
                                </PermissionChecker>

                                {IsEnableCangeAction(orderProduct) ?
                                    <div className="removeOrderProduct__button" onClick={() =>
                                        OnRemoveOrderProductCommand(orderProduct)}/>
                                    : null}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderProductUpdateComponent);