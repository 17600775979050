import { connect } from "react-redux";
import * as React from "react";
import Select from 'react-select';
import { bindActionCreators } from "redux";
import { PartnerInfoState } from "../../../../../states/partner/partner.info.state";
import { IdentityRolesForSelect } from "../../../../../helpers/identity.roles";
import { SelectRole, UpdateRole } from "../../../../../actions/partner/partner.info.actions"

const mapStateToProps = (state) => {
    return {
        partner: state.partner.partnerInfo,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        SelectRole,
        UpdateRole
    }, dispatch);
};

const ChangeRole = (props: any) => {
    const partnerInfo: PartnerInfoState = props.partner;

    const OnChangeRoleCommand = (value) => {
        props.SelectRole(value);
    }

    const OnUpdateRoleCommand = () => {
        props.UpdateRole(partnerInfo.user.NetId, partnerInfo.selectedRole.value);
    }

    return (
        <div className="default_form_component shadow_controls">
            <div className="form_row_component">
                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">Роль</div>
                    <div className="selectWrapper__container notPadd">
                        <Select
                            name="role"
                            options={IdentityRolesForSelect}
                            onChange={(value) => OnChangeRoleCommand(value)}
                            value={partnerInfo.selectedRole}
                        />
                    </div>
                </div>
            </div>

            <div className="defaultFormControl">
                <button className="defaultFormButton" onClick={() => OnUpdateRoleCommand()}>
                    Изменить
                </button>
            </div>
        </div>
    );
}
export default connect(
    mapStateToProps, mapDispatchToProps
)(ChangeRole);