import * as React from 'react';
import {
    AddFilter,
    AddOptions,
    GetRootCategories,
    GetSubCategories,
    SelectRootCategory,
    UpdateOptions,
    RemoveOptions,
    ChangeAvailableOnly
} from '../../../../actions/stock/price.list.options.actions';
import { GetPricelist, SelectContent } from '../../../../actions/stock/price.list.actions';
import { ApplicationState } from '../../../../reducers/index';
import { bindActionCreators } from 'redux';
import { Category } from '../../../../business.entities/category';
import { connect } from 'react-redux';
import { Lexicon } from '../../../../business.entities/lexicon';
import { List } from 'linqts';
import { LoginState } from '../../../../states/login.state';
import { PriceListFilterItem } from '../../../../business.entities/price.list.filter.item';
import { PricelistOptions } from '../../../../business.entities/price.list.options';
import { PriceListState } from '../../../../states/stock/price.list.state';
import { default as PriceListGrid } from "./price.list.grid";

export namespace PriceListOptions {
    export interface IProps {
        GetRootCategories(): void;
        GetSubCategories(categoryId: number): void;
        SelectRootCategory(category: Category): void;
        AddFilter(filter: PriceListFilterItem[]);
        AddOptions(options: PricelistOptions);
        UpdateOptions(options: PricelistOptions);
        RemoveOptions(netId: string);
        SelectContent(component: any): void;
        ChangeAvailableOnly(value: boolean);

        GetPricelist: any;
        PriceListState: PriceListState;
        AuthenticationState: LoginState;
    }

    export interface IState {
        SelectedCategory: Category;
    }
}

class PriceListOptions extends React.Component<PriceListOptions.IProps, PriceListOptions.IState> {
    constructor(props) {
        super(props);

        this.state = {
            SelectedCategory: null
        };

        this.props.GetRootCategories();
    }

    public onSelectParentCategory(categoryId) {
        if (new List(this.props.PriceListState.Categories).Where(c => c.Id === categoryId).Select(c => c.SubCategories).FirstOrDefault().length == 0) {
            if (new List(this.props.PriceListState.Categories).Any(c => c.Id === categoryId)) {
                this.props.GetSubCategories(categoryId);
            }
        } else {
            if (this.props.PriceListState.SelectedCategory && this.props.PriceListState.SelectedCategory.Id == categoryId) {
                this.props.SelectRootCategory(null);
            } else {
                this.props.SelectRootCategory(new List(this.props.PriceListState.Categories).Where(c => c.Id === categoryId).FirstOrDefault());
            }
        }
    }

    public onAddCategoryToFilter(category: Category) {
        let filter: PriceListFilterItem[] = Object.assign([], this.props.PriceListState.PriceListFilter);

        if (new List(filter).Any(c => c.CategoryId === category.Id)) {
            let index = filter.indexOf(new List(filter).FirstOrDefault(c => c.CategoryId === category.Id));
            filter.splice(index, 1);

            return this.props.AddFilter(filter);
        }

        if (new List(filter).Any(c => c.CategoryId === category.ParentId) &&
            new List(filter).FirstOrDefault(c => c.CategoryId === category.ParentId).SubCategoryIds.includes(category.Id)) {
            let filterItem = new List(filter).FirstOrDefault(c => c.CategoryId === category.ParentId);
            let rootIndex = filter.indexOf(filterItem);
            let subIndex = filter[rootIndex].SubCategoryIds.indexOf(category.Id)
            filter[rootIndex].SubCategoryIds.splice(subIndex, 1);

            return this.props.AddFilter(filter);
        }

        let itemToChange: PriceListFilterItem = null;
        let index: number = -1;

        if (!category.ParentId) {
            itemToChange = new List(filter).FirstOrDefault(c => c.CategoryId == category.Id);
        } else {
            itemToChange = new List(filter).FirstOrDefault(c => c.CategoryId == category.ParentId);
        }

        if (!itemToChange) {
            itemToChange = new PriceListFilterItem();
        } else {
            index = filter.indexOf(itemToChange);
        }

        if (!itemToChange.CategoryId)
            itemToChange.CategoryId = category.ParentId ? category.ParentId : category.Id;

        category.ParentId && itemToChange.SubCategoryIds.push(category.Id);

        if (index !== -1) {
            filter[index] = itemToChange;
        } else {
            filter.push(itemToChange);
        }

        this.props.AddFilter(filter);
    }

    public onSelectAllRootCategories() {
        let filter: PriceListFilterItem[] = [];
        let globalLexoiconFilter = new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId && c.SubCategoryIds.length === 0);

        if (this.props.PriceListState.PriceListFilter.filter(c => c.CategoryId).length === this.props.PriceListState.Categories.length) {
            if (globalLexoiconFilter) {
                filter.push(globalLexoiconFilter);
            }

            this.props.AddFilter(filter);
            return;
        }

        this.props.PriceListState.Categories.map((category) => {
            let filterItemToAdd = new PriceListFilterItem();
            filterItemToAdd.CategoryId = category.Id;
            let filterItem = new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => c.CategoryId === filterItemToAdd.CategoryId);

            filterItemToAdd.LexiconIds = filterItem ? filterItem.LexiconIds : [];
            filter.push(filterItemToAdd);
        });

        globalLexoiconFilter && filter.push(globalLexoiconFilter);

        this.props.AddFilter(filter);
    }

    public onSelectAllSubCategories() {
        if (this.props.PriceListState.SelectedCategory) {
            let filter: PriceListFilterItem[] = Object.assign([], this.props.PriceListState.PriceListFilter);
            let globalLexoiconFilter = new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId && c.SubCategoryIds.length === 0);
            let itemToChange: PriceListFilterItem = new List(filter).FirstOrDefault(c => c.CategoryId == this.props.PriceListState.SelectedCategory.Id);

            let index: number = -1;
            index = filter.indexOf(itemToChange);

            if (index === -1) {
                let filterItemToAdd = new PriceListFilterItem();
                filterItemToAdd.CategoryId = this.props.PriceListState.SelectedCategory.Id;
                filterItemToAdd.LexiconIds = [];

                let subCategories = new List(this.props.PriceListState.Categories)
                    .Where(c => c.Id === this.props.PriceListState.SelectedCategory.Id).FirstOrDefault().SubCategories;

                filterItemToAdd.SubCategoryIds = new List(subCategories).Select(c => c.Id).ToArray();

                filter.push(filterItemToAdd);
            }

            if (index != -1 && filter[index].SubCategoryIds.length === new List(this.props.PriceListState.Categories)
                .Where(c => c.Id === this.props.PriceListState.SelectedCategory.Id).FirstOrDefault().SubCategories.length) {
                filter[index].SubCategoryIds = [];

                this.props.AddFilter(filter);
                return;
            }

            if (index != -1) {
                let subCategories = new List(this.props.PriceListState.Categories)
                    .Where(c => c.Id === this.props.PriceListState.SelectedCategory.Id).FirstOrDefault().SubCategories;

                itemToChange.SubCategoryIds = new List(subCategories).Select(c => c.Id).ToArray();

                filter[index] = itemToChange;
            }

            this.props.AddFilter(filter);
        }
    }

    public onAddLexiconToFilter(lexiconId: number) {
        let filter: PriceListFilterItem[] = Object.assign([], this.props.PriceListState.PriceListFilter);

        if (new List(this.props.PriceListState.PriceListFilter).Any(c => !c.CategoryId))
            if (new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId).LexiconIds.includes(lexiconId)) {

                let filterItem: PriceListFilterItem = new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId);
                let fitlerIndex = filter.indexOf(filterItem);

                if (filter[fitlerIndex].LexiconIds.length > 1) {
                    let index = new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId).LexiconIds.indexOf(lexiconId);
                    filter[fitlerIndex].LexiconIds.splice(index, 1);
                } else {
                    filter.splice(fitlerIndex, 1);
                }

                this.props.AddFilter(filter);

                return;
            }

        let filterItem: PriceListFilterItem = new List(filter)
            .FirstOrDefault(c => c.CategoryId == null && c.SubCategoryIds.length == 0);

        let index = filter.indexOf(filterItem);

        if (!filterItem) {
            filterItem = new PriceListFilterItem();
        }

        filterItem.LexiconIds.push(lexiconId);

        if (index == -1) {
            filter.unshift(filterItem);
        } else {
            filter[index] = filterItem;
        }

        this.props.AddFilter(filter);
    }

    private SelectAllLexicons() {
        let filter: PriceListFilterItem[] = Object.assign([], this.props.PriceListState.PriceListFilter);
        let filterItem: PriceListFilterItem = new List(filter)
            .FirstOrDefault(c => !c.CategoryId);

        let index = filter.indexOf(filterItem);

        if (index === -1) {
            if (!filterItem)
                filterItem = new PriceListFilterItem();

            filterItem.LexiconIds = this.props.PriceListState.Lexicons.map(c => c.Id);
            filter.unshift(filterItem);
        } else {
            if (filter[index].LexiconIds.length === this.props.PriceListState.Lexicons.length) {
                filter.splice(index, 1);
            } else {
                filter[index].LexiconIds = this.props.PriceListState.Lexicons.map(c => c.Id);
            }
        }

        this.props.AddFilter(filter);
    }

    private GetSelectedLexiconsCount() {
        if (!this.props.PriceListState.PriceListFilter)
            return 0;

        const lexiconFilter = new List(this.props.PriceListState.PriceListFilter)
            .FirstOrDefault(c => !c.CategoryId);

        if (!lexiconFilter)
            return 0;

        return lexiconFilter.LexiconIds.length;
    }

    private isSubCategoryCheked(category: Category): boolean {
        if (!new List(this.props.PriceListState.PriceListFilter).Any(c => c.CategoryId === category.ParentId)) return false;
        return new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => c.CategoryId === category.ParentId).SubCategoryIds.includes(category.Id);
    }

    private isLexiconCheked(lexiocnId: number): boolean {
        if (!this.props.PriceListState.PriceListFilter)
            return false;

        if (this.props.PriceListState.PriceListFilter.length == 0)
            return false;

        if (new List(this.props.PriceListState.PriceListFilter).Any(c => !c.CategoryId)) {
            return new List(this.props.PriceListState.PriceListFilter).FirstOrDefault(c => !c.CategoryId).LexiconIds.includes(lexiocnId);
        } else
            return false;
    }

    private onSaveFilter() {
        let options = new PricelistOptions();
        options.PartnerId = this.props.AuthenticationState.userInfo.Id;
        options.PartnerNetId = this.props.AuthenticationState.userInfo.NetUid;
        options.Filter = JSON.stringify(this.props.PriceListState.PriceListFilter);
        options.AvailableOnly = this.props.PriceListState.AvailableOnly;

        this.props.AddOptions(options);
        this.props.GetPricelist(1, 40);
        // this.props.SelectContent(PriceListGrid);
    }

    private onUpdateFilter() {
        this.props.PriceListState.Options.Filter = JSON.stringify(this.props.PriceListState.PriceListFilter);
        this.props.PriceListState.Options.AvailableOnly = this.props.PriceListState.AvailableOnly;

        this.props.UpdateOptions(this.props.PriceListState.Options);
        this.props.GetPricelist(1, 40);
        // this.props.SelectContent(PriceListGrid);
    }

    private onRemoveFilter() {
        this.props.RemoveOptions(this.props.PriceListState.Options.NetUid);
        this.props.GetPricelist(1, 40);
        // this.props.SelectContent(PriceListGrid);
    }

    private GetSubCategoriesLength() {
        if (this.props.PriceListState.SelectedCategory) {
            const categoryFilter = new List(this.props.PriceListState.PriceListFilter)
                .FirstOrDefault(c => c.CategoryId === this.props.PriceListState.SelectedCategory.Id);

            if (categoryFilter) {
                const subs = categoryFilter.SubCategoryIds ? categoryFilter.SubCategoryIds : [];
                return subs.length;
            } else
                return 0;
        }

        return 0;
    }

    render() {
        const styleDiv = { position: 'absolute', zIndex: "1" } as any;

        return (
            <div className="price_list__grid__component">
                <div className="wrapper__col__4">

                    <div className="col_4__item">
                        <div className="list__wrapper__header_price">
                            <div className="header__control" onClick={() => { this.onSelectAllRootCategories() }}>
                                {this.props.PriceListState.Categories.length === this.props.PriceListState.PriceListFilter.filter(c => c.CategoryId).length ? 'Снять выбор всех' : 'Выбрать все'}
                            </div>
                        </div>
                        <div className="list__wrapper__price">
                            {this.props.PriceListState.Categories.length > 0 && this.props.PriceListState.Categories.map((category: Category, key: number) =>
                                <div className={`price_list__item ${this.props.PriceListState.SelectedCategory && category.Id === this.props.PriceListState.SelectedCategory.Id ? "is_selected" : ""}`} key={key} >
                                    <div className="item__name" onClick={() => this.onSelectParentCategory(category.Id)}>
                                        {category.Name}
                                    </div>

                                    <div className="plOption__check">
                                        <input type="checkbox"
                                            checked={
                                                this.props.PriceListState.PriceListFilter ?
                                                    new List(this.props.PriceListState.PriceListFilter).Any(c => c.CategoryId === category.Id) :
                                                    false}
                                            onChange={() => this.onAddCategoryToFilter(category)} />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {this.props.PriceListState.SelectedCategory &&
                        this.props.PriceListState.SelectedCategory.SubCategories.length > 0 &&
                        <div className="col_4__item">
                            <div className="list__wrapper__header_price">

                                <div className="header__control" onClick={() => { this.onSelectAllSubCategories() }}>
                                    {this.GetSubCategoriesLength() === this.props.PriceListState.SelectedCategory.SubCategories.length ?
                                        'Снять выбор всех' :
                                        'Выбрать все'}
                                </div>
                            </div>
                            <div className="list__wrapper__price">
                                {this.props.PriceListState.SelectedCategory.SubCategories.map((category: Category, key: number) =>
                                    <div className="price_list__item" key={key} >
                                        <div className="item__name">{category.Name}</div>
                                        <div className="plOption__check">
                                            <input type="checkbox"
                                                checked={this.isSubCategoryCheked(category)}
                                                onChange={() => this.onAddCategoryToFilter(category)} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    <div className="col_4__item">

                        <div className="list__wrapper__header_price">
                            <div className="header__control"
                                onClick={() => { this.SelectAllLexicons() }}>
                                {this.GetSelectedLexiconsCount() === this.props.PriceListState.Lexicons.length ? 'Снять выбор всех' :
                                    'Выбрать все'}
                            </div>
                        </div>

                        <div className="list__wrapper__price">
                            {
                                this.props.PriceListState.Lexicons.length > 0 && this.props.PriceListState.Lexicons.map((lexicon: Lexicon, key: number) =>
                                    <div className="price_list__item" key={`${lexicon.Id}.${key}`}>
                                        <div className="item__name">{lexicon.OfficialPartnerName}</div>
                                        <div className="plOption__check">
                                            <input type="checkbox"
                                                checked={this.isLexiconCheked(lexicon.Id)}
                                                onChange={() => this.onAddLexiconToFilter(lexicon.Id)} />
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="price_list__top__controls">
                    {
                        this.props.PriceListState.Options ?
                            <div className="button__control" onClick={() => this.onRemoveFilter()}>Удалить</div> :
                            null
                    }
                    {
                        this.props.PriceListState.Options ?
                            <div className="button__control" onClick={() => this.onUpdateFilter()}>Обновить</div> :
                            <div className="button__control" onClick={() => this.onSaveFilter()}>Сохранить</div>
                    }
                </div>
            </div>
        )
    }
}
export default connect(
    (state: ApplicationState) => ({ PriceListState: state.stock.priceList, AuthenticationState: state.authentication }),
    (dispatch) => bindActionCreators({
        GetRootCategories,
        GetSubCategories,
        SelectRootCategory,
        AddFilter,
        AddOptions,
        UpdateOptions,
        RemoveOptions,
        SelectContent,
        GetPricelist,
        ChangeAvailableOnly
    }, dispatch))
    (PriceListOptions);

