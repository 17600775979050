import * as React from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { List } from 'linqts';
import { OrderCardState } from '../../../../../states/sales/order.card.state';
import { OrderProduct } from '../../../../../business.entities/order.product';
import { ProductionStatusEnum, GetProductionStatusName } from '../../../../../helpers/enums/production.status';
import { UpdateOrderProduct, ChangeProductionOperatorComment, ChangeProductionComment } from '../../../../../actions/sales/order.card.actions';
import { ProductProductionStatus } from '../../../../../business.entities/product.production.status';
import { default as OrderProductCommon } from '../../../../shared/right.view/order.product.common';
import { PrintStatusEnum, GetPrintStatusName } from '../../../../../helpers/enums/print.status.enum';
import { GetIdentityRoleName, AllIdentityRolesExept } from '../../../../../helpers/identity.roles';
import PermissionChecker from '../../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../../helpers/identity.roles';
import * as moment from 'moment';
import { Loader } from '../../../../shared/loader';
import { OrderStatusEnum } from '../../../../../helpers/enums/order.status.enum';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    ChangeProductionOperatorComment,
    ChangeProductionComment
}, dispatch);

const ProductionInfoRv = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProduct: OrderProduct = new List<OrderProduct>(orderCard.order.OrderProducts)
        .FirstOrDefault(c => c.Id === orderCard.selectedOrderProductId);

    const OnSelectProductionStatusCommand = (status, id) => {
        let newStatus: ProductProductionStatus = new ProductProductionStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductProductionStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    //Add logic here
    const OnChangePartnerProdComment = (value) => {
        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner)) {
            if (orderCard.order.StockStatus.length == 0)
                props.ChangeProductionOperatorComment(value);
        } else {
            props.ChangeProductionOperatorComment(value);
        }
    }

    const OnChangeManufactureProdComment = (value) => {
        if (orderCard.order.StockStatus.length > 0)
            props.ChangeProductionComment(value);
    }

    const GetProductionStatusesForPartner = (status) => {
        if (status === ProductionStatusEnum.Producted)
            return GetProductionStatusName(ProductionStatusEnum.Producted);
        else
            // return GetProductionStatusName(ProductionStatusEnum.WaitingForProduction);
            return '';
    }

    return (
        <div className="printRVContainer">
            <div className="currentOrderProducts__item">
                <OrderProductCommon product={orderProduct} />
                {orderProduct.ProductProductionStatus.length > 0 ?
                    <PermissionChecker
                        Classes='defaultFormControl'
                        EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Manufacture]}>
                        <div className="defaultFormControl__label">Статус производство</div>
                        {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {orderProduct.ProductProductionStatus.length > 0 ?
                                        GetProductionStatusesForPartner(orderProduct.ProductProductionStatus[0].Status) :
                                        ''}
                                </div>
                            </div>
                            :
                            <div className="selectWrapper__container">
                                <Select
                                    name="productionStatus"
                                    isDisabled={orderProduct.ProductProductionStatus[0].Status === ProductionStatusEnum.WaitingForAssembling || (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status !== OrderStatusEnum.Confirmed)}
                                    openMenuOnFocus={true}
                                    options={orderCard.productionStatusList}
                                    onChange={(value) => OnSelectProductionStatusCommand(value, orderProduct.Id)}
                                    value={orderProduct.SelectedProductionStatus}
                                    placeholder="Статус производство" />
                            </div>
                        }
                    </PermissionChecker>
                    : null
                }

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishProductionStatus ?
                        <>
                            <div className="defaultFormControl__label">Дата пошива</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {moment(orderProduct.FinishProductionStatus.Created).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishProductionStatus ?
                        <>
                            <div className="defaultFormControl__label">Пошил</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {orderProduct.FinishProductionStatus.Partner.ContactName}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                    <div className="defaultFormControl__label">Комментарий заказчика</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderCard.order.ProductionOperatorComment ? orderCard.order.ProductionOperatorComment : ''}
                            onChange={(event) => OnChangePartnerProdComment(event.target.value)} />

                        {
                            orderCard.fetchInProgress ?
                                <Loader /> : null
                        }
                    </div>
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Manufacture, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    <div className="defaultFormControl__label">Коментарий швачки</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderCard.order.ProductionComment ? orderCard.order.ProductionComment : ''}
                            onChange={(event) => OnChangeManufactureProdComment(event.target.value)} />

                        {
                            orderCard.fetchInProgress ?
                                <Loader /> : null
                        }
                    </div>
                </PermissionChecker>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductionInfoRv)