import { connect } from "react-redux"
import * as React from "react";
import { bindActionCreators } from "redux";
import * as actions from '../../../../actions/cabinet/personal.info.actions'


const ConstructorWarningRv = (props: any) => {

    return (
     <></>
    );
}

export default connect(state => ({
    UserNetUid: (state as any).authentication.UserNetUid
}),
    dispatch => bindActionCreators({
        activateConstructor: actions.ActivateConstructor
    }, dispatch))
    (ConstructorWarningRv) 