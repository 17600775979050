import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as moment from 'moment';
import { DatePicker } from "material-ui";
import { DebounceInput } from 'react-debounce-input';
import Select from 'react-select';
import * as mutualSettlementsActions from '../../../../actions/cabinet/mutual.settlements.actions';
import { MutualSettlementFilter } from '../../../../helpers/mutual.filter';
import { GetIdentityRoleName, IdentityRoleEnum } from '../../../../helpers/identity.roles';

const mapStateToProps = (state) => {
    return {
        MutualSettlements: state.cabinet.mutualSettlements
        , Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ChangeOperation: mutualSettlementsActions.ChangeOperation,
    ChangeOrder: mutualSettlementsActions.ChangeOrder,
    ChangeStartDate: mutualSettlementsActions.ChangeStartDate,
    ChangeEndDate: mutualSettlementsActions.ChangeEndDate,
    GetOrdersForFilter: mutualSettlementsActions.GetOrdersAutocompleteFilter,
    ChangePage: mutualSettlementsActions.ChangePage,
    ChangeCount: mutualSettlementsActions.ChangeLimit,
    GetMutualSettlementsForPartner: mutualSettlementsActions.GetAllMutualSettlements,
    SetFiltered: mutualSettlementsActions.SetFiltered
}, dispatch);

const FilterComponent = (props: any) => {
    const IntlPolyfill = require('intl');
    const DateTimeFormat = IntlPolyfill.DateTimeFormat;
    require('intl/locale-data/jsonp/ru-UA');

    const format = (date: Date) => {
        return date ? moment(date).format('DD/MM/YYYY') : null;
    }

    const OnChangeStartDateCommand = (value) => {
        props.ChangeStartDate(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);
    }

    const OnChangeEndDateCommand = (value) => {
        props.ChangeEndDate(value ? moment(value).hour(0).minutes(0).seconds(0) : undefined);
    }

    const OnChangeOrderCodeMethod = (value) => {
        props.ChangeOrder(value);
    }

    const OnChangeOrderTypeCommand = (value) => {
        props.ChangeOperation(value);
    }

    const onAutocompleteInputChange = (value: any) => {
        let PartnerNetId = null;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
            PartnerNetId = props.MutualSettlements.SelectedPartner ? props.MutualSettlements.SelectedPartner.value : null
        } else {
            PartnerNetId = props.Authorization.UserNetUid;
        }

        value &&
            props.GetOrdersForFilter(PartnerNetId, value);
    }

    const ApplyFilter = () => {
        if (!props.MutualSettlements.Fetching) {
            let PartnerNetId = null;

            if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
                    PartnerNetId = props.MutualSettlements.SelectedPartner ? props.MutualSettlements.SelectedPartner.value : null
            } else {
                PartnerNetId = props.Authorization.UserNetUid;
            }

            let filter = BuildFilter();
            props.ChangePage(1);

            if (filter.StartDate || filter.EndDate || filter.OperationType || filter.OrderId) {
                props.SetFiltered(true);
            } else {
                props.SetFiltered(false);
            }

            props.GetMutualSettlementsForPartner(PartnerNetId, 1, props.MutualSettlements.Count, filter);
        }
    }

    const BuildFilter = () => {
        let filter = new MutualSettlementFilter();

        if (props.MutualSettlements.StartDate)
            filter.StartDate = props.MutualSettlements.StartDate.format('YYYY-MM-DDTHH:mm:ss');

        if (props.MutualSettlements.EndDate)
            filter.EndDate = props.MutualSettlements.EndDate.format('YYYY-MM-DDTHH:mm:ss');

        filter.OrderId = props.MutualSettlements.Order ? props.MutualSettlements.Order.value : null;
        filter.OperationType = props.MutualSettlements.Operation ? props.MutualSettlements.Operation.value : null;

        return filter;
    }

    return (
        <div className="orderBaseFilter__panelContainer">

            <div className="defaultFormControl dateCol">
                <div className="defaultFormControl__label">Начальная дата</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Начальная дата"
                        value={props.MutualSettlements.StartDate ? props.MutualSettlements.StartDate.toDate() : props.MutualSettlements.StartDate}
                        onChange={(event, date: any) => {
                            OnChangeStartDateCommand(date)
                        }}
                    />
                    <div className="clControl" onClick={() => { OnChangeStartDateCommand(null) }} />
                </div>
            </div>

            <div className="defaultFormControl dateCol">
                <div className="defaultFormControl__label">Конечная дата</div>
                <div className="dateTimeWrapper__container">
                    <DatePicker
                        DateTimeFormat={DateTimeFormat}
                        formatDate={format}
                        floatingLabelText="Начальная дата"
                        value={props.MutualSettlements.EndDate ? props.MutualSettlements.EndDate.toDate() : props.MutualSettlements.EndDate}
                        onChange={(event, date: any) => {
                            OnChangeEndDateCommand(date);
                        }}
                    />
                    <div className="clControl" onClick={() => { OnChangeEndDateCommand(null) }} />
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Заказ</div>
                <div className="selectWrapper__container notPadd">
                    <Select
                        name="subCategory"
                        placeholder="Код заказа"
                        isClearable
                        clearValue={() => { OnChangeOrderCodeMethod(null) }}
                        options={props.MutualSettlements.AllFilterOrders}
                        onInputChange={onAutocompleteInputChange}
                        onChange={(value) => {
                            OnChangeOrderCodeMethod(value)
                        }}
                        value={props.MutualSettlements.Order}
                    />
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Операция</div>
                <div className="selectWrapper__container">
                    <Select
                        name="orderStatus"
                        isClearable
                        clearValue={() => { OnChangeOrderTypeCommand(null) }}
                        options={props.MutualSettlements.Operations}
                        onChange={(value) => {
                            OnChangeOrderTypeCommand(value)
                        }}
                        value={props.MutualSettlements.Operation}
                        placeholder="Тип операции" />
                </div>
            </div>

            <div className="defaultFormButtonControl grActButton"
                onClick={() => {
                    ApplyFilter()
                }}>
                Применить фильтр
            </div>

        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
