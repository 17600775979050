import { EntityBase } from "./entity.base";
import { ProductionStatusEnum } from "../helpers/enums/production.status";
import { OrderProduct } from "./order.product";
import Partner from "./partner";

export class ProductProductionStatus extends EntityBase {
    public Status: ProductionStatusEnum;
    public OrderProductId: number;
    public UserId: number;
    public OrderProduct: OrderProduct;
    public Partner: Partner;
}