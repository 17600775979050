export enum MutualSettlementOperationType {
    CreateOrder = 1,
    CancelOrder,
    ClientPayment,
    BalanceReplenishment,
    Refunds,
    DeliveryPayment,
    ChangeOrderedProducts,
    AddedDiscount,
    RemoveDiscount,
    FundsWithdrawal,
    ConstructorPayment
}

export const GetMutaualSettlementName = (type: MutualSettlementOperationType) => {
    switch(type){
        case MutualSettlementOperationType.CreateOrder:
            return 'Создание заказа';
            case MutualSettlementOperationType.CancelOrder:
            return 'Отмена заказа';
            case MutualSettlementOperationType.ClientPayment:
            return 'Оплата клиента';
            case MutualSettlementOperationType.BalanceReplenishment:
            return 'Пополнение баланса';
            case MutualSettlementOperationType.Refunds:
            return 'Возврат средств';
            case MutualSettlementOperationType.DeliveryPayment:
            return 'Оплата доставки';
            case MutualSettlementOperationType.ChangeOrderedProducts:
            return 'Изменение товаров';
            case MutualSettlementOperationType.AddedDiscount:
            return 'Предоставление скидки';
            case MutualSettlementOperationType.RemoveDiscount:
            return 'Отмена скидки';
            case MutualSettlementOperationType.FundsWithdrawal:
            return 'Вывод средств';
            case MutualSettlementOperationType.ConstructorPayment:
            return 'Персональный конструктор';
            default:
            return '';
    }
}