import { ConfirmBaseColorSelect } from "../actions/sales/wizard.actions";

export const MaxLength = (len: number) => (val: string) => val.length <= len;
export const IsNumber = (val: string) => !isNaN(Number(val));
export const Required = (val: string) => !val && !val.length;

export class Validator {
    private static readonly _passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!_-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!_-]{8,}$/g;

    private static readonly _emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    private static readonly _phonePattern = /^(\d{10})$/i;

    private static readonly _pricePattern = /^(?![.])\d*[.]{0,1}\d{1,2}$/;

    private static readonly _cyrylicLettersPattern = /^[А-ЯЄІЇа-яєіёї\-'` ]+$/;

    private static readonly _cityAddressPattern = /^[А-ЯЄІЇа-яєіёї][А-ЯЄІЇа-яєіёї\/\,\.\-\'\`\d ]*$/

    private static readonly _englishLettersPattern = /^[A-z ]+$/;

    private static readonly _urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

    private static readonly _numericPattern = /^(\d)+$/;

    public static IsPriceMatchToPattern(value){
        return Validator._pricePattern.test(value);
    }

    public static IsPasswordMatchToPattern(value: string): boolean {
        console.log("PASSWORD: " + value);
        return Validator._passwordPattern.test(value);
    }

    public static IsCyrylicLetters(value: string): boolean {
        return value === '' ? true : Validator._cyrylicLettersPattern.test(value);
    }

    public static IsCityAddressMatchToPattern(value: string): boolean {
        return value === '' ? true : Validator._cityAddressPattern.test(value);
    }

    public static IsEnglishLetters(value: string): boolean {
        return Validator._englishLettersPattern.test(value);
    }

    public static IsUrl(value: string): boolean {
        return value === '' ? true : Validator._urlPattern.test(value);
    }

    public static IsNumeric(value: string): boolean {
        return Validator._numericPattern.test(value);
    }

    public static IsEmail(value: string): boolean {
        return Validator._emailPattern.test(value);
    }

    public static IsPhone(value: string): boolean {
        return Validator._phonePattern.test(value);
    }

    public static IsEmpty(value: string) {
        return (!value || /^\s*$/.test(value));
    }

    public static IsZero(value: string) {
        return (Number(value) >= 0);
    }
}
