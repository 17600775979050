import { List } from 'linqts';
import * as moment from 'moment';
import Pagination from 'rc-pagination';
import Select from 'rc-select';
import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { ChangeRVTitle, RemoveRVData, SelectRVData } from '../../../../actions/right.view.actions';
import {
    ChangeModelValue, ChangeOrderCodeForFilter,
    ChangePaymentDate,
    ChangePaymentStatusForOrder, GenerateOrderExportList, GetAllConfirmPaymentStatusForOrder,
    GetAllOrders,
    GetAllPaymentStatusesForOrder,
    GetAllStatusesForOrder,
    GetAllStockStatusForOrder, GetAutocompleteModelResult, GetClientsForFilter,
    GetClientToRestoreFilter,
    GetOrderProductsForOrder, GetPartnersForFilter, GetPartnerToRestoreFilter, GetReesters, ResetOrderBaseFilter,
    SelectAllOrder,
    SelectAssemblingDateForFilter,
    SelectClientsForFilter,
    SelectConfirmPaymentStatusForOrder,
    SelectDeliveryTypeForFilter,
    SelectEndDateForFilter,
    SelectOrder,
    SelectOrderStatusFilter,
    SelectOrderTypeFilter,
    SelectPartnerForFilter,
    SelectPaymentMethodForFilter,
    SelectPrintStatusForFilter,
    SelectProductionStatusForFilter,
    SelectStartDateForFilter,
    SetAllPaymentStatusesForOrder,
    SetCurrentOrderIndex, SetLaserStatusListFilter, SetOrderProductsForOrder,
    SetOrderStatusListFilter,
    SetPrintStatusListFilter,
    SetProdStatusListFilter,
    UnmountComponent,
    ChangeLexiconValue,
    GetAutocompleteLexiconResult,
    GetOrderProductsForAllOrdersOnPage,
    GetOrderProductsForAllOrdersOnPageSuccess
} from '../../../../actions/sales/order.base.actions';
import { Order } from '../../../../business.entities/order';
import * as API from '../../../../constants/api.constants';
import { ClipboardHelper } from '../../../../helpers/clipboardHelper';
import { getCookie } from '../../../../helpers/cookies';
import { FilterStatusList as LaserStatusList, FilterStatusPartnerList as PartnerLaserStatusList, GetLaserStatusName, LaserStatusEnum } from '../../../../helpers/enums/laser.status.enum';
import {
    AdministratorStatusList,
    ClientStatusList,
    GetNameForOrderStatus,
    OrderStatusEnum
} from '../../../../helpers/enums/order.status.enum';
import { OrderTypeEnum } from '../../../../helpers/enums/order.type.enum';
import { GetPaymentMethodName } from '../../../../helpers/enums/payment.method';
import { FilterStatusList as PrintStatusList, FilterStatusPartnerList as PartnerPrintStatusList, GetPrintStatusName, PrintStatusEnum } from '../../../../helpers/enums/print.status.enum';
import { GetOrderType } from '../../../../helpers/enums/product.type.enum';
import { FilterStatusList as ProdStatusList, FilterStatusPartnerList as PartnerProdStatusList, GetProductionStatusName, ProductionStatusEnum } from '../../../../helpers/enums/production.status';
import { GetStockStatusName } from '../../../../helpers/enums/stock.status.enum';
import {
    AllIdentityRolesExept, GetIdentityRoleName,
    IdentityRoleEnum
} from '../../../../helpers/identity.roles';
import Locale from '../../../../helpers/locale.pagination';
import { OrderBaseFilterEntity } from '../../../../helpers/order.base.fiter.entity';
import { IsPartner } from '../../../../helpers/role.validator';
import { LoginState } from '../../../../states/login.state';
import { RightViewState } from '../../../../states/right.view.state';
import { OrderBaseState } from '../../../../states/sales/order.base.state';
import { Loader } from '../../../shared/loader';
import PermissionChecker from '../../../shared/permission.checker.component';
import { default as DeliveryInfo } from './delivery.info';
import { default as GroupActions } from './group.actions';
import { default as LaserInfo } from './laser.info.rv';
import { default as OrderBaseFilter } from './order.base.filter';
import { default as OrderProductRV } from './order.products.rv';
import { default as OrderProductsForPageRv } from './order.products.page.rv';
import { default as OrderStatusesRV } from './order.status.rv';
import { default as PrintInfo } from './print.infos.rv';
import { default as ProductionInfo } from './production.info.rv';
import { default as RegistersInfo } from './registers.info';
import { default as StockInfo } from './stock.info';

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetAllOrders,
    SelectRVData,
    RemoveRVData,
    ChangeRVTitle,
    SetOrderStatusListFilter,
    SetPrintStatusListFilter,
    SetProdStatusListFilter,
    SetLaserStatusListFilter,
    GetClientsForFilter,
    GetOrderProductsForOrder,
    GetAllStatusesForOrder,
    SelectOrder,
    ResetOrderBaseFilter,
    SetCurrentOrderIndex,
    ChangePaymentStatusForOrder,
    GetAllPaymentStatusesForOrder,
    SetAllPaymentStatusesForOrder,
    ChangePaymentDate,
    SelectConfirmPaymentStatusForOrder,
    GetAllConfirmPaymentStatusForOrder,
    SetOrderProductsForOrder,
    GetAllStockStatusForOrder,
    SelectAllOrder,
    UnmountComponent,
    SelectOrderTypeFilter,
    SelectOrderStatusFilter,
    SelectClientsForFilter,
    SelectDeliveryTypeForFilter,
    SelectPaymentMethodForFilter,
    ChangeOrderCodeForFilter,
    SelectPartnerForFilter,
    SelectEndDateForFilter,
    SelectStartDateForFilter,
    SelectPrintStatusForFilter,
    SelectProductionStatusForFilter,
    SelectAssemblingDateForFilter,
    GetClientToRestoreFilter,
    GetPartnerToRestoreFilter,
    GetReesters,
    GetPartnersForFilter,
    ChangeModelValue,
    GetAutocompleteModelResult,
    GenerateOrderExportList,
    ChangeLexiconValue,
    GetAutocompleteLexiconResult,
    GetOrderProductsForAllOrdersOnPage,
    GetOrderProductsForAllOrdersOnPageSuccess
}, dispatch);

export interface IOrderBase {
    OrderBase: OrderBaseState;
    Authorization: LoginState;
    GetAllOrders(): void;
    SelectRVData(state: RightViewState): void;
    RemoveRVData(): void;
    ChangeRVTitle(title): void;
    GetAllPaymentStatusesForOrder(netId): void;
    SetAllPaymentStatusesForOrder(paymentStatusList: any[]);
    ChangePaymentDate(date);
    SetOrderStatusListFilter(value: any[]);
    SetPrintStatusListFilter(value: any[]);
    SetProdStatusListFilter(value: any[]);
    SetLaserStatusListFilter(value: any[]);
    SetCurrentOrderIndex(index);
    GetOrderProductsForOrder(netId);
    GetAllStatusesForOrder(netId);
    SelectOrder(index, isSelected);
    SelectAllOrder(selected);
    GetAllOrders(filter, page?, count?);
    ChangePaymentStatusForOrder(value);
    ResetOrderBaseFilter();
    SelectConfirmPaymentStatusForOrder(value);
    GetAllConfirmPaymentStatusForOrder(netId);
    SetOrderProductsForOrder(orderProducts);
    GetAllStockStatusForOrder(netId);
    UnmountComponent();
    SelectOrderTypeFilter(value);
    SelectOrderStatusFilter(value);
    SelectClientsForFilter(value);
    SelectDeliveryTypeForFilter(value);
    SelectPaymentMethodForFilter(value);
    ChangeOrderCodeForFilter(value);
    SelectPartnerForFilter(value);
    SelectEndDateForFilter(value);
    SelectStartDateForFilter(value);
    SelectPrintStatusForFilter(value);
    SelectProductionStatusForFilter(value);
    SelectAssemblingDateForFilter(value);
    GetClientToRestoreFilter(value);
    GetPartnerToRestoreFilter(value);
    GetReesters(date: any);
    GetPartnersForFilter(value);
    ChangeModelValue(value);
    GetAutocompleteModelResult(value);
    GenerateOrderExportList(value);
    ChangeLexiconValue(value);
    GetAutocompleteLexiconResult(value);
    GetOrderProductsForAllOrdersOnPage(value);
    GetOrderProductsForAllOrdersOnPageSuccess(value);
}

class OrdersBase extends React.Component<IOrderBase, any> {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            currentPageSize: 25
        }

        let filterCookie = getCookie('filter');

        if (filterCookie) {
            let filter: OrderBaseFilterEntity = JSON.parse(filterCookie);

            if (filter.Product) {
                filter.ProductId = filter.Product.value;
            }

            if (filter.Lexicon) {
                filter.LexiconId = filter.Lexicon.value;
            }

            if (props.Authorization.Role === 'Maysternya') {
                filter.PaymentMethod = 4;
            }

            this.setFilter(filter)

            props.GetAllOrders(filter);
        } else {
            let filter = new OrderBaseFilterEntity();

            if (props.Authorization.Role === 'Maysternya') {
                filter.PaymentMethod = 4;
            }

            props.GetAllOrders(filter);
        }

        this.props.SetOrderStatusListFilter(props.Authorization.Role !== "Partner" ? AdministratorStatusList : ClientStatusList);
        this.props.SetPrintStatusListFilter(props.Authorization.Role === "Partner" ? PartnerPrintStatusList : PrintStatusList);
        this.props.SetProdStatusListFilter(props.Authorization.Role === "Partner" ? PartnerProdStatusList : ProdStatusList);
        this.props.SetLaserStatusListFilter(props.Authorization.Role === "Partner" ? PartnerLaserStatusList : LaserStatusList);

        props.GetAutocompleteModelResult("");
        props.GetClientsForFilter("");
        props.GetPartnersForFilter("");
        props.GetAutocompleteLexiconResult("");
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    public GenerateOrderExportUrl() {
        let filter = new OrderBaseFilterEntity();

        if (this.props.OrderBase.selectedPartner)
            filter.PartnerNetId = this.props.OrderBase.selectedPartner.value;

        if (this.props.OrderBase.selectedOrderType)
            filter.OrderType = this.props.OrderBase.selectedOrderType.value;

        if (this.props.OrderBase.selectedOrderStatus)
            filter.OrderStatus = this.props.OrderBase.selectedOrderStatus.value;

        if (this.props.OrderBase.selectedStockStatus)
            filter.StockStatus = this.props.OrderBase.selectedStockStatus.value;

        if ((this.props.OrderBase as OrderBaseState).filterStartDate)
            filter.StartDate = moment((this.props.OrderBase as OrderBaseState).filterStartDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).filterEndDate)
            filter.EndDate = moment((this.props.OrderBase as OrderBaseState).filterEndDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).TTNCreateDateFilter)
            filter.TTNCreateDate = (this.props.OrderBase as OrderBaseState).TTNCreateDateFilter.format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).orderCode)
            filter.OrderCode = (this.props.OrderBase as OrderBaseState).orderCode;

        if ((this.props.OrderBase as OrderBaseState).selectedClient)
            filter.Client = (this.props.OrderBase as OrderBaseState).selectedClient.value;

        if ((this.props.OrderBase as OrderBaseState).selectedDeliveryType)
            filter.DeliveryType = (this.props.OrderBase as OrderBaseState).selectedDeliveryType.value;

        if ((this.props.OrderBase as OrderBaseState).selectedPaymentMethod)
            filter.PaymentMethod = (this.props.OrderBase as OrderBaseState).selectedPaymentMethod.value;

        if ((this.props.OrderBase as OrderBaseState).selectedPrintStatus)
            filter.PrintStatus = (this.props.OrderBase as OrderBaseState).selectedPrintStatus.value;

        if ((this.props.OrderBase as OrderBaseState).selectedProductionStatus)
            filter.ProductionStatus = (this.props.OrderBase as OrderBaseState).selectedProductionStatus.value;

        if ((this.props.OrderBase as OrderBaseState).selectedLaserStatus)
            filter.LaserStatus = (this.props.OrderBase as OrderBaseState).selectedLaserStatus.value;

        if ((this.props.OrderBase as OrderBaseState).TTN)
            filter.TTN = (this.props.OrderBase as OrderBaseState).TTN;

        if ((this.props.OrderBase as OrderBaseState).filterAssemblingDate)
            filter.AssemblingDate = (this.props.OrderBase as OrderBaseState).filterAssemblingDate.format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).selectedModel)
            filter.ProductId = (this.props.OrderBase as OrderBaseState).selectedModel.value;

        this.props.GenerateOrderExportList(filter);
    }

    public setFilter(filter: OrderBaseFilterEntity) {

        if (filter.OrderType) {
            let valueToSet = new List(this.props.OrderBase.orderTypeList).FirstOrDefault(c => c.value === filter.OrderType);
            this.props.SelectOrderTypeFilter(valueToSet)
        }

        if (filter.OrderStatus) {
            let valueToSet = new List(this.props.OrderBase.orderStatusList).FirstOrDefault(c => c.value === filter.OrderType);
            this.props.SelectOrderStatusFilter(valueToSet)
        }

        if (filter.StartDate) {
            this.props.SelectStartDateForFilter(moment(filter.StartDate));
        }

        if (filter.EndDate) {
            this.props.SelectEndDateForFilter(moment(filter.EndDate));
        }

        if (filter.OrderCode) {
            this.props.ChangeOrderCodeForFilter(filter.OrderCode);
        }

        if (filter.Client) {
            this.props.GetClientToRestoreFilter(filter.Client);
        }

        if (filter.PartnerNetId) {
            this.props.GetPartnerToRestoreFilter(filter.PartnerNetId);
        }

        if (filter.DeliveryType) {
            let valueToSet = new List(this.props.OrderBase.deliveryTypeList).FirstOrDefault(c => c.value === filter.DeliveryType);
            this.props.SelectDeliveryTypeForFilter(valueToSet);
        }

        if (filter.PaymentMethod) {
            let valueToSet = new List(this.props.OrderBase.paymentMethodList).FirstOrDefault(c => c.value === filter.PaymentMethod);
            this.props.SelectPaymentMethodForFilter(valueToSet);
        }

        if (filter.PrintStatus) {
            let valueToSet = new List(this.props.OrderBase.printStatusList).FirstOrDefault(c => c.value === filter.PrintStatus);
            this.props.SelectPrintStatusForFilter(valueToSet);
        }

        if (filter.ProductionStatus) {
            let valueToSet = new List(this.props.OrderBase.productionStatusList).FirstOrDefault(c => c.value === filter.ProductionStatus);
            this.props.SelectProductionStatusForFilter(valueToSet);
        }

        if (filter.AssemblingDate) {
            this.props.SelectAssemblingDateForFilter(moment(filter.AssemblingDate));
        }

        if (filter.Product) {
            this.props.ChangeModelValue(filter.Product);
        }

        if (filter.LexiconId) {
            this.props.ChangeLexiconValue(filter.Lexicon);
        }
    }

    public BuildFilter = () => {
        let filter = new OrderBaseFilterEntity();

        if (this.props.OrderBase.selectedPartner)
            filter.PartnerNetId = this.props.OrderBase.selectedPartner.value;

        if (this.props.OrderBase.selectedOrderType)
            filter.OrderType = this.props.OrderBase.selectedOrderType.value;

        if (this.props.OrderBase.selectedOrderStatus)
            filter.OrderStatus = this.props.OrderBase.selectedOrderStatus.value;

        if (this.props.OrderBase.selectedStockStatus)
            filter.StockStatus = this.props.OrderBase.selectedStockStatus.value;

        if ((this.props.OrderBase as OrderBaseState).filterStartDate)
            filter.StartDate = moment((this.props.OrderBase as OrderBaseState).filterStartDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).filterEndDate)
            filter.EndDate = moment((this.props.OrderBase as OrderBaseState).filterEndDate).format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).TTNCreateDateFilter)
            filter.TTNCreateDate = (this.props.OrderBase as OrderBaseState).TTNCreateDateFilter.format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).orderCode)
            filter.OrderCode = (this.props.OrderBase as OrderBaseState).orderCode;

        if ((this.props.OrderBase as OrderBaseState).selectedClient)
            filter.Client = (this.props.OrderBase as OrderBaseState).selectedClient.value;

        if ((this.props.OrderBase as OrderBaseState).selectedDeliveryType)
            filter.DeliveryType = (this.props.OrderBase as OrderBaseState).selectedDeliveryType.value;

        if ((this.props.OrderBase as OrderBaseState).selectedPaymentMethod)
            filter.PaymentMethod = (this.props.OrderBase as OrderBaseState).selectedPaymentMethod.value;

        if ((this.props.OrderBase as OrderBaseState).selectedPrintStatus)
            filter.PrintStatus = (this.props.OrderBase as OrderBaseState).selectedPrintStatus.value;

        if ((this.props.OrderBase as OrderBaseState).selectedProductionStatus)
            filter.ProductionStatus = (this.props.OrderBase as OrderBaseState).selectedProductionStatus.value;

        if ((this.props.OrderBase as OrderBaseState).selectedLaserStatus)
            filter.LaserStatus = (this.props.OrderBase as OrderBaseState).selectedLaserStatus.value;

        if ((this.props.OrderBase as OrderBaseState).TTN)
            filter.TTN = (this.props.OrderBase as OrderBaseState).TTN;

        if ((this.props.OrderBase as OrderBaseState).filterAssemblingDate)
            filter.AssemblingDate = (this.props.OrderBase as OrderBaseState).filterAssemblingDate.format('YYYY-MM-DDTHH:mm:ss');

        if ((this.props.OrderBase as OrderBaseState).selectedModel)
            filter.ProductId = (this.props.OrderBase as OrderBaseState).selectedModel.value;

        if ((this.props.OrderBase as OrderBaseState).selectedLexicon)
            filter.LexiconId = (this.props.OrderBase as OrderBaseState).selectedLexicon.value;

        return filter;
    }

    public OnChangeOrderCodeMethod = (value) => {
        if (!this.props.OrderBase.fetchInProgress) {
            this.props.ChangeOrderCodeForFilter(value);

            let filter = this.BuildFilter();

            if (value)
                filter.OrderCode = value;
            else
                filter.OrderCode = '';

            this.props.GetAllOrders(filter);
        }
    }

    public OnOpenFilterRvCommand(): void {
        let rvState = new RightViewState();
        rvState.Title = "Фильтры";
        rvState.Component = OrderBaseFilter;
        rvState.ViewSize = "rightViewGroupActions";
        rvState.CloseFunction = () => { this.props.RemoveRVData() };

        this.props.SelectRVData(rvState);
    }

    public OnOpenRegistersRvCommand(): void {
        const filter = {
            ReestersDate: moment().hour(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
            ReestersTtn: "",
            ReestersOrderCode: ""
        }
        this.props.GetReesters(filter);

        let rvState = new RightViewState();
        rvState.Title = "Реестры";
        rvState.Component = RegistersInfo;
        rvState.ViewSize = "rightViewGroupActions";
        rvState.CloseFunction = () => { this.props.RemoveRVData() };

        this.props.SelectRVData(rvState);
    }

    public GetPrevOrderIndex(): number {
        let currentIndex = this.props.OrderBase.currentOrderIndex;

        if (currentIndex - 1 < 0)
            return this.props.OrderBase.orders.length - 1;

        return --currentIndex;
    }

    public GetNextOrderIndex(): number {
        let currentIndex = this.props.OrderBase.currentOrderIndex;

        if (currentIndex + 1 == this.props.OrderBase.orders.length)
            return 0;

        return ++currentIndex;
    }

    public GetPrevPrintOrderIndex(print: boolean): number {
        //TODO: CHANGE LOGIC slightly

        let orders = print ?
            new List(this.props.OrderBase.orders)
                .Where(c => c.OrderType === OrderTypeEnum.Print || c.OrderType === OrderTypeEnum.ProductionAndPrint)
                .ToArray() :
            new List(this.props.OrderBase.orders)
                .Where(c => c.OrderType === OrderTypeEnum.Production || c.OrderType === OrderTypeEnum.ProductionAndPrint)
                .ToArray();

        let currentIndex = orders.indexOf(this.props.OrderBase.orders[this.props.OrderBase.currentOrderIndex]);

        if (currentIndex - 1 < 0) {
            return this.props.OrderBase.orders.indexOf(orders[orders.length - 1]);
        }

        return this.props.OrderBase.orders.indexOf(orders[currentIndex - 1]);
    }

    public GetNextPrintOrderIndex(print: boolean): number {
        //TODO: CHANGE LOGIC slightly

        let orders = print ?
            new List(this.props.OrderBase.orders)
                .Where(c => c.OrderType === OrderTypeEnum.Print || c.OrderType === OrderTypeEnum.ProductionAndPrint)
                .ToArray() :
            new List(this.props.OrderBase.orders)
                .Where(c => c.OrderType === OrderTypeEnum.Production || c.OrderType === OrderTypeEnum.ProductionAndPrint)
                .ToArray();

        let currentIndex = orders.indexOf(this.props.OrderBase.orders[this.props.OrderBase.currentOrderIndex]);

        if (currentIndex + 1 == orders.length) {
            return this.props.OrderBase.orders.indexOf(orders[0]);
        }

        return this.props.OrderBase.orders.indexOf(orders[currentIndex + 1]);
    }

    public GetPrevLaserOrderIndex(): number {
        let orders = new List(this.props.OrderBase.orders)
            .Where(c => c.OrderType === OrderTypeEnum.Laser || c.OrderType === OrderTypeEnum.ProductionAndPrint)
            .ToArray();

        let currentIndex = orders.indexOf(this.props.OrderBase.orders[this.props.OrderBase.currentOrderIndex]);

        if (currentIndex - 1 < 0) {
            return this.props.OrderBase.orders.indexOf(orders[orders.length - 1]);
        }

        return this.props.OrderBase.orders.indexOf(orders[currentIndex - 1]);
    }

    public GetNextLaserOrderIndex(): number {
        let orders = new List(this.props.OrderBase.orders)
            .Where(c => c.OrderType === OrderTypeEnum.Laser || c.OrderType === OrderTypeEnum.ProductionAndPrint)
            .ToArray();

        let currentIndex = orders.indexOf(this.props.OrderBase.orders[this.props.OrderBase.currentOrderIndex]);

        if (currentIndex + 1 == orders.length) {
            return this.props.OrderBase.orders.indexOf(orders[0]);
        }

        return this.props.OrderBase.orders.indexOf(orders[currentIndex + 1]);
    }

    public OnOpenOrderProductsForPageRvCommand(): void {

        let filterCookie = getCookie('filter');

        let filter: OrderBaseFilterEntity;

        if (filterCookie) {
            filter = JSON.parse(filterCookie);

            if (filter.Product) {
                filter.ProductId = filter.Product.value;
            }

            if (filter.Lexicon) {
                filter.LexiconId = filter.Lexicon.value;
            }

            if (this.props.Authorization.Role === 'Maysternya') {
                filter.PaymentMethod = 4;
            }

            this.setFilter(filter)
        } else {
            filter = new OrderBaseFilterEntity();

            if (this.props.Authorization.Role === 'Maysternya') {
                filter.PaymentMethod = 4;
            }
        }

        const filterOfOrderBasesForPage = {
            page: this.state.currentPage,
            count: this.state.currentPageSize,
            filter: filter
        }

        this.props.GetOrderProductsForAllOrdersOnPage(filterOfOrderBasesForPage);

        let rvState = new RightViewState();
        rvState.Title = "Заказаные продукты";
        rvState.Component = OrderProductsForPageRv;
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.GetOrderProductsForAllOrdersOnPageSuccess(null);
        };

        this.props.SelectRVData(rvState);
    }

    public OnOpenOrderProductsRvCommand(orderIndex: number): void {
        this.props.SetCurrentOrderIndex(orderIndex);
        this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Заказаные продукты: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = OrderProductRV;
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetOrderProductsForOrder(null);
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Заказаные продукти: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Заказаные продукти: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }

        this.props.SelectRVData(rvState);
    }

    public OnOpenPrintInfoRvCommand(orderIndex): void {
        if (this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.Print &&
            this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.ProductionAndPrint)
            return;

        //add check if any laser product exists


        this.props.SetCurrentOrderIndex(orderIndex);
        this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Печать: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = PrintInfo;
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetOrderProductsForOrder(null);
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevPrintOrderIndex(true);
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Печать: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextPrintOrderIndex(true);
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Печать: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }


        this.props.SelectRVData(rvState);
    }

    public OnOpenProductionInfoRvCommand(orderIndex): void {
        if (this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.Production &&
            this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.ProductionAndPrint)
            return;

        //add check if any laser product exists


        this.props.SetCurrentOrderIndex(orderIndex);
        this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Производство: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = ProductionInfo;
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetOrderProductsForOrder(null);
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevLaserOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Печать: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextLaserOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Печать: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }

        this.props.SelectRVData(rvState);
    }

    public OnOpenLaserInfoRvCommand(orderIndex): void {
        if (this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.Laser &&
            this.props.OrderBase.orders[orderIndex].OrderType !== OrderTypeEnum.ProductionAndPrint)
            return;

        this.props.SetCurrentOrderIndex(orderIndex);
        this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Лазер: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = LaserInfo;
        rvState.ViewSize = "rightViewGroupActions orderProductsInfo";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetOrderProductsForOrder(null);
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevPrintOrderIndex(false);
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Лазер: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextPrintOrderIndex(false);
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Лазер: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetOrderProductsForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }

        this.props.SelectRVData(rvState);
    }


    public OnOpenOrderStatusesRvCommand(orderIndex: number): void {
        this.props.SetCurrentOrderIndex(orderIndex);
        this.props.GetAllStatusesForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Статусы заказа: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = OrderStatusesRV;
        rvState.ViewSize = "rightViewGroupActions orderStatusesRV";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();

            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Статусы заказа: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetAllStatusesForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Заказаные продукты: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetAllStatusesForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }

        this.props.SelectRVData(rvState);
    }

    public OnSelectOrderCommand(index): void {
        this.props.SelectOrder(index, !this.props.OrderBase.orders[index].IsSelected);
    }

    public OnSelectAllOrderCommand(selected: boolean): void {
        let orders = Object.assign([], this.props.OrderBase.orders);

        orders.forEach((order) => (order as Order).IsSelected = selected);

        this.props.SelectAllOrder(orders);
    }

    public OnChangePageSizeCommand(current, pageSize): void {
        if (!this.props.OrderBase.fetchInProgress) {
            this.setState(() => ({
                currentPage: 1,
                currentPageSize: pageSize
            }));

            let filter = getCookie('filter');
            this.props.GetAllOrders(filter ? JSON.parse(filter) : new OrderBaseFilterEntity(), current, pageSize);
        }
    }

    public OnChangePageCommand(current, pageSize): void {
        if (!this.props.OrderBase.fetchInProgress) {
            this.setState(() => ({
                currentPage: current
            }));

            let filter = getCookie('filter');
            this.props.GetAllOrders(filter ? JSON.parse(filter) : new OrderBaseFilterEntity(), current, pageSize);
        }
    }

    public OnOpenOrderActionsRvCommand(): void {
        let rvState = new RightViewState();
        rvState.Title = "Действия";
        rvState.Component = GroupActions;
        rvState.ViewSize = "rightViewGroupActions rvGroupActions";
        rvState.CloseFunction = () => { this.props.RemoveRVData() };

        this.props.SelectRVData(rvState);
    }

    public OnOpenStockInfoRvCommand(orderIndex: number): void {
        this.props.SetCurrentOrderIndex(orderIndex);

        this.props.GetAllStockStatusForOrder(this.props.OrderBase.orders[orderIndex].NetUid);

        let rvState = new RightViewState();
        rvState.Title = "Склад: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = StockInfo;
        rvState.ViewSize = "rightViewGroupActions orderStatusesRV";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.GetAllStockStatusForOrder(null);
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Склад: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetAllStockStatusForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Склад: " + this.props.OrderBase.orders[newIndex].OrderCode);
                this.props.GetAllStockStatusForOrder(this.props.OrderBase.orders[newIndex].NetUid);
            }
        }

        this.props.SelectRVData(rvState);
    }

    public OnOpenDeliveryInfoRvCommand(orderIndex: number): void {
        this.props.SetCurrentOrderIndex(orderIndex);

        let rvState = new RightViewState();
        rvState.Title = "Доставка: " + this.props.OrderBase.orders[orderIndex].OrderCode;
        rvState.Component = DeliveryInfo;
        rvState.ViewSize = "rightViewGroupActions";
        rvState.CloseFunction = () => {
            this.props.RemoveRVData();
            this.props.SetCurrentOrderIndex(null);
        };

        rvState.CustomFunctions = {
            UpFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetPrevOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Доставка: " + this.props.OrderBase.orders[newIndex].OrderCode);
            },
            DownFunction: () => {
                this.props.SetOrderProductsForOrder(null);
                let newIndex = this.GetNextOrderIndex();
                this.props.SetCurrentOrderIndex(newIndex);
                this.props.ChangeRVTitle("Доставка: " + this.props.OrderBase.orders[newIndex].OrderCode);
            }
        }

        this.props.SelectRVData(rvState);
    }

    public OnResetFiltersCommand(): void {
        this.setState(() => ({
            currentPage: 1
        }));

        this.props.ResetOrderBaseFilter();

        this.props.GetAllOrders(new OrderBaseFilterEntity());
    }

    public IsAllSelected(): boolean {
        if (new List<Order>(this.props.OrderBase.orders).All(c => c.IsSelected))
            return true;

        return false;
    }

    public GetPrintStatusesForPartner(status) {
        if (status === PrintStatusEnum.Finished)
            return GetPrintStatusName(PrintStatusEnum.Finished);
        else
            // return GetPrintStatusName(PrintStatusEnum.ReadyForPrinting);
            return '';
    }

    public GetProductionStatusesForPartner(status) {
        if (status === ProductionStatusEnum.Producted)
            return GetProductionStatusName(ProductionStatusEnum.Producted);
        else
            // return GetProductionStatusName(ProductionStatusEnum.WaitingForProduction);
            return '';
    }

    public GetLaserStatusesForPartner(status) {
        if (status === LaserStatusEnum.Producted)
            return GetLaserStatusName(LaserStatusEnum.Producted);
        else
            // return GetProductionStatusName(ProductionStatusEnum.WaitingForProduction);
            return '';
    }


    public HighlightStatus(order) {
        if ((order as Order).GlobalComment) {
            if ((order as Order).OrderStatus.length > 0 && (order as Order).OrderStatus[0].Status === OrderStatusEnum.Cancel)
                return 'ggOstCommentError';
            else
                return 'ggOstComment';
        }

        if ((order as Order).OrderStatus.length > 0 && (order as Order).OrderStatus[0].Status === OrderStatusEnum.Cancel)
            return 'ggOstCommentError';
        else
            return '';
    }

    render() {
        return (
            <div className="oBesCellffContainer master_content_component menu_isNOT_missing orderBase__contentContainer" >
                <div className="content_header_component">
                    <div className="page_title oneTitleContainer">
                        <span className="title_default">База Заказов</span>
                        <span className="title_description"></span>
                    </div>
                    <div className="header__button__controls">
                        <details className="dStyle" open>
                            <summary>
                                <div className="summaryFilter" />
                            </summary>

                            <div className="defaultFormControl" style={{width: "200px", marginTop: "0", marginRight: "15px"}}>
                                <div className="defaultFormControl__value" style={{margin: "0"}}>
                                    <DebounceInput
                                        minLength={2}
                                        placeholder="Код заказа"
                                        debounceTimeout={1000}
                                        style={{height: "34px"}}
                                        value={this.props.OrderBase.orderCode}
                                        onChange={event => this.OnChangeOrderCodeMethod(event.target.value)}/>
                                </div>
                            </div>

                            {this.props.OrderBase.exportOrderUrl &&
                                <a className="button__control" href={`${API.SERVER_URL}${this.props.OrderBase.exportOrderUrl}`}
                                    download={`order_export_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать</a>
                            }

                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Partner, IdentityRoleEnum.Maysternya]}>
                                <div onClick={() => this.GenerateOrderExportUrl()}>
                                    Экспорт заказов
                                </div>
                            </PermissionChecker>

                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                                <div className="" onClick={() => this.OnOpenRegistersRvCommand()}>
                                    Реестры
                                </div>
                            </PermissionChecker>

                            <div className="button__control"
                                onClick={this.OnResetFiltersCommand.bind(this)}>
                                Сброс фильтров
                            </div>

                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                                <div className="" onClick={() => this.OnOpenOrderActionsRvCommand()}>
                                    Действия
                                </div>
                            </PermissionChecker>

                            <div className="button__control"
                                onClick={() => this.OnOpenFilterRvCommand()}>
                                Фильтрация
                            </div>
                        </details>

                        <details className="mStyle">
                            <summary>
                                <div className="summaryFilter" />
                            </summary>

                            {/* {this.props.PriceListUrl &&
                                <a className="button__control" href={`${API.SERVER_URL}${this.props.PriceListUrl}`}
                                    download={`caseshop_pricelist_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать прайс</a>
                            } */}
                            <div className="button__control" onClick={() => { }}>
                                Сгенерировать прайс
                            </div>

                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                <div className="" onClick={() => this.OnOpenRegistersRvCommand()}>
                                    Реестры
                                </div>
                            </PermissionChecker>

                            <div className="button__control"
                                onClick={this.OnResetFiltersCommand.bind(this)}>
                                Сброс фильтров
                            </div>

                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                                <div className="" onClick={() => this.OnOpenOrderActionsRvCommand()}>
                                    Действия
                                </div>
                            </PermissionChecker>

                            <div className="button__control"
                                onClick={() => this.OnOpenFilterRvCommand()}>
                                Фильтрация
                            </div>
                        </details>
                    </div>
                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">

                                        <PermissionChecker
                                            Classes='grid_cell control checkCell__container'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Booker, IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                            <div className={this.IsAllSelected() ? 'checkControl ch' : 'checkControl unCh'}
                                                onClick={() => this.OnSelectAllOrderCommand(!this.IsAllSelected())} />
                                        </PermissionChecker>

                                        <div className="grid_cell obCell__code">
                                            <div className="cell_content">Код заказа</div>
                                        </div>
                                        <div className="grid_cell obCell__date">
                                            <div className="cell_content">Дата создания</div>
                                        </div>
                                        <div className="grid_cell cell_type">
                                            <div className="cell_content">Тип заказа</div>
                                        </div>

                                        <PermissionChecker
                                            Classes='grid_cell manufOrderBaseCell ggOst fMW'
                                            EditRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                            <div className="">
                                                <div className="cell_content">Статус заказа</div>
                                            </div>
                                        </PermissionChecker>

                                        <PermissionChecker
                                            Classes='grid_cell obCell__totalAmount pPaPc'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                            <div className="cell_content"
                                                onClick={ () => this.OnOpenOrderProductsForPageRvCommand()}
                                            >Цена для партнера / Цена для клиента</div>
                                        </PermissionChecker>
                                        <div className="grid_cell">
                                            <div className="cell_content">Клиент</div>
                                        </div>
                                        <div className="grid_cell manufOrderBaseCell mPaymentMethod">
                                            <div className="cell_content">Метод Оплаты</div>
                                        </div>
                                        <PermissionChecker
                                            Classes='grid_cell manufOrderBaseCell mInfStockStatus'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                            <div className="cell_content">Информация о сборке</div>
                                        </PermissionChecker>

                                        <PermissionChecker
                                            Classes='grid_cell manufOrderBaseCell mInfPrintStatus'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Manufacture])}>
                                            <div className="cell_content">Информация о печати</div>
                                        </PermissionChecker>

                                        <PermissionChecker
                                            Classes='grid_cell manufOrderBaseCell mInfStockStatus '
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print])}>
                                            <div className="cell_content">Информация о пошиве</div>
                                        </PermissionChecker>

                                        <PermissionChecker
                                            Classes='grid_cell manufOrderBaseCell mInfStockStatus '
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                            <div className="cell_content">Информация о лазере</div>
                                        </PermissionChecker>

                                        <div className="grid_cell manufOrderBaseCell mOrTTN">
                                            <div className="cell_content">Информация о доставке</div>
                                        </div>
                                        <div className="grid_cell control"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid_scroll_content_component">
                                {this.props.OrderBase.fetchInProgress ?
                                    <Loader /> :
                                    <div className="grid_wrapper_component">
                                        {this.props.OrderBase.orders && this.props.OrderBase.orders.map((order, index) => {
                                            return (
                                                <div className={`mMEDIA___orderBase_ROW ordersBASE_GRID_ROW grid_row ${index === this.props.OrderBase.currentOrderIndex ? 'is_selected' : ''}`}
                                                    key={index}>
                                                    <PermissionChecker
                                                        Classes='grid_cell control checkCell__container oBase__CELL'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Booker, IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                                        <div>
                                                            <div className={order.IsSelected ? 'checkControl ch' : 'checkControl unCh'} onClick={() => this.OnSelectOrderCommand(index)} />
                                                        </div>
                                                    </PermissionChecker>
                                                    <div className="grid_cell obCell__code oBase__CELL" data-tooltip="Код заказа">
                                                        <div>
                                                            <div className="cell_content obCell__codeC">
                                                                {(order as Order).OrderCode}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid_cell obCell__date oBase__CELL" data-tooltip="Дата создания">
                                                        <div>
                                                            <div className="cell_content obCell__dateC">
                                                                <Moment format="DD/MM/YYYY HH:mm" date={(order as Order).Created} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid_cell cell_type oBase__CELL" data-tooltip="Тип заказа">
                                                        <div className={`type ${GetOrderType((order as Order).OrderType)}`} />
                                                        {(order as Order).AssemlingStatus ?
                                                            <div className="date">
                                                                <Moment format="DD/MM/YYYY" date={(order as Order).AssemlingStatus.Created} />
                                                            </div> :
                                                            null
                                                        }
                                                        {(order as Order).AssemlingStatus ?
                                                            <div className="number">
                                                                {(order as Order).AssemblingCell}
                                                            </div> :
                                                            null
                                                        }
                                                    </div>
                                                    <PermissionChecker
                                                        DataTooltip="Статус заказа"
                                                        Classes={`grid_cell oBase__CELL manufOrderBaseCell fMW ${this.HighlightStatus((order as Order))}`}
                                                        EditRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                                        <div className="" onClick={() => this.OnOpenOrderStatusesRvCommand(index)}>
                                                            <div className="cell_content">
                                                                {(order as Order).OrderStatus.length > 0 ?
                                                                    GetNameForOrderStatus((order as Order).OrderStatus[0].Status, IsPartner(this.props.Authorization)) :
                                                                    'НЕТУ'}
                                                                {' '}
                                                                {(order as Order).OrderStatus.length > 0 && (order as Order).OrderStatus[0].Status === OrderStatusEnum.Delivered ?
                                                                    (order as Order).WaitingDays
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </PermissionChecker>
                                                    <PermissionChecker
                                                        DataTooltip="Цена для партнера / Цена для клиента"
                                                        Classes='grid_cell oBase__CELL obCell__totalAmount bbbb'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}>
                                                        <div className="" onClick={() => this.OnOpenOrderProductsRvCommand(index)}>
                                                            <div className="cell_content obCell__totalAmountC">
                                                                <span>{(order as Order).PartnerTotalAmount}</span>
                                                                <span>{(order as Order).TotalAmount}</span>
                                                            </div>
                                                        </div>
                                                    </PermissionChecker>

                                                    <div className="grid_cell oBase__CELL vvvv cellClient__OBC" data-tooltip="Клиент">
                                                        <div>
                                                            {(order as Order).Client ?
                                                                <div className="cell_content">
                                                                    <span>
                                                                        {(order as Order).Client.FullName}
                                                                    </span>
                                                                    <span>
                                                                        {' '}
                                                                        {(order as Order).Client.PhoneNumber}
                                                                    </span>
                                                                </div> :
                                                                <div />}
                                                        </div>
                                                    </div>
                                                    <div className="grid_cell oBase__CELL manufOrderBaseCell mPaymentMethod" data-tooltip="Метод Оплаты">
                                                        <div>
                                                            <div className="cell_content">
                                                                {GetPaymentMethodName((order as Order).PaymentMethod)}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <PermissionChecker
                                                        DataTooltip="Информация о сборке"
                                                        Classes='grid_cell oBase__CELL manufOrderBaseCell mInfStockStatus'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                                        <div className="" onClick={this.OnOpenStockInfoRvCommand.bind(this, index)}>
                                                            <div className="cell_content">
                                                                {(order as Order).StockStatus.length > 0 ?
                                                                    GetStockStatusName((order as Order).StockStatus[0].Status) :
                                                                    ''}
                                                            </div>
                                                        </div>
                                                    </PermissionChecker>

                                                    <PermissionChecker
                                                        DataTooltip="Информация о печати"
                                                        Classes='grid_cell oBase__CELL manufOrderBaseCell mInfPrintStatus'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Manufacture])}>
                                                        <div className="" onClick={this.OnOpenPrintInfoRvCommand.bind(this, index)}>
                                                            {this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                <div className="cell_content">
                                                                    {(order as Order).PrintStatus.length > 0 && (order as Order).PrintStatus[0] != null ?
                                                                        this.GetPrintStatusesForPartner((order as Order).PrintStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                                :
                                                                <div className="cell_content">
                                                                    {(order as Order).PrintStatus.length > 0 && (order as Order).PrintStatus[0] != null ?
                                                                        GetPrintStatusName((order as Order).PrintStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                            }
                                                        </div>
                                                    </PermissionChecker>

                                                    <PermissionChecker
                                                        DataTooltip="Информация о пошиве"
                                                        Classes='grid_cell oBase__CELL manufOrderBaseCell mProductionStatus'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Lasercut, IdentityRoleEnum.Print])}>
                                                        <div className="" onClick={this.OnOpenProductionInfoRvCommand.bind(this, index)}>
                                                            {this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                <div className="cell_content">
                                                                    {(order as Order).ProductionStatus.length > 0 && (order as Order).ProductionStatus[0] != null ?
                                                                        this.GetProductionStatusesForPartner((order as Order).ProductionStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                                :
                                                                <div className="cell_content">
                                                                    {(order as Order).ProductionStatus.length > 0 && (order as Order).ProductionStatus[0] != null ?
                                                                        GetProductionStatusName((order as Order).ProductionStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                            }
                                                        </div>
                                                    </PermissionChecker>

                                                    <PermissionChecker
                                                        DataTooltip="Информация о лазере"
                                                        Classes='grid_cell oBase__CELL manufOrderBaseCell mProductionStatus'
                                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Manufacture, IdentityRoleEnum.Print])}>
                                                        <div className="" onClick={this.OnOpenLaserInfoRvCommand.bind(this, index)}>
                                                            {this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                                                <div className="cell_content">
                                                                    {(order as Order).LaserStatus.length > 0 && (order as Order).LaserStatus[0] != null ?
                                                                        this.GetLaserStatusesForPartner((order as Order).LaserStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                                :
                                                                <div className="cell_content">
                                                                    {(order as Order).LaserStatus.length > 0 && (order as Order).LaserStatus[0] != null ?
                                                                        GetLaserStatusName((order as Order).LaserStatus[0].Status) :
                                                                        ''}
                                                                </div>
                                                            }
                                                        </div>
                                                    </PermissionChecker>

                                                    <PermissionChecker
                                                        DataTooltip="Информация о доставке"
                                                        Classes='grid_cell oBase__CELL manufOrderBaseCell mOrTTN'>
                                                        {/*ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Print, IdentityRoleEnum.Manufacture])}*/}


                                                        <div className="onOpenDeliveryInfoRvCommand" onClick={this.OnOpenDeliveryInfoRvCommand.bind(this, index)}>
                                                            <div className="cell_content">
                                                                {(order as Order).TTN}
                                                            </div>
                                                        </div>

                                                        {(order as Order).TTN ?
                                                            <div className="mOrTTN__copy " onClick={() => {
                                                                ClipboardHelper.CopyTextWithMessage(
                                                                    (order as Order).TTN,
                                                                    `${(order as Order).TTN} скопирован в буфер обмена`)
                                                            }}></div> : null}
                                                    </PermissionChecker>
                                                    <div className="grid_cell oBase__CELL control settingsCell__container">
                                                        <NavLink to={`/sales/order-card/${order.NetUid}`}></NavLink>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        selectComponentClass={Select}
                        showSizeChanger
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.currentPage}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.OrderBase.ordersCount}
                    />
                </div>
            </div>
        )
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(OrdersBase);