import * as React from 'react';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { default as Carousel } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Countdown from 'react-countdown-now';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import KeyImage from '../../assets/styles/img/land2/7_new.svg'; 
import SVG from 'react-inlinesvg';

interface ILand2State {
    value: number;
    itemPrice: number;
    reverseValue: number;
    printPriceItemCountDay: number;
    printPriceItemCountMonth: number;
    printPriceItemsProfitDay: number;
    printPriceItemsProfitMonth: number;
    mobileMenuOpen: boolean;
}

export class LandingTwo extends React.Component<any, ILand2State>{
    public defaultItemPrice = 110;
    public defaultItemSellPrice = 200;
    public slidesToShowItem;
    constructor(props) {
        super(props);

        this.slidesToShowItem = document.getElementsByTagName('body')[0].clientWidth;

        let itemPrice: number = this.defaultItemPrice - (this.defaultItemPrice * 0.32);

        const reverseValue = 25;
        const printPriceItemsProfitDay = reverseValue * (this.defaultItemSellPrice - itemPrice);
        this.state = {
            value: 25,
            reverseValue,
            itemPrice,
            printPriceItemCountDay: reverseValue,
            printPriceItemCountMonth: reverseValue * 30,
            printPriceItemsProfitDay,
            printPriceItemsProfitMonth: printPriceItemsProfitDay * 30,
            mobileMenuOpen: false
        };
    }

    public handleChange(value) {
        this.setState({
            value: value
        })
    }

    public handleChangeMenu() {
        this.setState(state => ({
            mobileMenuOpen: !state.mobileMenuOpen
        }));
        //if (this.mobileMenuOpen == true) {
        //    this.mobileMenuOpen = false;

        //} else {
        //    this.mobileMenuOpen = true;
        //}
        //console.log(this.mobileMenuOpen);
    }

    public handleChangeReverse(value) {
        let newPrice = 0;

        if (value * (200 - this.defaultItemPrice) * 30 < 5000)
            newPrice = this.defaultItemPrice;

        if (value * (200 - this.defaultItemPrice) * 30 >= 5000 && value * (200 - this.defaultItemPrice) * 30 < 10000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.1);

        if (value * (200 - this.defaultItemPrice) * 30 >= 10000 && value * (200 - this.defaultItemPrice) * 30 < 25000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.15);

        if (value * (200 - this.defaultItemPrice) * 30 >= 25000 && value * (200 - this.defaultItemPrice) * 30 < 50000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.20);

        if (value * (200 - this.defaultItemPrice) * 30 >= 50000 && value * (200 - this.defaultItemPrice) * 30 < 100000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.30);

        if (value * (200 - this.defaultItemPrice) * 30 >= 100000 && value * (200 - this.defaultItemPrice) * 30 < 150000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.31);

        if (value * (200 - this.defaultItemPrice) * 30 >= 150000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.32);

        this.setState((state: ILand2State) => ({
            reverseValue: value,
            itemPrice: newPrice,
            printPriceItemCountDay: value,
            printPriceItemCountMonth: value * 30,
            printPriceItemsProfitDay: value * (200 - newPrice),
            printPriceItemsProfitMonth: value * (200 - newPrice) * 30,
        }))
    }

    public OnOpenRegistrationPage(): void {
        window.open('https://b2b.caseshop.com.ua/auth/register', '_self');
    }

    render() {
        const horizontalLabels = {
            0: '0',
            25: '25',
            50: '50',
            75: '75'
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 1,
            slidesToShow: this.slidesToShowItem < 960 ? 1 : 3,
            slidesToScroll: this.slidesToShowItem < 960 ? 1 : 3,
            autoplay: true
        };

        const { value, reverseValue, mobileMenuOpen } = this.state;

        const Completionist = () => <span>Время вышло</span>;

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return <Completionist />;
            } else {
                return <span>{hours}:{minutes}:{seconds}</span>;
            }
        };

        return (
            <div className="landPageContainer landPage2Container">
                <div className={`landGLOBAL__header ${(mobileMenuOpen ? 't' : 'f')}`} >
                    <div className={`landGLOBAL__headerContainer ${(mobileMenuOpen ? 't' : 'f')}`} >
                        <div className="menuICON__container" onClick={() => this.handleChangeMenu()}>
                            <div className="lineSC"></div>
                            <div className="lineSC"></div>
                            <div className="lineSC"></div>
                        </div>
                        <ul className={`header_menu_component ${(mobileMenuOpen ? 't' : 'f')}`} >
                            <li className="menu_list_item ">
                                <NavLink to={"/dropshipping_chehly"} activeClassName='active'>Дропшиппинг</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/chehly_optom"} activeClassName='active'>Чехлы и аксессуары оптом</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/auth/login"} activeClassName='active'>Вход</NavLink>
                            </li>
                        </ul>
                        <div className="header_logo"></div>
                    </div>
                </div>

                <Helmet>
                    <title>Чехлы и мобильные аксессуары оптом в Украине - caseshop</title>
                    <meta name="description" content="Ищешь поставщика чехлов оптом? Зарабатывай от 2000$/мес покупая аксессуары для телефонов оптом. Без минимальной стоимости. Широкий ассортимент." />
                    <meta property='og:title' content='Чехлы и мобильные аксессуары оптом в Украине - caseshop' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='http://b2b.caseshop.com.ua/logo.png' />
                    <meta property='og:description' content="Ищешь поставщика чехлов оптом? Зарабатывай от 2000$/мес покупая аксессуары для телефонов оптом. Без минимальной стоимости. Широкий ассортимент." />
                    <meta property='og:url' content="http://b2b.caseshop.com.ua/" />
                </Helmet>

                <div className="landWrapper__container">
                    <div className="topPicture__container top2Picture__container">
                        <div className="topPicture__containerTextPhone">
                            <span className="phoneContainer">069 669 96 96</span>
                            <span className="emailContainer">b2b.caseshop@gmail.com</span>
                        </div>
                        <div className="topPicture__containerText">
                            <h1 className="span">Платформа по продаже чехлов оптом
                                <br />
                                от CASESHOP
                                </h1>
                            <span className="logo hide" />
                            <span>
                                Рады приветствовать Вас в нашей системе! <br />
                                Более 8 лет мы поставляем мобильные аксессуары оптом нашим партнерам по всей Украине. <br />
                                Качественная продукция и высший уровень обслуживания являются залогом Вашего заработка.
                            </span>

                            <div className="regButton" onClick={() => {
                                //window.gtag('event', 'Регистрация_Опт', { 'event_category': 'ButtonConfirm', 'event_action': 'RegistrationOpt', })
                                this.OnOpenRegistrationPage()}}>Регистрация</div>
                        </div>
                    </div>

                    <div className="landContainer">
                        <h2 className="sectionTitle">Наш ассортимент</h2>

                        <div className="topBoxsContainer__2">
                            <div className="topBoxs__item topBoxs__item_v2__item">
                                <div className="topBoxs__itemTitle">Накладки силиконовые</div>
                                <div className="topBoxs__itemWrapp">
                                    Силиконовый бампер станет лучшей защитой гаджета для Вашего клиента
                                </div>
                            </div>
                            <div className="topBoxs__item topBoxs__item_v2__item">
                                <div className="topBoxs__itemTitle">Товары для планшетов</div>
                                <div className="topBoxs__itemWrapp">
                                    Много поставщиков не имеют защиты на планшеты, кожаных, силиконовый чехлов. У нас широкий ассортимент на эту категорию
                                </div>
                            </div>
                            <div className="topBoxs__item topBoxs__item_v2__item">
                                <div className="topBoxs__itemTitle">Защитные стекла, пленки</div>
                                <div className="topBoxs__itemWrapp">
                                    Что может быть важнее защиты экрана телефона! Для этого у нас есть защитные пленки, обычные, 3D стекла
                                </div>
                            </div>

                            <div className="topBoxs__item topBoxs__item_v2__item">
                                <div className="topBoxs__itemTitle">Кожаные чехлы</div>
                                <div className="topBoxs__itemWrapp">
                                    Уникальные изделия, изготовленные из высококачественной натуральной кожи
                                </div>
                            </div>
                        </div>


                        <div className="timeContainer">
                            <div className=" timeBoxContainer">
                                <div className="h2">
                                    Зарегистрируйся сейчас и получи бонус - 1 чехол с печатью в подарок. Поспеши!
                                </div>

                                <div className="timeCont">
                                    <Countdown
                                        date={Date.now() + 1800000}
                                        renderer={renderer}
                                    >
                                        <Completionist />
                                    </Countdown>
                                </div>

                                <div className="regButton" onClick={() => this.OnOpenRegistrationPage()}>Регистрация</div>
                            </div>
                        </div>

                        <div className="wthContainer">
                            <h2 className="sectionTitle">Почему выгодно покупать аксессуары для телефонов оптом у нас?</h2>
                            <div className="wtgItem__col first">
                                <div className="h2">100 процентное качество</div>
                                <p>
                                    Каждый аксессуар проходит несколько ступеней проверки перед отправкой. На чехлы собственного производства используем только натуральные и качественные материалы
                                </p>

                                <div className="h2">Удобная система расчета</div>
                                <p>
                                    В Вашем личном кабинете, Вы всегда можете увидеть свои заказы, сведения о взаиморасчетах
                                </p>

                                <div className="h2">Персональний подход</div>
                                <p>
                                    Всегда при возможности идем на встречу, учитываем персональные пожелание, не отказываем в помощи
                                </p>
                            </div>
                            <div className="wtgItem__col mmidImage">

                            </div>
                            <div className="wtgItem__col">
                                <div className="h2">Не нарушаем сроки исполнения</div>
                                <p>
                                    Мы не хотим подводить Ваших клиентов, потому понимаем важность своевременной отправки каждого заказа, а при возможности отправляем посылки быстрее
                                 </p>

                                <div className="h2">Система оптовых цен</div>
                                <p>
                                    При покупке одного товара количеством от 5 штук, Вы получаете очень выгодную оптовою цену на чехлы на айфон оптом в украине, что увеличит прибыль
                                </p>

                                <div className="h2">Большой ассортимент</div>
                                <p>
                                    Регулярное пополнение ассортимента для мобильных телефонов
                                    Мы не перестаем мониторить рынок на появление новых гаджетов, поэтому наш ассортимент беспрерывно растет, составляя более 1500 моделей
                                </p>
                            </div>
                        </div>

                        <h2 className="sectionTitle">Отзывы бизнес-партнеров</h2>
                        <div className="containerCarousel">
                            <Carousel {...settings}>
                                <div>
                                    <div className="partNameContainer">Богдан</div>
                                    <small>Сотрудничество с октября 2018</small>
                                    <div className="h3">
                                        Долго искал надежного поставщика аксессуаров. Знакомый товарищ подсказал контакты Caseshop. Сначала отнесся скептически к предложению, ведь было очень заманчиво. После месяца сотрудничества все сомнения пропали. Быстрая, слаженная работа, прекрасная поддержка. Рекомендую!
                                    </div>
                                </div>
                                <div>
                                    <div className="partNameContainer">Андрей</div>
                                    <small>Сотрудничество с июля 2018</small>
                                    <div className="h3">
                                        Год назад увидел предложение о сотрудничестве, еще тогда понимал, что продажа чехлов на мобильные телефоны в Instagram - тренд в Украине. Сам связался с компанией, начали работу буквально на следующий день. Конечно сначала заказов было мало, но через 2 месяца я получил очень достойный доход
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Ольга</div>
                                    <small>Сотрудничество с июля 2019</small>
                                    <div className="h3">
                                        Не первый месяц сотрудничаю с CaseShop. У них все налажено, сделал заказ, на следующий день получил чехлы. Опт и даже единичные покупки проходят легко и приятно. Рекомендую как надежного партнера!
                                    </div>
                                </div>
                                <div>
                                    <div className="partNameContainer">Артем</div>
                                    <small>Сотрудничество с мая 2019</small>
                                    <div className="h3">
                                        Компания в которой я заказывал чехлы для телефонов оптом постоянно подводила по срокам, качество было плохое. Соответственно, мои посылки не забирали более 6% клиентов. После часа поисков, нашел Caseshop. Представитель компании сразу объяснил как работать. Переход занял пол дня. На следующий день мои посылки были отправлены новым поставщиком. Благодаря этому показатель отказов снизился ровно вдвое, до 3%. Полностью доволен
                                   </div>
                                </div>
                                <div>
                                    <div className="partNameContainer">Сергей</div>
                                    <small>Сотрудничество с мая 2017</small>
                                    <div className="h3">
                                        Большое спасибо команде Кейсшоп! Слаженное профессиональное обслуживание и качественно выполненные заказы, - это все что нужно. Именно это я получаю с первого дня нашего партнерства. Так держать!
                                    </div>
                                </div>
                                <div>
                                    <div className="partNameContainer">Олег</div>
                                    <small>Сотрудничество с января 2019</small>
                                    <div className="h3">
                                        Приятные цены, ответственный персонал, своевременная обработка, всегда идут навстречу, никогда не забывают предупреждать о возможных накладках. Жаль что я Вас нашёл только сейчас, так как мой предыдущий поставщик кидал меня и моих клиентов при любой возможности, про качество вообще молчу. Рекомендую Caseshop как прекрасного поставщика в Украине!
                                    </div>
                                </div>
                            </Carousel>
                        </div>

                        <div className="dropContainer">
                            <div className="dropTextContainer">
                                <h2>Схема работы</h2>
                                <p>
                                    Продавая с нами, Вы убедитесь, что надежные, ответственные поставщики существуют. Вам не придется думать о задержках, качестве мобильных аксессуаров оптом и других аспектах, все будет на высшем уровне. Мы уверены в своем профессионализме. Вы убедитесь в нашей надежности!
                                </p>

                                <div className="regButton" onClick={() => this.OnOpenRegistrationPage()}>Регистрация</div>
                            </div>

                            <div className="blockCrContainer"></div>
                        </div>

                        <h2 className="sectionTitle myW">Наши аксессуары для мобильных телефонов</h2>
                        <div className="myProdsContainer myProds2Container">
                            <div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>

                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                            </div>
                        </div>


                        <div className="footerContainer">
                            <div className="phoneContainer">069 669 96 96</div>
                            <div className="emailContainer">b2b.caseshop@gmail.com</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
