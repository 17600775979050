import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import { Form, actions } from "react-redux-form";
import { TextBoxWithLabel } from "../../../../shared/form.controls/textbox.with.label";
import { MaxLength, Validator } from "../../../../../helpers/validator.helper";
import * as actionCreators from "../../../../../actions/partner/partner.info.actions"
import { ServerValidator } from "../../../../../helpers/server.validation.helper";

const mapStateToProps = (state) => {
    return {
        partner: state.partnerUserNameForm,
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ChangePartnerUserName: actionCreators.UpdatePartnerUserName
    }, dispatch);
};

const ChangeUserNameForm = (props: any) => {
    return (
        <Form
            className="default_form_component shadow_controls"
            validateOn='change'
            model="partnerUserNameForm"
            onSubmit={(partnerUserForm: any) => props.ChangePartnerUserName(partnerUserForm.OldUserName, partnerUserForm.NewUserName)}>
            <div className="form_row_component">
                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                        IsMaxLength: MaxLength(50)
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        IsMaxLength: 'TooManyCharacters',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Старое имя'
                    IsRequired={true}
                    Model='.OldUserName' 
                    Readonly/>

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                        IsMaxLength: MaxLength(50)
                    }}
                    AsyncValidators={{
                        isUniqueName: (val: string, done: any) =>
                            ServerValidator.CheckNameAvailability(val).then(res => done(res))
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        IsMaxLength: 'TooManyCharacters',
                        isUniqueName: 'Name is occupied'
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    AsyncValidateOn="blur"
                    LabelMessage="Новое имя"
                    IsRequired={true}
                    Model='.NewUserName' />

            </div>
            <div className="defaultFormControl ">
                <button type="submit" className="defaultFormButton" >
                    Изменить
                </button>
            </div>
        </ Form>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeUserNameForm)