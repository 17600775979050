import { MutualSettlement } from "../../business.entities/mutual.settlement";

export const GET_ALL_PARTNERS: string = '[API] MUTUAL.SETTLEMENTS.GET_ALL_PARTNERS';
export interface IGetAllPartners { type: '[API] MUTUAL.SETTLEMENTS.GET_ALL_PARTNERS'; payload: any; }
export const GetAllPartners = () => <IGetAllPartners>{ type: GET_ALL_PARTNERS };

export const SET_ALL_PARTNERS: string = 'MUTUAL.SETTLEMENTS.SET_ALL_PARTNERS';
export interface ISetAllPartners { type: 'MUTUAL.SETTLEMENTS.SET_ALL_PARTNERS'; payload: any; }
export const SetAllPartners = (partners: any[]) => <ISetAllPartners>{ type: SET_ALL_PARTNERS, payload: partners };

export const GET_PARTNERS_AUTOCOMPLETE: string = '[API] MUTUAL.SETTLEMENTS.GET_PARTNERS_AUTOCOMPLETE';
export interface IGetPartnersAutocomplete { type: '[API] MUTUAL.SETTLEMENTS.GET_PARTNERS_AUTOCOMPLETE'; payload: any; }
export const GetPartnersAutocomplete = (value: string) => <IGetPartnersAutocomplete>{ type: GET_PARTNERS_AUTOCOMPLETE, payload: value };

export const CHANGE_PARTNER_SELECT: string = 'MUTUAL.SETTLEMENTS.CHANGE_PARTNER_SELECT';
export interface IChangePartnerSelect { type: 'MUTUAL.SETTLEMENTS.CHANGE_PARTNER_SELECT'; payload: any; }
export const ChangePartnerSelect = (value: any) =>
    <IChangePartnerSelect>{ type: CHANGE_PARTNER_SELECT, payload: value };

export const SELECT_OPERATION_TYPE: string = 'MUTUAL.SETTLEMENTS.SELECT_OPERATION_TYPE';
export interface ISelectOperationType { type: 'MUTUAL.SETTLEMENTS.SELECT_OPERATION_TYPE'; payload: any; }
export const SelectOperationType = (value: any) =>
    <ISelectOperationType>{ type: SELECT_OPERATION_TYPE, payload: value };

export const GET_MUTUALSETTLEMENTS: string = '[API] MUTUAL.SETTLEMENTS.GET_ALL';
export interface IGetAllMutualSettlements { type: '[API] MUTUAL.SETTLEMENTS.GET_ALL'; payload: any; }
export const GetAllMutualSettlements = (netId: string, page: number, count: number, filter: any) =>
    <IGetAllMutualSettlements>{ type: GET_MUTUALSETTLEMENTS, payload: { netId, page, count, filter } };

export const ADD_MUTUALSETTLEMENTS: string = '[API] MUTUAL.SETTLEMENTS.ADD';
export interface IAddMutualSettlements { type: '[API] MUTUAL.SETTLEMENTS.ADD'; payload: any; }
export const AddMutualSettlements = (mutualSettlement: MutualSettlement) =>
    <IAddMutualSettlements>{ type: ADD_MUTUALSETTLEMENTS, payload: mutualSettlement };

export const SET_ALL_MUTUALSETTLEMENTS: string = 'MUTUAL.SETTLEMENTS.SET_ALL';
export interface ISetAllMutualSettlements { type: 'MUTUAL.SETTLEMENTS.SET_ALL'; payload: any; }
export const SetAllMutualSettlements = (mutualSettlements: MutualSettlement[], count: number) =>
    <ISetAllMutualSettlements>{ type: SET_ALL_MUTUALSETTLEMENTS, payload: { mutualSettlements, count } };

export const SET_MUTUALSETTLEMENTS: string = 'MUTUAL.SETTLEMENTS.SET';
export interface ISetMutualSettlements { type: 'MUTUAL.SETTLEMENTS.SET'; payload: any; }
export const SetMutualSettlements = (mutualSettlement: MutualSettlement) =>
    <ISetMutualSettlements>{ type: SET_MUTUALSETTLEMENTS, payload: mutualSettlement };

export const GET_BALANCE: string = '[API] MUTUAL.SETTLEMENTS.GET_BALANCE';
export interface IGetBalance { type: '[API] MUTUAL.SETTLEMENTS.GET_BALANCE'; payload: any; }
export const GetBalance = (partnerNetId: string) =>
    <IGetBalance>{ type: GET_BALANCE, payload: partnerNetId };

export const SET_BALANCE: string = '[API] MUTUAL.SETTLEMENTS.SET_BALANCE';
export interface ISetBalance { type: '[API] MUTUAL.SETTLEMENTS.SET_BALANCE'; payload: any; }
export const SetBalance = (balance: number) =>
    <ISetBalance>{ type: SET_BALANCE, payload: balance };

export const UNMOUNT_COMPONENT: string = '[API] MUTUAL.UNMOUNT_COMPONENT.SET_BALANCE';
export interface IUnmountComponent { type: '[API] MUTUAL.UNMOUNT_COMPONENT.SET_BALANCE'; payload: any; }
export const UnmountComponent = () =>
    <IUnmountComponent>{ type: UNMOUNT_COMPONENT };

export const GET_ORDERS_AUTOCOMPLETE: string = '[API] MUTUAL.SETTLEMENTS.GET_ORDERS_AUTOCOMPLETE';
export interface IGetOrdersAutocomplete { type: '[API] MUTUAL.SETTLEMENTS.GET_ORDERS_AUTOCOMPLETE'; payload: any; }
export const GetOrdersAutocomplete = (partnerId: number, value: string) =>
    <IGetOrdersAutocomplete>{ type: GET_ORDERS_AUTOCOMPLETE, payload: { partnerId, value } };

export const SET_ORDERS_AUTOCOMPLETE: string = '[API] MUTUAL.SETTLEMENTS.SET_ORDERS_AUTOCOMPLETE';
export interface ISetOrdersAutocomplete { type: '[API] MUTUAL.SETTLEMENTS.SET_ORDERS_AUTOCOMPLETE'; payload: any; }
export const SetOrdersAutocomplete = (value) =>
    <ISetOrdersAutocomplete>{ type: SET_ORDERS_AUTOCOMPLETE, payload: value };

export const GET_ORDERS_AUTOCOMPLETE_FILTER: string = '[API] MUTUAL.SETTLEMENTS.GET_ORDERS_AUTOCOMPLETE_FILTER';
export interface IGetOrdersAutocompleteFilter { type: '[API] MUTUAL.SETTLEMENTS.GET_ORDERS_AUTOCOMPLETE_FILTER'; payload: any; }
export const GetOrdersAutocompleteFilter = (partnerId: number, value: string) =>
    <IGetOrdersAutocompleteFilter>{ type: GET_ORDERS_AUTOCOMPLETE_FILTER, payload: { partnerId, value } };

export const SET_ORDERS_AUTOCOMPLETE_FILTER: string = 'MUTUAL.SETTLEMENTS.SET_ORDERS_AUTOCOMPLETE_FILTER';
export interface ISetOrdersAutocompleteFilter { type: 'MUTUAL.SETTLEMENTS.SET_ORDERS_AUTOCOMPLETE_FILTER'; payload: any; }
export const SetOrdersAutocompleteFilter = (value) =>
    <ISetOrdersAutocompleteFilter>{ type: SET_ORDERS_AUTOCOMPLETE_FILTER, payload: value };

export const CHANGE_ORDER_SELECT: string = 'MUTUAL.SETTLEMENTS.CHANGE_ORDER_SELECT';
export interface IChangeOrderSelect { type: 'MUTUAL.SETTLEMENTS.CHANGE_ORDER_SELECT'; payload: any; }
export const ChangeOrderSelect = (value: any) =>
    <IChangeOrderSelect>{ type: CHANGE_ORDER_SELECT, payload: value };

export const CHANGE_OPERATION: string = 'MUTUAL.SETTLEMENTS.CHANGE_OPERATION';
export interface IChangeOperation { type: 'MUTUAL.SETTLEMENTS.CHANGE_OPERATION'; payload: any; }
export const ChangeOperation = (value) =>
    <IChangeOperation>{ type: CHANGE_OPERATION, payload: value };

export const CHANGE_ORDER: string = 'MUTUAL.SETTLEMENTS.CHANGE_ORDER';
export interface IChangeOrder { type: 'MUTUAL.SETTLEMENTS.CHANGE_ORDER'; payload: any; }
export const ChangeOrder = (value) =>
    <IChangeOrder>{ type: CHANGE_ORDER, payload: value };

export const CHANGE_START_DATE: string = 'MUTUAL.SETTLEMENTS.CHANGE_START_DATE';
export interface IChangeStartDate { type: 'MUTUAL.SETTLEMENTS.CHANGE_START_DATE'; payload: any; }
export const ChangeStartDate = (value) =>
    <IChangeStartDate>{ type: CHANGE_START_DATE, payload: value };

export const CHANGE_END_DATE: string = 'MUTUAL.SETTLEMENTS.CHANGE_END_DATE';
export interface IChangeEndDate { type: 'MUTUAL.SETTLEMENTS.CHANGE_END_DATE'; payload: any; }
export const ChangeEndDate = (value) =>
    <IChangeEndDate>{ type: CHANGE_END_DATE, payload: value };

export const CLEAR_FILTER: string = 'MUTUAL.SETTLEMENTS.CLEAR_FILTER';
export interface IClearFilter { type: 'MUTUAL.SETTLEMENTS.CLEAR_FILTER'; payload: any; }
export const ClearFilter = (value) =>
    <IClearFilter>{ type: CHANGE_END_DATE, payload: value };

export const CHANGE_PAGE: string = 'MUTUAL.SETTLEMENTS.CHANGE_PAGE';
export interface IChangePage { type: 'MUTUAL.SETTLEMENTS.CHANGE_PAGE'; payload: any; }
export const ChangePage = (value) =>
    <IChangePage>{ type: CHANGE_PAGE, payload: value };

export const CHANGE_LIMIT: string = 'MUTUAL.SETTLEMENTS.CHANGE_LIMIT';
export interface IChangeLimit { type: 'MUTUAL.SETTLEMENTS.CHANGE_LIMIT'; payload: any; }
export const ChangeLimit = (value: any) =>
    <IChangeLimit>{ type: CHANGE_LIMIT, payload: value };

export const SET_FILTERED: string = 'MUTUAL.SETTLEMENTS.SET_FILTERED';
export interface ISetFiltered { type: 'MUTUAL.SETTLEMENTS.SET_FILTERED'; payload: any; }
export const SetFiltered = (value: boolean) =>
    <ISetFiltered>{ type: SET_FILTERED, payload: value}

export const EXPORT_MUTUAL_SETTLEMENTS: string = '[API] MUTUAL.SETTLEMENTS.EXPORT_MUTUAL_SETTLEMENTS';
export interface IExportMutualSettlements { type: '[API] MUTUAL.SETTLEMENTS.EXPORT_MUTUAL_SETTLEMENTS'; payload: any; }
export const ExportMutualSettlements = (value: any) =>
    <IExportMutualSettlements> { type: EXPORT_MUTUAL_SETTLEMENTS, payload: value }

export const EXPORT_MUTUAL_SETTLEMENTS_SUCCESS: string = 'MUTUAL.SETTLEMENTS.EXPORT_MUTUAL_SETTLEMENTS_SUCCESS';
export interface IExportMutualSettlementsSuccess { type: 'MUTUAL.SETTLEMENTS.EXPORT_MUTUAL_SETTLEMENTS_SUCCESS'; payload: any }
export const ExportMutualSettlementsSuccess = (value: any) =>
    <IExportMutualSettlementsSuccess> { type: EXPORT_MUTUAL_SETTLEMENTS_SUCCESS, payload: value }

export const SET_AFTERPAYMENTS_IMPORT_FILE_NAME: string = 'MUTUAL.SETTLEMENTS.SET_AFTERPAYMENTS_IMPORT_FILE_NAME';
export interface ISetAfterpaymentsImportFileName { type: 'MUTUAL.SETTLEMENTS.SET_AFTERPAYMENTS_IMPORT_FILE_NAME'; payload: any }
export const SetAfterpaymentsImportFileName = (value: any) => 
    <ISetAfterpaymentsImportFileName> {type: SET_AFTERPAYMENTS_IMPORT_FILE_NAME, payload: value }

export const SET_AFTERPAYMENTS_IMPORT_FILE: string = 'MUTUAL.SETTLEMENTS.SET_AFTERPAYMENTS_IMPORT_FILE';
export interface ISetAfterpaymentsImportFile { type: 'MUTUAL.SETTLEMENTS.SET_AFTERPAYMENTS_IMPORT_FILE'; payload: any }
export const SetAfterpaymentsImportFile = (value: any) =>
    <ISetAfterpaymentsImportFile> {type: SET_AFTERPAYMENTS_IMPORT_FILE, payload: value }

export const LOAD_AFTERPAYMENS_FILE: string = '[API] MUTUAL.SETTLEMENTS.LOAD_AFTERPAYMENS_FILE';
export interface ILoadAfterpaymentsFile { type: '[API] MUTUAL.SETTLEMENTS.LOAD_AFTERPAYMENS_FILE'; payload: any } 
export const LoadAfterpaymentsFile = (value: any) =>
    <ILoadAfterpaymentsFile> { type: LOAD_AFTERPAYMENS_FILE, payload: value }

export const LOAD_AFTERPAYMENTS_FILE_SUCCESS: string = '[API] MUTUAL.SETTLEMENTS.LOAD_AFTERPAYMENTS_FILE_SUCCESS';
export interface ILoadAfterpaymentsFileSuccess { type: '[API] MUTUAL.SETTLEMENTS.LOAD_AFTERPAYMENTS_FILE_SUCCESS'; }
export const LoadAfterpaymentsFileSuccess = () =>
    <ILoadAfterpaymentsFileSuccess> { type: LOAD_AFTERPAYMENTS_FILE_SUCCESS }

export type MutualSettlementsKnownActions =
    IGetAllPartners |
    IGetPartnersAutocomplete |
    ISelectOperationType |
    IChangePartnerSelect |
    ISetAllPartners |
    IGetAllMutualSettlements |
    IAddMutualSettlements |
    ISetMutualSettlements |
    ISetAllMutualSettlements |
    IGetBalance |
    ISetBalance |
    IUnmountComponent |
    IGetOrdersAutocomplete |
    ISetOrdersAutocomplete |
    IChangeOrderSelect |
    IChangeLimit |
    IChangePage | 
    IGetOrdersAutocompleteFilter |
    ISetOrdersAutocompleteFilter |
    IChangeOrderSelect |
    IChangeOperation |
    IChangeOrder |
    IChangeStartDate |
    IChangeEndDate |
    ISetFiltered |
    IExportMutualSettlements |
    ISetAfterpaymentsImportFileName |
    ISetAfterpaymentsImportFile |
    ILoadAfterpaymentsFileSuccess;