import { Lexicon } from "../../business.entities/lexicon";

export const SELECT_LEXICON: string = 'SELECT_LEXICON';
export interface ISelectLexicon { type: 'SELECT_LEXICON'; payload: any; }
export const SelectLexicon = (lexicon: Lexicon) =>
    <ISelectLexicon>{ type: SELECT_LEXICON, payload: lexicon };

export const DESELECT_LEXICON: string = 'DESELECT_LEXICON';
export interface IDeselectLexicon { type: 'DESELECT_LEXICON'; payload: any; }
export const DeselectLexicon = () =>
    <IDeselectLexicon>{ type: DESELECT_LEXICON };

export const UPDATE_LEXICON: string = '[API] UPDATE_LEXICON';
export interface IUpdateLexicon { type: '[API] UPDATE_LEXICON'; payload: any; }
export const UpdateLexicon = (lexicon: Lexicon) =>
    <IUpdateLexicon>{ type: UPDATE_LEXICON, payload: lexicon };

export const UPDATE_LEXICON_SUCCESS: string = 'UPDATE_LEXICON_SECCESS';
export interface IUpdateLexiconSuccess { type: 'UPDATE_LEXICON_SECCESS'; payload: any; }
export const UpdateLexiconSuccess = () =>
    <IUpdateLexiconSuccess>{ type: UPDATE_LEXICON_SUCCESS };

export const UPDATE_LEXICON_FAILED: string = 'UPDATE_LEXICON_FAILED';
export interface IUpdateLexiconFailed { type: 'UPDATE_LEXICON_FAILED'; payload: any; }
export const UpdateLexiconFailed = () =>
    <IUpdateLexiconFailed>{ type: UPDATE_LEXICON_FAILED };

export const GET_LEXICONS_SUCCESS: string = 'GET_LEXICONS_SUCCESS';
export interface IGetLexiconsSuccess { type: 'GET_LEXICONS_SUCCESS'; payload: any; }
export const GetLexiconsSuccess = (response: any) =>
    <IGetLexiconsSuccess>{ type: GET_LEXICONS_SUCCESS, payload: response };

export const GET_LEXICONS_FAILED: string = 'GET_LEXICONS_FAILED';
export interface IGetLexiconsFailed { type: 'GET_LEXICONS_FAILED'; payload: any; }
export const GetLexiconsFailed = (response: any) =>
    <IGetLexiconsFailed>{ type: GET_LEXICONS_FAILED, payload: response };

export const GET_ALL_LEXICONS: string = '[API] GET_ALL_LEXICONS';
export interface IGetAllLexicons { type: '[API] GET_ALL_LEXICONS'; payload: any; }
export const GetAllLexicons = () => <IGetAllLexicons>{ type: GET_ALL_LEXICONS };

export const GET_LEXICONS_FILTERED_PAGINATED: string = '[API] GET_LEXICONS_FILTERED_PAGINATED';
export interface IGetLexiconsFilteredPaginated { type: '[API] GET_LEXICONS_FILTERED_PAGINATED'; payload: any; }
export const GetLexiconsFilteredPaginated = (count: number, page: number, value: string) =>
    <IGetLexiconsFilteredPaginated>{ type: GET_LEXICONS_FILTERED_PAGINATED, payload: { count: count, page: page, value: value } };

export const UNMOUNT_LEXICON: string = 'UNMOUNT_LEXICON';
export interface IUnmountLexicon { type: 'UNMOUNT_LEXICON'; payload: any; }
export const UnmountLexicon = () =>
    <IUnmountLexicon>{ type: UNMOUNT_LEXICON };

export type LexiconKnownActions =
    ISelectLexicon |
    IDeselectLexicon |
    IUpdateLexiconSuccess |
    IUpdateLexiconFailed |
    IGetLexiconsSuccess |
    IGetLexiconsFailed |
    IGetAllLexicons |
    IGetLexiconsFilteredPaginated |
    IUnmountLexicon;
