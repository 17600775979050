import * as React from 'react';
import * as WizardActions from '../../../../actions/sales/wizard.actions';
import * as orderCardAction from "../../../../actions/sales/order.card.actions";
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { default as OrderProductStep } from './order.product.step';
import { DeliveryType } from '../../../../helpers/enums/delivery.type';
import { PaymentMethod } from '../../../../helpers/enums/payment.method';
import { TempOrder } from '../../../../business.entities/temp.order';
import { Validator } from '../../../../helpers/validator.helper';
import { WizardState } from '../../../../states/sales/wizard.state';
import * as moment from 'moment';
import * as API from "../../../../constants/api.constants";
import { CalculateDeliveryDateBody } from '../../../../helpers/novaposhta.body.builder';
import { Loader } from '../../../shared/loader';
import { Order } from '../../../../business.entities/order';
import { DeliveryInfo } from '../../../../helpers/deliveryInfo.entity';

export function SetClientPhoneThunkAction(value, mode) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            resolve(dispatch(WizardActions.SetClientPhone(value, mode)));
        });
    }
}

export function AddNewClientThunk(value) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            resolve(dispatch(WizardActions.AddNewClient(value)));
        });
    }
}

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard,
        OrderCard: state.sales.orderCard,
        UserInfo: state.authentication.userInfo,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectWizardStep: WizardActions.SelectWizardStep,
    GetClients: WizardActions.GetClientsForPartner,
    SelectClient: WizardActions.SelectClientPartner,
    SetClientPhone: SetClientPhoneThunkAction,
    ResetClientInfo: WizardActions.ResetClientInfo,
    ChangeRecipient: WizardActions.ChangeRecipient,
    SetPaymentMethod: WizardActions.SetPaymentMethod,
    SetDeliveryType: WizardActions.SetDeliveryType,
    ChangeNewClientName: WizardActions.ChangeNewClientName,
    ChangeNewClientEmail: WizardActions.ChangeNewClientEmail,
    UpdateTempOrder: WizardActions.UpdateTempOrder,
    AddNewClient: AddNewClientThunk,
    ChangeRecipientName: WizardActions.ChangeRecipientName,
    ChangeRecipientPhone: WizardActions.ChangeRecipientPhone,
    GetSities: WizardActions.GetCitiesForAutocomplete,
    SelectSity: WizardActions.SelectCitiesForAutocomplete,
    GetWarehouses: WizardActions.GetWerehousesForAutocomplete,
    SelectWarehouse: WizardActions.SelectWerehousesForAutocomplete,
    ChangeUkrCity: WizardActions.ChangeUkrCityName,
    ChangeUkrStreet: WizardActions.ChangeUkrStreetName,
    ChangeUkrBuilding: WizardActions.ChangeUkrBuildingNum,
    ChangeUkrFlat: WizardActions.ChangeUkrFlatNum,
    ChangeUkrZip: WizardActions.ChangeUkrZip,
    GetDeliveryPrice: WizardActions.GetDeliveryPrice,
    CalculateDeliveryDate: WizardActions.CalculateDeliveryDate,
    SetDeliveryDate: WizardActions.SetDeliveryDate,

    GetCityUkrPoshtaForAutocomplete: orderCardAction.GetCityUkrPoshtaForAutocomplete,
}, dispatch);

const InfoStep = (props: any) => {

    document.onkeydown = (e) => {
        if ((event as any).ctrlKey && (event as any).keyCode == 49) {
            event.preventDefault();

            props.SelectWizardStep(OrderProductStep, 1);
        }
    }

    const OnClientPhoneNumberChangeCommand = (value) => {
        if (value && Validator.IsNumeric(value)) {
            props.GetClients(value);
        }

        if (value === '')
            props.GetClients("");
    }

    const OnNewNumberChangeCommand = (value) => {
        props.SetClientPhone("", false).then(() => {
            props.Wizard.clientSelectComponentRef.current.state.inputValue = value;
            props.GetClients(value);
            props.Wizard.clientSelectComponentRef.current.focus();
        });
    }

    const OnSelectClientCommand = (value) => {
        props.SelectClient(value);
        props.Wizard.clientSelectComponentRef.current.blur();
    }

    const OnFocusClientSelectCommand = () => {
        if (props.Wizard.selectedClient) {
            props.Wizard.clientSelectComponentRef.current.state.inputValue = props.Wizard.selectedClient.label;
            props.GetClients(props.Wizard.selectedClient.label);

            props.ResetClientInfo();
        }
    }

    const OnChangeRecipientCommand = () => {
        if (props.Wizard.order.Client)
            props.ChangeRecipient(!props.Wizard.isRecipient);
    }

    const OnSetDeliveryTypeCommand = (value) => {
        if (value !== (props.Wizard as WizardState).order.DeliveryType) {
            value !== DeliveryType.Nova && props.SetDeliveryDate(null);

            if (value === DeliveryType.Nova && (props.Wizard as WizardState).selectedCity)
                (props.Wizard as WizardState).selectedCity.value && props.CalculateDeliveryDate(
                    CalculateDeliveryDateBody(
                        props.UserInfo.NovaPoshtaApiKey,
                        (props.Wizard as WizardState).sendDate,
                        (props.Wizard as WizardState).selectedCity.value
                    )
                )

            if (value == DeliveryType.Ukr && props.Wizard.order.PaymentMethod == PaymentMethod.Afterpaid)
                props.SetPaymentMethod(PaymentMethod.Prepaid);

            if (value == DeliveryType.Self && props.Wizard.order.PaymentMethod == PaymentMethod.Afterpaid)
                props.SetPaymentMethod(PaymentMethod.Prepaid);

            props.SetDeliveryType(value);
            props.GetDeliveryPrice(value, props.Wizard.order.PaymentMethod);
        }
    }

    const OnSetPaymentMethodCommand = (value) => {
        // if (props.Wizard.order.DeliveryType == DeliveryType.Ukr && value == PaymentMethod.Afterpaid)
        //     return;

        if (props.Wizard.order.DeliveryType == DeliveryType.Self && value == PaymentMethod.Afterpaid)
            return;

        props.SetPaymentMethod(value);
        props.GetDeliveryPrice(props.Wizard.order.DeliveryType, value);
    }

    const OnChangeNewClientName = (value) => {
        if (value && Validator.IsCyrylicLetters(value))
            props.ChangeNewClientName(value);

        if (value === '')
            props.ChangeNewClientName(value);
    }

    const OnChangeNewClientEmail = (value) => {
        props.ChangeNewClientEmail(value);
    }

    const OnAddClientToOrderCommand = () => {
        let orderToUpdate = Object.assign({}, props.Wizard.order, {
            Client: (props.Wizard as WizardState).bufferClient,
            ClientId: (props.Wizard as WizardState).bufferClient.Id,
            RecipientName: !props.Wizard.isRecipient ? (props.Wizard as WizardState).bufferClient.FullName : props.Wizard.order.RecipientName,
            RecipientPhoneNumber: !props.Wizard.isRecipient ? (props.Wizard as WizardState).bufferClient.PhoneNumber : props.Wizard.order.RecipientPhoneNumber
        } as TempOrder);

        !props.Wizard.fetching && props.UpdateTempOrder(orderToUpdate);
    }

    const OnChangeRecipientNameCommand = (value) => {
        if (value && Validator.IsCyrylicLetters(value))
            props.ChangeRecipientName(value);

        if (value === '')
            props.ChangeRecipientName(value);
    }

    const OnChangeRecipientPhoneCommand = (value) => {
        if (value && Validator.IsNumeric(value)) {
            if ((value.startsWith('0', 0) && value.length <= 10)
                || (value.startsWith('3', 0) && value.length <= 12)) {
                props.ChangeRecipientPhone(value);
            }
        }

        if (value === '')
            props.ChangeRecipientPhone('');
    }

    const OnChangeCityCommand = (value: any) => {
        if (value.length === 0)
            return;

        (props.Wizard as WizardState).order.DeliveryType === DeliveryType.Nova && props.CalculateDeliveryDate(
            CalculateDeliveryDateBody(
                props.UserInfo.NovaPoshtaApiKey,
                (props.Wizard as WizardState).sendDate,
                value.value
            )
        )

        props.SelectSity(value);
        value && value.value && props.GetWarehouses(value.value);
    }

    const OnChangeCityInputCommand = (value: string) => {
        props.GetSities(value === '' ? 'ав' : value);
    }
    const OnChangeCityUkrInputCommand = (value: string) => {
        if (value.length > 3) {
            props.GetCityUkrPoshtaForAutocomplete(value === '' ? 'ав' : value);
        }
    }
    const OnChangeWarehouseCommand = (value: any) => {
        props.SelectWarehouse(value);

        /*
        if (value.entity.CategoryOfWarehouse === 'Postomat')
            OnSetPaymentMethodCommand(PaymentMethod.Prepaid)
        */
    }

    const OnUpdateDeliveryCommand = () => {
        let orderToUpdate = Object.assign({}, props.Wizard.order, {
            Client: (props.Wizard as WizardState).bufferClient,
            ClientId: (props.Wizard as WizardState).bufferClient.Id,
            RecipientName: !props.Wizard.isRecipient ? (props.Wizard as WizardState).bufferClient.FullName : props.Wizard.order.RecipientName,
            RecipientPhoneNumber: !props.Wizard.isRecipient ? (props.Wizard as WizardState).bufferClient.PhoneNumber : props.Wizard.order.RecipientPhoneNumber
        } as TempOrder);

        !props.Wizard.fetching && props.UpdateTempOrder(orderToUpdate);
    }

    const IsAfterpayAllowed = () => {
        /*
        if ((props.Wizard as WizardState).selectedWarehouse && (props.Wizard as WizardState).selectedWarehouse.entity && (props.Wizard as WizardState).selectedWarehouse.entity.CategoryOfWarehouse === 'Postomat')
            return false;
        */

        return true;
    }

    return (
        <div className="infoStepWrapper__container">
            <div className="orderProductsContent__container">

                {(props.Wizard.order && props.Wizard.order.OrderProducts.length > 0) && props.Wizard.order.OrderProducts.map((orderProduct, index) =>
                    <div className={`productList__item productListQTY__item`}
                        key={index}>
                        <div className={`orderProduct__modalHeader ${orderProduct.BaseProduct ? 'ddd' : ''}`}>
                            <div className="brandContainer">
                                <div className="qtyWContainer ">
                                    <div className="value">{orderProduct.Quantity}</div>
                                    <div className="label">Кол-во</div>
                                </div>

                                <div className="brand">{orderProduct.Product.Category.Name}</div>
                                <div className="model">{orderProduct.Product.SubCategory.Name}</div>

                                <div className="lexiconContainer">
                                    <div className="lexiconWrapper">
                                        <div className="lexiconItem">
                                            <div className="lexLabel">{'Наименование: '}</div>
                                            <div className="lexValue">
                                                <span>{orderProduct.Product.Lexicon.OfficialPartnerName}</span>
                                            </div>

                                            {orderProduct.Color &&
                                                <div className="lexValueColor">
                                                    <div className="colorBg" style={{ backgroundColor: orderProduct.Color.Code }} />
                                                    <div className="colorBgLabel">
                                                        ({orderProduct.Color.Name})
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {orderProduct.BaseProduct &&
                                            <div className="lexiconItem">
                                                <div className="lexLabel">{'Основа: '}</div>

                                                <div className="lexValue">
                                                    <span>{orderProduct.BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                </div>

                                                {orderProduct.BaseColor &&
                                                    <div className="lexValueColor">
                                                        <div className="colorBg" style={{ backgroundColor: orderProduct.BaseColor.Code }} />
                                                        <div className="colorBgLabel">
                                                            ({orderProduct.BaseColor.Name})
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        }

                                        <div className="lexiconItem ">
                                            <div className="lexLabel">Цена</div>
                                            <div className="lexValue pTotal__value">{orderProduct.PartnerPrice}/{orderProduct.Price}</div>
                                        </div>

                                        <div className="lexiconItem ">
                                            <div className="lexLabel">Суммарно</div>
                                            <div className="lexValue pTotal__value">{orderProduct.PartnerTotal}|{orderProduct.Total}</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="infoStepClientWrapper__content">
                <div className="devContainer">
                    <div className="boxWrapper__container">
                        <div className="boxHeader__container">
                            <div className="boxHeaderTop__container">
                                <div className="label">Клиент</div>
                            </div>
                            <div className="boxHeaderBottom__container">
                                <label className="bHBCheckbox">
                                    <input type="checkbox" checked={props.Wizard.isRecipient} onChange={OnChangeRecipientCommand} />
                                    <span>Получатель другое лицо</span>
                                </label>
                            </div>

                            <div className="boxHeaderIcon__container"></div>
                        </div>

                        <div className="boxContent__container">
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Телефон</div>
                                <div className={`prefPhoneItem defaultFormSelectControl__value ${!props.Wizard.newClientMode ? '' : 'hide'}`}>
                                    <Select
                                        ref={props.Wizard.clientSelectComponentRef}
                                        name="clients"
                                        onInputChange={OnClientPhoneNumberChangeCommand}
                                        options={props.Wizard.clients}
                                        onChange={OnSelectClientCommand}
                                        value={props.Wizard.selectedClient}
                                        onFocus={OnFocusClientSelectCommand}
                                        openMenuOnFocus={true}
                                        placeholder="Клиент"
                                    />
                                </div>
                                <div className={`prefPhoneItem defaultFormControl__value ${props.Wizard.newClientMode ? '' : 'hide'}`}>
                                    <input type="text"
                                        ref={props.Wizard.clientPhoneInputRef}
                                        value={props.Wizard.bufferClient.PhoneNumber}
                                        onChange={(event) => OnNewNumberChangeCommand(event.target.value)} />
                                </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Имя и фамилия</div>
                                <div className="defaultFormControl__value">
                                    <input type="text" value={props.Wizard.newClientMode ?
                                        props.Wizard.bufferClient.FullName :
                                        (props.Wizard.selectedClient ? props.Wizard.selectedClient.object.FullName : '')}
                                        onChange={(event) => OnChangeNewClientName(event.target.value)} />
                                </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Мейл</div>
                                <div className="defaultFormControl__value">
                                    <input type="text"
                                        value={props.Wizard.newClientMode ?
                                            props.Wizard.bufferClient.Email :
                                            (props.Wizard.selectedClient ? props.Wizard.selectedClient.object.Email : '')}
                                        onChange={(event) => OnChangeNewClientEmail(event.target.value)} />
                                </div>
                            </div>

                            {props.Wizard.isRecipient && <div className="defaultFormControl defaultCheckFormControl">
                                <div className="defaultFormControl__label"></div>
                                <div className="defaultCheckFormControl__value" />
                            </div>}

                            {props.Wizard.isRecipient && <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Телефон</div>
                                <div className="defaultFormControl__value prefPhoneItem">
                                    <input type="text"
                                        value={props.Wizard.order.RecipientPhoneNumber}
                                        onChange={(event) => OnChangeRecipientPhoneCommand(event.target.value)} />
                                </div>
                            </div>}

                            {
                                props.Wizard.isRecipient && <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Имя и фамилия</div>
                                    <div className="defaultFormControl__value">
                                        <input type="text"
                                            value={props.Wizard.order.RecipientName}
                                            onChange={(event) => OnChangeRecipientNameCommand(event.target.value)} />
                                    </div>
                                </div>
                            }
                            {/* 
                            <div className="defaultFormControl defaultFormControl__container">
                                <div className="defaultFormControl__wrapper">
                                    <div className={`defaultFormControl__save ${props.Wizard.fetching ? 'disabled' : ''}`} onClick={() => OnAddClientToOrderCommand()}>Сохранить</div>
                                </div>
                            </div> */}
                        </div>
                    </div>


                    <div className="boxWrapper__container">
                        <div className="boxHeader__container">
                            <div className="boxHeaderTop__container">
                                <div className="label">Метод доставки</div>
                            </div>
                            <div className="boxHeaderBottom__container">
                                <div className="buttonGroupDelPriceRow__container">
                                    <label className={`bHBCheckbox ${props.Wizard.order.DeliveryType === DeliveryType.Nova ? 'isSelected' : ''}`}
                                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Nova)}>
                                        <span>Нова почта</span>
                                    </label>
                                    <label className={`bHBCheckbox ${props.Wizard.order.DeliveryType === DeliveryType.Ukr ? 'isSelected' : ''}`}
                                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Ukr)}>
                                        <span>Укрпочта</span>
                                    </label>
                                    <label className={`bHBCheckbox ${props.Wizard.order.DeliveryType === DeliveryType.Self ? 'isSelected' : ''}`}
                                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Self)}>
                                        <span>Самовывоз</span>
                                    </label>
                                </div>
                            </div>

                            <div className="boxHeaderIcon__container price">

                            </div>
                        </div>

                        <div className="boxContent__container">
                            {props.Wizard.order.DeliveryType === DeliveryType.Nova &&
                                <div className="contentDeliveryType">
                                    <div className="defaultFormControl zIndexUp3">
                                        <div className="defaultFormControl__label">Город</div>
                                        <div className="defaultFormSelectControl__value">
                                            <Select
                                                onInputChange={OnChangeCityInputCommand}
                                                options={props.Wizard.cities}
                                                onChange={OnChangeCityCommand}
                                                value={props.Wizard.selectedCity}
                                                onFocus={() => { }}
                                                openMenuOnFocus={true}
                                                placeholder="Город"
                                            />
                                        </div>
                                    </div>
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Отделение</div>
                                        <div className="defaultFormSelectControl__value">
                                            <Select
                                                options={props.Wizard.warehouses}
                                                onChange={OnChangeWarehouseCommand}
                                                value={props.Wizard.selectedWarehouse}
                                                onFocus={() => { }}
                                                openMenuOnFocus={true}
                                                placeholder="Отделение"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {props.Wizard.order.DeliveryType === DeliveryType.Ukr &&
                                <div className="contentDeliveryType">
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Область, город</div>
                                        <div className="defaultFormControl__value">
                                            <input type="text"
                                                value={props.Wizard.order.UkrPoshtaCityName ? props.Wizard.order.UkrPoshtaCityName : ''}
                                                onChange={(event) => props.ChangeUkrCity(event.target.value)} />
                                        </div>
                                    </div>

                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Улица</div>
                                        <div className="defaultFormControl__value">
                                            <input type="text"
                                                value={props.Wizard.order.UkrPoshtaStreetName ? props.Wizard.order.UkrPoshtaStreetName : ''}
                                                onChange={(event) => props.ChangeUkrStreet(event.target.value)} />
                                        </div>
                                    </div>

                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Дом</div>
                                        <div className="defaultFormControl__value">
                                            <input type="text"
                                                value={props.Wizard.order.UkrPoshtaBuildingNumber ? props.Wizard.order.UkrPoshtaBuildingNumber : ''}
                                                onChange={(event) => props.ChangeUkrBuilding(event.target.value)} />
                                        </div>
                                    </div>

                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Квартира</div>
                                        <div className="defaultFormControl__value">
                                            <input type="text"
                                                value={props.Wizard.order.UkrPoshtaFlatNumber ? props.Wizard.order.UkrPoshtaFlatNumber : ''}
                                                onChange={(event) => props.ChangeUkrFlat(event.target.value)} />
                                        </div>
                                    </div>

                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Индекс</div>
                                        <div className="defaultFormControl__value">
                                            <input type="text"
                                                value={props.Wizard.order.UkrPoshtaZipNumber ? props.Wizard.order.UkrPoshtaZipNumber : ''}
                                                onChange={(event) => props.ChangeUkrZip(event.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className="paymentType__PaymentMethod__container">
                                <div>
                                    <div className={`bHBCheckbox ${props.Wizard.order.PaymentMethod === PaymentMethod.Prepaid ? 'isSelected' : ''}`}
                                        onClick={() => OnSetPaymentMethodCommand(PaymentMethod.Prepaid)}>
                                        <span>Предоплата</span>
                                    </div>
                                    {(props.Wizard.order as Order).DeliveryType === DeliveryType.Nova || (props.Wizard.order as Order).DeliveryType === DeliveryType.Ukr && IsAfterpayAllowed() ?
                                        <div className={`bHBCheckbox ${props.Wizard.order.PaymentMethod === PaymentMethod.Afterpaid ? 'isSelected' : ''}`}
                                            onClick={() => OnSetPaymentMethodCommand(PaymentMethod.Afterpaid)}>
                                            <span>При получении</span>
                                        </div>
                                        : null}
                                </div>
                            </div>

                            <div className="defaultFormControl defaultFormControl__container">
                                <div className="defaultFormControl__wrapper">
                                    <div className={`defaultFormControl__save ${props.Wizard.fetching ? 'disabled' : ''}`} onClick={() => OnUpdateDeliveryCommand()}>Сохранить</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="labelsRowContainer__content">
                        {/* 
                        {(props.Wizard as WizardState).deliveryPayment ?
                            <div className="labelCol">
                                <div className="label">Ціна доставки</div>
                                <div className="value">{(props.Wizard as WizardState).deliveryPayment.DeliveryPrice}</div>
                            </div>
                            : null
                        } */}

                        {(props.Wizard as WizardState).sendDate ?
                            <div className="labelCol">
                                <div className="label">Дата отправки</div>
                                <div className="value">{moment((props.Wizard as WizardState).sendDate).format('DD.MM.YYYY')}</div>
                            </div> :
                            null
                        }

                        {(props.Wizard as WizardState).deliveryDate ?
                            <div className="labelCol">
                                <div className="label">Дата доставки</div>
                                <div className="value">{moment((props.Wizard as WizardState).deliveryDate).format('DD.MM.YYYY')}</div>
                            </div> :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoStep);
