import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import { Form } from "react-redux-form";
import { TextBoxWithLabel } from "../../../../shared/form.controls/textbox.with.label";
import { Validator, MaxLength } from "../../../../../helpers/validator.helper";
import { UpdatePartnerPasswordForce } from "../../../../../actions/partner/partner.info.actions";
import { Observable } from "rxjs";

const mapStateToProps = (state) => {
    return {
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        UpdatePassword: UpdatePartnerPasswordForce
    }, dispatch);
};

const UserPaswordChangeForm = (props: any) => {

    const IsPasswordValid = (val) => (Observable as any).of(val).map((val) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!_-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!_-]{8,}$/g.test(val)
    ).toPromise();


    const IsPasswordExist = (val) => {
        return (Observable as any).of(val).map((val) => {
            return (!val || val === '') ? true : false
        }
        ).toPromise();
    }

    return (
        <Form
            className="default_form_component shadow_controls"
            validateOn='change'
            model="partnerUserForm"
            onSubmit={(partnerUserForm: any) => props.UpdatePassword(partnerUserForm.UserName, partnerUserForm.Password)}>
            <div className="form_row_component">
                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val),
                        IsMaxLength: MaxLength(20)
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        IsMaxLength: 'TooManyCharacters',
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Логін'
                    IsRequired={true}
                    Model='.UserName'
                    Readonly />

                <TextBoxWithLabel
                    AsyncValidators={{
                        isRequired: (val: string, done: any) => IsPasswordExist(val).then(res => done(res)),
                        isPasswordMatchToPattern: (val: string, done: any) =>
                            IsPasswordValid(val).then(res => done(res)),
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        isPasswordMatchToPattern: 'PasswordMatchToPattern'
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    AsyncValidateOn='change'
                    LabelMessage='Пароль'
                    IsRequired={false}
                    Model='.Password' />

            </div>
            <div className="defaultFormControl">
                <button type="submit" className="defaultFormButton" >
                    Изменить
                </button>
            </div>
        </ Form>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserPaswordChangeForm)