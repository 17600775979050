import * as React from "react";
import { connect } from "react-redux";
import { IdentityRoleEnum } from "../../../../helpers/identity.roles";
import PermissionChecker from '../../../shared/permission.checker.component';

export const Order = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">

                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов (все статусы)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OrderQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Нет в наличии, не согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockNotWaitingQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockWaitingQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "В ожидании"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConfirmedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.CanceledQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SandedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "В пути"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "В пути"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OnTheWayQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.DeliveredQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Получен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReceivedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IgnoredQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество созданных заказов со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общее количество созданных заказов, которые на момент запроса имеют статус "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RefusedQuantity}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Количество созданных заказов, по которым были списаны средства с баланса и не возвращены
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общее количество заказов, по которым на момент запроса были списаны средства и не возвращены
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.WithdrawalQuantity}
                                    </span>
                                </div>
                            </div>
                        </PermissionChecker>

                        <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name"></div>
                            <div className="grid_cell"></div>
                            <div className="grid_cell cell__GRID_CONT"></div>
                        </div>

                        {/*  */}
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера (все статусы)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Нет в наличии, не согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockNotWaitingPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockWaitingPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "В ожидании"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Оформлен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConfirmedPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.CanceledPartnerTotal}
                                </span>
                            </div>
                        </div>


                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SandedPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "В пути"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "В пути"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OnTheWayPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.DeliveredPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Получен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReceivedPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IgnoredPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров в заказах по ценовому уровню партнера со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, которые на момент запроса имеют статус "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RefusedPartnerTotal}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров созданных заказов по ценовому уровню партнера, по которым были списаны средства с баланса и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая стоимость товаров по ценовому уровню партнера в созданных заказах, по которым на момент запроса были списаны средства и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WithdrawalPartnerTotal}
                                </span>
                            </div>
                        </div>
</PermissionChecker>
                        <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name"></div>
                            <div className="grid_cell"></div>
                            <div className="grid_cell cell__GRID_CONT"></div>
                        </div>

                        {/*  */}
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах (все статусы)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.Total}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Нет в наличии, не согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockNotWaitingTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockWaitingTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "В ожидании"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Оформлен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConfirmedTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.CanceledTotal}
                                </span>
                            </div>
                        </div>


                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SandedTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "В пути"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "В пути"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OnTheWayTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.DeliveredTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Получен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReceivedTotal}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров в заказах со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), которые на момент запроса имеют статус "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RefusedTotal}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Клиентская стоимость товаров созданных заказов, по которым были списаны средства с баланса и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Общая клиентская стоимость товаров в созданных заказах (стоимость, которую указывает партнер при оформлении заказа), по которым на момент запроса были списаны средства и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WithdrawalTotal}
                                </span>
                            </div>
                        </div>
                        </PermissionChecker>

                        <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name"></div>
                            <div className="grid_cell"></div>
                            <div className="grid_cell cell__GRID_CONT"></div>
                        </div>

                        {/*  */}
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости (все статусы)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Нет в наличии, не согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockNotWaitingPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockWaitingPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "В ожидании"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConfirmedPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.CanceledPartnerAverage}
                                </span>
                            </div>
                        </div>


                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SandedPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "В пути"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "В пути"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OnTheWayPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.DeliveredPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Получен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReceivedPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IgnoredPartnerAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, которые на момент запроса имеют статус "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RefusedPartnerAverage}
                                </span>
                            </div>
                        </div>
                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по партнерской стоимости, по которым были списаны средства с баланса и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по партнерской стоимости, по которым на момент запроса были списаны средства и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WithdrawalPartnerAverage}
                                </span>
                            </div>
                        </div>
                        </PermissionChecker>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row noStyles">
                            <div className="grid_cell cell__GRID_name"></div>
                            <div className="grid_cell"></div>
                            <div className="grid_cell cell__GRID_CONT"></div>
                        </div>

                        {/*  */}
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости (все статусы)
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.Average}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Нет в наличии, не согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockNotWaitingAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NotInStockWaitingAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "В ожидании"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Оформлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Оформлен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ConfirmedAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Отменен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.CanceledAverage}
                                </span>
                            </div>
                        </div>


                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SandedAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "В пути"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "В пути"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OnTheWayAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Доставлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.DeliveredAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Получен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReceivedAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IgnoredAverage}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, которые на момент запроса имеют статус "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RefusedAverage}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Средний чек созданных заказов по клиентской стоимости, по которым были списаны средства с баланса и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Средний чек в созданных заказах по клиентской стоимости, по которым на момент запроса были списаны средства и не возвращены
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WithdrawalAverage}
                                </span>
                            </div>
                        </div>
                        </PermissionChecker>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row noStyles">
                                <div className="grid_cell cell__GRID_name"></div>
                                <div className="grid_cell"></div>
                                <div className="grid_cell cell__GRID_CONT"></div>
                            </div>

                            {/*  */}
                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Прибыль (партнера) по товарам созданных заказов (все статусы)
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, независимо от статуса
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.PartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Нет в наличии, не согласен ждать"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.NotInStockNotWaitingPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Нет в наличии, согласен ждать"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Нет в наличии, согласен ждать"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.NotInStockWaitingPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "В ожидании"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "В ожидании"
                                 </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.WaitingPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Оформлен"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Нет в наличии, не согласен ждать"
                                 </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.ConfirmedPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Отменен"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Отменен"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.CanceledPartnerProfit}
                                    </span>
                                </div>
                            </div>


                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Отправлен"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Отправлен"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.SandedPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "В пути"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "В пути"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.OnTheWayPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Доставлен"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Доставлен"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.DeliveredPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Получен"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Получен"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.ReceivedPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Не забранный"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Не забранный"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.IgnoredPartnerProfit}
                                    </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                    <span className="cell_content__GC">
                                        Партнерський прибыль от товаров созданных заказов со статусом "Отказ"
                                </span>
                                </div>

                                <div className="grid_cell">
                                    <span className="cell_content__GC">
                                        Общая прибыль (клиентская цена-партнерская цена) в созданных заказах, которые на момент запроса имеют статус "Отказ"
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                    <span className="cell_content__GC">
                                        {model.RefusedPartnerProfit}
                                    </span>
                                </div>
                            </div>
                        </PermissionChecker>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(Order);
