import { Order } from "../../business.entities/order";
import { Client } from "../../business.entities/client";
import { UkrRegionModel } from "../../business.entities/UkrPoshtaRegion";
import { UkrAreaModel } from "../../business.entities/UkrAreaModel";
import { UkrPostOfficeModel } from "../../business.entities/UkrPostOfficeModel";
import * as React from "react";
import { DeliveryType } from "../../helpers/enums/delivery.type";
import { PrintStatusEnum, GetPrintStatusName } from "../../helpers/enums/print.status.enum";
import { PrintVerificationStatusEnum } from "../../helpers/enums/print.verification.status.enum";
import { ProductionStatusEnum, GetProductionStatusName } from "../../helpers/enums/production.status";
import Product from "../../business.entities/product";
import { OrderProduct } from "../../business.entities/order.product";
import { ComponentClass } from "react";
import { OrderProductStep as OrderProductStepEnum } from '../../helpers/enums/order.product.steps';
import { Color } from "../../business.entities/color";
import { GetNameForOrderStatus, OrderStatusEnum } from "../../helpers/enums/order.status.enum";
import { GetLaserStatusName, LaserStatusEnum } from "../../helpers/enums/laser.status.enum";
import { UkrCityModel } from "../../business.entities/UkrCityModel";

export class ProductUpdateState {
    currentOrderProducts: OrderProduct[];
    selectedCategory: any;
    selectedSubCategory: any;
    categoryList: any[];
    subCategoryList: any[];
    productList: Product[];
    categorySelectRef: any;
    subcategorySelectRef: any;
    currentProduct: Product;
    currentProductColor: Color;
    currentOrderProduct: OrderProduct;
    currentExpressPrintOrderProduct: OrderProduct;
    currentExtraPrintOrderProduct: OrderProduct;
    additionalServiceProducts: Product[];
    expressPrintProducts: Product[];
    extraPrintProducts: Product[];
    selectedExpressPrintProduct: Product;
    selectedAdditionalPrintProduct: Product;
    orderProductSubStep: ComponentClass;
    orderProductStepPattern: any[];
    currentStepEnum: OrderProductStepEnum;
    baseProductList: Product[];
    currentBaseProduct: Product;
    currentBaseColor: Color;
    productSelectionMode: boolean;
    orderProductIndex: number;
    serverActionInProcess: boolean;
}

export const defaultProductUpdateState = {
    currentOrderProducts: [],
    selectedCategory: null,
    selectedSubCategory: null,
    categoryList: [],
    subCategoryList: [],
    productList: [],
    categorySelectRef: React.createRef<HTMLDivElement>(),
    subcategorySelectRef: React.createRef<HTMLDivElement>(),
    currentProduct: null,
    currentProductColor: null,
    currentOrderProduct: new OrderProduct(),
    currentExpressPrintOrderProduct: new OrderProduct(),
    currentExtraPrintOrderProduct: new OrderProduct(),
    additionalServiceProducts: [],
    expressPrintProducts: [],
    extraPrintProducts: [],
    selectedExpressPrintProduct: null,
    selectedAdditionalPrintProduct: null,
    orderProductSubStep: null,
    orderProductStepPattern: [],
    currentStepEnum: null,
    baseProductList: [],
    currentBaseProduct: null,
    currentBaseColor: null,
    productSelectionMode: true,
    orderProductIndex: null,
    serverActionInProcess: false
}


export class OrderCardState {
    order: Order;
    fetchInProgress: boolean;
    bufferClient: Client;
    newClientMode: boolean;
    clients: Client[];
    clientSelectComponentRef: any;
    clientPhoneInputRef: any;
    selectedClient: any;
    isRecipient: boolean;
    recipientName: string;
    recipientPhone: string;
    UkrRegionModel: UkrRegionModel[];
    UkrPostOfficeModel: UkrPostOfficeModel[];
    UkrAreaModel: UkrAreaModel[];
    UkrCityModel: UkrCityModel[];
    selectedDeliveryType: DeliveryType;
    cities: any;
    warehouses: any;
    selectedCity: any;
    selectedWarehouse: any;
    selectedUkrPoshtaCityName: string;
    selectedUkrPoshtaStreetName: string;
    selectedUkrPoshtaBuildingNumber: string;
    selectedUkrPoshtaFlatNumber: string;
    selectedUkrPoshtaZipNumber: string;

    selectedOrderProductId: number;

    selectedUkrPoshtaRegionId: number;
    districtAreaId: number;
    districtCityId: number;
    printStatusList: any[];
    printVerifyStatusList: any[];
    reuseStatusList: any[];
    productionStatusList: any[];
    laserStatusList: any[];

    productUpdateMode: boolean;

    productUpdateSubState: ProductUpdateState;

    finishStatus: any[];
    isValidated: boolean;
    cantReuseQuantity: number;

    isFailProcessing: boolean;
    auditEntities: any[];
}

export const defaultOrderCardState: OrderCardState = {
    order: null,
    fetchInProgress: false,
    bufferClient: new Client(),
    newClientMode: false,
    clients: [],
    clientSelectComponentRef: React.createRef<HTMLDivElement>(),
    clientPhoneInputRef: React.createRef<HTMLDivElement>(),
    selectedClient: null,
    isRecipient: false,
    recipientName: '',
    recipientPhone: '',
    UkrRegionModel: [],
    UkrAreaModel: [],
    UkrCityModel: [],
    UkrPostOfficeModel: [],
    districtAreaId: null,
    selectedUkrPoshtaRegionId: null,
    selectedDeliveryType: null,
    districtCityId: null,
    cities: [],
    warehouses: [],
    selectedCity: null,
    selectedWarehouse: null,
    selectedUkrPoshtaCityName: '',
    selectedUkrPoshtaStreetName: '',
    selectedUkrPoshtaBuildingNumber: '',
    selectedUkrPoshtaFlatNumber: '',
    selectedUkrPoshtaZipNumber: '',
    selectedOrderProductId: null,
    printStatusList: [
        {
            value: PrintStatusEnum.ReadyForPrinting,
            label: GetPrintStatusName(PrintStatusEnum.ReadyForPrinting)
        },
        {
            value: PrintStatusEnum.PictureIssue,
            label: GetPrintStatusName(PrintStatusEnum.PictureIssue)
        },
        {
            value: PrintStatusEnum.FailedByPrinter,
            label: GetPrintStatusName(PrintStatusEnum.FailedByPrinter)
        },
        {
            value: PrintStatusEnum.FailedNotByPrinter,
            label: GetPrintStatusName(PrintStatusEnum.FailedNotByPrinter)
        },
        {
            value: PrintStatusEnum.Finished,
            label: GetPrintStatusName(PrintStatusEnum.Finished)
        }
    ],
    printVerifyStatusList: [
        {
            value: undefined,
            label: 'Выберите статус'
        },
        {
            value: PrintVerificationStatusEnum.Failed,
            label: 'Не пройдена'
        },
        {
            value: PrintVerificationStatusEnum.Passed,
            label: 'Пройдена'
        }
    ],
    reuseStatusList: [
        {
            value: undefined,
            label: 'Выберите опцию'
        },
        {
            value: false,
            label: 'Не использовать'
        },
        {
            value: true,
            label: 'Использовать'
        }
    ],
    productionStatusList: [
        {
            value: ProductionStatusEnum.WaitingForAssembling,
            label: GetProductionStatusName(ProductionStatusEnum.WaitingForAssembling),
            isDisabled: true
        },
        {
            value: ProductionStatusEnum.WaitingForProduction,
            label: GetProductionStatusName(ProductionStatusEnum.WaitingForProduction)
        },
        {
            value: ProductionStatusEnum.Producted,
            label: GetProductionStatusName(ProductionStatusEnum.Producted)
        },
    ],
    laserStatusList: [
        {
            value: LaserStatusEnum.WaitingForAssembling,
            label: GetLaserStatusName(LaserStatusEnum.WaitingForAssembling),
            isDisabled: true
        },
        {
            value: LaserStatusEnum.WaitingForProduction,
            label: GetLaserStatusName(LaserStatusEnum.WaitingForProduction)
        },
        {
            value: LaserStatusEnum.Producted,
            label: GetLaserStatusName(LaserStatusEnum.Producted)
        },
    ],
    productUpdateMode: false,
    productUpdateSubState: defaultProductUpdateState,
    finishStatus: [
        {
            name: GetNameForOrderStatus(OrderStatusEnum.Confirmed),
            isSelected: false,
            id: OrderStatusEnum.Confirmed
        },
        {
            name: GetNameForOrderStatus(OrderStatusEnum.Waiting),
            isSelected: false,
            id: OrderStatusEnum.Waiting
        },
        {
            name: GetNameForOrderStatus(OrderStatusEnum.NotAvailableWaiting),
            isSelected: false,
            id: OrderStatusEnum.NotAvailableWaiting
        },
        {
            name: GetNameForOrderStatus(OrderStatusEnum.NotAvailableNotWaiting),
            isSelected: false,
            id: OrderStatusEnum.NotAvailableNotWaiting
        },
        {
            name: GetNameForOrderStatus(OrderStatusEnum.Cancel),
            isSelected: false,
            id: OrderStatusEnum.Cancel
        },
        {
            name: GetNameForOrderStatus(OrderStatusEnum.Recived),
            isSelected: false,
            id: OrderStatusEnum.Recived
        },
    ],
    isValidated: false,
    cantReuseQuantity: 1,
    isFailProcessing: false,
    auditEntities: [],
};

