import * as ActionCreators from '../../../../actions/partner/partner.grid.actions'
import { error } from "react-notification-system-redux";
import { bindActionCreators } from "redux";
import * as routerActions from "react-router-redux";
import { connect } from "react-redux";
import * as React from "react";
import Select from 'react-select';
import { SelectModel } from "../../../../helpers/select.model";
import { PartnerFilter } from "../../../../business.entities/partner.filter";
import { Loader } from "../../../shared/loader";
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import SelectForPaginate from 'rc-select';
import { PriceLevel, GetPricelevelName } from '../../../../helpers/enums/price.level.enum';
import Moment from 'react-moment';
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";
import { IdentityRoleEnum } from '../../../../helpers/identity.roles';
import PermissionChecker from "../../../shared/permission.checker.component";
import Partner from '../../../../business.entities/partner';
import { warning } from "react-notification-system-redux";
import { GetPartnerPaymentMethodName } from '../../../../helpers/enums/partner.payment.method.enum';

export namespace PartnersComponent {
    export interface IProps {

        ErrorNotification: any;
        GetUnauthorized: any;
        FilterChange: any;
        PageChange: (take: number, page: number, filter: any) => {};
        WorkersPageChange: (take: number, page: number) => {};
        Push: any;
        UnmountComponent: () => any;

        partnerGrid: any;
        isPageChange: boolean;
        fetching: boolean;
    }

    export interface IState {
        currentPage: number;
        currentPageSize: number;
        productTypes: SelectModel[];
        saleMethod: SelectModel[];
        partnerPaymentMethod: SelectModel[];
        inputValue: string;
        isRegisterd: boolean;
        isWorkerMode: boolean;
        priceLevel: any;
        PriceLevels: any[];
        canceled: boolean;
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ErrorNotification: error,
        Push: routerActions.push,
        FilterChange: ActionCreators.FilterChange,
        PageChange: ActionCreators.GetPartnersFilteredPaginated,
        WorkersPageChange: ActionCreators.GetWorkersPaginated,
        UnmountComponent: ActionCreators.UnmountPartnerGrid,
        CancelPartnerMode: ActionCreators.CancelPartnerMode,
        CancelPartner: ActionCreators.CancelPartner,
        warning
    }, dispatch);
};

class PartnerAccountsComponent extends React.Component<PartnersComponent.IProps, PartnersComponent.IState>{
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            currentPageSize: 25,
            productTypes: [],
            saleMethod: [],
            partnerPaymentMethod: [],
            inputValue: '',
            isRegisterd: false,
            isWorkerMode: false,
            canceled: false,
            priceLevel: null,
            PriceLevels: [
                { label: GetPricelevelName(PriceLevel.S0), value: PriceLevel.S0 },
                { label: GetPricelevelName(PriceLevel.S1), value: PriceLevel.S1 },
                { label: GetPricelevelName(PriceLevel.S2), value: PriceLevel.S2 },
                { label: GetPricelevelName(PriceLevel.S3), value: PriceLevel.S3 },
                { label: GetPricelevelName(PriceLevel.S4), value: PriceLevel.S4 },
                { label: GetPricelevelName(PriceLevel.S5), value: PriceLevel.S5 },
                { label: GetPricelevelName(PriceLevel.S6), value: PriceLevel.S6 },
                { label: GetPricelevelName(PriceLevel.P1), value: PriceLevel.P1 }
            ]
        }

        let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.priceLevel)

        this.props.PageChange(this.state.currentPageSize, this.state.currentPage, filter);

        this.SelectPartner = this.SelectPartner.bind(this);
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    public OnChangePageSizeCommand(current, pageSize): void {
        console.log(this.props.partnerGrid.isPageChange);
        if (this.props.partnerGrid.isPageChange === false) {
            this.setState(() => ({
                currentPage: 1,
                currentPageSize: pageSize
            }));

            let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, this.state.canceled);
            this.state.isWorkerMode ? this.props.WorkersPageChange(pageSize, current) : this.props.PageChange(pageSize, current, filter);
            (this.refs.paginatorRef as any).setState({ current: 1 });
        } else
            return;
    }

    public OnChangePageCommand(current, pageSize): void {
        if (this.props.partnerGrid.isPageChange === false) {
            this.setState(() => ({
                currentPage: current
            }));

            let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, this.state.canceled);
            this.state.isWorkerMode ? this.props.WorkersPageChange(pageSize, current) : this.props.PageChange(pageSize, current, filter);
        } else
            return;
    }

    public OnChangeCanceledCommand(): void {
        if (this.props.partnerGrid.isPageChange === false) {
            this.setState(() => ({
                currentPage: 1,
                canceled: !this.state.canceled
            }));

            let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, !this.state.canceled);
            this.props.PageChange(this.state.currentPageSize, 1, filter);
        } else
            return;
    }

    private SelectPartner(netid: string) {
        this.props.Push(`/partners/edit/${netid}`)
    }

    private SelectSaleMethod(newValue: any) {
        this.setState({ saleMethod: [...this.state.saleMethod, newValue], currentPage: 1 });
        let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, this.state.canceled);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private SelectProductTypes(newValue: any) {
        this.setState({ productTypes: [...this.state.productTypes, newValue], currentPage: 1 });
        let filter = this.SetDataToFilter(
            [...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel : null, this.state.canceled);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private SelectPartnerPaymentMethod(newValue: any) {
        this.setState({ partnerPaymentMethod: [...this.state.partnerPaymentMethod, newValue], currentPage: 1 });
        let filter = this.SetDataToFilter([...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod], this.state.inputValue, this.state.isRegisterd, this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, this.state.canceled);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private OnChangeSearchCommand(value) {
        this.setState({ inputValue: value, currentPage: 1 })
        let filter = this.SetDataToFilter(
            [...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod],
            value,
            this.state.isRegisterd,
            this.state.isRegisterd && this.state.priceLevel ? this.state.priceLevel.value : null, this.state.canceled);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private OnResetSearchCommand() {
        if (!this.state.isWorkerMode) {
            let filter = this.SetDataToFilter([], '', this.state.isRegisterd, null, this.state.canceled);
            this.props.PageChange(this.state.currentPageSize, 1, filter);
            this.setState({ inputValue: '', currentPage: 1, saleMethod: [], productTypes: [], partnerPaymentMethod: [] })
        } else {
            this.setState({ currentPage: 1, isRegisterd: !this.state.isRegisterd, isWorkerMode: true, priceLevel: null });
            this.props.WorkersPageChange(this.state.currentPageSize, 1);
        }

        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private partnerTypeSwitch() {
        this.setState({ currentPage: 1, isRegisterd: !this.state.isRegisterd, isWorkerMode: false, priceLevel: null, canceled: false });
        let filter = this.SetDataToFilter(
            [...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod],
            this.state.inputValue, !this.state.isRegisterd, null);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private OnSelectPriceLevelCommand(value: any): void {
        this.setState({ currentPage: 1, isRegisterd: true, isWorkerMode: false, priceLevel: value });
        let filter = this.SetDataToFilter(
            [...this.state.productTypes, ...this.state.saleMethod, ...this.state.partnerPaymentMethod],
            this.state.inputValue, true, value ? value.value : null);
        this.props.PageChange(this.state.currentPageSize, 1, filter);
        (this.refs.paginatorRef as any).setState({ current: 1 });
    }

    private SetDataToFilter(value: SelectModel[], inputValue: string, isRegistered: boolean, priceLevel: any, canceled?: any) {
        let filter: PartnerFilter = new PartnerFilter();

        filter.PriceLevel = priceLevel;

        filter.Value = inputValue;

        filter.IsRegistered = isRegistered;

        filter.Canceled = canceled;

        value.forEach((element: SelectModel) => {
            switch (element.value) {
                case "OnlineShop":
                    filter.OnlineShop = true;
                    break;
                case "LocalShop":
                    filter.LocalShop = true;
                    break;
                case "Instagram":
                    filter.Instagram = true;
                    break;
                case "Vkontakte":
                    filter.Vkontakte = true;
                    break;
                case "Other":
                    filter.Other = true;
                    break;
                case "OfflineShop":
                    filter.OfflineShop = true;
                    break;
                case "PrintSiliconeCases":
                    filter.PrintSiliconeCases = true;
                    break;
                case "LeatherCase":
                    filter.LeatherCase = true;
                    break;
                case "SiliconeCases":
                    filter.SiliconeCases = true;
                    break;
                case "GlassAndFilm":
                    filter.GlassAndFilm = true;
                    break;
                case "EarlyPayment":
                    filter.EarlyPayment = true;
                    break;
                case "AfterPayment":
                    filter.AfterPayment = true;
                    break;
            }
        });

        return filter;
    }

    public OnWorkersModeEnable() {
        this.setState({ currentPage: 1, isRegisterd: !this.state.isRegisterd, isWorkerMode: true, priceLevel: null, canceled: false });

        this.props.WorkersPageChange(this.state.currentPageSize, 1);
    }

    public OnRemovePartner(event, partner: Partner): void {
        event.stopPropagation();
        if (!this.props.partnerGrid.cancelMode) {

            this.props.CancelPartnerMode(true);

            this.props.warning({
                title: 'ВНИМАНИЕ!',
                message: `При подтверждении заявка ${partner.ContactName} будет отменена`,
                position: 'br',
                autoDismiss: 0,
                onRemove: () => { this.props.CancelPartnerMode(false) },
                action: {
                    label: 'Сохранить',
                    callback: () => { this.props.CancelPartner(Object.assign({}, partner, { Deleted: true })) }
                }
            });
        }
    }

    render() {
        const saleMethodOption = [
            { value: "OnlineShop", label: "Интернет-магазин" },
            { value: "LocalShop", label: "Локальный магазин" },
            { value: "Instagram", label: "Инстаграм" },
            { value: "Vkontakte", label: "Вконтакте" },
            { value: "Other", label: "Другое" },
            { value: "OfflineShop", label: "Офлайн-магазин со стендом"}
        ];

        const productTypeOption = [
            { value: "PrintSiliconeCases", label: "Силиконовые чехлы с печатью" },
            { value: "LeatherCase", label: "Кожанные чехлы" },
            { value: "SiliconeCases", label: "Силиконовые чехлы" },
            { value: "GlassAndFilm", label: "Стекло и пленки" }
        ];

        const partnerPaymentMethodOption = [
            { value: "EarlyPayment", label: "Предоплата" },
            { value: "AfterPayment", label: "Послеоплата" }
        ];

        return (
            <div className="master_content_component part__contentContainer partnersPage__container">
                {/* <div className="left_menuCONTROL_component isMOB">
                    <div className="cName__CONTENT hide">Развернуть меню</div>
                    <div className="cName__CONTENT ">Свернуть меню</div>
                </div> */}

                <LeftMenuMobileControl />

                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        <div className="page_controls">
                            {!this.state.isWorkerMode ?

                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">
                                        Поиск
                                    </div>
                                    <div className="defaultFormControl__value notPadd">
                                        <input type="text" value={this.state.inputValue}
                                            placeholder="Поиск"
                                            onChange={(event) => this.OnChangeSearchCommand(event.target.value)} />
                                    </div>

                                </div> : null}

                            {!this.state.isWorkerMode ?
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">
                                        Путь сбыта
                                    </div>
                                    <div className="selectWrapper__container notPadd">
                                        <Select
                                            name="sale_method"
                                            multi={true}
                                            options={saleMethodOption}
                                            onChange={this.SelectSaleMethod.bind(this)}
                                            value={this.state.saleMethod}
                                            placeholder="Путь сбыта"
                                        />
                                    </div>
                                </div> : null}

                            {!this.state.isWorkerMode ?
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">
                                        Тип продукта
                            </div>
                                    <div className="selectWrapper__container notPadd">
                                        <Select
                                            name="product_types"
                                            multi={true}
                                            options={productTypeOption}
                                            onChange={this.SelectProductTypes.bind(this)}
                                            value={this.state.productTypes}
                                            placeholder="Тип продукта"
                                        />
                                    </div>
                                </div>
                                : null}

                            {!this.state.isWorkerMode ?
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">
                                        Метод рассчета
                                    </div>
                                    <div className="selectWrapper__container notPadd">
                                        <Select
                                            name="partner_payment_method"
                                            multi={true}
                                            options={partnerPaymentMethodOption}
                                            onChange={this.SelectPartnerPaymentMethod.bind(this)}
                                            value={this.state.partnerPaymentMethod}
                                            placeholder="Метод рассчета"
                                        />
                                    </div>
                                </div>
                            : null}


                            {!this.state.isWorkerMode && this.state.isRegisterd ?
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">
                                        Ценовой уровень
                            </div>
                                    <div className="selectWrapper__container notPadd">
                                        <Select
                                            name="priceLevels"
                                            isClearable
                                            clearValue={() => this.OnSelectPriceLevelCommand(null)}
                                            options={this.state.PriceLevels}
                                            onChange={this.OnSelectPriceLevelCommand.bind(this)}
                                            value={this.state.priceLevel}
                                            placeholder="Ценовой уровень"
                                        />
                                    </div>
                                </div>
                                : null}

                            <div className="defaultFormControl">
                                <div className={`defaultFormButton ${!this.state.isRegisterd && !this.state.isWorkerMode ? 'isSelected' : ''}`}
                                    onClick={() => this.partnerTypeSwitch()}>
                                    Новые партнеры
                            </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className={`defaultFormButton ${this.state.isRegisterd && !this.state.isWorkerMode ? 'isSelected' : ''}`}
                                    onClick={() => this.partnerTypeSwitch()}>
                                    Все партнеры
                            </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className={`defaultFormButton ${this.state.isWorkerMode ? 'isSelected' : ''}`}
                                    onClick={() => this.OnWorkersModeEnable()}>
                                    Персонал
                            </div>
                            </div>

                            <div className="defaultFormControl">
                                <div className="defaultFormButton" onClick={(event) => this.OnResetSearchCommand()}>
                                    Сброс
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">{this.state.isWorkerMode ? 'Персонал' : 'Партнёры'}</span>
                        <span className="title_description">Обзор</span>
                    </div>

                    {
                        !this.state.isRegisterd && !this.state.isWorkerMode ?
                            <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>
                                <label className="checkMid" >
                                    <input type="checkbox"
                                        name="available"
                                        defaultChecked={this.state.canceled}
                                        onClick={() => {
                                            !this.props.partnerGrid.fetching && this.OnChangeCanceledCommand()
                                        }} />
                                    <div className="checkControl"></div>
                                    Отказы и дубли
                            </label>
                            </PermissionChecker> :
                            null
                    }

                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">

                                        <div className="grid_cell">
                                            <span className="cell_content">Организация</span>
                                        </div>

                                        {!this.state.isRegisterd ?
                                            <div className="grid_cell">
                                                <span className="cell_content">Дата</span>
                                            </div> : null
                                        }

                                        <div className="grid_cell">
                                            <span className="cell_content">Ссылка на сайт или соцсеть</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Контактное лицо</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Телефон</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Email</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Город</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Баланс</span>
                                        </div>
                                        {
                                            !this.state.isWorkerMode && this.state.isRegisterd ?
                                                <div className="grid_cell">
                                                    <span className="cell_content">Уровень</span>
                                                </div> : null
                                        }
                                        <div className="grid_cell">
                                            <span className="cell_content">Метод рассчета</span>
                                        </div>

                                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss]} simple>
                                        { !this.state.canceled && !this.state.isRegisterd && !this.state.isWorkerMode ?
                                            <div className="grid_cell" >
                                                <span className="cell_content">Отменить</span>
                                            </div>
                                            : null
                                        }
                                        </PermissionChecker>   
                                    </div>
                                </div>
                            </div>

                            {this.props.partnerGrid.fetching === false ?
                                <div className="grid_scroll_content_component">
                                    <div className="grid_wrapper_component">
                                        {this.props.partnerGrid.partners.length > 0 ?
                                            this.props.partnerGrid.partners.map((partner, index) => {
                                                return (
                                                    <div key={index} className="grid_row mMEDIA___partners_ROW"
                                                        onClick={(event) => this.SelectPartner(partner.NetUid)}
                                                    >

                                                        <div className="grid_cell partners___CELL organizationName__CELL" data-tooltip="Организация">
                                                            <span className="cell_content">{partner.OrganizationName}</span>
                                                        </div>
                                                        {!this.state.isRegisterd ?
                                                            <div className="grid_cell partners___CELL pCELL__date" data-tooltip="Дата">
                                                                <span className="cell_content"><Moment format="DD/MM/YYYY HH:mm" date={partner.Created} /></span>
                                                            </div> : null
                                                        }

                                                        <div className="grid_cell partners___CELL pCELL__url" data-tooltip="Ссылка на сайт или соцсеть">
                                                            <span className="cell_content" data-tooltip={partner.PageUrl}>{partner.PageUrl}</span>
                                                        </div>
                                                        <div className="grid_cell partners___CELL pCELL__ContactName" data-tooltip="Контактное лицо">
                                                            <span className="cell_content">{partner.ContactName}</span>
                                                        </div>
                                                        <div className="grid_cell partners___CELL pCELL__ContactPhone" data-tooltip="Телефон">
                                                            <span className="cell_content">{partner.ContactPhone}</span>
                                                        </div>
                                                        <div className="grid_cell partners___CELL pCELL__ContactEmail" data-tooltip="Email">
                                                            <span className="cell_content">{partner.ContactEmail}</span>
                                                        </div>
                                                        <div className="grid_cell partners___CELL pCELL__OrganizationCity" data-tooltip="Город">
                                                            <span className="cell_content">{partner.OrganizationCity}</span>
                                                        </div>
                                                        <div className="grid_cell partners___CELL balance__CELL" data-tooltip="Баланс">
                                                            <span className="cell_content">{partner.Balance}</span>
                                                        </div>
                                                        {
                                                            !this.state.isWorkerMode && this.state.isRegisterd ?
                                                                <div className={`grid_cell partners___CELL ggLevelComment__CELL ${partner.MinimumPriceLevel ? 'ggLevelComment' : ''}`} data-tooltip="Уровень">
                                                                    <span className="cell_content">{GetPricelevelName(partner.PriceLevel)}</span>
                                                                </div> : null
                                                        }
                                                        <div className="grid_cell partners___CELL organizationName__CELL" data-tooltip="Метод рассчета">
                                                            <span className="cell_content">{GetPartnerPaymentMethodName(partner.PartnerPaymentMethod)}</span>
                                                        </div>
                                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss]} simple>
                                                        { !this.state.canceled && !this.state.isRegisterd && !this.state.isWorkerMode ?
                                                            <div className="grid_cell partners___CELL pCELL__OrganizationCity" data-tooltip="Отменить" onClick={(event) => { this.OnRemovePartner(event, partner) }}>
                                                                {/* icon */}
                                                                <span className="cell_content">
                                                                    <div className="cancelControl">ОТМЕНИТЬ</div>
                                                                </span>
                                                            </div>
                                                            : null}
                                        </PermissionChecker>   

                                                    </div>
                                                );
                                            }) : null
                                        }
                                    </div>
                                </div>
                                :
                                <Loader />
                            }
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        ref="paginatorRef"
                        selectComponentClass={SelectForPaginate}
                        showSizeChanger={true}
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.currentPage}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.partnerGrid.totalPages}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({ partnerGrid: (state as any).partner.partnerGrid }),
    mapDispatchToProps)
    (PartnerAccountsComponent);

