import * as React from "react";
import { Control, Errors } from "react-redux-form";

interface ITextAreaWithValidatorProps {
    LabelMessage?: string;
    ErrorMessages?: any;
    Model: string;
    IsRequired?: boolean;
    Validators?: any,
    ValidatedOn?: any,
    ShowErrorOn?: any,
    Placeholder?: any,
    Readonly?: boolean,
    AsyncValidators?: any;
    AsyncValidateOn?: any;
    MaxLength?: number;
}

export const CustomErrors = (props: any) => {
    return (<div>
        {
            props.children.map((obj: any) =>
                <span className={obj.key} key={obj.key}>
                    {obj.props.children}
                </span>)
        }
    </div>)
}

export const TextArea = (props: ITextAreaWithValidatorProps) => {
    return (
        <div className="defaultFormControlTextatea__value notPadd">
            <Errors
                show={props.ShowErrorOn}
                model={props.Model}
                wrapper={CustomErrors}
                messages={props.ErrorMessages}
            />
            <Control.textarea
                maxLength={props.MaxLength}
                readOnly={props.Readonly}
                placeholder={props.Placeholder}
                autoComplete='off'
                required={props.IsRequired ? true : false}
                validateOn={props.ValidatedOn}
                validators={props.Validators}
                autoCorrect={"off"}
                spellCheck={false}
                model={props.Model}
                asyncValidators={props.AsyncValidators}
                asyncValidateOn={props.AsyncValidateOn}
            />
        </div>
    );
}