import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import Select from 'react-select';
import { Validator } from "../../../../../helpers/validator.helper";
import * as orderCardActions from "../../../../../actions/sales/order.card.actions";
import { Order } from "../../../../../business.entities/order";
import { OrderCardState } from "../../../../../states/sales/order.card.state";

export function SetClientPhoneThunkAction(value, mode) {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            resolve(dispatch(orderCardActions.SetClientPhone(value, mode)));
        });
    }
}

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SetClientPhone: SetClientPhoneThunkAction,
    GetClients: orderCardActions.GetClientsForPartner,
    SelectClient: orderCardActions.SelectClient,
    ResetClientInfo: orderCardActions.ResetClientRv,
    ResetClient: orderCardActions.ResetClient,
    ChangeNewClientName: orderCardActions.ChangeNewClientName,
    ChangeNewClientEmail: orderCardActions.ChangeNewClientEmail,
    ChangeRecipient: orderCardActions.ChangeRecipient,
    ChangeRecipientName: orderCardActions.ChangeRecipientName,
    ChangeRecipientPhone: orderCardActions.ChangeRecipientPhone,
    SetCurrentRecipient: orderCardActions.SetCurrentRecipient,
    UpdateOrder: orderCardActions.UpdateOrder
}, dispatch);

const ClientRv = (props: any) => {

    const OnClientPhoneNumberChangeCommand = (value) => {
        const partenrUid = (props.OrderCard as OrderCardState).order.Partner.NetUid;

        if (value && Validator.IsNumeric(value)) {
            props.GetClients(value, partenrUid);

            if (props.OrderCard.clients.length === 0) {
                if ((value.startsWith('0', 0) && value.length === 10)
                    || (value.startsWith('3', 0) && value.length === 12)) {

                    props.SetClientPhone(value, true).then(() => {
                        props.OrderCard.clientPhoneInputRef.current.focus();
                    });
                }
            }
        }

        if (!props.OrderCard.selectedClient && value === '')
            props.GetClients("", partenrUid);
    }

    const OnSelectClientCommand = (value) => {
        props.SelectClient(value);
        props.OrderCard.clientSelectComponentRef.current.blur();
    }

    const OnFocusClientSelectCommand = () => {
        if (props.OrderCard.selectedClient) {
            // props.OrderCard.clientSelectComponentRef.current.state.inputValue = props.OrderCard.selectedClient.label;
            // props.GetClients(props.OrderCard.selectedClient.label, (props.OrderCard as OrderCardState).order.Partner.NetUid);

            // props.ResetClient();
        }
    }

    const OnNewNumberChangeCommand = (value) => {
        props.SetClientPhone("", false).then(() => {
            props.OrderCard.clientSelectComponentRef.current.state.inputValue = value;
            props.GetClients(value, (props.OrderCard as OrderCardState).order.Partner.NetUid);
            props.OrderCard.clientSelectComponentRef.current.focus();
        });
    }

    const OnChangeNewClientName = (value) => {
        if (value && Validator.IsCyrylicLetters(value))
            props.ChangeNewClientName(value);

        if (value === '')
            props.ChangeNewClientName(value);
    }

    const OnChangeNewClientEmail = (value) => {
        props.ChangeNewClientEmail(value);
    }

    const OnRecipientChangeCommand = () => {
        if (!props.OrderCard.isRecipient === true &&
            (props.OrderCard.order.Client.FullName != props.OrderCard.order.RecipientName ||
                props.OrderCard.order.Client.PhoneNumber != props.OrderCard.order.RecipientPhoneNumber)) {
            props.SetCurrentRecipient(props.OrderCard.order.RecipientName, props.OrderCard.order.RecipientPhoneNumber);
        } else {
            props.ChangeRecipient(!props.OrderCard.isRecipient);
        }
    }

    const OnUpdateOrderCommand = () => {
        const order = Object.assign({},
            props.OrderCard.order,
            {
                ClientId: (props.OrderCard as OrderCardState).newClientMode ? 0 : (props.OrderCard as OrderCardState).selectedClient.value,
                Client: (props.OrderCard as OrderCardState).newClientMode ? (props.OrderCard as OrderCardState).bufferClient : (props.OrderCard as OrderCardState).selectedClient.object,
                RecipientName: (props.OrderCard as OrderCardState).newClientMode ? (props.OrderCard as OrderCardState).bufferClient.FullName : (props.OrderCard as OrderCardState).selectedClient.object.FullName,
                RecipientPhoneNumber: (props.OrderCard as OrderCardState).newClientMode ? (props.OrderCard as OrderCardState).bufferClient.PhoneNumber : (props.OrderCard as OrderCardState).selectedClient.object.PhoneNumber
            } as Order);

        if ((props.OrderCard as OrderCardState).isRecipient) {
            const orderWithRecipient = Object.assign({}, order, {
                RecipientName: (props.OrderCard as OrderCardState).recipientName,
                RecipientPhoneNumber: (props.OrderCard as OrderCardState).recipientPhone,
            });

            props.UpdateOrder(orderWithRecipient);
        } else
            props.UpdateOrder(order);
    }

    return (
        <div className="clientRVContainer">

            <div className="defaultFormControl selectZIndex">
                <div className="defaultFormControl__label">Телефон</div>
                <div className={`selectWrapper__container  ${!props.OrderCard.newClientMode ? '' : 'hide'}`}>
                    <Select
                        ref={props.OrderCard.clientSelectComponentRef}
                        name="clients"
                        onInputChange={OnClientPhoneNumberChangeCommand}
                        options={props.OrderCard.clients}
                        onChange={OnSelectClientCommand}
                        value={props.OrderCard.selectedClient}
                        onFocus={OnFocusClientSelectCommand}
                        openMenuOnFocus={true}
                        placeholder="Клиент"
                    />
                </div>

                <div className={`defaultFormControl__value ${props.OrderCard.newClientMode ? '' : 'hide'}`}>
                    <input type="text"
                        ref={props.OrderCard.clientPhoneInputRef}
                        value={props.OrderCard.bufferClient.PhoneNumber}
                        onChange={(event) => OnNewNumberChangeCommand(event.target.value)} />
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Имя и фамилия</div>
                <div className="defaultFormControl__value">
                    <input type="text" value={props.OrderCard.newClientMode ?
                        props.OrderCard.bufferClient.FullName :
                        (props.OrderCard.selectedClient ? props.OrderCard.selectedClient.object.FullName : '')}
                        onChange={(event) => OnChangeNewClientName(event.target.value)} />
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">Мейл</div>
                <div className="defaultFormControl__value">
                    <input type="text"
                        value={props.OrderCard.newClientMode ?
                            props.OrderCard.bufferClient.Email :
                            (props.OrderCard.selectedClient ? props.OrderCard.selectedClient.object.Email : '')}
                        onChange={(event) => OnChangeNewClientEmail(event.target.value)} />
                </div>
            </div>


            <div className="defaultFormControl">
                <div className="defaultFormControl__label">
                </div>
                <div className="paymCheckWrapper__container">
                    <label className="bHBCheckbox">
                        <input type="checkbox" checked={props.OrderCard.isRecipient} onChange={OnRecipientChangeCommand} />
                        <span>Получатель другое лицо</span>
                    </label>

                </div>
            </div>

            {
                props.OrderCard.isRecipient ?
                    <div>

                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Номер получателя</div>
                            <div className="defaultFormControl__value">
                                <input
                                    type="text"
                                    value={props.OrderCard.recipientPhone}
                                    onChange={(event) => props.ChangeRecipientPhone(event.target.value)} />
                            </div>
                        </div>
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Имя получателя</div>
                            <div className="defaultFormControl__value">
                                <input
                                    type="text"
                                    value={props.OrderCard.recipientName}
                                    onChange={(event) => props.ChangeRecipientName(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    : null
            }
            <div className="defaultFormControl">
                <div className="defaultFormButtonControl" onClick={OnUpdateOrderCommand}>Сохранить</div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientRv)