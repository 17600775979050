export enum OrderTypeEnum {
    Sale = 1,
    Print,
    Production,
    ProductionAndPrint,
    Laser
}

export const GetTypeName = (type) => {
        switch (type) {
            case OrderTypeEnum.Sale:
                return 'Продажа';
            case OrderTypeEnum.Print:
                return 'Печать';
            case OrderTypeEnum.Production:
                return 'Производство';
            case OrderTypeEnum.ProductionAndPrint:
                return 'Печать + Производство + Текстиль';
            case OrderTypeEnum.Laser:
                return 'Текстиль';
            default:
                return '';
        }
}