import storeProvider from "./store.provider";
import { success } from "react-notification-system-redux";

export class ClipboardHelper {
    public static CopyTextWithMessage(text: string, message: string) {
        let tempInput  = document.createElement('input');
        document.getElementsByTagName('body')[0].appendChild(tempInput);
        tempInput.value = text;
        tempInput.select()
        document.execCommand("Copy");
        tempInput.remove();

        storeProvider.getStore().dispatch(success({
            title: message,
            position: 'br',
            autoDismiss: 3,
        }))
    }
}