import * as React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../reducers/index";
import { Form } from "react-redux-form";
import { ExportPtatformConfigItem } from "../../../../business.entities/platformConfigItem";
import { PriceListState } from "../../../../states/stock/price.list.state";
import { List } from "linqts";
import { RegistrationFormCheckbox } from "../../../authentication/common/registration.form.checkbox";

export interface IPriceListExportConfig {
    ExportPlatformForm: ExportPtatformConfigItem[],
    PriceListState: PriceListState
}

const PriceListExportConfig = (props: IPriceListExportConfig) => {

    const getPlatfromName = (netId: string) => {
        return new List(props.PriceListState.Platforms).FirstOrDefault(c => c.NetUid === netId).PlatformName;
    }

    return (
        <Form
            validateOn='change'
            model="platformConfigForm"
        >

            {
                (props.ExportPlatformForm as any).ExportPlatformConfigItem && (props.ExportPlatformForm as any).ExportPlatformConfigItem.map((exportPtatformConfigItem, key) =>
                    <div key={key}>
                        {getPlatfromName(exportPtatformConfigItem.PlatformNetId)}

                        <RegistrationFormCheckbox
                            model=".IsSingle"
                            text="Мадель-Цвет"
                        />

                        <RegistrationFormCheckbox
                            model=".IsMultiple"
                            text="Модель-Цвета"
                        />
                    </div>
                )
            }


        </Form>
    )
}
export default connect((state: ApplicationState) => ({
    PriceListState: state.stock.priceList,
    ExportPlatformForm: (state as any).platformConfigForm
}))(PriceListExportConfig)