import * as React from "react";
import { Route, Redirect } from "react-router";
import { NotFound } from "../components/component.root";


export interface IProtectedRoute {
    role?: string;
    component: any;
    path: string;
    exact?: boolean;
    rolesAllowed?: string[];
}

export const ProtectedRoute = (props: IProtectedRoute) => {
    if (props.rolesAllowed.indexOf(props.role) !== -1) {
        return (<Route exact={props.exact} path={props.path} component={props.component} />);
    } else {
        return (<Route exact={props.exact} path={props.path} component={NotFound} />);
    }
}