import { Category } from "./category";
import { Lexicon } from "./lexicon";
import { BaseCaseshopEntity } from "./base.caseshop.entity";
import { ProductColor } from "./product.color";
import { ProductSite } from "./product.site";
import PartnerWholesale from "./partnerWholesale";

export class Product extends BaseCaseshopEntity {
    public Name: string = "";
    public ProductCode: string = "";
    public SubCategoryId: number = 0;
    public SubCategory: Category = null;
    public Quantity: number = 0;
    public ReturnedDesigns: number = 0;
    public IsRemoved: boolean;
    public CategoryId: number = 0;
    public Category: Category  = null;
    public LexiconId: number = 0;
    public Lexicon: Lexicon  = null;
    public ProductPromId: number = 0;
    public PurchasePrice: number = 0;
    public PartnerPrice: number = 0;
    public RecomendedPartnerPrice: number = 0;
    public PartnerProductCode: number = 0;
    public ProductFoto: string = "";
    public Price: number = 0;
    public WholesalePrice: number = 0;
    public ProductColors: ProductColor[];
    public ProductSite: ProductSite;
    public Wholesale: PartnerWholesale;
}

export default Product;
