import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Loader} from '../../../shared/loader';
import {OrderBaseState} from '../../../../states/sales/order.base.state';
import {OrderProduct} from '../../../../business.entities/order.product';
import {ProductTypeEnum} from '../../../../helpers/enums/product.type.enum';
import * as API from '../../../../constants/api.constants';
import {StockStatusEnum} from '../../../../helpers/enums/stock.status.enum';
import {
    ChangeOrderProductAvailability,
    SendOrdersFromStockRightView
} from '../../../../actions/sales/order.base.actions';
import {PrintStatusEnum} from '../../../../helpers/enums/print.status.enum';
import {ProductionStatusEnum} from '../../../../helpers/enums/production.status';
import {AllIdentityRolesExept, IdentityRoleEnum} from '../../../../helpers/identity.roles';
import PermissionChecker from '../../../shared/permission.checker.component';
import {OrderStatusEnum} from '../../../../helpers/enums/order.status.enum';
import {ShowHoverBlock, SetDisplayingImage} from '../../../../actions/right.view.actions';
import { RightViewState } from '../../../../states/right.view.state';

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication,
        RightView: state.rightView
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    ChangeOrderProductAvailability,
    ShowHoverBlock,
    SetDisplayingImage,
    SendOrdersFromStockRightView
}, dispatch);

const OrderProductsRV = (props: any) => {
    
    const OnSendFromStockCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            props.SendOrdersFromStockRightView([props.OrderBase.orders[props.OrderBase.currentOrderIndex].Id]);
        }
    }

    const OnChangeProductAvailability = (uid, index, boolean) => {
        props.ChangeOrderProductAvailability(uid, index, boolean);
    }

    const CanChangeAvailability = (orderProduct: OrderProduct) => {
        if (props.OrderBase.orders[props.OrderBase.currentOrderIndex].TTN)
            return false;

        if (props.OrderBase.orders[props.OrderBase.currentOrderIndex].OrderStatus.length > 0 && props.OrderBase.orders[props.OrderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return;

        if (props.OrderBase.orders[props.OrderBase.currentOrderIndex].OrderStatus.length > 0 && props.OrderBase.orders[props.OrderBase.currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
            return;

        if (orderProduct.ProductType === ProductTypeEnum.Print) {
            if (orderProduct.ProductPrintStatus.length > 0)
                if (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
                    return false;

            if (orderProduct.CanBeReused == false)
                return false;
        }

        if (orderProduct.ProductType == ProductTypeEnum.Production)
            if (orderProduct.ProductProductionStatus.length > 0)
                if (orderProduct.ProductProductionStatus[0].Status === ProductionStatusEnum.Producted)
                    return false;

        return true;
    }

    return (
        <div className="rightView__orderProductsContainer withButton">
            {(props.RightView as RightViewState).DisplayedProductImage ? 
                <div className="rightView_scaledImage"
                    style={{backgroundImage: props.RightView.DisplayedProductImage}}>
                </div> : null
            }
            {
                (props.OrderBase as OrderBaseState).currentOrderProducts ?
                    <div className="rowItemOrderBase__wrapper">
                        {(props.OrderBase as OrderBaseState).currentOrderProducts.length > 0 ?
                            (props.OrderBase as OrderBaseState).currentOrderProducts.filter(op => !op.ParentOrderProductID).map((mainOrderProduct, mainIndex) =>
                                <div className="rowItemOrderBase__container " key={mainIndex}>
                                    {
                                        [mainOrderProduct].concat((props.OrderBase as OrderBaseState).currentOrderProducts.filter(op => op.ParentOrderProductID === mainOrderProduct.Id))
                                            .map((orderProduct, index) => 
                                                <div key={`${mainIndex}__${index}`}>
                                                    <div className="rowItemOrderBase__header">
                                                        <div className="brandContainer">
                                                            <div className="brandWrapperContainer2">
                                                                <div className="brand">
                                                                    {(orderProduct as OrderProduct).Product.Category.Name}
                                                                    <div className="partnerProductCode">
                                                                        {(orderProduct as OrderProduct).Product.PartnerProductCode}
                                                                    </div>
                                                                </div>
                                                                <div className="model">{(orderProduct as OrderProduct).Product.SubCategory.Name}</div>
                                                                <div className="lexiconContainer">
                                                                    <div className="lexiconWrapper">
                                                                        <div className="lexiconItem">
                                                                            <div className="lexLabel">Наименование:</div>
                                                                            <div className="lexValue">
                                                                                <span>{(orderProduct as OrderProduct).Product.Lexicon.OfficialPartnerName}</span>
                                                                                <span></span>
                                                                            </div>

                                                                            {
                                                                                (orderProduct as OrderProduct).Product.Lexicon.HasColors && (orderProduct as OrderProduct).Color ?
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg" style={{backgroundColor: (orderProduct as OrderProduct).Color.Code}}/>
                                                                                        <div className="colorBgLabel">({(orderProduct as OrderProduct).Color.Name})</div>
                                                                                    </div>
                                                                                    : null
                                                                            }

                                                                            {
                                                                                (orderProduct as OrderProduct).Product.Lexicon.HasOptions && (orderProduct as OrderProduct).Color ?
                                                                                    <div className="lexValueColor">
                                                                                        <div className="colorBg imgThumb__WRAPPER"
                                                                                            style={{backgroundImage: `url(${API.CRM_URL + '/' + orderProduct.Color.Thumb})`}}
                                                                                            onMouseEnter={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(orderProduct.Color)
                                                                                            }}
                                                                                            onMouseLeave={(e) => {
                                                                                                e.stopPropagation();
                                                                                                props.ShowHoverBlock(null)
                                                                                            }}
                                                                                        ></div>
                                                                                        <div className="colorBgLabel">({(orderProduct as OrderProduct).Color.Name})</div>
                                                                                    </div>
                                                                                    : null
                                                                            }

                                                                        </div>
                                                                        {(orderProduct as OrderProduct).BaseProductId ?
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Основа:</div>

                                                                                <div className="lexValue">
                                                                                    <span>{(orderProduct as OrderProduct).BaseProduct.Lexicon.OfficialPartnerName}</span>
                                                                                </div>

                                                                                {
                                                                                    (orderProduct as OrderProduct).BaseColor ?
                                                                                        <div className="lexValueColor">
                                                                                            <div className="colorBg" style={{backgroundColor: (orderProduct as OrderProduct).BaseColor.Code}}/>
                                                                                            <div className="colorBgLabel">({(orderProduct as OrderProduct).BaseColor.Name})</div>
                                                                                        </div>
                                                                                        : null
                                                                                }
                                                                            </div> : null
                                                                        }
                                                                        {(orderProduct as OrderProduct).Product && (orderProduct as OrderProduct).Product.SubCategory ? 
                                                                            <div className="lexiconItem">
                                                                                <div className="lexLabel">Номер стелажа: </div>
                                                                                <div className="lexValue">
                                                                                {(orderProduct as OrderProduct).Product.SubCategory.ShelvingNumber ? (orderProduct as OrderProduct).Product.SubCategory.ShelvingNumber : "Не установлен"}
                                                                                </div>
                                                                            </div> : null
                                                                            
                                                                        }
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        {orderProduct.Product.Lexicon.HasAdditionalOptions &&
                                                        <div className="brandContainer ops__brandContainer">
                                                            <div className="lexiconContainer">
                                                                <div className="lexiconWrapper">
                                                                    <div className="lexiconItem">
                                                                        <details open className="lexiconItem__DETAILS">
                                                                            <summary>Доп опции</summary>
                                                                            {
                                                                                orderProduct.OrderProductAdditionalOption && orderProduct.OrderProductAdditionalOption.map(orderProductOption =>
                                                                                    <div className="lexiconItem"
                                                                                        onMouseEnter={(e) => {
                                                                                            e.stopPropagation();
                                                                                            props.ShowHoverBlock(orderProductOption.AdditionalOption)
                                                                                        }}
                                                                                        onMouseLeave={(e) => {
                                                                                            e.stopPropagation();
                                                                                            props.ShowHoverBlock(null)
                                                                                        }}>
                                                                                        <div
                                                                                            className="lexLabel">{orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.OptionCategory.Parent.Name}</div>
                                                                                        <div className="lexValue">
                                                                                            <span>{orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.OptionCategory && orderProductOption.AdditionalOption.OptionCategory.Name} - {orderProductOption.AdditionalOption && orderProductOption.AdditionalOption.Name}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            }

                                                                        </details>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {(orderProduct as OrderProduct).Product.Lexicon.ProductTypeId === ProductTypeEnum.Production
                                                        || (orderProduct as OrderProduct).Product.Lexicon.ProductTypeId === ProductTypeEnum.PrintProduction ?
                                                            <div className="brandContainer ops__brandContainer">
                                                                <div className="defaultFormControl">
                                                                    <div className="defaultFormControl__label">Комментарий</div>
                                                                    <div className="defaultFormControl__value">
                                                                        <div className="imitation_input">
                                                                            {(orderProduct as OrderProduct).ProductionComment}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }


                                                    </div>
                                                    <div className="containerOrderProductSub containerOrderProductSubRel">
                                                        <div className="cops__container qty">
                                                            <div className={`label ${(orderProduct as OrderProduct).Quantity > 1 ? 'high_lighted':''}`}>
                                                                {(orderProduct as OrderProduct).Quantity}
                                                            </div>
                                                        </div>
                                                        <div className="cops__container price">
                                                            <div className="label">
                                                                {(orderProduct as OrderProduct).Price}
                                                            </div>
                                                        </div>
                                                        <div className="cops__container partnerTotal">
                                                            <div className="label">
                                                                {(orderProduct as OrderProduct).PartnerTotal} | {(orderProduct as OrderProduct).Total}
                                                            </div>
                                                        </div>

                                                        {CanChangeAvailability(orderProduct) ?
                                                            <PermissionChecker
                                                                Classes='opRVControls__position'
                                                                EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Sklad, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                                                {
                                                                    (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex]
                                                                        .StockStatus.length > 0 &&
                                                                    (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex]
                                                                        .StockStatus[0].Status &&
                                                                    <div className="controlsOrderBaseState__container">
                                                                        <div className="controlsOrderBaseState__wrapper">
                                                                            <div
                                                                                className={` ${(orderProduct as OrderProduct).IsAvailable == true ? 'controlsOrderBaseState iGre' : 'controlsOrderBaseState'}`}
                                                                                onClick={() => OnChangeProductAvailability((orderProduct as OrderProduct).NetUid, index, true)}>
                                                                                В наличии
                                                                            </div>
                                                                            <div
                                                                                className={` ${(orderProduct as OrderProduct).IsAvailable == false ? 'controlsOrderBaseState iRed' : 'controlsOrderBaseState'}`}
                                                                                onClick={() => OnChangeProductAvailability((orderProduct as OrderProduct).NetUid, index, false)}>
                                                                                Нету в наличии
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </PermissionChecker>
                                                            : null}
                                                    </div>

                                                    {
                                                        (orderProduct as OrderProduct).Product.Lexicon.CanHaveUserImg &&
                                                        <div>
                                                            <div className="userPrintProduction__img"
                                                                style={{backgroundImage: `url(${API.SERVER_URL}${(orderProduct as OrderProduct).UserPrewiev})`}}
                                                                onMouseEnter = {(e) => {
                                                                    e.stopPropagation();
                                                                    props.SetDisplayingImage(`url(${API.SERVER_URL}${(orderProduct as OrderProduct).UserPrewiev})`)
                                                                }}
                                                                onMouseLeave = {(e) => {
                                                                    e.stopPropagation();  
                                                                    props.SetDisplayingImage(null)
                                                                }}
                                                                />
                                                            <div className="userPrintProduction__comment">
                                                                {(orderProduct as OrderProduct).UserImageComment}
                                                            </div>
                                                        </div>
                                                    }                                                    
                                                </div>
                                            )
                                    }
                                </div>
                            )
                            :
                            null
                        }
                    </div> :
                    <Loader/>
            }
            {
                (!props.OrderBase.fetchInProgress && props.OrderBase.currentOrderIndex != null && props.OrderBase.currentOrderIndex != undefined) ?
                <div className="editTutorialContainer widthAuto atBottom">
                    <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                        <div className=""
                            onClick={OnSendFromStockCommand}>
                            Отправить со склада
                        </div>
                    </PermissionChecker>
                </div> : null
            }
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderProductsRV)