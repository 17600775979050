import { DatePicker } from 'material-ui';
import * as moment from 'moment';
import Pagination from 'rc-pagination';
import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Select as PaginationSelect } from 'rc-select';
import { bindActionCreators } from 'redux';
import {
    GetPromocodeNamesAutocomplete, GetPromocodesFiltered, GetPromocodeShopAddressAutocomplete, GetPromocodeStatusesAutocomplete,
    GetPromocodeTypeAutocomplete, SelectPromocodeNameFilter, SelectPromocodePriceFilter, SelectPromocodeShopAddressFilter,
    SelectPromocodeStatusFilter, SetPromocodes, SelectPromocode,
    SelectPromocodeTypeFilter, SelectPromocodeUsedDateFilter,
    DeletePromocodes, ExportPromocodes
} from '../../../../actions/partner/promocode.actions';
import { RemoveRVData, SelectRVData } from '../../../../actions/right.view.actions';
import { Promocode } from '../../../../business.entities/promocode';
import { PromocodeType } from '../../../../business.entities/promocode.type';
import { GetPromocodeStatusName } from '../../../../helpers/enums/promocode.status.enum';
import {
    GetIdentityRoleName,
    IdentityRoleEnum
} from '../../../../helpers/identity.roles';
import Locale from '../../../../helpers/locale.pagination';
import { PromocodeFilterEntity } from '../../../../helpers/promocode.filter.entity';
import { LoginState } from '../../../../states/login.state';
import { PromocodeState } from '../../../../states/partner/promocode.state';
import { RightViewState } from '../../../../states/right.view.state';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';
import PermissionChecker from '../../../shared/permission.checker.component';
import PromocodesImportComponent from './promocodes.upload.rv';
import { List } from 'linqts';
import Moment from 'react-moment';
import * as API from '../../../../constants/api.constants';


const mapStateToProps = (state) => {
    return {
        Promocode: state.partner.promocode,
        Authorization: state.authentication,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetPromocodesFiltered,

    SelectPromocodeNameFilter,
    SelectPromocodeUsedDateFilter,
    SelectPromocodeShopAddressFilter,
    SelectPromocodeStatusFilter,
    SelectPromocodeTypeFilter,
    SelectPromocodePriceFilter,
    GetPromocodeNamesAutocomplete,
    GetPromocodeStatusesAutocomplete,
    GetPromocodeTypeAutocomplete,
    GetPromocodeShopAddressAutocomplete,
    SelectPromocode,
    SetPromocodes,
    DeletePromocodes,
    ExportPromocodes,
    SelectRVData,
    RemoveRVData
}, dispatch);

export interface IPromocodeBase {
    Promocode: PromocodeState;
    Authorization: LoginState;
    GetPromocodesFiltered(filter: any, page: any, count: any),
    SelectPromocodeNameFilter(value: any),
    SelectPromocodeUsedDateFilter(value: any),
    SelectPromocodeShopAddressFilter(value: any),
    SelectPromocodeStatusFilter(value: any),
    SelectPromocodeTypeFilter(value: any),
    SelectPromocodePriceFilter(value: any),
    GetPromocodeNamesAutocomplete(value: any),
    GetPromocodeStatusesAutocomplete(value: any),
    GetPromocodeTypeAutocomplete(value: any),
    GetPromocodeShopAddressAutocomplete(value: any),
    GetPromocodesFilteredSuccess(value: any);
    DeletePromocodes(value: any, filter: any, page: any, count: any);
    SelectPromocode(value: any);
    ExportPromocodes(value: any);
    SetPromocodes(value: any);
    SelectRVData(value: any),
    RemoveRVData()
}

class PartnerPromocodesComponent extends React.Component<IPromocodeBase, any> {
    constructor(props) {
        super(props);

        this.state = {
            currentPage: 1,
            currentPageSize: 25,
        }
       
        this.props.GetPromocodeNamesAutocomplete("");
        this.props.GetPromocodeStatusesAutocomplete("");
        this.props.GetPromocodeShopAddressAutocomplete("");
        this.props.GetPromocodeTypeAutocomplete("");
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
    }


    public ImportPromocodes() {
        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)) {

            let rvState = new RightViewState();
            rvState.Title = "Загрузка промокодов";
            rvState.Component = PromocodesImportComponent
            rvState.ViewSize = "rightViewGroupActions promocodesImportWindow";
            rvState.CloseFunction = () => {
                this.props.RemoveRVData();
                let filter = this.GetFilter();
                this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
            }

            this.props.SelectRVData(rvState);
        }
    }

    public async DeletePromocodes() {
        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)) {
            
            let promocodes = Object.assign([], this.props.Promocode.promocodes);

            let filter = this.GetFilter();
            const promocodesToDelete = promocodes.filter(promocode => (promocode as Promocode).IsSelected);
            await this.props.DeletePromocodes(promocodesToDelete, filter, this.state.currentPage, this.state.currentPageSize);
            await this.props.GetPromocodeNamesAutocomplete("");
            
        }
    }

    public async ExportPromocodes() {
        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)) {

            let filter = this.GetFilter();
            
            await this.props.ExportPromocodes(filter);
        }

    }

    public async SelectNameFilter(value) {
        await this.props.SelectPromocodeNameFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public async SelectStatusFilter(value) {
        await this.props.SelectPromocodeStatusFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public async SelectUsedDateFilter(value) {
        await this.props.SelectPromocodeUsedDateFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public async SelectAddressFilter(value) {
        await this.props.SelectPromocodeShopAddressFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public async SelectTypeFilter(value) {
        await this.props.SelectPromocodeTypeFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public async SelectPriceFilter(value) {
        await this.props.SelectPromocodePriceFilter(value);
        let filter = this.GetFilter();
        this.props.GetPromocodesFiltered(filter, this.state.currentPage, this.state.currentPageSize);
        this.setState(() => ({
            currentPage: 1
        }));
    }

    public OnChangePageSize(current, pageSize):void {
        this.setState(() => ({
            currentPage: 1,
            currentPageSize: pageSize
        }));

        let filter = this.GetFilter();

        this.props.GetPromocodesFiltered(filter, current, pageSize);
    }

    public GetFilter(): PromocodeFilterEntity{
        let filter = new PromocodeFilterEntity;
        filter.Name = this.props.Promocode.promocodeNameFilter ? this.props.Promocode.promocodeNameFilter.label : "";
        filter.Price = this.props.Promocode.promocodePriceFilter && parseFloat(this.props.Promocode.promocodePriceFilter);
        filter.ShopAddress = this.props.Promocode.promocodeShopAddressFilter ? this.props.Promocode.promocodeShopAddressFilter.label : "";
        filter.Status = this.props.Promocode.promocodeStatusFilter && this.props.Promocode.promocodeStatusFilter.value;
        filter.Type = this.props.Promocode.promocodeTypeFilter ? this.props.Promocode.promocodeTypeFilter.label : "";
        filter.UsedDate = this.props.Promocode.promocodeUsedDateFilter  && moment(this.props.Promocode.promocodeUsedDateFilter).hour(0).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss');
        return filter;
    }

    public OnChangePage(current, page) {
        this.setState(() => ({
            currentPage: current
        }));

        let filter = this.GetFilter();

        this.props.GetPromocodesFiltered(filter, current, page);
    }

    public IsAllSelected() {
        if (new List<Promocode>(this.props.Promocode.promocodes).All(c => c.IsSelected))
            return true;

        return false;
    }

    public OnSelectAllPromocodesCommand(value) {
        let promocodes = Object.assign([], this.props.Promocode.promocodes);
        let count = promocodes.length;

        promocodes.forEach((promocode) => (promocode as Promocode).IsSelected = value);

        this.props.SetPromocodes({Item1: promocodes, Item2: this.props.Promocode.totalCount});
    }

    public OnSelectPromocodeCommand(index) {
        this.props.SelectPromocode({index: index, value: !this.props.Promocode.promocodes[index].IsSelected});
    }

    render() {
        return (
            <div className="master_content_component promocodes_containter">
                <LeftMenuMobileControl />
                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Поиск по промокоду</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="promocodeName"
                                    options={this.props.Promocode.promocodeNameAutocompleteOptions}
                                    onChange={(value) => this.SelectNameFilter(value)}
                                    value={this.props.Promocode.promocodeNameFilter}
                                    placeholder={''}
                                />
                            </div>
                        </div>
                        
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Статус промокода</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="promocodeStatus"
                                    options={this.props.Promocode.promocodeStatusAutocompleteOptions}
                                    onChange={(value) => this.SelectStatusFilter(value)}
                                    value={this.props.Promocode.promocodeStatusFilter}
                                    placeholder={''}
                                />
                            </div>
                        </div>
                        
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Дата использования промокода</div>
                            <div className="dateTimeWrapper__container">
                                <DatePicker
                                    name={"usedDate"}
                                    value={this.props.Promocode.promocodeUsedDateFilter}
                                    onChange={(event, date: any) => {
                                        this.SelectUsedDateFilter(date);
                                    }}
                                />
                                <div className="clControl" onClick={() => this.SelectUsedDateFilter(null)} />
                            </div>
                        </div>
                        
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Адрес магазина</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="promocodeShopAddress"
                                    options={this.props.Promocode.promocodeAddressAutocompleteOptions}
                                    onChange={(value) => this.SelectAddressFilter(value)}
                                    value={this.props.Promocode.promocodeShopAddressFilter}
                                    placeholder={''}
                                />
                            </div>
                        </div> 
                        
                         <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Тип сертификата</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="promocodeCertificateType"
                                    options={this.props.Promocode.promocodeTypeAutocompleteOptions}
                                    onChange={(value) => this.SelectTypeFilter(value)}
                                    value={this.props.Promocode.promocodeTypeFilter}
                                    placeholder={''}
                                />
                            </div>
                        </div>
                        
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Цена сертификата</div>
                            <div className="defaultFormControl__value">
                                <DebounceInput
                                    type={"number"}
                                    minLength={2}
                                    debounceTimeout={1000}
                                    value={this.props.Promocode.promocodePriceFilter}
                                    onChange={event => { this.SelectPriceFilter(event.target.value) }} />
                            </div>
                        </div> 

                    {/* </div> */}
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title oneTitleContainer">
                        <span className="title_default">Список промокодов</span>
                        <span className="title_description"></span>
                    </div>
                    <div className="header__button__controls">
                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className="" onClick={() => this.ImportPromocodes()}>
                                Загрузить промокоды
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className="" onClick={() => this.DeletePromocodes()}>
                                Удалить промокоды
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                            <div className="" onClick={() => this.ExportPromocodes()}>
                                Экспорт промокодов
                            </div>
                        </PermissionChecker>

                        {this.props.Promocode.fileToExportName &&
                            <a className="button__control" href={`${API.SERVER_URL}${this.props.Promocode.fileToExportName}`}
                                download={`promocodes_export_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать</a>
                        }
                    </div>
                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">

                                        <PermissionChecker
                                            Classes='grid_cell control checkCell__container'
                                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                            <div className="">
                                                <div className={this.IsAllSelected() ? 'checkControl ch' : 'checkControl unCh'}
                                                    onClick={() => {this.OnSelectAllPromocodesCommand(!this.IsAllSelected())}} />
                                            </div>
                                        </PermissionChecker>

                                        <div className="grid_cell oBase__CELL manufOrderBaseCell fMW">
                                            <div className="cell_content">Промокод</div>
                                        </div>

                                        <div className="grid_cell obCell__date">
                                            <div className="cell_content">Дата внесения</div>
                                        </div>

                                        <div className="grid_cell obCell__date">
                                            <div className="cell_content" style={{lineHeight:1}}>Дата и время использования</div>
                                        </div>

                                        <div className="grid_cell">
                                            <div className="cell_content">Адрес магазина где использован</div>
                                        </div>

                                        <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW">
                                            <div className="cell_content">Статус промокода</div>
                                        </div>

                                        <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW">
                                            <div className="cell_content">Тип сертификата</div>
                                        </div>

                                        <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW">
                                            <div className="cell_content">Цена сертификата</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="grid_scroll_content_component">
                                <div className="grid_wrapper_component">
                                    {this.props.Promocode.promocodes && this.props.Promocode.promocodes.map((promocode, index) => {
                                        return (
                                            <div className={`mMEDIA___orderBase_ROW ordersBASE_GRID_ROW grid_row`} key={index}>
                                                <PermissionChecker
                                                    Classes='grid_cell control checkCell__container'
                                                    ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                                    <div className="">
                                                        <div className={promocode.IsSelected ? 'checkControl ch' : 'checkControl unCh'}
                                                            onClick={() => {this.OnSelectPromocodeCommand(index)}} />
                                                    </div>
                                                </PermissionChecker>
                                                
                                                <div className="grid_cell oBase__CELL manufOrderBaseCell fMW" data-tooltip="Промокод">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            {(promocode as Promocode).Name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell obCell__date" data-tooltip="Дата внесения">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            <Moment format="DD/MM/YYYY HH:mm" date={(promocode as Promocode).InsertDate} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell obCell__date" data-tooltip="Дата и время использования">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                        {(promocode as Promocode).UsedDate ?<Moment format="DD/MM/YYYY HH:mm" date={(promocode as Promocode).UsedDate} /> : null}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell" data-tooltip="Адрес магазина где использован">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            {(promocode as Promocode).ShopAddress}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW" data-tooltip="Статус промокода">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            {GetPromocodeStatusName((promocode as Promocode).Status)}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW" data-tooltip="Тип сертификата">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            {((promocode as Promocode).Type as PromocodeType).Name}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="grid_cell  oBase__CELL manufOrderBaseCell fMW" data-tooltip="Цена сертификата">
                                                    <div>
                                                        <div className="cell_content obCell__codeC">
                                                            {(promocode as Promocode).Price}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        selectComponentClass={PaginationSelect}
                        showSizeChanger
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.currentPage}
                        onShowSizeChange={this.OnChangePageSize.bind(this)}
                        onChange={this.OnChangePage.bind(this)}
                        total={this.props.Promocode.totalCount}
                    /> 
                </div>
            </div>
        )
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PartnerPromocodesComponent);