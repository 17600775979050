export enum TimePartEnum {
    Hour,
    Day,
    Week,
    Month
}

export const GetTimepartName = (timepart: TimePartEnum) => {
    switch (timepart) {
        case TimePartEnum.Hour:
            return 'Часам';
        case TimePartEnum.Day:
            return 'Дням';
        case TimePartEnum.Week:
            return 'Неделям';
        case TimePartEnum.Month:
            return 'Месяцам';
        default:
            return '';
    }
}

export const GetTimePartArray = [
    {label: GetTimepartName(TimePartEnum.Hour), value: TimePartEnum.Hour},
    {label: GetTimepartName(TimePartEnum.Day), value: TimePartEnum.Day},
    {label: GetTimepartName(TimePartEnum.Week), value: TimePartEnum.Week},
    {label: GetTimepartName(TimePartEnum.Month), value: TimePartEnum.Month}
]