import { PromocodeStatus } from "./enums/promocode.status.enum";

export class PromocodeFilterEntity {
    public Name: string;

    public UsedDate?: any;

    public ShopAddress: string;

    public Status?: PromocodeStatus;

    public Type: string; 

    public Price?: number;
}