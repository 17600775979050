import * as React from "react";
import Pagination from 'rc-pagination';
import RCSelect from 'rc-select';
import Select from 'react-select';
import Locale from '../../../../helpers/locale.pagination';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from "../../../../Reducers/index";
import { SelectModel } from "../../../../helpers/select.model";
import { Category } from "../../../../business.entities/category";
import { Product } from "../../../../business.entities/product";
import { ProductColor } from "../../../../business.entities/product.color";
import * as AvibilityActionCreators from "../../../../actions/stock/aviability.actions";
import { default as AvailabilityProduct } from "./availability.product";
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";
import { IdentityRoleEnum } from "../../../../helpers/identity.roles";
import PermissionChecker from '../../../shared/permission.checker.component';

export interface IAvailabilityProps {
    ChangeModelValue: any;
    ChangeLexiconValue: any;
    ChangeArticulusValue: any;
    ChangeFilterSubCategoryValue: any;
    ChangeFilterCategoryValue: any;
    ResetSelectedValues: any;
    aviability: any;
    AvibilityActionCreators: any;
    optionLexiconList: SelectModel[];
    GetAutocompleteModelResult(value: string): any;
    GetAutocompleteLexiconResult(value: string): any;
    GetAutocompleteArticulusResult(value: string): any;
    GetAutocompleteFilterSubCategoryResult(value: string, selectedFilterCategoryValue: any): any;
    GetFilterCategoryResult(): any;
    GetCategoriesForSelectedProduct(filteredData: string): any;
    UnmounComponent();
    GetPartnerPriceList();
}

export interface IAvailabilityState {
    page: number;
}

class Availability extends React.Component<IAvailabilityProps, IAvailabilityState> {
    constructor(props) {
        super(props);

        this.state = { page: 1 };
        this.props.GetFilterCategoryResult();
        this.props.GetAutocompleteLexiconResult("");

        this.onAutocompleteModelInputChange = this.onAutocompleteModelInputChange.bind(this);
        this.onAutocompleteArticulusInputChange = this.onAutocompleteArticulusInputChange.bind(this);
        this.onAutocompleteLexiconInputChange = this.onAutocompleteLexiconInputChange.bind(this);
        this.onAutocompleteFilterSubCategoryInputChange = this.onAutocompleteFilterSubCategoryInputChange.bind(this);
        this.onResetSelectedValuesCommand = this.onResetSelectedValuesCommand.bind(this);
    }

    componentWillUnmount() {
        this.props.UnmounComponent();
    }

    componentWillReceiveProps(nextProps: IAvailabilityProps) {
        let {
            selectedArticulusValue,
            selectedFilterCategoryValue,
            productValue,
            selectedLexiconValue,
            filterChanged,
            fetchInProccess,
        } = nextProps.aviability;

        if (filterChanged && !fetchInProccess) {
            this.setState({
                page: 1
            });

            let filteredData = {
                ProductCode: selectedArticulusValue && selectedArticulusValue.label,
                CategoryId: selectedFilterCategoryValue && selectedFilterCategoryValue.value,
                SubCategoryId: productValue && productValue.value,
                LexiconId: selectedLexiconValue && selectedLexiconValue.value,
                Page: 1,
            };
            this.props.GetCategoriesForSelectedProduct(JSON.stringify(filteredData));

            selectedFilterCategoryValue && this.props.GetAutocompleteFilterSubCategoryResult("", selectedFilterCategoryValue);
        }
    }

    private onAutocompleteModelInputChange(value: string) {
        value && this.props.GetAutocompleteModelResult(value);
    }

    private onAutocompleteArticulusInputChange(value: string) {
        value && this.props.GetAutocompleteArticulusResult(value);
    }

    private onAutocompleteLexiconInputChange(value: string) {
        value && this.props.GetAutocompleteLexiconResult(value);
    }

    private onAutocompleteFilterSubCategoryInputChange(value: string) {
        value && this.props.GetAutocompleteFilterSubCategoryResult(value, this.props.aviability.selectedFilterCategoryValue);
    }

    private onResetSelectedValuesCommand() {
        this.props.ResetSelectedValues();
    }


    public OnChangePageCommand(current, pageSize): void {
        let {
            selectedArticulusValue,
            selectedFilterCategoryValue,
            productValue,
            selectedLexiconValue,
            fetchInProccess,
            totalPages,
        } = this.props.aviability;

        if (!this.props.aviability.fetchInProccess) {
            this.setState(() => ({
                page: current
            }));

            let filteredData = {
                ProductCode: selectedArticulusValue && selectedArticulusValue.label,
                CategoryId: selectedFilterCategoryValue && selectedFilterCategoryValue.value,
                SubCategoryId: productValue && productValue.value,
                LexiconId: selectedLexiconValue && selectedLexiconValue.value,
                Page: current,
            };

            this.props.GetCategoriesForSelectedProduct(JSON.stringify(filteredData));
        }
    }
    // private OnPrevPageCommand() {
    //     let {
    //         selectedArticulusValue,
    //         selectedFilterCategoryValue,
    //         productValue,
    //         selectedLexiconValue,
    //         fetchInProccess,
    //         totalPages,
    //     } = this.props.aviability;

    //     if (!fetchInProccess) {
    //         let { page } = this.state;
    //         if (totalPages > page) {
    //             page--;

    //             let filteredData = {
    //                 ProductCode: selectedArticulusValue && selectedArticulusValue.label,
    //                 CategoryId: selectedFilterCategoryValue && selectedFilterCategoryValue.value,
    //                 SubCategoryId: productValue && productValue.value,
    //                 LexiconId: selectedLexiconValue && selectedLexiconValue.value,
    //                 Page: page,
    //             };
    //             this.props.GetCategoriesForSelectedProduct(JSON.stringify(filteredData));

    //             this.setState({ page: page });
    //         }
    //     }
    // }

    // private OnNextPageCommand() {
    //     let {
    //         selectedArticulusValue,
    //         selectedFilterCategoryValue,
    //         productValue,
    //         selectedLexiconValue,
    //         fetchInProccess,
    //         totalPages,
    //     } = this.props.aviability;

    //     if (!fetchInProccess) {
    //         let { page } = this.state;
    //         if (totalPages > page) {
    //             page++;

    //             let filteredData = {
    //                 ProductCode: selectedArticulusValue && selectedArticulusValue.label,
    //                 CategoryId: selectedFilterCategoryValue && selectedFilterCategoryValue.value,
    //                 SubCategoryId: productValue && productValue.value,
    //                 LexiconId: selectedLexiconValue && selectedLexiconValue.value,
    //                 Page: page,
    //             };
    //             this.props.GetCategoriesForSelectedProduct(JSON.stringify(filteredData));

    //             this.setState({ page: page });
    //         }
    //     }
    // }

    private getKeyForProduct(categoryIndex: number, productIndex): string {
        return categoryIndex.toString() + productIndex.toString();
    }

    render() {
        const {
            optionModelList,
            selectedModelValue,
            optionArticulusList,
            selectedArticulusValue,
            optionFilterCategoryList,
            selectedFilterCategoryValue,
            optionFilterSubCategoryList,
            selectedFilterSubCategoryValue,
            optionLexiconList,
            selectedLexiconValue,
            totalPages,
            categories
        } = this.props.aviability;

        return (
            <div className="master_content_component rcPagination__component">
                <LeftMenuMobileControl />
                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Модель</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="model"
                                    onInputChange={this.onAutocompleteModelInputChange}
                                    options={optionModelList}
                                    onChange={this.props.ChangeModelValue}
                                    value={selectedModelValue}
                                    placeholder="Введите модель"
                                />
                            </div>
                        </div>
                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Артикул</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="articulus"
                                    onInputChange={this.onAutocompleteArticulusInputChange}
                                    options={optionArticulusList}
                                    onChange={this.props.ChangeArticulusValue}
                                    value={selectedArticulusValue}
                                    placeholder="Введите артикул"
                                />
                            </div>
                        </div>

                        <div className="deafultFormLineStyle" />

                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Фильтр</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    placeholder="Выберите марку"
                                    name="filterCategory"
                                    options={optionFilterCategoryList}
                                    onChange={this.props.ChangeFilterCategoryValue}
                                    value={selectedFilterCategoryValue}
                                />
                            </div>
                        </div>
                        {selectedFilterCategoryValue &&
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Введите модель</div>
                                <div className="selectWrapper__container notPadd">
                                    <Select
                                        placeholder="Введите модель"
                                        name="filterSubCategory"
                                        onInputChange={this.onAutocompleteFilterSubCategoryInputChange}
                                        options={optionFilterSubCategoryList}
                                        onChange={this.props.ChangeFilterSubCategoryValue}
                                        value={selectedFilterSubCategoryValue}

                                    />
                                </div>
                            </div>}

                        <div className="defaultFormControl">
                            <div className="defaultFormControl__label">Товар</div>
                            <div className="selectWrapper__container notPadd">
                                <Select
                                    name="lexicon"
                                    options={optionLexiconList}
                                    onChange={this.props.ChangeLexiconValue}
                                    value={selectedLexiconValue}
                                    placeholder="Введите тип товара"
                                />
                            </div>
                        </div>

                        <div className="deafultFormLineStyle" />

                        <div className="defaultFormControl">
                            <div
                                className="defaultFormButton"
                                onClick={(event) => this.onResetSelectedValuesCommand()} >Сброс</div>
                        </div>
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Наличие и цены</span>
                        <span className="title_description"></span>
                    </div>
                    <div className="header__button__controls">
                        <PermissionChecker
                            Classes='button__control'
                            ViewRoles={[IdentityRoleEnum.Administrator, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                            <div className="" onClick={() => this.props.GetPartnerPriceList()}>
                                Наличие для партнёров
                                </div>
                        </PermissionChecker>
                    </div>
                </div>
                <div className="content_component">
                    <div className="grid_component availability_grid__component aBil__grid">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">
                                        <div className="grid_cell cell__lexicon">
                                            <span className="cell_content">Наименование</span>
                                        </div>
                                        <div className="grid_cell cell__price">
                                            <span className="cell_content">Цена дропшиппинг</span>
                                        </div>
                                        <div className="grid_cell cell__ppc">
                                            <span className="cell_content">РРЦ</span>
                                        </div>
                                        <div className="grid_cell cell__opt">
                                            <span className="cell_content">Оптовая цена</span>
                                        </div>
                                        <div className="grid_cell cell__sites">
                                            <span className="cell_content">Пример</span>
                                        </div>
                                        <div className="grid_cell cell__code">
                                            <span className="cell_content">Код товара</span>
                                        </div>
                                        <div className="grid_cell cell__colors">
                                            <span className="cell_content">Цвета</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid_scroll_content_component">
                                <div className="grid_wrapper_component">
                                    {
                                        categories && categories.map((category: Category, index: number) =>
                                            <div className="width__full__grid_component" key={index}>
                                                <div className="category__name__header"><b>{category.Parent.Name}</b> {' - '} <b>{category.Name}</b></div>

                                                {
                                                    category.Products.map((product: Product, indexChild: number) =>
                                                        <AvailabilityProduct
                                                            key={this.getKeyForProduct(index, indexChild)}
                                                            Product={product} />
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <Pagination
                        locale={Locale}
                        selectComponentClass={RCSelect}
                        // showSizeChanger
                        // pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        // defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.page}
                        // onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.aviability.totalPages}
                    />
                </div>
            </div >
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ aviability: state.stock.aviability }),
    AvibilityActionCreators as any)
    (Availability);