import * as React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Chart from "chart.js"
import { List } from "linqts";
import { AddChart } from "../../../../actions/cabinet/statistics.actions";
import { ChartComponent } from "./chart.component";

function mapStateToProps(state) {
    return {
        Authorization: state.authentication,
        Statistics: state.cabinet.statistics
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        AddChart
    }, dispatch);
}

export const DiagramsComponent = (props: any) => {

    return (
        <div className="diagramsComponent__CONTAINER">
            {
                props.Statistics.DiagramData.length > 0 && props.Statistics.DiagramData.map((data, index) =>
                    <details className="diagramDataStatistics__ROW" key={index} open>
                        <summary className="statName__TITLE">
                            {data.StatName}
                        </summary>

                        <div className="statName__BODY">
                            <div className="bodyLINE__ROW headerLINE__ROW">
                                <div className="dCELL name_dCELL"></div>

                                {data.Labels.map((label, index) =>
                                    <>
                                        {
                                            index === 0 ?
                                                <div className="dCELL">
                                                    <div className="cContent">
                                                        Всего
                                                    </div>
                                                </div> : null
                                        }
                                        <div className="dCELL">
                                            <div className="cContent">
                                                {label}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {data.Datasets.map((dataset) =>
                                <div className="bodyLINE__ROW">
                                    <div className="dCELL name_dCELL">
                                        {dataset.Label}
                                        <div className="bSC" style={{ backgroundColor: dataset.PointBackgroundColor }} />
                                    </div>
                                    {dataset.Data.map((data, index) =>
                                        <>
                                            {index === 0 ?
                                                <div className="dCELL" data-tooltip={dataset.Label}>
                                                    {new List<any>(dataset.Data).Sum().toFixed(2)}
                                                </div>
                                                : null
                                            }
                                            <div className="dCELL" data-tooltip={dataset.Label}>
                                                {data}
                                            </div>
                                        </>
                                    )}
                                </div>
                            )}
                        </div>

                        <div>
                            <ChartComponent data={
                                Object.assign({}, {
                                    labels: data.Labels,
                                    datasets: data.Datasets.map(c => Object.assign({}, {
                                        label: c.Label,
                                        borderColor: c.BorderColor,
                                        pointBackgroundColor: c.PointBackgroundColor,
                                        pointHitRadius: c.PointHitRadius,
                                        pointRadius: c.PointRadius,
                                        data: c.Data
                                    }))
                                })
                            } />
                        </div>
                    </details>

                )
            }
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(DiagramsComponent);

