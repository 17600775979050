export const IdentityRolesArray = [
    { value: 'Partner', name: 'Партнер' },
    { value: 'Boss', name: 'Керівник' },
    { value: 'Coordinator', name: 'Координатор' },
    { value: 'Sklad', name: 'Робітник складу' },
    { value: 'Print', name: 'Друкар' },
    { value: 'Manufacture', name: 'Швачка' },
    { value: 'Booker', name: 'Бухгалтер' },
    { value: 'Admin', name: 'Адміністратор' },
    { value: 'Lasercut', name: 'Лазерщик' },
    { value: 'Maysternya', name: 'Майстерня чохлів' }
];

export const IdentityRolesForSelect = [
    { value: 'Partner', label: 'Партнер' },
    { value: 'Boss', label: 'Керівник' },
    { value: 'Coordinator', label: 'Координатор' },
    { value: 'Sklad', label: 'Робітник складу' },
    { value: 'Print', label: 'Друкар' },
    { value: 'Manufacture', label: 'Швачка' },
    { value: 'Booker', label: 'Бухгалтер' },
    { value: 'Admin', label: 'Адміністратор' },
    { value: 'Lasercut', label: 'Лазерщик' },
    { value: 'Maysternya', label: 'Майстерня чохлів' }
];

export enum IdentityRoleEnum {
    Boss = 1,
    Coordinator,
    Sklad,
    Print,
    Partner,
    Manufacture,
    Booker,
    Admin,
    Administrator,
    User,
    Lasercut,
    Maysternya
}

export const GetIdentityRoleName = (value: IdentityRoleEnum) => {
    switch (value) {
        case IdentityRoleEnum.Boss:
            return 'Boss';
        case IdentityRoleEnum.Coordinator:
            return 'Coordinator';
        case IdentityRoleEnum.Sklad:
            return 'Sklad';
        case IdentityRoleEnum.Print:
            return 'Print';
        case IdentityRoleEnum.Partner:
            return 'Partner';
        case IdentityRoleEnum.Manufacture:
            return 'Manufacture';
        case IdentityRoleEnum.Booker:
            return 'Booker';
        case IdentityRoleEnum.Admin:
            return 'Admin';
        case IdentityRoleEnum.Administrator:
            return 'Administrator';
        case IdentityRoleEnum.User:
            return 'User';
        case IdentityRoleEnum.Lasercut:
            return 'Lasercut';
        case IdentityRoleEnum.Maysternya:
            return 'Maysternya';
    }
}

export const GetIdentityRoleByName = (value: string) => {
    if (value === 'Boss')
        return IdentityRoleEnum.Boss;

    if (value === 'Coordinator')
        return IdentityRoleEnum.Coordinator;

    if (value === 'Sklad')
        return IdentityRoleEnum.Sklad;

    if (value === 'Print')
        return IdentityRoleEnum.Print;

    if (value === 'Partner')
        return IdentityRoleEnum.Partner;

    if (value === 'Manufacture')
        return IdentityRoleEnum.Manufacture;

    if (value === 'Booker')
        return IdentityRoleEnum.Booker;

    if (value === 'Admin')
        return IdentityRoleEnum.Admin;

    if (value === 'Administrator')
        return IdentityRoleEnum.Administrator;

    if (value === 'User')
        return IdentityRoleEnum.User;

    if (value === 'Lasercut')
        return IdentityRoleEnum.Lasercut;

    if (value === 'Maysternya')
        return IdentityRoleEnum.Maysternya;
}

export const AllIdentityRolesExept = (roles: IdentityRoleEnum[]) => [
    IdentityRoleEnum.Boss,
    IdentityRoleEnum.Coordinator,
    IdentityRoleEnum.Sklad,
    IdentityRoleEnum.Print,
    IdentityRoleEnum.Partner,
    IdentityRoleEnum.Manufacture,
    IdentityRoleEnum.Booker,
    IdentityRoleEnum.Admin,
    IdentityRoleEnum.Administrator,
    IdentityRoleEnum.User,
    IdentityRoleEnum.Lasercut,
    IdentityRoleEnum.Maysternya].filter((item) => roles.indexOf(item) === -1);