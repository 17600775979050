import * as React from "react";
import { Control, Errors } from "react-redux-form";
import { CustomErrors } from "../../shared/form.controls/textbox";

export interface IRegistrationFormCheckbox {
    text: string;
    name?: string;
    onChangeCheckboxValue?: any;
    readOnly?: boolean;
    checked?: boolean;
    model: string;
    Validators?: any;
    ValidatedOn?: any;
    ErrorMessages?: any;
    ShowErrorOn?: any;
}

export const RegistrationFormCheckbox = (props: IRegistrationFormCheckbox) => {
    const CheckboxChecked = (event) => {
        if (props.onChangeCheckboxValue)
            props.onChangeCheckboxValue(event);
    };
    return (
        <label className="checkbox_control">
             <Errors
                show={props.ShowErrorOn}
                model={props.model}
                wrapper={CustomErrors}
                messages={props.ErrorMessages}
            />
            <Control.checkbox
                validators={props.Validators}
                validateOn={props.ValidatedOn}
                model={props.model}
                disabled={props.readOnly}
            />
            <div className="checkbox_icon"></div>
            {props.text}
        </label>
    );
};