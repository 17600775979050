import { EntityBase } from "./entity.base";
import { OrderProduct } from "./order.product";
import { Client } from "./client";
import { DeliveryType } from "../helpers/enums/delivery.type";
import { PaymentMethod } from "../helpers/enums/payment.method";
import { PartnerPriceLevelEnum } from "../helpers/enums/partner.price.level.enum";
import { OrderTypeEnum } from "../helpers/enums/order.type.enum";
import { OrderStatus } from "./order.status";
import { PaymentStatus } from "./payment.status";
import { ConfirmPaymentStatus } from "./confirm.payment.status";
import { StockStatus } from "./stock.status";
import { PrintStatus } from "./print.status";
import { ProductionStatus } from "./production.status";
import Partner from "./partner";
import { Promocode } from "./promocode";

export class Order extends EntityBase {
    public OrderCode: string;
    public TotalAmount: number = 0;
    public PartnerTotalAmount: number = 0;
    public ClientId: number;

    public RecipientPhoneNumber: string;
    public RecipientName: string;

    public DeliveryType: DeliveryType = DeliveryType.Nova;
    public PaymentMethod: PaymentMethod = PaymentMethod.Prepaid;

    public PartnerPriceLevel: PartnerPriceLevelEnum;

    public OrderType: OrderTypeEnum;

    public NovaPoshtaCityRef: string;

    public NovaPoshtaCityName: string;

    public NovaPoshtaWarehouseRef: string;

    public NovaPoshtaWarehouseName: string;

    public UkrPoshtaCityName: string;

    public UkrPoshtaStreetName: string;

    public UkrPoshtaBuildingNumber: string;

    public UkrPoshtaFlatNumber: string;

    public UkrPoshtaZipNumber: string;

    public LastWaitingDaysUpdated: Date;

    public TTN: string;

    public WaitingDays: number;

    public AssemblingCell: number;

    public PrintOperatorComment: string;

    public PrintComment: string;

    public ProductionComment: string;

    public ProductionOperatorComment: string;

    public GlobalComment: string = '';

    public OrderProducts: OrderProduct[] = [];

    public Client: Client;

    public OrderStatus: OrderStatus[] = [];

    public StockStatus: StockStatus[] = [];

    public PrintStatus: PrintStatus[] = [];

    public ProductionStatus: ProductionStatus[] = [];

    public LaserStatus: LaserStatus[] = [];

    public AssemlingStatus: StockStatus;

    public ConfirmStatus: OrderStatus;

    public SendedStatus: OrderStatus;

    public Promocodes: Promocode[];

    public Partner: Partner;

    public UkrPoshtaShipmentId: string;

    public PromoCode?: string[];

    public TypePromoCode?: any;

    //UI
    public IsSelected: boolean = false;
}