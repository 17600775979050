import * as API from '../../../../../constants/api.constants';
import * as React from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import {
    ChangeCantReuseQuantity,
    ChangePrintComment,
    ChangePrintReuseStatusMulti,
    ChangeUserImageComment,
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    ProcessFailMulti,
    RemoveFileFromOrderProduct,
    SetFailProcessing
} from '../../../../../actions/sales/order.card.actions';
import { connect } from 'react-redux';
import { List } from 'linqts';
import { OrderCardState } from '../../../../../states/sales/order.card.state';
import { OrderProduct } from '../../../../../business.entities/order.product';
import { default as OrderProductCommon } from '../../../../shared/right.view/order.product.common';
import { OrderProductFileTypeEnum } from '../../../../../helpers/enums/order.productfile.type.enum';
import { PrintStatusEnum, GetPrintStatusName } from '../../../../../helpers/enums/print.status.enum';
import { ProductPrintStatus } from '../../../../../business.entities/product.print.status';
import { warning } from 'react-notification-system-redux';
import { GetIdentityRoleName, AllIdentityRolesExept } from '../../../../../helpers/identity.roles';
import PermissionChecker from '../../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../../helpers/identity.roles';
import * as moment from 'moment';
import { Loader } from '../../../../shared/loader';
import { OrderStatusEnum } from '../../../../../helpers/enums/order.status.enum';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    UploadFilesForOrderProduct,
    ChangePrintReuseStatusMulti,
    warning,
    ChangeCantReuseQuantity,
    ProcessFailMulti,
    RemoveFileFromOrderProduct,
    SetFailProcessing
}, dispatch);

const PrintInfoRv = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;
    const orderProduct: OrderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === orderCard.selectedOrderProductId);

    const IsActionAllowed = () => {
        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderCard.order.AssemlingStatus)
                return true;

            if (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status === OrderStatusEnum.Recived)
                return true;

            if (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
                return true;
        }

        return false;
    }

    // const IsActionAllowed = () => {
    //     if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
    //     ||props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
    //     ||props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator) )
    //         return true;
    //     else {
    //         if (!orderCard.order.AssemlingStatus)
    //             return true;
    //     }

    //     return false;
    // }

    const OnSelectPrintStatusCommand = (status, id) => {
       if(!IsActionAllowed())
            return;

        // if(orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
        //     return;

        let newStatus: ProductPrintStatus = new ProductPrintStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = orderProduct.Id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductPrintStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnSelectVerifyPrintStatusCommand = (status, id) => {
        const orderProductToUpdate = Object.assign({}, orderProduct, {
            PrintVerificationStatus: status.value
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnSelectReuseOptionCommand = (status, id) => {
        if (orderProduct.Quantity == 1) {
            const orderProductToUpdate = Object.assign({}, orderProduct, {
                CanBeReused: status.value
            } as OrderProduct);

            props.UpdateOrderProduct(orderProductToUpdate);
        } else {
            if (status.value == false) {
                const index = orderCard.order.OrderProducts.indexOf(orderProduct);
                props.ChangePrintReuseStatusMulti(status, index);
                props.warning({
                    title: 'ВНИМАНИЕ!',
                    message: 'После сохранения количества основ, которые не подлежат повторному использованию ОПЕРАЦИЮ НЕ МОЖНО БУДЕТЬ ОТМЕНИТЬ!',
                    position: 'br',
                    autoDismiss: 10,
                });
            } else {
                const orderProductToUpdate = Object.assign({}, orderProduct, {
                    CanBeReused: status.value === undefined ? null : status.value
                } as OrderProduct);

                props.UpdateOrderProduct(orderProductToUpdate);
            }
        }
    }

    const OnChangeCantReuseQuantityCommand = (value) => {
        if (value >= 1 && value <= orderProduct.Quantity)
            props.ChangeCantReuseQuantity(value);
    }

    const OnProcessFailMultiCommand = () => {
        const orderProductToUpdate = Object.assign({}, orderProduct, {
            CanBeReused: orderProduct.SelectedReuseOption.value
        } as OrderProduct);

        props.ProcessFailMulti(orderProductToUpdate, orderCard.cantReuseQuantity);
        props.SetFailProcessing(false);
    }

    const OnStopProccessingFailMultipleCommand = () => {
        props.SetFailProcessing(false);
    }

    const OnSaveReuseStatusMulti = () => {
        if (!orderCard.isFailProcessing) {
            props.SetFailProcessing(true);

            props.warning({
                title: 'ВНИМАНИЕ!',
                message: 'ОПЕРАЦИЮ НЕ МОЖНО БУДЕТЬ ОТМЕНИТЬ!',
                position: 'br',
                autoDismiss: 0,
                onRemove: () => OnStopProccessingFailMultipleCommand(),
                action: {
                    label: 'Сохранить',
                    callback: () => OnProcessFailMultiCommand()
                }
            });
        }
    }

    const OnFileUpload = (event, type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        const files = Array.from(event.target.files)
        let formData = new FormData();
        formData.append('files', files[0] as any, (files[0] as any).name);
        props.UploadFilesForOrderProduct(formData, orderProduct.NetUid, orderCard.order.NetUid, type);
    }

    const ChangeUserImageComment = (value, productId) => {
        const orderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = orderCard.order.OrderProducts.indexOf(orderProduct);

        props.ChangeUserImageComment(value, index, productId);
    }

    const ChangePrintComment = (value, productId) => {
        const orderProduct = new List<OrderProduct>(orderCard.order.OrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = orderCard.order.OrderProducts.indexOf(orderProduct);

        props.ChangePrintComment(value, index, productId);
    }

    const OnRemoveFileCommand = (type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        if(props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print))
            return;

        props.RemoveFileFromOrderProduct(id, type);
    }

    const GetPrintStatusesForPartner = (status) => {
        if (status === PrintStatusEnum.Finished)
            return GetPrintStatusName(PrintStatusEnum.Finished);
        else
            // return GetPrintStatusName(PrintStatusEnum.ReadyForPrinting);
            return '';
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    return (
        <div className="printRVContainer">
            <div className="currentOrderProducts__item">
                <OrderProductCommon product={orderProduct} />

                <div className="">
                    <PermissionChecker
                        Classes='pictureCol'
                        EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                        <div className="previewLabelContainer">Превью</div>
                        <div className="pictureContainer"
                            style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserPrewiev})` }}>
                            {!orderProduct.UserPrewiev ?
                                <label className="onMiniFileUploadControl mTop">
                                    <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Preview, orderProduct.Id)} />
                                </label> :
                                <>
                                    <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Preview, orderProduct.NetUid)} />
                                    <a className="" href={`${API.SERVER_URL}${orderProduct.UserPrewiev}`} download />
                                </>
                            }
                        </div>
                    </PermissionChecker>

                    <PermissionChecker
                        Classes='pictureCol'
                        EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                        <div className="previewLabelContainer">Картинка/Архив</div>
                        <div className={`pictureContainer ${IsZipFile(orderProduct.UserImage) ? 'zipp' : ''}`}
                            style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.UserImage})` }}>
                            {!orderProduct.UserImage ?
                                <label className="onMiniFileUploadControl mTop">
                                    <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.UserImage, orderProduct.Id)} />
                                </label> :
                                <>
                                    <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.UserImage, orderProduct.NetUid)} />
                                    <a className="" href={`${API.SERVER_URL}${orderProduct.UserImage}`} download />
                                </>
                            }
                        </div>
                    </PermissionChecker>

                    <PermissionChecker
                        Classes='pictureCol'
                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                        {orderProduct.ProductPrintStatus.length > 0 &&
                            (orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter) ?
                            <>
                                <div className="previewLabelContainer">Брак</div>
                                <div className="pictureContainer"
                                    style={{ backgroundImage: `url(${API.SERVER_URL}${orderProduct.FailImage})` }}>
                                    {!orderProduct.FailImage ?
                                        <label className="onMiniFileUploadControl mTop">
                                            <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Fail, orderProduct.Id)} />
                                        </label> :
                                        <>
                                            <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Fail, orderProduct.NetUid)} />
                                            <a className="" href={`${API.SERVER_URL}${orderProduct.FailImage}`} download />
                                        </>
                                    }
                                </div>
                            </>
                            :
                            null
                        }
                    </PermissionChecker>

                </div>


                {orderProduct.ProductPrintStatus.length > 0 ?
                    <div>

                        <PermissionChecker
                            Classes='defaultFormControl'
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Print]}>
                            <div className="defaultFormControl__label">Статус печати</div>

                            {props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                                <div className="defaultFormControl__value">
                                    <div className="imitation_input">
                                        {orderProduct.ProductPrintStatus.length > 0 ?
                                            GetPrintStatusesForPartner(orderProduct.ProductPrintStatus[0].Status) :
                                            ''}
                                    </div>
                                </div>
                                :
                                <div className="selectWrapper__container">
                                    <Select
                                        name="printStatus"
                                        isDisabled={orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.WaitingForAssembling || orderProduct.SelectedReuseOption.value === false || (orderCard.order.OrderStatus.length > 0 && orderCard.order.OrderStatus[0].Status !== OrderStatusEnum.Confirmed)}
                                        openMenuOnFocus={true}
                                        options={orderCard.printStatusList}
                                        onChange={(value) => OnSelectPrintStatusCommand(value, orderProduct.Id)}
                                        value={orderProduct.SelectedPrintStatus}
                                        placeholder="Статус печати" />
                                </div>
                            }
                        </PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormControl'
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}
                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner, IdentityRoleEnum.Maysternya])}>
                            <div className="defaultFormControl__label">Проверка печати</div>
                            <div className="selectWrapper__container">
                                <Select
                                    name="printVerifyStatus"
                                    isDisabled={orderProduct.SelectedReuseOption.value === false}
                                    openMenuOnFocus={true}
                                    options={orderCard.printVerifyStatusList}
                                    onChange={(value) => OnSelectVerifyPrintStatusCommand(value, orderProduct.Id)}
                                    value={orderProduct.SelectedVerificationStatus}
                                    placeholder="Проверка печати" />
                            </div>
                        </PermissionChecker>

                        <PermissionChecker
                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Print]}
                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                            {
                                orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                    orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter ?
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Использовать повторно</div>
                                        <div className="selectWrapper__container">
                                            <Select
                                                name="reuse"
                                                isDisabled={orderProduct.SelectedReuseOption.value === false}
                                                openMenuOnFocus={true}
                                                options={orderCard.reuseStatusList}
                                                onChange={(value) => OnSelectReuseOptionCommand(value, orderProduct.Id)}
                                                value={orderProduct.SelectedReuseOption}
                                                placeholder="Использовать повторно" />
                                        </div>
                                    </div> :
                                    null
                            }
                        </PermissionChecker>

                        {orderProduct.Quantity > 1 && orderProduct.SelectedReuseOption.value === false ?
                            <div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormControl__label">Не возможно использовать </div>
                                    <div className="defaultFormControl__value">
                                        <input type="number"
                                            value={orderCard.cantReuseQuantity}
                                            onChange={(event) => OnChangeCantReuseQuantityCommand(event.target.value)} />
                                    </div>
                                </div>
                                <div className="defaultFormControl">
                                    <div className="defaultFormButton" onClick={OnSaveReuseStatusMulti}>
                                        Сохранить
                                </div>
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    : null
                }

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishPrintStatus ?
                        <>
                            <div className="defaultFormControl__label">Дата печати</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {moment(orderProduct.FinishPrintStatus.Created).format('DD/MM/YYYY HH:mm')}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    {orderProduct.FinishPrintStatus ?
                        <>
                            <div className="defaultFormControl__label">Печатал</div>
                            <div className="defaultFormControl__value">
                                <div className="imitation_input">
                                    {orderProduct.FinishPrintStatus.Partner.ContactName}
                                </div>
                            </div>
                        </>
                        : null}
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator]}>
                    <div className="defaultFormControl__label">Комментарий заказчика</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderProduct.UserImageComment ? orderProduct.UserImageComment : ''}
                            onChange={(event) => ChangeUserImageComment(event.target.value, orderProduct.Id)} />

                        {
                            orderCard.fetchInProgress ?
                                <Loader /> : null
                        }
                    </div>
                </PermissionChecker>

                <PermissionChecker
                    Classes='defaultFormControl'
                    EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Print]}
                    ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                    <div className="defaultFormControl__label">Комментарий печатника</div>
                    <div className="defaultFormControlTextatea__value">
                        <textarea value={orderProduct.PrintComment ? orderProduct.PrintComment : ''}
                            onChange={(event) => ChangePrintComment(event.target.value, orderProduct.Id)} />

                        {
                            orderCard.fetchInProgress ?
                                <Loader /> : null
                        }
                    </div>
                </PermissionChecker>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PrintInfoRv);