import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { MenuProvider } from '../../../helpers/menu.provider';
import { ApplicationState } from '../../../Reducers/index';
import { LoginState } from '../../../states/login.state';
import { RightViewState } from '../../../states/right.view.state';
import { NotFound } from '../../component.root';
import PartnerAccountsComponent from '../partners/partner.accounts/accounts';
import {default as PartnerPromocodesComponent} from './partner.promocodes/promocodes';

namespace PartnersComponent {
    export interface IProps {
        RightViewData: RightViewState;
        MenuProvider: MenuProvider
        history: any;
        match: any;
        authState: LoginState;
    }
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        authState: state.authentication,
    };
}

class PartnersComponent extends React.Component<PartnersComponent.IProps, any>{
    static defaultProps = {
        MenuProvider: new MenuProvider()
    };

    render() {
        const startPath = this.props.MenuProvider.GetFirstAllowedRoutesForRole(this.props.authState.Role, "partners")
        const currentLocation = this.props.history.location.pathname;
        return (
            <div>
                <Switch>
                    {currentLocation === this.props.match.url ? 
                        <Redirect from={`${this.props.match.url}`} to={`/${startPath}`} /> : null
                    } 
                    <Route exact path={`${this.props.match.url}/accounts`} component={PartnerAccountsComponent} />
                    <Route path={`${this.props.match.url}/promocodes`} component={PartnerPromocodesComponent} />

                    <Route component={NotFound}></Route>
                </ Switch>
            </div>
        );
    }
}
export default connect(mapStateToProps, null)(PartnersComponent);