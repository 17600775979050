export const GET_NAME = '[API] PRINTERS.GET_NAME';
export interface IGetName { type: '[API] PRINTERS.GET_NAME', payload: any }
export const GetName = (value: string) =>
    <IGetName>{ type: GET_NAME, payload: value };

export const SET_NAME = 'PRINTERS.SET_NAME';
export interface ISetName { type: 'PRINTERS.SET_NAME', payload: any }
export const SetName = value =>
    <ISetName>({ type: SET_NAME, payload: value });


export const SET_PRINTERLIST = 'PRINTERS.SET_PRINTERLIST';
export interface ISetPrintersList { type: 'PRINTERS.SET_PRINTERLIST', payload: any }
export const SetPrintersList = value => <ISetPrintersList>({ type: SET_PRINTERLIST, payload: value });

export const GET_CHOICE_OF_CHANGE_TYPE = '[API] PRINTERS.GET_CHOICE_OF_CHANGE_TYPE';
export interface IGetChoiceOfChangeType { type: '[API] PRINTERS.GET_CHOICE_OF_CHANGE_TYPE', payload: any }
export const GetChoiceOfChangeType = (value: string) =>
    <IGetChoiceOfChangeType>{ type: GET_CHOICE_OF_CHANGE_TYPE, payload: value };

export const SET_CHOICE_OF_CHANGE_TYPE = 'PRINTERS.SET_CHOICE_OF_CHANGE_TYPE';
export interface ISetChoiceOfChangeType { type: 'PRINTERS.SET_CHOICE_OF_CHANGE_TYPE', payload: any }
export const SetChoiceOfChangeType = value => <ISetChoiceOfChangeType>({ type: SET_CHOICE_OF_CHANGE_TYPE, payload: value });

export const GET_SELECTION_OF_PRODUCT_TYPE = '[API] PRINTERS.GET_SELECTION_OF_PRODUCT_TYPE';
export interface IGetSelectionOfProductType { type: '[API] PRINTERS.GET_SELECTION_OF_PRODUCT_TYPE', payload: any }
export const GetSelectionOfProductType = (value: string) =>
    <IGetSelectionOfProductType>{ type: GET_SELECTION_OF_PRODUCT_TYPE, payload: value };

export const SET_SELECTION_OF_PRODUCT_TYPE = 'PRINTERS.SET_SELECTION_OF_PRODUCT_TYPE';
export interface ISetSelectionOfProductType { type: 'PRINTERS.SET_SELECTION_OF_PRODUCT_TYPE', payload: any }
export const SetSelectionOfProductType = value =>
    <ISetSelectionOfProductType>({ type: SET_SELECTION_OF_PRODUCT_TYPE, payload: value });

export const GET_CHOICE_OF_URGENCY = '[API] PRINTERS.GET_CHOICE_OF_URGENCY';
export interface IGetChoiceOfUrgency { type: '[API] PRINTERS.GET_CHOICE_OF_URGENCY', payload: any }
export const GetChoiceOfUrgency = (value: string) =>
    <IGetChoiceOfUrgency>{ type: GET_CHOICE_OF_URGENCY, payload: value };

export const SET_CHOICE_OF_URGENCY = 'PRINTERS.SET_CHOICE_OF_URGENCY';
export interface ISetChoiceOfUrgency { type: 'PRINTERS.SET_CHOICE_OF_URGENCY', payload: any }
export const SetChoiceOfUrgency = value =>
    <ISetChoiceOfUrgency>({ type: SET_CHOICE_OF_URGENCY, payload: value });

export const GET_LEXICON_SELECTION = '[API] PRINTERS.GET_LEXICON_SELECTION';
export interface IGetLexiconSelection { type: '[API] PRINTERS.GET_LEXICON_SELECTION', payload: any }
export const GetLexiconSelection = (value: string) =>
    <IGetLexiconSelection>{ type: GET_LEXICON_SELECTION, payload: value };

export const SET_LEXICON_SELECTION = 'PRINTERS.SET_LEXICON_SELECTION';
export interface ISetLexiconSelection { type: 'PRINTERS.SET_LEXICON_SELECTION', payload: any }
export const SetLexiconSelection = value =>
    <ISetLexiconSelection>({ type: SET_LEXICON_SELECTION, payload: value });

export const GET_SELECTION_OF_ADDITIONAL_SERVICE = '[API] PRINTERS.GET_SELECTION_OF_ADDITIONAL_SERVICE';
export interface IGetSelectionOfAdditionalServices { type: '[API] PRINTERS.GET_SELECTION_OF_ADDITIONAL_SERVICE', payload: any }
export const GetSelectionOfAdditionalServices = (value: string) =>
    <IGetSelectionOfAdditionalServices>{ type: GET_SELECTION_OF_ADDITIONAL_SERVICE, payload: value };

export const SET_SELECTION_OF_ADDITIONAL_SERVICE = 'PRINTERS.SET_SELECTION_OF_ADDITIONAL_SERVICE';
export interface ISetSelectionOfAdditionalServices { type: 'PRINTERS.SET_SELECTION_OF_ADDITIONAL_SERVICE', payload: any }
export const SetSelectionOfAdditionalServices = value => <ISetSelectionOfAdditionalServices>({ type: SET_SELECTION_OF_ADDITIONAL_SERVICE, payload: value });

export const ADD_NEW_PRINTER = '[API] PRINTERS.ADD_NEW_PRINTER';
export interface IAddNewPrinter { type: '[API] PRINTERS.ADD_NEW_PRINTER', payload: any }
export const AddNewPrinter = (value) =>
    <IAddNewPrinter>({ type: ADD_NEW_PRINTER, payload: value });

export const SET_NEW_PRINTER = 'PRINTERS.SET_NEW_PRINTER';
export interface ISetNewPrinter { type: 'PRINTERS.SET_NEW_PRINTER', payload: any }
export const SetNewPrinter = (value) =>
    <ISetNewPrinter>({ type: SET_NEW_PRINTER, payload: value });

export const GET_PRINTERS = '[API] PRINTERS.GET_PRINTERS';
export interface IGetPrinters { type: '[API] PRINTERS.GET_PRINTERS', payload: any }
export const GetPrinters = (value: string) =>
    <IGetPrinters>{ type: GET_PRINTERS, payload: value };

export const SET_PRINTERS = 'PRINTERS.SET_PRINTERS';
export interface ISetPrinters { type: 'PRINTERS.SET_PRINTERS', payload: any }
export const SetPrinters = value =>
    <ISetPrinters>({ type: SET_PRINTERS, payload: value });

export const REMOVE_PRINTER = '[API] PRINTERS.REMOVE_PRINTER';
export interface IRemovePrinter { type: '[API] PRINTERS.REMOVE_PRINTER', payload: any }
export const RemovePrinter = (value: string) =>
    <IRemovePrinter>{ type: REMOVE_PRINTER, payload: value };



export type PrinterKnownActions =
    IGetName | ISetName | IGetChoiceOfChangeType | ISetChoiceOfChangeType | IGetSelectionOfProductType | ISetSelectionOfProductType | IGetChoiceOfUrgency | ISetChoiceOfUrgency |
    IGetLexiconSelection | ISetLexiconSelection | IGetSelectionOfAdditionalServices | ISetSelectionOfAdditionalServices | ISetPrintersList | ISetNewPrinter |
    ISetPrinters;