import * as PropTypes from 'prop-types';
import * as React from 'react';
import { AuthenticationLayout } from './components/authentication/authentication.layout';
import Dashboard from './components/dashboard/dashboard.layout';
import NotificationRoot from './components/notification/notification.root';
import { Route, Switch, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import { LandingOne } from './components/lendings/lending1';
import { LandingTwo } from './components/lendings/lending2';
import { LandingThree } from './components/lendings/lending3';
export type PrivateRouteProps = typeof React.Component & boolean & any;

export const PrivateRoute = (props: PrivateRouteProps) => {
    if (props.authenticated === true) {
        return (<Route path={props.path} component={props.component} />);
    } else {
        return (<Redirect from={props.path} to='/dropshipping_chehly' />);
    }
};

export class Routing extends React.Component<any, any>{
    static contextTypes = {
        store: PropTypes.object
    }
    render() {
        const isAuthenticated = this.context.store.getState().authentication.isAuthenticated;
        return (
            <div>
                <Switch>
                    <Redirect exact from="/auth" to="/auth/login" />

                    <Route exact path="/dropshipping_chehly" component={LandingOne}></Route >
                    <Route exact path="/chehly_optom" component={LandingTwo}></Route >
                    <Route exact path="/lending3" component={LandingThree}></Route >

                    <Route path="/auth" component={AuthenticationLayout}></Route >
                    <PrivateRoute path="/" authenticated={isAuthenticated} component={Dashboard}></PrivateRoute>
                </Switch>
                <NotificationRoot />
            </div>
        )
    }
}

export default hot(module)(Routing)
