import * as React from "react";
import { Form, Errors, actions } from "react-redux-form";
import { TextBox } from "../../../shared/form.controls/textbox";
import { Validator, MaxLength } from "../../../../helpers/validator.helper";
import { RegistrationFormCheckbox } from "../../../authentication/common/registration.form.checkbox";
import { Partner } from "../../../../business.entities/partner";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RemoveRightViewData } from "../../../../actions/cabinet/cabinet.actions";
import { ChangeClientInfo } from "../../../../actions/cabinet/personal.info.actions";

export interface IEditPersonalClientInfoProps {
    newPartner: Partner;
    partnerFormReducer: any;
    ResetForm: any;
    ChangeClientInfo: any;
}

function mapStateToProps(state) {
    return {
        newPartner: state.partnerForm,
        partnerFormReducer: state.partnerFormReducer
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ResetForm: actions.reset,
        ChangeClientInfo,
    }, dispatch);
}

class EditPersonalClientInfo extends React.Component<IEditPersonalClientInfoProps, any> {
    constructor(props) {
        super(props);

        this.onChangePersonalClientInfo = this.onChangePersonalClientInfo.bind(this);
    }

    componentWillUnmount() {
        // this.props.ResetForm("partnerForm");
    }

    private SellTypeValidation(): boolean {
        if (this.props.newPartner.Vkontakte ||
            this.props.newPartner.Instagram ||
            this.props.newPartner.LocalShop ||
            this.props.newPartner.OnlineShop ||
            // this.props.newPartner.OfflineShop ||
            this.props.newPartner.Other) {

            return true;
        } else {

            return false;
        }
    }

    private InterestProduct(): boolean {
        if (this.props.newPartner.SiliconeCases ||
            this.props.newPartner.LeatherCase ||
            this.props.newPartner.PrintSiliconeCases ||
            this.props.newPartner.GlassAndFilm) {

            return true;
        } else {

            return false;
        }
    }

    private onChangePersonalClientInfo() {
        this.props.ChangeClientInfo(this.props.newPartner);
    }

    render() {
        return (
            <Form
                validateOn='change'
                model="partnerForm"
                validators={{
                    '': {
                        isAnyProdSelected: (vals) => this.InterestProduct(),
                        isAnySaleTypeSelected: (vals) => this.SellTypeValidation()
                    }
                }}
                onSubmit={this.onChangePersonalClientInfo}>
                <div className="edit_personal_information_component default_form_component shadow_controls">
                    <div className="two_col_list">

                        <div className="defaultFormControl">
                            <div className="control_title">{'Название организации'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    IsMaxLength: MaxLength(50)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    IsMaxLength: 'Слишком длинное название',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.OrganizationName'
                            />
                        </div>

                        <div className="defaultFormControl">
                            <div className="control_title">{'Город'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    IsCityAddress: (val: string) => Validator.IsCityAddressMatchToPattern(val),
                                    IsMaxLength: MaxLength(120)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    IsCityAddress: 'Не валидный адрес города',
                                    IsMaxLength: 'Слишком длинное название',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.OrganizationCity'
                            />
                        </div>

                        <div className="defaultFormControl">
                            <div className="control_title">{'Ссылка на сайт или соцсеть'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    isUrl: (val: string) => Validator.IsUrl(val),
                                    IsMaxLength: MaxLength(200)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    isUrl: 'Не валидний адрес',
                                    IsMaxLength: 'TooManyCharacters',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.PageUrl'
                            />
                        </div>

                        <div className="defaultFormControl">
                            <div className="control_title">{'Имя и фамилия'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    isCyrylic: (val: string) => Validator.IsCyrylicLetters(val),
                                    IsMaxLength: MaxLength(200)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    isCyrylic: 'Поле должно содержать только кириллицу',
                                    IsMaxLength: 'TooManyCharacters',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.ContactName'
                            />
                        </div>
                        <div className="defaultFormControl">
                            <div className="control_title">{'Контактный телефон'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    isPhone: (val: string) => Validator.IsPhone(val),
                                    IsMaxLength: MaxLength(10)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    isPhone: 'Не валидный номер телефона',
                                    IsMaxLength: 'TooManyCharacters',
                                }}
                                MaxLength={10}
                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.ContactPhone'
                            />
                        </div>
                        <div className="defaultFormControl">
                            <div className="control_title">{'Email'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    IsEmail: (val: string) => Validator.IsEmail(val),
                                    IsMaxLength: MaxLength(200)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    IsMaxLength: 'TooManyCharacters',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.ContactEmail'
                            />
                        </div>

                        <div className="defaultFormControl">
                            <div className="control_title">{'Имя и фамилия руководителя'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    isCyrylic: (val: string) => Validator.IsCyrylicLetters(val),
                                    IsMaxLength: MaxLength(200)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    isCyrylic: 'Поле должно содержать только кириллицу',
                                    IsMaxLength: 'TooManyCharacters',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.DirectorsName'
                            />
                        </div>
                        <div className="defaultFormControl">
                            <div className="control_title">{'Контактный телефон руководителя'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    isPhone: (val: string) => Validator.IsPhone(val),
                                    IsMaxLength: MaxLength(10)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    isPhone: 'Не валидный номер телефона',
                                    IsMaxLength: 'TooManyCharacters',
                                }}
                                MaxLength={10}
                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.DirectorsPhone'
                            />
                        </div>
                        <div className="defaultFormControl edPerClient__def">
                            <div className="control_title">{'Email руководителя'}</div>
                            <TextBox
                                Validators={{
                                    isRequired: (val: string) => !Validator.IsEmpty(val),
                                    IsEmail: (val: string) => Validator.IsEmail(val),
                                    IsMaxLength: MaxLength(200)
                                }}

                                ErrorMessages={{
                                    isRequired: '*',
                                    IsMaxLength: 'TooManyCharacters',
                                }}

                                ShowErrorOn='touched'
                                Type='text'
                                ValidatedOn='change'
                                IsRequired={true}
                                Model='.DirectorsEmail'
                            />
                        </div>

                    </div>

                    <div className="two_col_list last">
                        <div className="registration_form_control">
                            {
                                this.props.partnerFormReducer.$form.errors.isAnySaleTypeSelected ?
                                    <Errors
                                        model="partnerForm"
                                        messages={{
                                            isAnySaleTypeSelected: "Нужно выбрать хоть бы один пункт"
                                        }}
                                    /> : null
                            }
                            <div>
                                <div className="form_title_component">Путь сбыта</div>
                                <RegistrationFormCheckbox
                                    model=".OnlineShop"
                                    text="Интернет-магазин"
                                />
                                <RegistrationFormCheckbox
                                    model=".LocalShop"
                                    text="Локальный магазин"
                                />
                                <RegistrationFormCheckbox
                                    model=".Instagram"
                                    text="Инстаграм"
                                />
                                <RegistrationFormCheckbox
                                    model=".Vkontakte"
                                    text="Вконтакте"
                                />
                                <RegistrationFormCheckbox
                                    model=".Other"
                                    text="Другое"
                                />
                                {/* <RegistrationFormCheckbox
                                    model=".OfflineShop"
                                    text="Офлайн-магазин со стендом"
                                /> */}
                            </div>
                        </div>
                        {
                            this.props.newPartner.Other === true ?
                                <div className="defaultFormControl">
                                    <div className="control_title">{'Путь сбыта'}</div>
                                    <TextBox
                                        Validators={{
                                            isRequired: (val: string) => !Validator.IsEmpty(val),
                                            IsMaxLength: MaxLength(75)
                                        }}

                                        ErrorMessages={{
                                            isRequired: '*',
                                            IsMaxLength: 'TooManyCharacters',
                                        }}

                                        ShowErrorOn='touched'
                                        Type='text'
                                        ValidatedOn='change'
                                        IsRequired={true}
                                        Model='.OtherDescription'
                                    />
                                </div> : null
                        }

                        <div className="registration_form_control">
                            {
                                this.props.partnerFormReducer.$form.errors.isAnyProdSelected ?
                                    <Errors
                                        model="partnerForm"
                                        messages={{
                                            isAnyProdSelected: "Нужно выбрать хоть бы один пункт"
                                        }}
                                    /> : null
                            }

                            <div>
                                <div className="form_title_component">Интересующие товары</div>
                                <RegistrationFormCheckbox
                                    model=".PrintSiliconeCases"
                                    text="Силиконовые чехлы с печатью"
                                />
                                <RegistrationFormCheckbox
                                    model=".LeatherCase"
                                    text="Кожанные чехлы"
                                />
                                <RegistrationFormCheckbox
                                    model=".SiliconeCases"
                                    text="Силиконовые чехлы"
                                />
                                <RegistrationFormCheckbox
                                    model=".GlassAndFilm"
                                    text="Стекло и пленки"
                                />
                            </div>
                        </div>

                        <div>
                            <div className="form_title_component npF">"Нова пошта" АПИ</div>

                            <div className="defaultFormControl">
                                <div className="control_title">Ключ АПИ</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.NovaPoshtaApiKey'
                                />
                            </div>

                            <div className="defaultFormControl">
                                <div className="control_title">Логин</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.NovaPoshtaLogin'
                                />
                            </div>

                            <div className="defaultFormControl">
                                <div className="control_title">Пароль</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.NovaPoshtaPass'
                                />
                            </div>
                        </div>
                        <div>
                            <div className="form_title_component npF">"Укр Пошта" АПИ</div>

                            <div className="defaultFormControl">
                                <div className="control_title">UkrPoshta Api ConterPartyToken</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.UkrPoshtaApiConterPartyToken'
                                />
                            </div>
                            <div className="defaultFormControl">
                                <div className="control_title">UkrPoshta Api Bearer</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.UkrPoshtaApiBearer'
                                />
                            </div>
                        </div>
                        <div>
                            <div className="form_title_component npF">"Укр Пошта" Банковські дані</div>

                            <div className="defaultFormControl">
                                <div className="control_title">IBAN</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.BankAccount'
                                />
                            </div>
                            <div className="defaultFormControl">
                                <div className="control_title">Tin</div>
                                <TextBox
                                    ShowErrorOn='touched'
                                    Type='text'
                                    ValidatedOn='change'
                                    Model='.Tin'
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="defaultFormControl">
                    <div className="rightPullControls2">
                        <button type="submit" className="defaultFormButton mWidth mLpx" >Сохранить изменения</button>
                    </div>
                </div>
            </Form>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalClientInfo);
