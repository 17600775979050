import * as API from '../../constants/api.constants';
import { actions } from 'react-redux-form';
import { error, success } from 'react-notification-system-redux';
import { List } from 'linqts';
import { Observable } from 'rxjs/Observable';
import { Store } from 'redux';
import { ErrorHandler } from '../../helpers/error.manager';

import * as PlatformActions from "../../actions/stock/platform.actions";
import { ApplicationState } from '../../reducers/index';
import { ExportPtatformConfigItem } from '../../business.entities/platformConfigItem';
import { Platform } from '../../business.entities/platform';

import * as PriceListOptionActions from "../../actions/stock/price.list.options.actions";
import storeProvider from '../../helpers/store.provider';

export const GetAllPlatfroms = (action$, store: Store<any>) =>
    action$.ofType(PlatformActions.GET_ALL)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_PLATFORMS}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: 'Платформы получены',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));

                    if (!(storeProvider.getStore().getState() as ApplicationState).stock.priceList.ExportPlatformConfig) {
                        let exportConfig: ExportPtatformConfigItem[] = [];

                        response.Body && response.Body.forEach((element: Platform) => {
                            exportConfig.push({ PlatformNetId: element.NetUid, IsMultiple: false, IsSingle: false } as ExportPtatformConfigItem)
                        });

                        storeProvider.getStore().dispatch(PriceListOptionActions.AddExportPlatform(exportConfig));
                        storeProvider.getStore().dispatch(actions.change('platformConfigForm', exportConfig));
                    }

                    return PlatformActions.SetAll(response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );