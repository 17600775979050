import * as moment from 'moment';

export const CalculateDeliveryDateBody = (key: string, sendDate: any, to: any) => {

    return JSON.stringify({
        apiKey: key,
        modelName: "InternetDocument",
        calledMethod: "getDocumentDeliveryDate",
        methodProperties: {
            DateTime: moment(sendDate).format("DD.MM.YYYY"),
            ServiceType: "WarehouseWarehouse",
            CitySender: "db5c88ac-391c-11dd-90d9-001a92567626",
            CityRecipient: to
        }
    });
}