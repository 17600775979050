import { ProductColor } from "../business.entities/product.color";
import { Product } from "../business.entities/product";

export class HelperFunctions {
    public createNewProductColorArray(index: number, newValue: ProductColor, array: ProductColor[]) {
        return Object.assign([], array, { [index]: newValue });
    }

    public createProductArray(index: number, newValue: Product, array: Product[]) {
        return Object.assign([], array, { [index]: newValue });
    }
}