import * as React from 'react';
import { ApplicationState } from '../../../../reducers/index';
import { bindActionCreators } from 'redux';
import { Category } from '../../../../business.entities/category';
import { connect } from 'react-redux';
import { default as PriceListGrid } from './price.list.grid';
import { GetOptions, GetLexicons } from '../../../../actions/stock/price.list.options.actions';
import { GetPricelist, SelectContent, GetPricelistUrl, UnmountComponent } from '../../../../actions/stock/price.list.actions';
import {
    AddFilter, AddLexiconPrices, AddProductPrices, ChangeAvailableOnly
} from '../../../../actions/stock/price.list.options.actions';
import { LeftMenuItemModel } from '../../../../helpers/left.menu.item.model';
import { Lexicon } from '../../../../business.entities/lexicon';
import { List } from 'linqts';
import { PriceListFilterItem } from '../../../../business.entities/price.list.filter.item';
import PriceListOptions from './price.list.options';
import { PricelistOptions } from '../../../../business.entities/price.list.options';
import { ProductPriceListItem } from '../../../../business.entities/product.price.list.item';
import { Route } from 'react-router';
import { default as PriceListExport } from "./price.list.export";
import * as API from '../../../../constants/api.constants';
import * as moment from 'moment';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';

namespace PriceListComponent {
    export interface IProps {
        Lexicons: Lexicon[];
        Categories: Category[];
        Subcategories: Category[];
        PricelistItems: ProductPriceListItem[];
        PriceListFilter: PriceListFilterItem[];
        Options: PricelistOptions;
        SelectedComponent: any;
        Page: number;
        TotalPages: number;
        Limit: number;
        PriceListUrl: string;
        AvailableOnly: boolean;
        SelectContent(component: any): void;
        GetLexicons(): void;
        GetPricelist(page: number, limit: number): void;
        GetOptions(): void;
        GetPricelistUrl(): void;
        UnmountComponent();
        ChangeAvailableOnly(value: boolean);
        AddFilter(value);
        AddLexiconPrices(value);
        AddProductPrices(value);
    }

    export interface IState {
        leftMenuItems: LeftMenuItemModel[];
    }
}

class PriceListComponent extends React.Component<PriceListComponent.IProps, PriceListComponent.IState> {

    constructor(props) {
        super(props);


        this.state = {
            leftMenuItems: [
                {
                    Id: 1,
                    IsSelected: true,
                    Label: "Прайс-лист",
                    Component: PriceListGrid,
                } as LeftMenuItemModel,
                {
                    Id: 3,
                    IsSelected: false,
                    Label: "Експорт",
                    Component: PriceListExport,
                } as LeftMenuItemModel,
            ]
        }

        this.props.GetLexicons();

        this.props.GetOptions();
        this.props.GetPricelist(1, 40);
    }

    private onOpenFilterPageCommand() {
        let { leftMenuItems } = this.state;

        leftMenuItems.forEach(element => {
            element.IsSelected = false;
        });

        this.setState({ leftMenuItems: leftMenuItems });

        this.props.SelectContent(PriceListOptions);
    }

    private onSelectMenuItemCommand(selectedMenuItem: LeftMenuItemModel) {
        if (!selectedMenuItem.IsSelected) {
            let { leftMenuItems } = this.state;

            leftMenuItems.forEach(element => {
                element.IsSelected = false;
            });

            let element = new List<LeftMenuItemModel>(leftMenuItems)
                .Where(c => c.Id === selectedMenuItem.Id)
                .FirstOrDefault();

            let elementIndex = leftMenuItems.indexOf(element);
            leftMenuItems[elementIndex].IsSelected = true;

            this.setState({ leftMenuItems: leftMenuItems });

            elementIndex == 0 && this.props.GetPricelist(1, this.props.Limit);

            this.props.AddFilter(this.props.Options ? JSON.parse(this.props.Options.Filter) : []);
            this.props.AddLexiconPrices(this.props.Options ? JSON.parse(this.props.Options.LexiconPrices) : []);
            this.props.AddProductPrices(this.props.Options ? JSON.parse(this.props.Options.ProductPrices) : []);
            this.props.ChangeAvailableOnly(this.props.Options ? this.props.Options.AvailableOnly : false);

            this.props.SelectContent(selectedMenuItem.Component);
        }
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    render() {
        return (
            <div className="master_content_component rcPagination__component">
                <LeftMenuMobileControl />
                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        {this.state.leftMenuItems.length > 0 && this.state.leftMenuItems.map((item: LeftMenuItemModel, key: number) =>
                            <div key={key}
                                className={'prices__left_control__component ' + (this.state.leftMenuItems[key].IsSelected ? "is_selected" : "")}
                                onClick={() => this.onSelectMenuItemCommand(this.state.leftMenuItems[key])}>
                                <div className="control__item">
                                    {this.state.leftMenuItems[key].Label}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="content_header_component">
                    <div className="page_title oneTitleContainer">
                        <span className="title_default">
                            {new List(this.state.leftMenuItems).FirstOrDefault(c => c.IsSelected).Label}</span>
                    </div>

                    {this.props.SelectedComponent === PriceListGrid &&
                        <div className="header__button__controls">
                            <details className="dStyle" open>
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>
                                {/* <div className="button__control" onClick={() => this.onOpenFilterPageCommand()}>
                                Фильтрация
                            </div> */}

                                <div className="button__control" onClick={() => this.props.GetPricelistUrl()}>
                                    Сгенерировать прайс
                                </div>
                                {this.props.PriceListUrl &&
                                    <a className="button__control" href={`${API.SERVER_URL}${this.props.PriceListUrl}`}
                                        download={`caseshop_pricelist_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать прайс</a>
                                }
                            </details>

                            <details className="mStyle">
                                <summary>
                                    <div className="summaryFilter" />
                                </summary>

                                <div className="button__control" onClick={() => this.props.GetPricelistUrl()}>
                                    Сгенерировать прайс
                                </div>
                                {this.props.PriceListUrl &&
                                    <a className="button__control" href={`${API.SERVER_URL}${this.props.PriceListUrl}`}
                                        download={`caseshop_pricelist_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать прайс</a>
                                }
                            </details>
                        </div>
                    }

                    {this.props.SelectedComponent === PriceListExport &&
                        <label className="checkMid" >
                            <input type="checkbox" name="available" defaultChecked={this.props.AvailableOnly} onClick={() => { this.props.ChangeAvailableOnly(!this.props.AvailableOnly) }} />
                            <div className="checkControl"></div>

                            Без товаров которых нет в наличии
                        </label>
                    }
                </div>

                <Route path={(this.props as any).history.location.pathName} component={this.props.SelectedComponent} />
            </div>
        );
    }
}
export default connect((state: ApplicationState) => state.stock.priceList,
    (dispatch) => bindActionCreators({
        SelectContent, GetPricelist, GetOptions, GetLexicons, GetPricelistUrl, UnmountComponent, ChangeAvailableOnly, AddFilter, AddLexiconPrices, AddProductPrices
    }, dispatch))
    (PriceListComponent);

