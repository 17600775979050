export enum ConfirmPaymentStatusEnum {
    NotConfirmed = 1,
    Confirmed
}

export const GetConfirmPaymentStatusName = (status) => {
    switch (status) {
        case ConfirmPaymentStatusEnum.NotConfirmed:
            return 'Не подтверждена';
        case ConfirmPaymentStatusEnum.Confirmed:
            return 'Подтверждена';
        default:
            return '';
    }
}