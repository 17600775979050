import { Partner } from "../../business.entities/partner";
import { SelectModel } from "../../helpers/select.model";
import { Category } from "../../business.entities/category";
import { PartnerPrice } from "../../business.entities/partner.price";
import { Product } from "../../business.entities/product";
import { PriceTerm } from "../../business.entities/price.term";
import { PartnerWholesale } from "../../business.entities/partnerWholesale";
import { AuditEntity } from "../../business.entities/audit.entity";

export const GET_PARTNERS_AUTOCOMPLETE: string = '[API] GET_PARTNERS_AUTOCOMPLETE';
export interface IGetPartnersAutocomplete { type: '[API] GET_PARTNERS_AUTOCOMPLETE'; payload: any; }
export const GetPartnersAutocomplete = (value: string) =>
    <IGetPartnersAutocomplete>{ type: GET_PARTNERS_AUTOCOMPLETE, payload: value };

export const SET_PARTNERS_AUTOCOMPLETE: string = 'SET_PARTNERS_AUTOCOMPLETE';
export interface ISetPartnersAutocomplete { type: 'SET_PARTNERS_AUTOCOMPLETE'; payload: any; }
export const SetPartnersAutocomplete = (partners: SelectModel[]) =>
    <ISetPartnersAutocomplete>{ type: SET_PARTNERS_AUTOCOMPLETE, payload: partners };

export const CHANGE_PARTNER_SELECT: string = 'CHANGE_PARTNER_SELECT';
export interface IChangePartnerSelect { type: 'CHANGE_PARTNER_SELECT'; payload: any; }
export const ChangePartnerSelect = (value: SelectModel) =>
    <IChangePartnerSelect>{ type: CHANGE_PARTNER_SELECT, payload: value };

export const SELECT_CONTENT_COMPONENT: string = 'SELECT_CONTENT_COMPONENT';
export interface ISelectContentComponent { type: 'SELECT_CONTENT_COMPONENT'; payload: any; }
export const SelectContentComponent = (component: any) =>
    <ISelectContentComponent>{ type: SELECT_CONTENT_COMPONENT, payload: component };

export const GET_ALL_DEFAULT_PRICES: string = '[API] GET_ALL_DEFAULT_PRICES';
export interface IGetAllDefaultPrices { type: '[API] GET_ALL_DEFAULT_PRICES'; payload: any; }
export const GetAllDefaultPrices = () =>
    <IGetAllDefaultPrices>{ type: GET_ALL_DEFAULT_PRICES };

export const SET_ALL_PRICES: string = 'SET_ALL_PRICES';
export interface ISetAllPrices { type: 'SET_ALL_PRICES'; payload: any; }
export const SetAllPrices = (prices: any[]) =>
    <ISetAllPrices>{ type: SET_ALL_PRICES, payload: prices };

export const GET_PRICE_CATEGORY_SELECT_LIST: string = '[API] GET_PRICE_CATEGORY_SELECT_LIST';
export interface IGetPriceCategorySelectList { type: '[API] GET_PRICE_CATEGORY_SELECT_LIST'; payload: any; }
export const GetPriceCategorySelectList = () =>
    <IGetPriceCategorySelectList>{ type: GET_PRICE_CATEGORY_SELECT_LIST };

export const SET_PRICE_CATEGORY_SELECT_LIST: string = 'SET_PRICE_CATEGORY_SELECT_LIST';
export interface ISetPriceCategorySelectList { type: 'SET_PRICE_CATEGORY_SELECT_LIST'; payload: any; }
export const SetPriceCategorySelectList = (categories: Category[]) =>
    <ISetPriceCategorySelectList>{ type: SET_PRICE_CATEGORY_SELECT_LIST, payload: categories };

export const GET_PRICE_SUBCATEGORY_SELECT_LIST: string = '[API] GET_PRICE_SUBCATEGORY_SELECT_LIST';
export interface IGetPriceSubcategorySelectList { type: '[API] GET_PRICE_SUBCATEGORY_SELECT_LIST'; payload: any; }
export const GetPriceSubcategorySelectList = (categoryName: string, parent: SelectModel) =>
    <IGetPriceSubcategorySelectList>{ type: GET_PRICE_SUBCATEGORY_SELECT_LIST, payload: { categoryName: categoryName, parent: parent } };

export const SET_PRICE_SUBCATEGORY_SELECT_LIST: string = 'SET_PRICE_SUBCATEGORY_SELECT_LIST';
export interface ISetPriceSubcategorySelectList { type: 'SET_PRICE_SUBCATEGORY_SELECT_LIST'; payload: any; }
export const SetPriceSubategorySelectList = (categories: Category[]) =>
    <ISetPriceSubcategorySelectList>{ type: SET_PRICE_SUBCATEGORY_SELECT_LIST, payload: categories };

export const CHANGE_PRICE_SUBCATEGORY: string = 'CHANGE_PRICE_SUBCATEGORY';
export interface IChangePriceSubcategory { type: 'CHANGE_PRICE_SUBCATEGORY'; payload: any; }
export const ChangePriceSubcategory = (subcategory: SelectModel) =>
    <IChangePriceSubcategory>{ type: CHANGE_PRICE_SUBCATEGORY, payload: subcategory };

export const CHANGE_PRICE_CATEGORY: string = 'CHANGE_PRICE_CATEGORY';
export interface IChangePriceCategory { type: 'CHANGE_PRICE_CATEGORY'; payload: any; }
export const ChangePriceCategory = (category: SelectModel) =>
    <IChangePriceCategory>{ type: CHANGE_PRICE_CATEGORY, payload: category };

export const UPDATE_PARTNER_PRICE_SEGMENT: string = '[API] UPDATE_PARTNER_PRICE_SEGMENT';
export interface IUpdatePartnerPriceSegment { type: '[API] UPDATE_PARTNER_PRICE_SEGMENT'; payload: any; }
export const UpdatePartnerPriceSegment = (partnerPrice: PartnerPrice) =>
    <IUpdatePartnerPriceSegment>{ type: UPDATE_PARTNER_PRICE_SEGMENT, payload: partnerPrice };

export const UPDATE_PARTNER_PRICE_SEGMENT_SUCCESS: string = 'UPDATE_PARTNER_PRICE_SEGMENT_SUCCESS';
export interface IUpdatePartnerPriceSegmentSuccess { type: 'UPDATE_PARTNER_PRICE_SEGMENT_SUCCESS'; payload: any; }
export const UpdatePartnerPriceSegmentSuccess = (partnerPriceSegments: PartnerPrice[]) =>
    <IUpdatePartnerPriceSegmentSuccess>{ type: UPDATE_PARTNER_PRICE_SEGMENT_SUCCESS, payload: partnerPriceSegments };

export const GET_PRODUCTS_FOR_PRICES: string = '[API] GET_PRODUCTS_FOR_PRICES';
export interface IGetProductsForPrices { type: '[API] GET_PRODUCTS_FOR_PRICES'; payload: any; }
export const GetProductsForPrices = (categoryId: number, subcategoryId: number) =>
    <IGetProductsForPrices>{ type: GET_PRODUCTS_FOR_PRICES, payload: { categoryId, subcategoryId } };

export const SET_PRODUCTS_FOR_PRICES: string = 'SET_PRODUCTS_FOR_PRICES';
export interface ISetProductsForPrices { type: 'SET_PRODUCTS_FOR_PRICES'; payload: any; }
export const SetProductsForPrices = (products: Product[]) =>
    <ISetProductsForPrices>{ type: SET_PRODUCTS_FOR_PRICES, payload: products };

export const GET_PRICE_SEGMENTS_FOR_PARTNER: string = '[API] GET_PRICE_SEGMENTS_FOR_PARTNER';
export interface IGetPriceSergmentsForPartner { type: '[API] GET_PRICE_SEGMENTS_FOR_PARTNER'; payload: any; }
export const GetPriceSergmentsForPartner = (partnerNetid: string) =>
    <IGetPriceSergmentsForPartner>{ type: GET_PRICE_SEGMENTS_FOR_PARTNER, payload: partnerNetid };

export const CREATE_PARTNER_PRICE_SEGMENT: string = '[API] CREATE_PARTNER_PRICE_SEGMENT';
export interface ICreatePartnerPriceSegment { type: '[API] CREATE_PARTNER_PRICE_SEGMENT'; payload: any; }
export const CreatePartnerPriceSegment = (partnerPrice: PartnerPrice) =>
    <ICreatePartnerPriceSegment>{ type: CREATE_PARTNER_PRICE_SEGMENT, payload: partnerPrice };

export const CREATE_PARTNER_PRICE_SEGMENT_SUCCESS: string = 'CREATE_PARTNER_PRICE_SEGMENT_SUCCESS';
export interface ICreatePartnerPriceSegmentSuccess { type: 'CREATE_PARTNER_PRICE_SEGMENT_SUCCESS'; payload: any; }
export const CreatePartnerPriceSegmentSuccess = (partnerPriceSegments: PartnerPrice[]) =>
    <ICreatePartnerPriceSegmentSuccess>{ type: CREATE_PARTNER_PRICE_SEGMENT_SUCCESS, payload: partnerPriceSegments };

export const CREATE_PARTNER_PRICE_TERMS: string = '[API] CREATE_PARTNER_PRICE_TERMS';
export interface ICreatePartnerPriceTerms { type: '[API] CREATE_PARTNER_PRICE_TERMS'; payload: any; }
export const CreatePartnerPriceTerms = (priceTerm: PriceTerm) =>
    <ICreatePartnerPriceTerms>{ type: CREATE_PARTNER_PRICE_TERMS, payload: priceTerm };

export const CREATE_PARTNER_PRICE_TERMS_SUCCESS: string = 'CREATE_PARTNER_PRICE_TERMS_SUCCESS';
export interface ICreatePartnerPriceTermsSuccess { type: 'CREATE_PARTNER_PRICE_TERMS_SUCCESS'; payload: any; }
export const CreatePartnerPriceTermsSuccess = (priceTerm: PriceTerm) =>
    <ICreatePartnerPriceTermsSuccess>{ type: CREATE_PARTNER_PRICE_TERMS_SUCCESS, payload: priceTerm };

export const UPDATE_PARTNER_PRICE_TERMS: string = '[API] UPDATE_PARTNER_PRICE_TERMS';
export interface IUpdatePartnerPriceTerms { type: '[API] UPDATE_PARTNER_PRICE_TERMS'; payload: any; }
export const UpdatePartnerPriceTerms = (priceTerm: PriceTerm) =>
    <IUpdatePartnerPriceTerms>{ type: UPDATE_PARTNER_PRICE_TERMS, payload: priceTerm };

export const UPDATE_PARTNER_PRICE_TERMS_SUCCESS: string = 'UPDATE_PARTNER_PRICE_TERMS_SUCCESS';
export interface IUpdatePartnerPriceTermsSuccess { type: 'UPDATE_PARTNER_PRICE_TERMS_SUCCESS'; payload: any; }
export const UpdatePartnerPriceTermsSuccess = (priceTerm: PriceTerm) =>
    <IUpdatePartnerPriceTermsSuccess>{ type: UPDATE_PARTNER_PRICE_TERMS_SUCCESS, payload: priceTerm };

export const GET_PRICE_TERMS: string = '[API] GET_PRICE_TERMS';
export interface IGetPriceTerms { type: '[API] GET_PRICE_TERMS'; payload: any; }
export const GetPriceTerms = (partnerNetid: string) =>
    <IGetPriceTerms>{ type: GET_PRICE_TERMS, payload: partnerNetid };

export const SET_TERMS: string = 'SET_TERMS';
export interface ISetTerms { type: 'SET_TERMS'; payload: any; }
export const SetTerms = (priceTerm: PriceTerm) =>
    <ISetTerms>{ type: SET_TERMS, payload: priceTerm };

export const REMOVE_PRICE_SEGMENT: string = '[API] REMOVE_PRICE_SEGMENT';
export interface IRemovePriceSegment { type: '[API] REMOVE_PRICE_SEGMENT'; payload: any; }
export const RemovePriceSegment = (netid: string) =>
    <IRemovePriceSegment>{ type: REMOVE_PRICE_SEGMENT, payload: netid };

export const REMOVE_PRICE_TERM: string = '[API] REMOVE_PRICE_TERM';
export interface IRemovePriceTerm { type: '[API] REMOVE_PRICE_TERM'; payload: any; }
export const RemovePriceTerm = (netid: string) =>
    <IRemovePriceTerm>{ type: REMOVE_PRICE_TERM, payload: netid };

export const GET_WHOLESALE_LIST: string = '[API] GET_WHOLESALE_LIST';
export interface IGetWholesaleList { type: '[API] GET_WHOLESALE_LIST'; payload: any; }
export const GetWholesaleList = (netid: string) =>
    <IGetWholesaleList>{ type: GET_WHOLESALE_LIST, payload: netid };

export const SET_WHOLESALE_LIST: string = 'SET_WHOLESALE_LIST';
export interface ISetWholesaleList { type: 'SET_WHOLESALE_LIST'; payload: any; }
export const SetWholesaleList = (wholesaleList: PartnerWholesale[]) =>
    <ISetWholesaleList>{ type: SET_WHOLESALE_LIST, payload: wholesaleList };

export const REMOVE_WHOLESALE: string = '[API] REMOVE_WHOLESALE';
export interface IRemoveWholesale { type: '[API] REMOVE_WHOLESALE'; payload: any; }
export const RemoveWholesale = (netId: string) =>
    <IRemoveWholesale>{ type: REMOVE_WHOLESALE, payload: netId };

export const UPDATE_WHOLESALE: string = '[API] UPDATE_WHOLESALE';
export interface IUpdateWholesale { type: '[API] UPDATE_WHOLESALE'; payload: any; }
export const UpdateWholesale = (wholesale: PartnerWholesale) =>
    <IUpdateWholesale>{ type: UPDATE_WHOLESALE, payload: wholesale };

export const UPDATE_WHOLESALE_SUCCESS: string = 'UPDATE_WHOLESALE_SUCCESS';
export interface IUpdateWholesaleSuccess { type: 'UPDATE_WHOLESALE_SUCCESS'; payload: any; }
export const UpdateWholesaleSuccess = (wholesaleList: PartnerWholesale[]) =>
    <IUpdateWholesaleSuccess>{ type: UPDATE_WHOLESALE_SUCCESS, payload: wholesaleList };

export const CREATE_WHOLESALE: string = '[API] CREATE_WHOLESALE';
export interface ICreateWholesale { type: '[API] CREATE_WHOLESALE'; payload: any; }
export const CreateWholesale = (wholesale: PartnerWholesale) =>
    <ICreateWholesale>{ type: CREATE_WHOLESALE, payload: wholesale };

export const CREATE_WHOLESALE_SUCCESS: string = 'CREATE_WHOLESALE_SUCCESS';
export interface ICreateWholesaleSuccess { type: 'CREATE_WHOLESALE_SUCCESS'; payload: any; }
export const CreateWholesaleSuccess = (wholesaleList: PartnerWholesale[]) =>
    <ICreateWholesaleSuccess>{ type: CREATE_WHOLESALE_SUCCESS, payload: wholesaleList };

export const GET_PARTNER_PRICE_HISTORY: string = '[API] GET_PARTNER_PRICE_HISTORY';
export interface IGetPartnerHistory { type: '[API] GET_PARTNER_PRICE_HISTORY'; payload: any; }
export const GetPartnerHistory = (partnerNetId: string, entityName: string, dateFrom: any, dateTo: any) =>
    <IGetPartnerHistory>{ type: GET_PARTNER_PRICE_HISTORY, payload: { partnerNetId: partnerNetId, entityName: entityName, dateFrom: dateFrom, dateTo: dateTo } };

export const SET_PARTNER_PRICE_HISTORY: string = 'SET_PARTNER_PRICE_HISTORY';
export interface ISetPartnerHistory { type: 'SET_PARTNER_PRICE_HISTORY'; payload: any; }
export const SetPartnerHistory = (auditList: AuditEntity[]) =>
    <ISetPartnerHistory>{ type: SET_PARTNER_PRICE_HISTORY, payload: auditList };

export const REMOVE_PARTNER_PRICE_HISTORY: string = 'REMOVE_PARTNER_PRICE_HISTORY';
export interface IRemovePartnerHistory { type: 'REMOVE_PARTNER_PRICE_HISTORY'; payload: any; }
export const RemovePartnerHistory = () =>
    <IRemovePartnerHistory>{ type: REMOVE_PARTNER_PRICE_HISTORY };

export const UNMOUNT_PRICE_COMPONENT: string = 'UNMOUNT_PRICE_COMPONENT';
export interface IUnmountPriceComponent { type: 'UNMOUNT_PRICE_COMPONENT'; payload: any; }
export const UnmountPriceComponent = () =>
    <IUnmountPriceComponent>{ type: UNMOUNT_PRICE_COMPONENT };

export const SET_START_DATE: string = 'PRICE.SET_START_DATE';
export interface ISetStartDate { type: 'PRICE.SET_START_DATE'; payload: any; }
export const SetStartDate = (date: Date) =>
    <ISetStartDate>{ type: SET_START_DATE, payload: date };

export const SET_END_DATE: string = 'PRICE.SET_END_DATE';
export interface ISetEndDate { type: 'PRICE.SET_END_DATE'; payload: any; }
export const SetEndDate = (date: Date) =>
    <ISetEndDate>{ type: SET_END_DATE, payload: date };

export type PriceKnownActions =
    IGetPartnersAutocomplete |
    ISetPartnersAutocomplete |
    IChangePartnerSelect |
    IGetAllDefaultPrices |
    ISetAllPrices |
    IGetPriceCategorySelectList |
    ISetPriceCategorySelectList |
    IGetPriceSubcategorySelectList |
    ISetPriceSubcategorySelectList |
    IChangePriceSubcategory |
    IChangePriceCategory |
    IUpdatePartnerPriceSegment |
    IUpdatePartnerPriceSegmentSuccess |
    IGetProductsForPrices |
    ISetProductsForPrices |
    IGetPriceSergmentsForPartner |
    ICreatePartnerPriceSegmentSuccess |
    IGetPriceTerms |
    ICreatePartnerPriceTermsSuccess |
    ICreatePartnerPriceTerms |
    ISetTerms |
    IUpdatePartnerPriceTerms |
    IUpdatePartnerPriceTermsSuccess |
    IRemovePriceSegment |
    IRemovePriceTerm |
    IGetWholesaleList |
    ISetWholesaleList |
    IRemoveWholesale |
    IGetPartnerHistory |
    ISetPartnerHistory |
    IRemovePartnerHistory |
    IUnmountPriceComponent;