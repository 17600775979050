import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import { Order } from "../../../../business.entities/order";
import { OrderProduct } from "../../../../business.entities/order.product";
import { ProductTypeEnum } from "../../../../helpers/enums/product.type.enum";
import { Loader } from "../../../shared/loader";
import * as API from "../../../../constants/api.constants";
import Select from 'react-select';
import { LaserStatusEnum } from "../../../../helpers/enums/laser.status.enum";
import { ProductLaserStatus } from "../../../../business.entities/product.laser.status";
import {
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    RemoveFileFromOrderProduct,
    SetOrderProductsForOrder,
    SetCurrentOrderIndex
} from "../../../../actions/sales/order.base.actions";
import { OrderProductFileTypeEnum } from "../../../../helpers/enums/order.productfile.type.enum";
import { List } from "linqts";
import { default as OrderProductCommon } from "../../../shared/right.view/order.product.common";
import { RemoveRVData } from "../../../../actions/right.view.actions";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept, GetIdentityRoleName } from '../../../../helpers/identity.roles';
import * as moment from 'moment';
import { OrderStatusEnum } from "../../../../helpers/enums/order.status.enum";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    RemoveFileFromOrderProduct,
    RemoveRVData,
    SetOrderProductsForOrder,
    SetCurrentOrderIndex
}, dispatch);

const LaserInfoRv = (props: any) => {
    const currentOrderProducts: OrderProduct[] = props.OrderBase.currentOrderProducts;
    const orderBase: OrderBaseState = props.OrderBase;

    const IsActionAllowed = () => {
        if (orderBase.orders[orderBase.currentOrderIndex].TTN)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
            return false;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderBase.orders[orderBase.currentOrderIndex].AssemlingStatus)
                return true;
        }

        return false;
    }

    const OnSelectLaserStatusCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === id);

        // if(orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
        //     return;

        let newStatus: ProductLaserStatus = new ProductLaserStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = orderProduct.Id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductLaserStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnFileUpload = (event, type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        const files = Array.from(event.target.files)
        let formData = new FormData();
        formData.append('files', files[0] as any, (files[0] as any).name);
        props.UploadFilesForOrderProduct(formData, id, orderBase.orders[orderBase.currentOrderIndex].NetUid, type);
    }

    const ChangeUserImageComment = (value, productId) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = currentOrderProducts.indexOf(orderProduct);

        props.ChangeUserImageComment(value, index, productId);
    }

    const ChangeLaserComment = (value, productId) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = currentOrderProducts.indexOf(orderProduct);

        props.ChangePrintComment(value, index, productId);
    }

    const OnRemoveFileCommand = (type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print))
            return;

        props.RemoveFileFromOrderProduct(id, type);
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    return (
        <div className="printRVContainer">
            {
                currentOrderProducts ? (
                    currentOrderProducts.some(orderProduct => orderProduct.ProductType === ProductTypeEnum.Laser) ?
                        currentOrderProducts
                            .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Laser)
                            .map((product, index) =>
                                <div className="currentOrderProducts__item" key={index}>

                                    <OrderProductCommon product={product} />
                                    {product.Product.Lexicon.CanHaveUserImg ?
                                        <div className="">
                                            <div className="pictureCol">
                                                <div className="previewLabelContainer">Превью</div>
                                                <div className="pictureContainer"
                                                    style={{ backgroundImage: `url(${API.SERVER_URL}${product.UserPrewiev})` }}
                                                    onClick={() => { }}>
                                                    {!product.UserPrewiev ?
                                                        <label className="onMiniFileUploadControl mTop">
                                                            <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Preview, product.NetUid)} />
                                                        </label> :
                                                        <>
                                                            <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Preview, product.NetUid)} />
                                                            {product.UserPrewiev ? <a className="" href={`${API.SERVER_URL}${product.UserPrewiev}`} download /> : null}
                                                        </>
                                                    }
                                                </div>
                                            </div>

                                            <div className="pictureCol">
                                                <div className="previewLabelContainer">Картинка/Архив</div>
                                                <div className={`pictureContainer ${IsZipFile(product.UserImage) ? 'zipp' : ''}`}
                                                    style={{ backgroundImage: `url(${API.SERVER_URL}${product.UserImage})` }}
                                                    onClick={() => { }}>
                                                    {!product.UserImage ?
                                                        <label className="onMiniFileUploadControl mTop">
                                                            <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.UserImage, product.NetUid)} />
                                                        </label> :
                                                        <>
                                                            <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.UserImage, product.NetUid)} />
                                                            {product.UserImage ? <a className="" href={`${API.SERVER_URL}${product.UserImage}`} download /> : null}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div> : null}

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        <div className="defaultFormControl__label">Статус лазера</div>
                                        <div className="selectWrapper__container">
                                            <Select
                                                name="printStatus"
                                                isDisabled={product.ProductLaserStatus.length === 0 || product.ProductLaserStatus[0].Status === LaserStatusEnum.WaitingForAssembling || IsActionAllowed()}
                                                openMenuOnFocus={true}
                                                options={orderBase.laserStatusList}
                                                onChange={(value) => IsActionAllowed() && OnSelectLaserStatusCommand(value, product.Id)}
                                                value={product.SelectedLaserStatus}
                                                placeholder="Статус лазера" />
                                        </div>
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        {product.FinishLaserStatus ?
                                            <>
                                                <div className="defaultFormControl__label">Дата лазера</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {moment(product.FinishLaserStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        {product.FinishLaserStatus ?
                                            <>
                                                <div className="defaultFormControl__label">Изготовил</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {product.FinishLaserStatus.Partner.ContactName}
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                        <div className="defaultFormControl__label">Комментарий заказчика</div>
                                        <div className="defaultFormControlTextatea__value">

                                            <textarea value={product.UserImageComment ? product.UserImageComment : ''}
                                                onChange={(event) => ChangeUserImageComment(event.target.value, product.Id)} />
                                            {
                                                orderBase.fetchInProgress ?
                                                    <Loader /> : null
                                            }

                                        </div>
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        <div className="defaultFormControl__label">Комментарий изготовителя</div>
                                        <div className="defaultFormControlTextatea__value">
                                            <textarea value={product.PrintComment ? product.PrintComment : ''}
                                                onChange={(event) => ChangeLaserComment(event.target.value, product.Id)} />
                                            {
                                                orderBase.fetchInProgress ?
                                                    <Loader /> : null
                                            }
                                        </div>
                                    </PermissionChecker>

                                </div >
                            ) :
                        <div>
                            Отсутствуют товары даного типа
                    </div>
                )
                    :
                    <Loader />
            }
        </div >
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(LaserInfoRv);