import { GetOrderProductStepComponent, OrderProductStep, GetOrderProductOrderCardStepComponent } from "./enums/order.product.steps";
import { List } from "linqts";
import { Order } from "../business.entities/order";
import { ProductTypeEnum } from "./enums/product.type.enum";
import { OrderProduct } from "../business.entities/order.product";

export const OnGoToNextStepCommand = (props: any) => {
    let stepIndex = props.Wizard.orderProductStepPattern.indexOf(props.Wizard.currentStepEnum);

    let nextStep = stepIndex + 1;
    if (props.Wizard.orderProductStepPattern[nextStep] === OrderProductStep.Price) {
        // if (!props.Wizard.currentOrderProduct.Id) {
        //     let similarOrderProduct = null;
            
        //     if (props.Wizard.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Sale)
        //         similarOrderProduct = new List((props.Wizard.order as Order).OrderProducts)
        //             .Where(c => c.ProductId === props.Wizard.currentOrderProduct.ProductId
        //                 && c.ColorId === props.Wizard.currentProductColor.Id)
        //             .FirstOrDefault();

        //     if (props.Wizard.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Production)
        //         similarOrderProduct = new List((props.Wizard.order as Order).OrderProducts)
        //             .Where(c => c.ProductId === props.Wizard.currentOrderProduct.ProductId
        //                 && c.ColorId === props.Wizard.currentProductColor.Id
        //                 && c.BaseProductId === props.Wizard.currentOrderProduct.BaseProductId
        //                 && c.BaseColorId === props.Wizard.currentBaseColor.Id)
        //             .FirstOrDefault();

        //     if (similarOrderProduct)
        //         props.ConfirmOrderProductSelection(similarOrderProduct);
        // }
    }

    nextStep < props.Wizard.orderProductStepPattern.length &&
        props.SetNextSubStep(
            props.Wizard.orderProductStepPattern[nextStep],
            GetOrderProductStepComponent(props.Wizard.orderProductStepPattern[nextStep]));
}

export const OnGoToPrevStepCommand = (props: any) => {
    let stepIndex = props.Wizard.orderProductStepPattern.indexOf(props.Wizard.currentStepEnum);

    let nextStep = stepIndex - 1;

    nextStep >= 0
        && props.SetNextSubStep(
            props.Wizard.orderProductStepPattern[nextStep],
            GetOrderProductStepComponent(props.Wizard.orderProductStepPattern[nextStep]));
}

export const OnGoToNextOrderCardStepCommand = (props: any) => {
    let stepIndex = props.OrderCard.productUpdateSubState.orderProductStepPattern.indexOf(props.OrderCard.productUpdateSubState.currentStepEnum);

    let nextStep = stepIndex + 1;
    if (props.OrderCard.productUpdateSubState.orderProductStepPattern[nextStep] === OrderProductStep.Price) {
        // if (!props.OrderCard.productUpdateSubState.currentOrderProduct.Id) {
        //     let similarOrderProduct = null;
            
        //     if (props.OrderCard.productUpdateSubState.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Sale)
        //         similarOrderProduct = new List<OrderProduct>(props.OrderCard.productUpdateSubState.currentOrderProducts)
        //             .Where(c => c.ProductId === props.OrderCard.productUpdateSubState.currentOrderProduct.ProductId
        //                 && c.ColorId === props.OrderCard.productUpdateSubState.currentProductColor.Id)
        //             .FirstOrDefault();

        //     if (props.OrderCard.productUpdateSubState.currentOrderProduct.Product.Lexicon.ProductTypeId === ProductTypeEnum.Production)
        //         similarOrderProduct = new List<OrderProduct>(props.OrderCard.productUpdateSubState.currentOrderProducts)
        //             .Where(c => c.ProductId === props.OrderCard.productUpdateSubState.currentOrderProduct.ProductId
        //                 && c.ColorId === props.OrderCard.productUpdateSubState.currentProductColor.Id
        //                 && c.BaseProductId === props.OrderCard.productUpdateSubState.currentOrderProduct.BaseProductId
        //                 && c.BaseColorId === props.OrderCard.productUpdateSubState.currentBaseColor.Id)
        //             .FirstOrDefault();

        //     if (similarOrderProduct)
        //         props.ConfirmOrderProductSelection(similarOrderProduct);
        // }
    }

    nextStep < props.OrderCard.productUpdateSubState.orderProductStepPattern.length &&
        props.SetNextSubStep(
            props.OrderCard.productUpdateSubState.orderProductStepPattern[nextStep],
            GetOrderProductOrderCardStepComponent(props.OrderCard.productUpdateSubState.orderProductStepPattern[nextStep]));
}

export const OnGoToPrevOrderCardStepCommand = (props: any) => {
    let stepIndex = props.OrderCard.productUpdateSubState.orderProductStepPattern.indexOf(props.OrderCard.productUpdateSubState.currentStepEnum);

    let nextStep = stepIndex - 1;

    nextStep >= 0
        && props.SetNextSubStep(
            props.OrderCard.productUpdateSubState.orderProductStepPattern[nextStep],
            GetOrderProductOrderCardStepComponent(props.OrderCard.productUpdateSubState.orderProductStepPattern[nextStep]));
}