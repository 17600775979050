import { PriceListFilterItem } from "../../business.entities/price.list.filter.item";
import { ProductPriceListItem } from "../../business.entities/product.price.list.item";

export const SELECT_CONTENT: string = 'PRICELIST.SELECT_CONTENT';
export interface ISelectContent { type: 'PRICELIST.SELECT_CONTENT'; payload: any; }
export const SelectContent = (component: any) =>
    <ISelectContent>{ type: SELECT_CONTENT, payload: component };

export const GET_PRICELIST: string = '[API] PRICELIST.GET_PRICELIST';
export interface IGetPricelist { type: '[API] PRICELIST.GET_PRICELIST'; payload: any; }
export const GetPricelist = (page: number, limit: number) =>
    <IGetPricelist>{ type: GET_PRICELIST, payload: { page, limit } };

export const GET_PRICELIST_URL: string = '[API] PRICELIST.GET_PRICELIST_URL';
export interface IGetPricelistUrl { type: '[API] PRICELIST.GET_PRICELIST_URL'; payload: any; }
export const GetPricelistUrl = () =>
    <IGetPricelistUrl>{ type: GET_PRICELIST_URL, payload: {} };

export const SET_PRICELIST_URL: string = 'PRICELIST.SET_PRICELIST_URL';
export interface ISetPricelistUrl { type: 'PRICELIST.SET_PRICELIST_URL'; payload: any; }
export const SetPricelistUrl = (pricelistUrl: string) =>
    <ISetPricelistUrl>{ type: SET_PRICELIST_URL, payload: pricelistUrl };

export const SET_PRICELIST: string = 'PRICELIST.SET_PRICELIST';
export interface ISetPricelist { type: 'PRICELIST.SET_PRICELIST'; payload: any; }
export const SetPricelist = (priceListItem: ProductPriceListItem[], totalPages: number) =>
    <ISetPricelist>{ type: SET_PRICELIST, payload: { priceListItem, totalPages } };

export const UNMOUNT_COMPONENT: string = 'PRICELIST.UNMOUNT_COMPONENT';
export interface IUnmountComponent { type: 'PRICELIST.UNMOUNT_COMPONENT'; payload: any; }
export const UnmountComponent = () =>
    <IUnmountComponent>{ type: UNMOUNT_COMPONENT };

export type PriceListKnownActions =
    ISelectContent |
    IGetPricelist |
    IGetPricelistUrl |
    ISetPricelistUrl |
    ISetPricelist | 
    IUnmountComponent;