import * as actionCreators from '../actions/login.actions';
import * as API from '../constants/api.constants';
import { error, success } from 'react-notification-system-redux';
import { isUnauthorized } from '../helpers/unauthorize.hendle';
import { LOGIN, LOGIN_SUCCESS, GET_USER_INFO, SetUserInfo, GetUserInfo } from '../actions/login.actions';
import { LoginFailed, LoginSuccess } from '../actions/login.actions';
import { Observable } from 'rxjs/Observable';
import { push } from 'react-router-redux';
import { setCookie, deleteCookie } from '../helpers/cookies';
import { TokenHelper } from '../helpers/jwt.parser';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/operator/catch';
import { ErrorHandler } from '../helpers/error.manager';
import { debounce } from 'rxjs/operator/debounce';
import { getCookie } from "../helpers/cookies";
import * as moment from 'moment';
import { LoginState } from '../states/login.state';
import storeProvider from '../helpers/store.provider';
import 'rxjs/add/operator/switchMap';

export const login = (action$, state$) =>
    action$.ofType(LOGIN)
        .switchMap(action => 
            Observable.ajax.getJSON(API.LOGIN + '?username=' + action.payload.username + '&password=' + action.payload.password)
                .map((response: any) => {
                    
                    storeProvider.getStore().dispatch(success({
                        title: 'Вход успешен',
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));
                    const tockenP = TokenHelper.parseJwt(response.Body.AccessToken);
                    setCookie("token", response.Body.AccessToken, tockenP.exp);
                    setCookie("refresh", response.Body.RefreshToken, tockenP.exp);
                    storeProvider.getStore().getState().authentication.isAuthenticated = true;
                    storeProvider.getStore().getState().authentication.Role = tockenP.role;

                    (storeProvider.getStore().getState().authentication as LoginState).AuthenticationToken = response.Body.AccessToken;
                    (storeProvider.getStore().getState().authentication as LoginState).RefreshToken = response.Body.RefreshToken;
                    (storeProvider.getStore().getState().authentication as LoginState).Exp = tockenP.exp * 1000;
                    (storeProvider.getStore().getState().authentication as LoginState).UserName = action.payload.username;
                    (storeProvider.getStore().getState().authentication as LoginState).UserNetUid = tockenP.NetId;
                    storeProvider.getStore().dispatch(push('/'));

                    return GetUserInfo(response.Body.UserNetUid);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const getUserInfo = (action$, store) =>
    action$.ofType(GET_USER_INFO)
        .switchMap(action => {
            return Observable.ajax.getJSON(`${API.GET_USER_PERSONAL_INFO}?netId=${action.payload}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    return SetUserInfo(response.Body.Partner)
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        });
