import { Store } from "redux";
import * as priceListActions from "../../actions/stock/price.list.actions";
import * as priceListOptionsActions from "../../actions/stock/price.list.options.actions";
import { Observable } from "rxjs/Observable";
import * as API from "../../constants/api.constants";
import { success, error } from "react-notification-system-redux";
import { ErrorHandler } from "../../helpers/error.manager";
import { debounce } from "rxjs/operator/debounce";
import index from "redux-thunk";
import { ApplicationState } from "../../reducers/index";
import { List } from "linqts";
import { PricelistOptions } from "../../business.entities/price.list.options";
import * as priceListExportActions from "../../actions/stock/price.list.export.actions";
import storeProvider from "../../helpers/store.provider";
import { ofType } from "redux-observable";
import { debounceTime, map } from "rxjs/operators";

export const GetLexicons = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.GET_LEXICONS)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_ALL_LEXICONS}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    return priceListOptionsActions.SetLexicons(response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetCategories = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.GET_CATEGORIES)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PARENT_CATEGORIES}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {

                    return priceListOptionsActions.SetRootCategories(response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetSubCategories = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.GET_SUBCATEGORIES)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_SUB_CATEGORIES}?id=${action.payload}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                .map((response: any) => {
                    let index: number = (storeProvider.getStore().getState() as ApplicationState).stock.priceList.Categories
                        .indexOf(new List((storeProvider.getStore().getState() as ApplicationState).stock.priceList.Categories)
                            .FirstOrDefault(c => c.Id === action.payload));

                    storeProvider.getStore().dispatch(priceListOptionsActions.SetSubCategories(response.Body, index));

                    return priceListOptionsActions.SelectRootCategory((storeProvider.getStore().getState() as ApplicationState).stock.priceList.Categories[index]);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );

export const GetPriceList = (action$, store: Store<any>) =>
    action$.ofType(priceListActions.GET_PRICELIST)
        .switchMap(action =>
            Observable.ajax.post(`${API.GET_PRICELIST_PAGINATED}?count=${action.payload.limit}&page=${action.payload.page}`,
                JSON.stringify(action.payload),
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, 'Content-Type': 'application/json' }
            ).map((response: any) => {
                return priceListActions.SetPricelist(response.response.Body.Collection, response.response.Body.TotalPagesCount);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const GetPriceListUrl = (action$, store: Store<any>) =>
    action$.ofType(priceListActions.GET_PRICELIST_URL)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PRICELIST_URL}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` }
            ).map((response: any) => {
                return priceListActions.SetPricelistUrl(response.Body);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const GetPriceListOptions = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.GET_OPTIONS)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.GET_PRICELIST_OPTIONS_BY_PARTNER}?netId=${storeProvider.getStore().getState().authentication.UserNetUid}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` }
            ).map((response: any) => {

                if (response.Body) {
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddFilter(JSON.parse(response.Body.Filter)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddLexiconPrices(JSON.parse((response.Body as PricelistOptions).LexiconPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddProductPrices(JSON.parse((response.Body as PricelistOptions).ProductPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.ChangeAvailableOnly((response.Body as PricelistOptions).AvailableOnly));

                    //get platform 
                    // storeProvider.getStore().dispatch(priceListOptionsActions.AddExportPlatform(JSON.parse((response.Body as PricelistOptions).ExportConfigure)));
                }

                return priceListOptionsActions.SetOptions(response.Body);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const AddPriceListOptions = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.ADD_OPTIONS)
        .switchMap(action =>
            Observable.ajax.post(`${API.ADD_PRICELIST_OPTIONS}`,
                action.payload,
                {
                    "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
                    , 'Content-Type': 'application/json'
                }
            ).map((response: any) => {
                storeProvider.getStore().dispatch(success({
                    title: `Настройки добавлены успешно`,
                    message: '',
                    position: 'br',
                    autoDismiss: 3,
                }));

                if (response.response.Body) {
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddFilter(JSON.parse(response.response.Body.Filter)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddLexiconPrices(JSON.parse((response.response.Body as PricelistOptions).LexiconPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddProductPrices(JSON.parse((response.response.Body as PricelistOptions).ProductPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.ChangeAvailableOnly((response.response.Body as PricelistOptions).AvailableOnly));

                    //get platform 
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddExportPlatform(JSON.parse((response.response.Body as PricelistOptions).ExportConfigure)));
                }

                return priceListOptionsActions.SetOptions(response.response.Body);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const UpdatePriceListOptions = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.UPDATE_OPTIONS)
        .switchMap(action => {
            return Observable.ajax.post(`${API.UPDATE_PRICELIST_OPTIONS}`,
                action.payload,
                {
                    "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
                    , 'Content-Type': 'application/json'
                }
            ).map((response: any) => {
                storeProvider.getStore().dispatch(success({
                    title: `Настройки обновленны успешно`,
                    message: '',
                    position: 'br',
                    autoDismiss: 3,
                }));

                if (response.response.Body) {
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddFilter(JSON.parse(response.response.Body.Filter)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddLexiconPrices(JSON.parse((response.response.Body as PricelistOptions).LexiconPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddProductPrices(JSON.parse((response.response.Body as PricelistOptions).ProductPrices)));
                    storeProvider.getStore().dispatch(priceListOptionsActions.ChangeAvailableOnly((response.response.Body as PricelistOptions).AvailableOnly));

                    //get platform 
                    storeProvider.getStore().dispatch(priceListOptionsActions.AddExportPlatform(JSON.parse((response.response.Body as PricelistOptions).ExportConfigure)));
                }

                return priceListOptionsActions.SetOptions(response.response.Body);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        }
        );

export const RemovePriceListOptions = (action$, store: Store<any>) =>
    action$.ofType(priceListOptionsActions.REMOVE_OPTIONS)
        .switchMap(action =>
            Observable.ajax.delete(`${API.REMOVE_PRICELIST_OPTIONS}?netId=${action.payload}`,
                {
                    "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
                }
            ).map((response: any) => {
                storeProvider.getStore().dispatch(priceListOptionsActions.AddFilter([]));
                storeProvider.getStore().dispatch(priceListOptionsActions.AddLexiconPrices([]));
                storeProvider.getStore().dispatch(priceListOptionsActions.AddProductPrices([]));
                storeProvider.getStore().dispatch(priceListOptionsActions.ChangeAvailableOnly(false));

                return priceListOptionsActions.SetOptions(null);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const RenewOpencartSingle = (action$, store: Store<any>) =>
    action$.ofType(priceListExportActions.RENEW_OPENCART_SINGLE_FILE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.RENEW_OPENCART_SINGLE_COLOR_FILE}`,
                {
                    "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
                }
            ).map((response: any) => {
                storeProvider.getStore().dispatch(
                    success({
                        title: `Файл обновлён`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    })
                );
                return priceListExportActions.FinishAction();
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const RenewOpencartMultiple = (action$, store: Store<any>) =>
    action$.ofType(priceListExportActions.RENEW_OPENCART_MULTIPLE_FILE)
        .switchMap(action =>
            Observable.ajax.getJSON(`${API.RENEW_OPENCART_MULTIPLE_COLOR_FILE}`,
                {
                    "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`
                }
            ).map((response: any) => {
                storeProvider.getStore().dispatch(
                    success({
                        title: `Файл обновлён`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    })
                );

                return priceListExportActions.FinishAction();
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
        );

export const RemoveOptionsChained = (action$, state$) =>
    action$.pipe(
        ofType(priceListOptionsActions.REMOVE_OPTIONS),
        debounceTime(2000),
        map((action: any) => {
            return priceListActions.GetPricelist(1, 40);
        })
    );

export const UpdateOptionsChained = (action$, state$) =>
    action$.pipe(
        ofType(priceListOptionsActions.UPDATE_OPTIONS),
        debounceTime(2000),
        map((action: any) => {
            return priceListActions.GetPricelist(1, 40);
        })
    )

export const AddOptionsChained = (action$, state$) =>
    action$.pipe(
        ofType(priceListOptionsActions.ADD_OPTIONS),
        debounceTime(2000),
        map((action: any) => {
            return priceListActions.GetPricelist(1, 40);
        })
    )