export enum PriceLevel {
    S0,
    S1,
    S2,
    S3,
    S4,
    S5,
    S6,
    P1
}

export const GetPricelevelName = (value: PriceLevel) => {
    switch (value) {
        case PriceLevel.S0:
            return 'D0';
        case PriceLevel.S1:
            return 'D1';
        case PriceLevel.S2:
            return 'D2';
        case PriceLevel.S3:
            return 'D3';
        case PriceLevel.S4:
            return 'D4';
        case PriceLevel.S5:
            return 'D5';
        case PriceLevel.S6:
            return 'D6';
        case PriceLevel.P1:
            return 'P1';
        default:
            return 'D0';
    }
}