import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import { Order } from "../../../../business.entities/order";
import { OrderProduct } from "../../../../business.entities/order.product";
import { ProductTypeEnum } from "../../../../helpers/enums/product.type.enum";
import { Loader } from "../../../shared/loader";
import * as API from "../../../../constants/api.constants";
import Select from 'react-select';
import { PrintStatusEnum } from "../../../../helpers/enums/print.status.enum";
import { ProductPrintStatus } from "../../../../business.entities/product.print.status";
import {
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    RemoveFileFromOrderProduct,
    ChangeCantReuseQuantity,
    SetFailProcessing,
    ChangePrintReuseStatusMulti,
    ProcessFailMulti,
    SetOrderProductsForOrder,
    SetCurrentOrderIndex,
    SendOrdersFromStockRightView
} from "../../../../actions/sales/order.base.actions";
import { OrderProductFileTypeEnum } from "../../../../helpers/enums/order.productfile.type.enum";
import { List } from "linqts";
import { default as OrderProductCommon } from "../../../shared/right.view/order.product.common";
import { warning } from "react-notification-system-redux";
import { RemoveRVData } from "../../../../actions/right.view.actions";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept, GetIdentityRoleName } from '../../../../helpers/identity.roles';
import * as moment from 'moment';
import { OrderStatusEnum } from "../../../../helpers/enums/order.status.enum";
import { string } from "prop-types";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    UploadFilesForOrderProduct,
    ChangeUserImageComment,
    ChangePrintComment,
    RemoveFileFromOrderProduct,
    warning,
    ProcessFailMulti,
    ChangeCantReuseQuantity,
    SetFailProcessing,
    ChangePrintReuseStatusMulti,
    RemoveRVData,
    SetOrderProductsForOrder,
    SetCurrentOrderIndex,
    SendOrdersFromStockRightView
}, dispatch);

const PrintInfosRv = (props: any) => {
    const currentOrderProducts: OrderProduct[] = props.OrderBase.currentOrderProducts;
    const orderBase: OrderBaseState = props.OrderBase;

    const OnSendFromStockCommand = () => {
        if (!props.OrderBase.fetchInProgress) {
            props.SendOrdersFromStockRightView([props.OrderBase.orders[props.OrderBase.currentOrderIndex].Id]);
        }
    }

    const IsActionAllowed = () => {
        if (orderBase.orders[orderBase.currentOrderIndex].TTN)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
            return false;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderBase.orders[orderBase.currentOrderIndex].AssemlingStatus)
                return true;
        }

        return false;
    }

    const IsActionAllowedForImage = () => {
        if (orderBase.orders[orderBase.currentOrderIndex].TTN)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return false;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderBase.orders[orderBase.currentOrderIndex].AssemlingStatus)
                return true;
        }

        return false;
    }

    const OnSelectPrintStatusCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === id);

        // if(orderProduct.ProductPrintStatus[0].Status === PrintStatusEnum.Finished)
        //     return;

        let newStatus: ProductPrintStatus = new ProductPrintStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = orderProduct.Id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductPrintStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnSelectVerifyPrintStatusCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === id);

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            PrintVerificationStatus: status.value
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnFileUpload = (event, type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowedForImage())
            return;

        const files = Array.from(event.target.files)
        let formData = new FormData();
        formData.append('files', files[0] as any, (files[0] as any).name);
        props.UploadFilesForOrderProduct(formData, id, orderBase.orders[orderBase.currentOrderIndex].NetUid, type);
    }

    const ChangeUserImageComment = (value, productId) => {
        if (!IsActionAllowedForImage())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = currentOrderProducts.indexOf(orderProduct);

        props.ChangeUserImageComment(value, index, productId);
    }

    const ChangePrintComment = (value, productId) => {
        if (!IsActionAllowedForImage())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === productId);
        const index = currentOrderProducts.indexOf(orderProduct);

        props.ChangePrintComment(value, index, productId);
    }

    const OnRemoveFileCommand = (type: OrderProductFileTypeEnum, id) => {
        if (!IsActionAllowed())
            return;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Print))
            return;

        props.RemoveFileFromOrderProduct(id, type);
    }

    const OnSelectReuseOptionCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === id);
        if (orderProduct.Quantity == 1) {

            const orderProductToUpdate = Object.assign({}, orderProduct, {
                CanBeReused: status.value
            } as OrderProduct);

            props.UpdateOrderProduct(orderProductToUpdate);
        } else {
            if (status.value == false) {
                const index = currentOrderProducts.indexOf(orderProduct);
                props.ChangePrintReuseStatusMulti(status, index);
                props.warning({
                    title: 'ВНИМАНИЕ!',
                    message: 'После сохранения количества основ, которые не подлежат повторному использованию ОПЕРАЦИЮ НЕ МОЖНО БУДЕТЬ ОТМЕНИТЬ!',
                    position: 'br',
                    autoDismiss: 10,
                });
            } else {
                const orderProductToUpdate = Object.assign({}, orderProduct, {
                    CanBeReused: status.value === undefined ? null : status.value
                } as OrderProduct);

                props.UpdateOrderProduct(orderProductToUpdate);
            }
        }
    }

    const OnChangeCantReuseQuantityCommand = (value, orderProduct) => {
        if (!IsActionAllowed())
            return;

        if (value >= 1 && value <= orderProduct.Quantity)
            props.ChangeCantReuseQuantity(value);
    }

    const OnProcessFailMultiCommand = (orderProduct) => {
        if (!IsActionAllowed())
            return;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            CanBeReused: orderProduct.SelectedReuseOption.value
        } as OrderProduct);

        props.ProcessFailMulti(orderProductToUpdate, orderBase.cantReuseQuantity);
        props.SetFailProcessing(false);

        props.RemoveRVData();
        props.SetOrderProductsForOrder(null);
        props.SetCurrentOrderIndex(null);
    }

    const OnStopProccessingFailMultipleCommand = () => {
        if (!IsActionAllowed())
            return;

        props.SetFailProcessing(false);
    }

    const OnSaveReuseStatusMulti = (product) => {
        if (!IsActionAllowed())
            return;

        if (!orderBase.isFailProcessing) {
            props.SetFailProcessing(true);

            props.warning({
                title: 'ВНИМАНИЕ!',
                message: 'ОПЕРАЦИЮ НЕ МОЖНО БУДЕТЬ ОТМЕНИТЬ!',
                position: 'br',
                autoDismiss: 0,
                onRemove: () => OnStopProccessingFailMultipleCommand(),
                action: {
                    label: 'Сохранить',
                    callback: () => OnProcessFailMultiCommand(product)
                }
            });
        }
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    return (
        <div className="rightView__orderProductsContainer withButton">
        <div className="printRVContainer">
            {
                currentOrderProducts ? (
                    currentOrderProducts
                        .some(orderProduct => orderProduct.ProductType === ProductTypeEnum.Print && ProductTypeEnum.PrintProduction) ?
                        currentOrderProducts
                            .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Print && ProductTypeEnum.PrintProduction)
                            .map((product, index) =>
                                <div className="currentOrderProducts__item" key={index}>

                                    <OrderProductCommon product={product} />
                                    <div className="">
                                        <div className="pictureCol">
                                            <div className="previewLabelContainer">Превью</div>
                                            <div className="pictureContainer"
                                                style={{ backgroundImage: `url(${API.SERVER_URL}${product.UserPrewiev})` }}
                                                onClick={() => { }}>
                                                {!product.UserPrewiev ?
                                                    <label className="onMiniFileUploadControl mTop">
                                                        <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Preview, product.NetUid)} />
                                                    </label> :
                                                    <>
                                                        <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Preview, product.NetUid)} />
                                                        {product.UserPrewiev ? <a className="" href={`${API.SERVER_URL}${product.UserPrewiev}`} download /> : null}
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        <div className="pictureCol">
                                            <div className="previewLabelContainer">Картинка/Архив</div>
                                            <div className={`pictureContainer ${IsZipFile(product.UserImage) ? 'zipp' : ''}`}
                                                style={{ backgroundImage: `url(${API.SERVER_URL}${product.UserImage})` }}
                                                onClick={() => { }}>
                                                {!product.UserImage ?
                                                    <label className="onMiniFileUploadControl mTop">
                                                        <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.UserImage, product.NetUid)} />
                                                    </label> :
                                                    <>
                                                        <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.UserImage, product.NetUid)} />
                                                        {product.UserImage ? <a className="" href={`${API.SERVER_URL}${product.UserImage}`} download /> : null}
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {product.ProductPrintStatus.length > 0 &&
                                            (product.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                                product.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter) ?
                                            <div className="pictureCol">
                                                <div className="previewLabelContainer">Брак</div>
                                                <div className="pictureContainer"
                                                    style={{ backgroundImage: `url(${API.SERVER_URL}${product.FailImage})` }}
                                                    onClick={() => { }}>
                                                    {!product.FailImage ?
                                                        <label className="onMiniFileUploadControl mTop">
                                                            <input type="file" onChange={(event) => OnFileUpload(event, OrderProductFileTypeEnum.Fail, product.NetUid)} />
                                                        </label> :
                                                        <>
                                                            <div className="onMiniRemoveControl" onClick={() => OnRemoveFileCommand(OrderProductFileTypeEnum.Fail, product.NetUid)} />
                                                            {product.FailImage ? <a className="" href={`${API.SERVER_URL}${product.FailImage}`} download /> : null}
                                                        </>
                                                    }
                                                </div>
                                            </div> :
                                            null
                                        }
                                    </div>


                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        <div className="defaultFormControl__label">Статус печати</div>
                                        <div className="selectWrapper__container">
                                            <Select
                                                name="printStatus"
                                                isDisabled={product.ProductPrintStatus.length == 0 || product.ProductPrintStatus[0].Status === PrintStatusEnum.WaitingForAssembling || product.SelectedReuseOption.value === false || !IsActionAllowed()}
                                                openMenuOnFocus={true}
                                                options={orderBase.printStatusList}
                                                onChange={(value) => IsActionAllowed() && OnSelectPrintStatusCommand(value, product.Id)}
                                                value={product.SelectedPrintStatus}
                                                placeholder="Статус печати" />
                                        </div>
                                    </PermissionChecker>


                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        <div className="defaultFormControl__label">Проверка печати</div>
                                        <div className="selectWrapper__container">
                                            <Select
                                                name="printVerifyStatus"
                                                openMenuOnFocus={true}
                                                isDisabled={product.SelectedReuseOption.value === false || !IsActionAllowed()}
                                                options={orderBase.printVerifyStatusList}
                                                onChange={(value) => IsActionAllowed() && OnSelectVerifyPrintStatusCommand(value, product.Id)}
                                                value={product.SelectedVerificationStatus}
                                                placeholder="Проверка печати" />
                                        </div>
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        {product.ProductPrintStatus.length > 0 &&
                                            (product.ProductPrintStatus[0].Status === PrintStatusEnum.FailedByPrinter ||
                                                product.ProductPrintStatus[0].Status === PrintStatusEnum.FailedNotByPrinter) ?
                                            <div className="defaultFormControl">
                                                <div className="defaultFormControl__label">Использовать повторно</div>
                                                <div className="selectWrapper__container">
                                                    <Select
                                                        name="reuse"
                                                        isDisabled={product.SelectedReuseOption.value === false || !IsActionAllowed()}
                                                        openMenuOnFocus={true}
                                                        options={orderBase.reuseStatusList}
                                                        onChange={(value) => IsActionAllowed() && OnSelectReuseOptionCommand(value, product.Id)}
                                                        value={product.SelectedReuseOption}
                                                        placeholder="Использовать повторно" />
                                                </div>
                                            </div> :
                                            null
                                        }
                                    </PermissionChecker>

                                    {product.Quantity > 1 && product.SelectedReuseOption.value === false ?
                                        <div>
                                            <div className="defaultFormControl">
                                                <div className="defaultFormControl__label">Не возможно использовать </div>
                                                <div className="defaultFormControl__value">
                                                    <input type="number"
                                                        value={orderBase.cantReuseQuantity}
                                                        onChange={(event) => OnChangeCantReuseQuantityCommand(event.target.value, product)} />
                                                </div>
                                            </div>
                                            <div className="defaultFormControl">
                                                <div className="defaultFormButton" onClick={() => OnSaveReuseStatusMulti(product)}>
                                                    Сохранить
                                        </div>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        {product.FinishPrintStatus ?
                                            <>
                                                <div className="defaultFormControl__label">Дата печати</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {moment(product.FinishPrintStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        {product.FinishPrintStatus ?
                                            <>
                                                <div className="defaultFormControl__label">Печатал</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {product.FinishPrintStatus.Partner.ContactName}
                                                    </div>
                                                </div>
                                            </>
                                            : null}
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Partner, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                                        <div className="defaultFormControl__label">Комментарий заказчика</div>
                                        <div className="defaultFormControlTextatea__value">

                                            <textarea value={product.UserImageComment ? product.UserImageComment : ''}
                                                onChange={(event) => ChangeUserImageComment(event.target.value, product.Id)} />
                                            {
                                                orderBase.fetchInProgress ?
                                                    <Loader /> : null
                                            }

                                        </div>
                                    </PermissionChecker>

                                    <PermissionChecker
                                        Classes='defaultFormControl'
                                        ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                        <div className="defaultFormControl__label">Комментарий печатника</div>
                                        <div className="defaultFormControlTextatea__value">
                                            <textarea value={product.PrintComment ? product.PrintComment : ''}
                                                onChange={(event) => ChangePrintComment(event.target.value, product.Id)} />
                                            {
                                                orderBase.fetchInProgress ?
                                                    <Loader /> : null
                                            }
                                        </div>
                                    </PermissionChecker>

                                </div >
                            ) :
                        <div>
                            Отсутствуют товары даного типа
                        </div>
                ) :
                    <Loader />
            }
            {
                (!props.OrderBase.fetchInProgress && props.OrderBase.currentOrderIndex != null && props.OrderBase.currentOrderIndex != undefined) ?
                <div className="editTutorialContainer widthAuto atBottom">
                    <PermissionChecker
                            Classes='defaultFormButtonControl grActButton'
                            ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Sklad]}>
                        <div className=""
                            onClick={OnSendFromStockCommand}>
                            Отправить со склада
                        </div>
                    </PermissionChecker>
                </div> : null
            }

        </div >
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PrintInfosRv);