import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { OrderProduct } from "../../../../business.entities/order.product";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import { ProductTypeEnum } from "../../../../helpers/enums/product.type.enum";
import { Loader } from "../../../shared/loader";
import Select from "react-select";
import { ProductionStatusEnum } from "../../../../helpers/enums/production.status";
import { UpdateOrderProduct, ChangeProductionComment, ChangeProductionOperatorComment } from "../../../../actions/sales/order.base.actions";
import { ProductProductionStatus } from "../../../../business.entities/product.production.status";
import { List } from "linqts";
import { Order } from "../../../../business.entities/order";
import { default as OrderProductCommon } from "../../../shared/right.view/order.product.common";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept, GetIdentityRoleName } from '../../../../helpers/identity.roles';
import * as moment from "moment";
import { OrderStatusEnum } from "../../../../helpers/enums/order.status.enum";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase,
        Authorization: state.authentication
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrderProduct,
    ChangeProductionComment,
    ChangeProductionOperatorComment
}, dispatch);

const ProductionInfoRv = (props: any) => {
    const currentOrderProducts: OrderProduct[] = props.OrderBase.currentOrderProducts;
    const orderBase: OrderBaseState = props.OrderBase;
    const order: Order = orderBase.orders[orderBase.currentOrderIndex];

    const IsActionAllowed = () => {
        if (orderBase.orders[orderBase.currentOrderIndex].TTN)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return false;


        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Confirmed)
            return false;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Manufacture)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya))
            return true;
        else {
            if (!orderBase.orders[orderBase.currentOrderIndex].AssemlingStatus)
                return true;
        }

        return false;
    }

    const OnSelectProductionStatusCommand = (status, id) => {
        if (!IsActionAllowed())
            return;

        const orderProduct = new List<OrderProduct>(currentOrderProducts).FirstOrDefault(c => c.Id === id);

        let newStatus: ProductProductionStatus = new ProductProductionStatus();
        newStatus.Status = status.value;
        newStatus.OrderProductId = id;

        const orderProductToUpdate = Object.assign({}, orderProduct, {
            ProductProductionStatus: [newStatus]
        } as OrderProduct);

        props.UpdateOrderProduct(orderProductToUpdate);
    }

    const OnChangePartnerProdComment = (value, index) => {
        if (!IsActionAllowed())
            return;

        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner)) {
            if ((props.OrderBase as OrderBaseState).orders[index].StockStatus.length == 0)
                props.ChangeProductionOperatorComment(value, index);
        } else {
            props.ChangeProductionOperatorComment(value, index);
        }
    }

    const OnChangeManufactureProdComment = (value, index) => {
        if (!IsActionAllowed())
            return;

        if (orderBase.orders[orderBase.currentOrderIndex].TTN)
            return false;

        if (orderBase.orders[orderBase.currentOrderIndex].OrderStatus.length > 0 && orderBase.orders[orderBase.currentOrderIndex].OrderStatus[0].Status === OrderStatusEnum.Recived)
            return false;

        if ((props.OrderBase as OrderBaseState).orders[index].StockStatus.length > 0 &&
            (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Manufacture)
                || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Maysternya)))
            props.ChangeProductionComment(value, index);
    }

    return (
        <div className="printRVContainer">
            {currentOrderProducts ?
                currentOrderProducts
                    .some(orderProduct => orderProduct.ProductType === ProductTypeEnum.Production && ProductTypeEnum.PrintProduction) ?
                    <div>
                        {currentOrderProducts
                            .filter(orderProduct => orderProduct.ProductType === ProductTypeEnum.Production && ProductTypeEnum.PrintProduction)
                            .map((product, index) =>
                                <div className="currentOrderProducts__item" key={index}>
                                    <OrderProductCommon product={product} />

                                    {product.ProductProductionStatus.length > 0 &&
                                        product.ProductProductionStatus[0].Status > ProductionStatusEnum.WaitingForAssembling ?
                                        <PermissionChecker
                                            Classes='defaultFormControl'
                                            EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Manufacture]}>
                                            <div className="defaultFormControl__label">Статус производство</div>
                                            <div className="selectWrapper__container">
                                                <Select
                                                    name="productionStatus"
                                                    isDisabled={!IsActionAllowed()}
                                                    openMenuOnFocus={true}
                                                    options={orderBase.productionStatusList}
                                                    onChange={(value) => OnSelectProductionStatusCommand(value, product.Id)}
                                                    value={product.SelectedProductionStatus}
                                                    placeholder="Статус производство" />
                                            </div>
                                        </PermissionChecker> : null}

                                    {product.FinishProductionStatus ?
                                        <PermissionChecker
                                            Classes='defaultFormControl'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                            <>
                                                <div className="defaultFormControl__label">Дата пошива</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {moment(product.FinishProductionStatus.Created).format('DD/MM/YYYY HH:mm')}
                                                    </div>
                                                </div>
                                            </>
                                        </PermissionChecker>
                                        : null}

                                    {product.FinishProductionStatus ?

                                        <PermissionChecker
                                            Classes='defaultFormControl'
                                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                                            <>
                                                <div className="defaultFormControl__label">Пошил</div>
                                                <div className="defaultFormControl__value">
                                                    <div className="imitation_input">
                                                        {product.FinishProductionStatus.Partner.ContactName}
                                                    </div>
                                                </div>
                                            </>
                                        </PermissionChecker>
                                        : null}

                                </div>
                            )}

                        <PermissionChecker
                            Classes='defaultFormControl'
                            ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                            <div className="defaultFormControl__label">Комментарий заказчика</div>
                            <div className="defaultFormControlTextatea__value">
                                <textarea value={order.ProductionOperatorComment ? order.ProductionOperatorComment : ''}
                                    onChange={(event) => OnChangePartnerProdComment(event.target.value, orderBase.currentOrderIndex)} />
                                {
                                    orderBase.fetchInProgress ?
                                        <Loader /> : null
                                }
                            </div>
                        </ PermissionChecker>

                        <PermissionChecker
                            Classes='defaultFormControl'
                            ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])}>
                            <div className="defaultFormControl__label">Комментарий швачки</div>
                            <div className="defaultFormControlTextatea__value">
                                <textarea value={order.ProductionComment ? order.ProductionComment : ''}
                                    onChange={(event) => OnChangeManufactureProdComment(event.target.value, orderBase.currentOrderIndex)} />

                                {
                                    orderBase.fetchInProgress ?
                                        <Loader /> : null
                                }
                            </div>
                        </ PermissionChecker>

                    </div>
                    :
                    <div>
                        Отсутствуют товары даного типа
                    </div>
                :
                <Loader />
            }
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductionInfoRv);