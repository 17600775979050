import { Reducer } from 'redux';
import * as printersActions from '../../actions/sales/printers.actions';
import { PrinterState, defaultPrinterState } from '../../states/sales/printer.state';



export const reducer: Reducer<any> = (state: PrinterState, action: printersActions.PrinterKnownActions) => {
     switch (action.type) {
          case printersActions.SET_NAME:
               return {
                    ...state,
                    NameList: action.payload
               } as PrinterState;
          case printersActions.SET_CHOICE_OF_CHANGE_TYPE:
               return {
                    ...state,
                    ChoiceOfChangeTypeList: action.payload
               } as PrinterState;
          case printersActions.SET_SELECTION_OF_PRODUCT_TYPE:
               return {
                    ...state,
                    SelectionOfProductTypeList: action.payload
               } as PrinterState;
          case printersActions.SET_CHOICE_OF_URGENCY:
               return {
                    ...state,
                    ChoiceOfUrgencyList: action.payload
               } as PrinterState;
          case printersActions.SET_LEXICON_SELECTION:
               return {
                    ...state,
                    LexiconSelectionList: action.payload
               } as PrinterState;
          case printersActions.SET_SELECTION_OF_ADDITIONAL_SERVICE:
               return {
                    ...state,
                    SelectionOfAdditionalServicesList: action.payload
               } as PrinterState;
          case printersActions.SET_PRINTERS:
               return {
                    ...state,
                    PrintersList: action.payload,
                    fetchInProgress: true
               } as PrinterState;
          default:
               return state || defaultPrinterState;
     }
}
