import { RegistrationFormCheckbox } from "../../../authentication/common/registration.form.checkbox";
import { TextBoxWithLabel } from "../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../helpers/validator.helper";
import * as LexiconActions from "../../../../actions/stock/lexicon.actions";
import { Lexicon } from "../../../../business.entities/lexicon";
import { Form, actions } from "react-redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as React from "react";

export namespace LexiconFormComponent {
    export interface IProps {
        ChangeForm?: any;
        ResetForm?: any;
        SelectedLexicon?: Lexicon;
        DeselectLexicon?: any;
        UpdateLexicon?: any;
        lexicon?: any;
    }

    export interface IState {
    }
}

const mapStateToProps = (state) => {
    return {
        SelectedLexicon: state.stock.lexicon.SelectedLexicon,
        lexicon: state.lexiconForm
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ResetForm: actions.reset,
        ChangeForm: actions.change,
        DeselectLexicon: LexiconActions.DeselectLexicon,
        UpdateLexicon: LexiconActions.UpdateLexicon
    }, dispatch);
};

class LexiconFormComponent extends React.Component<LexiconFormComponent.IProps, LexiconFormComponent.IState> {
    constructor(props) {
        super(props);

        this.props.ChangeForm('lexiconForm', this.props.SelectedLexicon);
    }

    componentWillUnmount() {
        this.props.ResetForm('lexiconForm');
    }

    onUpdateLexiconCommand() {
        this.props.UpdateLexicon(this.props.lexicon);
    }

    render() {
        return (
            <Form
                validateOn='change'
                model="lexiconForm"
                onSubmit={this.onUpdateLexiconCommand.bind(this)}>
                <div className="default_form_component shadow_controls">
                    <TextBoxWithLabel
                        Validators={{
                            isRequired: (val: string) => !Validator.IsEmpty(val),
                        }}
                        ErrorMessages={{
                            isRequired: '*',
                        }}
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Оф. партнерское название'
                        IsRequired={true}
                        Model='.OfficialPartnerName' />
                    <TextBoxWithLabel
                        Type='text'
                        LabelMessage='Лексиконное название'
                        IsRequired={true}
                        Model='.CasualName'
                        Readonly />
                    {/* <TextBoxWithLabel
                        Type='text'
                        LabelMessage='Название для поставщика'
                        IsRequired={true}
                        Model='.PurchaseName'
                        Readonly />
                    <TextBoxWithLabel
                        Type='text'
                        LabelMessage='Имя поставщика'
                        IsRequired={true}
                        Model='.VendorName'
                        Readonly />
                    <TextBoxWithLabel
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Цена поставщика'
                        IsRequired={true}
                        Model='.VendorPrice'
                        Readonly />
                    <TextBoxWithLabel
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Колличество дизайнов'
                        IsRequired={true}
                        Model='.DesignsQuantity'
                        Readonly /> */}
                    <TextBoxWithLabel
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Тип продукта'
                        IsRequired={true}
                        Model='.ProductType.Name'
                        Readonly />

                    {/* <div className="form_control">
                        <RegistrationFormCheckbox
                            model=".HasColors"
                            text="Цвет"
                            readOnly={true} />
                        <RegistrationFormCheckbox
                            model=".HasQuantity"
                            text="Колличество"
                            readOnly={true} />
                        <RegistrationFormCheckbox
                            model=".HasDesigns"
                            text="Дизайны"
                            readOnly={true} />
                        <RegistrationFormCheckbox
                            model=".HasComment"
                            text="Комментарий"
                            readOnly={true} />
                        <RegistrationFormCheckbox
                            model=".CanBeBase"
                            text="Основа"
                            readOnly={true} />
                        <RegistrationFormCheckbox
                            model=".CanHaveUserImg"
                            text="Картинка"
                            readOnly={true} />
                    </div> */}

                    <TextBoxWithLabel
                        Validators={{
                            isNumeric: (val: string) => Validator.IsNumeric(val),
                        }}
                        ErrorMessages={{
                            isNumeric: 'Поле должно сожержать только цыфри'
                        }}
                        ShowErrorOn='touched'
                        Type='text'
                        ValidatedOn='change'
                        LabelMessage='Порядковый номер лексикон'
                        IsRequired={true}
                        Model='.PartnerPriority' />
                    <div className="defaultFormControl">
                        <button type="submit" className="defaultFormButton" >Сохранить</button>
                    </div>
                </div>
            </Form>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps)
    (LexiconFormComponent);
