import { reducer as notifications } from 'react-notification-system-redux';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { ApplicationState, reducers } from '../reducers';
import {  createEpicMiddleware } from "redux-observable";
import { epics } from "../Reducers";
import { History } from 'history'
import thunk from 'redux-thunk';
import { localeReducer  } from 'react-localize-redux';
import refreshTockenMiddleware from '../middleware/refresh.token.middleware';

export default function configureStore(history: History, initialState?: ApplicationState) {
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    
    const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const epicMiddleware = createEpicMiddleware();

    const simpleRouter = routerMiddleware(history);

    const createStoreWithMiddleware = applyMiddleware(thunk, simpleRouter, refreshTockenMiddleware, epicMiddleware);
    
    const allReducers = buildRootReducer(reducers);

    const store = createStore(
        allReducers,
        initialState,
        composeEnhancers(
            createStoreWithMiddleware
        )
    );

    if ((module as any).hot) {
        (module as any).hot.accept('../reducers', () => {
            const nextRootEpic = require('../reducers').epics;
            const nextRootReducer = require('../reducers').reducers;

            (epicMiddleware as any).replaceEpic(nextRootEpic)
            store.replaceReducer(buildRootReducer(nextRootReducer));
        });
    }

    epicMiddleware.run(epics as any);

    return store;
}

function buildRootReducer(allReducers) {
    return combineReducers<ApplicationState>({ ...allReducers, routing: routerReducer, notifications: notifications, locale: localeReducer });
}