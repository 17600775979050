import * as mutualSettlementsActions from '../../../../actions/cabinet/mutual.settlements.actions';
import * as React from 'react';
import MutualSettlementsFundsWithdrawalForm from './mutual.settlements.funds.withdrawal.form';
import MutualSettlementsPaymentForDeliveryForm from './mutual.settlements.payment.for.delivery.form';
import MutualSettlementsRefundsForm from './mutual.settlements.refunds.form';
import MutualSettlementsReplenishmentOfBalanceForm from './mutual.settlements.replenishment.of.balance.form';
import Select from 'react-select';
import { ApplicationState } from '../../../../reducers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { List } from 'linqts';
import { MutualSettlementsState } from '../../../../states/cabinet/mutual.settlements.state';
import { Route } from 'react-router';
import * as moment from 'moment';
import { GetMutaualSettlementName, MutualSettlementOperationType } from '../../../../helpers/enums/mutualsettlement.operation.type';
import { LoginState } from '../../../../states/login.state';
import { NavLink } from 'react-router-dom';
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, GetIdentityRoleName, AllIdentityRolesExept } from '../../../../helpers/identity.roles';
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import SelectForPaginate from 'rc-select';
import { RightViewState } from '../../../../states/right.view.state';
import { ChangeRVTitle, RemoveRVData, SelectRVData } from '../../../../actions/right.view.actions';
import { default as FilterComponent } from "./filter.component";
import { MutualSettlementFilter } from '../../../../helpers/mutual.filter';
import { LeftMenuMobileControl } from '../../../shared/leftMenu.control';
import * as API from '../../../../constants/api.constants';
import { Loader } from '../../../shared/loader';
import storeProvider from '../../../../helpers/store.provider';
import { error, success } from 'react-notification-system-redux';

namespace MutualSettlements {
    export interface IProps {
        history: any,
        GetPartnersAutocomplete: (value: string) => any;
        ChangePartnerSelect: (value: any) => any;
        SelectOperationType: (value: any) => any;
        GetMutualSettlementsForPartner(netId: any, page: number, count: number, filter: any);
        GetBalance(partnerNetId: string): any;
        mutualSettlements: MutualSettlementsState;
        Authorization: LoginState;
        UnmountComponent();
        GetAllOrders(partnerId, value);
        ChangePage(number);
        ChangeCount(number);
        RemoveRVData();
        SelectRVData(state: RightViewState);
        ResetFilter();
        SetBalance(value);
        SetFiltered(value);
        ExportMutualSettlements(value);
        SetAfterpaymentsImportFileName(value);
        SetAfterpaymentsImportFile(value);
        LoadAfterpaymentsFile(value);
    }

    export interface IState {
        operationTypes: any[];
    }
}

const mapStateToProps = (state: ApplicationState) => ({ mutualSettlements: state.cabinet.mutualSettlements, Authorization: state.authentication });

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetAllPartners: mutualSettlementsActions.GetPartnersAutocomplete,
    GetAllOrders: mutualSettlementsActions.GetOrdersAutocomplete,
    GetPartnersAutocomplete: mutualSettlementsActions.GetPartnersAutocomplete,
    ChangePartnerSelect: mutualSettlementsActions.ChangePartnerSelect,
    SelectOperationType: mutualSettlementsActions.SelectOperationType,
    GetMutualSettlementsForPartner: mutualSettlementsActions.GetAllMutualSettlements,
    GetBalance: mutualSettlementsActions.GetBalance,
    SetBalance: mutualSettlementsActions.SetBalance,
    UnmountComponent: mutualSettlementsActions.UnmountComponent,
    ChangePage: mutualSettlementsActions.ChangePage,
    ChangeCount: mutualSettlementsActions.ChangeLimit,
    ResetFilter: mutualSettlementsActions.ClearFilter,
    ChangeOperation: mutualSettlementsActions.ChangeOperation,
    ChangeOrder: mutualSettlementsActions.ChangeOrder,
    ChangeStartDate: mutualSettlementsActions.ChangeStartDate,
    ChangeEndDate: mutualSettlementsActions.ChangeEndDate,
    SetFiltered: mutualSettlementsActions.SetFiltered,
    ExportMutualSettlements: mutualSettlementsActions.ExportMutualSettlements,
    SetAfterpaymentsImportFileName: mutualSettlementsActions.SetAfterpaymentsImportFileName,
    SetAfterpaymentsImportFile: mutualSettlementsActions.SetAfterpaymentsImportFile,
    LoadAfterpaymentsFile: mutualSettlementsActions.LoadAfterpaymentsFile,
    SelectRVData,
    RemoveRVData
}, dispatch);

class MutualSettlements extends React.Component<MutualSettlements.IProps, MutualSettlements.IState> {

    constructor(props) {
        super(props);
        if (props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
            props.GetPartnersAutocomplete("");

            let operations = [];

            if (props.Authorization.Role !== GetIdentityRoleName(IdentityRoleEnum.Coordinator))
                operations.push({ value: 'replenishment-of-balance', label: 'Пополнение баланса', selectedForm: MutualSettlementsReplenishmentOfBalanceForm });

            operations.push({ value: 'refunds', label: 'Возврат средств', selectedForm: MutualSettlementsRefundsForm });
            operations.push({ value: 'withdrawl', label: 'Вывод средств', selectedForm: MutualSettlementsFundsWithdrawalForm });

            this.state = {
                operationTypes: operations
            };
            props.SelectOperationType(this.state.operationTypes[0]);

            props.GetMutualSettlementsForPartner(null, props.mutualSettlements.Page, props.mutualSettlements.Count, null);

        } else {
            props.GetMutualSettlementsForPartner(props.Authorization.UserNetUid, props.mutualSettlements.Page, props.mutualSettlements.Count, null);
            props.GetBalance(props.Authorization.UserNetUid);
        }
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    public GetSelectedForm() {
        return new List(this.state.operationTypes)
            .Where(c => this.props.mutualSettlements.SelectedOperationType && c.value === this.props.mutualSettlements.SelectedOperationType.value)
            .Select(c => c.selectedForm)
            .FirstOrDefault();
    }

    public onAutocompleteInputChange(value: string) {
        this.props.GetPartnersAutocomplete(value);
    }

    public onChangePartnerSelectCommand(value: any) {
        this.props.ChangePartnerSelect(value);

        if (value)
            this.props.GetMutualSettlementsForPartner(value.value, 1, 25, this.BuildFilter());
        else {
            this.props.GetMutualSettlementsForPartner(null, 1, 25, this.BuildFilter());
            this.props.SetBalance(0);
        }

        if (value)
            this.props.GetBalance(value.value);

        if (value && this.props.mutualSettlements.SelectedOperationType.value == 'refunds') {
            this.props.GetAllOrders(value.value, '');
        }
    }

    public onChangeOperationTypeSelectCommand(value: any) {
        this.props.SelectOperationType(value);

        if (value.value == 'refunds') {
            this.props.GetAllOrders(this.props.mutualSettlements.SelectedPartner.value, '');
        }
    }

    public OnChangePageSizeCommand(current, pageSize) {
        if (!this.props.mutualSettlements.Fetching) {
            this.props.ChangePage(1);
            this.props.ChangeCount(pageSize);

            this.props.GetMutualSettlementsForPartner(
                this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                    this.props.Authorization.UserNetUid
                    :
                    this.props.mutualSettlements.SelectedPartner !== null ? this.props.mutualSettlements.SelectedPartner.value : null, 1, pageSize, this.BuildFilter());
        }
    }

    public OnChangePageCommand(current, pageSize) {
        if (!this.props.mutualSettlements.Fetching) {
            this.props.ChangePage(current);
            this.props.GetMutualSettlementsForPartner(
                this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Partner) ?
                    this.props.Authorization.UserNetUid
                    :
                    this.props.mutualSettlements.SelectedPartner !== null ? this.props.mutualSettlements.SelectedPartner.value : null,
                current,
                this.props.mutualSettlements.Count,
                this.BuildFilter());
        }
    }

    public GetSymbol(operation: MutualSettlementOperationType): string {
        switch (operation) {
            case MutualSettlementOperationType.CreateOrder:
            case MutualSettlementOperationType.DeliveryPayment:
            case MutualSettlementOperationType.RemoveDiscount:
            case MutualSettlementOperationType.FundsWithdrawal:
                return "-";
        }

        return "";
    }

    public OnOpenFilterRvCommand(): void {
        // if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
        //     || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
        //     || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
        //     || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
        //     // if (!this.props.mutualSettlements.SelectedPartner)
        //         // return;
        // }

        let rvState = new RightViewState();
        rvState.Title = "Фильтр";
        rvState.Component = FilterComponent;
        rvState.ViewSize = "rightViewGroupActions";
        rvState.CloseFunction = () => { this.props.RemoveRVData() };

        this.props.SelectRVData(rvState);
    }

    public BuildFilter() {
        let filter = new MutualSettlementFilter();

        if (this.props.mutualSettlements.StartDate)
            filter.StartDate = this.props.mutualSettlements.StartDate.format('YYYY-MM-DDTHH:mm:ss');

        if (this.props.mutualSettlements.EndDate)
            filter.EndDate = this.props.mutualSettlements.EndDate.format('YYYY-MM-DDTHH:mm:ss');

        filter.OrderId = this.props.mutualSettlements.Order ? this.props.mutualSettlements.Order.value : null;
        filter.OperationType = this.props.mutualSettlements.Operation ? this.props.mutualSettlements.Operation.value : null;

        return filter;
    }

    public ResetFilter() {
        if (!this.props.mutualSettlements.Fetching) {
            let PartnerNetId = null;

            if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
                || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
                PartnerNetId = this.props.mutualSettlements.SelectedPartner ? this.props.mutualSettlements.SelectedPartner.value : null
            } else {
                PartnerNetId = this.props.Authorization.UserNetUid;
            }

            this.props.ResetFilter();
            this.props.SetFiltered(false);
            this.props.ChangeStartDate(undefined);
            this.props.ChangeEndDate(undefined);
            this.props.ChangeOrder(null);
            this.props.ChangeOperation(null);

            this.props.ChangePage(1);
            this.props.GetMutualSettlementsForPartner(PartnerNetId, 1, this.props.mutualSettlements.Count, null);
        }
    }

    public ExportMutualSettlements() {
        let PartnerNetId = null;

        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Booker)) {
            PartnerNetId = this.props.mutualSettlements.SelectedPartner ? this.props.mutualSettlements.SelectedPartner.value : null
        } else {
            PartnerNetId = this.props.Authorization.UserNetUid;
        }

        const filter = this.BuildFilter();

        this.props.ExportMutualSettlements({partnerId: PartnerNetId, filter: filter})
    }


    public async onSelectFileWithPromocodes(event) {
        const files = Array.from(event.target.files)
        let formData = new FormData();
        
        if (files[0]){
            formData.append('files', files[0] as any, (files[0] as any).name);
            await this.props.SetAfterpaymentsImportFileName((files[0] as any).name);
            await this.props.SetAfterpaymentsImportFile(formData)
            storeProvider.getStore().dispatch(success({
                title: 'Файл выбран',
                message: '',
                position: 'br',
                autoDismiss: 3
            }));
        }
    }

    public async onSaveFile(){
        if (this.props.mutualSettlements.FileForImportAfterpayments){
            await this.props.LoadAfterpaymentsFile(this.props.mutualSettlements.FileForImportAfterpayments);
        }
        else
            storeProvider.getStore().dispatch(error({
                title: 'Файл не добавлен',
                message: '',
                position: 'br',
                autoDismiss: 3,
            }));
    }

    render() {
        return (
            <div className="cMutualSettlements__container rcPagination__component">
                <LeftMenuMobileControl />
                <PermissionChecker
                    Classes='left_menu_component'
                    ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Booker]}>

                    <div className="left_menu_SCROLL">
                        <div className="left_menu_SCROLL">
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Все Партнеры</div>
                                <div className="selectWrapper__container">
                                    < Select
                                        name="partner"
                                        isClearable
                                        options={this.props.mutualSettlements.AllPartners}
                                        onInputChange={this.onAutocompleteInputChange.bind(this)}
                                        onChange={this.onChangePartnerSelectCommand.bind(this)}
                                        clearValue={() => { this.onChangePartnerSelectCommand(null) }}
                                        value={this.props.mutualSettlements.SelectedPartner}
                                        placeholder=""
                                    />
                                </div>
                            </div>
                            {
                                this.props.mutualSettlements.SelectedPartner ?
                                    <div className="defaultFormControl">
                                        <div className="defaultFormControl__label">Баланс: {this.props.mutualSettlements.Balance}грн</div>
                                        <div className="selectWrapper__container">
                                            <Select
                                                name="operationType"
                                                options={this.state.operationTypes}
                                                onChange={this.onChangeOperationTypeSelectCommand.bind(this)}
                                                value={this.props.mutualSettlements.SelectedOperationType}
                                                placeholder="Тип проведения операции"
                                            />
                                        </div>
                                        <div>
                                            <Route path={this.props.history.location.pathName} component={this.GetSelectedForm()} />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </PermissionChecker>

                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Взаиморасчеты</span>
                        <span className="title_description">Создание</span>
                    </div>

                    <PermissionChecker
                        Classes='page_title totalDol'
                        ViewRoles={[IdentityRoleEnum.Partner]}>
                        <div>
                            <span className="title_default">
                                {this.props.mutualSettlements.Balance}
                                <small>грн</small>
                            </span>
                            <span className="title_description">Текущий баланс</span>
                        </div>
                    </PermissionChecker>
                    <div className="header__button__controls">
                        <details className="dStyle" open>
                            <summary>
                                <div className="summaryFilter" />
                            </summary>
                            { (this.props.mutualSettlements.IsFiltered) &&
                                <PermissionChecker 
                                    Classes='button__control'
                                    ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                    <div onClick={() => { this.ExportMutualSettlements() }}>
                                        Экспорт взаимо-рассчетов
                                    </div>
                                </PermissionChecker>
                            }

                            {
                                (this.props.mutualSettlements.IsFiltered && this.props.mutualSettlements.ExportFileName) &&
                                    <PermissionChecker 
                                        Classes='button__control'
                                        ViewRoles={[IdentityRoleEnum.Partner, IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                                        <a href={`${API.SERVER_URL}${this.props.mutualSettlements.ExportFileName}`}
                                            download={`mutual_settlements_export_${moment(new Date()).tz('Europe/Kiev').format('dd_MM_YYYY')}.xlsx`}>Скачать файл</a>
                                    </PermissionChecker>
                            }

                            <PermissionChecker
                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Booker]} 
                                Classes="buttonForSelectFile_container"
                                >
                                <div className="buttonForSelectFile">
                                    <label className="selectFileWithPromocodesButton">
                                        <input type="file" onChange={(event) => { this.onSelectFileWithPromocodes(event) }} />
                                        <div className="uLoad">Загрузить файл</div>
                                    </label>
                                </div>
                            </PermissionChecker>
                            
                            { (this.props.mutualSettlements.FileForImportAfterpayments) ?
                            <PermissionChecker
                                Classes='button__control'
                                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Booker]}
                            >
                                <div onClick={() => this.onSaveFile()}>Сохранить изменения</div>
                                </PermissionChecker> : null
                            }
                            
                            <div className="button__control"
                                onClick={() => { this.ResetFilter() }}>
                                Сброс фильтров
                            </div>

                            <div className="button__control"
                                onClick={this.OnOpenFilterRvCommand.bind(this)}>
                                Фильтр
                            </div>
                        </details>

                        <details className="mStyle">
                            <summary>
                                <div className="summaryFilter" />
                            </summary>
                            <div className="button__control"
                                onClick={() => { this.ResetFilter() }}>
                                Сброс фильтров
                            </div>

                            <div className="button__control"
                                onClick={this.OnOpenFilterRvCommand.bind(this)}>
                                Фильтр
                            </div>
                        </details>
                    </div>
                </div>
                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">
                                        <div className="grid_cell">
                                            <span className="cell_content">№</span>
                                        </div>

                                        <PermissionChecker ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])} simple>
                                            <div className="grid_cell">
                                                <span className="cell_content">Партнёр</span>
                                            </div>
                                        </PermissionChecker>
                                        <div className="grid_cell">
                                            <span className="cell_content">Операция</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Сума</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Баланс</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Заказ</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Причина</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Комментарий</span>
                                        </div>
                                        <div className="grid_cell">
                                            <span className="cell_content">Дата</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {!this.props.mutualSettlements.Fetching ?
                                <div className="grid_scroll_content_component">
                                    <div className="grid_wrapper_component">
                                        {this.props.mutualSettlements.MutualSettlements && this.props.mutualSettlements.MutualSettlements.map((item, index) => {
                                            return (
                                                <div key={index} className="grid_row mMEDIA___mutualSettlements_ROW ">
                                                    <div className="grid_cell mutualSettlements___CELL mutualSettlements_ID__CELL" data-tooltip="№">
                                                        <span className="cell_content">{item.Id}</span>
                                                    </div>
                                                    <PermissionChecker ViewRoles={AllIdentityRolesExept([IdentityRoleEnum.Partner])} simple>
                                                        <div className="grid_cell mutualSettlements___CELL mutaualSettlementName__CELL" data-tooltip="Партнёр">
                                                            <span className="cell_content">{item.Partner? item.Partner.ContactName : ''}</span>
                                                        </div>
                                                    </PermissionChecker>

                                                    <div className="grid_cell mutualSettlements___CELL mutualSettlements_Amount__CELL" data-tooltip="Операция">
                                                        <span className="cell_content">{GetMutaualSettlementName(item.OperationType)}</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mutualSettlements_Amount__CELL" data-tooltip="Сума">
                                                        <span className="cell_content">{`${this.GetSymbol(item.OperationType)}${item.Amount}`}</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mutualSettlements_Amount__CELL" data-tooltip="Баланс">
                                                        <span className="cell_content">{`${item.Balance}`}</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mSOrderCode__CELL" data-tooltip="Заказ">
                                                        <span className="cell_content">{item.Order && item.Order.NetUid ?
                                                            <NavLink className="cell_content" to={`/sales/order-card/${item.Order.NetUid}`}>
                                                                {item.Order.OrderCode}
                                                            </NavLink>
                                                            :
                                                            ""
                                                        }</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mSReason__CELL" data-tooltip="Причина">
                                                        <span className="cell_content">{item.Reason}</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mSComment__CELL" data-tooltip="Комментарий">
                                                        <span className="cell_content over__TEXT" title={item.Comment}>{item.Comment}</span>
                                                    </div>
                                                    <div className="grid_cell mutualSettlements___CELL mutualSettlements_PaymentDate__CELL" data-tooltip="Дата">
                                                        <span className="cell_content">{item.PaymentDate ? moment(item.PaymentDate).format("DD/MM/YYYY HH:mm") : moment(item.Created).format("DD/MM/YYYY HH:mm")}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div> : <Loader/>
                            }
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        selectComponentClass={SelectForPaginate}
                        showSizeChanger={true}
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.props.mutualSettlements.Count}
                        defaultCurrent={this.props.mutualSettlements.Page}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.mutualSettlements.TotalPages}
                    />
                </div>
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MutualSettlements);


