import { RightViewState } from "../states/right.view.state";

export const SELECT_RV_DATA: string = 'SELECT_RV_DATA';
export interface ISelectRVData { type: 'SELECT_RV_DATA'; payload: any; }
export const SelectRVData = (rvData: RightViewState) => <ISelectRVData>{ type: SELECT_RV_DATA, payload: rvData };

export const REMOVE_RV_DATA: string = 'REMOVE_RV_DATA';
export interface IRemoveRVData { type: 'REMOVE_RV_DATA'; payload: any; }
export const RemoveRVData = () => <IRemoveRVData>{ type: REMOVE_RV_DATA };

export const CHANGE_RV_TITLE: string = 'CHANGE_RV_TITLE';
export interface IChangeRVTitle { type: 'CHANGE_RV_TITLE'; payload: any; }
export const ChangeRVTitle = (title) => <IChangeRVTitle>{ type: CHANGE_RV_TITLE, payload: title };

export const CHANGE_RV_SIZE: string = 'CHANGE_RV_SIZE';
export interface IChangeRVSize { type: 'CHANGE_RV_SIZE'; payload: any; }
export const ChangeRVSize = (size) => <IChangeRVTitle>{ type: CHANGE_RV_SIZE, payload: size };

export const SHOW_HOVER_BLOCK: string = 'SHOW_HOVER_BLOCK';
export interface IShowHoverBlock { type: 'SHOW_HOVER_BLOCK'; payload: any; }
export const ShowHoverBlock = (data) => <IShowHoverBlock>{ type: SHOW_HOVER_BLOCK, payload: data};

export const SET_DISPLAYING_IMAGE: string = 'SET_DISPLAYING_IMAGE';
export interface ISetDisplayingImage { type: 'SET_DISPLAYING_IMAGE'; payload: any; }
export const SetDisplayingImage = (data) => <ISetDisplayingImage>{ type: SET_DISPLAYING_IMAGE, payload: data};

export type RightViewKnownActions =
    ISelectRVData | IRemoveRVData | IChangeRVTitle | IChangeRVSize | IShowHoverBlock | ISetDisplayingImage;
