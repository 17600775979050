import * as API from '../../../../../constants/api.constants';
import * as React from 'react';
import { ApplicationState } from '../../../../../reducers';
import { bindActionCreators } from 'redux';
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SetUserImage,
    SetUserImageComment,
    SetUserPreview,
    UploadUserImage,
    UploadUserPreview
    } from '../../../../../actions/sales/wizard.actions';
import { connect } from 'react-redux';
import { FindValueOperator } from 'rxjs/internal/operators/find';
import { OnGoToNextStepCommand, OnGoToPrevStepCommand } from '../../../../../helpers/wizard.substep.helper';
import { OrderProductFileTypeEnum } from '../../../../../helpers/enums/order.productfile.type.enum';

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SetNextSubStep,
    CancelCurrentOrderProduct,
    UploadUserImage,
    UploadUserPreview,
    SetUserPreview,
    SetUserImage,
    SetUserImageComment
}, dispatch);


const UserImage = (props: any) => {

    document.onkeydown = (event) => {
        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39 || (event as any).keyCode === 13) {
            event.preventDefault();
            OnGoToNextStepCommand(props);
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnFileUpload = (event) => {
        const files = Array.from(event.target.files)

        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);

        props.UploadUserImage(formData, props.Wizard.order.NetUid, OrderProductFileTypeEnum.UserImage);
    }

    const OnPreviewUpload = (event) => {
        const files = Array.from(event.target.files)

        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);

        props.UploadUserPreview(formData, props.Wizard.order.NetUid, OrderProductFileTypeEnum.Preview);
    }

    const RemoveCurrentFile = (type) => {
        props.SetUserImage(undefined, type);
    }

    const OnSetUserImageCommentCommand = (value) => {
        props.SetUserImageComment(value);
    }

    const IsZipFile = (url: string) => {
        if (!url)
            return false;

        let array = url.split('.');

        return array[array.length - 1] === 'zip' || array[array.length - 1].toLowerCase() === 'psd';
    }

    return (
        <div className="userImageView__content uiContainer">

            <div className="userImageContainer__contentLabel">Изображение</div>
            <div className="userImageContainer__contentWrapper">
                <div className="uploadImageWrapper__container uiConItem">
                    <div className="uploadImageWrapper__item"
                        style={{ backgroundImage: `url(${API.SERVER_URL}${props.Wizard.currentOrderProduct.UserPrewiev})` }}
                        onClick={() => RemoveCurrentFile(OrderProductFileTypeEnum.Preview)}>
                        {!props.Wizard.currentOrderProduct.UserPrewiev &&
                            <label>
                                <input type="file" onChange={(event) => OnPreviewUpload(event)} />
                            </label>
                        }
                        <div className="lab">Превью</div>
                    </div>
                </div>
                <div className="uploadImageWrapper__container uiConItem">
                    <div className={`uploadImageWrapper__item ${IsZipFile(props.Wizard.currentOrderProduct.UserImage) ? 'zipp' : ''}`}
                        style={{ backgroundImage: `url(${API.SERVER_URL}${props.Wizard.currentOrderProduct.UserImage})` }}
                        onClick={() => RemoveCurrentFile(OrderProductFileTypeEnum.UserImage)}>
                        {!props.Wizard.currentOrderProduct.UserImage &&
                            <label>
                                <input type="file" onChange={(event) => OnFileUpload(event)} />
                            </label>
                        }
                        <div className="lab">Файл</div>
                    </div>
                </div>

                <div className="uploadImageComennt__container">
                    <div className="uploadImageControl">
                        <div className="uploadImageControl__label">Коментарий</div>
                        <div className="uploadImageControl__value">
                            <textarea value={props.Wizard.currentOrderProduct.UserImageComment}
                                onChange={(event) => OnSetUserImageCommentCommand(event.target.value)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(UserImage)