export enum ProductionStatusEnum {
    WaitingForAssembling = 1,
    WaitingForProduction,
    Producted
}

export const GetProductionStatusName = (status) => {
    switch (status) {
        case ProductionStatusEnum.WaitingForAssembling:
            return 'Ожидает сборки';
        case ProductionStatusEnum.WaitingForProduction:
            return 'Готов к пошиву';
        case ProductionStatusEnum.Producted:
            return 'Пошит';
        default:
            return '';
    }
}

export const FilterStatusList = [
    {
        value: ProductionStatusEnum.WaitingForProduction,
        label: GetProductionStatusName(ProductionStatusEnum.WaitingForProduction)
    },
    {
        value: ProductionStatusEnum.Producted,
        label: GetProductionStatusName(ProductionStatusEnum.Producted)
    }
];

export const FilterStatusPartnerList = [
    {
        value: ProductionStatusEnum.Producted,
        label: GetProductionStatusName(ProductionStatusEnum.Producted)
    }
];