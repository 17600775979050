import { TextBoxWithLabel } from "../../../shared/form.controls/textbox.with.label";
import { Validator } from "../../../../helpers/validator.helper";
import { ProductColor } from "../../../../business.entities/product.color";
import { Product } from "../../../../business.entities/product";
import * as actionCreators from "../../../../actions/stock/product.actions"
import { Form, actions } from "react-redux-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { List } from "linqts";
import { SelectControl } from "../../../shared/custom.controls/select";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum } from '../../../../helpers/identity.roles';

namespace ProductFormComponent {
    export interface IProps {
        productFormModel?: Product;
        UpdateProduct: any;
        SelectProductColor: any;
        selectedProductColor: ProductColor;
        ChangeForm: any;
        ResetForm: any;
        UpdateProductColor: any;
        UpdateProductImage: any;
        UpdateProductColorImage: any;
    }
}

const mapStateToProps = (state) => {
    return {
        productFormModel: state.productForm,
        productColorFormModel: state.productColorForm,
        selectedProductColor: state.stock.product.selectedProductColor
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        UpdateProduct: actionCreators.UpdateProduct,
        SelectProductColor: actionCreators.SelectProductColor,
        ChangeForm: actions.change,
        ResetForm: actions.reset,
        UpdateProductColor: actionCreators.UpdateProductColor,
        UpdateProductImage: actionCreators.UpdateProductImage,
        UpdateProductColorImage: actionCreators.UpdateProductColorImage
    }, dispatch);
};

const ProductFormComponent = (props: ProductFormComponent.IProps) => {
    const OnChangeSelectCommand = (target) => {
        let selectedProductColor: ProductColor = new List(props.productFormModel.ProductColors)
            .Where(c => c.Id == target.value as number)
            .FirstOrDefault();

        let selectedProductColorCopy = new ProductColor();
        selectedProductColorCopy.Color = selectedProductColor.Color;
        selectedProductColorCopy.ColorId = selectedProductColor.ColorId;
        selectedProductColorCopy.CreatedDate = selectedProductColor.CreatedDate;
        selectedProductColorCopy.Id = selectedProductColor.Id;
        selectedProductColorCopy.isRemoved = selectedProductColor.isRemoved;
        selectedProductColorCopy.Product = selectedProductColor.Product;
        selectedProductColorCopy.ProductId = selectedProductColor.ProductId;
        selectedProductColorCopy.UpdatedDate = selectedProductColor.UpdatedDate;
        selectedProductColorCopy.Quantity = selectedProductColor.Quantity;
        selectedProductColorCopy.PictureUrl1 = selectedProductColor.PictureUrl1 ? selectedProductColor.PictureUrl1 : "";
        selectedProductColorCopy.PictureUrl2 = selectedProductColor.PictureUrl2 ? selectedProductColor.PictureUrl2 : "";
        selectedProductColorCopy.PictureUrl3 = selectedProductColor.PictureUrl3 ? selectedProductColor.PictureUrl3 : "";
        selectedProductColorCopy.PictureUrl4 = selectedProductColor.PictureUrl4 ? selectedProductColor.PictureUrl4 : "";
        selectedProductColorCopy.PictureUrl5 = selectedProductColor.PictureUrl5 ? selectedProductColor.PictureUrl5 : "";

        props.ChangeForm('productColorForm', selectedProductColorCopy);
    }

    const GetOptions = () => {
        let model = [];

        props.productFormModel.ProductColors.forEach(element => {
            model.push({ value: element.Id, name: element.Color.Name });
        });

        return model;
    }

    const OnProductImageUpload = (event) => {
        const files = Array.from(event.target.files)

        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);

        props.UpdateProductImage(props.productFormModel.Id, formData);
    }

    const OnProductColorImageUpload = (event, number) => {
        const files = Array.from(event.target.files)

        let formData = new FormData();

        formData.append('files', files[0] as any, (files[0] as any).name);

        props.UpdateProductColorImage((props as any).productColorFormModel.Id, number, formData);
    }

    return (
        <div>
            <Form
                validateOn='change'
                model="productForm"
                onSubmit={props.UpdateProduct.bind(this)}>
                <div className="default_form_component shadow_controls">
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Категория</div>
                        <div className="defaultFormControl__value notPadd">
                            <input type="text" defaultValue={`${props.productFormModel.Category ? props.productFormModel.Category.Name : undefined}`} readOnly />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Подкатегория</div>
                        <div className="defaultFormControl__value notPadd">
                            <input type="text" defaultValue={`${props.productFormModel.SubCategory ? props.productFormModel.SubCategory.Name : undefined}`} readOnly />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Официальное название</div>
                        <div className="defaultFormControl__value notPadd">
                            <input type="text" defaultValue={`${props.productFormModel.Lexicon ? props.productFormModel.Lexicon.OfficialPartnerName : undefined} для ${props.productFormModel.Category ? props.productFormModel.Category.Name : undefined} ${props.productFormModel.SubCategory ? props.productFormModel.SubCategory.Name : undefined}`} readOnly />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Лексикон</div>
                        <div className="defaultFormControl__value notPadd">
                            <input type="text" defaultValue={`${props.productFormModel.Lexicon ? props.productFormModel.Lexicon.CasualName : undefined}`} readOnly />
                        </div>
                    </div>


                    <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]} EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                        <TextBoxWithLabel
                            Validators={{
                                isRequired: (val: string) => !Validator.IsEmpty(val),
                                isPrice: (val: string) => Validator.IsPriceMatchToPattern(val)
                            }}
                            ErrorMessages={{
                                isRequired: '*',
                                isPrice: "Цена может быть только число, которое > 0 и не начинаться с 0 или ."
                            }}
                            ShowErrorOn='touched'
                            Type='text'
                            ValidatedOn='change'
                            LabelMessage='Цена для партнёра'
                            IsRequired={true}
                            Model='.PartnerPrice' />
                    </PermissionChecker>

                    <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]} EditRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Admin]}>
                        <TextBoxWithLabel
                            Validators={{
                                isRequired: (val: string) => !Validator.IsEmpty(val),
                                isPrice: (val: string) => Validator.IsPriceMatchToPattern(val)
                            }}
                            ErrorMessages={{
                                isRequired: '*',
                                isPrice: "Цена может быть только число, которое > 0 и не начинаться с 0 или ."
                            }}
                            ShowErrorOn='touched'
                            Type='text'
                            ValidatedOn='change'
                            LabelMessage='РРЦ'
                            IsRequired={true}
                            Model='.RecomendedPartnerPrice' />
                    </PermissionChecker>

                    <TextBoxWithLabel
                        Type='text'
                        LabelMessage='Код товара'
                        IsRequired={true}
                        Model='.PartnerProductCode'
                        Readonly />

                    {props.productFormModel.ProductFoto ?
                        <TextBoxWithLabel
                            ShowErrorOn='touched'
                            Type='text'
                            ValidatedOn='change'
                            LabelMessage='Фото продукта'
                            IsRequired={false}
                            Model='.ProductFoto'
                            IsLink /> :
                        <div className="defaultFormControl ">
                            <label className="onFileUploadControl">
                                <div className="label">
                                    Фото
                                    <input type="file" accept="image/*" onChange={(event) => OnProductImageUpload(event)} />
                                </div>
                            </label>
                        </div>
                    }

                    <div className="full_list_color">
                        {
                            props.productFormModel && props.productFormModel.ProductColors &&
                            props.productFormModel.ProductColors.map((productColor: ProductColor, index) => {
                                (productColor.Color) ?
                                    <div className="color_item" key={index}>
                                        <div className="color" style={{ backgroundColor: productColor.Color.Code }}></div>
                                        <div className="quantity">{productColor.Quantity}</div>
                                    </div> :
                                    null
                            })
                        }
                    </div>

                    <div className="defaultFormControl">
                        <button type="submit" className="defaultFormButton" >Сохранить</button>
                    </div>
                </div>
            </Form>

            {
                GetOptions().length > 0 ?
                    <div>
                        <div className="default_form_component">
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">Цвет</div>
                                <div className="selectWrapper__container notPadd">
                                    <SelectControl
                                        onChangeMethod={OnChangeSelectCommand}
                                        options={GetOptions()}
                                    />
                                </div>
                            </div>
                        </div>

                        <Form
                            validateOn='change'
                            model="productColorForm"
                            onSubmit={props.UpdateProductColor.bind(this)}>
                            <div className="default_form_component">

                                {(props as any).productColorFormModel.PictureUrl1 ?
                                    <TextBoxWithLabel
                                        Type='text'
                                        LabelMessage='Ссылка 1'
                                        IsRequired={false}
                                        Model='.PictureUrl1'
                                        IsLink /> :
                                    <div className="defaultFormControl ">
                                        <label className="onFileUploadControl">
                                            <div className="label">
                                                Фото
                                                <input type="file" accept="image/*" onChange={(event) => OnProductColorImageUpload(event, 1)} />
                                            </div>
                                        </label>
                                    </div>
                                }


                                {(props as any).productColorFormModel.PictureUrl2 ?
                                    <TextBoxWithLabel
                                        Type='text'
                                        LabelMessage='Ссылка 2'
                                        IsRequired={false}
                                        Model='.PictureUrl2'
                                        IsLink /> :
                                    <div className="defaultFormControl ">
                                        <label className="onFileUploadControl">
                                            <div className="label">
                                                Фото
                                                <input type="file" accept="image/*" onChange={(event) => OnProductColorImageUpload(event, 2)} />
                                            </div>
                                        </label>
                                    </div>
                                }

                                {(props as any).productColorFormModel.PictureUrl3 ?
                                    <TextBoxWithLabel
                                        Type='text'
                                        LabelMessage='Ссылка 3'
                                        IsRequired={false}
                                        Model='.PictureUrl3'
                                        IsLink /> :
                                    <div className="defaultFormControl ">
                                        <label className="onFileUploadControl">
                                            <div className="label">
                                                Фото
                                                <input type="file" accept="image/*" onChange={(event) => OnProductColorImageUpload(event, 3)} />
                                            </div>
                                        </label>
                                    </div>
                                }

                                {(props as any).productColorFormModel.PictureUrl4 ?
                                    <TextBoxWithLabel
                                        Type='text'
                                        LabelMessage='Ссылка 4'
                                        IsRequired={false}
                                        Model='.PictureUrl4'
                                        IsLink /> :
                                    <div className="defaultFormControl ">
                                        <label className="onFileUploadControl">
                                            <div className="label">
                                                Фото
                                                <input type="file" accept="image/*" onChange={(event) => OnProductColorImageUpload(event, 4)} />
                                            </div>
                                        </label>
                                    </div>
                                }

                                {(props as any).productColorFormModel.PictureUrl5 ?
                                    <TextBoxWithLabel
                                        Type='text'
                                        LabelMessage='Ссылка 5'
                                        IsRequired={false}
                                        Model='.PictureUrl5'
                                        IsLink /> :
                                    <div className="defaultFormControl ">
                                        <label className="onFileUploadControl">
                                            <div className="label">
                                                Фото
                                                <input type="file" accept="image/*" onChange={(event) => OnProductColorImageUpload(event, 5)} />
                                            </div>
                                        </label>
                                    </div>
                                }

                                <div className="defaultFormControl">
                                    <button type="submit" className="defaultFormButton" >Сохранить</button>
                                </div>
                            </div>
                        </ Form>
                    </div>
                    : null
            }
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductFormComponent)