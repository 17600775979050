import { SelectModel } from "../../helpers/select.model";
import { Category } from "../../business.entities/category";

// Lexicon 
export const GET_AUTOCOMPLETE_LEXICON_RESULT = "[API] GET_AUTOCOMPLETE_LEXICON_RESULT";
export interface IGetAutocompleteLexiconResult { type: "[API] GET_AUTOCOMPLETE_LEXICON_RESULT"; payload: any }
export const GetAutocompleteLexiconResult = (value: string) =>
  <IGetAutocompleteLexiconResult>{ type: GET_AUTOCOMPLETE_LEXICON_RESULT, payload: value };

export const GET_AUTOCOMPLETE_LEXICONS_SUCCESS = "GET_AUTOCOMPLETE_LEXICONS_SUCCESS";
export interface IGetAutocompliteLexiconsSuccess { type: "GET_AUTOCOMPLETE_LEXICONS_SUCCESS"; payload: any }
export const GetAutocompliteLexiconsSuccess = (optionData: any[]) =>
  <IGetAutocompliteLexiconsSuccess>{ type: GET_AUTOCOMPLETE_LEXICONS_SUCCESS, payload: optionData };

export const CHANGE_LEXICON_VALUE: string = 'CHANGE_LEXICON_VALUE';
export interface IChangeLexiconValue { type: 'CHANGE_LEXICON_VALUE'; payload: any; }
export const ChangeLexiconValue = (value: SelectModel) =>
  <IChangeLexiconValue>{ type: CHANGE_LEXICON_VALUE, payload: value };

// Model
export const GET_AUTOCOMPLETE_MODEL_RESULT: string = '[API] GET_AUTOCOMPLETE_MODEL_RESULT';
export interface IGetAutocompleteModelResult { type: '[API] GET_AUTOCOMPLETE_MODEL_RESULT'; payload: any; }
export const GetAutocompleteModelResult = (value: string) =>
  <IGetAutocompleteModelResult>{ type: GET_AUTOCOMPLETE_MODEL_RESULT, payload: value };

export const GET_AUTOCOMPLETE_MODEL_SUCCESS = "GET_AUTOCOMPLETE_MODEL_SUCCESS";
export interface IGetAutocompliteModelSuccess { type: "GET_AUTOCOMPLETE_MODEL_SUCCESS"; payload: any }
export const GetAutocompliteModelSuccess = (optionData: any[]) =>
  <IGetAutocompliteModelSuccess>{ type: GET_AUTOCOMPLETE_MODEL_SUCCESS, payload: optionData };

export const CHANGE_MODEL_VALUE: string = 'CHANGE_MODEL_VALUE';
export interface IChangeModelValue { type: 'CHANGE_MODEL_VALUE'; payload: any; }
export const ChangeModelValue = (value: SelectModel) =>
  <IChangeModelValue>{ type: CHANGE_MODEL_VALUE, payload: value };

// Articulus
export const GET_AUTOCOMPLETE_ARTICULUS_RESULT = "[API] GET_AUTOCOMPLETE_ARTICULUS_RESULT";
export interface IGetAutocompleteArticulusResult { type: "[API] GET_AUTOCOMPLETE_ARTICULUS_RESULT"; payload: any }
export const GetAutocompleteArticulusResult = (value: string) =>
  <IGetAutocompleteArticulusResult>{ type: GET_AUTOCOMPLETE_ARTICULUS_RESULT, payload: value };

export const GET_AUTOCOMPLETE_ARTICULUS_SUCCESS = "GET_AUTOCOMPLETE_ARTICULUS_SUCCESS";
export interface IGetAutocompliteArticulusSuccess { type: "GET_AUTOCOMPLETE_ARTICULUS_SUCCESS"; payload: any }
export const GetAutocompliteArticulusSuccess = (optionData: any[]) =>
  <IGetAutocompliteArticulusSuccess>{ type: GET_AUTOCOMPLETE_ARTICULUS_SUCCESS, payload: optionData };

export const CHANGE_ARTICULUS_VALUE: string = 'CHANGE_ARTICULUS_VALUE';
export interface IChangeArticulusValue { type: 'CHANGE_ARTICULUS_VALUE'; payload: any; }
export const ChangeArticulusValue = (value: SelectModel) =>
  <IChangeArticulusValue>{ type: CHANGE_ARTICULUS_VALUE, payload: value };

// FilterCategory
export const GET_FILTER_CATEGORY_RESULT = "[API] GET_FILTER_CATEGORY_RESULT";
export interface IGetFilterCategoryResult { type: "[API] GET_FILTER_CATEGORY_RESULT"; payload: any }
export const GetFilterCategoryResult = () =>
  <IGetFilterCategoryResult>{ type: GET_FILTER_CATEGORY_RESULT };

export const GET_FILTER_CATEGORY_SUCCESS = "GET_FILTER_CATEGORY_SUCCESS";
export interface IGetFilterCategoryListSuccess { type: "GET_FILTER_CATEGORY_SUCCESS"; payload: any }
export const GetFilterCategoryListSuccess = (optionData: any[]) =>
  <IGetFilterCategoryListSuccess>{ type: GET_FILTER_CATEGORY_SUCCESS, payload: optionData };

export const CHANGE_FILTER_CATEGORY_VALUE: string = 'CHANGE_FILTER_CATEGORY_VALUE';
export interface IChangeFilterCategoryValue { type: 'CHANGE_FILTER_CATEGORY_VALUE'; payload: any; }
export const ChangeFilterCategoryValue = (value: SelectModel) =>
  <IChangeFilterCategoryValue>{ type: CHANGE_FILTER_CATEGORY_VALUE, payload: value };

// FilterSubCategory
export const GET_AUTOCOMPLETE_FILTER_SUB_CATEGORY_RESULT = "[API] GET_AUTOCOMPLETE_FILTER_SUB_CATEGORY_RESULT";
export interface IGetAutocompleteFilterSubCategoryResult { type: "[API] GET_AUTOCOMPLETE_FILTER_SUB_CATEGORY_RESULT"; payload: any }
export const GetAutocompleteFilterSubCategoryResult = (categoryName: string, parent: SelectModel) =>
  <IGetAutocompleteFilterSubCategoryResult>{ type: GET_AUTOCOMPLETE_FILTER_SUB_CATEGORY_RESULT, payload: { categoryName, parent } };

export const GET_FILTER_SUB_CATEGORY_LIST_SUCCESS = "GET_FILTER_SUB_CATEGORY_LIST_SUCCESS";
export interface IGetFilterSubCategoryListSuccess { type: "GET_FILTER_SUB_CATEGORY_LIST_SUCCESS"; payload: any }
export const GetFilterSubCategoryListSuccess = (optionData: any[]) =>
  <IGetFilterSubCategoryListSuccess>{ type: GET_FILTER_SUB_CATEGORY_LIST_SUCCESS, payload: optionData };

export const CHANGE_FILTER_SUB_CATEGORY_VALUE: string = 'CHANGE_FILTER_SUB_CATEGORY_VALUE';
export interface IChangeFilterSubCategoryValue { type: 'CHANGE_FILTER_SUB_CATEGORY_VALUE'; payload: any; }
export const ChangeFilterSubCategoryValue = (value: SelectModel) =>
  <IChangeFilterSubCategoryValue>{ type: CHANGE_FILTER_SUB_CATEGORY_VALUE, payload: value };

// Reset
export const RESET_SELECTED_VALUES: string = 'RESET_SELECTEDVALUES';
export interface IResetSelectedValues { type: 'RESET_SELECTEDVALUES'; payload: any; }
export const ResetSelectedValues = () =>
  <IResetSelectedValues>{ type: RESET_SELECTED_VALUES };

// Categories
export const GET_CATEGORIES_FOR_SELECTED_PRODUCT = "[API] GET_CATEGORIES_FOR_SELECTED_PRODUCT";
export interface IGetCategoriesForSelectedProduct { type: "[API] GET_CATEGORIES_FOR_SELECTED_PRODUCT"; payload: any }
export const GetCategoriesForSelectedProduct = (filteredData: string) =>
  <IGetCategoriesForSelectedProduct>{ type: GET_CATEGORIES_FOR_SELECTED_PRODUCT, payload: filteredData };

export const GET_CATEGORIES_FOR_SELECTED_PRODUCT_SUCCESS = "GET_CATEGORIES_FOR_SELECTED_PRODUCT_SUCCESS";
export interface IGetCategoriesForSelectedProductSuccess { type: "GET_CATEGORIES_FOR_SELECTED_PRODUCT_SUCCESS"; payload: any }
export const GetCategoriesForSelectedProductSuccess = (categories: Category[], totalPages: number) =>
  <IGetCategoriesForSelectedProductSuccess>{ type: GET_CATEGORIES_FOR_SELECTED_PRODUCT_SUCCESS, payload: { categories, totalPages } };

  export const GET_PARTNER_PRICELIST = "[API] AVAILABILITY.GET_PARTNER_PRICELIST";
  export interface IGetPartnerPriceList { type: "[API] AVAILABILITY.GET_PARTNER_PRICELIST"; payload: any }
  export const GetPartnerPriceList = () =>
    <IGetPartnerPriceList>{ type: GET_PARTNER_PRICELIST };
  
export const UNMOUNT_COMPONENT = "AVAILABILITY.UNMOUNT_COMPONENT";
export interface IUnmounComponent { type: "AVAILABILITY.UNMOUNT_COMPONENT"; payload: any }
export const UnmounComponent = () =>
  <IUnmounComponent>{ type: UNMOUNT_COMPONENT};

export type AviabilityKnownActions =
  IGetCategoriesForSelectedProductSuccess |
  IGetCategoriesForSelectedProduct |
  IResetSelectedValues |
  IGetFilterSubCategoryListSuccess |
  IGetAutocompleteFilterSubCategoryResult |
  IChangeFilterSubCategoryValue |
  IChangeFilterCategoryValue |
  IGetFilterCategoryListSuccess |
  IGetFilterCategoryResult |
  IGetAutocompliteModelSuccess |
  IGetAutocompleteModelResult |
  IChangeLexiconValue |
  IChangeModelValue |
  IChangeArticulusValue |
  IGetAutocompliteLexiconsSuccess |
  IGetAutocompliteArticulusSuccess |
  IGetAutocompleteArticulusResult |
  IGetAutocompleteLexiconResult |
  IUnmounComponent | 
  IGetPartnerPriceList;