import { connect } from 'react-redux';
import * as React from 'react'
import { bindActionCreators } from 'redux';
import {
    SelectFileWithPromocodes, AddFileWithPromocodes, SaveFileWithPromocodes, SelectFileWithPromocodesName
} from '../../../../actions/partner/promocode.actions';
import { error, success } from 'react-notification-system-redux';
import { LoginState } from '../../../../states/login.state';
import { PromocodeState } from '../../../../states/partner/promocode.state';
import storeProvider from '../../../../helpers/store.provider';

const mapStateToProps = (state) => {
    return {
        Promocode: state.partner.promocode,
        Authorization: state.authentication,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    SelectFileWithPromocodes, 
    AddFileWithPromocodes, 
    SaveFileWithPromocodes,
    SelectFileWithPromocodesName
}, dispatch);

interface IPromocodeUploadRightViewProps {
    Promocode: PromocodeState;
    Authorization: LoginState;
    SelectFileWithPromocodes(value: any),
    AddFileWithPromocodes(value:any),
    SaveFileWithPromocodes(value:any),
    SelectFileWithPromocodesName(value:any);
}

class PromocodesImportComponent extends React.Component<IPromocodeUploadRightViewProps, any> {

    constructor(props){
        super(props)
    }

    public async onSelectFileWithPromocodes(event) {
        const files = Array.from(event.target.files)
        let formData = new FormData();
        
        if (files[0]){
            formData.append('files', files[0] as any, (files[0] as any).name);
            await this.props.SelectFileWithPromocodesName((files[0] as any).name);
            await this.props.SelectFileWithPromocodes(formData)
            storeProvider.getStore().dispatch(success({
                title: 'Файл выбран',
                message: '',
                position: 'br',
                autoDismiss: 3
            }));
        }
    }

    public async onAddFile(){
        if (this.props.Promocode.fileWithPromocodes){
            await this.props.AddFileWithPromocodes(this.props.Promocode.fileWithPromocodes);

            storeProvider.getStore().dispatch(success({
                title: 'Файл добавлен',
                message: '',
                position: 'br',
                autoDismiss: 3
            }));
        }
        else
            storeProvider.getStore().dispatch(error({
                title: 'Файл не выбран',
                message: '',
                position: 'br',
                autoDismiss: 3,
            }));
    }

    public async onSaveFile(){
        if (this.props.Promocode.fileWithPromocodesToSend){
            await this.props.SaveFileWithPromocodes(this.props.Promocode.fileWithPromocodesToSend);
        }
        else
            storeProvider.getStore().dispatch(error({
                title: 'Файл не добавлен',
                message: '',
                position: 'br',
                autoDismiss: 3,
            }));
    }

    render() {
        return (
            <div className="promocodesMainContainer">
                <div className="addPromocodesViaFile">Добавить с помощью файла</div> 
                <div className="promocodesInlineContainer">
                <div className="buttonForSelectFile">
                    <label className="selectFileWithPromocodesButton">
                        <input type="file" onChange={(event) => { this.onSelectFileWithPromocodes(event) }} />
                        <div className="uLoad">Загрузить файл</div>
                    </label>
                </div>
                    
                    <div className="addFileWithPromocodesComponent" onClick={() => this.onAddFile()}>Добавить</div>
                </div>
                {this.props.Promocode.fileToSendName ? <div className="loadedPromocode">Файл {this.props.Promocode.fileToSendName} загружен</div> : 
                                                            <div className="loadedPromocode">Файл не выбран</div> }
                <div>
                <div className="saveChangesForPromocodesButton" onClick={() => this.onSaveFile()}>Сохранить изменения</div>
                </div>
                <div className="instructionsForPromocodesLoading">
                    <span>* в excel-файле должно быть минимум 3 столбца</span>
                    <span>1 - Промокод(promocode)</span>
                    <span>2 - Тип/название Сертификата(promocode_type)</span>
                    <span>3 - Цена Сертификата (promocode_price)</span>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PromocodesImportComponent);