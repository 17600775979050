export enum FinancesStatsEnum {
    ReplenishmentSum,
    WithdrawalSum,
    PrintItemsSold,
    PrintItemsPartnerTotal,
    SaleItemSold,
    SaleItemPartnerTotal,
    ManufactureSold,
    ManufacturePartnerTotal,
    LaserSold,
    LaserPartnerTotal
};

export const FinancesStatsName = (status: FinancesStatsEnum) => {
    switch (status) {
        case FinancesStatsEnum.ReplenishmentSum:
            return 'Полученные на счет средства от партнеров';
        case FinancesStatsEnum.WithdrawalSum:
            return 'Возвращенные партнеру средства';
        case FinancesStatsEnum.PrintItemsSold:
            return 'Количество проданных товаров типа "Печать"';
        case FinancesStatsEnum.PrintItemsPartnerTotal:
            return 'Партнерская стоимость проданных товаров типа "Печать"';
        case FinancesStatsEnum.SaleItemSold:
            return 'Количество проданных товаров типа "Продажи"';
        case FinancesStatsEnum.SaleItemPartnerTotal:
            return 'Партнерская стоимость проданных товаров типа "Продажи"';
        case FinancesStatsEnum.ManufactureSold:
            return 'Количество проданных товаров типа "Производство"';
        case FinancesStatsEnum.ManufacturePartnerTotal:
            return 'Партнерская стоимость проданных товаров типа "Производство"';
        case FinancesStatsEnum.LaserSold:
            return 'Количество проданных товаров типа "Текстиль"';
        case FinancesStatsEnum.LaserPartnerTotal:
            return 'Партнерская стоимость проданных товаров типа "Текстиль"';
        default:
            return '';
    }
}

export const GetFinancesStatsForDiagrams = () => [
    { value: FinancesStatsEnum.ReplenishmentSum, label: FinancesStatsName(FinancesStatsEnum.ReplenishmentSum) },
    { value: FinancesStatsEnum.WithdrawalSum, label: FinancesStatsName(FinancesStatsEnum.WithdrawalSum) },
    { value: FinancesStatsEnum.PrintItemsSold, label: FinancesStatsName(FinancesStatsEnum.PrintItemsSold) },
    { value: FinancesStatsEnum.PrintItemsPartnerTotal, label: FinancesStatsName(FinancesStatsEnum.PrintItemsPartnerTotal) },
    { value: FinancesStatsEnum.SaleItemSold, label: FinancesStatsName(FinancesStatsEnum.SaleItemSold) },
    { value: FinancesStatsEnum.SaleItemPartnerTotal, label: FinancesStatsName(FinancesStatsEnum.SaleItemPartnerTotal) },
    { value: FinancesStatsEnum.ManufactureSold, label: FinancesStatsName(FinancesStatsEnum.ManufactureSold) },
    { value: FinancesStatsEnum.ManufacturePartnerTotal, label: FinancesStatsName(FinancesStatsEnum.ManufacturePartnerTotal) },
    { value: FinancesStatsEnum.LaserSold, label: FinancesStatsName(FinancesStatsEnum.LaserSold) },
    { value: FinancesStatsEnum.LaserPartnerTotal, label: FinancesStatsName(FinancesStatsEnum.LaserPartnerTotal) },
];


