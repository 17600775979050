import { Partner } from "../business.entities/partner";

export class LoginState {
    AuthenticationToken: string;
    RefreshToken: string;
    Role: string;
    UserName: string;
    UserNetUid: string;
    Exp: number;
    isLoginInProgress: boolean;
    isAuthenticated: boolean;
    userInfo: Partner;
    RefreshPromise: any;
}

export const defaultLoginState: LoginState = {
    AuthenticationToken: '',
    RefreshToken: '',
    Role: '',
    UserName: '',
    UserNetUid: '',
    Exp: 0,
    isAuthenticated: false,
    isLoginInProgress: false,
    userInfo: null,
    RefreshPromise: null
}