import * as orderCardActions from '../../../../actions/sales/order.card.actions';
import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { default as OrderCardMainComponent } from './components/order.card.main.component';
import { default as OrderProductUpdateComponent } from './components/order.products.update.component';

import { History } from 'history';
import { Loader } from '../../../shared/loader';
import { OrderCardState } from '../../../../states/sales/order.card.state';
import { RemoveRVData, SelectRVData } from '../../../../actions/right.view.actions';
import { Route } from 'react-router';

import { default as PopupWrapper } from './components/updateOrderProductSteps/wrapper';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetOrder: orderCardActions.GetOrderForOrderCard,
    RemoveRVData,
    SelectRVData,
    GetClients: orderCardActions.GetClientsForPartner,
    ResetClientRv: orderCardActions.ResetClientRv,
    SelectClient: orderCardActions.SelectClient,
    SetCurrentRecipient: orderCardActions.SetCurrentRecipient,
    SetCurrentDelivery: orderCardActions.SetCurrentDeliveryInfo,
    GetCitiesForAutocomplete: orderCardActions.GetCitiesForAutocomplete,
    SelectOrderProductId: orderCardActions.SelectOrderProductForRv,
    UnmountComponent: orderCardActions.UnmountComponent
}, dispatch);

interface IOrderCard {
    match: any;
    OrderCard: OrderCardState;
    GetOrder(netId): void;
    RemoveRVData(),
    SelectRVData(data),
    GetClients(value: string);
    ResetClientRv();
    SelectClient(value);
    SetCurrentRecipient(name, phone);
    SetCurrentDelivery(info: any);
    GetCitiesForAutocomplete(name): any;
    SelectOrderProductId(orderProductId): any;
    history: History;
    UnmountComponent();
}

class OrderCard extends React.Component<IOrderCard, any> {

    constructor(props) {
        super(props);
        
        this.props.GetOrder(this.props.match.params.netid);
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    render() {
        const orderCard: OrderCardState = this.props.OrderCard;
        const order = this.props.OrderCard.order;

        return (
            <div className="master_content_component menu_isNOT_missing orderCardComponent">
                {
                    !order ?
                        <Loader /> :
                        <div className="orderCardComponent__WRAP">
                            <Route path={this.props.match.url}
                                component={orderCard.productUpdateMode ? OrderProductUpdateComponent : OrderCardMainComponent} />

                            {orderCard.productUpdateSubState.orderProductSubStep && 
                                <Route path={`${this.props.match.url}`} component={PopupWrapper} />
                            }
                        </div>
                }
            </div>
        );
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderCard)

