export enum DeliveryType {
    Nova = 1,
    Ukr,
    Self = 4
}

export const GetDeliveryTypeName = (type) => {
    switch (type) {
        case DeliveryType.Nova:
            return 'Нова Пошта';
        case DeliveryType.Ukr:
            return 'УкрПошта';
        case DeliveryType.Self:
            return 'Самовывоз';
        default:
            return '';
    }
}