import * as React from 'react';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Form, actions } from 'react-redux-form';
import { Validator } from '../../../../helpers/validator.helper';
import { TextBoxWithLabel } from '../../../shared/form.controls/textbox.with.label';
import { TextAreaWithLabel } from '../../../shared/form.controls/textarea.with.label';
import { SelectControl } from '../../../shared/custom.controls/select';
import { RefundReasons } from '../../../../helpers/return.funds.reason';
import { MutualSettlementOperationType } from '../../../../helpers/enums/mutualsettlement.operation.type';
import { MutualSettlementsState } from '../../../../states/cabinet/mutual.settlements.state';
import * as mutualSettlementAction from "../../../../actions/cabinet/mutual.settlements.actions";
import { MutualSettlement } from '../../../../business.entities/mutual.settlement';

const mapStateToProps = (state) => {
    return {
        Form: state.mutualSettlementsRefundsForm,
        MutualSettlements: state.cabinet.mutualSettlements
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    Add: mutualSettlementAction.AddMutualSettlements,
    Change: actions.change,
    GetOrders: mutualSettlementAction.GetOrdersAutocomplete,
    SelectOrder: mutualSettlementAction.ChangeOrderSelect
}, dispatch);

const MutualSettlementsRefundsForm = (props: any) => {
    const AddMutualSettlement = (form: any) => {
        let mutualSettlementToAdd = new MutualSettlement();

        mutualSettlementToAdd.Amount = form.amount;
        mutualSettlementToAdd.Comment = form.comment;
        mutualSettlementToAdd.Reason = form.reason;
        mutualSettlementToAdd.OrderId =  (props.MutualSettlements as MutualSettlementsState).SelectedOrder.value;
        mutualSettlementToAdd.OperationType = MutualSettlementOperationType.Refunds;
        mutualSettlementToAdd.PartnerId = (props.MutualSettlements as MutualSettlementsState).SelectedPartner.partnerId;
        props.Add(mutualSettlementToAdd);
    }

    const OnChangeReasonCommmand = (value) => {
        props.Change('mutualSettlementsRefundsForm.reason', value.value);
    }

    const GetOrderForAutocomplete = (code) => {
        props.GetOrders((props.MutualSettlements as MutualSettlementsState).SelectedPartner.value, code);
    }

    const SetSelectedOrder = (value) => {
        props.SelectOrder(value);
    }

    return (
        <Form
            validateOn='change'
            model="mutualSettlementsRefundsForm"
            onSubmit={(form) => AddMutualSettlement(form)}>
            <div className="default_form_component shadow_controls">
                {/* <TextBoxWithLabel
                    Validators={{ isRequired: (val: string) => !Validator.IsEmpty(val) }}
                    ErrorMessages={{ isRequired: '*' }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='TTN'
                    IsRequired={true}
                    Model='.TTN' /> */}
                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">Номер заказа</div>
                    <div className="selectWrapper__container notPadd"></div>
                    <Select
                        name="OrderCode"
                        isClearable
                        onInputChange={GetOrderForAutocomplete}
                        clearValue={() => { GetOrderForAutocomplete(null) }}
                        options={props.MutualSettlements.AllOrders}
                        onChange={(value) => { SetSelectedOrder(value) }}
                        value={props.MutualSettlements.SelectedOrder}
                        placeholder="Заказ" />
                </div>

                <TextBoxWithLabel
                    Validators={{
                        isRequired: (val: string) => !Validator.IsEmpty(val.toString()),
                        isPrice: (val: number) => Validator.IsPriceMatchToPattern(val)
                    }}
                    ErrorMessages={{
                        isRequired: '*',
                        isPrice: 'Неверная сума'
                    }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Сумма'
                    IsRequired={true}
                    Model='.amount' />

                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">Причина</div>
                    <div className="selectWrapper__container notPadd">
                        <SelectControl
                            onChangeMethod={(value) => OnChangeReasonCommmand(value)}
                            options={RefundReasons}
                        />
                    </div>
                </div>



                {/* <TextBoxWithLabel
                    Validators={{ isRequired: (val: string) => !Validator.IsEmpty(val) }}
                    ErrorMessages={{ isRequired: '*' }}
                    ShowErrorOn='touched'
                    Type='text'
                    ValidatedOn='change'
                    LabelMessage='Причина'
                    IsRequired={true}
                    Model='.reason' /> */}

                <TextAreaWithLabel
                    Validators={{ isRequired: (val: string) => !Validator.IsEmpty(val) }}
                    ErrorMessages={{ isRequired: '*' }}
                    ShowErrorOn='touched'
                    ValidatedOn='change'
                    LabelMessage='Коментарий'
                    IsRequired={true}
                    Model='.comment' />

                <div className="defaultFormControl">
                    <button type="submit" className="defaultFormButton">Сохранить</button>
                </div>
            </div>
        </Form >
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MutualSettlementsRefundsForm);