import { bindActionCreators } from "redux";
import * as React from "react";
import { connect } from "react-redux";
import { OrderBaseState } from "../../../../states/sales/order.base.state";
import {
    GetOrdersForAssembling,
    AddOrderStatus,
    UpdateOrderTTN,
    ChangeTTNManual
} from '../../../../actions/sales/order.base.actions';
import { DelvieryInfoRvModel } from "../../../../helpers/delviery.info.rv.model";
import { DeliveryType } from "../../../../helpers/enums/delivery.type";
import { OrderStatusEnum } from "../../../../helpers/enums/order.status.enum";
import { OrderStatus } from "../../../../business.entities/order.status";
import Moment from "react-moment";
import { Observable } from "rxjs";
import { SetAllMutualSettlements } from "../../../../actions/cabinet/mutual.settlements.actions";
import PermissionChecker from '../../../shared/permission.checker.component';
import { IdentityRoleEnum, AllIdentityRolesExept } from '../../../../helpers/identity.roles';
import { ClipboardHelper } from "../../../../helpers/clipboardHelper";
import { StockStatusEnum } from "../../../../helpers/enums/stock.status.enum";
import { Order } from "../../../../business.entities/order";
import { SERVER_URL, UKR_POSHTA_PDF_DOWNLOAD_ENDPOINT } from "../../../../constants/api.constants";
import storeProvider from "../../../../helpers/store.provider";

const mapStateToProps = (state) => {
    return {
        OrderBase: state.sales.orderBase
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    GetOrdersForAssembling,
    AddOrderStatus,
    UpdateOrderTTN,
    ChangeTTNManual
}, dispatch);

const DeliveryInfo = (props: any) => {
    const OnGetDeliveryTypeNameCommand = (type: DeliveryType) => {
        switch (type) {
            case DeliveryType.Nova:
                return "Нова пошта";
            case DeliveryType.Ukr:
                return "Укр пошта";
            case DeliveryType.Self:
                return "Самовывоз";
            default:
                return '';
        }
    }

    const deliveryInfo = {
        DelvieryType: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].DeliveryType,
        RecipientName: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].RecipientName,
        RecipientPhoneNumber: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].RecipientPhoneNumber,

        UkrPoshtaCityName: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].UkrPoshtaCityName,
        UkrPoshtaStreetName: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].UkrPoshtaStreetName,
        UkrPoshtaBuildingNumber: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].UkrPoshtaBuildingNumber,
        UkrPoshtaFlatNumber: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].UkrPoshtaFlatNumber,
        UkrPoshtaZipNumber: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].UkrPoshtaZipNumber,

        NovaPoshtaCityName: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].NovaPoshtaCityName,
        NovaPoshtaWarehouseName: (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].NovaPoshtaWarehouseName,
    } as DelvieryInfoRvModel;

    const SetSendedStatus = () => {
        if (props.OrderBase.fetchInProgress === true)
            return;

        if ((props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].OrderStatus[0].Status
            === OrderStatusEnum.Sended)
            return;

        if ((props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Confirmed
            && (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].OrderStatus[0].Status !== OrderStatusEnum.Sended)
            return;

        const statusToAdd = new OrderStatus;
        statusToAdd.OrderId = (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].Id;

        statusToAdd.Status = OrderStatusEnum.Sended;

        props.AddOrderStatus(statusToAdd);
    }

    const OnOpenTtn = () => {

        fetch(UKR_POSHTA_PDF_DOWNLOAD_ENDPOINT + '?urkPoshtaShipmentId=' + props.OrderBase.orders[props.OrderBase.currentOrderIndex].UkrPoshtaShipmentId + '&token=784a36e6-adea-41fd-878c-35cbb4a4f343', {
            method: 'GET',
            headers: {
                "Authorization": 'Bearer ' + storeProvider.getStore().getState().authentication.AuthenticationToken
            },
        }).then(async (response: any) => {
            
            if (response.status == 200) {

                var responseJson = await response.json();

                var binary_string = window.atob(responseJson.Body);
                var len = binary_string.length;
                var bytes = new Uint8Array(len);
                for (var i = 0; i < len; i++) {
                    bytes[i] = binary_string.charCodeAt(i);
                }
                var file = new Blob([bytes.buffer], { type: 'application/pdf' });
                var fileUrl = window.URL.createObjectURL(file);
                window.open(fileUrl, "_self");
            }
        })
    }

    const NeedToShowManualTTN = () => {
        const currentOrder = (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex];


        if (currentOrder.OrderType === 1 &&
            (currentOrder.StockStatus.length != 0 && currentOrder.StockStatus[0].Status === StockStatusEnum.Available)
            && currentOrder.OrderStatus[0].Status === OrderStatusEnum.Confirmed)
            return true;

        if (currentOrder.OrderType === 2 &&
            (currentOrder.PrintStatus.length != 0 && currentOrder.PrintStatus[0].Status === 6) &&
            currentOrder.OrderStatus[0].Status === 1)
            return true;

        if (currentOrder.OrderType === 3 &&
            (currentOrder.ProductionStatus.length != 0 && currentOrder.ProductionStatus[0].Status === 3) &&
            currentOrder.OrderStatus[0].Status === 1)
            return true;

        if (currentOrder.OrderType === 4 &&
            (currentOrder.PrintStatus.length != 0 && currentOrder.PrintStatus[0].Status === 6) &&
            (currentOrder.ProductionStatus.length != 0 && currentOrder.ProductionStatus[0].Status === 3) &&
            currentOrder.OrderStatus[0].Status === 1)
            return true;

        const afterStartDeliveryStatuses = [OrderStatusEnum.Sended, OrderStatusEnum.OnTheWay, OrderStatusEnum.Delivered, OrderStatusEnum.Recived,
                                            OrderStatusEnum.WaitingForReturn, OrderStatusEnum.Return]

        if (afterStartDeliveryStatuses.some(status => status === currentOrder.OrderStatus[0].Status))
            return true;

        return false;
    }

    return (
        <div className="rightView__deliveryInfo">
            <PermissionChecker
                Classes='defaultFormControl'
                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Sklad, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya]}>
                {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN ?
                    <>
                        <div className="defaultFormControl__label">
                            Отправлен
                        </div>
                        <div className="defaultFormControl__value setSendedStatus" onClick={() => SetSendedStatus()}>
                            {
                                (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].OrderStatus[0].Status >= OrderStatusEnum.Sended ?
                                    <div className="yI">Да</div> :
                                    <div className="nI">Нет</div>
                            }

                        </div>
                    </> :
                    null
                }
            </PermissionChecker>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">
                    Способ доставки
                </div>
                <div className="defaultFormControl__value">
                    <div className="imitation_inputText">
                        {OnGetDeliveryTypeNameCommand(deliveryInfo.DelvieryType)}
                    </div>
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">
                    Имя получателя
                    </div>
                <div className="defaultFormControl__value">
                    <div className="imitation_inputText">
                        {deliveryInfo.RecipientName}
                    </div>
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">
                    Номер получателя
                    </div>
                <div className="defaultFormControl__value">
                    <div className="imitation_inputText">
                        {deliveryInfo.RecipientPhoneNumber}
                    </div>
                </div>
            </div>

            <div className="defaultFormControl">
                <div className="defaultFormControl__label">
                </div>
                <div className="defaultFormControl__value">
                    {
                        deliveryInfo.DelvieryType == DeliveryType.Nova ?
                            <div className="imitation_inputText">
                                <ul>
                                    <li>
                                        <div className="liLabel">Город</div>
                                        <div className="livalue">{deliveryInfo.NovaPoshtaCityName}</div>
                                    </li>
                                    <li>
                                        <div className="liLabel">Склад</div>
                                        <div className="livalue">{deliveryInfo.NovaPoshtaWarehouseName}</div>
                                    </li>
                                </ul>
                            </div> :
                            <div className="imitation_inputText">
                                <ul>
                                    <li>
                                        <div className="liLabel">Город</div>
                                        <div className="livalue">{deliveryInfo.UkrPoshtaCityName}</div>
                                    </li>
                                    <li>
                                        <div className="liLabel">Улица</div>
                                        <div className="livalue">{deliveryInfo.UkrPoshtaStreetName}</div>
                                    </li>
                                    <li>
                                        <div className="liLabel">Дом</div>
                                        <div className="livalue">{deliveryInfo.UkrPoshtaBuildingNumber}</div>
                                    </li>
                                    <li>
                                        <div className="liLabel">Квартира</div>
                                        <div className="livalue">{deliveryInfo.UkrPoshtaFlatNumber}</div>
                                    </li>
                                    <li>
                                        <div className="liLabel">Индекс</div>
                                        <div className="livalue">{deliveryInfo.UkrPoshtaZipNumber}</div>
                                    </li>
                                </ul>
                            </div>
                    }
                </div>
            </div>

            {/* {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN ?
                <PermissionChecker
                    Classes='defaultFormControl'>
                    <div className="defaultFormControl__label" onClick={() => OnOpenTtn()}>
                        ТТН
                    </div>
                    <div className="defaultFormControl__value" >
                        <div className="imitation_inputText">
                            {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN}
                        </div>
                    </div>
                </PermissionChecker> :
                null
            } */}


            <PermissionChecker
                Classes='defaultFormControl'
                ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator, IdentityRoleEnum.Maysternya, IdentityRoleEnum.Sklad]}>
                <div className="defaultFormControl__label" onClick={() => OnOpenTtn()}>
                    ТТН
                </div>
                <div className="defaultFormControl__value" >
                    <div className="imitation_inputText">

                        {NeedToShowManualTTN() ?
                            <>
                                <input type="text" value={props.OrderBase.CustomTTN ? props.OrderBase.CustomTTN : (props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN}
                                    onChange={(event) => props.ChangeTTNManual(event.target.value)} />
                                <div className="defaultFormControl">
                                    <button className="defaultFormButton" onClick={() => props.UpdateOrderTTN((props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].Id, props.OrderBase.CustomTTN)}>
                                        Save
                                    </button>
                                </div>
                            </> :
                            <>
                                {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN}
                            </>

                        }
                    </div>
                </div>
            </PermissionChecker>

            {/* {!(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN ?
                <PermissionChecker
                    Classes='defaultFormControl'
                    ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]}>
                    <div className="defaultFormControl__label" onClick={() => OnOpenTtn()}>
                        ТТН
                    </div>
                    <div className="defaultFormControl__value" >
                        <div className="imitation_inputText">

                            {NeedToShowManualTTN() ?
                                <>
                                    <input type="text" value={props.OrderBase.CustomTTN}
                                        onChange={(event) => props.ChangeTTNManual(event.target.value)} />
                                    <div className="defaultFormControl">
                                        <button className="defaultFormButton" onClick={() => props.UpdateOrderTTN((props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].Id, props.OrderBase.CustomTTN)}>
                                            Save
                                        </button>
                                    </div>
                                </> :
                                <>
                                 {(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].TTN}
                                </>
                                
                            }
                        </div>
                    </div>
                </PermissionChecker> :
                null
            } */}

            {props.OrderBase.orders[props.OrderBase.currentOrderIndex].SendedStatus ?
                <div className="defaultFormControl">
                    <div className="defaultFormControl__label">
                        Дата отправки
                    </div>
                    <div className="defaultFormControl__value">
                        <div className="imitation_inputText">
                            <Moment date={(props.OrderBase as OrderBaseState).orders[(props.OrderBase as OrderBaseState).currentOrderIndex].SendedStatus.Created} format='DD/MM/YYYY HH:mm' />
                        </div>
                    </div>
                </div> : null}
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfo)