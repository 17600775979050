import * as orderCardActions from '../../actions/sales/order.card.actions';
import { defaultOrderCardState, OrderCardState, defaultProductUpdateState, ProductUpdateState } from '../../states/sales/order.card.state';
import { Reducer } from 'redux';
import { Order } from '../../business.entities/order';
import { OrderProduct } from '../../business.entities/order.product';
import { OrderProductFileTypeEnum } from '../../helpers/enums/order.productfile.type.enum';
import { OrderStatus } from '../../business.entities/order.status';

export const reducer: Reducer<OrderCardState> = (state: OrderCardState, action: orderCardActions.OrderCardKnownActions) => {
    switch (action.type) {
        case orderCardActions.GET_ORDER_FOR_ORDER_CARD:
            return { ...state, fetchInProgress: true };
        case orderCardActions.UPDATE_ORDER:
            return { ...state, fetchInProgress: true };
        case orderCardActions.UPDATE_ORDER_SILENT:
            return { ...state, fetchInProgress: true };
        case orderCardActions.SET_ORDER_FOR_ORDER_CARD:
            return { ...state, fetchInProgress: false, order: action.payload };
        case orderCardActions.SET_CLIENTS_FOR_PARTNER:
            return { ...state, clients: action.payload };
        case orderCardActions.SET_CLIENT_PHONE:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({}, state.bufferClient, {
                        PhoneNumber: action.payload.value
                    }),
                    newClientMode: action.payload.mode
                } as OrderCardState
            );
        case orderCardActions.SELECT_CLIENT:
            return Object.assign({},
                state,
                {
                    selectedClient: action.payload,
                    bufferClient: action.payload.object,
                } as OrderCardState
            );

        case orderCardActions.CHANGE_NEW_CLIENT_NAME:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({},
                        state.bufferClient, {
                        FullName: action.payload
                    })
                } as OrderCardState
            );
        case orderCardActions.CHANGE_NEW_CLIENT_EMAIL:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({},
                        state.bufferClient, {
                        Email: action.payload
                    })
                } as OrderCardState
            );
        case orderCardActions.RESET_CLIENT:
            return Object.assign({},
                state,
                {
                    selectedClient: defaultOrderCardState.selectedClient,
                    bufferClient: defaultOrderCardState.bufferClient,
                } as OrderCardState
            );
        case orderCardActions.RESET_CLIENT_RV:
            return Object.assign({},
                state,
                {
                    selectedClient: defaultOrderCardState.selectedClient,
                    bufferClient: defaultOrderCardState.bufferClient,
                    clientPhoneInputRef: defaultOrderCardState.clientPhoneInputRef,
                    clientSelectComponentRef: defaultOrderCardState.clientSelectComponentRef,
                    clients: defaultOrderCardState.clients,
                    newClientMode: defaultOrderCardState.newClientMode,
                    recipientName: defaultOrderCardState.recipientName,
                    recipientPhone: defaultOrderCardState.recipientPhone,
                    isRecipient: defaultOrderCardState.isRecipient
                } as OrderCardState
            );
        case orderCardActions.SET_CURRENT_RECIPIENT:
            return Object.assign({},
                state,
                {
                    recipientName: action.payload.name,
                    recipientPhone: action.payload.phone,
                    isRecipient: true
                } as OrderCardState
            );
        case orderCardActions.CHANGE_RECIPIENT:
            return Object.assign({},
                state,
                {
                    recipientName: action.payload ? '' : (state.newClientMode ? state.bufferClient.FullName : state.selectedClient.object.FullName),
                    recipientPhone: action.payload ? '' : (state.newClientMode ? state.bufferClient.PhoneNumber : state.selectedClient.object.PhoneNumber),
                    isRecipient: action.payload
                } as OrderCardState
            );
        case orderCardActions.CHANGE_RECIPIENT_NAME:
            return Object.assign({},
                state,
                {
                    recipientName: action.payload
                } as OrderCardState
            );
        case orderCardActions.CHANGE_RECIPIENT_PHONE:
            return Object.assign({},
                state,
                {
                    recipientPhone: action.payload
                } as OrderCardState
            );
        case orderCardActions.SET_CURRENT_DELIVERY_INFO:
            return Object.assign({},
                state,
                {
                    selectedDeliveryType: action.payload.deliveryType,
                    selectedCity: action.payload.novaPoshtaCity,
                    selectedWarehouse: action.payload.novaPoshtaWarehouse,
                    selectedUkrPoshtaCityName: action.payload.ukrPoshtaCityName,
                    selectedUkrPoshtaStreetName: action.payload.ukrPoshtaStreetName,
                    selectedUkrPoshtaBuildingNumber: action.payload.ukrPoshtaBuildingNumber,
                    selectedUkrPoshtaFlatNumber: action.payload.ukrPoshtaFlatNumber,
                    selectedUkrPoshtaZipNumber: action.payload.ukrPoshtaZipNumber,
                } as OrderCardState
            );
        case orderCardActions.SELECT_DELIERY_TYPE:
            return Object.assign({},
                state,
                {
                    selectedDeliveryType: action.payload
                } as OrderCardState
            );
        case orderCardActions.SET_CITIES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    cities: Object.assign(
                        [],
                        action.payload
                    ),
                } as OrderCardState);
        case orderCardActions.SELECT_CITIES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    selectedCity: action.payload,
                    warehouses: defaultOrderCardState.warehouses,
                    selectedWarehouse: defaultOrderCardState.selectedWarehouse,
                } as OrderCardState);
        case orderCardActions.SET_WEREHOUSES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    warehouses: action.payload,
                } as OrderCardState);
        case orderCardActions.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    selectedWarehouse: action.payload,
                } as OrderCardState);
        case orderCardActions.CHANGE_UKR_CITY_NAME:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaCityName: action.payload
                } as OrderCardState);
        case orderCardActions.CHANGE_UKR_STREET_NAME:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaStreetName: action.payload
                } as OrderCardState);
        case orderCardActions.CHANGE_UKR_BUILDING_NUM:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaBuildingNumber: action.payload
                } as OrderCardState);
        case orderCardActions.CHANGE_UKR_FLAT_NUM:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaFlatNumber: action.payload
                } as OrderCardState);
        case orderCardActions.CHANGE_UKR_ZIP:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaZipNumber: action.payload
                } as OrderCardState);
        case orderCardActions.SELECT_ORDER_PRODUCT_FOR_RV:
            return Object.assign({},
                state,
                {
                    selectedOrderProductId: action.payload
                } as OrderCardState);
        case orderCardActions.SET_UPDATED_ORDER_PRODUCT:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    OrderProducts: Object.assign([], state.order.OrderProducts, {
                        [action.payload.index]: action.payload.orderProduct
                    })
                } as Order),
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts, {
                        [action.payload.index]: action.payload.orderProduct
                    }),
                    currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                        UserImage: action.payload.orderProduct.UserImage,
                        UserPrewiev: action.payload.orderProduct.UserPrewiev
                    }),
                    serverActionInProcess: false
                } as ProductUpdateState),
                fetchInProgress: false
            } as OrderCardState);
        case orderCardActions.CHANGE_USER_IMAGE_COMMENT:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    OrderProducts: Object.assign([], state.order.OrderProducts, {
                        [action.payload.index]: Object.assign({}, state.order.OrderProducts[action.payload.index], {
                            UserImageComment: action.payload.comment
                        } as OrderProduct)
                    })
                } as Order)
            } as OrderCardState);
        case orderCardActions.SET_USER_IMAGE_COMMENT:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                        UserImageComment: action.payload
                    })
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return {
                ...state,
                UkrPostOfficeModel: action.payload,
            } as OrderCardState;

        case orderCardActions.SET_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return {
                ...state,
                UkrAreaModel: action.payload,
            } as OrderCardState;
        case orderCardActions.SET_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return {
                ...state,
                UkrCityModel: action.payload,
            } as OrderCardState;
        case orderCardActions.SELECT_REGION_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    selectedUkrPoshtaRegionId: action.payload
                } as OrderCardState);
        case orderCardActions.SELECT_AREA_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    districtAreaId: action.payload
                } as OrderCardState);
        case orderCardActions.SELECT_CITY_UKR_POSHTA_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    districtCityId: action.payload
                } as OrderCardState);
        case orderCardActions.CHANGE_PRINT_COMMENT:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    OrderProducts: Object.assign([], state.order.OrderProducts, {
                        [action.payload.index]: Object.assign({}, state.order.OrderProducts[action.payload.index], {
                            PrintComment: action.payload.comment
                        } as OrderProduct)
                    })
                } as Order)
            } as OrderCardState);
        case orderCardActions.CHANGE_PRODUCTION_COMMENT:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    ProductionComment: action.payload
                } as Order)
            } as OrderCardState);
        case orderCardActions.CHANGE_PRODUCTION_OPERATOR_COMMENT:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    ProductionOperatorComment: action.payload
                } as Order)
            } as OrderCardState);
        case orderCardActions.SET_PRODUCT_UPDATE_MODE:
            return Object.assign({}, state, {
                productUpdateMode: action.payload.isActive,
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    currentOrderProducts: action.payload.orderProducts
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.RESET_PRODUCT_UPDATE_MODE:
            return Object.assign({}, state, {
                productUpdateMode: action.payload,
                productUpdateSubState: defaultProductUpdateState
            } as OrderCardState);
        case orderCardActions.SET_CATEGORIES:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    categoryList: action.payload
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_SUBCATEGORIES:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    subCategoryList: action.payload
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SELECT_CATEGORY:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    selectedCategory: action.payload,
                    selectedSubCategory: defaultProductUpdateState.selectedSubCategory
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SELECT_SUBCATEGORY:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    selectedSubCategory: action.payload,
                    productList: defaultProductUpdateState.productList
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_PRODUCTS:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    productList: action.payload,
                    currentProduct: action.payload[0]
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_ADDITIONAL_SERVICE_PRODUCTS:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    additionalServiceProducts: action.payload,
                    expressPrintProducts: action.payload.filter(product => product.LexiconId === 60415),
                    extraPrintProducts: action.payload.filter(product => product.LexiconId !== 60415)
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_EXPRESS_PRINT_PRODUCT:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    selectedExpressPrintProduct: action.payload,
                    currentExpressPrintOrderProduct: {
                        ...state.productUpdateSubState.currentExpressPrintOrderProduct,
                        Product: action.payload,
                        ProductId: action.payload !== null ? action.payload.Id : null,
                        PartnerPrice: action.payload.PartnerPrice,
                        Price: action.payload.PartnerPrice
                    } as OrderProduct,
                    currentOrderProduct: {
                        ...state.productUpdateSubState.currentOrderProduct,
                        ChildrenOrderProducts: [
                            {
                                ...state.productUpdateSubState.currentExpressPrintOrderProduct,
                                Product: action.payload,
                                ProductId: action.payload !== null ? action.payload.Id : null,
                                PartnerPrice: action.payload.PartnerPrice,
                                Price: action.payload.PartnerPrice
                            } as OrderProduct,
                            state.productUpdateSubState.currentExtraPrintOrderProduct
                        ].filter(orderProduct => orderProduct !== null && orderProduct.Product !== null)
                    } as OrderProduct    
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.SET_EXTRA_PRINT_PRODUCT:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    selectedAdditionalPrintProduct: action.payload,
                    currentExtraPrintOrderProduct: {
                        ...state.productUpdateSubState.currentExtraPrintOrderProduct,
                        Product: action.payload,
                        ProductId: action.payload !== null ? action.payload.Id : null,
                        PartnerPrice: action.payload.PartnerPrice,
                        Price: action.payload.PartnerPrice
                    } as OrderProduct,
                    currentOrderProduct: {
                        ...state.productUpdateSubState.currentOrderProduct,
                        ChildrenOrderProducts: [
                            state.productUpdateSubState.currentExpressPrintOrderProduct,
                            {
                                ...state.productUpdateSubState.currentExtraPrintOrderProduct,
                                Product: action.payload,
                                ProductId: action.payload !== null ? action.payload.Id : null,
                                PartnerPrice: action.payload.PartnerPrice,
                                Price: action.payload.PartnerPrice
                            } as OrderProduct
                        ].filter(orderProduct => orderProduct !== null && orderProduct.Product !== null)
                    } as OrderProduct    
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.CHANGE_PRODUCT_SELECTION_MODE:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    productSelectionMode: action.payload,
                    orderProductIndex: state.productUpdateSubState.orderProductIndex ? state.productUpdateSubState.orderProductIndex : 0
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.REMOVE_ORDER_PRODUCT_SERVER:
            return Object.assign({}, state, {
                productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                    serverActionInProcess: true
                } as ProductUpdateState)
            } as OrderCardState);
        case orderCardActions.REMOVE_ORDER_PRODUCT_CLIENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderStatus: Object.assign([], [], {
                            [0]: action.payload.status
                        }) as OrderStatus[],
                        OrderProducts: Object.assign([], state.order.OrderProducts.filter((orderProduct) => {
                            return state.order.OrderProducts.indexOf(orderProduct) !== action.payload.index &&
                                orderProduct.ParentOrderProductID !== state.order.OrderProducts[action.payload.index].Id;
                        })),
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType
                    }),
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts.filter((orderProduct) => {
                            return state.productUpdateSubState.currentOrderProducts.indexOf(orderProduct) !== action.payload.index;
                        })),
                        serverActionInProcess: false
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.SELECT_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        orderProductIndex: action.payload
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_ORDER_PRODUCT_SELECTION:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: action.payload,
                        currentProduct: action.payload.Product,
                        currentProductColor: action.payload.Product.ProductColors.length > 0 ?
                            (action.payload as OrderProduct).Color
                            : defaultProductUpdateState.currentProductColor,
                        currentBaseProduct: (action.payload as OrderProduct).BaseProduct ?
                            (action.payload as OrderProduct).BaseProduct :
                            defaultProductUpdateState.currentBaseProduct,
                        currentBaseColor: (action.payload as OrderProduct).BaseColor ?
                            (action.payload as OrderProduct).BaseColor :
                            defaultProductUpdateState.currentBaseColor
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CANCEL_CURRENT_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: defaultProductUpdateState.currentOrderProduct,
                        orderProductStepPattern: defaultProductUpdateState.orderProductStepPattern,
                        currentProductColor: defaultProductUpdateState.currentProductColor,
                        orderProductSubStep: defaultProductUpdateState.orderProductSubStep,
                        currentStepEnum: defaultProductUpdateState.currentStepEnum
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SELECT_CURRENT_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentProduct: action.payload
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_PRODUCT_SELECT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentProductColor: state.productUpdateSubState.currentProduct.ProductColors.length > 0 ?
                            state.productUpdateSubState.currentProduct.ProductColors[0].Color
                            : defaultProductUpdateState.currentProductColor,
                        currentOrderProduct: {
                            OrderId: state.order.Id,
                            Product: state.productUpdateSubState.currentProduct,
                            PartnerPrice: state.productUpdateSubState.currentProduct.PartnerPrice,
                            Price: state.productUpdateSubState.currentProduct.PartnerPrice,
                            ProductId: state.productUpdateSubState.currentProduct.Id,
                            Quantity: 1,
                            ColorId: null,
                            BaseColorId: null,
                            BaseProductId: null
                        },
                        currentExpressPrintOrderProduct: {
                            TempOrderId: state.order.Id,
                            Quantity: 1,
                            Product: null,
                            ProductId: null
                        } as OrderProduct,
                        currentExtraPrintOrderProduct: {
                            TempOrderId: state.order.Id,
                            Quantity: 1,
                            Product: null,
                            ProductId: null
                        } as OrderProduct        
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SET_BASES_FOR_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        baseProductList: action.payload,
                        currentBaseProduct: !state.productUpdateSubState.currentOrderProduct.BaseProduct ?
                            action.payload[0] :
                            state.productUpdateSubState.currentOrderProduct.BaseProduct
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SET_PATTERN:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        orderProductStepPattern: action.payload
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SET_NEXT_SUBSTEP:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        orderProductSubStep: action.payload.component,
                        currentStepEnum: action.payload.stepEnum
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SELECT_CURRENT_BASE_COLOR:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentBaseColor: state.productUpdateSubState.productSelectionMode ?
                            action.payload :
                            state.productUpdateSubState.currentOrderProduct.BaseColor
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_BASE_COLOR_SELECT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                BaseColorId: state.productUpdateSubState.currentBaseColor.Id,
                                BaseColor: state.productUpdateSubState.currentBaseColor
                            })
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SELECT_CURRENT_BASE_COLOR:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentBaseColor: state.productUpdateSubState.productSelectionMode ? action.payload : state.productUpdateSubState.currentOrderProduct.BaseColor
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_BASE_COLOR_SELECT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                BaseColorId: state.productUpdateSubState.currentBaseColor.Id,
                                BaseColor: state.productUpdateSubState.currentBaseColor
                            })
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SELECT_CURRENT_PRODUCT_COLOR:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentProductColor: action.payload
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_PRODUCT_COLOR_SELECT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                ColorId: state.productUpdateSubState.currentProductColor.Id,
                                Color: state.productUpdateSubState.currentProductColor
                            })
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CALCULATE_PRODUCT_TOTAL:
            return Object.assign({},
                state,
                {
                    fetchInProgress: true,
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        serverActionInProcess: true
                    } as ProductUpdateState)
                } as OrderCardState
            );
        case orderCardActions.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        serverActionInProcess: false,
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                PartnerPrice: action.payload.Item1,
                                PartnerTotal: action.payload.Item2,
                                Total: action.payload.Item3
                            })
                    } as ProductUpdateState),
                    fetchInProgress: false
                } as OrderCardState);
        case orderCardActions.CHANGE_CURRENT_ORDER_PRODUCT_PRICE:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                Price: action.payload
                            })
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct,
                            {
                                Quantity: action.payload,
                                ChildrenOrderProducts: state.productUpdateSubState.currentOrderProduct.ChildrenOrderProducts
                                    .map(child => {child.Quantity = action.payload; return child;})
                            }),
                            currentExpressPrintOrderProduct: {
                                ...state.productUpdateSubState.currentOrderProduct,
                                Quantity: action.payload
                            } as OrderProduct,
                            currentExtraPrintOrderProduct: {
                                ...state.productUpdateSubState.currentOrderProduct,
                                Quantity: action.payload
                            } as OrderProduct            
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.UPDATE_ORDER_PRODUCT_SERVER:
        case orderCardActions.SAVE_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        serverActionInProcess: true
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.SET_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        // OrderProducts: Object.assign([], state.order.OrderProducts, {
                        //     [state.order.OrderProducts.length]: action.payload.orderProduct
                        // }),
                        OrderProducts: Object.assign([], [
                            ...state.order.OrderProducts,
                            action.payload.orderProduct,
                            ...action.payload.orderProduct.ChildrenOrderProducts
                        ]),
                        OrderStatus: Object.assign([], [], {
                            [0]: action.payload.status
                        }) as OrderStatus[],
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType,
                    } as Order),
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts, {
                            [state.productUpdateSubState.currentOrderProducts.length]: action.payload.orderProduct
                        }),
                        currentOrderProduct: defaultProductUpdateState.currentOrderProduct,
                        orderProductStepPattern: defaultProductUpdateState.orderProductStepPattern,
                        currentProductColor: defaultProductUpdateState.currentProductColor,
                        orderProductSubStep: defaultProductUpdateState.orderProductSubStep,
                        currentStepEnum: defaultProductUpdateState.currentStepEnum,
                        currentExpressPrintOrderProduct: defaultProductUpdateState.currentExpressPrintOrderProduct,
                        currentExtraPrintOrderProduct: defaultProductUpdateState.currentExtraPrintOrderProduct,
                        selectedExpressPrintProduct: defaultProductUpdateState.selectedExpressPrintProduct,
                        selectedAdditionalPrintProduct: defaultProductUpdateState.selectedAdditionalPrintProduct,    
                        serverActionInProcess: false
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.SET_ORDER_PRODUCT_BY_INDEX:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([], state.order.OrderProducts, {
                            [action.payload.index]: action.payload.orderProduct
                        }),
                        OrderStatus: Object.assign([], [], {
                            [0]: action.payload.status
                        }) as OrderStatus[],
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType,
                    } as Order),
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts, {
                            [action.payload.index]: action.payload.orderProduct
                        }),
                        currentOrderProduct: defaultProductUpdateState.currentOrderProduct,
                        orderProductStepPattern: defaultProductUpdateState.orderProductStepPattern,
                        currentProductColor: defaultProductUpdateState.currentProductColor,
                        orderProductSubStep: defaultProductUpdateState.orderProductSubStep,
                        currentStepEnum: defaultProductUpdateState.currentStepEnum,
                        currentExpressPrintOrderProduct: defaultProductUpdateState.currentExpressPrintOrderProduct,
                        currentExtraPrintOrderProduct: defaultProductUpdateState.currentExtraPrintOrderProduct,
                        selectedExpressPrintProduct: defaultProductUpdateState.selectedExpressPrintProduct,
                        selectedAdditionalPrintProduct: defaultProductUpdateState.selectedAdditionalPrintProduct,    
                        serverActionInProcess: false
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.SELECT_CURRENT_BASE_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentBaseProduct: action.payload
                    } as ProductUpdateState)
                } as OrderCardState);
        case orderCardActions.CONFIRM_CURRENT_BASE_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentBaseColor: state.productUpdateSubState.currentBaseProduct.ProductColors[0].Color,
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                            BaseProduct: state.productUpdateSubState.currentBaseProduct,
                            BaseProductId: state.productUpdateSubState.currentBaseProduct.Id
                        })
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.UPDATE_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    fetchInProgress: true
                } as OrderCardState);

        case orderCardActions.SET_FORCE_WHOLESALE:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        serverActionInProcess: true
                    } as ProductUpdateState),
                    fetchInProgress: true
                } as OrderCardState);
        case orderCardActions.UPDATE_ORDER_PRODUCT_CLIENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderStatus: Object.assign([], [], {
                            [0]: action.payload.status
                        }) as OrderStatus[],
                        OrderProducts: Object.assign([],
                            state.order.OrderProducts,
                            {
                                [action.payload.index]: action.payload.value
                            }),
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total
                    }),
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts, {
                            [action.payload.index]: action.payload.value
                        }),
                        currentOrderProduct: defaultProductUpdateState.currentOrderProduct,
                        orderProductStepPattern: defaultProductUpdateState.orderProductStepPattern,
                        currentProductColor: defaultProductUpdateState.currentProductColor,
                        orderProductSubStep: defaultProductUpdateState.orderProductSubStep,
                        currentStepEnum: defaultProductUpdateState.currentStepEnum,
                        serverActionInProcess: false
                    } as ProductUpdateState),
                    fetchInProgress: false
                } as OrderCardState);
        case orderCardActions.SET_USER_IMAGE:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                            UserImage: action.payload.fileType === OrderProductFileTypeEnum.UserImage ?
                                action.payload.url :
                                state.productUpdateSubState.currentOrderProduct.UserImage,
                            UserPrewiev: action.payload.fileType === OrderProductFileTypeEnum.Preview ?
                                action.payload.url :
                                state.productUpdateSubState.currentOrderProduct.UserPrewiev
                        })
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.SET_CURRENT_ORDER_STATUS:
            return Object.assign({},
                state,
                {
                    finishStatus: Object.assign([], defaultOrderCardState.finishStatus, {
                        [action.payload]: Object.assign(
                            {},
                            defaultOrderCardState.finishStatus[action.payload],
                            {
                                isSelected: true
                            })
                    }),

                    isValidated: defaultOrderCardState.isValidated
                } as OrderCardState);

        case orderCardActions.ADD_ORDER_STATUS:
            return Object.assign({}, state, { fetchInProgress: true } as OrderCardState);
        case orderCardActions.SET_ORDER_STATUS:
            return Object.assign({}, state, {
                order: Object.assign({}, state.order, {
                    OrderStatus: Object.assign([], [], {
                        [0]: action.payload
                    }) as OrderStatus[]
                } as Order),
                isValidated: defaultOrderCardState.isValidated,
                fetchInProgress: false
            });
        case orderCardActions.SELECT_FINISH_STATUS:
            return Object.assign({},
                state,
                {
                    finishStatus: Object.assign([], defaultOrderCardState.finishStatus, {
                        [action.payload.value]: Object.assign(
                            {},
                            defaultOrderCardState.finishStatus[action.payload.value],
                            {
                                isSelected: true
                            })
                    }),
                    isValidated: action.payload.validated
                } as OrderCardState);
        case orderCardActions.CHANGE_GLOBAL_COMMENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        GlobalComment: action.payload
                    } as Order)
                } as OrderCardState);
        case orderCardActions.CHANGE_PRINT_REUSE_STATUS_MULTI:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([], state.order.OrderProducts, {
                            [action.payload.index]: Object.assign({}, state.order.OrderProducts[action.payload.index], {
                                SelectedReuseOption: action.payload.status
                            } as OrderProduct)
                        })
                    } as Order)
                } as OrderCardState);
        case orderCardActions.CHANGE_CANT_REUSE_QUANTITY:
            return Object.assign({},
                state,
                {
                    cantReuseQuantity: action.payload
                } as OrderCardState);
        case orderCardActions.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                            ProductionComment: action.payload
                        } as OrderProduct)
                    })
                } as OrderCardState);
        case orderCardActions.SET_FAIL_PROCESSING:
            return Object.assign({},
                state,
                {
                    isFailProcessing: action.payload
                } as OrderCardState);

        case orderCardActions.GET_ORDER_AUDIT:
            return Object.assign({},
                state,
                {
                    fetchInProgress: true
                } as OrderCardState);

        case orderCardActions.SET_ORDER_AUDIT:
            return Object.assign({},
                state,
                {
                    auditEntities: action.payload,
                    fetchInProgress: false
                } as OrderCardState);
        case orderCardActions.SET_REFRESHED_ORDERPRODUCT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([],
                            state.order.OrderProducts,
                            {
                                [action.payload.index]: action.payload.orderProduct
                            }),
                    }),
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProducts: Object.assign([], state.productUpdateSubState.currentOrderProducts, {
                            [action.payload.index]: action.payload.orderProduct
                        }),
                    } as ProductUpdateState),
                    fetchInProgress: false
                } as OrderCardState);
        case orderCardActions.SELECT_OPTION_FOR_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        currentOrderProduct: Object.assign({}, state.productUpdateSubState.currentOrderProduct, {
                            OrderProductAdditionalOption: action.payload
                        } as OrderProduct)
                    } as ProductUpdateState),
                } as OrderCardState);
        case orderCardActions.UNMOUNT_COMPONENT:
            return Object.assign({},
                state, defaultOrderCardState
            );
        case orderCardActions.GET_PRODUCT_BASES_FOR_ORDER_CARD_SUCCESS:
            return Object.assign({},
                state,
                {
                    productUpdateSubState: Object.assign({}, state.productUpdateSubState, {
                        baseProductList: action.payload
                    } as ProductUpdateState)
                }
            ) as OrderCardState;
        default:
            const exhaustiveCheck: any = action;
            return state || defaultOrderCardState;
    }
}