import * as React from 'react';
import {connect} from 'react-redux';
import PermissionChecker from '../../../shared/permission.checker.component';
import {IdentityRoleEnum} from '../../../../helpers/identity.roles';

export const Production = (props) => {

    const model = props.Statistics.StatisticsEntity;
    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус производства которых был переведен в "Ожидает сборки"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingForAssemblyQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус производства которых был переведен в "Готов к пошиву"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReadyForProductionQuantity}
                                </span>
                            </div>

                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус производства которых был переведен в "Пошит"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ProducedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус производства которых был переведен с "Готов к пошиву" в "Пошит"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус пошива которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только олин перевод в определенный статус
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToProducedQuantity}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>

                            <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name"></div>
                                <div className="grid_cell"></div>
                                <div className="grid_cell cell__GRID_CONT"></div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус производства которых был переведен в "Ожидает сборки"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.WaitingForAssemblingTotal.toFixed(2)}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус производства которых был переведен в "Готов к пошиву"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReadyForProductionTotal.toFixed(2)}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус производства которых был переведен в "Пошит"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус пошива которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ProducedTotal.toFixed(2)}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус производства которых был переведен с "Готов к пошиву" в "Пошит"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Производство", статус пошива которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToProducedTotal.toFixed(2)}
                                </span>
                                </div>
                            </div>
                        </PermissionChecker>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({Statistics: (state as any).cabinet.statistics}))(Production);
