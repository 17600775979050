import * as React from 'react';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { default as Carousel } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Countdown from 'react-countdown-now';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';
import { log } from 'util';


interface ILandState {
    value: number;
    itemPrice: number;
    reverseValue: number;
    printPriceItemCountDay: number;
    printPriceItemCountMonth: number;
    printPriceItemsProfitDay: number;
    printPriceItemsProfitMonth: number;
    mobileMenuOpen: boolean;
}

export class LandingOne extends React.Component<any, ILandState>{
    public defaultItemPrice = 110;
    public defaultItemSellPrice = 200;
    public slidesToShowItem;

    constructor(props) {
        super(props);

        this.slidesToShowItem = document.getElementsByTagName('body')[0].clientWidth;

        let itemPrice: number = this.defaultItemPrice - (this.defaultItemPrice * 0.32);

        const reverseValue = 25;
        const printPriceItemsProfitDay = reverseValue * (this.defaultItemSellPrice - itemPrice);
        this.state = {
            value: 25,
            reverseValue,
            itemPrice,
            printPriceItemCountDay: reverseValue,
            printPriceItemCountMonth: reverseValue * 30,
            printPriceItemsProfitDay,
            printPriceItemsProfitMonth: printPriceItemsProfitDay * 30,
            mobileMenuOpen: false
        };
    }

    public handleChange(value) {
        this.setState({
            value: value
        })
    }

    public handleChangeMenu() {
        this.setState(state => ({
            mobileMenuOpen: !state.mobileMenuOpen
        }));
        //if (this.mobileMenuOpen == true) {
        //    this.mobileMenuOpen = false;

        //} else {
        //    this.mobileMenuOpen = true;
        //}
        //console.log(this.mobileMenuOpen);
    }

    public handleChangeReverse(value) {
        let newPrice = 0;

        if (value * (200 - this.defaultItemPrice) * 30 < 5000)
            newPrice = this.defaultItemPrice;

        if (value * (200 - this.defaultItemPrice) * 30 >= 5000 && value * (200 - this.defaultItemPrice) * 30 < 10000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.1);

        if (value * (200 - this.defaultItemPrice) * 30 >= 10000 && value * (200 - this.defaultItemPrice) * 30 < 25000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.15);

        if (value * (200 - this.defaultItemPrice) * 30 >= 25000 && value * (200 - this.defaultItemPrice) * 30 < 50000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.20);

        if (value * (200 - this.defaultItemPrice) * 30 >= 50000 && value * (200 - this.defaultItemPrice) * 30 < 100000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.30);

        if (value * (200 - this.defaultItemPrice) * 30 >= 100000 && value * (200 - this.defaultItemPrice) * 30 < 150000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.31);

        if (value * (200 - this.defaultItemPrice) * 30 >= 150000)
            newPrice = this.defaultItemPrice - (this.defaultItemPrice * 0.32);

        this.setState((state: ILandState) => ({
            reverseValue: value,
            itemPrice: newPrice,
            printPriceItemCountDay: value,
            printPriceItemCountMonth: value * 30,
            printPriceItemsProfitDay: value * (200 - newPrice),
            printPriceItemsProfitMonth: value * (200 - newPrice) * 30,
        }))
    }

    public OnOpenRegistrationPage(): void {
        window.open('https://b2b.caseshop.com.ua/auth/register', '_self');
    }

    render() {
        const horizontalLabels = {
            0: '0',
            25: '25',
            50: '50',
            75: '75'
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 1,
            slidesToShow: this.slidesToShowItem < 960 ? 1 : 3,
            slidesToScroll: this.slidesToShowItem < 960 ? 1 : 3,
            autoplay: true
        };

        const { value, reverseValue, mobileMenuOpen } = this.state;

        const Completionist = () => <span>Время вышло</span>;

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return <Completionist />;
            } else {
                return <span>{hours}:{minutes}:{seconds}</span>;
            }
        };


        return (
            <div className="landPageContainer">
                <div className={`landGLOBAL__header ${(mobileMenuOpen ? 't' : 'f')}`} >
                    <div className={`landGLOBAL__headerContainer ${(mobileMenuOpen ? 't' : 'f')}`} >
                        <div className="menuICON__container" onClick={() => this.handleChangeMenu()}>
                            <div className="lineSC"></div>
                            <div className="lineSC"></div>
                            <div className="lineSC"></div>
                        </div>
                        <ul className={`header_menu_component ${(mobileMenuOpen ? 't' : 'f')}`} >
                            <li className="menu_list_item ">
                                <NavLink to={"/dropshipping_chehly"} activeClassName='active'>Дропшиппинг</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/chehly_optom"} activeClassName='active'>Чехлы и аксессуары оптом</NavLink>
                            </li>
                            <li className="menu_list_item">
                                <NavLink to={"/auth/login"} activeClassName='active'>Вход</NavLink>
                            </li>
                        </ul>
                        <div className="header_logo"></div>
                    </div>
                </div>

                <Helmet>
                    <title>Дропшиппинг на чехлы для телефонов в Украине - зарабатывай с caseshop</title>
                    <meta name="description" content="Старт продаж чехлов за 1 день! Зарабатывай от 1000$/мес. Аренда конструктора. Высокая прибыль на дропшиппинге аксессуаров. Лучшее качество." />
                    <meta property='og:title' content='Дропшиппинг на чехлы для телефонов в Украине - зарабатывай с caseshop' />
                    <meta property='og:type' content='website' />
                    <meta property='og:image' content='http://b2b.caseshop.com.ua/logo.png' />
                    <meta property='og:description' content="Старт продаж чехлов за 1 день! Зарабатывай от 1000$/мес. Аренда конструктора. Высокая прибыль на дропшиппинге аксессуаров. Лучшее качество." />
                    <meta property='og:url' content="http://b2b.caseshop.com.ua/" />
                </Helmet>

                <div className="landWrapper__container">
                    <div className="topPicture__container">
                        <div className="topPicture__containerTextPhone">
                            <span className="phoneContainer">069 669 96 96</span>
                            <span className="emailContainer">b2b.caseshop@gmail.com</span>
                        </div>
                        <div className="topPicture__containerText">
                            <h1 className="span">Дропшиппинг чехлов и аксессуаров
                                <br />
                                с CASESHOP
                                </h1>
                            <span className="logo hide" />
                            <span>
                                Уважаемый партнер, наша команда рада приветствовать Вас на нашей дропшиппинг платформе по чехлам для телефонов.
                                    <br />
                                Уже 5 лет мы поставляем товары отменного качества нашим партнерам по Украине. За это время мы прошли долгий путь развития и создали идеальное предложение по сотрудничеству в сфере продаж.
                                <br />
                                Будем рады стать Вашим надежным бизнес-партнером!
                            </span>

                            <div className="regButton" onClick={() => {
                                //window.gtag('event', 'Регистрация_Дроп', { 'event_category': 'ButtonConfirm', 'event_action': 'RegistrationDrop', });
                                this.OnOpenRegistrationPage()
                            }}>
                                Регистрация</div>
                        </div>
                    </div>

                    <div className="landContainer">
                        <h2 className="sectionTitle">Виды печати на чехлах</h2>

                        <div className="topBoxsContainer">
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Полная <br /> запечатка</div>
                                <div className="topBoxs__itemWrapp">
                                    Изображение наносится на заднюю поверхность силиконовой накладки, выглядит неповторимо. Напечатать можно любое изображение - идеальный товар для дропшиппинга
                                    </div>
                            </div>
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Частичная <br /> печать</div>
                                <div className="topBoxs__itemWrapp">
                                    Нанесение изображения без фона с высоким качеством мелких деталей для утонченности Вашего гаджета
                                    </div>
                            </div>
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Покрытие <br /> лаком</div>
                                <div className="topBoxs__itemWrapp">
                                    Дополнительное нанесение слоя лака поверх изображения сделает Ваш бампер уникальным
                                    </div>
                            </div>
                        </div>

                        <h2 className="sectionTitle">Чехлы и другие аксессуары</h2>

                        <div className="topBoxsContainer__2">
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Силиконовые бампера</div>
                                <div className="topBoxs__itemWrapp">
                                    Огромный ассортимент и цветовая гамма аксессуаров из лучшего материала для защиты Вашего девайса
                                </div>
                            </div>
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Аксессуары для планшетов</div>
                                <div className="topBoxs__itemWrapp">
                                    Силиконовые и кожаные, с разворотом, стекло, плёнки
                                </div>
                            </div>
                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Стекло и пленки</div>
                                <div className="topBoxs__itemWrapp">
                                    Защита экрана от царапин и ударов, будет прекрасным дополнением к силиконовой накладке
                                </div>
                            </div>

                            <div className="topBoxs__item">
                                <div className="topBoxs__itemTitle">Кейсы из натуральной кожи</div>
                                <div className="topBoxs__itemWrapp">
                                    Престижный и элегантный флип для максимальной защиты девайса
                                </div>
                            </div>
                        </div>


                        <div className="wthContainer">
                            <h2 className="sectionTitle">Почему Вам выгодно сотрудничать с нами!</h2>
                            <div className="wtgItem__col first">
                                <div className="h2">Высокое качество</div>
                                <p>
                                    Используя только оригинальные чернила, напечатав более 100000 накладок мы достигли безупречного качества
                                 </p>

                                <div className="h2">Гарантия выплат</div>
                                <p>
                                    Выплаты по первому требованию без задержек
                                </p>

                                <div className="h2">Индивидуальный подход</div>
                                <p>
                                    Мы всегда учитываем пожелания наших коллег в сфере дропшиппинга и при возможности идем на встречу отправляя заказы еще быстрее
                                </p>
                            </div>
                            <div className="wtgItem__col mmidImage">

                            </div>
                            <div className="wtgItem__col">
                                <div className="h2">Скорость исполнения</div>
                                <p>
                                    Своевременная обработка и отправка каждого заказа
                                 </p>

                                <div className="h2">Лучшие оптовые цены</div>
                                <p>
                                    Являясь прямым импортером кейсов мы предлагаем
                                    самые выгодные оптовые цены при покупке одного товара количеством от 5 штук
                                </p>

                                <div className="h2">Большой ассортимент</div>
                                <p>
                                    У нас представлены кейсы на 1500 популярных моделей гаджетов
                                </p>
                            </div>
                        </div>

                        <div className="timeContainer">
                            <div className=" timeBoxContainer">
                                <div className="h2">
                                    Зарегистрируйся сейчас и получи бонус - 1 чехол с печатью в подарок. Поспеши!
                                </div>

                                <div className="timeCont">
                                    <Countdown
                                        date={Date.now() + 1800000}
                                        renderer={renderer}
                                    >
                                        <Completionist />
                                    </Countdown>
                                </div>

                                <div className="regButton" onClick={() => this.OnOpenRegistrationPage()}>Регистрация</div>
                            </div>
                        </div>

                        <h2 className="sectionTitle">Отзывы наших партнеров</h2>
                        <div className="containerCarousel">
                            <Carousel {...settings}>
                                <div>
                                    <div className="partNameContainer">Андрей</div>
                                    <small>Сотрудничество с февраля 2016</small>
                                    <div className="h3">
                                        Выбирая себе поставщика по дропшиппингу аксессуаров для телефонов, сразу обратил внимание на "CaseShop". Благодарю за ответственное выполнение наших заявок, своевременные поставки, высокий профессионализм. Скорость работы, соответствие обещанных сроков характеризует компанию как сплоченный коллектив, что движется вперед. Рекомендуем "CaseShop" как надежного исполнителя.
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Евгений</div>
                                    <small>Сотрудничество с декабря 2015</small>
                                    <div className="h3">
                                        Сотрудничаю с CaseShop уже много лет, только положительные впечатления от работы. Сильная профессиональная команда, приятно общаться, всегда готовы пойти на встречу. Нацеленность на результат, амбициозность, умение работать с заказчиками. Рад, что наша работа успешно развивается.
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Артем</div>
                                    <small>Сотрудничество с февраля 2019</small>
                                    <div className="h3">
                                        Все отлично, товары всегда приходят по срокам, иногда быстрее. Если возникают какие-то проблемы, менеджер сразу решает. Радует что на новые модели смартфонов, очень быстро появляются чехлы. Дропшиппинг с Caseshop - одна из самых лучших моих бизнес-идей =)
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Александр</div>
                                    <small>Сотрудничество с октября 2015</small>
                                    <div className="h3">
                                        Сотрудничаю с компанией уже несколько лет. Надёжные партнёры, всегда выполняют свои обязательства, качественно в оговоренные сроки. Хорошо организованный сервис. В рабочее время всегда на связи, быстро, оперативно отвечают на вопросы, помогают в решении.
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Ульяна</div>
                                    <small>Сотрудничество с июля 2016</small>
                                    <div className="h3">
                                        Компания "CaseShop" является нашим поставщиком более трех лет. Зарекомендовала себя надежным, стабильным исполнителем - обеспечивая гибкий подход к запросам заказчика, оперативность, надежность, неизменное качество изделия и обслуживания, также своевременное выполнение обещанных сроков. Рекомендуем, как надежного поставщика и добросовестного партнера по тематике дропшиппинга чехлов.
                                    </div>
                                </div>

                                <div>
                                    <div className="partNameContainer">Людмила</div>
                                    <small>Сотрудничество с ноября 2016</small>
                                    <div className="h3">
                                        Спасибо вам большое за качественное выполнение моих заказов. Неоднократно получаем довольные отзывы наших клиентов благодаря безупречности полученных аксессуаров. Отдельное спасибо менеджеру за прекрасное обслуживание, быструю реакцию на запросы!
                                    </div>
                                </div>

                            </Carousel>
                        </div>

                        <div className="dropContainer">
                            <div className="dropTextContainer">
                                <h2>Как работает дропшиппинг на чехлы</h2>
                                <p>
                                    У нас большой опыт полученный за долгие годы работы на рынке мобильных аксессуаров, нашими гарантиями являются сотни тысяч обслуженных клиентов, мы гарантируем идеальное качество наших продуктов, безусловную надежность работы с нами
                                </p>

                                <div className="regButton" onClick={() => this.OnOpenRegistrationPage()}>Регистрация</div>
                            </div>
                            <div className="blockCrContainer">
                                <div className="crTitle__item">Вы (дропшиппер)</div>
                                <div className="crTitle__item">Покупатель</div>
                                <div className="crTitle__item">Мы (поставщик)</div>
                                <div className="crTitle__item">
                                    Покупатель <br />делает заказ у <br />продавца
                               </div>
                                <div className="crTitle__item">
                                    Продавец <br />передает заказ
                               </div>
                                <div className="crTitle__item">
                                    Дропшиппер
                                </div>
                            </div>
                        </div>


                        <h2 className="sectionTitle myW">Наши работы</h2>
                        <div className="myProdsContainer">
                            <div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>

                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                                <div className="myProd__item"></div>
                            </div>
                        </div>


                        <h2 className="sectionTitle ttPtitle">Расчёт стоимости печати</h2>
                        <div className="priceContainer">
                            <div className="pccTitle">Количество купленных кейсов за день, шт</div>
                            <div className="priceLineWrapper">
                                <Slider
                                    min={0}
                                    max={75}
                                    value={reverseValue}
                                    labels={horizontalLabels}
                                    handleLabel={reverseValue}
                                    orientation='horizontal'
                                    onChange={this.handleChangeReverse.bind(this)}
                                />
                            </div>
                            <div className="priceBoxWrapper">

                                <div className="price__item">
                                    <div className="price__itemTitle">Ваша стоимость</div>
                                    <div className="price__itemValue">
                                        <span>{this.state.itemPrice.toFixed(1)}</span>
                                        <span>грн</span>
                                    </div>
                                </div>

                                <div className="price__item">
                                    <div className="price__itemTitle">
                                        Ваша прибыль с <br />одного кейса <br />
                                        <small>(при продажной цене 200грн)</small>
                                    </div>
                                    <div className="price__itemValue">
                                        <span>{(200 - this.state.itemPrice).toFixed(1)}</span>
                                        <span>грн</span>
                                    </div>
                                </div>

                                <div className="price__item">
                                    <div className="price__itemTitle">Количество продаж <br /> в день</div>
                                    <div className="price__itemValue">
                                        <span>{this.state.printPriceItemCountDay}</span>
                                        <span>шт</span>
                                    </div>
                                </div>

                                <div className="price__item">
                                    <div className="price__itemTitle">Количество продаж<br /> в месяц</div>
                                    <div className="price__itemValue">
                                        <span>{this.state.printPriceItemCountMonth}</span>
                                        <span>шт</span>
                                    </div>
                                </div>

                                <div className="price__item">
                                    <div className="price__itemTitle">Прибыль<br /> в день</div>
                                    <div className="price__itemValue">
                                        <span>{this.state.printPriceItemsProfitDay.toFixed(1)}</span>
                                        <span>грн</span>
                                    </div>
                                </div>

                                <div className="price__item">
                                    <div className="price__itemTitle">Прибыль<br /> в месяц</div>
                                    <div className="price__itemValue">
                                        <span>{this.state.printPriceItemsProfitMonth.toFixed(1)}</span>
                                        <span>грн</span>
                                    </div>
                                </div>
                            </div>
                            <div className="price__itemTitle rddTitle">
                                Так, как у нас действует система скидок, цена меняется в зависимости от объема в месяц. Дропшиппинг чехлов - лучший старт Вашего нового бизнеса!
                                </div>
                        </div>

                        {/* 
                          <div className="dropContainer comProp">
                            <div className="dropTextContainer">
                                <h1>Комерційна пропозиція для дропшипінгу</h1>

                                <div className="groupTextContainer">
                                    <h3>Чохли з друком</h3>
                                    <small>Друк будь-якого зображення на силіконовому чохлі (картинка, фото, іменний чохол)</small>

                                    <ul>
                                        <li>Асортимент</li>
                                        <li>Метод друку</li>
                                        <li>Термін виконання</li>
                                        <li>Вартість</li>

                                        <li>Вимоги до зображення</li>
                                        <li>Гарантія на друк</li>
                                        <li>Виконання конструктора в особистому кабінеті</li>
                                        <li>Конструктор для власного сайту</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="dropTextContainerColT">
                                <div className="groupTextContainer">
                                    <h3>Співпраця по дропшипінгу з</h3>
                                    <small></small>

                                    <ul>
                                        <li>Переваги роботи в CRM-системі</li>
                                        <li>Механізм оформлення замовлення</li>
                                        <li>Відправлення замовлень</li>
                                        <li>Розрахунок</li>
                                    </ul>
                                </div>

                                <div className="groupTextContainer">
                                    <h3>Чохли та аксесуари</h3>
                                    <small>Чохли з натуральної шкіри ручної роботи. Різного виду чохли, скло та плівки до телефонів і планшетів</small>

                                    <ul>
                                        <li>Асортимент</li>
                                        <li>Вартість</li>
                                        <li>Технологія виробництва шкіряних виробів</li>
                                        <li>Термін використання замовлень на шкіряні чохли</li>
                                        <li>Гарантія</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                         */}


                        <div className="footerContainer">
                            <div className="phoneContainer">069 669 96 96</div>
                            <div className="emailContainer">b2b.caseshop@gmail.com</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}