import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import { Order } from "../../../../../business.entities/order";
import { PaymentMethod } from "../../../../../helpers/enums/payment.method";
import { UpdateOrder } from "../../../../../actions/sales/order.card.actions";
import { DeliveryType } from "../../../../../helpers/enums/delivery.type";

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrder
}, dispatch);

const PaymentRv = (props: any) => {
    const order: Order = props.OrderCard.order;

    const OnChangePaymentMethodCommand = (paymentMethod: PaymentMethod) => {
        if (paymentMethod !== props.OrderCard.order.PaymentMethod) {
            const order = Object.assign({},
                props.OrderCard.order,
                {
                    PaymentMethod: paymentMethod
                } as Order);

            props.UpdateOrder(order);
        }
    }

    const IsAfterPayAllowed = () => {
        // if((props.OrderCard.order as Order).DeliveryType === DeliveryType.Ukr)
        //     return false;

        if ((props.OrderCard.order as Order).DeliveryType === DeliveryType.Self)
            return false;

        if ((props.OrderCard.order as Order).NovaPoshtaWarehouseName
            && ((props.OrderCard.order as Order).NovaPoshtaWarehouseName.indexOf('Поштомат') !== -1
                || (props.OrderCard.order as Order).NovaPoshtaWarehouseName.indexOf('Почтомат') !== -1))
            return false;

        return true;
    }

    return (
        <div className="paymRVContainer">
            <div className="defaultFormControl">
                <div className="defaultFormControl__label"> Метод оплати</div>
                <div className="paymCheckWrapper__container">
                    <div className={`bHBCheckbox ${order.PaymentMethod === PaymentMethod.Prepaid ? 'isSelected' : ''}`}
                        onClick={() => OnChangePaymentMethodCommand(PaymentMethod.Prepaid)}>
                        <span>Предоплата</span>
                    </div>
                    {IsAfterPayAllowed() ?
                        <div className={`bHBCheckbox ${order.PaymentMethod === PaymentMethod.Afterpaid ? 'isSelected' : ''}`}
                            onClick={() => OnChangePaymentMethodCommand(PaymentMethod.Afterpaid)}>
                            <span>Наложеный платёж</span>
                        </div>
                        : null}

                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentRv);
