import { success } from "react-notification-system-redux";
import { Store } from "redux";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import * as statisticsActionTypes from "../../actions/cabinet/print.statistics.actions";
import { ErrorHandler } from "../../helpers/error.manager";
import storeProvider from "../../helpers/store.provider";
import * as API from '../../constants/api.constants';
import { dispatch } from "rxjs/internal/observable/pairs";

export const GetPrintingStats = (action$, store: Store<any>) =>
    action$.ofType(statisticsActionTypes.GET_PRINT_STATISTICS)
        .switchMap(action =>
            ajax.getJSON(`${API.GET_PRINT_STATISTICS}?startDate=${action.payload.startDate}&endDate=${action.payload.endDate}`,
                { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}`, "Content-Type": "application/json" })
                .map((response: any) => {
                    storeProvider.getStore().dispatch(success({
                        title: `Статистика печати получена`,
                        message: '',
                        position: 'br',
                        autoDismiss: 3,
                    }));
                    return statisticsActionTypes.GetPrintStatisticsSuccess(response.Body);
                }).catch((errorData: any) => {
                    return Observable.of(ErrorHandler(errorData));
                })
        );