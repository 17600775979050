import * as wizardActions from '../../actions/sales/wizard.actions';
import Product from '../../business.entities/product';
import { defaultWizardState, WizardState } from '../../states/sales/wizard.state';
import { object } from 'prop-types';
import { Order } from '../../business.entities/order';
import { OrderProduct } from '../../business.entities/order.product';
import { Reducer } from 'redux';
import { TempOrder } from '../../business.entities/temp.order';
import { DeliveryInfo } from '../../helpers/deliveryInfo.entity';
import { OrderProductFileTypeEnum } from '../../helpers/enums/order.productfile.type.enum';
import { Action } from 'rxjs/internal/scheduler/Action';

export const reducer: Reducer<any> = (state: WizardState, action: wizardActions.WizardKnownActions) => {
    switch (action.type) {
        case wizardActions.UPDATE_TEMP_ORDER:
            return { ...state, fetching: true } as WizardState;
        case wizardActions.APPROVE_ORDER_CREATION:
            return { ...state, isApproved: true } as WizardState;
        case wizardActions.SET_TEMP_ORDER:
            return {
                ...state,
                fetching: false,
                order: action.payload,
                bufferClient: Object.assign({}, state.bufferClient, action.payload.Client)
            } as WizardState;
        case wizardActions.SELECT_WIZARD_STEP:
            return { ...state, step: action.payload.component, stepNumber: action.payload.stepNumber } as WizardState;
        case wizardActions.SET_CATEGORIES:
            return { ...state, categoryList: action.payload } as WizardState;
        case wizardActions.SET_SUBCATEGORIES:
            return { ...state, subCategoryList: action.payload } as WizardState;
        case wizardActions.SELECT_CATEGORY:
            return {
                ...state,
                selectedCategory: action.payload,
                selectedSubCategory: defaultWizardState.selectedSubCategory
            } as WizardState;
        case wizardActions.SELECT_SUBCATEGORY:
            return {
                ...state,
                selectedSubCategory: action.payload,
                productList: defaultWizardState.productList
            } as WizardState;
        case wizardActions.SET_PRODUCTS:
            return {
                ...state,
                productList: action.payload,
                currentProduct: action.payload[0]
            } as WizardState;
        case wizardActions.SET_ADDITIONAL_SERVICE_PRODUCTS:
            return {
                ...state,
                additionalServiceProducts: action.payload,
                expressPrintProducts: action.payload.filter(product => product.Lexicon.OfficialPartnerName === "Экспресс печать и доставка"),
                extraPrintProducts: action.payload.filter(product => product.Lexicon.OfficialPartnerName !== "Экспресс печать и доставка")
            } as WizardState;
        case wizardActions.SET_EXPRESS_PRINT_PRODUCT:
            return {
                ...state,
                selectedExpressPrintProduct: action.payload,
                currentExpressPrintOrderProduct: {
                    ...state.currentExpressPrintOrderProduct,
                    Product: action.payload,
                    ProductId: action.payload !== null ? action.payload.Id : null,
                    PartnerPrice: action.payload.PartnerPrice,
                    Price: action.payload.PartnerPrice
                } as OrderProduct,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    ChildrenOrderProducts: [
                        {
                            ...state.currentExpressPrintOrderProduct,
                            Product: action.payload,
                            ProductId: action.payload !== null ? action.payload.Id : null,
                            PartnerPrice: action.payload.PartnerPrice,
                            Price: action.payload.PartnerPrice
                        } as OrderProduct,
                        state.currentExtraPrintOrderProduct
                    ].filter(orderProduct => orderProduct !== null && orderProduct.Product !== null)
                } as OrderProduct
            } as WizardState;
        case wizardActions.SET_EXTRA_PRINT_PRODUCT:
            return {
                ...state,
                selectedAdditionalPrintProduct: action.payload,
                currentExtraPrintOrderProduct: {
                    ...state.currentExtraPrintOrderProduct,
                    Product: action.payload,
                    ProductId: action.payload !== null ? action.payload.Id : null,
                    PartnerPrice: action.payload.PartnerPrice,
                    Price: action.payload.PartnerPrice
                } as OrderProduct,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    ChildrenOrderProducts: [
                        state.currentExpressPrintOrderProduct,
                        {
                            ...state.currentExtraPrintOrderProduct,
                            Product: action.payload,
                            ProductId: action.payload !== null ? action.payload.Id : null,
                            PartnerPrice: action.payload.PartnerPrice,
                            Price: action.payload.PartnerPrice
                        } as OrderProduct
                    ].filter(orderProduct => orderProduct !== null && orderProduct.Product !== null)
                } as OrderProduct
            } as WizardState;    
        case wizardActions.SET_SUBCATEGORY_REF:
            return { ...state, subcategorySelectRef: action.payload } as WizardState;
        case wizardActions.SELECT_CURRENT_PRODUCT:
            return { ...state, currentProduct: action.payload } as WizardState;
        case wizardActions.SET_NEXT_SUBSTEP:
            return {
                ...state,
                orderProductSubStep: action.payload.component,
                currentStepEnum: action.payload.stepEnum
            } as WizardState;
        case wizardActions.CONFIRM_PRODUCT_SELECT:
            return {
                ...state,
                currentProductColor: state.currentProduct.ProductColors.length > 0 ?
                    state.currentProduct.ProductColors[0].Color
                    : defaultWizardState.currentProductColor,
                currentOrderProduct: {
                    TempOrderId: state.order.Id,
                    Product: state.currentProduct,
                    PartnerPrice: state.currentProduct.PartnerPrice,
                    Price: state.currentProduct.PartnerPrice,
                    ProductId: state.currentProduct.Id,
                    Quantity: 1,
                    ColorId: null,
                    BaseColorId: null,
                    BaseProductId: null,
                    ChildrenOrderProducts: []
                } as OrderProduct,
                currentExpressPrintOrderProduct: {
                    TempOrderId: state.order.Id,
                    Quantity: 1,
                    Product: null,
                    ProductId: null
                } as OrderProduct,
                currentExtraPrintOrderProduct: {
                    TempOrderId: state.order.Id,
                    Quantity: 1,
                    Product: null,
                    ProductId: null
                } as OrderProduct
            } as WizardState;
        case wizardActions.SET_PATTERN:
            return {
                ...state,
                orderProductStepPattern: action.payload
            } as WizardState;
        case wizardActions.SELECT_CURRENT_PRODUCT_COLOR:
            return {
                ...state,
                currentProductColor: action.payload
            } as WizardState;
        case wizardActions.CONFIRM_PRODUCT_COLOR_SELECT:
            return {
                ...state,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    ColorId: state.currentProductColor.Id,
                    Color: state.currentProductColor
                } as OrderProduct
            } as WizardState;
        case wizardActions.CHANGE_CURRENT_ORDER_PRODUCT_PRICE:
            return {
                ...state,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    Price: action.payload
                } as OrderProduct
            } as WizardState;
        case wizardActions.CHANGE_CURRENT_ORDER_PRODUCT_QUANTITY:
            return {
                ...state,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    Quantity: action.payload,
                    ChildrenOrderProducts: state.currentOrderProduct.ChildrenOrderProducts
                        .map(child => {child.Quantity = action.payload; return child;})
                } as OrderProduct,
                currentExpressPrintOrderProduct: {
                    ...state.currentOrderProduct,
                    Quantity: action.payload
                } as OrderProduct,
                currentExtraPrintOrderProduct: {
                    ...state.currentOrderProduct,
                    Quantity: action.payload
                } as OrderProduct
            } as WizardState;
        case wizardActions.SET_BASES_FOR_PRODUCT:
            return {
                ...state,
                baseProductList: action.payload,
                currentBaseProduct: !state.currentOrderProduct.BaseProduct ? action.payload[0] : state.currentOrderProduct.BaseProduct
            } as WizardState;
        case wizardActions.SELECT_CURRENT_BASE_PRODUCT:
            return {
                ...state,
                currentBaseProduct: action.payload
            } as WizardState;
        case wizardActions.CONFIRM_CURRENT_BASE_PRODUCT:
            return {
                ...state,
                currentBaseColor: state.currentBaseProduct.ProductColors[0].Color,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    BaseProduct: state.currentBaseProduct,
                    BaseProductId: state.currentBaseProduct.Id
                } as OrderProduct
            } as WizardState;
        case wizardActions.SELECT_CURRENT_BASE_COLOR:
            return {
                ...state,
                currentBaseColor: state.productSelectionMode ? action.payload : state.currentOrderProduct.BaseColor
            } as WizardState;
        case wizardActions.CONFIRM_BASE_COLOR_SELECT:
            return {
                ...state,
                currentOrderProduct: {
                    ...state.currentOrderProduct,
                    BaseColorId: state.currentBaseColor.Id,
                    BaseColor: state.currentBaseColor
                } as OrderProduct
            } as WizardState;
        case wizardActions.CANCEL_CURRENT_ORDER_PRODUCT:
            return {
                ...state,
                currentOrderProduct: defaultWizardState.currentOrderProduct,
                orderProductStepPattern: defaultWizardState.orderProductStepPattern,
                currentProductColor: defaultWizardState.currentProductColor,
                orderProductSubStep: defaultWizardState.orderProductSubStep,
                currentStepEnum: defaultWizardState.currentStepEnum,
                baseProductList: defaultWizardState.baseProductList
            } as WizardState;
        case wizardActions.GO_TO_NEXT_STEP:
            return {
                ...state,
                orderProductSubStep: defaultWizardState.orderProductSubStep,
                currentStepEnum: defaultWizardState.currentStepEnum
            } as WizardState;
        case wizardActions.SAVE_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    serverActionInProcess: true
                } as WizardState
            );
        case wizardActions.SET_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        // OrderProducts: Object.assign([], state.order.OrderProducts, {
                        //     [state.order.OrderProducts.length]: action.payload.orderProduct
                        // }),
                        OrderProducts: Object.assign([], [
                            ...state.order.OrderProducts,
                            action.payload.orderProduct,
                            ...action.payload.orderProduct.ChildrenOrderProducts
                        ]),
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType
                    } as Order),
                    currentOrderProduct: defaultWizardState.currentOrderProduct,
                    currentExpressPrintOrderProduct: defaultWizardState.currentExpressPrintOrderProduct,
                    currentExtraPrintOrderProduct: defaultWizardState.currentExtraPrintOrderProduct,
                    selectedExpressPrintProduct: defaultWizardState.selectedExpressPrintProduct,
                    selectedAdditionalPrintProduct: defaultWizardState.selectedAdditionalPrintProduct,
                    orderProductStepPattern: defaultWizardState.orderProductStepPattern,
                    currentProductColor: defaultWizardState.currentProductColor,
                    orderProductSubStep: defaultWizardState.orderProductSubStep,
                    currentStepEnum: defaultWizardState.currentStepEnum,
                    serverActionInProcess: false
                } as WizardState
            );
        case wizardActions.SET_ORDER_PRODUCT_BY_INDEX:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([], state.order.OrderProducts, {
                            [action.payload.index]: action.payload.orderProduct
                        }),
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType
                    } as Order),
                    currentOrderProduct: defaultWizardState.currentOrderProduct,
                    currentExpressPrintOrderProduct: defaultWizardState.currentExpressPrintOrderProduct,
                    currentExtraPrintOrderProduct: defaultWizardState.currentExtraPrintOrderProduct,
                    selectedExpressPrintProduct: defaultWizardState.selectedExpressPrintProduct,
                    selectedAdditionalPrintProduct: defaultWizardState.selectedAdditionalPrintProduct,
                    orderProductStepPattern: defaultWizardState.orderProductStepPattern,
                    currentProductColor: defaultWizardState.currentProductColor,
                    orderProductSubStep: defaultWizardState.orderProductSubStep,
                    currentStepEnum: defaultWizardState.currentStepEnum,
                    serverActionInProcess: false
                } as WizardState
            );
        case wizardActions.CHANGE_PRODUCT_SELECTION_MODE:
            return {
                ...state,
                productSelectionMode: action.payload,
                orderProductIndex: state.orderProductIndex ? state.orderProductIndex : 0
            } as WizardState;
        case wizardActions.SELECT_ORDER_PRODUCT:
            return {
                ...state,
                orderProductIndex: action.payload
            } as WizardState;
        case wizardActions.CONFIRM_ORDER_PRODUCT_SELECTION:
            return {
                ...state,
                currentOrderProduct: action.payload,
                currentProduct: action.payload.Product,
                currentProductColor: action.payload.Product.ProductColors.length > 0 ?
                    (action.payload as OrderProduct).Color
                    : defaultWizardState.currentProductColor,
                currentBaseProduct: (action.payload as OrderProduct).BaseProduct ?
                    (action.payload as OrderProduct).BaseProduct :
                    defaultWizardState.currentBaseProduct,
                currentBaseColor: (action.payload as OrderProduct).BaseColor ?
                    (action.payload as OrderProduct).BaseColor :
                    defaultWizardState.currentBaseColor
            } as WizardState;
        case wizardActions.REMOVE_ORDER_PRODUCT_SERVER:
            return Object.assign({},
                state,
                {
                    serverActionInProcess: true
                } as WizardState
            );
        case wizardActions.REMOVE_ORDER_PRODUCT_CLIENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([], state.order.OrderProducts.filter((orderProduct) => {
                            return state.order.OrderProducts.indexOf(orderProduct) !== action.payload.index && 
                                orderProduct.ParentOrderProductID !== state.order.OrderProducts[action.payload.index].Id;
                        })),
                        PartnerTotalAmount: action.payload.partnerTotal,
                        TotalAmount: action.payload.total,
                        OrderType: action.payload.orderType
                    }),
                    serverActionInProcess: false
                } as WizardState);
        case wizardActions.SET_CLIENT_PHONE:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({}, state.bufferClient, {
                        PhoneNumber: action.payload.phone
                    }),
                    newClientMode: action.payload.mode
                } as WizardState
            );
        case wizardActions.SET_CLIENTS_FOR_PARTNER:
            return Object.assign({},
                state,
                {
                    clients: action.payload,
                } as WizardState
            );
        case wizardActions.SELECT_CLIENT_PARTNER:
            return Object.assign({},
                state,
                {
                    selectedClient: action.payload,
                    bufferClient: action.payload.object,
                    order: Object.assign({}, state.order, {
                        Client: action.payload.object,
                        ClientId: action.payload.value,
                        RecipientPhoneNumber: action.payload.object.PhoneNumber,
                        RecipientName: action.payload.object.FullName
                    } as Order)
                } as WizardState
            );
        case wizardActions.RESET_CLIENT_INFO:
            return Object.assign({},
                state,
                {
                    selectedClient: null,
                    bufferClient: defaultWizardState.bufferClient,
                    order: Object.assign({}, state.order, {
                        Client: null,
                        ClientId: null,
                        RecipientPhoneNumber: null,
                        RecipientName: null
                    } as Order)
                } as WizardState
            );
        case wizardActions.CHANGE_RECIPIENT:
            return Object.assign({},
                state,
                {
                    isRecipient: action.payload,
                    order: Object.assign({},
                        state.order, {
                        RecipientName: action.payload ? '' : state.order.Client.FullName,
                        RecipientPhoneNumber: action.payload ? '' : state.order.Client.PhoneNumber,
                    })
                } as WizardState
            );
        case wizardActions.CHANGE_RECIPIENT_NAME:
            return Object.assign({},
                state,
                {
                    order: Object.assign({},
                        state.order,
                        {
                            RecipientName: action.payload
                        } as Order)
                } as WizardState
            );
        case wizardActions.CHANGE_RECIPIENT_PHONE:
            return Object.assign({},
                state,
                {
                    order: Object.assign({},
                        state.order,
                        {
                            RecipientPhoneNumber: action.payload
                        } as Order)
                } as WizardState
            );
        case wizardActions.SET_DELIVERY_TYPE:
            return Object.assign({},
                state,
                {
                    order: Object.assign({},
                        state.order, {
                            DeliveryType: action.payload,
                            NovaPoshtaWarehouseRef: null,
                            NovaPoshtaWarehouseName: null,
                            NovaPoshtaCityRef: null,
                            NovaPoshtaCityName: null,
                            UkrPoshtaBuildingNumber: null,
                            UkrPoshtaCityName: null,
                            UkrPoshtaFlatNumber: null,
                            UkrPoshtaStreetName: null,
                            UkrPoshtaZipNumber: null
                        } as Order),
                    selectedCity: null,
                    selectedWarehouse: null,

                } as WizardState
            );
        case wizardActions.SET_PAYMENT_METHOD:
            return Object.assign({},
                state,
                {
                    order: Object.assign({},
                        state.order,
                        {
                            PaymentMethod: action.payload
                        } as Order)
                } as WizardState
            );
        case wizardActions.CHANGE_NEW_CLIENT_NAME:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({},
                        state.bufferClient, {
                        FullName: action.payload
                    })
                } as WizardState
            );
        case wizardActions.CHANGE_NEW_CLIENT_EMAIL:
            return Object.assign({},
                state,
                {
                    bufferClient: Object.assign({},
                        state.bufferClient, {
                        Email: action.payload
                    })
                } as WizardState
            );
        case wizardActions.UPDATE_ORDER_PRODUCT_CLIENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({},
                        state.order,
                        {
                            OrderProducts: Object.assign([],
                                state.order.OrderProducts,
                                {
                                    [action.payload.index]: action.payload.value
                                }),
                            PartnerTotalAmount: action.payload.partnerTotal,
                            TotalAmount: action.payload.total,
                        } as Order),
                    currentOrderProduct: defaultWizardState.currentOrderProduct,
                    orderProductStepPattern: defaultWizardState.orderProductStepPattern,
                    currentProductColor: defaultWizardState.currentProductColor,
                    orderProductSubStep: defaultWizardState.orderProductSubStep,
                    currentStepEnum: defaultWizardState.currentStepEnum
                } as WizardState
            );
        case wizardActions.SET_NEW_CLIENT:
            return Object.assign({},
                state,
                {
                    bufferClient: action.payload,
                    order: Object.assign({}, state.order, {
                        Client: action.payload,
                        ClientId: action.payload.Id,
                        RecipientPhoneNumber: action.payload.PhoneNumber,
                        RecipientName: action.payload.FullName
                    } as Order)
                } as WizardState
            );
        case wizardActions.OPEN_FINISH_MENU:
            return Object.assign({},
                state,
                {
                    isFinishMenuOpen: action.payload,
                    finishStatus: defaultWizardState.finishStatus,
                    isValidated: defaultWizardState.isValidated,
                } as WizardState);

        case wizardActions.CALCULATE_PRODUCT_TOTAL:
            return Object.assign({},
                state,
                {
                    serverActionInProcess: true
                } as WizardState
            );

        case wizardActions.SET_TOTAL_FOR_CURRENT_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    currentOrderProduct: Object.assign({}, state.currentOrderProduct, {
                        PartnerPrice: action.payload.Item1,
                        PartnerTotal: action.payload.Item2,
                        Total: action.payload.Item3
                    } as OrderProduct),
                    serverActionInProcess: false
                } as WizardState);
        case wizardActions.SET_CITIES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    cities: Object.assign(
                        [],
                        action.payload
                    ),
                } as WizardState);
        case wizardActions.SELECT_CITIES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    selectedCity: action.payload,
                    warehouses: defaultWizardState.warehouses,
                    selectedWarehouse: defaultWizardState.selectedWarehouse,
                    order: Object.assign({},
                        state.order, {
                            NovaPoshtaCityRef: action.payload.value,
                            NovaPoshtaCityName: action.payload.label,
                            NovaPoshtaWarehouseRef: null,
                            NovaPoshtaWarehouseName: null
                        } as Order)
                } as WizardState);
        case wizardActions.SET_WEREHOUSES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    warehouses: action.payload,
                } as WizardState);
        case wizardActions.SELECT_WEREHOUSES_FOR_AUTOCOMPLETE:
            return Object.assign({},
                state,
                {
                    selectedWarehouse: action.payload,
                    order: Object.assign({},
                        state.order, {
                            NovaPoshtaWarehouseRef: action.payload.value,
                            NovaPoshtaWarehouseName: action.payload.label
                        } as Order)
                } as WizardState);
        case wizardActions.SET_CHECKED_TEMP_ORDER:
            return Object.assign({},
                state,
                {
                    order: action.payload,
                    tempOrderChecked: true
                } as WizardState);
        case wizardActions.SELECT_FINISH_STATUS:
            return Object.assign({},
                state,
                {
                    finishStatus: Object.assign([], defaultWizardState.finishStatus, {
                        [action.payload.value]: Object.assign(
                            {},
                            defaultWizardState.finishStatus[action.payload.value],
                            {
                                isSelected: true
                            })
                    }),
                    isValidated: action.payload.validated
                } as WizardState);
        case wizardActions.SET_USER_IMAGE:
            return Object.assign({},
                state,
                {
                    currentOrderProduct: Object.assign({}, state.currentOrderProduct, {
                        UserImage: action.payload.fileType === OrderProductFileTypeEnum.UserImage ? action.payload.url : state.currentOrderProduct.UserImage,
                        UserPrewiev: action.payload.fileType === OrderProductFileTypeEnum.Preview ? action.payload.url : state.currentOrderProduct.UserPrewiev
                    } as OrderProduct)
                } as WizardState);
        case wizardActions.SET_USER_IMAGE_COMMENT:
            return Object.assign({},
                state,
                {
                    currentOrderProduct: Object.assign({}, state.currentOrderProduct, {
                        UserImageComment: action.payload
                    } as OrderProduct)
                } as WizardState);
        case wizardActions.CHANGE_UKR_CITY_NAME:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        UkrPoshtaCityName: action.payload
                    } as Order)
                } as WizardState);
        case wizardActions.CHANGE_UKR_STREET_NAME:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        UkrPoshtaStreetName: action.payload
                    } as Order)
                } as WizardState);

        case wizardActions.CHANGE_UKR_BUILDING_NUM:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        UkrPoshtaBuildingNumber: action.payload
                    } as Order)
                } as WizardState);

        case wizardActions.CHANGE_UKR_FLAT_NUM:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        UkrPoshtaFlatNumber: action.payload
                    } as Order)
                } as WizardState);

        case wizardActions.CHANGE_UKR_ZIP:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        UkrPoshtaZipNumber: action.payload
                    } as Order)
                } as WizardState);
        case wizardActions.CHANGE_GLOBAL_COMMENT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        GlobalComment: action.payload
                    } as Order)
                } as WizardState);
        case wizardActions.SET_DELIVERY_PRICE:
            return Object.assign({},
                state,
                {
                    deliveryPayment: action.payload
                } as WizardState);
        case wizardActions.SET_SEND_DATE:
            return Object.assign({},
                state,
                {
                    sendDate: action.payload
                } as WizardState);
        case wizardActions.SET_DELIVERY_DATE:
            return Object.assign({},
                state,
                {
                    deliveryDate: action.payload
                } as WizardState);
        case wizardActions.SET_PRODUCTION_COMMENT_FOR_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    currentOrderProduct: Object.assign({}, state.currentOrderProduct, {
                        ProductionComment: action.payload
                    } as OrderProduct)
                } as WizardState);
        case wizardActions.SET_PROCESSING_ORDER_STATE:
            return Object.assign({},
                state,
                {
                    proccessingOrderState: true
                } as WizardState);
        case wizardActions.SET_REFRESHED_ORDERPRODUCT:
            return Object.assign({},
                state,
                {
                    order: Object.assign({}, state.order, {
                        OrderProducts: Object.assign([], state.order.OrderProducts, {
                            [action.payload.index]: action.payload.orderProduct
                        }),
                    } as Order)
                } as WizardState
            );
        // case wizardActions.SET_OPTIONS_FOR_LEXICON:
        //     return Object.assign({},
        //         state,
        //         {
        //             opotionCategoryTree: action.payload
        //         } as WizardState);
        case wizardActions.SELECT_OPTION_FOR_ORDER_PRODUCT:
            return Object.assign({},
                state,
                {
                    currentOrderProduct: Object.assign({}, state.currentOrderProduct, {
                        OrderProductAdditionalOption: action.payload
                    } as OrderProduct)
                } as WizardState);
        case wizardActions.CLEAR_WIZARD:
            return defaultWizardState;
        default:
            return state || defaultWizardState;
    }
}