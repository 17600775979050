import * as React from "react";
import { connect } from "react-redux";

export const Delivery = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество отправленных заказов, независимо от статуса
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество отправленных заказов, которые на момент проверки имеют любой статус. Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SendedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество отправленных заказов со статусом "Получен"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, которые на момент проверки имеют статус "Получен". Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.RecivedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество отправленных заказов со статусом "Не забранный"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, которые на момент проверки имеют статус "Не забранный". Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.OverdueQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество отправленных заказов со статусом "Отказ"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, которые на момент проверки имеют статус "Отказ". Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IgnoredQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество отправленных заказов по НП
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, тип доставки которых "Новая Почта". Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.NPQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество отправленных заказов по УП
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, тип доставки которых "Укр Пошта". Отправленным заказом, считается заказ, который был отмечен на складе как "Отправлен"
                                </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.UPQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row  mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                Количество самовывезенных заказов
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                Количество отправленных заказов, тип доставки которых "Самовывоз". Отправленным заказом, считается заказ, который был отмечен на складе как "Получен"
                                 </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.SelfQuantity}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({ Statistics: (state as any).cabinet.statistics }))(Delivery);
