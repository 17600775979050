import * as React from "react";
import * as Notifications from "react-notification-system-redux";
import { connect } from "react-redux";
import { ApplicationState } from "../../Reducers/index";

class NotificationRoot extends React.Component<any, any> {

    public render() {
        const style = {
            NotificationItem: {
                DefaultStyle: {
                    margin: '10px 5px 2px 1px'
                },
                success: {
                    color: 'green',
                },
                error: {
                    color: 'red',
                },
                warning: {
                    color: 'yellow',
                },
                info: {
                    color: 'blue',
                }
            }
        };

        return (
            <Notifications
                notifications={this.props.Notifications}
                style={style}
            />
        );
    }
}
export default connect(
    (state: ApplicationState) => ({ Notifications: state.notifications }))
    (NotificationRoot);


