import * as PropTypes from 'prop-types';
import * as React from 'react';
import PartnerInfo from './partners/partner.accounts/partner.info/partner.info';
import PartnersComponent from './partners/partners';
import SalesComponent from './sales/sales';
import { ApplicationState } from '../../Reducers/index';
import { error, success, warning, info } from "react-notification-system-redux";
import CabinetComponent from './cabinet/cabinet';
import { connect } from 'react-redux';
import { default as RightView } from '../shared/right.view/right.view';
import { deleteCookie } from '../../helpers/cookies';
import { Header } from './common/header/header';
import { Logout } from '../../actions/login.actions';
import { MenuProvider } from '../../helpers/menu.provider';
import { ProtectedRoute } from '../../helpers/protected.route';
import { push } from 'react-router-redux';
import { Redirect, Route } from 'react-router-dom';
import { StartRouteRedirect } from '../../helpers/start.route';
import StockComponent from './stock/stock';
import {
    RouteComponentProps,
} from "react-router-dom";
import { bindActionCreators } from 'redux';
import { GetIdentityRoleName, IdentityRoleEnum, GetIdentityRoleByName } from '../../helpers/identity.roles';
import * as API from '../../constants/api.constants';

export namespace Dashboard {
    export interface IState {
        MenuItems: any;
    }

    export interface IProps {
        MenuProvider: MenuProvider;
        error(obj: any);
        success(obj: any);
        warning(obj: any);
        info(obj: any);
    }
}

export type DashboardProps = Dashboard.IProps & RouteComponentProps<{}> & ApplicationState | any;

class Dashboard extends React.Component<DashboardProps, Dashboard.IState>{
    constructor(props) {
        super(props);

        this.state = {
            MenuItems: this.props.MenuProvider.GetRootMenuItems()
        };
    }

    static contextTypes = {
        store: PropTypes.object,
        router: PropTypes.object
    }

    static defaultProps = {
        MenuProvider: new MenuProvider(),
    };

    onLogoutCommand() {
        deleteCookie('token');
        this.context.store.dispatch(Logout());
        window.open('https://b2b.caseshop.com.ua/auth/login', '_self');
        //this.context.store.dispatch(push('/auth/login'));
    }

    onGetClassForRole(value): string {
        switch (value) {
            case IdentityRoleEnum.Boss:
                return 'roleBoss__container';
            case IdentityRoleEnum.Coordinator:
                return 'roleCoordinator__container';
            case IdentityRoleEnum.Sklad:
                return 'roleSklad__container';
            case IdentityRoleEnum.Print:
                return 'rolePrint__container';
            case IdentityRoleEnum.Partner:
                return 'rolePartner__container';
            case IdentityRoleEnum.Manufacture:
                return 'roleManufacture__container';
            case IdentityRoleEnum.Maysternya:
                return 'roleMaysternya__container';
            case IdentityRoleEnum.Booker:
                return 'roleBooker__container';
            case IdentityRoleEnum.Admin:
                return 'roleAdmin__container';
            case IdentityRoleEnum.Administrator:
                return 'roleAdministrator__container';
            case IdentityRoleEnum.User:
                return 'roleUser__container';
        }

        return '';
    }

    render() {
        const isAuthenticated = this.context.store.getState().authentication.isAuthenticated;
        const role = this.context.store.getState().authentication.Role;
        const userName = this.context.store.getState().authentication.UserName;
        console.log(role)
        if (!isAuthenticated) {
            return (
                <Redirect to="/auth/login" />
            );
        }
        return (
            <div className={`master_page_component ${this.onGetClassForRole(GetIdentityRoleByName(role))}`}>
                <Header
                    role={role}
                    user={userName}
                    logoutCommand={this.onLogoutCommand.bind(this)}
                    history={this.props.history}
                    MenuItems={this.state.MenuItems} />

                <StartRouteRedirect role={role} path={this.props.history.location.pathname} />

                <Route path={this.props.match.url + "stock"} component={StockComponent} />
                <Route path={this.props.match.url + "sales"} component={SalesComponent} />
                <ProtectedRoute
                    path={this.props.match.url + "partners"}
                    component={PartnersComponent}
                    role={role}
                    rolesAllowed={[GetIdentityRoleName(IdentityRoleEnum.Boss), GetIdentityRoleName(IdentityRoleEnum.Coordinator), GetIdentityRoleName(IdentityRoleEnum.Administrator)]} /> 
               
                <Route path={this.props.match.url + "cabinet"} component={CabinetComponent} />

                <Route path={"/partners/edit/:netid"} component={PartnerInfo} />

                {
                    this.props.rightView.Title &&
                    this.props.rightView.Component &&
                    <Route path={this.props.history.location.pathname} component={RightView} />
                }


                {
                    this.props.rightView.HoverData ?
                        <div className="imgThumb__WRAPPER__IMG is_active" >
                            <div className="item_color_name">
                                {this.props.rightView.HoverData.Name}
                            </div>
                            <img src={`${API.CRM_URL + this.props.rightView.HoverData.Image}`} />
                        </div>
                        : null
                }

            </div>
        );
    }
}
export default connect(state => state, (dispatch) => bindActionCreators({
    error, success, warning, info
}, dispatch))(Dashboard);
