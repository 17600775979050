import { connect } from "react-redux";
import * as React from "react";
import { bindActionCreators } from "redux";
import { DeliveryType } from "../../../../../helpers/enums/delivery.type";
import { Order } from "../../../../../business.entities/order";
import Select from 'react-select';
import { OrderCardState } from "../../../../../states/sales/order.card.state";
import {
    SelectDeliveryType,
    SelectCitiesForAutocomplete,
    GetWerehousesForAutocomplete,
    GetCitiesForAutocomplete,
    SelectWerehousesForAutocomplete,
    SetCurrentDeliveryInfo,
    ChangeUkrCityName,
    ChangeUkrStreetName,
    ChangeUkrBuildingNum,
    ChangeUkrFlatNum,
    ChangeUkrZip,
    UpdateOrder
} from "../../../../../actions/sales/order.card.actions";
import { PaymentMethod } from "../../../../../helpers/enums/payment.method";
import { warning, removeAll, error, info } from 'react-notification-system-redux';
import { RemoveRVData } from '../../../../../actions/right.view.actions';

const mapStateToProps = (state) => {
    return {
        OrderCard: state.sales.orderCard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    UpdateOrder,
    SelectDeliveryType,
    SelectCitiesForAutocomplete,
    GetWerehousesForAutocomplete,
    GetCitiesForAutocomplete,
    SelectWerehousesForAutocomplete,
    SetCurrentDeliveryInfo,
    ChangeUkrCityName,
    ChangeUkrStreetName,
    ChangeUkrBuildingNum,
    ChangeUkrFlatNum,
    ChangeUkrZip,
    warning,
    removeAll,
    error,
    RemoveRVData,
    info
}, dispatch);

const DeliveryRv = (props: any) => {
    const orderCard: OrderCardState = props.OrderCard;

    const OnSetDeliveryTypeCommand = (deliveryType) => {
        props.SelectDeliveryType(deliveryType);



        if (orderCard.order.DeliveryType === deliveryType) {
            props.SetCurrentDeliveryInfo({
                deliveryType: orderCard.order.DeliveryType,
                novaPoshtaCity: orderCard.order.DeliveryType === DeliveryType.Nova ? {
                    value: orderCard.order.NovaPoshtaCityRef,
                    label: orderCard.order.NovaPoshtaCityName
                } : null,
                novaPoshtaWarehouse: orderCard.order.DeliveryType === DeliveryType.Nova ? {
                    value: orderCard.order.NovaPoshtaWarehouseRef,
                    label: orderCard.order.NovaPoshtaWarehouseName
                } : null,
                ukrPoshtaCityName: orderCard.order.UkrPoshtaCityName,
                ukrPoshtaStreetName: orderCard.order.UkrPoshtaStreetName,
                ukrPoshtaBuildingNumber: orderCard.order.UkrPoshtaBuildingNumber,
                ukrPoshtaFlatNumber: orderCard.order.UkrPoshtaFlatNumber,
                ukrPoshtaZipNumber: orderCard.order.UkrPoshtaZipNumber
            });
        }

    }

    const OnChangeCityInputCommand = (value) => {
        props.GetCitiesForAutocomplete(value === '' ? 'ав' : value);
    }

    const OnChangeCityCommand = (value) => {
        props.SelectCitiesForAutocomplete(value);
        props.GetWerehousesForAutocomplete(value.value);
    }

    const GetValidPaymentMethod = () => {
        if (orderCard.selectedDeliveryType === DeliveryType.Ukr)
            return PaymentMethod.Prepaid;

        if (orderCard.selectedDeliveryType === DeliveryType.Self)
            return PaymentMethod.Prepaid;

        /*
        if (orderCard.selectedDeliveryType === DeliveryType.Nova
            && orderCard.selectedWarehouse.entity.CategoryOfWarehouse === 'Postomat')
            return PaymentMethod.Prepaid;
        */

        return orderCard.order.PaymentMethod;
    }

    const OnChangeDeliveryInfoCommand = () => {
        const order = Object.assign({}, orderCard.order, {
            DeliveryType: orderCard.selectedDeliveryType,
            NovaPoshtaCityName: orderCard.selectedDeliveryType === DeliveryType.Nova ? orderCard.selectedCity.label : '',
            NovaPoshtaCityRef: orderCard.selectedDeliveryType === DeliveryType.Nova ? orderCard.selectedCity.value : '',
            NovaPoshtaWarehouseName: orderCard.selectedDeliveryType === DeliveryType.Nova ? orderCard.selectedWarehouse.label : '',
            NovaPoshtaWarehouseRef: orderCard.selectedDeliveryType === DeliveryType.Nova ? orderCard.selectedWarehouse.value : '',
            UkrPoshtaCityName: orderCard.selectedDeliveryType === DeliveryType.Ukr ? orderCard.selectedUkrPoshtaCityName : '',
            UkrPoshtaStreetName: orderCard.selectedDeliveryType === DeliveryType.Ukr ? orderCard.selectedUkrPoshtaStreetName : '',
            UkrPoshtaBuildingNumber: orderCard.selectedDeliveryType === DeliveryType.Ukr ? orderCard.selectedUkrPoshtaBuildingNumber : '',
            UkrPoshtaFlatNumber: orderCard.selectedDeliveryType === DeliveryType.Ukr ? orderCard.selectedUkrPoshtaFlatNumber : '',
            UkrPoshtaZipNumber: orderCard.selectedDeliveryType === DeliveryType.Ukr ? orderCard.selectedUkrPoshtaZipNumber : '',
            PaymentMethod: GetValidPaymentMethod()
        } as Order);

        props.SetCurrentDeliveryInfo({
            deliveryType: order.DeliveryType,
            novaPoshtaCity: order.DeliveryType === DeliveryType.Nova ? {
                value: order.NovaPoshtaCityRef,
                label: order.NovaPoshtaCityName
            } : null,
            novaPoshtaWarehouse: order.DeliveryType === DeliveryType.Nova ? {
                value: order.NovaPoshtaWarehouseRef,
                label: order.NovaPoshtaWarehouseName
            } : null,
            ukrPoshtaCityName: order.UkrPoshtaCityName,
            ukrPoshtaStreetName: order.UkrPoshtaStreetName,
            ukrPoshtaBuildingNumber: order.UkrPoshtaBuildingNumber,
            ukrPoshtaFlatNumber: order.UkrPoshtaFlatNumber,
            ukrPoshtaZipNumber: order.UkrPoshtaZipNumber,
        });

        props.UpdateOrder(order);

        props.removeAll();
    }

    const OnChangeWarehouseCommand = (value) => {
        props.SelectWerehousesForAutocomplete(value);

        /*
        if (value.entity.CategoryOfWarehouse === 'Postomat' && orderCard.order.PaymentMethod === PaymentMethod.Afterpaid)
            props.info({
                title: 'ВНИМАНИЕ!',
                message: 'Наложенный платеж при отправлении на почтомат - невозможен! Способ оплаты будет изменен автоматически.',
                position: 'tr',
                autoDismiss: 0,
            });
        */
    }

    return (
        <div className="deliveryRVContainer">
            <div className="defaultFormControl">
                <div className="defaultFormControl__label"> Метод доставки</div>
                <div className="deliveryCheckWrapper__container">
                    <label className={`bHBCheckbox ${orderCard.selectedDeliveryType === DeliveryType.Nova ? 'isSelected' : ''}`}
                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Nova)}>
                        <span>Нова почта</span>
                    </label>
                    <label className={`bHBCheckbox ${orderCard.selectedDeliveryType === DeliveryType.Ukr ? 'isSelected' : ''}`}
                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Ukr)}>
                        <span>Укрпочта</span>
                    </label>
                    <label className={`bHBCheckbox ${orderCard.selectedDeliveryType === DeliveryType.Self ? 'isSelected' : ''}`}
                        onClick={() => OnSetDeliveryTypeCommand(DeliveryType.Self)}>
                        <span>Самовывоз</span>
                    </label>
                </div>
            </div>

            {orderCard.selectedDeliveryType === DeliveryType.Nova &&
                <div>
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Город</div>
                        <div className="selectWrapper__container">
                            <Select
                                onInputChange={OnChangeCityInputCommand}
                                options={orderCard.cities}
                                onChange={OnChangeCityCommand}
                                value={orderCard.selectedCity}
                                onFocus={() => { }}
                                openMenuOnFocus={true}
                                placeholder="Город"
                            />
                        </div>
                    </div>
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Отделение</div>
                        <div className="selectWrapper__container">
                            <Select
                                options={orderCard.warehouses}
                                onChange={OnChangeWarehouseCommand}
                                value={orderCard.selectedWarehouse}
                                onFocus={() => { }}
                                openMenuOnFocus={true}
                                placeholder="Отделение"
                            />
                        </div>
                    </div>
                </div>
            }

            {orderCard.selectedDeliveryType === DeliveryType.Ukr &&
                <div >
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Область, город</div>
                        <div className="defaultFormControl__value">
                            <input type="text"
                                value={orderCard.selectedUkrPoshtaCityName ? orderCard.selectedUkrPoshtaCityName : ''}
                                onChange={(event) => props.ChangeUkrCityName(event.target.value)} />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Улица</div>
                        <div className="defaultFormControl__value">
                            <input type="text"
                                value={orderCard.selectedUkrPoshtaStreetName ? orderCard.selectedUkrPoshtaStreetName : ''}
                                onChange={(event) => props.ChangeUkrStreetName(event.target.value)} />
                        </div>
                    </div>


                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Дом</div>
                        <div className="defaultFormControl__value">
                            <input type="text"
                                value={orderCard.selectedUkrPoshtaBuildingNumber ? orderCard.selectedUkrPoshtaBuildingNumber : ''}
                                onChange={(event) => props.ChangeUkrBuildingNum(event.target.value)} />
                        </div>
                    </div>

                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Квартира</div>
                        <div className="defaultFormControl__value">
                            <input type="text"
                                value={orderCard.selectedUkrPoshtaFlatNumber ? orderCard.selectedUkrPoshtaFlatNumber : ''}
                                onChange={(event) => props.ChangeUkrFlatNum(event.target.value)} />
                        </div>
                    </div>
                    <div className="defaultFormControl">
                        <div className="defaultFormControl__label">Индекс</div>
                        <div className="defaultFormControl__value">
                            <input type="text"
                                value={orderCard.selectedUkrPoshtaZipNumber ? orderCard.selectedUkrPoshtaZipNumber : ''}
                                onChange={(event) => props.ChangeUkrZip(event.target.value)} />
                        </div>
                    </div>
                </div>
            }


            <div className="defaultFormControl">
                <div className="defaultFormButtonControl" onClick={OnChangeDeliveryInfoCommand}>
                    Сохранить
                </div>
            </div>
        </div>
    );
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryRv);