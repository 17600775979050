import { Partner } from "../business.entities/partner";

export const LOGIN: string = '[API] LOGIN';
export interface Login { type: 'USER_LOGIN'; payload: any; }
export const Login = (username: string, password: string) =>
    <Login>{ type: LOGIN, payload: { username: username, password: password } };

export const LOGOUT: string = 'LOGOUT';
export interface Logout { type: 'USER_LOGOUT'; payload: any; }
export const Logout = () =>
    <Logout>{ type: LOGOUT };

export const LOGIN_SUCCESS: string = 'LOGIN_SUCCESS';
export interface SuccessLogin { type: "SUCCESS_LOGIN"; payload: any; }
export const LoginSuccess = (token: string, refresh: string, userLogin: string, role: string, userNetUid: string, exp: any) =>
    <SuccessLogin>{ type: LOGIN_SUCCESS, payload: { token: token, refresh: refresh, userLogin: userLogin, role: role, userNetUid: userNetUid, exp: exp } };

export const LOGIN_FAILED: string = 'LOGIN_FAILED';
export interface LoginFailed { type: "LOGIN_FAILED"; payload: any; }
export const LoginFailed = (message: string) =>
    <LoginFailed>{ type: LOGIN_FAILED, payload: message };

export const GET_USER_INFO: string = '[API] GET_USER_INFO';
export interface IGetUserInfo { type: '[API] GET_USER_INFO'; payload: string; }
export const GetUserInfo = (UserNetUid: string) =>
    <IGetUserInfo>{ type: GET_USER_INFO, payload: UserNetUid };

export const SET_USER_INFO: string = 'SET_USER_INFO';
export interface ISetUserInfo { type: 'SET_USER_INFO'; payload: Partner; }
export const SetUserInfo = (userInfo: Partner) =>
    <ISetUserInfo>{ type: SET_USER_INFO, payload: userInfo };


export const SET_REFRESHED_INFO: string = 'SET_REFRESHED_INFO';
export interface ISetRefreshedInfo { type: 'SET_REFRESHED_INFO'; payload: Partner; }
export const SetRefreshedInfo = (info: any) =>
    <ISetRefreshedInfo>{ type: SET_REFRESHED_INFO, payload: info };

export const SET_REFRESH_PROPMISE: string = 'SET_REFRESH_PROPMISE';
export interface ISetRefreshPromise { type: 'SET_REFRESH_PROPMISE'; payload: Partner; }
export const SetRefreshPromise = (promise: any) =>
    <ISetRefreshPromise>{ type: SET_REFRESH_PROPMISE, payload: promise };

export type AuthenticationKnownActions = Login | Logout | SuccessLogin | LoginFailed | IGetUserInfo | ISetUserInfo | ISetRefreshedInfo | ISetRefreshPromise;
