import * as React from 'react';
import {connect} from 'react-redux';
import {IdentityRoleEnum} from '../../../../helpers/identity.roles';
import PermissionChecker from '../../../shared/permission.checker.component';

export const Print = (props) => {

    const model = props.Statistics.StatisticsEntity;

    return (
        <div className="grid_component productionGRID__CONTAINER">
            <div className="grid_container">
                <div className="grid_header">
                    <div className="grid_header_wrapper">
                        <div className="grid_row row_header">

                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content">Показатель</span>
                            </div>
                            <div className="grid_cell">
                                <span className="cell_content">Описание</span>
                            </div>
                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content">Значение</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid_scroll_content_component">
                    <div className="grid_wrapper_component">
                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Ожидает сборки"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.AssemblingQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Готов к печати"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReadyToPrintQuantity}
                                </span>
                            </div>

                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Вопрос по картинке"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IssueQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Брак"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.FailQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен в "Напечатан"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PrintedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Ворпос по картинке"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только олин перевод в определенный статус </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToIssueQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Брак"
                                </span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только олин перевод в определенный статус   </span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToFailQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Готов к печати" в "Напечатан"</span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только олин перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToPrintedQuantity}
                                </span>
                            </div>
                        </div>

                        <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                            <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых был переведен с "Брак" в "Напечатан"</span>
                            </div>

                            <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Количество товаров, статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только олин перевод в определенный статус</span>
                            </div>

                            <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionFailToPrintedQuantity}
                                </span>
                            </div>
                        </div>

                        <PermissionChecker ViewRoles={[IdentityRoleEnum.Boss, IdentityRoleEnum.Coordinator]} simple>

                            <div className="grid_row noStylesmMEDIA___ mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name"></div>
                                <div className="grid_cell"></div>
                                <div className="grid_cell cell__GRID_CONT"></div>
                            </div>

                            {/*  */}
                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен в "Ожидает сборки"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.AssemblingTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен в "Готов к печати"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус</span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.ReadyToPrintTotal}
                                </span>
                                </div>

                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен в "Вопрос по картинке"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.IssueTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен в "Брак"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.FailTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен в "Напечатан"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время приобретал определенное значения. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.PrintedTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен с "Готов к печати" в "Ворпос по картинке"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToIssueTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен с "Готов к печати" в "Брак"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToFailTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен с "Готов к печати" в "Напечатан"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionReadyToPrintedTotal}
                                </span>
                                </div>
                            </div>

                            <div className="grid_row mMEDIA___cabinetMP__GRID__row">
                                <div className="grid_cell cell__GRID_name">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых был переведен с "Брак" в "Напечатан"
                                </span>
                                </div>

                                <div className="grid_cell">
                                <span className="cell_content__GC">
                                    Стоимость товаров типа "Печать", статус печати которых за все время был переведен из одного статуса в другой. Во избежание скликования, показатель учитывает только один перевод в определенный статус
                                </span>
                                </div>

                                <div className="grid_cell cell__GRID_CONT">
                                <span className="cell_content__GC">
                                    {model.TransitionFailToPrintedTotal}
                                </span>
                                </div>
                            </div>
                        </PermissionChecker>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default connect((state) => ({Statistics: (state as any).cabinet.statistics}))(Print);
