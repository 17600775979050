import { default as ColorStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/color";
import { default as AdditionalOptions } from "../../components/dashboard/sales/create.order/addOrderProductSteps/additional.options";
import { default as BaseProductStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/base.product";
import { default as BaseColorStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/base.color";
import { default as DesignStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/design";
import { default as UserImageStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/user.image";
import { default as PriceStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/price";
import { default as QuantityStep } from "../../components/dashboard/sales/create.order/addOrderProductSteps/quantity";

import { default as OrderCardBaseColorStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/base.color";
import { default as OrderCardAdditionalOptions } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/additional.options";
import { default as OrderCardColorStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/color";
import { default as OrderCardBaseProductStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/base.product";
import { default as OrderCardDesignStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/design";
import { default as OrderCardUserImageStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/user.image";
import { default as OrderCardPriceStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/price";
import { default as OrderCardQuantityStep } from "../../components/dashboard/sales/order.card/components/updateOrderProductSteps/quantity";
import additionalOptions from "../../components/dashboard/sales/create.order/addOrderProductSteps/additional.options";

export enum OrderProductStep {
    ProductColor,
    BaseProduct,
    BaseProductColor,
    Design,
    Picture,
    Price,
    Quantity,
    AdditionalOption
}

export const GetOrderProductStepComponent = (step: OrderProductStep) => {
    switch (step) {
        case 0:
            return ColorStep;
        case 1:
            return BaseProductStep;
        case 2:
            return BaseColorStep;
        case 3:
            return DesignStep;
        case 4:
            return UserImageStep;
        case 5:
            return PriceStep;
        case 6:
            return QuantityStep;
        case 7:
            return AdditionalOptions;
    }
}

export const GetOrderProductOrderCardStepComponent = (step: OrderProductStep) => {
    switch (step) {
        case 0:
            return OrderCardColorStep;
        case 1:
            return OrderCardBaseProductStep;
        case 2:
            return OrderCardBaseColorStep;
        case 3:
            return OrderCardDesignStep;
        case 4:
            return OrderCardUserImageStep;
        case 5:
            return OrderCardPriceStep;
        case 6:
            return OrderCardQuantityStep;
        case 7:
            return OrderCardAdditionalOptions;
    }
}

export const GetOrderProductStepName = (step: OrderProductStep) => {
    switch (step) {
        case 0:
            return 'Цвет продукта';
        case 1:
            return "Основа";
        case 2:
            return "Цвет основы";
        case 3:
            return "Дизайн";
        case 4:
            return "Картинка";
        case 5:
            return "Цена";
        case 6:
            return "Количество";
        case 7:
            return "Доп. Опции";
    }
}