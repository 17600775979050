import * as React from "react";
import { TextBox } from "./textbox";
import { TextArea } from "./textarea";

interface ITextAreaWithLableProps {
    LabelMessage: string;
    ErrorMessages?: any;
    Model: string;
    IsRequired: boolean;
    Validators?: any,
    ValidatedOn?: any,
    AsyncValidators?: any;
    AsyncValidateOn?: any;
    ShowErrorOn?: any,
    Placeholder?: any,
    Readonly?: boolean,
}

export const TextAreaWithLabel = (props: ITextAreaWithLableProps) => {
    return (
        <div className="defaultFormControl">
            <div className="defaultFormControl__label">
                {props.LabelMessage}
            </div>
            <TextArea
                Readonly={props.Readonly}
                Placeholder={props.Placeholder}
                ErrorMessages={props.ErrorMessages}
                Model={props.Model}
                IsRequired={props.IsRequired}
                ShowErrorOn={props.ShowErrorOn}
                ValidatedOn={props.ValidatedOn}
                Validators={props.Validators}
                AsyncValidators={props.AsyncValidators}
                AsyncValidateOn={props.AsyncValidateOn}
            />
        </div>
    );
};