import { StatisticsKnownActions } from "../../actions/cabinet/statistics.actions";
import { StatisticsState, DefaultStatisticsState } from "../../states/cabinet/statistics.state";
import * as statisticsActionTypes from "../../actions/cabinet/statistics.actions";
import { Reducer } from "redux";
import { GetStatisticsTypesForSelectForBoss, GetStatisticsTypesForSelectForCoordinator } from "../../helpers/enums/statistics.type.enum";
import { GetStatisticsTypesForDiagrams, GetPartnerStatisticsTypesForDiagrams } from "../../helpers/enums/diagram.stats.enum";
import { GetTimePartArray } from "../../helpers/enums/time.part.enum";

export const reducer: Reducer<StatisticsState> = (state: StatisticsState, action: StatisticsKnownActions) => {
    switch (action.type) {
        case statisticsActionTypes.GET_FINANCE_STATS:
            return Object.assign(state, {
                Fetching: true
            } as StatisticsState);

        case statisticsActionTypes.GET_CLIENT_STATS:
            return Object.assign(state, {
                Fetching: true
            } as StatisticsState);

        case statisticsActionTypes.SET_STATS:
            return Object.assign(state, {
                Fetching: false,
                StatisticsEntity: action.payload
            } as StatisticsState);

        case statisticsActionTypes.SET_OPTIONS:
            return Object.assign(state, {
                TypesList: action.payload
            } as StatisticsState);

        case statisticsActionTypes.SET_PARTNERS_FOR_FILTER:
            return Object.assign(state, {
                PartnerList: action.payload
            } as StatisticsState);

        case statisticsActionTypes.CHANGE_PARTNER:
            return Object.assign(state, {
                SelectedPartner: action.payload
            } as StatisticsState);

        case statisticsActionTypes.CHANGE_START_DATE:
            return Object.assign(state, {
                StartDate: action.payload
            } as StatisticsState);

        case statisticsActionTypes.CHANGE_END_DATE:
            return Object.assign(state, {
                EndDate: action.payload
            } as StatisticsState);

        case statisticsActionTypes.CHANGE_STAT:
            return Object.assign(state, {
                SelectedPartner: null,
                SelectedType: action.payload,
                StatisticsEntity: null,
            } as StatisticsState);

        case statisticsActionTypes.CHANGE_DIAGRAMM_MODE:
            return Object.assign(state, {
                DiagrammMode: action.payload
            } as StatisticsState);

        case statisticsActionTypes.ADD_PARTNER_FOR_DIAGRAM:
            return Object.assign(state, {
                SelectedPartnerList: Object.assign([], [...state.SelectedPartnerList, action.payload ])
            } as StatisticsState);    

        case statisticsActionTypes.REMOVE_PARTNER_FROM_LIST:
            return Object.assign(state, {
                SelectedPartnerList: Object.assign([], state.SelectedPartnerList.filter((value, index) =>  index !== action.payload)),
                PartnerList: Object.assign([], state.PartnerList)
            } as StatisticsState);

        case statisticsActionTypes.SELECT_PARTNER_FOR_LIST:
            return Object.assign(state, {
                PartnerForList: action.payload 
            } as StatisticsState);

        case statisticsActionTypes.SET_DIAGRAMM_OPTIONS:
            return Object.assign(state, {
                DiagramTypes: action.payload 
            } as StatisticsState);

        case statisticsActionTypes.SELECT_DIAGRAMM_OPTION:
            return Object.assign(state, {
                SelectedDiagramType: action.payload,
                StatsList: action.payload.subOptions,
                SelectedDiagrammStat: action.payload.subOptions[0]
            } as StatisticsState);

        case statisticsActionTypes.SELECT_DIAGRAMM_STAT_OPTION:
            return Object.assign(state, {
                SelectedDiagrammStat: action.payload 
            } as StatisticsState);

        case statisticsActionTypes.ADD_STAT_FOR_LIST:
            return Object.assign(state, {
                SelectedStatsList: Object.assign([], [...state.SelectedStatsList, action.payload ])
            } as StatisticsState);    

        case statisticsActionTypes.REMOVE_STAT_FROM_LIST:
            return Object.assign(state, {
                SelectedStatsList: Object.assign([], state.SelectedStatsList.filter((value, index) =>  index !== action.payload)),
                StatsList: Object.assign([], state.SelectedDiagramType.subOptions)
            } as StatisticsState);    

        case statisticsActionTypes.CHANGE_TIMEPART:
            return Object.assign(state, {
                SelectedTimePart: action.payload
            } as StatisticsState);    

        case statisticsActionTypes.GET_FINANCES_DIAGRAM:
            return Object.assign(state, {
                Fetching: true
            } as StatisticsState);   

        case statisticsActionTypes.SET_DIAGRAM:
            return Object.assign(state, {
                DiagramData: action.payload.value,
                Fetching: false
            } as StatisticsState);    

        case statisticsActionTypes.CHANGE_DIAGRAM_START_DATE:
            return Object.assign(state, {
                DiagramStartDate: action.payload
            } as StatisticsState);   

        case statisticsActionTypes.CHANGE_DIAGRAM_END_DATE:
            return Object.assign(state, {
                DiagramEndDate: action.payload
            } as StatisticsState);   

        case statisticsActionTypes.RESET_DIAGRAMS: 
            return Object.assign(state, {
                DiagramData: [],
                DiagramStartDate: null,
                DiagramEndDate: null,
                SelectedPartnerList: [],
                PartnerList: Object.assign([], state.PartnerList),
                SelectedStatsList: [],
                PartnerForList: [],
                SelectedDiagramType: GetStatisticsTypesForDiagrams()[0],
                SelectedDiagrammStat: GetStatisticsTypesForDiagrams()[0].subOptions[0],
                StatsList: GetStatisticsTypesForDiagrams()[0].subOptions,
            } as StatisticsState);

        case statisticsActionTypes.RESET_PARTNER_DIAGRAMS: 
            return Object.assign(state, {
                DiagramData: [],
                DiagramStartDate: null,
                DiagramEndDate: null,
                SelectedPartnerList: [],
                PartnerList: Object.assign([], state.PartnerList),
                SelectedStatsList: [],
                PartnerForList: [],
                SelectedDiagramType: GetPartnerStatisticsTypesForDiagrams()[0],
                SelectedDiagrammStat: GetPartnerStatisticsTypesForDiagrams()[0].subOptions[0],
                StatsList: GetPartnerStatisticsTypesForDiagrams()[0].subOptions,
            } as StatisticsState);

        case statisticsActionTypes.UNMUONT:
            return Object.assign({},{
                Fetching: false,
                StatisticsEntity: null,
                SelectedType: GetStatisticsTypesForSelectForCoordinator()[0],
                TypesList: GetStatisticsTypesForSelectForCoordinator(),
                SelectedPartner: null,
                PartnerList: [],
                StartDate: null,
                EndDate: null,
                DiagrammMode: false,
                DiagramTypes: GetStatisticsTypesForDiagrams(),
                SelectedDiagramType: GetStatisticsTypesForDiagrams()[0],
                SelectedDiagrammStat: GetStatisticsTypesForDiagrams()[0].subOptions[0],
                StatsList: GetStatisticsTypesForDiagrams()[0].subOptions,
                SelectedPartnerList: [],
                SelectedStatsList: [],
                PartnerForList: null,
                TimeParts: GetTimePartArray,
                SelectedTimePart: GetTimePartArray[2],
                DiagramData: [],
                DiagramStartDate: null,
                DiagramEndDate: null
            });

        default:
            const exhaustiveCheck: any = action;
            return state || DefaultStatisticsState;
    }
} 