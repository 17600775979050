import { SelectModel } from "../../helpers/select.model";

export const GET_AUTOCOMPLETE_PARTNER_RESULT = '[API] GET_AUTOCOMPLETE_PARTNER_RESULT';
export interface IGetAutocompletePartnerResult { type: '[API] GET_AUTOCOMPLETE_PARTNER_RESULT', payload: any }
export const GetAutocompletePartnerResult = (value: string) =>
  <IGetAutocompletePartnerResult>{ type: GET_AUTOCOMPLETE_PARTNER_RESULT, payload: value };


export const SET_AUTOCOMPLETE_PARTNER_RESULT = 'SET_AUTOCOMPLETE_PARTNER_RESULT';
export interface ISetAutocompletePartnerResult { type: 'SET_AUTOCOMPLETE_PARTNER_RESULT', payload: any }
export const SetAutocompletePartnerResult = (partners: SelectModel[]) =>
  <ISetAutocompletePartnerResult>{ type: SET_AUTOCOMPLETE_PARTNER_RESULT, payload: partners };


export const CHANGE_PARTNER_VALUE = 'CHANGE_PARTNER_VALUE';
export interface IChangePartnerValue { type: 'CHANGE_PARTNER_VALUE', payload: any }
export const ChangePartnerValue = (partner: SelectModel) =>
  <IChangePartnerValue>{ type: CHANGE_PARTNER_VALUE, payload: partner };


export const GET_CLIENTS_FOR_SELECTED_PARTNER = '[API] GET_CLIENTS_FOR_SELECTED_PARTNER';
export interface IGetClientsForSelectedPartner { type: '[API] GET_CLIENTS_FOR_SELECTED_PARTNER', payload: any }
export const GetClientsForSelectedPartner = (filteredData: string) =>
  <IGetClientsForSelectedPartner>{ type: GET_CLIENTS_FOR_SELECTED_PARTNER, payload: filteredData };


export const SET_CLIENTS_RESULT = 'SET_CLIENTS_RESULT';
export interface ISetClientsResult { type: 'SET_CLIENTS_RESULT', payload: any }
export const SetClientsResult = (clientData: any) =>
  <ISetClientsResult>{ type: SET_CLIENTS_RESULT, payload: clientData };


export const RESET_COMMAND = 'RESET_COMMAND';
export interface IResetCommand { type: 'RESET_COMMAND', payload?: any }
export const ResetCommand = () =>
  <IResetCommand>{ type: RESET_COMMAND };


  export const UNMOUNT_CLIENT_BASE_COMPONENT = 'UNMOUNT_CLIENT_BASE_COMPONENT';
export interface IUnmountClientBaseComponent { type: 'UNMOUNT_CLIENT_BASE_COMPONENT', payload?: any }
export const UnmountClientBaseComponent = () =>
  <IUnmountClientBaseComponent>{ type: UNMOUNT_CLIENT_BASE_COMPONENT };



export type ClientBaseKnownActions =
  IUnmountClientBaseComponent |
  IResetCommand |
  ISetClientsResult |
  IGetClientsForSelectedPartner |
  IChangePartnerValue |
  ISetAutocompletePartnerResult |
  IGetAutocompletePartnerResult;