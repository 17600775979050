import { Promocode } from "../../business.entities/promocode";
import { PromocodeFilterEntity } from "../../helpers/promocode.filter.entity";

export class PromocodeState {
    public promocodes: Promocode[];
    public totalCount: number;
    public promocodeNameFilter: any;
    public promocodeStatusFilter: any;
    public promocodeUsedDateFilter: any;
    public promocodeShopAddressFilter: any;
    public promocodeTypeFilter: any;
    public promocodePriceFilter: any;
    public promocodeNameAutocompleteOptions: any[];
    public promocodeStatusAutocompleteOptions: any[];
    public promocodeAddressAutocompleteOptions: any[];
    public promocodeTypeAutocompleteOptions: any[];
    public fileWithPromocodes: any;
    public fileWithPromocodesToSend: any;
    public fileToSendName: string;
    public fileToExportName: string;
}

export const defaultPromocodeState: PromocodeState = {
    promocodes: [],
    totalCount: 0,
    promocodeNameFilter: null,
    promocodeStatusFilter: null,
    promocodeUsedDateFilter: null,
    promocodeShopAddressFilter: null,
    promocodeTypeFilter: null,
    promocodePriceFilter: "",
    promocodeNameAutocompleteOptions: [],
    promocodeStatusAutocompleteOptions: [],
    promocodeAddressAutocompleteOptions: [],
    promocodeTypeAutocompleteOptions: [],
    fileWithPromocodes: null,
    fileWithPromocodesToSend: null,
    fileToSendName: "",
    fileToExportName: ""
}