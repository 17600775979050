import * as React from 'react';
import Product from '../../business.entities/product';
import { ComponentClass } from 'react';
import { default as OrderProductStep } from '../../components/dashboard/sales/create.order/order.product.step';
import { Order } from '../../business.entities/order';
import { OrderProduct } from '../../business.entities/order.product';
import { OrderProductStep as OrderProductStepEnum } from '../../helpers/enums/order.product.steps';
import { Color } from '../../business.entities/color';
import { Client } from '../../business.entities/client';
import { DeliveryInfo } from '../../helpers/deliveryInfo.entity';
import { OrderStatusEnum, GetNameForOrderStatus } from '../../helpers/enums/order.status.enum';

const finishStatus = [
    {
        name: GetNameForOrderStatus(OrderStatusEnum.Confirmed),
        isSelected: false,
        id: OrderStatusEnum.Confirmed
    },
    {
        name: GetNameForOrderStatus(OrderStatusEnum.Waiting),
        isSelected: false,
        id: OrderStatusEnum.Waiting
    },
    {
        name: GetNameForOrderStatus(OrderStatusEnum.NotAvailableWaiting),
        isSelected: false,
        id: OrderStatusEnum.NotAvailableWaiting
    },
    {
        name: GetNameForOrderStatus(OrderStatusEnum.NotAvailableNotWaiting),
        isSelected: false,
        id: OrderStatusEnum.NotAvailableNotWaiting
    },
    {
        name: GetNameForOrderStatus(OrderStatusEnum.Cancel),
        isSelected: false,
        id: OrderStatusEnum.Cancel
    },
    {
        name: 'Завершить без сохранения',
        isSelected: false,
        id: 0
    }
]

export class WizardState {
    opotionCategoryTree: any[];
    fetching: boolean;
    isApproved: boolean;
    step: ComponentClass;
    stepNumber: number;
    selectedCategory: any;
    selectedSubCategory: any;
    categoryList: any[];
    subCategoryList: any[];
    productList: Product[];
    order: Order;
    categorySelectRef: any;
    subcategorySelectRef: any;
    currentProduct: Product;
    currentProductColor: Color;
    currentOrderProduct: OrderProduct;
    currentExpressPrintOrderProduct: OrderProduct;
    currentExtraPrintOrderProduct: OrderProduct;
    additionalServiceProducts: Product[];
    expressPrintProducts: Product[];
    extraPrintProducts: Product[];
    selectedExpressPrintProduct: Product;
    selectedAdditionalPrintProduct: Product;
    orderProductSubStep: ComponentClass;
    orderProductStepPattern: any[];
    currentStepEnum: OrderProductStepEnum;
    baseProductList: Product[];
    currentBaseProduct: Product;
    currentBaseColor: Color;
    productSelectionMode: boolean;
    orderProductIndex: number;
    bufferClient: Client;
    newClientMode: boolean;
    clients: Client[];
    selectedClient: any;
    infoStep: number;
    clientSelectComponentRef: any;
    clientPhoneInputRef: any;
    isRecipient: boolean;
    isFinishMenuOpen: boolean;
    serverActionInProcess: boolean;

    cities: any;
    warehouses: any;
    selectedCity: any;
    selectedWarehouse: any;
    tempOrderChecked: boolean;

    finishStatus: any[];

    isValidated: boolean;
    deliveryPayment: any;
    sendDate: Date;
    deliveryDate: Date;
    proccessingOrderState: boolean;

}

export const defaultWizardState: WizardState = {
    opotionCategoryTree: [],
    fetching: false,
    isApproved: false,
    step: OrderProductStep,
    stepNumber: 1,
    selectedCategory: null,
    selectedSubCategory: null,
    categoryList: [],
    subCategoryList: [],
    productList: [],
    order: new Order(),
    categorySelectRef: React.createRef<HTMLDivElement>(),
    subcategorySelectRef: React.createRef<HTMLDivElement>(),
    currentProduct: null,
    currentProductColor: null,
    currentOrderProduct: new OrderProduct(),
    currentExpressPrintOrderProduct: new OrderProduct(),
    currentExtraPrintOrderProduct: new OrderProduct(),
    additionalServiceProducts: [],
    expressPrintProducts: [],
    extraPrintProducts: [],
    selectedAdditionalPrintProduct: null,
    selectedExpressPrintProduct: null,
    orderProductSubStep: null,
    orderProductStepPattern: [],
    currentStepEnum: null,
    baseProductList: [],
    currentBaseProduct: null,
    currentBaseColor: null,
    productSelectionMode: true,
    orderProductIndex: null,
    bufferClient: new Client(),
    clients: [],
    selectedClient: null,
    infoStep: 1,
    newClientMode: false,
    clientSelectComponentRef: React.createRef<HTMLDivElement>(),
    clientPhoneInputRef: React.createRef<HTMLDivElement>(),
    isRecipient: false,
    isFinishMenuOpen: false,
    serverActionInProcess: false,
    cities: [],
    warehouses: [],
    selectedCity: null,
    selectedWarehouse: null,
    tempOrderChecked: false,
    finishStatus: finishStatus,
    isValidated: false,
    deliveryPayment: null,
    sendDate: null,
    deliveryDate: null,
    proccessingOrderState: false,
};


