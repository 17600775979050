import * as LexiconActions from "../../../../actions/stock/lexicon.actions";
import { Lexicon } from "../../../../business.entities/lexicon";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { actions } from "react-redux-form";
import * as rightViewActions from '../../../../actions/right.view.actions'
import { RightViewState } from "../../../../states/right.view.state";
import { Loader } from "../../../shared/loader";
import LexiconForm from "./lexicon.form"
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import RCSelect from 'rc-select';
import { LeftMenuMobileControl } from "../../../shared/leftMenu.control";

export namespace LexiconComponent {
    export interface IProps {
        Token: string;
        SelectLexicon: any;
        ErrorNotification: any;
        SelectedLexicon: Lexicon;
        Take: number;
        Page: number;
        TotalPages: number;
        GetLexicons: any;
        Lexicons: Lexicon[];
        UpdateForm: any;
        LexiconForm: any;
        DeselectLexicon: any;
        OpenRv: (rvData: RightViewState) => any;
        CloseRv: () => any;
        UnmountComponent: () => any;
        Fetching: boolean;
    }

    export interface IState {
        page: number;
        count: number;
        searchValue: string;
    }
}

const mapStateToProps = (state) => {
    return {
        Token: state.authentication.AuthenticationToken,
        SelectedLexicon: state.stock.lexicon.SelectedLexicon,
        Take: state.stock.lexicon.take,
        TotalPages: state.stock.lexicon.totalPages,
        Lexicons: state.stock.lexicon.lexicons,
        Fetching: state.stock.lexicon.fetching,
        LexiconForm: state.lexiconForm
    };
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        SelectLexicon: LexiconActions.SelectLexicon,
        GetLexicons: LexiconActions.GetLexiconsFilteredPaginated,
        UpdateForm: actions.change,
        DeselectLexicon: LexiconActions.DeselectLexicon,
        OpenRv: rightViewActions.SelectRVData,
        CloseRv: rightViewActions.RemoveRVData,
        UnmountComponent: LexiconActions.UnmountLexicon
    }, dispatch)
};

class LexiconComponent extends React.Component<LexiconComponent.IProps, LexiconComponent.IState> {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            count: 25,
            searchValue: ''
        }

        this.OnSelectLexiconCommand = this.OnSelectLexiconCommand.bind(this);

        this.props.GetLexicons(this.state.count, this.state.page, this.state.searchValue);
    }

    componentWillUnmount() {
        this.props.UnmountComponent();
    }

    public OnSelectLexiconCommand(lexicon: Lexicon) {
        this.props.SelectLexicon(lexicon);

        let rvState = new RightViewState();
        rvState.Title = "Лексикон";
        rvState.Component = LexiconForm;
        rvState.CloseFunction = this.onDeselectLexicon.bind(this);
        this.props.OpenRv(rvState);
    }

    public onDeselectLexicon(): void {
        this.props.CloseRv();
        this.props.DeselectLexicon();
    }

    private OnChangeSearchCommand(value) {
        this.setState({ searchValue: value, page: 1 })
        this.props.GetLexicons(this.state.count, 1, value);
    }

    public OnChangePageCommand(current, pageSize): void {

        if (!this.props.Fetching) {
            this.setState(() => ({
                page: current
            }));

            this.props.GetLexicons(
                this.state.count,
                current,
                this.state.searchValue);
        }
    }

    public OnChangePageSizeCommand(current, pageSize): void {

        if (!this.props.Fetching) {
            this.setState(() => ({
                page: 1,
                count: pageSize
            }));

            this.props.GetLexicons(
                pageSize,
                1,
                this.state.searchValue);
        }
    }

    render() {
        return (
            <div className="master_content_component rcPagination__component">
                <LeftMenuMobileControl />
                <div className="left_menu_component">
                    <div className="left_menu_SCROLL">
                        <div className="page_controls">
                            <div className="defaultFormControl">
                                <div className="defaultFormControl__label">
                                    Поиск
                            </div>
                                <div className="defaultFormControl__value notPadd">
                                    <input
                                        type="text"
                                        placeholder="Поиск"
                                        value={this.state.searchValue}
                                        onChange={(event) => this.OnChangeSearchCommand(event.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="defaultFormControl">
                                <div className="defaultFormButton" onClick={(event) => this.OnChangeSearchCommand('')}>
                                    Очистить
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content_header_component">
                    <div className="page_title">
                        <span className="title_default">Лексиконы</span>
                        <span className="title_description">Формирование лексиконов</span>
                    </div>
                </div>

                <div className="content_component">
                    <div className="grid_component">
                        <div className="grid_container">
                            <div className="grid_header">
                                <div className="grid_header_wrapper">
                                    <div className="grid_row row_header">
                                        <div className="grid_cell">
                                            <span className="cell_content">
                                                Оф. партнерское название
                                            </span>
                                        </div>
                                        <div className="grid_cell lexicons__lexicon_name_cell">
                                            <span className="cell_content">
                                                Лексиконное название
                                            </span>
                                        </div>
                                        <div className="grid_cell lexicons__type_product_cell">
                                            <span className="cell_content">
                                                Тип продукта
                                            </span>
                                        </div>
                                        <div className="grid_cell lexicons__type_product_cell">
                                            <span className="cell_content">
                                                Порядок
                                            </span>
                                        </div>
                                        <div className="grid_cell lexicons__type_product_cell">
                                            <span className="cell_content">
                                                Баллов в распределении
                                            </span>
                                        </div>
                                        <div className="grid_cell lexicons__lexicon_name_cell">
                                            <span className="cell_content">
                                                Дополнительные услуги
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                this.props.Lexicons.length > 0 ?
                                    <div className="grid_scroll_content_component">
                                        <div className="grid_wrapper_component">
                                            {this.props.Lexicons.length > 0 ?
                                                this.props.Lexicons.map((lexicon, index) => {
                                                    return (
                                                        <div key={index} className="grid_row mMEDIA___lexicon_ROW" onClick={(event) =>
                                                            this.OnSelectLexiconCommand(lexicon)}>
                                                            <div className="grid_cell lexicons__NAMEPROD__CELL" data-tooltip="Оф. партнерское название">
                                                                <span className="cell_content">
                                                                    {lexicon.OfficialPartnerName}
                                                                </span>
                                                            </div>
                                                            <div className="grid_cell lexicons__lexicon_name_cell" data-tooltip="Лексиконное название">
                                                                <span className="cell_content">
                                                                    {lexicon.CasualName}
                                                                </span>
                                                            </div>
                                                            <div className="grid_cell lexicons__type_product_cell lexicons__TYPEPROD__CELL" data-tooltip="Тип продукта">
                                                                <span className="cell_content">
                                                                    {lexicon.ProductType.Name}
                                                                </span>
                                                            </div>
                                                            <div className="grid_cell lexicons__type_product_cell" data-tooltip="Порядок">
                                                                <span className="cell_content">
                                                                    {lexicon.PartnerPriority}
                                                                </span>
                                                            </div>
                                                            <div className="grid_cell lexicons__type_product_cell" data-tooltip="Баллов в распределении">
                                                                <span className="cell_content">
                                                                    {lexicon.DistributionPoints}
                                                                </span>
                                                            </div>
                                                            <div className="grid_cell lexicons__lexicon_name_cell" data-tooltip="Дополнительные услуги">
                                                                <span className="cell_content">
                                                                    {lexicon.CanHaveExpressDelievery && "Экспресс / "}
                                                                    {lexicon.CanHaveExtraLacquer && "Доп. печать лака / "}
                                                                    {lexicon.CanHave3dPrint && "3Д / "}
                                                                    {lexicon.CanHaveOnlyLacquer && "Только лак"}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Loader />
                            }
                        </div>
                    </div>
                    <Pagination
                        locale={Locale}
                        selectComponentClass={RCSelect}
                        showSizeChanger
                        pageSizeOptions={['25', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.count}
                        defaultCurrent={this.state.page}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.TotalPages}
                    />
                </div>
            </div>
        );
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps)
    (LexiconComponent);

