import { Reducer } from "redux";
import * as partnerGridActions from "../../actions/partner/partner.grid.actions";
import { PartnerGridState, defaultPartnerGridState } from "../../states/partner/partner.grid.state";

export const reducer: Reducer<any> = (state: PartnerGridState,
    action: partnerGridActions.PartnerGridKnownActions) => {
    switch (action.type) {
        case partnerGridActions.FILTER_CHANGE:
            return { ...state };
        case partnerGridActions.GET_PARTNERS_FILTERED_PAGINATED:
            return {
                ...state,
                isPageChange: true,
                fetching: true
            };
        case partnerGridActions.GET_WORKERS_PAGINATED:
            return {
                ...state,
                isPageChange: true,
                fetching: true
            };
        case partnerGridActions.GET_PARTNERS_SUCCESS:
            return {
                ...state,
                partners: action.payload.Collection,
                totalPages: action.payload.TotalPagesCount,
                isPageChange: false,
                fetching: false
            };
        case partnerGridActions.GET_PARTNERS_FAILED:
            return {
                ...state, isPageChange: false,
                fetching: false
            };

        case partnerGridActions.CANCEL_PARTNER_MODE: 
            return Object.assign({}, state, {
                cancelMode: action.payload
            });

        case partnerGridActions.CANCEL_PARTNER: 
            return Object.assign({}, state, {
                fetching: true
            });  

        case partnerGridActions.CANCEL_PARTNER_SUCCESS: 
            return Object.assign({}, state, {
                cancelMode: false,
                fetching: false,
                partners: action.payload
            });   

        case partnerGridActions.UNMOUNT_PARTNER_GRID:
            return { ...defaultPartnerGridState };
        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultPartnerGridState;
}