import { BaseCaseshopEntity } from "./base.caseshop.entity";
import { ProductType } from "./product.type";

export class Lexicon extends BaseCaseshopEntity {
    public DesignsQuantity: number = 0;

    public OfficialPartnerName: string = "";
    public OfficialName: string = "";
    public CasualName: string = "";
    public PurchaseName: string = "";
    public VendorName: string = "";
    public ProductTypeId: number = 0;
    public VendorPrice: number = 0;

    public HasColors: boolean = false;
    public HasOptions: boolean = false;
    public HasQuantity: boolean = false;
    public HasDesigns: boolean = false;
    public HasComment: boolean = false;
    public CanBeBase: boolean = false;
    public CanHaveUserImg: boolean = false;
    public HasAdditionalOptions: boolean = false;
    
    public IsRemoved: boolean = false;
    public IsForPartners: boolean = false;
    public Priority: number = 0;
    public PartnerPriority: number = 0;

    public DistributionPoints: number = 0;
    public CanHaveExpressDelievery: boolean = false;
    public CanHaveExtraLacquer: boolean = false;
    public CanHave3dPrint: boolean = false;
    public CanHaveOnlyLacquer: boolean = false;

    public ProductType: ProductType;
}

export default Lexicon;
