export class RightViewState {
    public Component: any;
    public CloseFunction: any;
    public Title: string;
    public Props: any;
    public ViewSize:string;
    public CustomFunctions?: any;
    public HoverData: any;
    public DisplayedProductImage: any;
}

export const defaultRightViewState: RightViewState = {
    Component: null,
    CloseFunction: null,
    Title: null,
    ViewSize: null,
    Props: null||undefined,
    CustomFunctions: null,
    HoverData: null,
    DisplayedProductImage: null
}