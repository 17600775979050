import { getCookie } from "./cookies";
import { TokenHelper } from "./jwt.parser";
import { LoginSuccess, GetUserInfo } from "../actions/login.actions";
import { push } from "react-router-redux";
import { MenuItem } from "../states/manu.item.state";
import { List } from "linqts";
import { Store } from "redux";

export function AppInit(store: Store<any>) {
    const token: string = getCookie("token");
    const refresh: string = getCookie("refresh");

    if (token && refresh) {
        const parsedToken = TokenHelper.parseJwt(token);
        store.dispatch(LoginSuccess(token, refresh, parsedToken.UserName, parsedToken.role, parsedToken.NetId, parsedToken.exp * 1000));
        store.dispatch(GetUserInfo(parsedToken.NetId));
    }
}