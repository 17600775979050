import * as React from "react";
import { List } from "linqts";
import { NavLink } from "react-router-dom";
import { MenuModel } from "../../../../helpers/menu.model";

export namespace Header {
    export interface IProps {
        history: any;
        MenuItems: MenuModel[];
        logoutCommand: () => void;
        role: string;
        user: string;
    }
}

export const Header = (props: Header.IProps) => {
    const currentParent: MenuModel = new List(props.MenuItems)
        .Where(c => props.history.location.pathname.includes(c.Route))
        .FirstOrDefault();





    return (
        <>
            <label className="headerMENU_MCONTROL" for="cMenuID">
                <div />
                <div />
                <div />
            </label>
            <input className="headerMENU_MCONTROL" id="cMenuID" type="checkbox" />

            <div className="header_component">
                <div className="header_wrapper_component">
                    <div className="header_top_row_component">
                        <div className="header_menu_component">
                            {
                                props.MenuItems.map((menuItem, key) => {
                                    if (menuItem.Roles.indexOf(props.role) !== -1) {
                                        return (
                                            <div key={key} className="menu_list_item">
                                                <NavLink to={"/" + menuItem.Route}
                                                    onClick={(event) => {
                                                        if (menuItem.ChildMenuItems.length === 0) {
                                                            let element = document.getElementById('cMenuID');
                                                            element.checked = !element.checked;
                                                        }
                                                    }}
                                                    activeClassName='active'>
                                                    {menuItem.Name}
                                                </NavLink>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="header_logo"></div>
                    </div>

                    <div className="header_bottom_row_component">
                        <div className="header_submenu_component">
                            {currentParent ?
                                currentParent.ChildMenuItems.map((child, key) => {
                                    if (child.IsSubRoute && child.Roles.indexOf(props.role) !== -1)
                                        return (
                                            <div key={key} className="submenu_list_item">
                                                <NavLink to={"/" + child.Route}
                                                    onClick={(event) => {
                                                        let element = document.getElementById('cMenuID');
                                                        element.checked = !element.checked;
                                                    }}
                                                    activeClassName='active'>
                                                    {child.Name}
                                                </NavLink>
                                            </div>
                                        )
                                }) : null
                            }
                        </div>

                        <div className="user_profile_component">
                            <div className="user_name">{props.user}</div>
                            <div className="user_logout" onClick={(event) => (props.logoutCommand())}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}