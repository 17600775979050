import * as orderBaseActions from "../../actions/sales/order.base.actions";
import { OrderBaseState, defaultOrderBaseState } from "../../states/sales/order.base.state";
import { Reducer } from "redux";
import { stat } from "fs";
import { Order } from "../../business.entities/order";
import { PrintStatus } from "../../business.entities/print.status";
import { OrderStatus } from "../../business.entities/order.status";
import { OrderStatusEnum } from "../../helpers/enums/order.status.enum";

export const reducer: Reducer<OrderBaseState> = (state: OrderBaseState, action: orderBaseActions.OrderBaseKnownActions) => {
    switch (action.type) {
        case orderBaseActions.GET_ALL_ORDERS:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.GET_ORDERS_FOR_ASSEMBLING:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.GET_ORDERS_FOR_DECLARATIONS:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.SEND_ORDERS_FOR_ASSEMBLE:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.CREATE_DECLARATIONS_DEFAULT:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.CREATE_DECLARATIONS_MULTI:
            return { ...state, fetchInProgress: true };
        case orderBaseActions.SET_ALL_ORDERS:
            return { ...state, fetchInProgress: false, orders: action.payload.Item1, ordersCount: action.payload.Item2 };
        case orderBaseActions.SELECT_ORDER_TYPE_FILTER:
            return { ...state, selectedOrderType: action.payload }
        case orderBaseActions.SET_ORDER_STATUS_LIST_FILTER:
            return { ...state, orderStatusList: action.payload }
        case orderBaseActions.SET_PRINT_STATUS_LIST_FILTER:
            return { ...state, printStatusList: action.payload }
        case orderBaseActions.SET_PROD_STATUS_LIST_FILTER:
            return { ...state, productionStatusList: action.payload }
        case orderBaseActions.SET_LASER_STATUS_LIST_FILTER:
            return { ...state, laserStatusList: action.payload }
        case orderBaseActions.SELECT_ORDER_STATUS_FILTER:
            return { ...state, selectedOrderStatus: action.payload }
        case orderBaseActions.SET_CLIENTS_FOR_FILTER:
            return { ...state, clientList: action.payload }
        case orderBaseActions.SELECT_CLIENTS_FOR_FILTER:
            return { ...state, selectedClient: action.payload }
        case orderBaseActions.SELECT_DELIVERY_TYPE_FOR_FILTER:
            return { ...state, selectedDeliveryType: action.payload }
        case orderBaseActions.SELECT_PAYMENT_METHOD_FOR_FILTER:
            return { ...state, selectedPaymentMethod: action.payload }
        case orderBaseActions.CHANGE_ORDER_CODE_FOR_FILTER:
            return { ...state, orderCode: action.payload }
        case orderBaseActions.GET_ORDER_PRODUCTS_FOR_ORDER:
            return { ...state, fetchInProgress: true }
        case orderBaseActions.SET_ORDER_PRODUCTS_FOR_ORDER:
            return { ...state, currentOrderProducts: action.payload, fetchInProgress: false }
        case orderBaseActions.GET_ALL_STATUSES_FOR_ORDER:
            return { ...state, fetchInProgress: true }
        case orderBaseActions.SET_ALL_STATUSES_FOR_ORDER:
            return { ...state, currentOrderStatuses: action.payload, fetchInProgress: false }
        case orderBaseActions.SELECT_ALL_ORDERES:
            return { ...state, orders: action.payload }
        case orderBaseActions.RESET_ORDER_BASE_FILTER:
            return Object.assign({},
                state, {
                    selectedClient: defaultOrderBaseState.selectedClient,
                    selectedOrderStatus: defaultOrderBaseState.selectedOrderStatus,
                    selectedDeliveryType: defaultOrderBaseState.selectedDeliveryType,
                    selectedOrderType: defaultOrderBaseState.selectedOrderType,
                    selectedPaymentMethod: defaultOrderBaseState.selectedPaymentMethod,
                    selectedPrintStatus: defaultOrderBaseState.selectedPrintStatus,
                    selectedProductionStatus: defaultOrderBaseState.selectedProductionStatus,
                    selectedStockStatus: defaultOrderBaseState.selectedStockStatus,
                    filter: defaultOrderBaseState.filter,
                    orderCode: defaultOrderBaseState.orderCode,
                    filterStartDate: defaultOrderBaseState.filterStartDate,
                    filterEndDate: defaultOrderBaseState.filterEndDate,
                    filterAssemblingDate: defaultOrderBaseState.filterAssemblingDate,
                    TTNCreateDateFilter: defaultOrderBaseState.TTNCreateDateFilter,
                    selectedPartner: defaultOrderBaseState.selectedPartner,
                    selectedModel: defaultOrderBaseState.selectedModel
                } as OrderBaseState);
        case orderBaseActions.SELECT_ORDER:
            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [action.payload.index]: Object.assign({},
                        state.orders[action.payload.index],
                        {
                            IsSelected: action.payload.selected
                        })
                })
            } as OrderBaseState);
        case orderBaseActions.SET_CURRENT_ORDER_INDEX:
            return { ...state, currentOrderIndex: action.payload }
        case orderBaseActions.CHANGE_PAYMENT_STATUS_FOR_ORDER:
            return { ...state, selectedPaymentStatusForOrder: action.payload }
        case orderBaseActions.CHANGE_PAYMENT_DATE:
            return { ...state, selectedPaymentDate: action.payload }
        case orderBaseActions.SET_PRODUCT_AVAILABILITY:
            //debugger
            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [state.currentOrderIndex]: Object.assign({},
                        state.orders[state.currentOrderIndex],
                        {
                            StockStatus: Object.assign([],
                                state.orders[state.currentOrderIndex].StockStatus,
                                {
                                    [0]: action.payload.stockstatus
                                }),
                            PrintStatus: Object.assign([],
                                state.orders[state.currentOrderIndex].PrintStatus,
                                {
                                    [0]: action.payload.printStatus ? action.payload.printStatus : state.orders[state.currentOrderIndex].PrintStatus[0]
                                }),
                            ProductionStatus: Object.assign([],
                                state.orders[state.currentOrderIndex].ProductionStatus,
                                {
                                    [0]: action.payload.productionStatus ? action.payload.productionStatus : state.orders[state.currentOrderIndex].ProductionStatus[0]
                                }),
                            LaserStatus: Object.assign([],
                                state.orders[state.currentOrderIndex].LaserStatus,
                                {
                                    [0]: action.payload.laserStatus ? action.payload.laserStatus : state.orders[state.currentOrderIndex].LaserStatus[0]
                                }),
                        })
                }),
                currentOrderProducts: Object.assign([], state.currentOrderProducts, {
                    [action.payload.productIndex]: Object.assign({},
                        state.currentOrderProducts[action.payload.productIndex],
                        {
                            IsAvailable: action.payload.isAvailable
                        })
                })
            });

        case orderBaseActions.SET_PRODUCT_AVAILABILITY_FROM_PAGE_RV:

            let selectedOrderId = state.orders.findIndex(order => order.NetUid == action.payload.orderNetId);

            let selectedRvOrderId = state.orderProductsForPage.findIndex(order => order.OrderId == action.payload.orderNetId);
            let selectedRvOrderProductId = action.payload.orderProductIndex;

            let currentOrderProductForOrderOnPageState = state.orderProductsForPage[selectedRvOrderId].OrderProducts;
            
            let orderProductWithChangedAvailability = Object.assign({}, 
                state.orderProductsForPage[selectedRvOrderId].OrderProducts[selectedRvOrderProductId],
                {
                    IsAvailable: action.payload.isAvailable
                });

            let orderProductForOrderOnPageState = Object.assign([], currentOrderProductForOrderOnPageState);

            orderProductForOrderOnPageState.splice(selectedRvOrderProductId, 1);

            orderProductForOrderOnPageState.splice(selectedRvOrderProductId, 0, orderProductWithChangedAvailability)

            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [selectedOrderId]: Object.assign({},
                        state.orders[selectedOrderId],
                        {
                            StockStatus: Object.assign([],
                                state.orders[selectedOrderId].StockStatus,
                                {
                                    [0]: action.payload.stockstatus
                                }),
                            PrintStatus: Object.assign([],
                                state.orders[selectedOrderId].PrintStatus,
                                {
                                    [0]: action.payload.printStatus ? action.payload.printStatus : state.orders[selectedOrderId].PrintStatus[0]
                                }),
                            ProductionStatus: Object.assign([],
                                state.orders[selectedOrderId].ProductionStatus,
                                {
                                    [0]: action.payload.productionStatus ? action.payload.productionStatus : state.orders[selectedOrderId].ProductionStatus[0]
                                }),
                            LaserStatus: Object.assign([],
                                state.orders[selectedOrderId].LaserStatus,
                                {
                                    [0]: action.payload.laserStatus ? action.payload.laserStatus : state.orders[selectedOrderId].LaserStatus[0]
                                }),
                        })
                }),

                orderProductsForPage: Object.assign([], state.orderProductsForPage, {
                    [selectedRvOrderId]: Object.assign({},
                        state.orderProductsForPage[selectedRvOrderId],
                        {
                            OrderProducts: orderProductForOrderOnPageState
                        })
                })
            });
        
        case orderBaseActions.UPDATE_ORDER_PRODUCT:
            return Object.assign({}, state, {
                fetchInProgress: true
            } as OrderBaseState);
        case orderBaseActions.SET_UPDATED_ORDER_PRODUCT:
            return Object.assign({}, state, {
                fetchInProgress: false,
                currentOrderProducts: Object.assign([], state.currentOrderProducts, {
                    [action.payload.index]: action.payload.orderProduct
                }),
                orders: Object.assign([], state.orders, {
                    [state.currentOrderIndex]: Object.assign({}, state.orders[state.currentOrderIndex], {
                        PrintStatus: action.payload.printStatus ? action.payload.printStatus : state.orders[state.currentOrderIndex].PrintStatus,
                        ProductionStatus: action.payload.productionStatus ? action.payload.productionStatus : state.orders[state.currentOrderIndex].ProductionStatus,
                        LaserStatus: action.payload.laserStatus ? action.payload.laserStatus : state.orders[state.currentOrderIndex].LaserStatus
                    } as Order)
                })
            } as OrderBaseState);
        case orderBaseActions.CHANGE_USER_IMAGE_COMMENT:
            return Object.assign({}, state, {
                currentOrderProducts: Object.assign([], state.currentOrderProducts, {
                    [action.payload.index]: Object.assign({}, state.currentOrderProducts[action.payload.index], {
                        UserImageComment: action.payload.comment
                    })
                })
            } as OrderBaseState);
        case orderBaseActions.CHANGE_PRINT_COMMENT:
            return Object.assign({}, state, {
                currentOrderProducts: Object.assign([], state.currentOrderProducts, {
                    [action.payload.index]: Object.assign({}, state.currentOrderProducts[action.payload.index], {
                        PrintComment: action.payload.comment
                    })
                })
            } as OrderBaseState);
        case orderBaseActions.CHANGE_PRODUCTION_OPERATOR_COMMENT:
            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [action.payload.index]: Object.assign({}, state.orders[action.payload.index], {
                        ProductionOperatorComment: action.payload.comment
                    })
                })
            } as OrderBaseState);
        case orderBaseActions.CHANGE_PRODUCTION_COMMENT:
            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [action.payload.index]: Object.assign({}, state.orders[action.payload.index], {
                        ProductionComment: action.payload.comment
                    })
                })
            } as OrderBaseState);
        case orderBaseActions.UPDATE_ORDER:
            return Object.assign({}, state, {
                fetchInProgress: true
            } as OrderBaseState);
        case orderBaseActions.SET_UPDATED_ORDER:
            return Object.assign({}, state, {
                orders: Object.assign([], state.orders, {
                    [state.currentOrderIndex]: action.payload
                }),
                fetchInProgress: false
            } as OrderBaseState);
        case orderBaseActions.SET_FAIL_PROCESSING:
            return Object.assign({},
                state,
                {
                    isFailProcessing: action.payload
                } as OrderBaseState);
        case orderBaseActions.CHANGE_CANT_REUSE_QUANTITY:
            return Object.assign({},
                state,
                {
                    cantReuseQuantity: action.payload
                } as OrderBaseState);
        case orderBaseActions.CHANGE_PRINT_REUSE_STATUS_MULTI:
            return Object.assign({},
                state,
                {
                    currentOrderProducts: Object.assign([], state.currentOrderProducts, {
                        [action.payload.index]: Object.assign({}, state.currentOrderProducts[action.payload.index], {
                            SelectedReuseOption: action.payload.status
                        })
                    })
                } as OrderBaseState);

        case orderBaseActions.SET_ORDER_FOR_BASE:
            return Object.assign({},
                state,
                {
                    orders: Object.assign([], state.orders, {
                        [state.currentOrderIndex]: Object.assign({}, action.payload)
                    }),
                } as OrderBaseState);
        case orderBaseActions.SET_ORDER_FOR_BASE_BY_INDEX:
            return Object.assign({},
                state,
                {
                    orders: Object.assign([], state.orders, {
                        [action.payload.index]: Object.assign({}, action.payload.order)
                    }),
                } as OrderBaseState);
        case orderBaseActions.SET_ORDER_TTN:
            return Object.assign({},
                state,
                {
                    CustomTTN: '',
                    orders: Object.assign([], state.orders, {
                        [state.currentOrderIndex]: Object.assign({}, state.orders[state.currentOrderIndex], {
                            TTN: action.payload
                        } as Order)
                    }),
                } as OrderBaseState);
        case orderBaseActions.SET_ALL_PAYMENT_STATUSES_FOR_ORDER:
            return { ...state, orderPaymentStatuses: action.payload };
        case orderBaseActions.CHANGE_TTN_MANUAL:
            return Object.assign({},
                state,
                {
                    CustomTTN: action.payload,
                } as OrderBaseState);
        case orderBaseActions.SET_ALL_STOCK_STATUSES_FOR_ORDER:
            return { ...state, currentStockStatuses: action.payload };
        case orderBaseActions.SET_ALL_CONFIRM_PAYMENT_STATUSES_FOR_ORDER:
            return { ...state, orderConfirmPaymentStatus: action.payload };
        case orderBaseActions.SELECT_CONFIRM_PAYMENT_STATUS_FOR_ORDER:
            return { ...state, selectedConfirmPaymentStatusForOrder: action.payload };
        case orderBaseActions.SET_PARTNERS_FOR_FILTER:
            return { ...state, partnerList: action.payload };
        case orderBaseActions.SELECT_PARNTER_FOR_FILTER:
            return { ...state, selectedPartner: action.payload };
        case orderBaseActions.SELECT_START_DATE_FOR_FILTER:
            return { ...state, filterStartDate: action.payload };
        case orderBaseActions.SELECT_TTN_DATE_FOR_FILTER:
            return { ...state, TTNCreateDateFilter: action.payload };
        case orderBaseActions.SELECT_END_DATE_FOR_FILTER:
            return { ...state, filterEndDate: action.payload };
        case orderBaseActions.SELECT_PRINT_STATUS_FOR_FILTER:
            return { ...state, selectedPrintStatus: action.payload };
        case orderBaseActions.SELECT_STOCK_STATUS_FOR_FILTER:
            return { ...state, selectedStockStatus: action.payload };
        case orderBaseActions.SELECT_PRODUCTION_STATUS_FOR_FILTER:
            return { ...state, selectedProductionStatus: action.payload };
        case orderBaseActions.SELECT_LASER_STATUS_FOR_FILTER:
            return { ...state, selectedLaserStatus: action.payload };
        case orderBaseActions.SELECT_ASSEMBLING_DATE_FOR_FILTER:
            return { ...state, filterAssemblingDate: action.payload };
        case orderBaseActions.CHANGE_TTN_FOR_FILTER:
            return { ...state, TTN: action.payload };
        case orderBaseActions.GET_AUTOCOMPLETE_MODEL_SUCCESS:
            return { ...state, modelList: action.payload };
        case orderBaseActions.GET_AUTOCOMPLETE_LEXICON_SUCCESS:
            return { ...state, lexiconList: action.payload };
        case orderBaseActions.ADD_ORDER_STATUS:
            return Object.assign({}, state, {
                fetchInProgress: true,
            } as OrderBaseState);
        case orderBaseActions.CHANGE_MODEL_VALUE:
            return Object.assign({}, state, {
                selectedModel: action.payload,
            } as OrderBaseState);
        case orderBaseActions.CHANGE_LEXICON_VALUE:
            return Object.assign({}, state, {
                selectedLexicon: action.payload,
            } as OrderBaseState);
        case orderBaseActions.SET_ORDER_STATUS:
            return Object.assign({}, state, {
                fetchInProgress: false,
                orders: Object.assign([], state.orders, {
                    [state.currentOrderIndex]: Object.assign({}, state.orders[state.currentOrderIndex], {
                        OrderStatus: Object.assign([], [], {
                            [0]: action.payload
                        }) as OrderStatus[],
                        SendedStatus: (action.payload as OrderStatus).Status === OrderStatusEnum.Sended ? action.payload : state.orders[state.currentOrderIndex].SendedStatus
                    } as Order)
                })
            } as OrderBaseState);
        case orderBaseActions.GENERATE_REESTERS:
            return Object.assign({}, state, {
                fetchInProgress: true
            } as OrderBaseState);
        case orderBaseActions.GET_REESTERS:
            return Object.assign({}, state, {
                fetchInProgress: true,
                ReestersDate: action.payload.ReestersDate,
                ReestersTtn: action.payload.ReestersTtn,
                ReestersOrderCode: action.payload.ReestersOrderCode
            } as OrderBaseState);
        case orderBaseActions.SET_REESTERS:
            return Object.assign({}, state, {
                fetchInProgress: false,
                Reesters: action.payload
            } as OrderBaseState);
        case orderBaseActions.FINISH_ACTION:
            return Object.assign({}, state, {
                fetchInProgress: false,
            } as OrderBaseState);
        case orderBaseActions.GENERATE_ORDER_EXPORT_LIST:
            return Object.assign({}, state, { 
                fetchInProgress: true,
            });
        case orderBaseActions.SET_ORDER_EXPORT_LIST_URL:
            return Object.assign({}, state, {
                exportOrderUrl: action.payload,
                fetchInProgress: false,
            } as OrderBaseState);
        case orderBaseActions.UNMOUNT_COMPONENT:
            return defaultOrderBaseState;
        case orderBaseActions.GET_ORDER_PRODUCTS_FOR_ALL_ORDERS_ON_PAGE_SUCCESS:
            return Object.assign({}, state, {
                orderProductsForPage: action.payload,
                fetchInProgress: false
            } as OrderBaseState);
        case orderBaseActions.SET_ORDERS_AFTER_SENDING_FROM_STOCK_RIGHT_VIEW:
            let copiedOrders = Object.assign([], state.orders);

            for (let newOrder of action.payload.orders) {
                let id = copiedOrders.findIndex(order => order.Id === newOrder.Id);

                copiedOrders[id] = newOrder;
            }

            return Object.assign({}, state, {
                orders: copiedOrders,
                fetchInProgress: false
            });

        default:
            const exhaustiveCheck: any = action;
            return state || defaultOrderBaseState;
    }
}