import * as React from "react";
import { ProductColor } from "../../../../business.entities/product.color";

export interface IAvailabilityProductColor {
    ProductColor: ProductColor;
}

export const AvailabilityProductColor = (props: IAvailabilityProductColor) => {
    return (
        <div className="item_color" style={{ backgroundColor: props.ProductColor.Color.Code === 'clear' ? '' : props.ProductColor.Color.Code }}>
            <div className="item_color_name">
                {props.ProductColor.Color.Name}
            </div>
            <div className="item_quantity">
                {props.ProductColor.Quantity}
            </div>
        </div>
    );
}