export function setCookie(cname, cvalue, exdays) {
    const expires = `expires=${new Date(exdays * 1000).toString()}`;
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1
    })
}