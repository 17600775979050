import { ClientBaseState, defaultClientBaseState } from "../../states/sales/client.base.state";
import * as clientBaseActions from "../../actions/sales/client.base.actions";

export default function (state: ClientBaseState, action: clientBaseActions.ClientBaseKnownActions) {
  switch (action.type) {
    case clientBaseActions.GET_AUTOCOMPLETE_PARTNER_RESULT:
      return { ...state, fetchInProgress: true };
    case clientBaseActions.SET_AUTOCOMPLETE_PARTNER_RESULT:
      return { ...state, optionPartnerList: action.payload };
    case clientBaseActions.CHANGE_PARTNER_VALUE:
      return {
        ...state,
        selectedPartnerValue: action.payload,
        fetchInProgress: false,
        filterChanged: true
      };
    case clientBaseActions.GET_CLIENTS_FOR_SELECTED_PARTNER:
      return {
        ...state,
        filterChanged: false,
      };
    case clientBaseActions.SET_CLIENTS_RESULT:
      return {
        ...state,
        totalPages: action.payload.PagesCount,
        clients: action.payload.Collection,
      };
    case clientBaseActions.RESET_COMMAND:
      return {
        ...state,
        selectedPartnerValue: null,
        clients: []
      };
    case clientBaseActions.UNMOUNT_CLIENT_BASE_COMPONENT:
      return { ...defaultClientBaseState };
    default:
      const exhaustiveCheck: any = action;
      return state || defaultClientBaseState;
  }
}