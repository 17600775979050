export enum LaserStatusEnum {
    WaitingForAssembling = 1,
    WaitingForProduction,
    Producted
}

export const GetLaserStatusName = (status) => {
    switch (status) {
        case LaserStatusEnum.WaitingForAssembling:
            return 'Ожидает сборки';
        case LaserStatusEnum.WaitingForProduction:
            return 'Готов к изготовлению';
        case LaserStatusEnum.Producted:
            return 'Изготовлен';
        default:
            return '';
    }
}

export const FilterStatusList = [
    {
        value: LaserStatusEnum.WaitingForProduction,
        label: GetLaserStatusName(LaserStatusEnum.WaitingForProduction)
    },
    {
        value: LaserStatusEnum.Producted,
        label: GetLaserStatusName(LaserStatusEnum.Producted)
    }
];

export const FilterStatusPartnerList = [
    {
        value: LaserStatusEnum.Producted,
        label: GetLaserStatusName(LaserStatusEnum.Producted)
    }
];