import { Store } from "redux";
import {
    GET_PARTNERS_FILTERED_PAGINATED
} from "../../actions/partner/partner.grid.actions";
import { Observable } from "rxjs/Observable";
import * as API from "../../constants/api.constants";
import { success, error } from "react-notification-system-redux";
import { isUnauthorized } from "../../helpers/unauthorize.hendle";
import * as actionCreators from "../../actions/partner/partner.grid.actions";
import { LocalForm } from "react-redux-form";
import { ErrorHandler } from "../../helpers/error.manager";
import storeProvider from "../../helpers/store.provider";

export const GetPartnersFilteredPaginated = (action$, store: Store<any>) =>
        action$.ofType(GET_PARTNERS_FILTERED_PAGINATED)
            .switchMap(action =>
                Observable.ajax.getJSON(`${API.GET_PARTNERS_FILTERED_PAGINATED}?count=${action.payload.count}&page=${action.payload.page}&filter=${JSON.stringify(action.payload.filter)}`,
                    { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                    .map((response: any) => {
                        storeProvider.getStore().dispatch(success({
                            title: 'Партнеры успешно получены',
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));
    
                        return actionCreators.GetPartnersSuccess(response.Body);
                    }).catch((errorData: any) => {
                        actionCreators.GetPartnersFailed({});
    
                        return Observable.of(ErrorHandler(errorData));
                    })
            );


export const GetWorkersPaginated = (action$, store: Store<any>) =>
        action$.ofType(actionCreators.GET_WORKERS_PAGINATED)
            .switchMap(action =>
                Observable.ajax.getJSON(`${API.GET_WORKERS_PAGINATED}?count=${action.payload.count}&page=${action.payload.page}`,
                    { "Authorization": `Bearer ${storeProvider.getStore().getState().authentication.AuthenticationToken}` })
                    .map((response: any) => {
                        storeProvider.getStore().dispatch(success({
                            title: 'Персонал успешно получен',
                            message: '',
                            position: 'br',
                            autoDismiss: 3,
                        }));
    
                        return actionCreators.GetPartnersSuccess(response.Body);
                    }).catch((errorData: any) => {
                        actionCreators.GetPartnersFailed({});
    
                        return Observable.of(ErrorHandler(errorData));
                    })
            );

export const updatePartner = (action$, state$) =>
action$.ofType(actionCreators.CANCEL_PARTNER)
    .switchMap(action =>
        Observable.ajax.post(`${API.UPDATE_PARTNER}`,
            JSON.stringify(action.payload),
            { "Authorization": `Bearer ${state$.value.authentication.AuthenticationToken}`, "Content-Type": "application/json" })
            .map((response: any) => {
                storeProvider.getStore().dispatch(success({
                    title: `Заявка успешно отменена`,
                    message: '',
                    position: 'br',
                    autoDismiss: 3,
                }));
            
                let filtered = state$.value.partner.partnerGrid.partners.filter(c => c.Id !== action.payload.Id)
            
                return actionCreators.CancelPartnerSuccess(filtered);
            }).catch((errorData: any) => {
                return Observable.of(ErrorHandler(errorData));
            })
    );