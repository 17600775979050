import { Category } from "../../business.entities/category";
import { Lexicon } from "../../business.entities/lexicon";
import { PriceListFilterItem } from "../../business.entities/price.list.filter.item";
import { PricelistOptions } from "../../business.entities/price.list.options";
import { PriceListLexiconPriceItem } from "../../business.entities/price.list.lexicon.price.item";
import { PriceListProductPriceItem } from "../../business.entities/price.list.product.price.item";
import { ExportPtatformConfigItem } from "../../business.entities/platformConfigItem";

export const GET_CATEGORIES: string = '[API] PRICELIST_OPTIONS.GET_CATEGORIES';
export interface IGetRootCategories { type: '[API] PRICELIST_OPTIONS.GET_CATEGORIES'; payload: any; }
export const GetRootCategories = () =>
    <IGetRootCategories>{ type: GET_CATEGORIES };

export const SET_CATEGORIES: string = 'PRICELIST_OPTIONS.SET_CATEGORIES';
export interface ISetRootCategories { type: 'PRICELIST_OPTIONS.SET_CATEGORIES'; payload: any; }
export const SetRootCategories = (categories: Category[]) =>
    <ISetRootCategories>{ type: SET_CATEGORIES, payload: categories };

export const GET_SUBCATEGORIES: string = '[API] PRICELIST_OPTIONS.GET_SUBCATEGORIES';
export interface IGetSubCategories { type: '[API] PRICELIST_OPTIONS.GET_SUBCATEGORIES'; payload: any; }
export const GetSubCategories = (parentId: number) =>
    <IGetSubCategories>{ type: GET_SUBCATEGORIES, payload: parentId };

export const SET_SUBCATEGORIES: string = 'PRICELIST_OPTIONS.SET_SUBCATEGORIES';
export interface ISetSubCategories { type: 'PRICELIST_OPTIONS.SET_SUBCATEGORIES'; payload: any; }
export const SetSubCategories = (categories: Category[], index: number) =>
    <ISetSubCategories>{ type: SET_SUBCATEGORIES, payload: { categories, index } };

export const GET_LEXICONS: string = '[API] PRICELIST_OPTIONS.GET_LEXICONS';
export interface IGetLexicons { type: '[API] PRICELIST_OPTIONS.GET_LEXICONS'; payload: any; }
export const GetLexicons = () =>
    <IGetLexicons>{ type: GET_LEXICONS };

export const SET_LEXICONS: string = 'PRICELIST_OPTIONS.SET_LEXICONS';
export interface ISetLexicons { type: 'PRICELIST_OPTIONS.SET_LEXICONS'; payload: any; }
export const SetLexicons = (lexicons: Lexicon[]) =>
    <ISetLexicons>{ type: SET_LEXICONS, payload: lexicons };

export const ADD_FILTER: string = 'PRICELIST_OPTIONS.ADD_FILTER';
export interface IAddFilter { type: 'PRICELIST_OPTIONS.ADD_FILTER'; payload: any; }
export const AddFilter = (filterList: PriceListFilterItem[]) =>
    <IAddFilter>{ type: ADD_FILTER, payload: filterList };

export const ADD_LEXICON_PRICES: string = 'PRICELIST_OPTIONS.ADD_LEXICON_PRICES';
export interface IAddLexiconPrices { type: 'PRICELIST_OPTIONS.ADD_LEXICON_PRICES'; payload: any; }
export const AddLexiconPrices = (priceList: PriceListLexiconPriceItem[]) =>
    <IAddLexiconPrices>{ type: ADD_LEXICON_PRICES, payload: priceList };

export const ADD_PRODUCT_PRICES: string = 'PRICELIST_OPTIONS.ADD_PRODUCT_PRICES';
export interface IAddProductPrices { type: 'PRICELIST_OPTIONS.ADD_PRODUCT_PRICES'; payload: any; }
export const AddProductPrices = (priceList: PriceListProductPriceItem[]) =>
    <IAddProductPrices>{ type: ADD_PRODUCT_PRICES, payload: priceList };

export const SELECT_SUBCATEGORIES: string = 'PRICELIST_OPTIONS.SELECT_SUBCATEGORIES';
export interface ISelectSubCategories { type: 'PRICELIST_OPTIONS.SELECT_SUBCATEGORIES'; payload: any; }
export const SelectSubCategories = (lexicons: Lexicon[]) =>
    <ISelectSubCategories>{ type: SELECT_SUBCATEGORIES, payload: lexicons };

export const SELECT_LEXICONS: string = 'PRICELIST_OPTIONS.SELECT_LEXICONS';
export interface ISelectLexicons { type: 'PRICELIST_OPTIONS.SELECT_LEXICONS'; payload: any; }
export const SelectLexicons = (lexicons: Lexicon[]) =>
    <ISelectLexicons>{ type: SELECT_LEXICONS, payload: lexicons };

export const CHANGE_AVAILABLE_ONLY: string = 'PRICELIST_OPTIONS.CHANGE_AVAILABLE_ONLY';
export interface IChangeAvailableOnly { type: 'PRICELIST_OPTIONS.CHANGE_AVAILABLE_ONLY'; payload: any; }
export const ChangeAvailableOnly = (value: boolean) =>
    <IChangeAvailableOnly>{ type: CHANGE_AVAILABLE_ONLY, payload: value };

export const SELECT_ROOT_CATEGORY: string = 'PRICELIST_OPTIONS.SELECT_ROOT_CATEGORY';
export interface ISelectRootCategory { type: 'PRICELIST_OPTIONS.SELECT_ROOT_CATEGORY'; payload: any; }
export const SelectRootCategory = (category: Category) =>
    <ISelectRootCategory>{ type: SELECT_ROOT_CATEGORY, payload: category };

export const GET_OPTIONS: string = '[API] PRICELIST_OPTIONS.GET_OPTIONS';
export interface IGetOptions { type: '[API] PRICELIST_OPTIONS.GET_OPTIONS'; payload: any; }
export const GetOptions = () =>
    <IGetOptions>{ type: GET_OPTIONS };

export const SET_OPTIONS: string = 'PRICELIST_OPTIONS.SET_OPTIONS';
export interface ISetOptions { type: 'PRICELIST_OPTIONS.SET_OPTIONS'; payload: any; }
export const SetOptions = (options: PricelistOptions) =>
    <ISetOptions>{ type: SET_OPTIONS, payload: options };

export const ADD_OPTIONS: string = '[API] PRICELIST_OPTIONS.ADD_OPTIONS';
export interface IAddOptions { type: '[API] PRICELIST_OPTIONS.ADD_OPTIONS'; payload: any; }
export const AddOptions = (options: PricelistOptions) =>
    <IAddOptions>{ type: ADD_OPTIONS, payload: options };

export const UPDATE_OPTIONS: string = '[API] PRICELIST_OPTIONS.UPDATE_OPTIONS';
export interface IUpdateOptions { type: '[API] PRICELIST_OPTIONS.UPDATE_OPTIONS'; payload: any; }
export const UpdateOptions = (options: PricelistOptions) =>
    <IUpdateOptions>{ type: UPDATE_OPTIONS, payload: options };

export const REMOVE_OPTIONS: string = '[API] PRICELIST_OPTIONS.REMOVE_OPTIONS';
export interface IRemoveOptions { type: '[API] PRICELIST_OPTIONS.REMOVE_OPTIONS'; payload: any; }
export const RemoveOptions = (netId: string) =>
    <IRemoveOptions>{ type: REMOVE_OPTIONS, payload: netId };

export const ADD_EXPORT_PLATFORM: string = 'PRICELIST_OPTIONS.ADD_EXPORT_PLATFORM';
export interface IAddExportPlatform { type: 'PRICELIST_OPTIONS.ADD_EXPORT_PLATFORM'; payload: any; }
export const AddExportPlatform = (platformConfig: ExportPtatformConfigItem[]) =>
    <IAddExportPlatform>{ type: ADD_EXPORT_PLATFORM, payload: platformConfig };

export type PriceListOptionsKnownActions =
    IGetRootCategories |
    ISetRootCategories |
    IGetSubCategories |
    ISetSubCategories |
    IGetLexicons |
    ISetLexicons |
    IAddFilter |
    ISelectSubCategories |
    ISelectLexicons |
    ISelectRootCategory |
    IChangeAvailableOnly |
    IGetOptions |
    ISetOptions |
    IAddOptions |
    IUpdateOptions |
    IRemoveOptions |
    IAddLexiconPrices |
    IAddProductPrices |
    IAddExportPlatform;