import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RightViewState } from "../../../../states/right.view.state";
import EditTutorialComponent from "./edit.tutorial.component"
import * as  rightViewActions from "../../../../actions/right.view.actions";
import Locale from '../../../../helpers/locale.pagination';
import Pagination from 'rc-pagination';
import SelectForPaginate from 'rc-select';
import * as tutorialActions from "../../../../actions/cabinet/tutorial.actions";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import * as API from "../../../../constants/api.constants";
import { GetIdentityRoleName, IdentityRoleEnum } from "../../../../helpers/identity.roles";
import { isNullOrUndefined } from "util";

class TutorialsComponent extends React.Component<any, any>{

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            currentPageSize: 20,
            selectedVideo: null
        }

        props.GetAllTutorials(1, 20);
    }

    componentWillUnmount() {
        this.props.Unmount();
    }

    public OnOpenNewTutorialCommand() {
        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)) {
            this.props.ChangeOrder(0);

            let rvState = new RightViewState();
            rvState.Title = "Добавить";
            rvState.Component = EditTutorialComponent;
            rvState.ViewSize = "rightViewGroupActions";
            rvState.CloseFunction = () => {
                if (!this.props.Tutorial.fetching) {
                    this.props.RemoveRVData();
                    this.props.DeselectTutorial();
                    this.props.ChangeVideo(null);
                }
            };

            this.props.SelectRVData(rvState);
        }
    }

    public OnOpenSelectTutorialCommand(tutorial, key): void {

        if (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)) {

            this.props.SelectTutorial(tutorial, key);

            let rvState = new RightViewState();
            rvState.Title = "Изменить";
            rvState.Component = EditTutorialComponent;
            rvState.ViewSize = "rightViewGroupActions";
            rvState.CloseFunction = () => {
                if (!this.props.Tutorial.fetching) {
                    this.props.RemoveRVData();
                    this.props.DeselectTutorial();
                    this.props.ChangeVideo(null);
                }
            };

            this.props.SelectRVData(rvState);
        }
    }

    public OnChangePageSizeCommand(current, pageSize): void {
        if (!this.props.Tutorial.fetching) {
            this.setState(() => ({
                currentPage: 1,
                currentPageSize: pageSize
            }));

            this.props.GetAllTutorials(1, this.state.currentPageSize);
        } else
            return;
    }

    public OnChangePageCommand(current, pageSize): void {
        if (!this.props.Tutorial.fetching) {
            this.setState(() => ({
                currentPage: current
            }));

            this.props.GetAllTutorials(current, this.state.currentPageSize);
        } else
            return;
    }

    public OnSelectVideoCommand(tutorial) {
        this.setState(() => ({
            selectedVideo: tutorial ? tutorial.Link : null
        }));
    }

    render() {
        return (
            <div className="rcPagination__component tutorialPageComponent">

                <div className="content_header_component">
                    <div className="page_title oneTitleContainer">
                        <span className="title_default">Видео инструкции</span>
                        <span className="title_description"></span>
                    </div>
                    {
                        (this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                            || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator)) ?
                            <div className="header__button__controls">
                                <details className="dStyle" open>
                                    <summary>
                                        <div className="summaryFilter" />
                                    </summary>
                                    <div className="button__control" onClick={() => this.OnOpenNewTutorialCommand()}>Добавить</div>
                                </details>

                                <details className="mStyle">
                                    <summary>
                                        <div className="summaryFilter" />
                                    </summary>
                                    <div className="button__control" onClick={() => this.OnOpenNewTutorialCommand()}>Добавить</div>
                                </details>
                            </div> : null
                    }

                </div>

                <div className="content_component">
                    <div className="videoWrapper__container">
                        <div className="videoWrapper__content">
                            {
                                this.props.Tutorial.tutorials.length > 0 ?
                                    this.props.Tutorial.tutorials.map((tutorial, key) =>
                                        <div className={`video_wrapper videoItem__container1 ${tutorial.IsSelected ? 'isSelected' : ''}`}
                                            key={key}
                                        >
                                            <div className="video_title">
                                                {tutorial.Name}
                                            </div>
                                            <div className="playBtn__vid" onClick={() => this.OnSelectVideoCommand(tutorial)}></div>
                                            {this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Administrator)
                                                || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Boss)
                                                || this.props.Authorization.Role === GetIdentityRoleName(IdentityRoleEnum.Coordinator) ?
                                                <div className="editBtn__vid" onClick={() => this.OnOpenSelectTutorialCommand(tutorial, key)}></div>
                                                : null}
                                            <div className="videoWrapper"></div>
                                        </div>
                                    ) : null
                            }
                        </div>
                    </div>

                    <Pagination
                        locale={Locale}
                        selectComponentClass={SelectForPaginate}
                        showSizeChanger={true}
                        pageSizeOptions={['20', '30', '50', '100', '200', '500']}
                        defaultPageSize={this.state.currentPageSize}
                        defaultCurrent={this.state.currentPage}
                        onShowSizeChange={this.OnChangePageSizeCommand.bind(this)}
                        onChange={this.OnChangePageCommand.bind(this)}
                        total={this.props.Tutorial.total}
                    />
                </div>

                {this.state.selectedVideo ?
                    <div className="video_full_component">
                        <div className="close_video_full" onClick={() => this.OnSelectVideoCommand(null)}></div>
                        <iframe width="420" height="345" src={`${API.SERVER_URL}${this.state.selectedVideo}`} allowFullScreen>
                        </iframe>

                        <div className="VVwrapCont">
                            {/*
                              <Video
                                controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
                                <source  type='video/webm; codecs="vp8, vorbis"' />
                            </Video>
                             */}
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}
export default connect((state: any) => ({
    Tutorial: state.cabinet.tutorial,
    Authorization: state.authentication
}),
    dispatch => bindActionCreators({
        SelectRVData: rightViewActions.SelectRVData,
        RemoveRVData: rightViewActions.RemoveRVData,
        GetAllTutorials: tutorialActions.GetAllTutorials,
        Unmount: tutorialActions.Unmount,
        SelectTutorial: tutorialActions.SelectTutorial,
        DeselectTutorial: tutorialActions.DeselectTutorial,
        ChangeVideo: tutorialActions.ChangeVideo,
        ChangeOrder: tutorialActions.ChangeOrder
    }, dispatch))(TutorialsComponent);