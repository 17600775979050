import * as React from "react";
import { connect } from "react-redux";
import {
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseProduct,
    ConfirmBaseProductSelect
} from '../../../../../actions/sales/wizard.actions';
import { bindActionCreators } from "redux";
import Product from "../../../../../business.entities/product";
import { OnGoToNextStepCommand, OnGoToPrevStepCommand } from "../../../../../helpers/wizard.substep.helper";
import { List } from "linqts";
import { GetOrderProductStepComponent } from "../../../../../helpers/enums/order.product.steps";
import { WizardState } from "../../../../../states/sales/wizard.state";

const mapStateToProps = (state) => {
    return {
        Wizard: state.sales.wizard
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    CancelCurrentOrderProduct,
    SetNextSubStep,
    SelectCurrentBaseProduct,
    ConfirmBaseProductSelect
}, dispatch);

const BaseProduct = (props: any) => {

    document.onkeydown = (e) => {
        if ((event as any).keyCode == 38) {
            event.preventDefault();
            OnSelectTopBaseCommand();
        }

        if ((event as any).keyCode == 40) {
            event.preventDefault();
            OnSelectBottomBaseCommand();
        }

        if ((event as any).keyCode == 13) {
            event.preventDefault();
            OnProductSelectConfirm()
        }

        if ((event as any).keyCode === 27) {
            event.preventDefault();

            if (props.Wizard.currentOrderProduct) {
                props.CancelCurrentOrderProduct();
            }
        }

        if ((event as any).keyCode === 37) {
            event.preventDefault();
            OnGoToPrevStepCommand(props);
        }

        if ((event as any).keyCode === 39) {
            event.preventDefault();
            (props.Wizard as WizardState).currentOrderProduct.BaseProduct &&
                OnGoToNextStepCommand(props);
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 50) {
            event.preventDefault();
        }

        if ((event as any).ctrlKey
            && (event as any).keyCode == 49) {
            event.preventDefault();
        }
    }

    const OnSelectTopBaseCommand = () => {
        let startIndex = 0;

        if (props.Wizard.currentBaseProduct) {
            const base = new List(props.Wizard.baseProductList)
                .FirstOrDefault(c => (c as Product).Id === props.Wizard.currentBaseProduct.Id)

            startIndex = props.Wizard.baseProductList.indexOf(base);
            startIndex != 0 ? startIndex-- : startIndex = props.Wizard.baseProductList.length - 1;
        }

        props.SelectCurrentBaseProduct(props.Wizard.baseProductList[startIndex]);
    }

    const OnSelectBottomBaseCommand = () => {
        let startIndex = 0;

        if (props.Wizard.currentBaseProduct) {
            const base = new List(props.Wizard.baseProductList)
                .FirstOrDefault(c => (c as Product).Id === props.Wizard.currentBaseProduct.Id)

            startIndex = props.Wizard.baseProductList.indexOf(base);
            startIndex != props.Wizard.baseProductList.length - 1 ? startIndex++ : startIndex = 0;
        }

        props.SelectCurrentBaseProduct(props.Wizard.baseProductList[startIndex]);
    }

    const OnProductSelectConfirm = () => {
        if (props.Wizard.currentBaseProduct) {
            props.ConfirmBaseProductSelect();
            OnGoToNextStepCommand(props);
        }
    }

    const OnSelectBaseProductByMouse = (index) => {
        props.SelectCurrentBaseProduct(props.Wizard.baseProductList[index]);

        if (props.Wizard.baseProductList[index]) {
            props.ConfirmBaseProductSelect();
        }

        OnGoToNextStepCommand(props);
    }

    return (
        <div className="baseBroductView__container">
            <div className="colorContainer__contentLabel">Основа</div>
            <div>
                {props.Wizard.baseProductList.length > 0 && props.Wizard.baseProductList.map((baseProduct, index) =>
                    <div key={index}
                        className={`baseProductItem__container  ${props.Wizard.currentBaseProduct.Id === baseProduct.Id ? 'isSelected' : ''}`}
                        onClick={() => OnSelectBaseProductByMouse(index)}>
                        <div className="baseProduct__nameContainer">
                            <div className="casualName">
                                {(baseProduct as Product).Lexicon.OfficialPartnerName}
                            </div>
                            {/* <div className="officialPartnerName">
                                {(baseProduct as Product).Lexicon.CasualName}
                            </div> */}
                        </div>
                        <div className="productColors__container">
                            {(baseProduct as Product).ProductColors.map((productColor, colorIndex) =>
                                <div className="colorItem" key={`${index}-${colorIndex}`}>
                                    <div className={`dotStyle ${productColor.Color.Code === 'clear' ? 'clearStyle' : ''}`} style={{ backgroundColor: productColor.Color.Code }} >
                                        <div className="hide">
                                            {productColor.Color.Name}
                                        </div>
                                        <div className="qty">
                                            {productColor.Quantity}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(BaseProduct)