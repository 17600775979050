import { Partner } from "../../business.entities/partner";
import { Client } from "../../business.entities/client";
import { SelectModel } from "../../helpers/select.model";

export class ClientBaseState {
  totalPages: number;

  filterChanged: boolean;
  fetchInProgress: boolean;

  optionPartnerList: SelectModel[];
  selectedPartnerValue: SelectModel;

  clients: Client[];
}

export const defaultClientBaseState = {
  totalPages: 0,

  filterChanged: false,
  fetchInProgress: false,

  optionPartnerList: [],
  selectedPartnerValue: null,

  clients: [],

};