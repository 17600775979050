import * as React from "react";
import { Switch, Route } from "react-router-dom";
import PersonalInfo from "./personal.info/personal.info";
import { default as ConstructorInfo } from "./constructor/constructor.info";
import { RightView } from "../../shared/right.view/right.view";
import { connect } from "react-redux";
import { MenuProvider } from "../../../helpers/menu.provider";
import { Redirect } from "react-router";
import { default as Statistics } from "./statistics/statistics";
import { Chat } from "./chat/chat";
import MutualSettlements from "./mutual.settlements/mutual.settlements";
import TutorialsComponent from "./tutorials/tutorials.component";
import {default as PrintingStatistics } from "./printing.statuses.statistics/statiscics";

interface ICabinetComponentProps {
    match: any;
    RightViewData: any;
    MenuProvider: MenuProvider
    history: any;
    Authentication: any;
}

class CabinetComponent extends React.Component<ICabinetComponentProps, any> {
    static defaultProps = {
        MenuProvider: new MenuProvider()
    };

    render() {
        const role = this.props.Authentication.Role;
        const authState = this.props.Authentication;
        const startPath = this.props.MenuProvider.GetFirstAllowedRoutesForRole(role, "cabinet")
        const currentLocation = this.props.history.location.pathname;

        return (
            <div className="master_content_component  cabinetMP__content">
                <Switch>
                    {currentLocation === this.props.match.url ?
                        <Redirect from={`${this.props.match.url}`} to={`/${startPath}`} /> : null
                    }
                    <Route path={`${this.props.match.url}/client-info`} component={PersonalInfo} />
                    <Route path={`${this.props.match.url}/constructor-info`} component={ConstructorInfo} />
                    <Route path={`${this.props.match.url}/tutorial`} component={TutorialsComponent} />
                    {/*<Route path={`${this.props.match.url}/chat`} component={Chat} /> */}
                    <Route path={`${this.props.match.url}/mutual-settlements`} component={MutualSettlements} />
                    <Route path={`${this.props.match.url}/statistics`} component={Statistics} />
                    <Route path={`${this.props.match.url}/printing-stats`} component={PrintingStatistics} />
                </Switch>
                {/* {
                    this.props.RightViewData &&
                    <RightView Title={this.props.RightViewData.Title}
                        Component={this.props.RightViewData.Component}
                        CloseFunction={this.props.RightViewData.CloseFunction} />
                } */}
            </div>
        );
    }
}
export default connect(state => ({
    Authentication: (state as any).authentication
}))(CabinetComponent);
