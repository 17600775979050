export enum WatermarkPositionEnum {
    TopLeft,
    TopRight,
    MidLeft,
    MidRight,
    BottomLeft,
    BottomRight
}

export const GetPositionName = (value: WatermarkPositionEnum) => {
    switch (value) {
        case WatermarkPositionEnum.TopLeft:
            return 'Вверху слева';
        case WatermarkPositionEnum.TopRight:
            return 'Вверху справа';
        case WatermarkPositionEnum.MidLeft:
            return 'По центру слева';
        case WatermarkPositionEnum.MidRight:
            return 'По центру справа';
        case WatermarkPositionEnum.BottomLeft:
            return 'Внизу слева';
        case WatermarkPositionEnum.BottomRight:
            return 'Внизу справа';
    }
}