import { Reducer } from "redux";
import * as priceActions from "../../actions/stock/price.actions";
import { PriceState, defaultPriceState } from "../../states/stock/price.state";
import { ApplicationState } from "..";

export const reducer: Reducer<PriceState> = (state: PriceState, action: priceActions.PriceKnownActions) => {
    switch (action.type) {

        case priceActions.SET_PARTNERS_AUTOCOMPLETE:
            return { ...state, partnerAutocompleteList: action.payload };

        case priceActions.CHANGE_PARTNER_SELECT:
            return Object.assign(state, { selectedPartner: action.payload } as PriceState);

        case priceActions.SELECT_CONTENT_COMPONENT:
            return { ...state, selectedContentComponent: action.payload }

        case priceActions.SET_ALL_PRICES:
            return { ...state, partnerPriceSegments: action.payload };

        case priceActions.SET_PRICE_CATEGORY_SELECT_LIST:
            return { ...state, categorySelectList: action.payload };

        case priceActions.SET_PRICE_SUBCATEGORY_SELECT_LIST:
            return { ...state, subcategoryAutocompleteList: action.payload };

        case priceActions.CHANGE_PRICE_CATEGORY:
            return { ...state, selectedCategory: action.payload };

        case priceActions.CHANGE_PRICE_SUBCATEGORY:
            return {
                ...state,
                selectedSubcategory: action.payload,
                subcategoryAutocompleteList: [],
                productFilterChanged: true
            };

        case priceActions.UPDATE_PARTNER_PRICE_SEGMENT_SUCCESS:
            return { ...state, partnerPriceSegments: action.payload }

        case priceActions.CREATE_PARTNER_PRICE_SEGMENT_SUCCESS:
            return { ...state, partnerPriceSegments: action.payload }

        case priceActions.GET_PRODUCTS_FOR_PRICES:
            return {
                ...state,
                productsFetchInProgress: true
            }

        case priceActions.SET_PRODUCTS_FOR_PRICES:
            return {
                ...state,
                products: action.payload,
                productFilterChanged: false,
                productsFetchInProgress: false
            }

        case priceActions.SET_WHOLESALE_LIST:
            return {
                ...state,
                wholesaleList: action.payload,
            }

        case priceActions.CREATE_WHOLESALE_SUCCESS:
            return {
                ...state,
                wholesaleList: action.payload,
            }

        case priceActions.UPDATE_WHOLESALE_SUCCESS:
            return {
                ...state,
                wholesaleList: action.payload,
            }

        case priceActions.SET_PARTNER_PRICE_HISTORY:
            return {
                ...state,
                auditInfo: action.payload,
            }

        case priceActions.REMOVE_PARTNER_PRICE_HISTORY:
            return {
                ...state,
                auditInfo: defaultPriceState.auditInfo,
            }

        case priceActions.SET_START_DATE:
            return Object.assign(state, { auditStartDate: action.payload } as PriceState);

        case priceActions.SET_END_DATE:
            return Object.assign(state, { auditEndDate: action.payload } as PriceState);

        case priceActions.UNMOUNT_PRICE_COMPONENT:
            return defaultPriceState;

        default:
            const exhaustiveCheck: any = action;
    }

    return state || defaultPriceState;
}