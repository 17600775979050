export enum PaymentMethod {
    Prepaid = 1,
    Afterpaid = 3,
    Certificate = 4
}

export const GetPaymentMethodName = (method) => {
    switch (method) {
        case PaymentMethod.Prepaid:
            return 'Предоплата';
        case PaymentMethod.Afterpaid:
            return 'Наложеный платёж';
        case PaymentMethod.Certificate:
            return 'Сертификат-промокод'
        default:
            return '';
    }
}